import React, {useState} from 'react';
import {isEmpty} from 'lodash';
import {useDispatch} from 'react-redux';
import {CloseOutlined, DeleteOutlined, PlusOutlined, SaveOutlined} from '@ant-design/icons';
import {mdm} from '../../Utils/constant';
import {setDataForOptionsTypeSelect} from '../../redux/actions/actions';
import {StyledModal} from '../Themes/Components';

import styles from './DetailTable.module.scss';
import {Button, Modal, Table} from 'ui-kit';
import {Dispatch, SetStateAction} from 'react';
import {errorModalCreate} from 'components/Helpers/Modals';
import ComboboxOptionsForm from './ComboboxOptionsForm';

export interface DetailTableRecordType {
  key: string;
  value: string;
}

export interface DetailTableProps {
  detailSubmit: (values: DetailTableRecordType) => void;
  data: Array<DetailTableRecordType>;
  disabled: boolean;
  okModal: () => void;
  setComboboxData: Dispatch<SetStateAction<DetailTableRecordType[]>>;
  onCancel: () => void;
}

/** Модальное окно добавления значений в список
 * @param detailSubmit - функция сбора данных для payload
 * @param disabled - условия для disabled
 * @param okModal - функция setter значений в форму
 * @param setComboboxData - функция setter значений
 * @param onCancel - функция закрытия модального окна
 * @param data - данные для отображения в таблице */
const DetailTable = ({detailSubmit, data, disabled, okModal, setComboboxData, onCancel}: DetailTableProps) => {
  /** Отправка данных в хранилище */
  const dispatch = useDispatch();
  /** Начальное состояние модального окна */
  const [modalVisible, setModalVisible] = useState(false);
  /** Функция удаления значения  */
  const onDeleteHandler = (record: DetailTableRecordType) => {
    const filterData = data.filter((item: DetailTableRecordType) => item?.key !== record?.key);
    dispatch(setDataForOptionsTypeSelect(filterData));
    setComboboxData(filterData);
  };
  /** Колонки для таблицы */
  const comboboxDetailColumns = [
    {
      title: mdm.key,
      dataIndex: 'key',
    },
    {
      title: mdm.value,
      dataIndex: 'value',
    },
    {
      render: (text: React.ReactNode, record: DetailTableRecordType) => {
        return <DeleteOutlined onClick={() => onDeleteHandler(record)} />;
      },
    },
  ];

  return (
    <div className={styles.detailModal}>
      <div className={styles.detailTable}>
        <div className={styles.beforeTableButtons}>
          <Button icon={<PlusOutlined />} disabled={disabled} type="primary" onClick={() => setModalVisible(true)}>
            {mdm.add}
          </Button>
        </div>
        <Table
          columns={comboboxDetailColumns}
          dataSource={!isEmpty(data) ? data : []}
          rowKey={(record) => record.key}
          pagination={false}
          bordered
        />
      </div>

      <div className={styles.afterTableButtons}>
        <Button
          icon={<CloseOutlined />}
          onClick={() => {
            onCancel();
          }}
        >
          {mdm.cancel}
        </Button>
        <Button icon={<SaveOutlined />} type="primary" htmlType="submit" onClick={okModal}>
          {mdm.save}
        </Button>
      </div>
      <Modal
        title={mdm.addItem}
        open={modalVisible}
        onCancel={() => setModalVisible(false)}
        destroyOnClose={true}
        closable={false}
        footer={null}
        width={960}
      >
        <ComboboxOptionsForm closeModal={() => setModalVisible(false)} onSubmit={detailSubmit} />
      </Modal>
    </div>
  );
};

export default React.memo(DetailTable);
