import React, {useEffect, useState} from 'react';
import {fetchFunc} from '../../Utils/security/http/mdm';
import {Docs} from 'ecm-docs';
import security from '../../Utils/security';
const {getToken} = security;

const CatalogsOrgDeduplicationDocs = ({recordUuid, graph}) => {
  const [docsSelectionFilter, setDocsSelectionFilter] = useState({});

  useEffect(() => {
    const setFilter = async () => {
      const graphOrgs = await getGraphOrgs(graph.uuid);
      if (graphOrgs && graphOrgs.length) {
        let orgsUuid = graphOrgs.map((item) => item.uuid);
        setDocsSelectionFilter({
          fields: {
            'orgId.uuid.keyword': `in(${orgsUuid.join(',')})`,
          },
        });
      }
    };
    setFilter();
  }, []);

  const getGraphOrgs = async (uuid) => {
    try {
      const fetchData = await fetchFunc({
        url: `/api/v1/deduplication/graphs/${uuid}/items`,
      });
      return fetchData.content;
    } catch (error) {}
  };

  const deleteDoc = async (uuid) => {
    try {
      await fetchFunc(
        {
          url: `/api/v1/deduplication/graphs/${recordUuid}/ecmdoc/${uuid}`,
          method: 'delete',
        },
        null
      );
    } catch (error) {}
  };

  const addToGraph = async (docType, docUuid) => {
    try {
      const fetchData = await fetchFunc({
        url: `/api/v1/deduplication/graphs/${recordUuid}/ecmdoc/${docType}/${docUuid}`,
        method: 'post',
      });
      return fetchData.content;
    } catch (error) {}
  };

  const addDocuments = async (documents) => {
    try {
      const promises = documents.map(async (doc) => {
        await addToGraph(doc.documentTypeIdentifier.documentTypeIdentifier, doc.uuid);
      });
      await Promise.all(promises);
    } catch (error) {}
  };

  const {REACT_APP_ECM_URL: ecmApiUrl, REACT_APP_ECM_FRONT_URL: ecmFrontUrl} = window._env;

  return (
    <div className="org-list__no-structure-items container--white">
      <Docs
        defaultDocsSelectorFilter={docsSelectionFilter}
        recordUuid={recordUuid}
        multiply={true}
        ecmApiUrl={ecmApiUrl}
        ecmFrontUrl={ecmFrontUrl}
        fetch={{
          docStoreUrl: `/api/v1/deduplication/graphs/ecmdocs`,
          method: 'GET',
          extraParams: {
            unverifiedGoldCard: recordUuid,
          },
          service: 'mdm',
        }}
        customMethods={{
          deleteDoc,
        }}
        token={getToken()}
        onDocumentsAdd={addDocuments}
        showDocsList={true}
        showCreateBtn={false}
      />
    </div>
  );
};

export default CatalogsOrgDeduplicationDocs;
