import React, {useEffect} from 'react';
import {Route, BrowserRouter, Switch, Redirect} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {Layout} from 'ui-kit';

import {getUserName} from '../../redux/thunk/userThunk';
import {getHelpInfo} from '../../redux/thunk/helpThunk';
import Sidebar from '../../components/Sidebar/Sidebar';
import {PageHeader} from 'components/PageHeader/PageHeader';
import {routes} from 'config/routes';

export interface AppProps {
  keycloakLogout: () => void;
}

const App = (props: AppProps) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserName());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getHelpInfo());
  }, [dispatch]);

  return (
    <div style={{position: 'relative'}} className="app">
      <BrowserRouter>
        <div className="d-flex flex-column container-fluid p-0 h-100 mainContainer">
          {/* <HeaderMDM keycloakLogout={props.keycloakLogout} /> */}
          <Layout>
            <Sidebar keycloakLogout={props.keycloakLogout} />
            <div className="w-100 transition" style={{overflowX: 'auto'}}>
              <div className="appContent">
                <PageHeader />
                <Switch>
                  <Redirect exact from="/" to="/catalog" />
                  {routes.map((route, i) => {
                    return (
                      <Route
                        key={'route-' + i}
                        path={route.path}
                        render={(props) => {
                          return route.main && <route.main {...props} {...route} />;
                        }}
                        // other={route}
                      />
                    );
                  })}
                </Switch>
              </div>
            </div>
          </Layout>
        </div>
      </BrowserRouter>
    </div>
  );
};

export default React.memo(App);
