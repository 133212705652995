import React, {useState, useEffect, useMemo} from 'react';
import useCRUD from '../../hooks/CRUD.hook';
import {fetchFunc} from '../../Utils/security/http/mdm';
import {Link} from 'react-router-dom';
import {saveAs} from 'file-saver';
import {isEmpty} from 'lodash';
import {Input, Select, Table} from 'ui-kit';
import {useHistory} from 'react-router';
import {
  createFilterString,
  defaultDateFormat,
  onError,
  renderChangeType,
  renderStatus,
  renderVerifierFio,
} from '../../components/Helpers/Utils';
import moment from 'moment';

const columns = [
  {
    title: 'Статус',
    dataIndex: ['sysVerifyInfo', 'status'],
    key: 'status',
    render: (text, record) => renderStatus(record, 'sysVerifyInfo.status'),
  },
  {
    title: 'Тип изменения',
    dataIndex: ['sysVerifyInfo', 'changeType'],
    key: 'changeType',
    render: (text) => renderChangeType(text),
  },
  {
    title: 'Дата начала верификации',
    dataIndex: ['sysVerifyInfo', 'dateFrom'],
    key: 'dateFrom',
    render: (text) => moment(text).format(defaultDateFormat),
  },
  {
    title: 'Верификатор',
    dataIndex: 'verifier',
    key: 'verifier',
    render: (text, record) => renderVerifierFio('sysVerifyInfo.verifier', record),
  },
  {
    title: 'Название',
    dataIndex: 'caption',
    key: 'caption',
  },
  {
    title: 'ИНН',
    dataIndex: 'inn',
    key: 'inn',
  },
  {
    title: 'ОГРН',
    dataIndex: 'ogrn',
    key: 'ogrn',
  },
  {
    title: 'Аналог ИНН',
    dataIndex: 'codeOgrnAnalog',
    key: 'codeOgrnAnalog',
  },
  {
    title: 'Аналог ОГРН',
    dataIndex: 'innAnalog',
    key: 'innAnalog',
  },
  {
    title: 'Страна',
    dataIndex: ['regCountry', 'caption'],
    key: 'country',
  },
  {
    title: 'Нерезидент РФ',
    dataIndex: 'nonResidentRf',
    key: 'nonResidentRf',
    render: (text) => (text ? 'Да' : 'Нет'),
  },
];

const displayedColumns = [
  'status',
  'changeType',
  'dateFrom',
  'verifier',
  'caption',
  'ogrn',
  'inn',
  'codeOgrnAnalog',
  'innAnalog',
  'country',
  'nonResidentRf',
];

const VerifyOrgList = () => {
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const [data, setData] = useState(null);
  const [totalElements, setTotalElements] = useState(null);
  const [loading, setLoading] = useState(false);
  const [columnsForRender, setColumnsForRender] = useState(columns);
  const [displayedColumnsState, setDisplayedColumns] = useState(displayedColumns);

  const history = useHistory();

  useEffect(() => {
    setLoading(true);
    getVerificationList();
  }, [size, page]);

  const getVerificationList = async () => {
    try {
      const pageParam = `?page=${page}`;
      const sizeParam = `&size=${size}`;
      const sortParam = `&sort=createDate,desc`;
      const statusParam = `&verifyStatus=verification`;

      let url = `/api/v1/catalogs/org/items/unverified${pageParam}${sizeParam}${sortParam}${statusParam}`;

      const data = await fetchFunc(
        {
          url,
          method: 'get',
        },
        onError
      );
      if (data) {
        setTotalElements(data.totalElements);
        setData(data.content);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  let columnsShowOptions = useMemo(() => {
    return columns.map((item, index) => {
      return (
        <Select.Option value={item.key} key={'column-select' + index}>
          {item.title}
        </Select.Option>
      );
    });
  }, [columns]);

  const handleTableChange = (paginator) => {
    setPage(--paginator.current);
    setSize(paginator.pageSize);
  };

  const handleColumnsSelectChange = (value) => {
    let columnsForRender = columns.filter((item) => value.some((elem) => elem === item.key));
    setColumnsForRender(columnsForRender);
    setDisplayedColumns(value);
  };

  const onRow = (record) => {
    return {
      onDoubleClick: async () => {
        history.push(`/verifier-list/catalogs/org/${record.uuid}`);
      },
    };
  };

  return (
    <>
      <div className="container container--white">
        <Select
          className="ml-auto"
          mode="multiple"
          name="displayedColumns"
          maxTagCount={0}
          maxTagPlaceholder={'Колонки'}
          style={{width: '120px'}}
          placeholder="Колонки"
          value={displayedColumnsState}
          onChange={(value) => handleColumnsSelectChange(value)}
        >
          {columnsShowOptions}
        </Select>
        <Table
          dataSource={data}
          columns={columnsForRender}
          loading={loading}
          rowKey={(record) => record.uuid}
          onChange={handleTableChange}
          pagination={{
            total: totalElements,
            current: page + 1,
          }}
          onRow={onRow}
          scroll={{
            x: true,
          }}
        />
      </div>
    </>
  );
};

export default VerifyOrgList;
