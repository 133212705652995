import React, {ReactNode, useCallback, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {Button, Modal, Popover, Table} from 'ui-kit';
import {useHistory, useRouteMatch} from 'react-router';
import _, {isBoolean, isEmpty} from 'lodash';

import {
  setChangedDetailName,
  setChangedDetailField,
  setChangedDetailMode,
  setRecordLoading,
  setCurrentCatalog,
} from '../../redux/actions/actions';
import {SignDocModal} from '../SignDoc';
import {mdm} from '../../Utils/constant';
import RecordReader from './RecordReader';
import RecordEditor from './RecordEditor';
import FormInitiator from './FormInitator';
import Versions from '../Verisons';
import {backendDateFormat} from '../Helpers/Utils';
import VersionsByDateForm from './VersionByDateForm';
import SystemInformation from '../SystemInformation';
import {fetchFunc} from '../../Utils/security/http/mdm';
import {metaUnifier} from '../Helpers/RuleConfigurator';
import {warningModalCreate, showDeleteConfirm} from '../Helpers/Modals';
import {getFieldsMeta, normalizeValues, onError} from '../Helpers/Utils';
import {
  CheckCircleOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  SaveOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import {Typography} from 'antd';
import {getCatalog} from 'api';
import {RecordType} from 'types';
import styles from './RecordRedactor.module.scss';
import classNames from 'classnames';
import {ModalConfirm} from 'ui-kit/Modal/ModalConfirm';

export interface RecordRedactorProps {
  catalogUuid?: string;
  isEdit?: boolean;
  recordUuid: string;
  versionId?: string;
  type?: 'deleted' | 'info';
  detailUuid?: string;
  detailFieldUuid?: string;
  referenceOrigin?: any;
  referenceUuid?: string;
  mode?: 'create' | 'edit';
  onCompositeModalSubmit?: (value: any, type?: any) => void;
  closeModal?: () => void;
  isUnverified?: boolean;
  compositeInitialValue?: any;
  additionalMeta?: any;
  rootValues?: any;
  additionalFields?: any[];
  rootForm?: boolean;
  defaultRecord?: RecordType;
  fixedSubmitButton?: boolean;
  renderCustomTable?: string;
  structure?: boolean;
  submitButtonPosition?: boolean;
  maxRefsDepth?: number;
  versionKey?: string;
  setVersionKey?: (value: string) => void;
  setIsUnverified?: (value: boolean) => void;
  onRestore?: () => void;
  getCatalogItem?: () => void;
  isModal?: boolean;
  customEditModal?: any;
  isRestored?: boolean;
  initialValidateCatalog?: boolean;
  setIsRestored?: React.Dispatch<React.SetStateAction<boolean>>;
  setIsChanged?: React.Dispatch<React.SetStateAction<boolean>>;
  onRestoreCallback?: () => void;
  isCard?: boolean;
  fieldsMeta?: any[];
  canEdit?: string;
}

const RecordRedactor = ({
  catalogUuid,
  setIsChanged,
  isEdit = true,
  recordUuid,
  versionId,
  type,
  detailUuid,
  detailFieldUuid,
  referenceOrigin,
  referenceUuid,
  mode,
  onCompositeModalSubmit,
  closeModal,
  isUnverified,
  compositeInitialValue,
  additionalMeta,
  additionalFields,
  rootValues,
  rootForm,
  defaultRecord,
  fixedSubmitButton,
  submitButtonPosition,
  renderCustomTable,
  structure,
  maxRefsDepth,
  versionKey,
  onRestore,
  getCatalogItem,
  setVersionKey,
  setIsUnverified,
  isModal = false,
  initialValidateCatalog,
  isRestored,
  setIsRestored,
  onRestoreCallback,
  fieldsMeta,
  isCard = false,
  canEdit,
}: RecordRedactorProps) => {
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const history = useHistory();
  const [referenceOriginLocal, setReferenceOrigin] = useState<string | null>(null);
  const [refDepth, setRefDepth] = useState(maxRefsDepth || 1);
  const [fields, setFields] = useState([]);
  const [files, setFiles] = useState<any>({});
  const [data, setData] = useState<any>(defaultRecord || []);
  const [disabledRestore, setDisabledRestore] = useState(true);
  const [isActiveVersion, setActiveVersion] = useState(true);
  const [showVersionModal, setShowVersionModal] = useState(false);
  const [versionDate, setVersionDate] = useState<string | null>(null);
  const [editMode, setEditMode] = useState(mode === 'create');
  const [loading, setLoading] = useState(false);
  const [validateCatalog, setValidateCatalog] = useState(initialValidateCatalog || false);
  const [showSignModal, setShowSignModal] = useState(false);
  const [editSigns, setEditSigns] = useState<any>({});
  const [hiddenFields, setHiddenFields] = useState([]);
  const [ecmDocData, setEcmDocData] = useState({
    templateDocType: '',
    templateDocUuid: '',
    templateDocName: '',
  });
  const [filesForSign, setFilesForSign] = useState<{modal: boolean; files: any[]}>({
    modal: false,
    files: [],
  });
  const [deleteConfirmIsVisible, setDeleteConfirmIsVisible] = useState(false);

  const [formValues, setFormValues] = useState<Record<string, any>>({});

  const setCatalog = useCallback(async () => {
    if (catalogUuid) {
      const data = await getCatalog(catalogUuid);

      if (data) {
        if (data.options && data.options.max_refs_depth) {
          setRefDepth(data.options.max_refs_depth);
        }
        setReferenceOrigin(data.origin);
        setEditSigns({
          editAttributes: data.editAttributes,
          editCatalog: data.editCatalog,
          editProperties: data.editProperties,
          inputType: data.inputType,
        });
        if (data.validateRecords) {
          setValidateCatalog(true);
        }
      }
    }

    setLoading(false);
  }, [catalogUuid]);

  const getCatalogRecordFields = useCallback(async () => {
    try {
      let url = `/api/v1/catalogs/${referenceOriginLocal}/fields?size=1000`;
      if (detailUuid) {
        url = `/api/v1/catalogs/${referenceOriginLocal}/detail/${detailUuid}/fields?showDetails=0&showRefs=0&type=base&size=1000`;
      }
      const data = await fetchFunc({
        url,
        method: 'get',
      });
      if (detailUuid && defaultRecord) {
        data.content.forEach((field: any) => {
          if (field.fieldType.id === 12) {
            field.fieldType.id = 112;
          }
        });
      }
      if (additionalFields) {
        data.content.push(...additionalFields);
      }
      setFields(additionalMeta ? metaUnifier(data.content, additionalMeta) : data.content);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }, [additionalFields, additionalMeta, defaultRecord, detailUuid, referenceOriginLocal]);

  const getCatalogRecordItems = useCallback(async () => {
    try {
      if (
        !isEmpty(referenceOriginLocal) ||
        !isEmpty(recordUuid) ||
        !isEmpty(versionId) ||
        !isEmpty(detailFieldUuid) ||
        !isEmpty(versionId)
      ) {
        let url = `/api/v1/catalogs/${referenceOriginLocal}/items/${recordUuid}?showRefs=${refDepth}`;
        if (versionId) {
          url = `/api/v1/catalogs/${referenceOriginLocal}/items/${recordUuid}/history/${versionId}?showRefs=${refDepth}&showDetails=1`;
        } else if (detailUuid && mode === 'edit') {
          url = `/api/v1/catalogs/${referenceOriginLocal}/detail/${detailUuid}/${recordUuid}/items/${detailFieldUuid}${
            isUnverified ? '/unverified' : ''
          }?showRefs=${refDepth}`;
        } else if (detailUuid && mode === 'create') {
          return false;
        }
        const data = await fetchFunc({
          url,
          method: 'get',
        });
        setData(data);
        let isActive = data?.active;
        if (typeof data?.sysVerifyInfoDraft?.sourceId?.id !== 'undefined' && typeof data?.id !== 'undefined') {
          isActive = data?.id === data?.sysVerifyInfoDraft?.sourceId?.id;
        }
        if (typeof isActive !== 'undefined') {
          setActiveVersion(isActive);
        }
        setIsUnverified && setIsUnverified(!!data.sysVerifyInfoDraft);

        const {templateDocName, templateDocType, templateDocUuid} = data;
        setEcmDocData({
          templateDocType,
          templateDocUuid,
          templateDocName,
        });
        data.catalogId && dispatch(setCurrentCatalog(data.catalogId));
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  }, [
    detailFieldUuid,
    detailUuid,
    dispatch,
    isUnverified,
    mode,
    recordUuid,
    refDepth,
    referenceOriginLocal,
    versionId,
    setIsUnverified,
  ]);

  // const getCatalogRecordFiles = useCallback(async () => {
  //   try {
  //     const data = await fetchFunc({
  //       url: `/api/v1/catalogs/${referenceOriginLocal}/items/${recordUuid}/file`,
  //       method: 'get',
  //     });
  //     setFiles(data);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }, [recordUuid, referenceOriginLocal]);

  const saveRecord = async (newData: any) => {
    try {
      let url = `/api/v1/catalogs/${referenceOriginLocal}/items/${recordUuid}`;
      if (detailUuid) {
        url = `/api/v1/catalogs/${referenceOriginLocal}/detail/${detailUuid}/${recordUuid}/items/${detailFieldUuid}${
          isUnverified ? '/unverified' : ''
        }`;
      }
      const data = await fetchFunc(
        {
          url,
          method: 'put',
          data: {fields: newData},
        },
        onError
      );
      dispatch(setChangedDetailName(detailUuid));
      dispatch(setChangedDetailField(data));
      dispatch(setChangedDetailMode('edit'));
      if (onCompositeModalSubmit) {
        onCompositeModalSubmit(data);
      }
      if (closeModal) closeModal();
      dispatch(setRecordLoading(false));

      if (data.sysVerifyInfo && data.sysVerifyInfo.status && data.sysVerifyInfo.status !== 'approved') {
        const recordUuidFromPath = match.params && (match.params as any).id;
        if (referenceOriginLocal === 'org') {
          history.push(`/catalog/organisations/${recordUuidFromPath || recordUuid}/verify`);
        } else {
          const pathname = history.location.pathname;
          const url = `/catalog/${referenceOriginLocal}/${recordUuidFromPath || recordUuid}/verify`;

          if (pathname.includes(url)) {
            getCatalogItem && getCatalogItem();
          } else {
            history.push(url);
          }
        }
      } else {
        setData(data);
        setEditMode(false);
        getCatalogItem && getCatalogItem();
      }
    } catch (error) {
      dispatch(setRecordLoading(false));
    }
  };

  const createRecord = async (newData: any) => {
    try {
      let url = `/api/v1/catalogs/${referenceOriginLocal}/items`;
      if (detailUuid) {
        url = `/api/v1/catalogs/${referenceOriginLocal}/detail/${detailUuid}/${recordUuid}/items${
          isUnverified ? '/unverified' : ''
        }`;
      }
      const data = await fetchFunc(
        {
          url,
          method: 'post',
          data: {fields: newData},
        },
        onError
      );
      dispatch(setChangedDetailName(detailUuid));
      dispatch(setChangedDetailField(data));
      dispatch(setChangedDetailMode('create'));
      if (onCompositeModalSubmit) {
        onCompositeModalSubmit(data);
        if (closeModal) {
          closeModal();
        }
      }
      if (detailUuid && mode !== 'create') {
        await getCatalogRecordFields();
        await getCatalogRecordItems();
      }
      if (data.sysVerifyInfo && data.sysVerifyInfo.status && data.sysVerifyInfo.status !== 'approved') {
        const recordUuidFromPath = match.params && (match.params as any).id;
        if (referenceOriginLocal === 'org') {
          history.push(`/catalog/organisations/${detailUuid ? recordUuidFromPath || recordUuid : data.uuid}/verify`);
        } else {
          const pathname = history.location.pathname;
          const url = `/catalog/${referenceOriginLocal}/${detailUuid ? recordUuidFromPath || recordUuid : data.uuid}/verify`;
          if (pathname.includes(url)) {
            getCatalogItem && getCatalogItem();
          } else {
            history.push(url);
          }
        }
      } else if (getCatalogItem) {
        getCatalogItem();
      }
      closeModal && closeModal();
      dispatch(setRecordLoading(false));
    } catch (error) {
      dispatch(setRecordLoading(false));
    }
  };

  const deleteRecord = async () => {
    try {
      let url = `/api/v1/catalogs/${referenceOriginLocal}/items/${recordUuid}`;
      if (detailUuid) {
        url = `/api/v1/catalogs/${referenceOriginLocal}/detail/${detailUuid}/${recordUuid}/items/${detailFieldUuid}${
          isUnverified ? '/unverified' : ''
        }`;
      }
      await fetchFunc(
        {
          url,
          method: 'delete',
        },
        onError
      );
      dispatch(setChangedDetailName(detailUuid));
      dispatch(setChangedDetailField(data));
      dispatch(setChangedDetailMode('delete'));
      if (onCompositeModalSubmit) {
        onCompositeModalSubmit(data, 'delete');
        dispatch(setChangedDetailName(detailUuid));
        if (closeModal) {
          closeModal();
        }
      }
      if (validateCatalog) {
        if (referenceOriginLocal === 'org') {
          history.push(`/catalog/organisations/${recordUuid}/verify`);
        } else {
          history.push(`/catalog/${referenceOriginLocal}/${recordUuid}/verify`);
        }
      } else if (detailUuid) {
        await getCatalogRecordFields();
        if (getCatalogItem) {
          getCatalogItem();
        }
        if (closeModal) {
          closeModal();
        }
      } else {
        history.push(`/catalog/${referenceOriginLocal}`);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const restoreRecord = async () => {
    setLoading(true);
    const urlRollBack = `/api/v1/catalogs/${catalogUuid}/item/${recordUuid}/history/${versionId}/undo`;
    try {
      const data = await fetchFunc(
        {
          url:
            type === 'deleted'
              ? `/api/v1/catalogs/${catalogUuid}/items/${recordUuid}/history/${versionId}/restore`
              : urlRollBack,
          method: 'post',
        },
        onError,
        'mdm'
      );

      if (versionKey === 'version') {
        setData(data);
        closeModal && closeModal();
        !data.sysVerifyInfo && onRestore && onRestore();
      }

      if (data.sysVerifyInfo && data.sysVerifyInfo.status && data.sysVerifyInfo.status === 'draft') {
        if (referenceOriginLocal === 'org') {
          history.push(`/catalog/organisations/${data.uuid}/verify`);
        } else {
          history.push(`/catalog/${referenceOriginLocal}/${data.uuid}/verify`);
        }
      } else if (onRestore && type === 'deleted') {
        !data.sysVerifyInfo && onRestore();
      } else {
        history.push(`/catalog/${catalogUuid}/${recordUuid}`);
      }
      if (onRestoreCallback) {
        await onRestoreCallback();
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const changeRecord = async (values: RecordType) => {
    let formValues = values;

    if (renderCustomTable) formValues = {...formValues, ...ecmDocData};
    formValues = await normalizeValues({values: formValues, fields, type: 'recordRedactor', key: Math.random()});
    dispatch(setRecordLoading(true));
    if (mode === 'create') {
      createRecord(formValues).then();
    } else {
      saveRecord(formValues).then(() => setIsChanged && setIsChanged((isChanged) => !isChanged));
    }
  };

  const handleEditMode = () => {
    const editableFlags = fields.map(
      (item: any) =>
        _.isNull(item.options) ||
        (item.options &&
          (item.options.gui_editable ||
            _.isNull(item.options.gui_editable) ||
            _.isUndefined(item.options.gui_editable))) ||
        item?.accessReadOnly
    );
    if (editableFlags.some((item) => _.isUndefined(item) || _.isNull(item) || item === true)) {
      setEditMode(true);
    } else {
      warningModalCreate('', 'Отсутствуют поля, доступные для редактирования. Обратитесь к администратору');
    }
  };

  const findVersionsByDate = (values: any) => {
    setVersionDate(values.date && values.date.format(backendDateFormat));
    setShowVersionModal(true);
  };

  const onUpdateDataForm = (values: Record<string, any>) => {
    setFormValues(values);
  };

  const onSaveForm = (values: RecordType) => {
    const formValues = values;

    if (hiddenFields.length) hiddenFields.forEach((item) => (formValues[item] = ''));
    const formLength = Object.keys(formValues).length;
    if (formLength) {
      changeRecord(formValues);
    } else {
      setEditMode(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    referenceOrigin && setReferenceOrigin(referenceOrigin);
    catalogUuid && setCatalog();
  }, [catalogUuid, setCatalog, recordUuid, referenceOrigin]);

  useEffect(() => {
    if (referenceOriginLocal) {
      getCatalogRecordFields().then();
      recordUuid && !defaultRecord && getCatalogRecordItems();
      // if (!isUnverified && !detailUuid && !versionId) recordUuid && getCatalogRecordFiles();
    }
  }, [
    referenceOriginLocal,
    defaultRecord,
    versionId,
    isUnverified,
    refDepth,
    getCatalogRecordFields,
    recordUuid,
    getCatalogRecordItems,
    detailUuid,
    isRestored,
  ]);

  useEffect(() => {
    setDisabledRestore(isEmpty(data) || !!data?.sysVerifyInfoDraft);
  }, [data]);

  const totalFormBottons = () => (
    <>
      {/* MDM-2157 */}
      {/* {!detailUuid && !editMode && (
        <Button
          onClick={() => {
            if (files && files.content && files.content.length)
              setFilesForSign({
                ...filesForSign,
                modal: true,
              });
            else setShowSignModal(true);
          }}
          icon={<CheckCircleOutlined />}
          type="default"
        >
          Подписать
        </Button>
      )} */}
      <Button
        type="primary"
        danger
        ghost
        onClick={() =>
          showDeleteConfirm({
            content: 'Вы действительно хотите удалить запись справочника?',
            onOk: () => deleteRecord(),
            okText: mdm.confirm,
            cancelText: mdm.cancel,
          })
        }
        icon={<DeleteOutlined />}
      >
        {mdm.delete}
      </Button>
    </>
  );

  const formFooter = (disabled: boolean) => {
    return (
      ((detailUuid && isEdit) || (editSigns?.editCatalog && editSigns?.inputType !== 'external')) && (
        <div className="d-flex gap-8">
          {!data?.sysVerifyInfoDraft && (
            <>
              <Button htmlType="submit" disabled={disabled} icon={<SaveOutlined />} type="primary">
                Сохранить
              </Button>
              <Button onClick={() => setEditMode(false)} icon={<CloseOutlined />}>
                Отменить
              </Button>
              {totalFormBottons()}
            </>
          )}
        </div>
      )
    );
  };

  return (
    <div
      // loading={loading} bordered={false}
      className="m-0 p-0 d-flex flex-column gap-24"
    >
      {!detailUuid && mode !== 'create' && data && referenceOriginLocal && (
        <SystemInformation
          data={data}
          referenceOriginLocal={referenceOriginLocal}
          recordUuid={recordUuid}
          validateCatalog={validateCatalog}
        />
      )}
      {!versionId && !detailUuid && mode !== 'create' && referenceOriginLocal !== 'org' && (
        <VersionsByDateForm onFinish={findVersionsByDate} />
      )}

      {data && fields && !editMode ? (
        <div className="modal--attributes">
          <Typography.Title level={5}>Атрибуты</Typography.Title>
          <RecordReader
            isRestored={isRestored}
            fieldsMeta={fieldsMeta || fields}
            data={data}
            referenceOrigin={referenceOriginLocal}
            referenceUuid={referenceUuid}
            recordUuid={!isEmpty(recordUuid) ? recordUuid : undefined}
            detailFieldUuid={detailFieldUuid}
            mode={mode}
            detailUuid={detailUuid}
            rootValues={rootValues}
            maxRefsDepth={refDepth}
            renderCustomTable={renderCustomTable}
            structure={structure}
            isUnverified={isUnverified}
            versionId={versionId}
            twoColumnsMode={true}
          />
        </div>
      ) : (
        <FormInitiator
          className={classNames(styles.recordForm, {[styles.card]: !isModal})}
          formName={'record-form'}
          hiddenFields={hiddenFields}
          fieldsMeta={fields}
          data={mode === 'create' && compositeInitialValue ? compositeInitialValue : data}
          changeRecord={!versionId && mode !== 'create' ? onSaveForm : changeRecord}
          fixedSubmitButton={fixedSubmitButton}
          submitButtonPosition={submitButtonPosition}
          children={(form: any) => (
            <RecordEditor
              form={form}
              validateCatalog={validateCatalog}
              fieldsMeta={getFieldsMeta({
                columns: fields,
                rule: renderCustomTable,
              })}
              data={data}
              mode={mode}
              isEdit={isBoolean(isEdit) ? isEdit : editMode}
              referenceOrigin={referenceOriginLocal}
              recordUuid={recordUuid}
              detailUuid={detailUuid}
              detailFieldUuid={detailFieldUuid}
              setHiddenFields={setHiddenFields}
              hiddenFields={hiddenFields}
              rootValues={rootValues}
              rootForm={rootForm}
              maxRefsDepth={refDepth}
              renderCustomTable={renderCustomTable}
              ecmDocData={ecmDocData}
              setEcmDocData={setEcmDocData}
              structure={structure}
              isUnverified={isUnverified}
              versionId={versionId}
              isModal={isModal}
              onUpdateDataForm={onUpdateDataForm}
            />
          )}
          footer={!versionId && mode !== 'create' && formFooter}
          structure={undefined}
          setTabKey={undefined}
          mode={mode}
          onCancel={undefined}
          onChangeFormData={onUpdateDataForm}
        />
      )}

      {(canEdit || detailUuid) &&
        !versionId &&
        mode !== 'create' &&
        ((detailUuid && isEdit) || (editSigns?.editCatalog && editSigns?.inputType !== 'external')) &&
        !editMode && (
          <div className="d-flex gap-8">
            {!data?.sysVerifyInfoDraft && (
              <>
                <Button type="primary" onClick={() => handleEditMode()} icon={<EditOutlined />}>
                  Изменить
                </Button>
                {totalFormBottons()}
              </>
            )}
          </div>
        )}

      {(type === 'deleted' || (versionKey === 'version' && !isActiveVersion)) && (
        <div className="d-flex mt-4 justify-content-end align-items-center">
          {disabledRestore && (
            <Popover content="Запись уже находится на верификации">
              <ExclamationCircleOutlined className="exclamationIcon mr-2" />
            </Popover>
          )}
          <Button
            loading={loading}
            disabled={disabledRestore}
            type="primary"
            onClick={restoreRecord}
            icon={<SyncOutlined />}
          >
            Восстановить
          </Button>
        </div>
      )}
      <Modal
        title={'Просмотр версий на дату'}
        open={showVersionModal}
        footer={null}
        onCancel={() => setShowVersionModal(false)}
        destroyOnClose={true}
        width={800}
        className={styles.modalVersion}
      >
        <Versions
          catalogUuid={catalogUuid}
          recordUuid={recordUuid}
          versionDate={versionDate}
          setVersionKey={setVersionKey}
          versionKey={versionKey}
          modal={true}
          type={'info'}
          setIsRestored={setIsRestored}
          setShowVersionModal={setShowVersionModal}
          onRestoreCallback={getCatalogRecordItems}
        />
      </Modal>
      <SignDocModal
        showSignModal={showSignModal}
        setShowSignModal={setShowSignModal}
        mainFetchProps={{docUuid: recordUuid, docTypeIdentifier: catalogUuid}}
        filesForSign={filesForSign.files}
        fileUuid={undefined}
      />
      <Modal
        title="Выберите файлы для подписания"
        open={filesForSign.modal}
        width={700}
        onOk={async () => {
          setShowSignModal(true);
          setFilesForSign({
            ...filesForSign,
            modal: false,
          });
        }}
        onCancel={() =>
          setFilesForSign({
            ...filesForSign,
            modal: false,
          })
        }
      >
        <Table
          columns={[
            {
              title: 'Наименование',
              dataIndex: 'name',
              sorter: (a, b) => {
                const nameA = a.name.toLowerCase(),
                  nameB = b.name.toLowerCase();
                if (nameA < nameB) return -1;
                if (nameA > nameB) return 1;
                return 0;
              },
            },
            {
              title: 'Метки',
              dataIndex: 'labels',
              render: (text) => <span>{text && text.join(', ')}</span>,
            },
            {
              title: 'Дата загрузки',
              dataIndex: 'createDate',
              sorter: (a: any, b: any) => a.date - b.date,
            },
          ]}
          dataSource={files?.content}
          rowKey={(record: any) => record.uuid}
          rowSelection={{
            onChange: (selectedRowKeys) => {
              setFilesForSign({
                ...filesForSign,
                files: [...selectedRowKeys],
              });
            },
          }}
          pagination={{
            total: files?.totalElements,
          }}
        />
      </Modal>
    </div>
  );
};

export default React.memo(RecordRedactor);
