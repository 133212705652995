import React, {useState, useEffect} from 'react';
import {Modal, Button, Card, Table, Checkbox, Input, Tooltip, Alert} from 'ui-kit';
import {DeleteOutlined, UnlockOutlined} from '@ant-design/icons';
import AccessRights from '../../AccessRights';
import useCRUD from '../../../hooks/CRUD.hook';
import {showDeleteConfirm} from '../../Helpers/Modals';

const StatusList = ({
  store,
  readOnly,
  columns,
  AddForm,
  cardTitle,
  isFilter,
  modalWidth,
  onCreateError,
  onDeleteError,
}) => {
  const [columnsForRender, setColumnsForRender] = useState(columns);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const [modalVisible, setModalVisible] = useState(false);
  const [accessRightsModal, showAccessRightsModal] = useState(false);
  const [editableItemId, setEditableItemId] = useState(null);
  const [filterCaption, setFilterCaption] = useState('');
  const [filterIsActive, setFilterIsActive] = useState(true);

  const {getData, changeData, deleteData, getItem, clearRecord, record, data, loading, total} = useCRUD({
    apiUrl: `/api/v1/${store}`,
    idField: 'id',
    onCreateError,
    onDeleteError,
  });

  useEffect(() => {
    if (!readOnly) {
      setColumnsForRender([
        ...columns,
        {
          title: '',
          key: 'action',
          render: (text, record, index) => {
            return (
              <div className="text-right">
                {store === 'status-matrix' && (
                  <Tooltip title="Настройка доступа">
                    <UnlockOutlined onClick={() => editAccessRights(record.id)} />
                  </Tooltip>
                )}
                <DeleteOutlined
                  className="ml-2"
                  onClick={() =>
                    showDeleteConfirm({
                      content: 'Вы действительно хотите удалить?',
                      onOk: () => deleteStatus(record.id),
                    })
                  }
                />
              </div>
            );
          },
        },
      ]);
    }
  }, [readOnly, total, page]);

  useEffect(() => {
    getData({
      page,
      size,
      params: {
        active: filterIsActive,
        caption: filterCaption,
      },
    });
  }, [page, size, filterCaption, filterIsActive]);

  const handleTableChange = ({current, pageSize}) => {
    setPage(current - 1);
    setSize(pageSize);
  };

  const editAccessRights = async (id) => {
    showAccessRightsModal(true);
    setEditableItemId(id);
  };

  const changeStatus = async (values) => {
    try {
      await changeData(values, record);
      await getData({
        page,
        size,
        params: {
          active: filterIsActive,
          caption: filterCaption,
        },
      });
      setModalVisible(false);
    } catch (error) {}
  };

  const deleteStatus = async (id) => {
    try {
      await deleteData(id);
      if (total % 10 === 1) {
        setPage(page - 1);
      } else {
        await getData({
          page,
          size,
          params: {
            active: filterIsActive,
            caption: filterCaption,
          },
        });
      }
    } catch (error) {}
  };

  const onRow = (record) => {
    return {
      onDoubleClick: async () => {
        try {
          await getItem(record.id);
          setModalVisible(true);
        } catch (error) {}
      },
    };
  };

  const renderStatusListFilter = () => {
    return (
      <div className="status-filter mb-3 mt-3">
        <div className="status-filter__item d-flex align-items-center mb-3">
          <div className="status-filter__label col-2">Активные</div>
          <div className="status-filter__value col">
            <Checkbox
              name="active"
              onChange={(e) => {
                setPage(0);
                setFilterIsActive(e.target.checked);
              }}
              checked={filterIsActive}
            />
          </div>
        </div>
        <div className="status-filter__item d-flex align-items-center">
          <div className="status-filter__label col-2">Наименование</div>
          <div className="status-filter__value col">
            <Input name="caption" onChange={(e) => setFilterCaption(e.target.value)} value={filterCaption} />
          </div>
        </div>
      </div>
    );
  };

  return (
    <Card title={cardTitle} bordered={false}>
      {isFilter && renderStatusListFilter()}
      <Table
        columns={columnsForRender}
        dataSource={data}
        loading={loading}
        rowKey={(record) => record.id}
        onChange={handleTableChange}
        pagination={{
          total: total,
          current: page + 1,
        }}
        onRow={readOnly ? null : onRow}
        footer={() =>
          readOnly ? (
            ''
          ) : (
            <div className="text-right">
              <Button
                onClick={() => {
                  clearRecord();
                  setModalVisible(true);
                }}
                type="primary"
              >
                Добавить
              </Button>
            </div>
          )
        }
      />
      {!readOnly && (
        <Modal
          title={record ? 'Редактирование' : 'Создание'}
          open={modalVisible}
          footer={null}
          width={modalWidth || 700}
          onCancel={() => setModalVisible(false)}
          destroyOnClose
        >
          <AddForm editibleItem={record} onSubmit={changeStatus} onReset={() => setModalVisible(false)} />
        </Modal>
      )}
      <Modal
        title={'Права доступа'}
        open={accessRightsModal}
        footer={null}
        width={modalWidth || 700}
        onCancel={() => showAccessRightsModal(false)}
        destroyOnClose
      >
        <div>
          <Alert
            className="mb-3"
            message={
              <div>
                Если для статуса установлен признак "Нередактируемый", то указанные ниже пользователи (роли) имеют
                возможность изменять записи на статусе, при условии что для этих пользователей (ролей) настроен полный
                доступ к записям справочника в МДМ "НСИ".
                <div className="mt-3">
                  Настройка не распространяется на статусы, для которых снят признак "Нередактируемый"
                </div>
              </div>
            }
            type="info"
            showIcon
          />
          <AccessRights store="status-matrix" rulesRecordUuid={editableItemId} />
        </div>
      </Modal>
    </Card>
  );
};

export default StatusList;
