import React, {ReactElement, useMemo} from 'react';

import {ColumnsType, Table, TableProps} from 'ui-kit/Table/Table';
import {Skeleton} from 'antd/lib';
// ignore because it's UI-Kit component
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const SkeletonTable = (props: TableProps<any>): ReactElement => {
  // ignore because it's UI-Kit component
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const skeletonColumns: ColumnsType<any> = useMemo(() => {
    const array = Array.from({length: 5}, (_, index) => index + 1);

    const renderField = () => {
      return <Skeleton.Button active />;
    };

    return array.map((el) => ({
      key: el,
      render: renderField,
      title: <Skeleton.Button active />,
      width: '20%',
    }));
  }, []);

  const data = useMemo(() => {
    const array = Array.from({length: 5}, (_, index) => index + 1);
    return array.map(() => {
      return {};
    });
  }, []);
  if (!props.loading) {
    return <>{props.children}</>;
  }

  return <Table columns={skeletonColumns} dataSource={data} />;
};
