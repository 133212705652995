/** Проверка на добавление условия  при валидации */
export const getValidationRules = (validator) => {
  const value =
    Number(validator?.validator_Length) || validator?.validator_Length === 0 ? validator?.validator_Length : false;
  if (validator?.validator_operator_Length === 'eq') {
    return [
      {
        min: value,
        message: `Значение по умолчанию должно быть равно ${validator?.validator_Length} символов`,
      },
      {
        max: value,
        message: `Значение по умолчанию должно быть равно ${validator?.validator_Length} символов`,
      },
    ];
  } else {
    return [
      {
        max: value,
        message: `Значение по умолчанию должно быть не больше ${validator?.validator_Length} символов`,
      },
    ];
  }
};
