import React, {useEffect, useState} from 'react';
import {Form, Input, Button, Spin, Table} from 'ui-kit';
import {forIn, isNull} from 'lodash';

import DynamicSelect from '../../components/FormElements/DynamicSelect';
import {fetchFunc} from '../../Utils/security/http/mdm';
import {DeleteOutlined} from '@ant-design/icons';
import {layout} from '../../components/Helpers/constants';

const validateMessages = {
  required: 'Поле обязательно для заполнения!',
};

const CreateLinkTypeForm = ({editableItem, onSubmit, onCancel}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);
  const [additionalTableData, setAdditionalTableData] = useState([]);
  const columns = [
    {
      title: 'caption',
      key: 'caption',
      render: (text, record, index) => renderTableRow('caption', index),
    },
    {
      title: 'name',
      key: 'name',
      render: (text, record, index) => renderTableRow('name', index),
    },
    {
      title: 'type',
      key: 'type',
      render: (text, record, index) => renderTableRow('type', index),
    },
    {
      key: 'del',
      render: (text, record, index) => <DeleteOutlined onClick={() => deleteTableRow(index)} />,
    },
  ];

  const renderTableRow = (fieldName, index) => {
    return (
      <Form.Item name={`table_${fieldName}-${index}`} rules={[{required: true}]}>
        <Input name={fieldName} onChange={(e) => handleTableRowChange(e, index)} />
      </Form.Item>
    );
  };

  useEffect(() => {
    editableItem && getEditibleItem();
  }, [editableItem]);

  const getEditibleItem = async () => {
    try {
      const data = await fetchFunc({
        url: `/api/v1/link-type/${editableItem.id}`,
      });
      const {normalizedData, formValues} = getInitialValues(data);
      await form.setFieldsValue({...normalizedData, ...formValues});
      setAdditionalTableData(normalizedData.additionalAttributes || []);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getInitialValues = (data) => {
    let normalizedData = {...data};
    if (!isNull(normalizedData.availableForCatalogs)) {
      normalizedData.availableForCatalogs = normalizedData.availableForCatalogs.map((item) => item.catalogId.uuid);
    } else normalizedData.availableForCatalogs = [];
    if (normalizedData.symmetricLinkTypeId) {
      normalizedData.symmetricLinkTypeId = normalizedData.symmetricLinkTypeId.id;
    }
    let formValues = {};
    normalizedData.additionalAttributes &&
      normalizedData.additionalAttributes.forEach((item, index) => {
        formValues = {
          ...formValues,
          [`table_caption-${index}`]: item.caption,
          [`table_name-${index}`]: item.name,
          [`table_type-${index}`]: item.type,
        };
      });
    return {
      normalizedData,
      formValues,
    };
  };

  const handleSelectChange = (value, name) => {
    form.setFieldsValue({[name]: value});
  };

  const onFinish = (values) => {
    let formattedValues = {};
    forIn(values, (value, key) => {
      if (key.split('_')[0] !== 'table') formattedValues[key] = value;
    });
    formattedValues.additionalAttributes = additionalTableData;
    formattedValues.availableForCatalogs = formattedValues.availableForCatalogs.map((item) => ({catalogUuid: item}));
    formattedValues.subsystem = 'mdm';
    onSubmit(formattedValues, editableItem);
  };

  const addTableRow = () => {
    const rows = [...additionalTableData];
    rows.push({caption: '', name: '', type: ''});
    form.setFieldsValue({
      [`table_caption-${rows.length - 1}`]: '',
      [`table_name-${rows.length - 1}`]: '',
      [`table_type-${rows.length - 1}`]: '',
    });
    setAdditionalTableData(rows);
  };

  const deleteTableRow = (index) => {
    const rows = [...additionalTableData];
    if (rows.length === index + 1) {
      form.resetFields([`table_caption-${index}`, `table_name-${index}`, `table_type-${index}`]);
    } else {
      for (let i = index + 1; i < rows.length; i++) {
        const val = form.getFieldsValue([`table_caption-${i}`, `table_name-${i}`, `table_type-${i}`]);
        form.setFieldsValue({
          [`table_caption-${i - 1}`]: val[`table_caption-${i}`],
          [`table_name-${i - 1}`]: val[`table_name-${i}`],
          [`table_type-${i - 1}`]: val[`table_type-${i}`],
        });
      }
    }
    rows.splice(index, 1);
    setAdditionalTableData(rows);
  };

  const handleTableRowChange = ({target: {value, name}}, index) => {
    const rows = [...additionalTableData];
    rows[index][name] = value;
    form.setFieldsValue({
      [`table_${name}-${index}`]: value,
    });
    setAdditionalTableData(rows);
  };

  return (
    <>
      {loading && editableItem ? (
        <div className="d-flex justify-content-center">
          <Spin />
        </div>
      ) : (
        <Form name="LinkTypeForm" form={form} onFinish={onFinish} {...layout} validateMessages={validateMessages}>
          {editableItem && (
            <>
              <Form.Item name="id" label="id">
                <Input disabled />
              </Form.Item>
              <Form.Item name="subsystem" label="Подсистема">
                <Input disabled />
              </Form.Item>
            </>
          )}
          <Form.Item label="Наименование типа кросс-связи" name="caption" rules={[{required: true}]}>
            <Input />
          </Form.Item>
          <Form.Item label="Симметричный тип кросс-связи" name="symmetricLinkTypeId">
            <DynamicSelect
              style={{width: '100%'}}
              allowClear={true}
              placeholder="Выбрать"
              notFoundContentPlaceholder="Введите запрос"
              dropdownClassName="form-item__dropdown"
              feachoptions={{
                url: '/api/v1/link-type',
                inComeOptionUrl: '/api/v1/link-type',
                method: 'get',
                displayedField: 'caption',
                valueField: 'id',
                valueSearchName: 'caption',
                defaultSize: 20,
              }}
              handleSelectChange={(value) => handleSelectChange(value, 'symmetricLinkTypeId')}
            />
          </Form.Item>
          <Form.Item label="Доступен для справочников" name="availableForCatalogs" rules={[{required: true}]}>
            <DynamicSelect
              style={{width: '100%'}}
              placeholder="Выбрать"
              notFoundContentPlaceholder="Введите запрос"
              mode="multiple"
              dropdownClassName="form-item__dropdown"
              feachoptions={{
                url: '/api/v1/catalogs',
                inComeOptionUrl: '/api/v1/catalogs',
                method: 'get',
                displayedField: 'caption',
                valueSearchName: 'search',
                defaultSize: 20,
              }}
              handleSelectChange={(value) => handleSelectChange(value, 'availableForCatalogs')}
            />
          </Form.Item>
          <Table
            dataSource={[...additionalTableData]}
            columns={[...columns]}
            footer={() => (
              <div>
                <Button onClick={addTableRow}>Добавить</Button>
              </div>
            )}
            pagination={null}
          />
          <div className="d-flex mt-3 justify-content-end">
            <Button type="primary" className="mr-1" htmlType="submit">
              {editableItem ? 'Обновить' : 'Создать'}
            </Button>
            <Button onClick={onCancel}>Отмена</Button>
          </div>
        </Form>
      )}
    </>
  );
};

export default CreateLinkTypeForm;
