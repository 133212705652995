import {convertToJson} from './Utils';
import {searchProcessRule, processRule} from './RuleConfigurator';
import {isArray, isString} from 'lodash';

export const structureElementManipulations = (meta, newStructure, data) => {
  meta.forEach(async (item) => {
    if (item.options && item.options.rules) {
      let rulesObj = item.options.rules;
      if (isString(rulesObj)) rulesObj = await convertToJson(item.options.rules);
      if (rulesObj.location) {
        const location = isArray(rulesObj.location) ? searchProcessRule(rulesObj.location, data) : rulesObj.location;
        const ruleFlag = processRule(location.rule, data);
        if (ruleFlag) {
          structureElementDeleter(newStructure[rulesObj.location.tab], item.origin);
          structureElementCreator(newStructure[rulesObj.location.tab], item.origin, rulesObj.location);
        }
      }
    }
  });
};

export const structureElementDeleter = (structureItems, origin) => {
  structureItems &&
    structureItems.forEach((item) => {
      if (item.items && item.items.length) structureElementDeleter(item.items, origin);
      else if (item.origin === origin) item.origin = 'deleted_item';
    });
};

export const structureElementCreator = (structureItems, origin, {block, line, greed, order}) => {
  structureItems &&
    structureItems.forEach((item) => {
      if (item.name === block) {
        if (line) {
          const lineForInsert = item.items.find((item) => item.name === `${block}__line-${line}`);
          if (lineForInsert) {
            if (order) {
              lineForInsert.items.splice(order, 0, {origin, type: 'item', greed});
            } else {
              lineForInsert.items.push({origin, type: 'item', greed});
            }
          }
        } else {
          item.items.push({origin, type: 'item', greed});
        }
      } else {
        if (item.items && item.items.length) {
          structureElementCreator(item.items, origin, {block, line, greed, order});
        }
      }
    });
};
