import React from 'react';
import {Form, Input, Button, InputNumber, Popover} from 'antd';
import {DeleteOutlined, PlusOutlined} from '@ant-design/icons';
// import PhoneNumber from '../FormElements/PhoneNumber/PhoneNumber';
import {setExclamation, truncationToNumberByType, stepToNumberByType} from '../Helpers/Utils';
import styles from './RenderValuesArrayField.module.scss';
import cn from 'classnames';
import {Typography} from 'ui-kit';

const RenderValuesArrayField = (item, cnf, createItemRules, form) => {
  const {
    fieldType: {id},
  } = item;
  const isDisabled = item?.options?.gui_editable !== true || item?.accessReadOnly;

  const renderField = () => {
    switch (id) {
      case 23:
      case 24:
        return (
          <InputNumber
            style={{width: '100%'}}
            disabled={isDisabled}
            decimalSeparator={'.'}
            step={stepToNumberByType('0.00', id, 24, true)}
            parser={(value) => truncationToNumberByType(value, id, 24, false)}
          />
        );
      default:
        //  MDM-2125
        // if (item.options && item.options.phone_mask) {
        //   return <PhoneNumber disabled={item?.options?.gui_editable !== true || item?.accessReadOnly} />;
        // }
        return <Input disabled={item?.options?.gui_editable !== true || item?.accessReadOnly} />;
    }
  };

  const fieldTitleRender = item.description ? (
    <Popover content={item.description} placement="topLeft">
      <div className="d-flex align-items-center gap-8">
        {<Typography.Text>{item?.caption}</Typography.Text>}
        {setExclamation(item)}
      </div>
    </Popover>
  ) : (
    <div className="d-flex align-items-center gap-8">
      {<Typography.Text>{item?.caption}</Typography.Text>}
      {setExclamation(item)}
    </div>
  );

  const itemRules = createItemRules(cnf, item.required, item.fieldType.id);
  return (
    <Form.List name={item.origin}>
      {(fields, {add, remove}, {errors}) => {
        const allowedToAddValue = item?.options?.gui_editable;
        const isRequired = itemRules.find((rule) => rule?.required);

        const onRemoveField = (name) => {
          if (fields.length === 2 && !isRequired) {
            const firstItemValue = form.getFieldValue(item.origin)[0];
            if (!firstItemValue && form) {
              form.resetFields([item.origin]);
              form.setFieldValue(item.origin, [null]);
            }
          }
          remove(name);
        };

        return (
          <>
            {fields.map((field, index) => {
              const message =
                index === 0
                  ? 'Необходимо заполнить значение массива'
                  : 'Необходимо удалить или заполнить значение массива';

              const requiredRule = {
                required: fields.length > 1,
                message,
              };

              const rules = isRequired ? itemRules : [...itemRules, requiredRule];

              return (
                <div key={field.key} className={styles.itemField}>
                  <Form.Item
                    {...field}
                    required={isRequired}
                    rules={rules}
                    label={index === 0 ? fieldTitleRender : ''}
                    className={cn([styles.formItem], {'w-100': index === 0})}
                  >
                    {renderField()}
                  </Form.Item>
                  {index !== 0 ? (
                    <Button
                      icon={<DeleteOutlined />}
                      onClick={() => onRemoveField(field.name)}
                      disabled={isDisabled}
                      className={styles.deleteIcon}
                      type="text"
                    />
                  ) : null}
                </div>
              );
            })}
            <Form.Item>
              <Button type="link" onClick={() => add()} icon={<PlusOutlined />} disabled={!allowedToAddValue}>
                Добавить значение
              </Button>
              <Form.ErrorList errors={errors} />
            </Form.Item>
          </>
        );
      }}
    </Form.List>
  );
};

export default RenderValuesArrayField;
