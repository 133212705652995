import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {Modal, Select} from 'ui-kit';
import {isEmpty} from 'lodash';
import {CryptoPro} from 'ruscryptojs';
import {errorModalCreate} from '../Helpers/Modals';
import {sendSignData, getSignDigest} from './effects/actions';
import {Form} from 'ui-kit';
import {CheckCircleOutlined, CloseCircleOutlined} from '@ant-design/icons';

export interface SignDocModalProps {
  mainFetchProps: any;
  showSignModal: boolean;
  setShowSignModal: (value: boolean) => void;
  filesForSign: any[];
  fileUuid?: string;
}

export const SignDocModal: React.FC<SignDocModalProps> = ({
  mainFetchProps,
  showSignModal,
  setShowSignModal,
  filesForSign,
  fileUuid,
}) => {
  const dispatch = useDispatch();
  const [certsList, setCertsList] = useState<any[]>([]);
  const [thumbprint, setThumbprint] = useState('');

  useEffect(() => {
    showSignModal && singDocumentGetCert();
  }, [showSignModal]);

  const singDocumentGetCert = () => {
    const cL: any[] = [];
    const cryptopro = new CryptoPro();

    cryptopro.init().then(
      function (info) {
        cryptopro.listCertificates().then(
          function (list) {
            list.forEach(function (cert) {
              cL.push({label: cert.name, value: cert.id});
            });
            setCertsList([...cL]);
          },
          function (error) {
            errorModalCreate(error);
          }
        );
      },
      function (error) {
        errorModalCreate(error);
      }
    );
  };

  const singDocument = (hash: string, uuid: string) => {
    const cryptopro = new CryptoPro();

    cryptopro.init().then(
      function (info) {
        cryptopro.signData(hash, thumbprint).then(
          function (signature) {
            dispatch(sendSignData(mainFetchProps, uuid, signature.replace(/\r?\n/g, ''), hash, fileUuid));
            setShowSignModal(false);
          },
          function (error) {
            errorModalCreate(error.message);
            setShowSignModal(false);
          }
        );
      },
      function (error) {
        errorModalCreate(error.message);
        setShowSignModal(false);
      }
    );
  };

  const onOk = async () => {
    const data = await getSignDigest(mainFetchProps, fileUuid as any, filesForSign as any);
    singDocument(fileUuid ? data.hash : data.digest, data.uuid);
    setThumbprint('');
    setCertsList([]);
  };

  const onCancel = () => {
    setShowSignModal(false);
    setThumbprint('');
    setCertsList([]);
  };

  return (
    <Modal
      title="Выберите сертификат для подписания"
      open={showSignModal}
      width={700}
      onOk={onOk}
      okText="Подписать"
      okButtonProps={{
        icon: <CheckCircleOutlined />,
      }}
      cancelButtonProps={{icon: <CloseCircleOutlined />}}
      cancelText="Отмена"
      onCancel={onCancel}
      style={{maxWidth: '600px'}}
    >
      {!isEmpty(certsList) && (
        <div className="pt-3 pb-3">
          <Form layout="vertical">
            <Form.Item name="certForSign" label="Сертификат">
              <Select className="w-100" options={certsList} onChange={(value) => setThumbprint(value)} />
            </Form.Item>
          </Form>
        </div>
      )}
    </Modal>
  );
};
