import React from 'react';
import {Form, Input, Button} from 'ui-kit';
import {CloseOutlined, FileAddOutlined, SaveOutlined} from '@ant-design/icons';

const buttonLayout = {
  wrapperCol: {span: 7, offset: 17},
};

const validateMessages = {
  required: 'Поле обязательно для заполнения',
};

const ExternalSystenForm = ({onSubmit, editibleItem, onCancel}) => {
  const initialValues = {
    caption: editibleItem && editibleItem.caption,
    code: editibleItem && editibleItem.code,
  };

  return (
    <Form
      onFinish={(values) => onSubmit(values, editibleItem)}
      initialValues={initialValues}
      validateMessages={validateMessages}
      layout="vertical"
    >
      <Form.Item label="Наименование" name="caption" rules={[{required: true}]}>
        <Input placeholder="Введите наименование" />
      </Form.Item>
      <Form.Item label="Код" name="code" rules={[{required: true}]}>
        <Input placeholder="Введите код" />
      </Form.Item>
      <Form.Item>
        <div className="gap-8 d-flex justify-content-end">
          <Button onClick={onCancel} icon={<CloseOutlined />}>
            Отменить
          </Button>
          <Button icon={editibleItem ? <SaveOutlined /> : <FileAddOutlined />} type="primary" htmlType="submit">
            {editibleItem ? 'Обновить' : 'Создать'}
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};

export default ExternalSystenForm;
