import {Typography} from 'ui-kit';
import React from 'react';

export interface LinesBlockItem {
  label: string;
  value: string;
}

export interface LinseBlockProps {
  items: LinesBlockItem[];
}

export const LinesBlock: React.FC<LinseBlockProps> = ({items}) => {
  if (!items) {
    return null;
  }
  return (
    <div>
      {items.map((line) => (
        <div key={line.label}>
          <Typography.Text type="secondary">{line.label}:</Typography.Text>
          <Typography.Text>
            {` `}
            {line.value}
          </Typography.Text>
        </div>
      ))}
    </div>
  );
};
