import {Input, Rule} from 'ui-kit';
import styles from '../EditFieldForm.module.scss';
import React from 'react';
import {CheckboxAccessValue, DefaultValue, FieldOrder} from '../helpers/FormComponents';

interface Props {
  isDisabled: boolean;
  detail: any;
  numericComparison: any;
  defaultValue?: boolean;
}

const IntegerFieldForm = ({isDisabled, detail, numericComparison, defaultValue = true}: Props) => {
  const rules: Rule[] = [
    {
      required: false,
      message: `Допустимое значение - целое число`,
      pattern: /^[0-9]*$/,
    },
    {
      validator: async (_, value) => {
        if (
          value &&
          ((Number(value) !== numericComparison?.validator_NumericComparison &&
            numericComparison?.validator_operator_NumericComparison === 'eq') ||
            (Number(value) > numericComparison?.validator_NumericComparison &&
              numericComparison?.validator_operator_NumericComparison === 'less') ||
            (Number(value) < numericComparison?.validator_NumericComparison &&
              numericComparison?.validator_operator_NumericComparison === 'more') ||
            ((Number(value) >= Number(numericComparison?.validator_NumericComparison_bottom) &&
              Number(value) <= Number(numericComparison?.validator_NumericComparison_top)) === false &&
              numericComparison?.validator_operator_NumericComparison === 'range'))
        ) {
          return Promise?.reject(new Error('Введенное значение не соответствует валидации'));
        }
      },
    },
  ];
  return (
    <>
      <div className={styles.twoColumns}>
        <FieldOrder />
        {defaultValue && (
          <DefaultValue rules={rules}>
            <Input disabled={isDisabled} />
          </DefaultValue>
        )}
      </div>
      {/* MDM-1769 */}
      {/* {!detail && <CheckboxAccessValue isDisabled={isDisabled} />} */}
    </>
  );
};

export default React.memo(IntegerFieldForm);
