import React, {useState} from 'react';
import {Table, Typography} from 'ui-kit';
import {convertDate} from '../../components/Helpers/Utils';
import {Docs} from 'ecm-docs';
import moment from 'moment';
import security from '../../Utils/security';
import {showDeleteConfirm} from '../../components/Helpers/Modals';

const {getToken} = security;

const DocumentTable = ({
  data,
  total,
  origin,
  caption,
  editMode,
  fillEcmDocuments,
  removeDocFromEcmDocuments,
  recUuid,
}) => {
  const {REACT_APP_ECM_FRONT_URL} = window._env;
  const columns = [
    {
      title: 'Наименование',
      dataIndex: 'ecmDocName',
      key: 'ecmDocName',
      render: (text, {ecmDocUuid}) => (
        <a
          href={`${REACT_APP_ECM_FRONT_URL}/document/${origin}.${ecmDocUuid}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {text}
        </a>
      ),
    },
    {
      title: 'Дата создания',
      dataIndex: 'ecmDocCreateDate',
      key: 'ecmDocCreateDate',
      render: (text) => convertDate(text),
    },
    {
      title: 'Дата изменения',
      dataIndex: 'ecmDocUpdateDate',
      key: 'ecmDocUpdateDate',
    },
    {
      title: 'Автор документа',
      dataIndex: 'authorFio',
      key: 'authorFio',
    },
    {
      title: 'Действие',
      dataIndex: 'action',
      key: 'action',
      render: (_, rec) => (
        <Typography.Link
          disabled={!editMode}
          onClick={() =>
            showDeleteConfirm({
              content: `Вы действительно хотите удалить документ ${rec.ecmDocName}?`,
              onOk: () => removeDocFromEcmDocuments(rec.uuid),
            })
          }
        >
          Удалить
        </Typography.Link>
      ),
    },
  ];

  const [page, setPage] = useState(0);

  const handleTableChange = ({current, pageSize}) => {
    setPage(current - 1);
  };

  const {REACT_APP_ECM_URL: ecmApiUrl, REACT_APP_ECM_FRONT_URL: ecmFrontUrl} = window._env;
  return (
    <>
      <div>
        <strong>{caption}</strong>
      </div>
      <br />
      <Table
        bordered
        dataSource={data}
        columns={columns}
        rowKey={(record) => record.uuid}
        pagination={{
          total,
          current: page + 1,
        }}
        onChange={handleTableChange}
      />
      <br />
      {editMode && (
        <Docs
          defaultDocsSelectorFilter={{types: [origin]}}
          bindDefaultDocsSelectorFilter={true}
          ecmApiUrl={ecmApiUrl}
          ecmFrontUrl={ecmFrontUrl}
          showDocsList={false}
          showCreateBtn={true}
          initValues={{
            ecmDocType: origin,
            docTypeUuid: origin,
            source: 'МДМ',
            regDate: moment(),
            isEndData: false,
            docKind: '2',
            orgId: '4a27ff58-7ee0-488f-9cd1-6c7c5035513e',
          }}
          formItemsProps={{
            docTypeUuid: {
              readOnly: true,
            },
          }}
          token={getToken()}
          onDocumentsAdd={(doc) => fillEcmDocuments(doc, recUuid)}
          multiply={true}
        />
      )}
      <br />
    </>
  );
};

export default DocumentTable;
