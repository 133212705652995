import {RecordType} from 'types';

export const sortRecordArrayByStringArray = (
  recordArray: RecordType[],
  arrayWithKeys: string[],
  keyForSort: string
) => {
  const sortedArray = recordArray.sort((a: any, b: any) => {
    return arrayWithKeys.indexOf(a[keyForSort]) - arrayWithKeys.indexOf(b[keyForSort]);
  });
  return sortedArray;
};
