import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {RootState} from 'redux/types/rootState';
import {CustomIcon, MenuItemGroupType, MenuItemType} from 'ui-kit';

export interface useProfileMenuProps {
  keycloakLogout: () => void;
  showModal: () => void;
}

export const useProfileMenu = ({keycloakLogout, showModal}: useProfileMenuProps) => {
  const userName = useSelector((state: RootState) => state?.user.userName);

  const profileMenu: (MenuItemType | MenuItemGroupType)[] = useMemo(() => {
    return [
      {
        label: userName,
        key: 'user_menu',
        style: {
          marginTop: '24px',
        },
        icon: <CustomIcon name="userFilled" menuIcon />,
        children: [
          {
            label: (
              <a
                href={`${window._env?.REACT_APP_SSO_CONFIG_URL || '/auth'}/realms/master/account/?referrer=security-admin-console`}
              >
                Профиль
              </a>
            ),
            key: 'profile',
          },
          {label: 'О программе', onClick: showModal, key: 'about'},
          {label: 'Выход', key: 'logout', onClick: keycloakLogout},
        ],
      },
    ] as (MenuItemType | MenuItemGroupType)[];
  }, [keycloakLogout, showModal, userName]);
  return profileMenu;
};
