import {fetchFunc} from '../../Utils/security/http/mdm';
import {
  addDeduplicationProtocolAC,
  addStartDeduplicationProtocolAC,
  editJobDeduplicationAC,
  getBlockLinkFullGroupAC,
  getBlockLinkGroupAC,
  getCancelLinkGroupAC,
  getDeduplicationFormProtocolAC,
  getDeduplicationGroupProtocolInfoAC,
  getDeduplicationJobProtocolAC,
  getDeduplicationJobProtocolInfoDeleteAC,
  getDeduplicationProtocolDeleteAC,
  getDeduplicationProtocolInfoAC,
  getDeduplicationProtocolsAC,
  getDeduplicationUnarchivedProtocolsAC,
  getSetLinkGroupAC,
  getSubmitRequestAC,
  loadingJobsAC,
  loadingProtocolsAC,
  loadingProtocolsUnarchivedAC,
  resetRulesDeduplication,
} from '../actions/actions';
import {onError} from '../../components/Helpers/Utils';
import {isEmpty} from 'lodash';

/** Создание протокола дедупликации  */
export const addDeduplicationProtocol = (protocol) => async (dispatch) => {
  let onSubmitRequest = false;
  try {
    const response = await fetchFunc(
      {
        url: `/api/v1/deduplication-mdm-controller/create-deduplication-job`,
        method: 'post',
        credentials: 'include',
        data: protocol,
      },
      onError,
      'deduplicationUrl'
    );
    if (response) {
      onSubmitRequest = true;
    }
    dispatch(addDeduplicationProtocolAC(response));
    onSubmitRequest && dispatch(resetRulesDeduplication());
  } catch (error) {
    throw new Error(error.message);
  }
};
/** Запуск протокола дедупликации  */
export const addStartDeduplication = (uuid) => async (dispatch) => {
  try {
    const response = await fetchFunc(
      {
        url: `/api/v1/deduplication-mdm-controller/start-deduplication-process/${uuid}`,
        method: 'post',
      },
      onError,
      'deduplicationUrl'
    );
    dispatch(addStartDeduplicationProtocolAC(response, uuid));
  } catch (error) {
    throw new Error(error.message);
  }
};
/** Получение правил  дедупликации  */
export const getDeduplicationFormProtocol = () => async (dispatch) => {
  try {
    const response = await fetchFunc(
      {
        url: `/api/v1/catalogs/job_deduplication/detail/rulesDeduplication/fields?showSystemFields=false`,
        method: 'get',
      },
      onError,
      'mdm'
    );
    dispatch(getDeduplicationFormProtocolAC(response.content));
  } catch (error) {
    throw new Error(error.message);
  }
};
/** Получение списка заданий дедупликации  */
export const getDeduplicationJobProtocol = () => async (dispatch) => {
  dispatch(loadingJobsAC(true));
  let onSubmitRequest = false;
  try {
    const response = await fetchFunc(
      {
        url: `/api/v1/deduplication-mdm-controller/get-jobs?size=10000`,
        method: 'get',
      },
      onError,
      'deduplicationUrl'
    );
    if (response) {
      onSubmitRequest = true;
    }

    dispatch(getDeduplicationJobProtocolAC(response.content));
    dispatch(loadingJobsAC(!onSubmitRequest));
  } catch (error) {
    dispatch(loadingJobsAC(false));
    throw new Error(error.message);
  }
};
/** Редактирование протокола дедупликации */
export const editJobDeduplication = (uuid, protocol) => async (dispatch) => {
  try {
    const response = await fetchFunc(
      {
        url: `/api/v1/deduplication-mdm-controller/update-job/${uuid}`,
        method: 'put',
        data: protocol,
      },
      onError,
      'deduplicationUrl'
    );
    dispatch(editJobDeduplicationAC(response, uuid));
  } catch (error) {
    throw new Error(error.message);
  }
};
/** Получение списка протоколов дедупликации, исключаяя заархивированные  */
export const getDeduplicationUnarchivedProtocols = () => async (dispatch) => {
  let onSubmitRequest;
  dispatch(loadingProtocolsUnarchivedAC(true));
  try {
    const response = await fetchFunc(
      {
        url: `/api/v1/deduplication-mdm-controller/get-protocols?only_visible=false&size=10000`,
        method: 'get',
      },
      onError,
      'deduplicationUrl'
    );
    if (response) {
      onSubmitRequest = true;
    }
    dispatch(getDeduplicationUnarchivedProtocolsAC(response.content));
    dispatch(loadingProtocolsUnarchivedAC(!onSubmitRequest));
  } catch (error) {
    dispatch(loadingProtocolsUnarchivedAC(false));

    throw new Error(error.message);
  }
};
/** Получение списка протоколов дедупликации,включая заархивированные  */
export const getDeduplicationProtocols = () => async (dispatch) => {
  let onSubmitRequest;
  dispatch(loadingProtocolsAC(true));

  try {
    const response = await fetchFunc(
      {
        url: `/api/v1/deduplication-mdm-controller/get-protocols?size=10000`,
        method: 'get',
      },
      onError,
      'deduplicationUrl'
    );
    if (response) {
      onSubmitRequest = true;
    }
    dispatch(getDeduplicationProtocolsAC(response.content));
    dispatch(loadingProtocolsAC(!onSubmitRequest));
  } catch (error) {
    dispatch(loadingProtocolsAC(false));
    throw new Error(error.message);
  }
};
/** Получение системной информации по протоколу дедупликации  */
export const getDeduplicationProtocolInfo = (protocolId) => async (dispatch) => {
  let onSubmitRequest = false;
  try {
    const response = await fetchFunc(
      {
        url: `/api/v1/deduplication-mdm-controller/get-groups/${protocolId}`,
        method: 'get',
      },
      onError,
      'deduplicationUrl'
    );
    if (response) {
      onSubmitRequest = true;
    }
    dispatch(getDeduplicationProtocolInfoAC(response, protocolId));
    dispatch(getSubmitRequestAC(onSubmitRequest));
  } catch (error) {
    throw new Error(error.message);
  }
};
/** Получение групп дедупликации  */
export const getDeduplicationGroupProtocolInfo = (groupId) => async (dispatch) => {
  try {
    const response = await fetchFunc(
      {
        url: `/api/v1/deduplication-mdm-controller/get-group/${groupId}`,
        method: 'get',
      },
      onError,
      'deduplicationUrl'
    );
    dispatch(getDeduplicationGroupProtocolInfoAC(response, groupId));
  } catch (error) {
    throw new Error(error.message);
  }
};
/** Удаление задания  дедупликации  */
export const getDeduplicationJobProtocolInfoDelete = (uuid) => async (dispatch) => {
  try {
    await fetchFunc(
      // {
      //   url: `/api/v1/catalogs/job_deduplication/items/${uuid}`,
      //   method: 'delete',
      // },
      {
        url: `/api/v1/deduplication-mdm-controller/hide-job/${uuid}`,
        method: 'put',
      },
      onError,
      'deduplicationUrl'
    );
    dispatch(getDeduplicationJobProtocolInfoDeleteAC(uuid));
  } catch (error) {
    throw new Error(error.message);
  }
};
/** Архивирование протокола дедупликации  */
export const getDeduplicationProtocolDelete = (protocolId) => async (dispatch) => {
  try {
    const response = await fetchFunc(
      {
        url: `/api/v1/deduplication-mdm-controller/hide-protocol/${protocolId}`,
        method: 'put',
      },
      onError,
      'deduplicationUrl'
    );
    dispatch(getDeduplicationProtocolDeleteAC(response, protocolId));
  } catch (error) {
    throw new Error(error.message);
  }
};
/** Запретить связь в группе */
export const getBlockLinkGroup = (groupId, itemGroupId) => async (dispatch) => {
  try {
    const response = await fetchFunc(
      {
        url: `/api/v1/deduplication-mdm-controller/block-link-group/${groupId}/${itemGroupId}`,
        method: 'put',
      },
      onError,
      'deduplicationUrl'
    );
    dispatch(getBlockLinkGroupAC(response, groupId, itemGroupId));
  } catch (error) {
    throw new Error(error.message);
  }
};
/** Запретить связь у целой группы */
export const getBlockLinkFullGroup = (groupId) => async (dispatch) => {
  try {
    const response = await fetchFunc(
      {
        url: `/api/v1/deduplication-mdm-controller/block-link-full-group/${groupId}`,
        method: 'put',
      },
      onError,
      'deduplicationUrl'
    );
    dispatch(getBlockLinkFullGroupAC(response, groupId));
  } catch (error) {
    throw new Error(error.message);
  }
};
/** Отменить связь у целой группы */
export const getCancelLinkGroup = (groupId, mainItemUuid) => async (dispatch) => {
  try {
    const response = await fetchFunc(
      {
        url: `/api/v1/deduplication-mdm-controller/cancel-link/${groupId}/${mainItemUuid}`,
        method: 'put',
      },
      onError,
      'deduplicationUrl'
    );
    dispatch(getCancelLinkGroupAC(response, groupId));
  } catch (error) {
    throw new Error(error.message);
  }
};
/** Установить  связь у группы */
export const getSetLinkGroup = (value, groupId) => async (dispatch) => {
  try {
    const response = await fetchFunc(
      {
        url: `/api/v1/deduplication-mdm-controller/set-link/${groupId}`,
        method: 'put',
        data: value,
      },
      onError,
      'deduplicationUrl'
    );
    if (!isEmpty(response)) dispatch(getSetLinkGroupAC(response, groupId));
  } catch (error) {
    throw new Error(error.message);
  }
};
