import {Checkbox, Form, Input, SelectDefaultOptionType} from 'ui-kit';
import React from 'react';
import styles from '../EditFieldForm.module.scss';
import {FieldOrder} from '../helpers/FormComponents';
import DynamicSelect from 'components/FormElements/DynamicSelect/DynamicSelect';
import {isEmpty} from 'lodash';

interface Props {
  isDisabled: boolean;
  detail: any;
  editableItem: any;
  uuidCatalog: any;
  referenceCatalog: any;
  uuid: any;
  findCaptionFromUuid: () => any;
  handleReferenceCatalogChange: (value: any, option: SelectDefaultOptionType | SelectDefaultOptionType[]) => void;
  handleReferenceCatalogPatternChange: (
    value: any,
    option: SelectDefaultOptionType | SelectDefaultOptionType[]
  ) => void;
}

const CatalogLinkFieldForm = ({
  isDisabled,
  detail,
  editableItem,
  uuidCatalog,
  referenceCatalog,
  uuid,
  handleReferenceCatalogPatternChange,
  findCaptionFromUuid,
  handleReferenceCatalogChange,
}: Props) => {
  return (
    <>
      <FieldOrder />
      <Form.Item
        noStyle
        shouldUpdate={(prevState, nextState) =>
          prevState.fieldTypeId !== nextState.fieldTypeId ||
          prevState.multilevel_link !== nextState.multilevel_link ||
          prevState.accessValue !== nextState.accessValue
        }
      >
        {({getFieldValue, setFieldsValue}) => {
          const accessValue = getFieldValue('accessValue');
          return (
            <>
              {/* MDM-2126 */}
              {/* <div className={styles.twoColumns}>
                <Form.Item name="hierarchy_field" valuePropName="checked">
                  <Checkbox
                    disabled={isDisabled}
                    onChange={({target: {checked}}) => {
                      if (checked) {
                        setFieldsValue({
                          referenceCatalog: null,
                          referenceCatalogPattern: null,
                        });
                      }
                    }}
                  >
                    Иерархическое поле
                  </Checkbox>
                </Form.Item>
                <Form.Item name="multilevel_link" valuePropName="checked">
                  <Checkbox
                    disabled={isDisabled}
                    onChange={({target: {checked}}) => {
                      if (checked) {
                        setFieldsValue({
                          referenceCatalogPattern: null,
                        });
                      }
                    }}
                  >
                    Многоуровневая ссылка
                  </Checkbox>
                </Form.Item>
              </div> */}
              <div className={styles.twoColumns}>
                {/* MDM-1769 */}
                {/* 
                {!detail && (
                  <Form.Item name="accessValue" valuePropName="checked">
                    <Checkbox
                      disabled={isDisabled}
                      onChange={({target: {checked}}) => {
                        if (!checked) {
                          setFieldsValue({
                            enabledLower: false,
                          });
                        }
                      }}
                    >
                      Доступ по значению поля
                    </Checkbox>
                  </Form.Item>
                )} */}
                {accessValue && !detail && (
                  <Form.Item name="enabledLower" valuePropName="checked">
                    <Checkbox disabled={isDisabled}>Включая нижестоящие записи</Checkbox>
                  </Form.Item>
                )}
              </div>
            </>
          );
        }}
      </Form.Item>
      <div className={styles.twoColumns}>
        <Form.Item
          noStyle
          shouldUpdate={(prevState, nextState) => prevState.hierarchy_field !== nextState.hierarchy_field}
        >
          {({getFieldValue}) => {
            const hierarchyField = getFieldValue('hierarchy_field');
            return (
              <Form.Item
                label="Справочник - источник"
                name="referenceCatalog"
                rules={[
                  {
                    required: !hierarchyField,
                    message: 'Поле обязательно для заполнения',
                  },
                ]}
              >
                <DynamicSelect
                  style={{width: '100%'}}
                  placeholder="Выбрать"
                  // editableItem={editableItem}
                  notFoundContent="Не найдено"
                  filterOption={true}
                  selectedUuid={uuidCatalog}
                  resetOptions={true}
                  disabled={isDisabled || hierarchyField}
                  defaultValue={!isEmpty(editableItem) ? referenceCatalog : findCaptionFromUuid()}
                  feachoptions={{
                    referenceStore: 'mdm',
                    inComeOptionUrl: '/api/v1/catalogs',
                    url: '/api/v1/catalogs',
                    method: 'get',
                    catalogOrigin: 'catalogs',
                    displayedField: 'caption',
                    valueField: 'caption',
                    valueSearchName: 'caption',
                    defaultSize: 1000,
                  }}
                  handleSelectChange={(value: any, option: SelectDefaultOptionType | SelectDefaultOptionType[]) =>
                    handleReferenceCatalogChange(value, option)
                  }
                />
              </Form.Item>
            );
          }}
        </Form.Item>

        <Form.Item
          shouldUpdate={(prevState, nextState) =>
            prevState.multilevel_link !== nextState.multilevel_link ||
            prevState.hierarchy_field !== nextState.hierarchy_field
          }
          noStyle
        >
          {({getFieldValue}) => {
            const multilevelLink = getFieldValue('multilevel_link');
            const referenceCatalog = getFieldValue('referenceCatalog');
            const hierarchyField = getFieldValue('hierarchy_field');
            return (
              <Form.Item
                label="Поле для отображения"
                name="referenceCatalogPattern"
                rules={[
                  {
                    required: !multilevelLink,
                    message: 'Поле обязательно для заполнения',
                  },
                ]}
              >
                <DynamicSelect
                  style={{width: '100%'}}
                  placeholder="Выбрать"
                  filterOption={true}
                  noVisibleOptions={[22, 23, 24, 25]}
                  resetOptions={true}
                  notFoundContent="Не найдено"
                  selectedUuid={uuidCatalog}
                  disabled={isDisabled || (isEmpty(referenceCatalog) && !hierarchyField) || multilevelLink}
                  feachoptions={{
                    referenceStore: 'mdm',
                    url: `${
                      hierarchyField
                        ? `/api/v1/catalog-draft/${uuid}/fields`
                        : `/api/v1/catalogs/${uuidCatalog || editableItem?.referenceCatalog?.uuid}/fields`
                    }`,
                    method: 'get',
                    catalogOrigin: uuidCatalog,
                    displayedField: 'caption',
                    valueField: 'caption',
                    valueSearchName: 'caption',
                    defaultSize: 1000,
                  }}
                  handleSelectChange={(value: any, option: SelectDefaultOptionType | SelectDefaultOptionType[]) =>
                    handleReferenceCatalogPatternChange(value, option)
                  }
                />
              </Form.Item>
            );
          }}
        </Form.Item>
      </div>
      <Form.Item label="Фильтрация значений" name="filter">
        <Input.TextArea style={{height: 76, resize: 'none'}} disabled={isDisabled} />
      </Form.Item>
      <Form.Item label="Скрипт для вычисления значения" name="reference_value_script">
        <Input.TextArea style={{height: 76, resize: 'none'}} disabled={isDisabled} />
      </Form.Item>
    </>
  );
};

export default React.memo(CatalogLinkFieldForm);
