import React, {useState, useEffect, useMemo, useCallback} from 'react';
import {get, isEmpty} from 'lodash';
import RecordReader from '../../../components/RecordRedactor/RecordReader';
import RecordEditor from '../../../components/RecordRedactor/RecordEditor';
import {match, useHistory, useRouteMatch} from 'react-router';
import {fetchFunc} from '../../../Utils/security/http/mdm';
import {Button, Alert, FormInstance} from 'ui-kit';
import {normalizeValues} from '../../../components/Helpers/Utils';
import {useDispatch} from 'react-redux';
import {setBreadCrumbsTitle, setPageHeaderBack, setPageHeaderButtons, setVerify} from '../../../redux/actions/actions';
import FormInitiator from '../../../components/RecordRedactor/FormInitator';
import SystemInformation from '../../../components/SystemInformation';
import style from './VerifyCard.module.scss';
import {
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  FileDoneOutlined,
  RollbackOutlined,
  SaveOutlined,
} from '@ant-design/icons';
import {mdm} from 'Utils/constant';
import {showDeleteConfirm} from 'components/Helpers/Modals';

const VerifyCard = () => {
  const match = useRouteMatch();
  const [fields, setFields] = useState([]);
  const [data, setData] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [isOnVerify, setIsOnVerify] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const uuid = match.params.id;
  const referenceOrigin = match.params.item;

  useEffect(() => {
    if (!isEmpty(data) && data.catalogId && data.catalogId.catalogUuid) {
      const catalogUuid = data.catalogId.catalogUuid;
      dispatch(setPageHeaderBack(`/catalog/${catalogUuid}`));
    }
  }, [data, dispatch]);

  const getFields = useCallback(async () => {
    try {
      const data = await fetchFunc({
        url: `/api/v1/catalogs/${referenceOrigin}/fields?size=1000`,
      });
      setFields(data.content);
    } catch (error) {}
  }, [referenceOrigin]);

  const getUnverifiedItem = useCallback(async () => {
    try {
      const data = await fetchFunc({
        url: `/api/v1/catalogs/${referenceOrigin}/items/${uuid}/unverified/`,
        params: {
          showDetail: 2,
        },
      });
      setData(data);
      dispatch(setVerify(data.sysVerifyInfo.result));
    } catch (error) {}
  }, [dispatch, referenceOrigin, uuid]);

  const saveRecord = async (values) => {
    try {
      values = await normalizeValues({values, fields});
      await fetchFunc({
        url: `/api/v1/catalogs/${referenceOrigin}/items/${uuid}/unverified/`,
        method: 'put',
        data: {
          fields: values,
        },
      });
      setIsEdit(false);
      getUnverifiedItem();
    } catch (error) {}
  };

  const deleteRecord = useCallback(async () => {
    try {
      await fetchFunc({
        url: `/api/v1/catalogs/${referenceOrigin}/items/${uuid}/unverified/`,
        method: 'delete',
      });
      history.push(`/catalog/${referenceOrigin}`);
    } catch (error) {}
  }, [history, referenceOrigin, uuid]);

  const updateRecord = async (updateType) => {
    if (updateType === 'reject') {
      setIsOnVerify(false);
    } else {
      setIsOnVerify(true);
    }
    try {
      await fetchFunc({
        url: `/api/v1/catalogs/${referenceOrigin}/items/${uuid}/unverified/${updateType}`,
        method: 'post',
      });
      getUnverifiedItem();
    } catch (error) {
      console.log(error);
    }
  };

  const changeType = useMemo(() => get(data, 'sysVerifyInfo.changeType'), [data]);

  useEffect(() => {
    referenceOrigin && getFields();
    uuid && referenceOrigin && getUnverifiedItem();
  }, [getFields, getUnverifiedItem, referenceOrigin, uuid]);

  useEffect(() => {
    dispatch(setBreadCrumbsTitle('Верификация записи'));
  }, [dispatch]);

  return (
    <div className={style.container}>
      {!isEmpty(data) && (
        <SystemInformation data={data} referenceOriginLocal={referenceOrigin} recordUuid={uuid} validateCatalog />
      )}
      {get(data, 'sysVerifyInfo.remark') && (
        <Alert
          message="Замечания по результатам верификации"
          description={get(data, 'sysVerifyInfo.remark')}
          type="error"
          showIcon
        />
      )}
      {isEdit ? (
        <FormInitiator
          formName={'verify-form'}
          fieldsMeta={fields}
          data={data}
          className={style.formInitiator}
          changeRecord={saveRecord}
          children={(form) => (
            <RecordEditor
              form={form}
              fieldsMeta={fields}
              changeType={changeType}
              data={data}
              isEdit={isEdit}
              mode={'edit'}
              referenceOrigin={referenceOrigin}
              recordUuid={uuid}
              isUnverified
            />
          )}
          footer={(disabled) => (
            <>
              <div className={style.footerButtons}>
                <Button icon={<SaveOutlined />} disabled={disabled} type="primary" htmlType="submit">
                  Сохранить
                </Button>
                <Button icon={<CloseOutlined />} onClick={() => setIsEdit(false)}>
                  Отменить
                </Button>
              </div>
            </>
          )}
        />
      ) : (
        <div className={style.recordContainer}>
          <div className={style.records}>
            <RecordReader fieldsMeta={fields} referenceOrigin={referenceOrigin} data={data} isUnverified />
          </div>
        </div>
      )}
      {!isEdit ? (
        <div className={style.underRecordsButtons}>
          {(get(data, 'sysVerifyInfo.status') === 'draft' || get(data, 'sysVerifyInfo.status') === 'rejected') && (
            <>
              <Button type="primary" icon={<FileDoneOutlined />} onClick={() => updateRecord('verify')}>
                На верификацию
              </Button>
              {changeType !== 'delete' && (
                <Button icon={<EditOutlined />} onClick={() => setIsEdit(true)}>
                  Изменить
                </Button>
              )}
            </>
          )}
          {get(data, 'sysVerifyInfo.status') === 'verification' && (
            <Button danger type="primary" icon={<RollbackOutlined />} onClick={() => updateRecord('reject')}>
              Вернуть для уточнения
            </Button>
          )}
          {!isOnVerify && get(data, 'sysVerifyInfo.status') !== 'verification' && (
            <Button
              onClick={() =>
                showDeleteConfirm({
                  content: 'Вы действительно хотите удалить заявку на верификацию?',
                  onOk: () => deleteRecord(),
                })
              }
              type="primary"
              danger
              ghost
              icon={<DeleteOutlined />}
            >
              {mdm.deleteRequest}
            </Button>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default VerifyCard;
