import React, {useEffect, useState} from 'react';
import Records from '../../components/CatalogItemWidgets/Records/Records';
import {useRouteMatch, useHistory} from 'react-router';
import {fetchFunc} from '../../Utils/security/http/mdm';
import {History} from 'history';
import {useDispatch} from 'react-redux';
import {setBreadCrumbsTitle, setPageHeaderBack, setPageHeaderLoading} from 'redux/actions/actions';
import classNames from 'classnames';

export interface VerifyRecordsProps {
  history: History;
  location: any;
}

const VerifyRecords = (props: VerifyRecordsProps) => {
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const referenceUuid = match.params && (match.params as any)?.item;
  const [data, setData] = useState<any>({});
  const history = useHistory();
  /** Проверка на наличие верификации */
  const isVerification = data.verification === true && true;
  /** Получение записей справочника */
  useEffect(() => {
    referenceUuid && getCatalog(referenceUuid);
  }, [referenceUuid]);

  const getCatalog = async (uuid: string) => {
    try {
      const responseData = await fetchFunc({
        url: `/api/v1/catalogs/${uuid}`,
        method: 'get',
      });
      setData(responseData);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const backLink = '/verifier-list/catalogs/';
    dispatch(setPageHeaderBack(backLink));
  }, [dispatch]);

  useEffect(() => {
    if (!data.caption) {
      dispatch(setPageHeaderLoading(true));
    } else {
      dispatch(setBreadCrumbsTitle(data.caption));
      dispatch(setPageHeaderLoading(false));
    }
  }, [data.caption, dispatch]);

  return (
    <div className={classNames({container: !true, containerWhite: true})}>
      <Records
        isVerification={isVerification}
        onDoubleClick={(record: any) => history.push(`/verifier-list/catalogs/${data.origin}/${record.uuid}`)}
        referenceOrigin={data.origin}
        referenceCaption={data.caption}
        referenceUuid={referenceUuid}
        location={props.location}
        match={match}
        isVerifier
        userRole="verificator"
        history={history}
        hasContextSearch={false}
      />
    </div>
  );
};

export default VerifyRecords;
