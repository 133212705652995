import {Input} from 'ui-kit';
import styles from '../EditFieldForm.module.scss';
import React from 'react';
import {DefaultValue, FieldOrder} from '../helpers/FormComponents';

interface Props {
  isDisabled: boolean;
}

const UUIDFieldForm = ({isDisabled}: Props) => {
  return (
    <>
      <div className={styles.twoColumns}>
        <FieldOrder />
        <DefaultValue>
          <Input disabled={isDisabled} />
        </DefaultValue>
      </div>
    </>
  );
};

export default React.memo(UUIDFieldForm);
