import React, {useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router';
import {isEmpty} from 'lodash';
import dayjs from 'dayjs';
import {Button, Checkbox, Table} from 'ui-kit';
import {FileZipOutlined} from '@ant-design/icons';
import cn from 'classnames';
import {showDeleteConfirm} from 'components/Helpers/Modals';
import {deduplication} from '../helpers/constants';
import {deduplicationJobsFilter} from '../helpers/deduplicationFilterUtils';
import {catalogFilter, jobTitleFilter} from '../helpers/DeduplicationUtils';
import DeduplicationFilters from '../DeduplicationFilters/DeduplicationFilters';
import {
  getDeduplicationProtocolDelete,
  getDeduplicationProtocolInfo,
  getDeduplicationProtocols,
  getDeduplicationUnarchivedProtocols,
} from '../../../redux/thunk/deduplicationThunk';

import styles from './DeduplicationProtocols.module.scss';

import {setBreadCrumbsTitle} from 'redux/actions/actions';

/** Протокол дедупликации */
const DeduplicationProtocols = () => {
  /** Маршрутизатор */
  const history = useHistory();
  /** Отправка данных в хранилище */
  const dispatch = useDispatch();
  /** Получаем список всех протоколов */
  const protocolsDeduplication = useSelector((state) => state?.deduplication.deduplicationProtocols);
  /** Получаем список незаархивированных  протоколов */
  const deduplicationUnarchivedProtocols = useSelector(
    (state) => state?.deduplication.deduplicationUnarchivedProtocols
  );
  /** Получаем ключ для лоадера */
  const loading = useSelector((state) => state?.deduplication.loadingProtocols);
  const loadingUnarchivedProtocols = useSelector((state) => state?.deduplication.loadingUnarchivedProtocols);
  /** Начальное состояние фильтра */
  const [filter, setFilter] = useState({catalog: null, name: null, launchData: null});
  /** Количество протоколов для пагинации */
  const [countProtocols, setCountProtocols] = useState('');
  /** Состояние чекбокса */
  const [isChecked, setIsChecked] = useState(false);

  /** Фильтрация завершенных и не заархивированных протоколов */
  const isDataReportUnarchivedProtocols =
    !isEmpty(deduplicationUnarchivedProtocols) &&
    deduplicationUnarchivedProtocols?.filter((item) => (!isEmpty(item.dateReport) ? item : null));
  /** Фильтрация завершенных и  заархивированных протоколов */
  const isDataReportArchivedProtocols =
    !isEmpty(protocolsDeduplication) &&
    protocolsDeduplication?.filter((item) => (!isEmpty(item.dateReport) ? item : null));

  /** Функция для отображения отфильтрованной таблицы  */
  const filteringDeduplicationProtocolsList = deduplicationJobsFilter(
    isChecked ? isDataReportArchivedProtocols : isDataReportUnarchivedProtocols,
    filter
  );

  const computedLoading = useMemo(() => {
    return loading === true || loadingUnarchivedProtocols === true;
  }, [loading, loadingUnarchivedProtocols]);

  /** Колонки таблицы*/
  const columnsDeduplicationTableList = [
    {
      title: deduplication.nameJob,
      dataIndex: 'name',
      key: 1,
      render: (text) => <p>{text}</p>,
    },
    {
      title: deduplication.dateOfProtocol,
      dataIndex: 'dateReport',
      key: 2,
      render: (text) => <p>{!isEmpty(text) && dayjs(text).format('DD.MM.YYYY HH:mm')}</p>,
    },
    {
      title: deduplication.catalog,
      dataIndex: 'catalogDeduplicationName',
      key: 3,
      render: (text) => <p>{text}</p>,
    },
    {
      title: '',
      dataIndex: '',
      key: 4,
      render: (record) => (
        <Button
          type="link"
          className={styles.open_protocol_btn}
          onClick={() => {
            dispatch(getDeduplicationProtocolInfo(record.uuid));
            history.push(`/deduplication/protocols/${record.uuid}`);
          }}
        >
          {deduplication.openProtocol}
        </Button>
      ),
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      width: 36,
      render: (record) => (
        <FileZipOutlined
          hidden={record.isHidden === true}
          className={styles.open_protocol_btn}
          title="Направить в архив"
          onClick={() => {
            showDeleteConfirm({
              content:
                'Вы действительно хотите заархивировать протокол дедупликации? После подтверждения протокол будет направлен в архив без возможности восстановления',
              onOk: () => dispatch(getDeduplicationProtocolDelete(record.uuid)),
            });
          }}
        />
      ),
    },
  ];

  /** Функция скрытия.отображения протоколов */
  const onChangeVisibleDeletedProtocols = (event) => {
    setIsChecked(event.target.checked);
  };

  /** Получение списка протоколов дедупликации */
  useEffect(() => {
    isChecked ? dispatch(getDeduplicationProtocols()) : dispatch(getDeduplicationUnarchivedProtocols());
  }, [dispatch, isChecked]);

  useEffect(() => {
    !isEmpty(isDataReportArchivedProtocols) && setCountProtocols(isDataReportArchivedProtocols?.length);
    !isEmpty(isDataReportUnarchivedProtocols) && setCountProtocols(isDataReportUnarchivedProtocols?.length);
    !isEmpty(filteringDeduplicationProtocolsList) && setCountProtocols(filteringDeduplicationProtocolsList?.length);
  }, [isDataReportArchivedProtocols, isDataReportUnarchivedProtocols, filteringDeduplicationProtocolsList]);

  useEffect(() => {
    dispatch(setBreadCrumbsTitle(deduplication.titleForListProtocols));
  }, [dispatch]);

  return (
    <div className="containerWhite d-flex flex-column gap-24">
      <DeduplicationFilters
        deduplicationProtocolsFilter={true}
        catalogTitle={catalogFilter(
          isChecked && !isEmpty(protocolsDeduplication)
            ? isDataReportArchivedProtocols
            : isDataReportUnarchivedProtocols
        )}
        jobTitleFilter={jobTitleFilter(isChecked ? isDataReportArchivedProtocols : isDataReportUnarchivedProtocols)}
        setFilter={setFilter}
        loading={computedLoading}
      />
      <div>
        <Checkbox onChange={onChangeVisibleDeletedProtocols} disabled={computedLoading}>
          {deduplication.labelCheckboxForDelete}
        </Checkbox>
      </div>
      <Table
        columns={columnsDeduplicationTableList}
        dataSource={
          (isDataReportArchivedProtocols || isDataReportUnarchivedProtocols) && filteringDeduplicationProtocolsList
        }
        rowKey={(record) => record.uuid}
        rowClassName={(record) =>
          cn('', {
            [styles.zip]: record.isHidden === true,
          })
        }
        scroll={{x: true}}
        bordered
        loading={computedLoading}
      />
    </div>
  );
};

export default React.memo(DeduplicationProtocols);
