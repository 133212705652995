import React, {Component} from 'react';
import {connect} from 'react-redux';
import {DeleteOutlined, EditOutlined, CloseCircleOutlined, PlusOutlined, CheckCircleOutlined} from '@ant-design/icons';
import _, {includes, isString, forOwn, concat, isEmpty} from 'lodash';
import CatalogList from '../../CatalogList';
import {camelToSnake, onError} from '../../Helpers/Utils';
import {errorModalCreate} from '../../Helpers/Modals';
import {fetchFunc} from '../../../Utils/security/http/mdm';
import EditFieldForm from '../../EditFieldForm/EditFieldForm';
import {Typography, Table, Button, Modal, Input} from 'ui-kit';
import styles from './Fields.module.scss';

class Head extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        {
          title: 'Наименование',
          dataIndex: 'caption',
        },
        {
          title: 'Код',
          dataIndex: 'origin',
        },
        {
          title: 'Тип',
          dataIndex: ['fieldType', 'id'],
          render: (text, record) => record.fieldType.caption,
        },
        {
          title: 'Операция',
          dataIndex: 'operation',
          render: (text) => (text === 'add' ? 'Добавить' : text === 'modify' ? 'Изменить' : 'Удалить'),
        },
        {
          title: '',
          key: 'edit',
          width: 20,
          render: (text, record) =>
            this.props.status === 'Черновик' &&
            record.operation !== 'drop' && (
              <Button
                onClick={() => {
                  this.getDraftField(record);
                }}
                type="ghost"
                size="small"
                icon={<EditOutlined className={styles.buttonIcon} />}
              />
            ),
        },
        {
          title: '',
          key: 'delete',
          width: 20,
          render: (text, record) =>
            this.props.status === 'Черновик' && (
              <Button
                onClick={() =>
                  this.setState(
                    {
                      loading: true,
                    },
                    () => this.deleteDraftField(record.uuid)
                  )
                }
                type="ghost"
                size="small"
                icon={<DeleteOutlined className={styles.buttonIcon} />}
              />
            ),
        },
      ],
      editFieldModalVisible: false,
      editDraftModalVisible: false,
      loading: true,
      title: '',
      page: 0,
      size: 10,
      modalColumns: [
        {
          title: 'Наименование',
          dataIndex: 'caption',
        },
        {
          title: 'Код',
          dataIndex: 'origin',
        },
        {
          title: 'Тип поля',
          dataIndex: ['fieldType', 'caption'],
        },
      ],
      optionsNames: [
        'old',
        'gui_editable',
        'default_in_list',
        'attribute_search',
        'context_search',
        'filter',
        'control_type',
        'combobox_options',
        'date_format',
        'date_default_value_type',
        'date_duration',
        'input_mask',
        'hierarchy_field',
        'multilevel_link',
        'phone_mask',
        'rules',
        'reference_value_script',
      ],
      validators: [],
      dataCatalogs: [],
      validatorTypes: [
        {id: 1, value: 'Enumeration'},
        {id: 2, value: 'Length'},
        {id: 3, value: 'Pattern'},
        {id: 4, value: 'NumericComparison'},
      ],
    };
  }
  componentDidMount() {
    const {
      match: {
        params: {draftId, detailId, catalogIdentifier},
      },
    } = this.props;
    this.setState(
      {
        uuid: draftId,
        detailUuid: detailId,
        detailCatalogIdentifier: catalogIdentifier,
      },
      () => {
        this.getDraftFields();
        this.getCatalogs();
      }
    );
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      match: {
        params: {draftId, detailId, catalogIdentifier},
      },
    } = this.props;
    if (prevProps.match !== this.props.match) {
      this.setState(
        {
          uuid: draftId,
          detailUuid: detailId,
          detailCatalogIdentifier: catalogIdentifier,
        },
        () => {
          this.getDraftFields();
          this.getCatalogs();
        }
      );
    }
  }
  getCatalogs = async (fieldUuid, referenceUuid, page = 0, size = 1000) => {
    const fetchData = await fetchFunc({
      url: `/api/v1/catalogs?size=${size}`,
      method: 'get',
    });
    this.setState({
      dataCatalogs: fetchData.content,
    });
  };

  getDraftFields = async (search) => {
    const {page, size, uuid, detailUuid} = this.state;
    const {detail} = this.props;
    const pageParam = `?page=${page}`;
    const sizeParam = `&size=${size}`;
    let searchParam = search ? `&search=${search}` : '';
    const detailPeace = detail ? '/detail/' + detailUuid : '';
    try {
      const data = await fetchFunc({
        url: `/api/v1/catalog-draft/${uuid}${detailPeace}/fields${pageParam}${sizeParam}${searchParam}`,
        method: 'get',
      });
      this.setState({
        fields: data.content,
        totalElements: data.totalElements,
        loading: false,
      });
    } catch (error) {
      this.setState({loading: false});
    }
  };

  getReferenceCatalogPattern = (pattern) => {
    const fields = this.state.fields || [];
    const referenceCatalogPatternName = pattern.split(';')[1];
    const referenceCatalogPatternCaption = fields.find((item) => item.origin === referenceCatalogPatternName)?.caption;
    this.setState({
      referenceCatalogPatternCaption,
    });
  };

  getReferenceCatalogPatternById = async (uuid) => {
    const {editableItem} = this.state;
    try {
      const response = await fetchFunc(
        {
          url: `/api/v1/catalogs/${uuid}/fields`,
          method: 'get',
        },
        onError,
        'mdm'
      );
      const referenceCatalogPatternCaption = response.content?.find(
        (item) => item.origin === editableItem?.referenceCatalogPattern?.split(';')[1] && item
      )?.caption;
      this.setState({
        referenceCatalogPatternCaption: referenceCatalogPatternCaption,
      });
    } catch (error) {
      throw new Error(error.message);
    }
  };

  getEditableData = async (fieldUuid) => {
    const {uuid} = this.state;
    try {
      const data = await fetchFunc({
        url: `/api/v1/catalog-draft/${uuid}/fields/${fieldUuid}`,
        method: 'get',
      });
      this.setState({
        editableItem: data,
      });
      !isEmpty(data.referenceCatalog?.uuid) && this.getReferenceCatalogPatternById(data.referenceCatalog?.uuid);
      isEmpty(data.referenceCatalog?.uuid) &&
        !isEmpty(data.referenceCatalogPattern) &&
        this.getReferenceCatalogPattern(data.referenceCatalogPattern);
    } catch (error) {}
  };

  getValidators = async (fieldUuid) => {
    try {
      const {uuid} = this.state;
      const data = await fetchFunc({
        url: `/api/v1/catalog-draft/${uuid}/fields/${fieldUuid}/validators`,
        method: 'get',
      });
      this.setState(
        {
          validators: data.content,
        },
        () => this.openModal('edit')
      );
    } catch (error) {}
  };

  createValidator = async (fieldUuid, body, validatorUuid) => {
    try {
      const {uuid} = this.state;
      await fetchFunc({
        url: `/api/v1/catalog-draft/${uuid}/fields/${fieldUuid}/validators/${validatorUuid || ''}`,
        method: validatorUuid ? 'put' : 'post',
        data: body,
      });
      this.setState({
        validators: [],
      });
    } catch (error) {}
  };

  deleteValidator = async (fieldUuid, validatorUuid) => {
    try {
      const {uuid} = this.state;
      await fetchFunc(
        {
          url: `/api/v1/catalog-draft/${uuid}/fields/${fieldUuid}/validators/${validatorUuid}`,
          method: 'delete',
        },
        ({data: {message}}) => errorModalCreate(message)
      );
      this.setState({
        validators: [],
      });
    } catch (error) {}
  };

  deleteDraftField = async (fieldUuid) => {
    const {uuid} = this.state;
    try {
      await fetchFunc(
        {
          url: `/api/v1/catalog-draft/${uuid}/fields/${fieldUuid}`,
          method: 'delete',
        },
        ({data: {message}}) => errorModalCreate(message)
      );
      this.getDraftFields();
    } catch (error) {
      this.setState({loading: false});
    }
  };

  getDraftField = async (record) => {
    const {catalogIdentifier} = this.props;
    if (record.fieldType.id === 12) {
      this.setState({editableItem: record}, () => {
        this.props.history.push(`/detail-editor/${this.state.uuid}/${catalogIdentifier}/${record.uuid}`);
      });
    } else {
      await this.getEditableData(record.uuid);
      this.getValidators(record.uuid);
    }
  };

  changeDraftField = async (values, mode, validators, callBackFunc) => {
    this.setState({loading: true});
    try {
      const {editableItem, uuid} = this.state;
      let url = `/api/v1/catalog-draft/${uuid}/fields/${mode === 'edit' ? editableItem.uuid : ''}`;
      if (mode === 'modify' || mode === 'delete') {
        url = `/api/v1/catalog-draft/${uuid}/fields/${values.uuid}/${mode}`;
      }
      if (mode === 'create') {
        url = `/api/v1/catalog-draft/${uuid}/fields`;
      }
      const result = await fetchFunc({
        url,
        method: mode === 'edit' ? 'put' : 'post',
        data: this.getFieldBody(values, mode),
      });
      if (mode === 'create') {
        if (validators) {
          const {createValidatorValues, createValidatorOperators} = validators;
          this.changeValidator(createValidatorValues, createValidatorOperators, result.uuid, {});
        }
      }
      callBackFunc && callBackFunc();
      this.setState({loading: true}, () => this.getDraftFields());
    } catch (error) {
      this.setState({loading: false});
    }
  };

  addModifyField = async (fieldUuid) => {
    try {
      const {uuid, editDraftModalMode} = this.state;
      await fetchFunc({
        url: `/api/v1/catalog-draft/${uuid}/fields/${fieldUuid}/${editDraftModalMode}`,
        method: 'post',
      });
    } catch (error) {}
  };

  changeValidator = (values, operators, fieldUuid, uuid) => {
    const {validatorTypes} = this.state;
    let keys = Object.keys(values);
    if (keys.length) {
      keys.forEach((item) => {
        let body = {};
        if (operators[item]) {
          body.rule = JSON.stringify({
            [operators[item]]: values[item],
          });
        } else {
          body.rule = values[item];
        }
        let id = validatorTypes.find((elem) => elem.value === item).id;
        body.validationType = {id};
        this.createValidator(fieldUuid, body, uuid[item]);
      });
    }
  };

  getFieldBody = (values, mode) => {
    const textOptions = ['date_format', 'date_default_value_type', 'date_duration', 'input_mask', 'control_type'];
    const {editableItem, detailUuid, optionsNames} = this.state;
    const {detail, catalogOptions, referenceCatalogPatternStore} = this.props;
    const {fieldOrder, referenceCatalog} = values;
    let options = {};
    let catalogOptNames = [];
    if (!isEmpty(referenceCatalogPatternStore)) {
      values.referenceCatalogPattern = `id;${referenceCatalogPatternStore}`;
    }
    if (referenceCatalog && _.isObject(referenceCatalog)) {
      values.referenceCatalog = referenceCatalog.uuid;
    }
    if (catalogOptions && catalogOptions.show_field_options) {
      catalogOptions.show_field_options.forEach((item) => {
        catalogOptNames.push(item.option);
      });
    }
    const allOptions = concat(optionsNames, catalogOptNames);
    forOwn(values, (value, key) => {
      if (includes(allOptions, key)) {
        if (value || value === 0) {
          if (isString(value) && !includes(textOptions, key)) {
            try {
              options[key] = JSON.parse(value);
            } catch (error) {
              errorModalCreate(`Неверный JSON у атрибута ${key}`);
              throw error;
            }
          } else {
            options[key] = value;
          }
        }
        delete values[key];
      }
    });
    let body = {
      ...values,
      origin: camelToSnake(values.origin),
      fieldOrder: fieldOrder || 1000,
      options,
    };

    switch (mode) {
      case 'create':
        body.operation = 'add';
        break;
      case 'edit':
        body.operation = editableItem.operation;
        break;
      case 'modify':
        body.operation = 'modify';
        body.fieldTypeId = values.fieldType.id;
        break;
      case 'drop':
        body.operation = 'drop';
        body.fieldTypeId = values.fieldType.id;
        break;
      default:
        body.operation = 'add';
    }

    if (detail) {
      body.detailUuid = detailUuid;
    }
    return body;
  };

  addFields = () => {
    const {selectedRows, editDraftModalMode} = this.state;
    selectedRows.forEach((item) => {
      this.changeDraftField(item, editDraftModalMode);
    });
    this.setState(
      {
        selectedRowKeys: [],
        selectedRows: [],
      },
      () => this.closeModal('editDraftModalVisible')
    );
  };

  closeModal = (type) => {
    this.setState({
      [type]: false,
    });
  };

  openModal = (mode) => {
    this.setState({
      editFieldModalVisible: true,
      title: mode === 'edit' ? 'Редактировать атрибут' : 'Добавить атрибут',
    });
  };

  openEditDraftModal = (editDraftModalMode) => {
    this.setState({
      editDraftModalVisible: true,
      editDraftModalMode,
      editDraftModalTitle: `${editDraftModalMode === 'modify' ? 'Изменить' : 'Удалить'} атрибут`,
    });
  };

  handleTableChange = (pagination) => {
    this.setState(
      {
        loading: true,
        size: pagination.pageSize,
        page: --pagination.current,
      },
      () => this.getDraftFields()
    );
  };
  renderActionButtons = () => {
    const {draftAction} = this.props;
    return (
      <>
        <Button
          onClick={() =>
            this.setState(
              {
                editableItem: null,
              },
              () => this.openModal('create')
            )
          }
          icon={<PlusOutlined />}
          type="primary"
          ghost={draftAction !== 'modify'}
        >
          Добавить
        </Button>
        {draftAction === 'modify' && (
          <>
            <Button type="primary" icon={<EditOutlined />} ghost onClick={() => this.openEditDraftModal('modify')}>
              Редактировать
            </Button>
            <Button icon={<DeleteOutlined />} onClick={() => this.openEditDraftModal('drop')}>
              Удалить
            </Button>
          </>
        )}
      </>
    );
  };

  onSelectChange = (uuids, selected) => {
    this.setState({
      selectedRowKeys: [...uuids],
      selectedRows: [...selected],
    });
  };

  onRow = (record) => {
    const {status} = this.props;
    return {
      onClick: status === 'Применено' ? () => this.getDraftField(record) : null,
    };
  };

  render() {
    const {
      columns,
      editFieldModalVisible,
      loading,
      title,
      totalElements,
      editableItem,
      editDraftModalVisible,
      fields,
      modalColumns,
      selectedRowKeys,
      validators,
      editDraftModalTitle,
      detailUuid,
      detailCatalogIdentifier,
      dataCatalogs,
      uuid,
      referenceCatalogPatternCaption,
    } = this.state;

    const {status, detail, catalogOptions, catalogIdentifier, draftAction, match} = this.props;

    const catalogId = draftAction === 'create' ? match && match.params && match.params.draftId : catalogIdentifier;

    return (
      <div className="default-column-gap">
        <div className={styles.titleBlock}>
          <Typography.Title level={3} className="flex-grow-1">
            Атрибуты
          </Typography.Title>
          <div className="d-flex gap-12">
            <div className="d-flex gap-12">{status === 'Черновик' && this.renderActionButtons()}</div>
          </div>
        </div>
        <Input.Search
          placeholder="Укажите поисковый запрос"
          allowClear
          enterButton={true}
          size="large"
          name="requestString"
          onSearch={(value) => {
            this.setState(
              {
                size: 10,
                page: 0,
              },
              () => this.getDraftFields(value)
            );
          }}
        />
        <Table
          bordered
          className={styles.tableFields}
          dataSource={!isEmpty(fields) && fields}
          columns={columns}
          loading={loading}
          rowKey={(record) => record.uuid}
          onChange={this.handleTableChange}
          onRow={this.onRow}
          pagination={{
            total: totalElements,
          }}
        />
        <Modal
          title={title}
          open={editFieldModalVisible}
          onCancel={() => {
            this.closeModal('editFieldModalVisible');
            this.setState({validators: []});
          }}
          width={960}
          footer={null}
          destroyOnClose={true}
        >
          <EditFieldForm
            closeModal={this.closeModal}
            detail={detail}
            uuid={uuid}
            loading={loading}
            referenceCatalogPatternCaption={
              !isEmpty(referenceCatalogPatternCaption) && !isEmpty(editableItem) && referenceCatalogPatternCaption
            }
            editableItem={!isEmpty(editableItem) && editableItem}
            validators={validators}
            dataCatalogs={dataCatalogs}
            catalogOptions={catalogOptions}
            type={editableItem && editableItem.fieldType.id}
            changeDraftField={this.changeDraftField}
            changeValidator={this.changeValidator}
            draftAction={draftAction}
            deleteValidator={this.deleteValidator}
            status={status}
            catalogIdentifier={catalogId}
          />
        </Modal>
        <Modal
          title={editDraftModalTitle}
          open={editDraftModalVisible}
          onCancel={() =>
            this.setState(
              {
                selectedRowKeys: [],
                selectedRows: [],
              },
              () => this.closeModal('editDraftModalVisible')
            )
          }
          okButtonProps={{
            icon: <CheckCircleOutlined />,
            type: 'primary',
          }}
          okText="Сохранить"
          cancelText="Отменить"
          cancelButtonProps={{
            icon: <CloseCircleOutlined />,
          }}
          onOk={this.addFields}
          width={1100}
          destroyOnClose={true}
          className={styles.modalEditAttr}
        >
          <CatalogList
            rowSelection={{
              selectedRowKeys: selectedRowKeys ? [...selectedRowKeys] : [],
              onChange: this.onSelectChange,
            }}
            columns={modalColumns}
            isContextSearch={true}
            isFilterSearch={true}
            apiUrl={
              detail
                ? `/api/v1/catalogs/${detailCatalogIdentifier}/detail/${detailUuid}/fields`
                : `/api/v1/catalogs/${catalogIdentifier}/fields`
            }
            catalogIdentifier={catalogId}
          />
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (store) => {
  return {
    referenceCatalogPatternStore: store.detail.referenceCatalogPatternStore,
  };
};

export default connect(mapStateToProps)(Head);
