import React, {useEffect, useState} from 'react';
import {isEmpty} from 'lodash';
import {useDispatch, useSelector} from 'react-redux';
import {FileAddOutlined} from '@ant-design/icons';
import {Button, Checkbox, Form, Radio, Select} from 'ui-kit';
import {tailLayout} from '../Helpers/constants';
import {accessArr, accessRule} from './helpers/constants';
import DynamicSelect from '../../components/FormElements/DynamicSelect';
import {getUsers} from '../../redux/thunk/catalogThunk';

const AccessRuleForm = (props) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const {store, editableUserRules} = props;
  const [accessFor, setAccessFor] = useState(editableUserRules && editableUserRules.roleName ? 'role' : 'user');
  const [noAccess, setNoAccess] = useState(false);
  const [userSelectedId, setUserSelectedId] = useState('');
  /** Используем хранилище */
  const editableUser = useSelector((state) => state?.user.userList);

  /** Получаем userName пользователя */
  const user =
    !isEmpty(editableUserRules) &&
    !isEmpty(editableUser) &&
    editableUser?.find((item) => item.uuid === editableUserRules.userId && item)?.username;
  let initialValues = {};
  if (editableUserRules) {
    const {access, actions, roleName, userId} = editableUserRules;

    const baseValues = {
      accessFor: userId ? 'user' : 'role',
      access: access === 4 ? 2 : access,
      roleName: !isEmpty(roleName) ? roleName : form.getFieldValue('roleName'),
      userId: !isEmpty(userId) ? user : form.getFieldValue('userId'),
    };

    initialValues = baseValues;

    if (store === 'dictionary') {
      initialValues = {
        ...baseValues,
        all_fields: actions.all_fields,
        all_items: actions.all_items,
        attributes: actions.attributes,
        files: actions.files,
        properties: actions.properties,
        verifier: actions.verifier,
      };
    }
  } else {
    initialValues = {
      accessFor: accessFor,
      access: store === 'dictionary' ? 2 : 1,
      all_items: true,
      all_fields: true,
      files: true,
      attributes: false,
      properties: false,
    };
  }
  /** Получаем текущего пользователя по uuid для отображения в модальном окне */
  useEffect(() => {
    if (editableUserRules?.userId) {
      const filter = {
        or: [
          {
            uuid: editableUserRules?.userId,
          },
        ],
      };

      dispatch(
        getUsers({
          filter,
        })
      );
    }
  }, [dispatch, editableUserRules?.userId, editableUserRules?.roleName]);

  /** Функция сеттрер значений формы при редактировании */
  useEffect(() => {
    form.setFieldsValue(initialValues);
    setUserSelectedId(editableUserRules?.userId || '');
  }, [form, user]);

  const onChangeAccessFor = (event) => {
    event.preventDefault();
    const {value} = event.target;
    setAccessFor(value);
    if (value === 'user') {
      form.resetFields(['roleName']);
    } else {
      form.resetFields(['userId']);
    }
  };
  /** Функция для изменения значения пользователя */
  const handleUserChange = (value, option) => {
    setUserSelectedId(option.uuidValue);
    form.setFieldsValue({userId: value});
  };

  /** Функция для изменения значения роли */
  const handleRoleChange = (value, option) => {
    setUserSelectedId(option.uuidValue);
    form.setFieldsValue({roleName: value});
  };

  const renderAccessCheckboxes = () => {
    return accessArr.map((item, index) => (
      <Form.Item key={index} name={item.name} valuePropName="checked">
        <Checkbox disabled={noAccess}>{item.label}</Checkbox>
      </Form.Item>
    ));
  };

  const prepareRuleValue = (values) => {
    const {access, all_items, attributes, files, properties, verifier, roleName} = values;
    const mode = props.editableUserRules ? 'edit' : 'create';

    let newRights = {
      access: store === 'status-matrix' ? 3 : access,
      roleName,
    };

    if (!roleName) {
      newRights.userId = !isEmpty(userSelectedId) ? userSelectedId : null;
    } else {
      newRights.userId = null;
    }

    if (store === 'dictionary') {
      newRights.actions = {
        all_fields: true,
        all_items,
        attributes,
        files,
        properties,
        verifier,
      };
    }
    props.updateAccessRules(newRights, mode);
  };
  return (
    <Form form={form} name="basic" onFinish={prepareRuleValue} layout="vertical">
      <Form.Item label={accessRule.accessFor} name="accessFor" className="mt-4">
        <Radio.Group onChange={onChangeAccessFor}>
          <Radio value="user">{accessRule.user}</Radio>
          <Radio value="role">{accessRule.role}</Radio>
        </Radio.Group>
      </Form.Item>
      {accessFor === 'user' ? (
        <Form.Item
          label={accessRule.user}
          name="userId"
          rules={[{required: true, message: accessRule.requiredMessage}]}
        >
          <DynamicSelect
            style={{width: '100%'}}
            placeholder={accessRule.select}
            filterOption={true}
            resetOptions={true}
            feachoptions={{
              referenceStore: 'mdm',
              url: '/api/v1/catalogs/user_profile/items/search/extended',
              method: 'post',
              catalogOrigin: 'user_profile',
              displayedField: 'username',
              valueField: 'username',
              valueSearchName: 'username',
              defaultSize: 20,
            }}
            handleSelectChange={(value, option) => handleUserChange(value, option)}
          />
        </Form.Item>
      ) : (
        <Form.Item
          label={accessRule.roleLabel}
          name="roleName"
          rules={[{required: true, message: accessRule.requiredMessage}]}
        >
          <DynamicSelect
            style={{width: '100%'}}
            placeholder={accessRule.select}
            filterOption={true}
            resetOptions={true}
            feachoptions={{
              referenceStore: 'mdm',
              url: '/api/v1/catalogs/user_role/items/search/extended',
              method: 'post',
              catalogOrigin: 'user_role',
              displayedField: 'role',
              valueField: 'role',
              valueSearchName: 'role',
              defaultSize: 20,
            }}
            handleSelectChange={(value, option) => handleRoleChange(value, option)}
          />
        </Form.Item>
      )}
      {store !== 'status-matrix' && (
        <Form.Item label={accessRule.accessLevel} name="access">
          <Select
            onChange={(value) => {
              if (value === 0) {
                setNoAccess(true);
                accessArr.forEach((item) => {
                  form.setFieldsValue({[item.name]: false});
                });
              } else setNoAccess(false);
            }}
          >
            {store !== 'dictionary' && (
              <Select.Option key={0} value={0}>
                {accessRule.accessIsDenied}
              </Select.Option>
            )}
            {store !== 'status-matrix-action' && (
              <Select.Option key={1} value={1}>
                {accessRule.view}
              </Select.Option>
            )}
            {store === 'dictionary' && (
              <Select.Option key={2} value={2}>
                {accessRule.full}
              </Select.Option>
            )}
          </Select>
        </Form.Item>
      )}
      {store === 'dictionary' && <div>{renderAccessCheckboxes()}</div>}
      <Form.Item {...tailLayout} className="text-right">
        <Button icon={<FileAddOutlined />} type="primary" htmlType="submit">
          {editableUserRules ? accessRule.save : accessRule.create}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default React.memo(AccessRuleForm);
