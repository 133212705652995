import React, {ReactNode} from 'react';
import {Card, Col, Row, Collapse} from 'ui-kit';
import VerifyAlert from '../Verification/VerifyAlert';

export interface StructureRendererProps {
  structure: any;
  meta: any[];
  renderItem: (item: any, index: number) => ReactNode;
  isUnverified?: boolean;
}

const StructureRenderer = ({structure, meta, renderItem, isUnverified}: StructureRendererProps) => {
  const renderItemFromMeta = (
    {
      origin,
      greed,
      offset,
      className,
    }: {
      className: string;
      origin: string;
      greed: string | number;
      offset: string;
    },
    index: number
  ) => {
    if (origin === 'deleted_item') return;
    const itemFromMeta = meta.find((item) => item.origin === origin);
    if (itemFromMeta) {
      const field = renderItem(itemFromMeta, index);
      return greed ? (
        <Col
          key={'bpm-field-col-' + index}
          offset={offset}
          span={greed}
          className={className || ' ' + (isUnverified ? 'd-flex' : '')}
        >
          {field}
          {isUnverified && (
            <div className="col mb-0 d-flex justify-content-center align-items-end">
              <VerifyAlert name={origin} />
            </div>
          )}
        </Col>
      ) : (
        <div key={'bpm-field-col-' + index}>
          {isUnverified ? (
            <div className={isUnverified ? 'd-flex' : ''}>
              {field}
              <div className="col mb-0 d-flex justify-content-center align-items-end">
                <VerifyAlert name={origin} />
              </div>
            </div>
          ) : (
            field
          )}
        </div>
      );
    }
  };

  const renderStructureGroup = (
    groupIndex: number,
    {
      items,
      name: groupName,
      label: groupLabel,
      collapsible,
      open,
    }: {items: any[]; name: string; label: string; collapsible: boolean; open: boolean}
  ) => {
    const render = items.map((item, index) => {
      return item.type === 'item' ? renderItemFromMeta(item, index) : renderContainer(index, item);
    });
    if (collapsible) {
      return (
        <Collapse key={groupIndex} defaultActiveKey={open ? 1 : ''} bordered={false} destroyInactivePanel={false}>
          <Collapse.Panel header={groupLabel || ''} key="1" forceRender={true}>
            <div className="grouped-items pt-2 pb-2">{render}</div>
          </Collapse.Panel>
        </Collapse>
      );
    } else {
      return (
        <Card key={groupIndex} title={groupLabel || ''} bordered={false} className={'org-list__card container--white'}>
          <div className="grouped-items pt-2 pb-2">{render}</div>
        </Card>
      );
    }
  };

  const renderContainer = (
    index: number,
    {
      items,
      greed,
      className,
      label,
      inline,
    }: {
      items: any[];
      greed: string | number;
      className: string;
      label: string;
      inline: boolean;
    }
  ) => {
    const needItemsWrap = items.every(({type, greed, inline}) => {
      if (type === 'container' && inline) return true;
      return type === 'item' && greed;
    });
    const children = items.map((item, index) => {
      switch (item.type) {
        case 'group':
          return renderStructureGroup(index, item);
        case 'container':
          return renderContainer(index, item);
        default:
          if (!needItemsWrap) delete item.greed;
          return renderItemFromMeta(item, index);
      }
    });
    if (needItemsWrap)
      return (
        <Row gutter={8} key={'container-row-' + index}>
          {children}
        </Row>
      );

    const ct = (
      <Col key={'container-col-' + index} span={greed} className={'container ' + className}>
        {label && <div className={'container__head h4 mt-4 mb-4'}>{label}</div>}
        {children}
      </Col>
    );
    return inline ? (
      ct
    ) : (
      <Row gutter={[24, 24]} key={'container-row-' + index}>
        {ct}
      </Row>
    );
  };

  const renderStructure = (structure: any[]) => {
    const render = structure.map((item, index) => renderContainer(index, item));
    return <div>{render}</div>;
  };

  return <>{renderStructure(structure)}</>;
};

export default StructureRenderer;
