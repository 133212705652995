import DetailDraftRedactor from 'components/DetailDraftRedactor';
import CatalogDraftCard from 'modules/CatalogDraftCard';
import CatalogGroupAccessPage from 'modules/CatalogGroupAccessPage';
import CatalogItem from 'modules/CatalogItem';
import CatalogItemAdd from 'modules/CatalogItemAdd';
import CatalogItemImport from 'modules/CatalogItemImport';
import CatalogItemRecord from 'modules/CatalogItemRecord';
import Catalogs from 'modules/Catalogs';
import CatalogsDraft from 'modules/CatalogsDraft';
import CatalogsOrgCard from 'modules/CatalogsOrg/CatalogsOrgCard';
import CatalogsOrgList from 'modules/CatalogsOrg/CatalogsOrgList';
import DeduplicationJob from 'modules/Deduplication/DeduplicationJob/DeduplicationJob';
import DeduplicationProtocol from 'modules/Deduplication/DeduplicationProtocol/DeduplicationProtocol';
import DeduplicationProtocolForm from 'modules/Deduplication/DeduplicationProtocolForm/DeduplicationProtocolForm';
import DeduplicationProtocols from 'modules/Deduplication/DeduplicationProtocols/DeduplicationProtocols';
import LinkTypes from 'modules/LinkTypes/LinkTypes';
import NotAccessPage from 'modules/NotAccessPage';
import NotFoundPage from 'modules/NotFoundPage';
import NotificationPageList from 'modules/NotificationPage/NotificationPageList/NotificationPageList';
import NotificationPageTemplates from 'modules/NotificationPage/NotificationPageTemplates/NotificationPageTemplates';
import SchedulerCalendar from 'modules/SchedulerCalendar';
import SchedulerTasks from 'modules/SchedulerTasks';
import Services from 'modules/Services';
import ServiceCard from 'modules/Services/ServiceCard';
import SettingsPage from 'modules/SettingsPage';
import Subscriptions from 'modules/Subscriptions';
import Verification from 'modules/Verification';
import VerifierList from 'modules/Verification/components/VerifierList';
import VerifyCard from 'modules/Verification/components/VerifyCard';
import VerifyOrgCard from 'modules/VerifyOrg/VerifyOrgCard';
import VerifyRecords from 'modules/VerifyRecords';
import ContactsPage from 'modules/ContactsPage';
import VerifierOrgList from 'modules/VerifyOrg/VerifyOrgList';
import externalSystemsList from 'modules/ExternalSystemsList/ExternalSystemsList';
import subsystemsList from 'modules/SubsystemsList/SubsystemsList';
import Comparsion from 'modules/Comparison/Comparison';

import {Eroute, IRoute} from 'types';

export const routes: IRoute[] = [
  // catalogs
  {
    id: Eroute.VerifyOrgCard,
    parent: Eroute.MenuCatalogs,

    path: '/:scope(catalog)/organisations/:id/verify',
    main: VerifyOrgCard,
    mode: 'user',
  },
  {
    id: Eroute.VerifyCard,
    parent: Eroute.MenuCatalogs,

    path: '/:scope(catalog)/:item/:id/verify',
    main: VerifyCard,
    mode: 'user',
  },
  {
    id: Eroute.Comparsion,
    parent: Eroute.CatalogItemRecord,
    title: 'Сравнение версий записи',
    path: '/:scope(catalog)/:item/:id/compare/:versionId/:comparedVersionId',
    main: Comparsion,
    mode: 'user',
  },
  {
    id: Eroute.CatalogItemRecord,
    parent: Eroute.CatalogItem,

    path: '/:scope(catalog)/:item/:id',
    main: CatalogItemRecord,
  },
  {
    id: Eroute.CatalogItem,
    parent: Eroute.Catalogs,
    title: 'Выбранный справочник',

    path: '/:scope(catalog)/:item',
    main: CatalogItem,
  },
  {
    id: Eroute.Catalogs,
    navLink: '/catalog',
    title: 'Все справочники',
    parent: Eroute.MenuCatalogs,
    showMenu: true,

    path: '/:scope(catalog)',
    main: Catalogs,
  },

  // deduplication
  {
    id: Eroute.DeduplicationProtocol,
    parent: Eroute.DeduplicationProtocols,

    path: '/:scope(deduplication)/protocols/:id',
    main: DeduplicationProtocol,
  },
  {
    id: Eroute.DeduplicationProtocols,
    parent: Eroute.MenuDeduplication,
    navLink: '/deduplication/protocols',
    title: 'Протоколы дедупликации',
    showMenu: true,

    path: '/:scope(deduplication)/protocols',
    main: DeduplicationProtocols,
  },
  {
    id: Eroute.DeduplicationProtocolForm,
    parent: Eroute.MenuDeduplication,
    path: '/:scope(deduplication)/deduplication-add',
    main: DeduplicationProtocolForm,
  },
  {
    id: Eroute.DeduplicationJob,
    navLink: '/deduplication',
    title: 'Задания дедупликации',
    parent: Eroute.MenuDeduplication,
    showMenu: false,

    path: '/:scope(deduplication)',
    main: DeduplicationJob,
  },

  // Verification
  {
    id: Eroute.Verification,
    parent: Eroute.VerifyRecords,
    title: 'Изменение записи',

    path: '/:scope(verifier-list)/catalogs/:item/:id',
    main: Verification,
  },
  {
    id: Eroute.VerifyRecords,
    parent: Eroute.VerifierList,
    title: 'Выбранный справочник',

    path: '/:scope(verifier-list)/catalogs/:item',
    main: VerifyRecords,
  },
  {
    id: Eroute.VerifierList,
    navLink: '/verifier-list/catalogs',
    title: 'Справочники',
    parent: Eroute.MenuVerifiaction,
    showMenu: true,

    path: '/:scope(verifier-list)/catalogs',
    main: VerifierList,
  },
  {
    id: Eroute.VerifierOrgList,
    path: '/:scope(verifier-list)/org',
    main: VerifierOrgList,
  },

  // notification
  {
    id: Eroute.NotificationPageTemplates,
    navLink: '/notification/template',
    title: 'Шаблоны',
    parent: Eroute.MenuNotification,
    showMenu: false,

    path: '/:scope(notification)/template',
    main: NotificationPageTemplates,
  },
  {
    id: Eroute.NotificationPageList,
    navLink: '/notification/list',
    title: ' Список уведомлений',
    parent: Eroute.MenuNotification,
    showMenu: false,

    path: '/:scope(notification)/list',
    main: NotificationPageList,
  },

  //scheduler
  {
    id: Eroute.SchedulerTasks,
    navLink: '/scheduler-tasks',
    title: 'Задачи планировщика',
    parent: Eroute.MenuScheduler,
    showMenu: false,

    path: '/:scope(scheduler-tasks)',
    main: SchedulerTasks,
  },
  {
    id: Eroute.SchedulerCalendar,
    navLink: '/scheduler-calendar',
    title: 'Расписание планировщика',
    parent: Eroute.MenuScheduler,
    showMenu: false,

    path: '/:scope(scheduler-calendar)',
    main: SchedulerCalendar,
  },

  // admin
  {
    id: Eroute.CatalogItemAdd,
    navLink: '/catalog-add',
    title: 'Создать справочник',
    parent: Eroute.MenuAdmin,
    showMenu: false,
    path: '/:scope(catalog-add)',
    main: CatalogItemAdd,
  },
  {
    id: Eroute.CatalogItemImport,
    navLink: '/catalog-import',
    title: 'Импортировать заявку из файла',
    parent: Eroute.MenuAdmin,
    showMenu: false,

    path: '/:scope(catalog-import)',
    main: CatalogItemImport,
  },
  {
    id: Eroute.CatalogDraftCard,
    parent: Eroute.CatalogsDraft,

    path: '/:scope(catalog-draft)/:draftId',
    main: CatalogDraftCard,
  },
  {
    id: Eroute.CatalogsDraft,
    navLink: '/catalog-draft',
    title: 'Заявки на изменение справочников',
    parent: Eroute.MenuAdmin,
    showMenu: true,

    path: '/:scope(catalog-draft)',
    main: CatalogsDraft,
  },

  // MDM-1769
  // {
  //   id: Eroute.CatalogGroupAccessPage,
  //   navLink: '/catalogs-rules',
  //   title: 'Установить доступ для группы справочников',
  //   parent: Eroute.MenuAdmin,
  //   showMenu: true,

  //   path: '/:scope(catalogs-rules)',
  //   main: CatalogGroupAccessPage,
  // },

  // system catalogs
  {
    id: Eroute.ExternalSystemsList,
    navLink: '/external-systems',
    title: 'Внешние системы',
    parent: Eroute.MenuSystemCatalogs,
    showMenu: false,

    path: '/:scope(external-systems)',
    main: externalSystemsList,
  },
  {
    id: Eroute.SubsystemsList,
    navLink: '/subsystems',
    title: 'Подсистемы',
    parent: Eroute.MenuSystemCatalogs,
    showMenu: false,

    path: '/:scope(subsystems)',
    main: subsystemsList,
  },
  {
    id: Eroute.Subscriptions,
    navLink: '/subscriptions',
    title: 'Подписки',
    parent: Eroute.MenuSystemCatalogs,
    showMenu: false,

    path: '/:scope(subscriptions)',
    main: Subscriptions,
  },

  // unsorted

  {
    id: Eroute.CatalogsOrgCard,
    parent: Eroute.CatalogsOrgList,
    path: '/:scope(organisations)/:id',
    main: CatalogsOrgCard,
  },
  {
    id: Eroute.CatalogsOrgList,
    path: '/:scope(organisations)',
    main: CatalogsOrgList,
  },
  {
    id: Eroute.DetailDraftRedactor,
    parent: Eroute.CatalogDraftCard,
    path: '/:scope(detail-editor)/:draftId/:catalogIdentifier/:detailId',
    main: DetailDraftRedactor,
    title: 'Детализация',
    status: 'Черновик',
  },

  {
    id: Eroute.ServiceCard,
    path: '/:scope(services)/:id',
    main: ServiceCard,
  },

  {
    id: Eroute.Services,
    path: '/:scope(services)',
    main: Services,
  },
  {
    id: Eroute.LinkTypes,
    navLink: '/link-type',
    title: 'Типы кросс-связей',
    showMenu: false,

    path: '/:scope(link-type)',
    main: LinkTypes,
  },

  {
    id: Eroute.SettingsPage,
    path: '/:scope(settings)',
    main: SettingsPage,
  },
  {
    id: Eroute.ContactsPage,
    path: '/:scope(contacts)',
    main: ContactsPage,
  },

  {
    id: Eroute.NotAccessPage,
    path: '/:scope(error)/403',
    main: NotAccessPage,
  },
  {
    id: Eroute.NotFoundPage,
    path: '/:scope(error)/404',
    main: NotFoundPage,
  },
  {
    id: Eroute.NotFoundPageAny,
    path: '*',
    main: NotFoundPage,
  },

  // Main Menu

  {
    id: Eroute.MenuCatalogs,
    navLink: '',
    title: 'Справочники',
    icon: 'FolderOpenOutlined',
    showMenu: true,
  },
  {
    id: Eroute.MenuDeduplication,
    navLink: '',
    title: 'Дедупликация',
    icon: 'DeduplicationOutlined',
    showMenu: true,
  },
  {
    id: Eroute.MenuVerifiaction,
    navLink: '',
    title: 'Верификация',
    icon: 'CheckSquareOutlined',
    showMenu: true,
  },
  {
    id: Eroute.MenuNotification,
    // navLink: '/notification',
    navLink: '',
    title: 'Уведомления',
    icon: 'BellOutlined',
    showMenu: false,
  },
  {
    id: Eroute.MenuScheduler,
    // id: 9,
    navLink: '',
    title: 'Планировщик',
    icon: 'CalendarOutlined',
    showMenu: false,
  },
  {
    id: Eroute.MenuAdmin,
    navLink: '',
    title: 'Администрирование',
    icon: 'SettingOutlined',
    showMenu: true,
  },
  {
    id: Eroute.MenuSystemCatalogs,
    navLink: '',
    title: 'Системные справочники',
    icon: 'DatabaseOutlined',
    showMenu: false,
  },
];
