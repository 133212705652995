import {Divider, Table, Typography} from 'ui-kit';
import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {isEmpty} from 'lodash';

import {headerMDM, helpersModal} from '../Helpers/constants';

import style from './HelpModalInfo.module.scss';
import {RootState} from 'redux/types/rootState';

/** Модальное окно для инфорации пользователю */
const HelpInfoModal = () => {
  /** Информация о версиях приложения */
  const helpInfo = useSelector((state: RootState) => state?.helpInfo.helpInfo);
  /** Преобразования информации о версия к виду таблицы */
  const helpInfoTable = useMemo(() => {
    if (!isEmpty(helpInfo)) {
      return [
        {name: 'mdm-api-adapter', version: helpInfo?.build?.version},
        {name: 'docstore', version: helpInfo.docstore?.version},
        {name: 'keycloak - spring - adapter', version: helpInfo['keycloak-spring-adapter']?.version},
        {name: 'mdm-api-service', version: helpInfo['mdm-api-service']?.version},
        {name: 'mdm-core', version: helpInfo['mdm-core']?.version},
      ];
    }
  }, [helpInfo]);
  /** Колонки для таблицы */
  const columns = [
    {
      title: headerMDM.component,
      dataIndex: ['name'],
      key: 'name',
    },
    {
      title: headerMDM.versionTable,
      dataIndex: ['version'],
      key: 'version',
    },
  ];

  return (
    <div className={style.helpModal}>
      <div>{helpersModal.info?.map((info, index) => <div key={index}>{info}</div>)}</div>
      <a className={style.helpModalUrl} href="https://www.7tech.ru/">
        {headerMDM.url}
      </a>
      <div>
        {headerMDM.version} {window._env?.REACT_APP_VERSION}
        <Divider />
        <Typography.Title level={5}>{headerMDM.productVersion}</Typography.Title>
      </div>
      <Table bordered columns={columns} dataSource={helpInfoTable} pagination={false} />
    </div>
  );
};

export default HelpInfoModal;
