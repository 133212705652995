import React, {useEffect, useState, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useRouteMatch} from 'react-router';
import {setComparedVersionsAC, setPageHeaderBack} from 'redux/actions/actions';
import styles from './Comparison.module.scss';
import VersionSystemInformation from './components/VersionSystemInformation';
import {fetchFunc} from 'Utils/security/http/mdm';
import {RootState} from 'redux/types/rootState';
import VersionCard from './components/VersionCard';
import {isEmpty, isEqual} from 'lodash';
import {Button} from 'ui-kit';
import {CloseOutlined} from '@ant-design/icons';

const Comparison = () => {
  const dispatch = useDispatch();
  const {params} = useRouteMatch();
  const [fields, setFields] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const catalogUuid = (params as any).item;
  const recordUuid = (params as any).id;
  const versionId = (params as any).versionId;
  const comparedVersionId = (params as any).comparedVersionId;

  const {changedFields, versionItem, comparedItem} = useSelector((state: RootState) => state.comparedVersions);

  const getComparedVersions = useCallback(async () => {
    try {
      const body = {versionId, comparedVersionId};
      const data = await fetchFunc({
        url: `/api/v1/catalogs/${catalogUuid}/items/${recordUuid}/history/versions/compare`,
        method: 'post',
        data: body,
      });
      dispatch(setComparedVersionsAC(data));
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }, [dispatch, catalogUuid, recordUuid, versionId, comparedVersionId]);

  const getFields = useCallback(async () => {
    try {
      const data = await fetchFunc({
        url: `/api/v1/catalogs/${catalogUuid}/fields?size=1000`,
        params: {
          showDetails: 1,
        },
      });
      setFields(data.content);
    } catch (error) {}
  }, [catalogUuid]);

  useEffect(() => {
    setLoading(true);
    catalogUuid && getFields();
    getComparedVersions();
  }, [getFields, catalogUuid, recordUuid, versionId, comparedVersionId, getComparedVersions]);

  useEffect(() => {
    dispatch(setPageHeaderBack(`/catalog/${catalogUuid}/${recordUuid}?tab=2`));
  }, [dispatch, catalogUuid, recordUuid]);

  const onClose = () => {
    history.push(`/catalog/${catalogUuid}/${recordUuid}?tab=2`);
  };

  let fieldsForRender = fields;

  fieldsForRender = fieldsForRender.map((item) => {
    const hasChanged = !isEqual(versionItem?.[item.origin], comparedItem?.[item.origin]);
    return {
      ...item,
      hasChanged,
    };
  });

  return (
    <div className={styles.container}>
      <VersionSystemInformation data={[versionItem, comparedItem]} />
      <div className={styles.VersionCards}>
        {!isEmpty(versionItem) && <VersionCard fieldsMeta={fieldsForRender} data={versionItem} />}
        {!isEmpty(comparedItem) && <VersionCard fieldsMeta={fieldsForRender} data={comparedItem} />}
      </div>
      <div>
        <Button onClick={onClose} icon={<CloseOutlined />}>
          Закрыть
        </Button>
      </div>
    </div>
  );
};

export default Comparison;
