import React, {ChangeEvent, Component, ReactElement} from 'react';
import _, {isString} from 'lodash';
import {CheckOutlined, CloseOutlined} from '@ant-design/icons';

import {fetchFunc} from '../../../Utils/security/http/mdm';
import {errorModalCreate} from '../../Helpers/Modals';
// import {mdm} from '../../../Utils/constant';
import {Checkbox, Input, Select, Typography} from 'ui-kit';
import {AttributesButton} from './AttributesButton';
import styles from './Attributes.module.scss';
import classNames from 'classnames';
import {CheckboxChangeEvent} from 'antd/es/checkbox';
import {RecordType} from 'types';

// @todo need rewrite correct type
type DataType = RecordType[];

export interface AttributesProps {
  data: DataType;
  changeMode: boolean;
  uuid: string;
  detailUuid?: string;
  operation?: string;
  // @todo need rewrite correct type
  options: DataType;
  loading: boolean;
  status?: string;
  onSave: () => void;
  onChange: () => void;
}

interface AttributesState {
  isAttrEdit: boolean;
  // @todo need rewrite correct type
  data: DataType;
  defaultData: DataType;
}

class Attributes extends Component<AttributesProps, AttributesState> {
  constructor(props: AttributesProps) {
    super(props);
    this.state = {
      isAttrEdit: false,
      data: [],
      defaultData: [],
    };
  }

  componentDidUpdate(prevProps: AttributesProps): void {
    if (prevProps.data !== this.props.data) {
      this.setState({data: this.props.data});
    }
    // if (this.state.isAttrEdit !== this.props.changeMode) {
    //   this.setState({isAttrEdit: this.props.changeMode});
    // }
  }

  editAttributes = async () => {
    try {
      const {uuid, detailUuid} = this.props;
      const data = this.makeBody();
      if (!detailUuid && data.options) {
        data.options = isString(data.options) ? JSON.parse(data.options) : data.options;
      }
      await fetchFunc({
        url: '/api/v1/catalog-draft/' + uuid + (detailUuid ? '/fields/' + detailUuid : ''),
        method: 'put',
        data,
      });
      this.props.onSave();
      // this.setState({isAttrEdit: false});
      this.props.onChange();
    } catch (error) {
      errorModalCreate('Неверный JSON');
    }
  };

  makeBody = () => {
    const {detailUuid, operation} = this.props;
    const {data} = this.state;
    const body = {} as any;
    data.forEach((item, index) => {
      if (detailUuid) {
        body.fieldOrder = index * 100;
        body.operation = operation;
        if (item.origin.split('.')[0] === 'options' && item.value) {
          body.options = {
            ...body.options,
            [item.origin.split('.')[1]]: item.value,
          };
        } else {
          body[item.origin] = item.value;
        }
      } else {
        if (item.origin === 'options') {
          if (!_.isEmpty(item.value)) body[item.origin] = item.value;
        } else if (item.origin.split('.')[0] === 'options' && !!item.value) {
          body.options = {
            ...body.options,
            [item.origin.split('.')[1]]: item.value.split(', '),
          };
        } else {
          body[item.origin] = item.value;
        }
      }
    });

    return body;
  };

  changeMode = () => {
    const {data} = this.state;
    const defaultData = _.cloneDeep(data);
    this.setState({isAttrEdit: true, defaultData});
  };

  onCancel = () => {
    const {onChange} = this.props;
    onChange();

    this.setState({data: this.state.defaultData, isAttrEdit: false});
  };

  handleInputChange = (
    e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement> | CheckboxChangeEvent,
    index: number,
    type: 'input' | 'checkbox' | 'select' | string
  ): void => {
    const {data} = this.state;
    const newData = data;
    switch (type) {
      case 'input':
        newData[index].value = e.target.value;
        break;
      case 'checkbox':
        newData[index].value = (e as CheckboxChangeEvent).target.checked;
        break;
      case 'select':
        newData[index].value = e;
        break;
      default:
        newData[index].value = e.target.value;
    }
    this.setState({data: newData});
  };

  renderAttributes = (data: DataType) => {
    return (
      <>
        {data.map((item, index) => {
          return (
            (!this.props.changeMode || !item.noEdit) && (
              <div key={index} className="documents__info-item col-12">
                <div>
                  <Typography.Text type="secondary">{item.caption}:</Typography.Text>
                </div>
                {this.renderFields(item, index)}
              </div>
            )
          );
        })}
      </>
    );
  };

  renderFields = (item: any, index: number): ReactElement | null => {
    switch (item.type) {
      case 'String':
        return this.renderInput(item, index);
      case 'Text':
        if (item.format === 'json') {
          try {
            if (!_.isString(item.value) && !_.isNull(item.value)) {
              item.value = JSON.stringify(item.value);
            }
          } catch (error) {
            errorModalCreate('Неверный JSON');
          }
        }
        return this.renderTextArea(item.value, index);
      case 'Checkbox':
        return this.renderCheckbox(item.value, index, item.disabled);
      case 'Select':
        return this.renderSelect(item.value, index, item.origin);

      default:
        return this.renderInput(item, index);
    }
  };

  renderInput = (item: RecordType, index: number): ReactElement => (
    <>
      {this.props.changeMode ? (
        <Input
          disabled={item?.disabled}
          value={item?.value}
          onChange={(e) => this.handleInputChange(e, index, 'input')}
        />
      ) : (
        item?.value || '-'
      )}
    </>
  );

  renderTextArea = (value: string, index: number): ReactElement => {
    return (
      <>
        {this.props.changeMode ? (
          <Input.TextArea value={value} onChange={(e) => this.handleInputChange(e, index, 'input')} />
        ) : (
          <div className={styles.attributesUnique}>{value || '-'}</div>
        )}
      </>
    );
  };

  renderCheckbox = (value: boolean, index: number, disabled: boolean): ReactElement => (
    <>
      {this.props.changeMode && !disabled ? (
        <Checkbox checked={value} onChange={(e) => this.handleInputChange(e, index, 'checkbox')} />
      ) : value ? (
        <CheckOutlined />
      ) : (
        <CloseOutlined />
      )}
    </>
  );

  renderSelect = (value: any, index: number, origin: string): ReactElement | null => {
    if (!this.props.options) {
      return null;
    }
    const options = this.props.options.map((item, index) => (
      <Select.Option key={index} value={item.value}>
        {item.label}
      </Select.Option>
    ));
    const valueItem = this.props.options.find((elem) => elem.value === value);

    return (
      <>
        {this.props.changeMode ? (
          <Select
            disabled={origin === 'fieldTypeId'}
            style={{width: '100%'}}
            value={value}
            onChange={(value) => this.handleInputChange(value, index, 'select')}
          >
            {options}
          </Select>
        ) : valueItem ? (
          valueItem.label
        ) : (
          '-'
        )}
      </>
    );
  };

  render() {
    const {data} = this.state;
    const {loading, status, changeMode} = this.props;
    return (
      <div className={styles.attributes}>
        {/* <div className={styles.titleBlock}>
          <Typography.Title level={3}>{mdm.params}</Typography.Title>
        </div> */}
        <div className={classNames('d-flex flex-wrap', styles.attributes)} style={{width: 600}}>
          {!loading && this.renderAttributes(data)}
        </div>
        <AttributesButton
          isAttrEdit={changeMode}
          onCancel={this.onCancel}
          onChangeMode={this.changeMode}
          onEditAttributes={this.editAttributes}
          status={status}
          loading={loading}
        />
      </div>
    );
  }
}

export default Attributes;
