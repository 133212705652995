import React, {useState, useEffect} from 'react';
import {DatePicker, Select, Form, Button, Collapse} from 'ui-kit';
import {useDispatch, useSelector} from 'react-redux';
import {setHistorySearchValue, setFilterReset, setFilterError} from '../../../redux/actions/actions';
import {catalogsFilters} from '../helpers/constant';
import {isEmpty} from 'lodash';
import moment from 'moment/moment';
import dayjs from 'dayjs';
import {RootState} from 'redux/types/rootState';
import styles from '../CatalogsFilters.module.scss';
import classNames from 'classnames';
import {DeleteOutlined} from '@ant-design/icons';

const {RangePicker} = DatePicker;

const validateMessages = {
  required: 'Поле обязательно для заполнения',
};

/**
 * Фильтр по истории изменений
 * @param initialValues - начальные значения дат
 * @param fields - поля для поиска
 * @param formHistory - данные формы
 */

export interface FilterHistoryChangesProps {
  fields?: any[];
  formHistory: any;
  // don't used
  activeKeyCollapse?: string | string[] | null;
  setActiveKeyCollapse?: (value: string | string[] | null) => void;
  onFilterSearch?: boolean;
  onHistoryReset?: () => void;
}

const FilterHistoryChanges: React.FC<FilterHistoryChangesProps> = ({fields, formHistory}) => {
  const dispatch = useDispatch();
  const {Panel} = Collapse;
  const [attributeOptions, setAttributeOptions] = useState([]);
  const [isActive, setIsActive] = useState([]);
  const filterErrorFlag = useSelector((state: RootState) => state?.filter.filterError);
  /** Начальные значения дат */
  const initialValues = {
    range: [dayjs(), dayjs().add(7, 'days')],
  };
  useEffect(() => {
    const newFields: any = [];
    fields &&
      fields.forEach((item) => {
        if (item.fieldType.id !== 12) {
          newFields.push(
            <Select.Option key={item.origin} value={item.origin}>
              {item.caption}
            </Select.Option>
          );
        }
      });
    setAttributeOptions(newFields);
  }, [fields]);
  /** Очистка фильтра */
  const onReset = () => {
    formHistory.resetFields();
    dispatch(setHistorySearchValue(null));
    dispatch(setFilterReset(true));
  };
  /** Сброс фильтра при сворачивании панели */
  const onResetHistoryFilterForm = () => {
    if (filterErrorFlag === true && isEmpty(isActive)) {
      formHistory.resetFields();
      dispatch(setHistorySearchValue(null));
      dispatch(setFilterReset(false));
    }
  };
  const getFilterBody = async (changedValues: any, allValues: any) => {
    formHistory
      .validateFields()
      .then(() => {
        const {fields, range} = allValues;
        const filterBody = {
          '%history_filter': {
            attributes: fields,
            dateFrom: `${range[0].format('YYYY-MM-DD')}T00:00:00.000+0300`,
            dateTo: `${range[1].format('YYYY-MM-DD')}T23:59:59.000+0300`,
          },
        };
        dispatch(setHistorySearchValue(filterBody));
      })
      .catch((errorInfo: any) => {
        const {errorFields} = errorInfo;
        if (errorFields.length) dispatch(setFilterError(true));
        else {
          const {fields, range} = allValues;
          const filterBody = {
            '%history_filter': {
              attributes: fields,
              dateFrom: `${range[0].format('YYYY-MM-DD')}T00:00:00.000+0300`,
              dateTo: `${range[1].format('YYYY-MM-DD')}T23:59:59.000+0300`,
            },
          };
          dispatch(setHistorySearchValue(filterBody));
          dispatch(setFilterError(false));
        }
      });
  };

  return (
    <div onClick={onResetHistoryFilterForm}>
      <Collapse
        className="catalogsFilters__collapse"
        onChange={(key) => {
          setIsActive(key as any);
        }}
      >
        <Panel key="historyFilter" header={catalogsFilters.historySearch}>
          <Form
            name="history_filter"
            form={formHistory}
            onValuesChange={getFilterBody}
            validateMessages={validateMessages}
            initialValues={initialValues}
          >
            <div className="d-flex flex-column gap-20">
              <div className={classNames('d-flex filter-item mb-0 w-100', styles['filter-item'])}>
                <div
                  className={classNames(
                    'd-flex flex-column align-items-start  p-0 justify-content-center',
                    styles['operator-block']
                  )}
                  style={{width: 180}}
                >
                  <p className="mb-0">Период выборки</p>
                </div>
                <div className="d-flex flex-column w-100">
                  <Form.Item name="range" className="m-0 mb-0" rules={[{required: true}]}>
                    <RangePicker style={{width: '100%', maxWidth: '660px'}} format="DD.MM.YYYY" />
                  </Form.Item>
                </div>
              </div>
              <div className={classNames('d-flex filter-item mb-0 w-100', styles['filter-item'])}>
                <div
                  className={classNames(
                    'd-flex flex-column align-items-start operator-block p-0 justify-content-center',
                    styles['operator-block']
                  )}
                  style={{width: 180}}
                >
                  <p className="mb-0">Выберите атрибуты</p>
                </div>
                <div className="d-flex flex-column w-100">
                  <Form.Item name="fields" rules={[{required: true}]} className="m-0 mb-0">
                    <Select mode="multiple" style={{width: '100%', maxWidth: '660px'}} placeholder="Выберите атрибуты">
                      {attributeOptions}
                    </Select>
                  </Form.Item>
                </div>
              </div>
              <Form.Item wrapperCol={{offset: 20}}>
                <div className="d-flex justify-content-end">
                  <Button className="mr-1" onClick={onReset} danger ghost icon={<DeleteOutlined />}>
                    {catalogsFilters.reset}
                  </Button>
                </div>
              </Form.Item>
            </div>
          </Form>
        </Panel>
      </Collapse>
    </div>
  );
};

export default React.memo(FilterHistoryChanges);
