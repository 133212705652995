import type {ThemeConfig} from 'ui-kit';

export const themeMDM: ThemeConfig = {
  token: {
    colorPrimary: '#597EF7',
    colorText: 'rgba(0, 0, 0, 0.88)',
    colorTextSecondary: 'rgba(0, 0, 0, 0.65)',
    colorError: '#F5222D',
    colorSplit: '#f0f0f0',
    colorIcon: 'rgba(0, 0, 0, 0.45)',
    fontFamily: '"Inter", sans-serif',
  },
  components: {
    Tooltip: {
      colorBgDefault: '#fff',
      colorTextLightSolid: 'rgba(0, 0, 0, 0.88)',
    },
  },
};
