import React from 'react';
import {Form, Button, DatePicker} from 'ui-kit';

const VersionsByDateForm = ({onFinish}) => {
  return (
    <div>
      <Form className="d-flex gap-8 mb-0" name="versions-by-date" onFinish={onFinish}>
        <Form.Item name="date" label="Показать версию на дату" className="mb-0">
          <DatePicker format={'DD.MM.YYYY'} style={{width: 160}} className="mb-0" />
        </Form.Item>
        <Form.Item className="mb-0">
          <Button htmlType="submit" type="primary">
            Показать
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default VersionsByDateForm;
