import React, {ReactElement, useCallback, useEffect, useMemo, useState} from 'react';
import {Layout, Menu, Button, Modal, MenuItemGroupType, MenuItemType} from 'ui-kit';
import styles from './Sidebar.module.scss';
import {MenuUnfoldOutlined, MenuFoldOutlined} from '@ant-design/icons';
import {useSiderCollapse} from 'hooks';
import {Link} from 'react-router-dom';

import {convertStringToIcon} from 'ui-kit';
import HelpInfoModal from 'components/HelpInfoModal/HelpInfoModal';
import Logo from 'assets/images/logo.svg';
import classNames from 'classnames';
import {IRoute} from 'types';
import {useMenuItems, useBreadCrumbs} from 'hooks/navigation';
import {useProfileMenu} from './hooks/useProfileMenu';
const {Sider} = Layout;

export interface SidebarProps {
  keycloakLogout: () => void;
}

const Sidebar = ({keycloakLogout}: SidebarProps): ReactElement => {
  useMenuItems();
  const breadCrumbs = useBreadCrumbs();
  const items = useMenuItems();
  const {collapsed, setCollapsed} = useSiderCollapse();

  const [visibleHelpModal, setVisibleHelpModal] = useState(false);
  const [openKeysState, setOpenKeysState] = useState<string[]>([]);

  const activeKeys = useMemo(() => {
    return breadCrumbs.map((breadCrumb) => `${breadCrumb.id}`);
  }, [breadCrumbs]);

  const profileMenuItems = useProfileMenu({
    keycloakLogout,
    showModal: () => setVisibleHelpModal(true),
  });

  const openKeys = useMemo(() => {
    if (collapsed) {
      return openKeysState;
    }
    return [...openKeysState, ...activeKeys];
  }, [activeKeys, collapsed, openKeysState]);

  const prepareItem = useCallback(
    (item: IRoute, index: number): MenuItemType | MenuItemGroupType => {
      const renderLabel = () => {
        if (!item.navLink) {
          return item.title;
        }
        return <Link to={item.navLink}>{item.title}</Link>;
      };

      const newItem: MenuItemType | MenuItemGroupType = {
        label: renderLabel(),
        key: `${item.id || '_menu_' + index}`,
        icon: convertStringToIcon(item.icon),
      };
      const childrenItems = items.filter((childItem) => childItem.parent === item.id && childItem.showMenu === true);

      if (childrenItems.length) {
        (newItem as MenuItemGroupType).children = childrenItems.filter((item) => item.showMenu).map(prepareItem);
      }

      return newItem;
    },
    [items]
  );

  const preparedMenuItems: (MenuItemType | MenuItemGroupType)[] = useMemo(() => {
    const navigationItems = items.filter((item) => !item.parent && item.showMenu).map(prepareItem);
    return [...navigationItems, ...profileMenuItems];
  }, [items, prepareItem, profileMenuItems]);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const onOpenChange = (keys: string[]) => {
    setOpenKeysState(keys);
  };

  // const onMenuClick = useCallback(
  //   (info: Record<string, any>) => {
  //     if (info.key === 'about') {
  //       setOpenKeysState(openKeys.filter((item) => item !== 'user_menu'));
  //     }
  //   },
  //   [openKeys]
  // );

  return (
    <div className={classNames(styles.wrapperSider, {[styles.collapsed]: collapsed})}>
      <Sider collapsed={collapsed} className={styles.sider} theme="light" width="100%">
        <div className={styles.logo_and_button}>
          <div className={styles.logo}>
            <img src={Logo} />
          </div>
          <div className={styles.collapsedWrapper}>
            <Button
              className={classNames(styles.collapsedButton, {
                [styles.collapsed]: collapsed,
              })}
              onClick={toggleCollapsed}
            >
              {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            </Button>
          </div>
        </div>
        <Menu
          className={styles.siderMenu}
          mode="inline"
          items={preparedMenuItems}
          openKeys={openKeys}
          selectedKeys={activeKeys}
          onOpenChange={onOpenChange}
          // onClick={onMenuClick}
        />
      </Sider>
      <Modal
        title="О программе"
        open={visibleHelpModal}
        width={600}
        footer={null}
        onCancel={() => setVisibleHelpModal(false)}
      >
        <HelpInfoModal />
      </Modal>
    </div>
  );
};

export default Sidebar;
