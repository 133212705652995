import React from 'react';
import {Button, Form, Input, Select, DatePicker} from 'ui-kit';
import DynamicSelect from '../../FormElements/DynamicSelect';
import locale from 'antd/es/date-picker/locale/ru_RU';

const {RangePicker} = DatePicker;

export interface SimpleFilterProps {
  fields: any[];
  onFilterSearch: () => void;
  onFilterClear: () => void;
}

const SimpleFilter: React.FC<SimpleFilterProps> = ({fields, onFilterSearch, onFilterClear}) => {
  const [form] = Form.useForm();

  const layout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
  };

  const renderComboBox = (item: any) => {
    const {options} = item;
    return (
      <Select>
        {options &&
          options.combobox_options.map((item: any, index: number) => {
            return (
              <Select.Option key={index} value={item.value}>
                {item.label}
              </Select.Option>
            );
          })}
      </Select>
    );
  };

  const renderFieldByType = (item: any) => {
    switch (item.fieldType) {
      case 'combobox':
        return renderComboBox(item);
      case 'rangePicker':
        return <RangePicker locale={locale} />;
      case 'datePicker':
        return <DatePicker locale={locale} />;
      case 'reference': {
        const {fetchOptions} = item;
        return (
          <DynamicSelect
            style={{width: '100%'}}
            placeholder="Выбрать"
            // notfoundcontentplaceholder="Введите запрос"
            mode={item.mode}
            feachoptions={{
              referenceStore: fetchOptions.store,
              url: fetchOptions.url,
              method: fetchOptions.method,
              displayedField: fetchOptions.displayedField,
              valueField: fetchOptions.valueField,
              valueSearchName: 'search',
              defaultSize: 20,
            }}
            handleSelectChange={(value: any) => {
              form.setFieldsValue({[item.origin]: value});
            }}
          />
        );
      }
      default:
        return <Input />;
    }
  };

  const renderFields = (fields: any[]) => {
    return fields.map((item: any, index: number) => {
      return (
        <Form.Item label={item.caption} key={index} name={item.origin}>
          {renderFieldByType(item)}
        </Form.Item>
      );
    });
  };

  return (
    <Form {...layout} name="doc-filter" onFinish={onFilterSearch} form={form}>
      {fields && renderFields(fields)}
      <div className="mt-2 d-flex justify-content-end">
        <Form.Item className="text-right">
          <Button
            className="mr-3"
            danger
            onClick={() => {
              form.resetFields();
              onFilterClear();
            }}
          >
            Сбросить
          </Button>
        </Form.Item>
        <Form.Item className="text-right">
          <Button className="mr-3" type="primary" htmlType="submit">
            Применить
          </Button>
        </Form.Item>
      </div>
    </Form>
  );
};

export default SimpleFilter;
