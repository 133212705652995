import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/600.css';

import React from 'react';
import dayjs from 'dayjs';
import ReactDOM from 'react-dom';

import {Provider} from 'react-redux';
import {Router} from 'react-router';
import Keycloak from 'keycloak-js';
// import 'antd/dist/antd.css';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import {ConfigProvider, ruRU} from 'ui-kit';
import 'dayjs/locale/ru';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import {createBrowserHistory} from 'history';
import {ThemeProvider} from 'styled-components';

import App from './modules/App';
import '../node_modules/bootstrap/dist/css/bootstrap.css';
import configureStore from './redux/store/configureStore';
import {defaultTheme} from './components/Themes/defaultTheme';
import {setUserRoles} from './redux/actions/actions';
import HeaderMdm from './components/Header/HeaderMDM';

import './index.scss';
import {themeMDM} from 'themes/mdm-theme';

const history = createBrowserHistory();

const DafailtTZ = 'Europe/Moscow';

dayjs.extend(utc);
dayjs.extend(timezone);

dayjs.locale('ru');
dayjs.tz.setDefault(DafailtTZ);

window._env = Object.assign(window._env as {[key: string]: string}, process.env);

console.log('process.env', process.env);
console.log('window._env', window._env);

// In future: need to update version Keycloak
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const keycloak = new Keycloak({
  url: `${window._env.REACT_APP_SSO_CONFIG_URL}`,
  realm: `${window._env.REACT_APP_SSO_CONFIG_REALM}`,
  clientId: `${window._env.REACT_APP_CLIENT_ID}`,
});

const store = configureStore;
keycloak
  .init({
    onLoad: 'login-required',
    checkLoginIframeInterval: 100,
  })
  .then((authenticated: any) => {
    if (authenticated) {
      const {
        idTokenParsed: {sub},
        realmAccess: {roles},
        token,
      } = keycloak;

      window.sessionStorage.setItem('kctoken', `${token}`);

      store.dispatch(setUserRoles(roles));

      render();
    } else {
      console.error('authenticated=false');
    }
  })
  .catch();

export function keycloakLogout(): void {
  keycloak
    .logout()
    .then(() => {
      window.sessionStorage.removeItem('kctoken');
    })
    .catch((error: Error) => {
      console.error('Ошибка при выходе из Keycloak:', error);
    });
}

function render() {
  ReactDOM.render(
    <Provider store={store}>
      <Router history={history}>
        <ConfigProvider locale={ruRU} theme={themeMDM}>
          <ThemeProvider theme={defaultTheme}>
            <App keycloakLogout={keycloakLogout} />
          </ThemeProvider>
        </ConfigProvider>
      </Router>
    </Provider>,
    document.getElementById('react-container')
  );
}
