import React, {useEffect, useState} from 'react';
import Modeler from 'bpmn-js/lib/Modeler';
import Viewer from 'bpmn-js/lib/Viewer';
import propertiesPanelModule from 'bpmn-js-properties-panel';
import propertiesProviderModule from 'bpmn-js-properties-panel/lib/provider/camunda';
import camundaModdleDescriptor from 'camunda-bpmn-moddle/resources/camunda.json';
import 'bpmn-js/dist/assets/diagram-js.css';
import 'bpmn-js/dist/assets/bpmn-font/css/bpmn-embedded.css';
import 'bpmn-js-properties-panel/dist/assets/bpmn-js-properties-panel.css';
import {fetchFunc} from '../../Utils/security/http/mdm';
import {onError} from '../Helpers/Utils';
import {StyledButton, BaseDiv} from '../Themes/Components';
import {errorModalCreate, showDeleteConfirm} from '../Helpers/Modals';
import {get} from 'lodash';

const BpmnModeller = ({schemeId, store, readonly, editMode}) => {
  const [diagram, setDiagram] = useState('');
  const [modeler, setModeler] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (schemeId) {
      const container = document.getElementById(`container-${store}`);
      if (editMode) {
        setModeler(
          new Modeler({
            container,
            propertiesPanel: {
              parent: `#properties-panel-${store}`,
            },
            additionalModules: [propertiesPanelModule, propertiesProviderModule],
            // needed if you'd like to maintain camunda:XXX properties in the properties panel
            moddleExtensions: {
              camunda: camundaModdleDescriptor,
            },
            keyboard: {
              bindTo: document,
            },
          })
        );
      } else {
        setModeler(
          new Viewer({
            container,
          })
        );
      }
    } else {
      errorModalCreate('Не указан код bpmn процесса');
    }
  }, [editMode]);

  useEffect(() => {
    setLoading(true);
    schemeId && getData();
  }, [schemeId]);

  useEffect(() => {
    diagram && setLoading(true);
    diagram && openDiagramInModeller(diagram);
  }, [diagram]);

  useEffect(() => {
    if (modeler && editMode) {
      const buttonsTypeSubmitRemover = (panel) => {
        const buttons = panel.querySelectorAll('button');
        buttons.forEach((element) => {
          element.setAttribute('type', 'button');
        });
      };
      const panel = document.getElementById(`properties-panel-${store}`);
      panel.addEventListener('click', () => buttonsTypeSubmitRemover(panel));
      return () => {
        panel.removeEventListener('click', () => buttonsTypeSubmitRemover(panel));
      };
    }
  }, [modeler, editMode]);

  const onBpmnError = (response) => {
    const status = get(response, 'status');
    let message = '';
    if (store === 'service') {
      setLoading(false);
      return;
    }
    switch (status) {
      case 404:
        message = 'Исполняемый процесс не найден';
        break;
      case 403:
        message = 'Недостаточно прав для просмотра';
        break;
      default:
        message = get(response, 'data.message');
    }
    errorModalCreate(message);
    setLoading(false);
  };

  const getData = async () => {
    const schemaStore = store === 'service' ? 'mdm' : 'bpmn';
    const url =
      store === 'service' ? `/api/v1/service/${schemeId}/bpmn` : `/api/v1/bpmn/process-definition/${schemeId}/bpmn`;
    try {
      const data = await fetchFunc(
        {
          url,
          method: 'get',
        },
        onBpmnError,
        schemaStore
      );
      const xmlBlob = new Blob([data], {
        type: 'text/xml',
      });
      const file = new File([xmlBlob], `bpmn-${schemeId}.bpmn`);
      readFile(file, function (xml) {
        setDiagram(xml);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const postData = async (file) => {
    setLoading(true);
    let data = new FormData();
    data.append('file', file, file.name);
    try {
      await fetchFunc(
        {
          url: `/api/v1/service/${schemeId}/bpmn`,
          method: 'post',
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          data: data,
        },
        onError
      );
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const deleteData = async () => {
    setLoading(true);
    try {
      await fetchFunc(
        {
          url: `/api/v1/service/${schemeId}/bpmn`,
          method: 'delete',
        },
        onError
      );
      modeler.clear();
      setDiagram('');
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const openDiagramInModeller = async (diagramForOpen) => {
    try {
      await modeler.importXML(diagramForOpen);
    } catch (err) {
      errorModalCreate('could not import BPMN 2.0 XML, see console');
      return console.log('could not import BPMN 2.0 XML', err);
    } finally {
      setLoading(false);
    }
  };

  const saveBpmn = async () => {
    modeler.saveXML().then(({xml}) => {
      const xmlBlob = new Blob([xml], {
        type: 'text/xml',
      });
      const file = new File([xmlBlob], 'diagram.bpmn');
      postData(file);
    });
  };

  const readFile = (file, callback) => {
    try {
      const reader = new FileReader();
      reader.onload = (e) => callback(e.target.result);
      reader.readAsText(file);
    } catch (error) {
      throw error;
      console.error('could not read file', error);
    }
  };

  const onChange = (e) => {
    readFile(e.target.files[0], (xml) => {
      openDiagramInModeller(xml);
      setDiagram(xml);
    });
  };

  const saveFile = () => {
    modeler.saveXML({format: true}, function (error, xml) {
      const canvas = modeler.get('canvas');

      const rootElement = canvas.getRootElement();

      console.log('Process Id:', rootElement.id);
      console.log('Process Name:', rootElement.businessObject.name);

      if (error) {
        return;
      }

      const xmlBlob = new Blob([xml], {
        type: 'text/xml',
      });
      const fileName = `${rootElement.id}.bpmn`;
      const downloadLink = document.createElement('a');

      downloadLink.download = fileName;
      downloadLink.href = window.URL.createObjectURL(xmlBlob);
      downloadLink.onclick = function (event) {
        document.body.removeChild(event.target);
      };
      downloadLink.style.visibility = 'hidden';
      document.body.appendChild(downloadLink);
      downloadLink.click();
    });
  };

  return (
    <>
      {schemeId && (
        <BaseDiv sName="bpmnModeller" className="bpmn-modeler">
          <BaseDiv className="d-flex mb-2">
            {!readonly && !loading && editMode && (
              <BaseDiv>
                {diagram && (
                  <>
                    <StyledButton sName="primaryBtn" className="mr-2" type="primary" onClick={() => saveBpmn()}>
                      Сохранить
                    </StyledButton>
                    <StyledButton
                      sName="dangerBtn"
                      type="danger"
                      onClick={() =>
                        showDeleteConfirm({
                          content: 'Вы действительно хотите удалить файл процесса?',
                          onOk: () => deleteData(),
                        })
                      }
                    >
                      Удалить
                    </StyledButton>
                  </>
                )}
                {!diagram && (
                  <label className="ant-btn-primary bpmn-modeler__input-btn">
                    <input className="bpmn-modeler__file-input" type="file" onChange={onChange} />
                    Добавить
                  </label>
                )}
              </BaseDiv>
            )}
            {diagram && (
              <BaseDiv>
                <StyledButton sName="defaultBtn" type="default" onClick={() => saveFile()}>
                  Скачать
                </StyledButton>
              </BaseDiv>
            )}
          </BaseDiv>
          <BaseDiv className="d-flex">
            <BaseDiv id={`container-${store}`} className="bpmn-modeler__container"></BaseDiv>
            <BaseDiv className="properties-panel-parent col-3" id={`properties-panel-${store}`}></BaseDiv>
          </BaseDiv>
        </BaseDiv>
      )}
    </>
  );
};

export default BpmnModeller;
