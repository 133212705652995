import React from 'react';
import {get} from 'lodash';
import moment from 'moment';
import {defaultDateFormat, renderStatus} from '../Helpers/Utils';
import {Link} from 'react-router-dom';

const VerificatorHead = ({data, catalogUuid, recordUuid}) => {
  const renderVerifierFio = () => {
    return `${get(data, 'sysVerifyInfo.verifier.lastName') || ''} ${get(data, 'sysVerifyInfo.verifier.firstName') || ''} ${get(data, 'sysVerifyInfo.verifier.middleName') || ''}`;
  };

  return (
    <div className="documents__head container mb-5">
      <div className="documents__title mb-3">
        <div className="d-flex mt-3">
          <div className="col-9 documents__info-title">Неверефицированная версия</div>
        </div>
      </div>
      <div className="documents__info">
        <div className="d-flex mt-3">
          <div className="col-8">
            <div className="mt-2">
              <span className="documents__info-label mr-2">Идентификатор:</span>
              <Link to={`/catalog/${catalogUuid}/${recordUuid}/verify`}>{data && data.version}</Link>
            </div>
            <div>
              <span className="documents__info-label mr-2">Автор:</span>
              {data && data.authorFio}
            </div>
            <div>
              <span className="documents__info-label mr-2">Верификатор:</span>
              {renderVerifierFio()}
            </div>
            <div>
              <span className="documents__info-label mr-2">Дата начала верификации:</span>
              {moment(get(data, 'sysVerifyInfo.dateFrom')).format(defaultDateFormat)}
            </div>
            <div>
              <span className="documents__info-label mr-2">Статус:</span>
              {renderStatus(data)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerificatorHead;
