import React, {useCallback, useEffect, useState} from 'react';
import {fetchFunc} from '../../Utils/security/http/mdm';
import _ from 'lodash';
import {charCounter, createReferenceScriptText} from '../Helpers/Utils';

export interface ReferenceReaderProps {
  catalog: string;
  uuid: string;
  code?: string;
  apiUrl?: string;
  apiInfoUrl?: string;
  href?: string;
  referenceCatalogPattern: string;
  referenceScript?: boolean;
  alternativeReferenceCatalogPattern?: string;
  displayedFieldSeparator?: string;
  customView?: any[];
  active?: boolean;
  detailMode?: boolean;
  viewRoute?: string;
  versionId?: string;
  isUnverified?: boolean;
}

const ReferenceReader = ({
  catalog,
  versionId,
  active,
  isUnverified,
  uuid,
  code,
  apiUrl,
  apiInfoUrl,
  href,
  referenceCatalogPattern,
  referenceScript,
  alternativeReferenceCatalogPattern,
  displayedFieldSeparator = ' ',
  customView,
  detailMode,
  viewRoute,
}: ReferenceReaderProps) => {
  const [data, setData] = useState<any>(null);

  const getReferenceInfo = useCallback(
    async (catalog: string, uuid: string) => {
      let depth = detailMode ? 1 : 0;

      if (_.isString(referenceCatalogPattern)) {
        const patternArr = referenceCatalogPattern.split(';');
        depth = charCounter(patternArr[1] || patternArr[0], /[^.]/g);
      }
      let url = `/api/v1/catalogs/${catalog}/items/${uuid}?showRefs=${depth}`;
         if(!active && !isUnverified && versionId) url = `/api/v1/catalogs/${catalog}/items/${uuid}/history/${versionId}?showRefs=${depth}`;
      try {
        const fetchData = await fetchFunc({
          url: apiInfoUrl || url,
          method: 'get',
        });
        setData(fetchData);
      } catch (error) {
        console.log(error);
      }
    },
    [active, apiInfoUrl, detailMode, referenceCatalogPattern, versionId]
  );

  const getReferenceList = useCallback(
    async (catalog: string, code: string) => {
      try {
        const fetchData = await fetchFunc({
          url: apiUrl || `/api/v1/catalogs/${catalog}/items`,
          method: 'get',
        });
        const refItem = fetchData.content.find((item: any) => item.code === code);
        refItem && setData(refItem);
      } catch (error) {
        console.log(error);
      }
    },
    [apiUrl]
  );

  const renderLabel = (data: any) => {
    let text = '';
    if (referenceScript) {
      text = createReferenceScriptText(data, referenceScript);
    } else {
      if (_.isArray(referenceCatalogPattern)) {
        referenceCatalogPattern.forEach((item, index) => {
          text =
            text + _.get(data, item) + (index !== referenceCatalogPattern.length - 1 ? displayedFieldSeparator : '');
        });
      } else {
        const patternArr = referenceCatalogPattern.split(';');
        if (patternArr.length > 2) {
          text = `${data[patternArr[1]]} - ${data[patternArr[2]]}`;
        } else {
          const patternStr = patternArr[1] || patternArr[0];
          if (patternStr.indexOf('.') !== -1) {
            const path = patternStr.split('.');
            text = _.get(data, path);
          } else {
            const textByPath = data[patternStr];
            const textByAlternativePattern =
              alternativeReferenceCatalogPattern && data[alternativeReferenceCatalogPattern];
            if (textByPath) {
              text = textByPath;
            } else if (textByAlternativePattern) {
              text = textByAlternativePattern;
            }
          }
        }
      }
    }
    return text || `Значение c паттерн ${referenceCatalogPattern} не заполнено в базе`;
  };

  const renderCustomView = (data: any) => {
    if (!customView) {
      return null;
    }
    const columnsGreed = Math.floor(12 / customView.length);
    return customView.map((item, index) => {
      const last = index === customView.length - 1;
      return (
        <div className={`col-${columnsGreed} mt-3 pl-0` + (last ? ' pr-0' : '')} key={index}>
          <div className="mb-1">
            <b>{item.label}</b>
          </div>
          <div className="field__item--bordered">{data[item.pattern] || '-'}</div>
        </div>
      );
    });
  };

  useEffect(() => {
    ((catalog && uuid) || apiInfoUrl) && getReferenceInfo(catalog, uuid);
    catalog && code && getReferenceList(catalog, code);
  }, [catalog, uuid, code, apiInfoUrl, getReferenceInfo, getReferenceList]);

  return (
    <div className={customView ? 'd-flex' : ''}>
      {customView ? (
        data && renderCustomView(data)
      ) : (
        <>
          {apiUrl ? (
            data && (referenceCatalogPattern ? renderLabel(data) : data.caption || data.description)
          ) : (
            <a
              href={href || (viewRoute ? `${viewRoute}/${uuid}` : `/catalog/${catalog}/${uuid}`)}
              target="_blank"
              rel="noopener noreferrer"
            >
              {data && (referenceCatalogPattern ? renderLabel(data) : data.caption || data.description)}
            </a>
          )}
        </>
      )}
    </div>
  );
};

export default ReferenceReader;
