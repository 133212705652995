import React from 'react';
import {ExclamationCircleOutlined} from '@ant-design/icons';
import {StyledModal} from '../Themes/Components';
import {Modal} from 'ui-kit';

export function errorModalCreate(text) {
  Modal.error({
    sName: 'errorModal',
    title: 'Ошибка',
    zIndex: 2000,
    content: text.replace(/[:"]/g, ''),
  });
}

export function successModalCreate(title, text) {
  StyledModal.success({
    title: title,
    content: text,
  });
}

export function warningModalCreate(title, text) {
  StyledModal.warning({
    title: title,
    content: text,
  });
}

export function infoModalCreate({title, content}) {
  Modal.info({
    title,
    content,
  });
}

export function confirmModalCreate({title, content, okText, onOk, cancelText, onCancel}) {
  StyledModal.confirm({
    title,
    icon: <ExclamationCircleOutlined />,
    content,
    okText,
    cancelText,
    onOk,
    onCancel,
  });
}

export function showDeleteConfirm({title = 'Внимание!', content, onOk, okText = 'Да', cancelText = 'Нет'}) {
  Modal.confirm({
    title,
    icon: <ExclamationCircleOutlined />,
    content,
    okType: 'primary',
    onOk,
    okText,
    cancelText,
    zIndex: 10000,
  });
}

export const showConfirm = ({title, content, action, okText = 'Ок', cancelText = 'Отмена', onCancel = undefined}) => {
  Modal.confirm({
    title,
    content,
    okText,
    cancelText,
    onOk() {
      action();
    },
    onCancel,
  });
};
