import React from 'react';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import ru from 'react-phone-number-input/locale/ru';
import './PhoneNumber.scss';
import flags from 'react-phone-number-input/flags';
import {PhoneOutlined} from '@ant-design/icons';

const PhoneNumber = ({onChange: onValueChange, disabled, value}) => {
  const onPhoneChange = (updatedValue) => {
    if (value !== updatedValue) onValueChange(updatedValue);
  };

  return (
    <PhoneInput
      flags={flags}
      disabled={disabled}
      labels={ru}
      value={value}
      onChange={onPhoneChange}
      defaultCountry="RU"
      internationalIcon={PhoneOutlined}
    />
  );
};

export default PhoneNumber;
