import React, {useMemo, useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useHistory} from 'react-router';
import {
  changeSourcePriority,
  deleteSourcePriority,
  getCatalogFields,
  getSourcePriority,
  createCatalogDraft,
  exportOriginData,
} from './effects/actions';
import {modalFields} from './utils/constants';
import {
  UnlockOutlined,
  CheckOutlined,
  CloseOutlined,
  BarChartOutlined,
  EditOutlined,
  ExportOutlined,
} from '@ant-design/icons';
import cn from 'classnames';
import {Modal, Select, Table, Button, Tooltip, Input, notification} from 'ui-kit';
import PriorityModal from './PriorityModal';
import AccessRights from '../AccessRights';
import {includes, isEmpty} from 'lodash';
import style from './Style.module.scss';

const Attributes = ({referenceOrigin, referenceUuid, editSigns, prioritySupport, priorityModalVisible}) => {
  const [api, contextHolder] = notification.useNotification();
  const dispatch = useDispatch();
  const history = useHistory();

  const columns = [
    {
      title: 'Наименование',
      dataIndex: 'caption',
      key: 'caption',
    },
    {
      title: 'Код',
      dataIndex: 'origin',
      key: 'origin',
    },
    {
      title: 'Тип поля',
      dataIndex: ['fieldType', 'caption'],
      key: 'fieldType',
    },
    {
      title: 'Описание',
      dataIndex: 'description',
      key: 'description',
    },
  ];

  const [displayedColumns, setDisplayedColumns] = useState(['caption', 'origin', 'fieldType']);
  const [attrModalShow, setAttrModalShow] = useState({
    visible: false,
    record: null,
  });
  const [rulesModalShow, setRulesModalShow] = useState({
    visible: false,
    recordUuid: null,
  });
  const [priorityModalShow, setPriorityModalShow] = useState({
    visible: false,
    fieldUuid: null,
  });
  const [priorityPage, setPriorityPage] = useState(0);
  const [prioritySize, setPrioritySize] = useState(10);

  const fields = useSelector(({cartWidgets}) => cartWidgets.catalogFields);
  const loadings = useSelector(({cartWidgets}) => cartWidgets.loadings);
  const sourcePriority = useSelector(({cartWidgets}) => cartWidgets.sourcePriority);

  useEffect(() => {
    referenceOrigin && dispatch(getCatalogFields(referenceOrigin));
  }, [dispatch, referenceOrigin]);

  useEffect(() => {
    priorityModalVisible && dispatch(getSourcePriority(priorityModalShow.fieldUuid, priorityPage, prioritySize));
  }, [priorityModalVisible, prioritySize, priorityPage, priorityModalShow, dispatch]);

  const columnsForRender = useMemo(() => {
    let resultColumns = [];
    columns.forEach((item) => {
      if (includes(displayedColumns, item.key)) resultColumns.push(item);
    });
    if (editSigns && editSigns.editProperties) {
      resultColumns.unshift({
        key: 'actions',
        width: '80px',
        render: (_text, record) => {
          return (
            <div>
              <Tooltip title="Настройка доступа">
                <UnlockOutlined
                  className={cn('mr-2', style.icon)}
                  onClick={() =>
                    setRulesModalShow({
                      visible: true,
                      recordUuid: record.uuid,
                    })
                  }
                />
              </Tooltip>
              {record.fieldType.id !== 12 && prioritySupport && (
                <Tooltip title="Настроить приоритет источников">
                  <BarChartOutlined
                    className={style.icon}
                    onClick={() => {
                      setPriorityModalShow({
                        visible: true,
                        fieldUuid: record.uuid,
                      });
                      dispatch(getSourcePriority(record.uuid, priorityPage, prioritySize));
                    }}
                  />
                </Tooltip>
              )}
            </div>
          );
        },
      });
    }
    return resultColumns;
  }, [displayedColumns, columns, editSigns]);

  const renderFieldValue = (record, origin) => {
    let value;
    let addr = origin.split('.');
    if (addr.length > 1) {
      value = record && record[addr[0]] && record[addr[0]][addr[1]];
    } else {
      value = record && record[addr[0]];
    }
    switch (typeof value) {
      case 'boolean':
        return value ? <CheckOutlined /> : <CloseOutlined />;
      case 'string':
        return value;
      case 'object':
        if (record.options && record.options.combobox_options && record.options.combobox_options.length) {
          return record.options.combobox_options.map((item) => <div key={item.key}>{item.value}</div>);
        }
        break;
      default:
        return '-';
    }
  };

  const renderFieldAttributes = (record) => {
    let newModalFields = [...modalFields];
    const disabledTypes = [10, 12, 14, 15];
    const fieldType = record && record.fieldType?.id;
    if (!includes(disabledTypes, fieldType)) {
      newModalFields.push(
        {title: 'Группа уникальных атрибутов', origin: 'uniqueIndex'},
        {title: 'Уникальный атрибут', origin: 'uniqueSingleIndex'}
      );
    }
    switch (fieldType) {
      case 4:
        newModalFields.push(
          {title: 'Справочник - источник', origin: 'referenceCatalog.caption'},
          {title: 'Поле для отображения', origin: 'referenceCatalogPattern'},
          {title: 'Фильтрация значений', origin: 'options.filter'}
        );
        break;
      case 7:
      case 8:
        newModalFields.push(
          {title: 'Формат значения', origin: 'options.date_format'},
          {title: 'Значение по умолчанию', origin: 'options.date_default_value_type'},
          {title: 'Календарных дней от текущей даты', origin: 'options.date_duration'},
          {title: 'Значение', origin: 'default_value'}
        );
        break;
      case 10:
        newModalFields.push({title: 'Значение по умолчанию', origin: 'default_value'});
        break;
      case 14:
      case 15:
        newModalFields.push({title: 'Список значений', origin: 'options.combobox_options'});
        break;
      default:
    }
    return (
      <div className="d-flex flex-wrap">
        {newModalFields.map((item, index) => (
          <div key={index} className="col-12 mb-5">
            <div>
              <strong>{item.title}</strong>
            </div>
            {renderFieldValue(record, item.origin)}
          </div>
        ))}
      </div>
    );
  };

  const openNotificationWithIconForm = (type) => {
    api[type]({
      message: 'Невозможно скрыть текущую колонку, должна быть выбрана хотя бы одна колонка',
      duration: 4,
      closeIcon: false,
    });
  };

  return (
    <div>
      {contextHolder}
      <Input.Search
        placeholder="Укажите наименование атрибута для поиска"
        allowClear
        className="mb-3"
        size="large"
        name="requestString"
        onSearch={(value) => dispatch(getCatalogFields(referenceOrigin, value))}
      />
      <div>
        <div className="d-flex mb-3 justify-content-between">
          <div>
            {editSigns && editSigns.editAttributes && (
              <Button
                type="primary"
                className="mr-2"
                icon={<EditOutlined />}
                onClick={() => dispatch(createCatalogDraft(referenceUuid, history))}
              >
                Изменить
              </Button>
            )}
            <Button
              type="primary"
              ghost
              icon={<ExportOutlined />}
              onClick={() => dispatch(exportOriginData(referenceOrigin))}
            >
              <Tooltip title="Экспортировать структуру справочника в файл">Экспортировать</Tooltip>
            </Button>
          </div>
          <Select
            mode="multiple"
            name="displayedColumns"
            maxTagCount={0}
            maxTagPlaceholder={'Колонки'}
            className={style.select}
            placeholder="Колонки"
            value={displayedColumns}
            onChange={(value) => {
              if (displayedColumns?.length === 1 && value?.length === 0) {
                openNotificationWithIconForm('warning');
              } else {
                setDisplayedColumns(value);
              }
            }}
          >
            {columns.map((item) => {
              return (
                <Select.Option value={item.key} key={item.key}>
                  {item.title}
                </Select.Option>
              );
            })}
          </Select>
        </div>
      </div>
      <Table
        columns={columnsForRender}
        dataSource={[...fields.content]}
        loading={loadings.catalogFields}
        rowKey={(record) => record.uuid}
        onRow={(record) => {
          return {
            onDoubleClick: () =>
              setAttrModalShow({
                visible: true,
                record,
              }),
          };
        }}
        pagination={{
          total: fields.totalElements,
        }}
      />
      <Modal
        title="Просмотр атрибутов"
        open={attrModalShow.visible}
        onCancel={() =>
          setAttrModalShow({
            ...attrModalShow,
            visible: false,
          })
        }
        footer={null}
      >
        {attrModalShow.record && renderFieldAttributes(attrModalShow.record)}
      </Modal>
      <Modal
        title={'Права записи'}
        open={rulesModalShow.visible}
        footer={null}
        onCancel={() =>
          setRulesModalShow({
            ...rulesModalShow,
            visible: false,
          })
        }
        destroyOnClose={true}
        width={800}
      >
        <AccessRights rulesRecordUuid={rulesModalShow.recordUuid} store="fields" referenceOrigin={referenceOrigin} />
      </Modal>

      <PriorityModal
        data={!isEmpty(sourcePriority.content) && sourcePriority.content}
        loading={loadings.sourcePriority}
        totalElements={sourcePriority.totalElements}
        modalVisible={priorityModalShow.visible}
        handleTableChange={({pageSize, current}) => {
          setPriorityPage(pageSize);
          setPrioritySize(--current);
        }}
        createPriority={(values, editableItem) =>
          dispatch(changeSourcePriority(priorityModalShow.fieldUuid, referenceUuid, values, editableItem))
        }
        deletePriority={(priorityId) => dispatch(deleteSourcePriority(priorityModalShow.fieldUuid, priorityId))}
        onCancel={() =>
          setPriorityModalShow({
            ...priorityModalShow,
            visible: false,
          })
        }
      />
    </div>
  );
};

export default Attributes;
