export const ADD_VERIFY_VALUE = 'VERIFY/ADD';
export const ADD_DEDUPLICATION_PROTOCOL = 'ADD_DEDUPLICATION_PROTOCOL';
export const SET_DEDUPLICATION_FORM_PROTOCOL = 'DEDUPLICATION/SET';
export const SET_DEDUPLICATION_JOB_PROTOCOL = 'SET_DEDUPLICATION_JOB_PROTOCOL';
export const SET_ALL_VERIFY_VALUE = 'VERIFY/SET';
export const SET_CHANGED_DETAIL_NAME = 'DETAIL/NAME';
export const SET_CHANGED_DETAIL_FIELD = 'DETAIL/FIELD';
export const SET_CHANGED_DETAIL_MODE = 'DETAIL/MODE';
export const SET_CONTEXT_SEARCH_VALUE = 'FILTER/CONTEXT';
export const SET_USER = 'SET_USER';
export const GET_FIELDS_TYPE = 'GET_FIELDS_TYPE';
export const GET_USERS_ROLES = 'GET_USERS_ROLES';
export const SET_USER_ROLES = 'SET_USER_ROLES';
export const SET_SCHEDULE_EXECUTION = 'SET_SCHEDULE_EXECUTION';
export const SET_FILTER_SEARCH_VALUE = 'FILTER/FILTER';
export const SET_HISTORY_SEARCH_VALUE = 'FILTER/HISTORY';
export const SET_FILTER_RESET = 'FILTER/RESET';
export const SET_DATA_OPTIONS_RESET = ' SET_DATA_OPTIONS_RESET';
export const GET_USER_NAME = 'GET_USER_NAME';
export const SET_VALIDATOR_LENGTH = 'SET_VALIDATOR_LENGTH';
export const SET_NUMERIC_COMPARISON = 'SET_NUMERIC_COMPARISON';
export const GET_HELP_INFO = 'GET_HELP_INFO';
export const SET_FILTER_ERROR = 'FILTER/ERROR';
export const SET_FILTER_ERROR_KEY_ATTRIBUTE = 'SET_FILTER_ERROR_KEY_ATTRIBUTE';
export const SET_RECORD_LOADING = 'LOADING/RECORD';
export const SET_MENU_ACCESS = 'MENU_ACCESS/SET';
export const SET_DATA_OPTIONS = 'SET_DATA_OPTIONS';
export const SET_BREADCRUMB_NAMES = 'NAVIGATE_BREADCRUMB/SET';
export const SET_ACTIVE_MENU_ITEM = 'NAVIGATE_ACTIVE/SET';
export const SET_PAGE_HEADER = 'SET_PAGE_HEADER';
export const SET_PAGE_HEADER_TITLE = 'SET_PAGE_HEADER_TITLE';
export const SET_PAGE_HEADER_LOADING = 'SET_PAGE_HEADER_LOADING';
export const SET_PAGE_HEADER_BUTTONS = 'SET_PAGE_HEADER_BUTTONS';
export const SET_PAGE_HEADER_TABS = 'SET_PAGE_HEADER_TABS';
export const SET_PAGE_HEADER_ACTIVE_TAB = 'SET_PAGE_HEADER_ACTIVE_TAB';
export const SET_PAGE_HEADER_TAG = 'SET_PAGE_HEADER_TAG';
export const SET_PAGE_HEADER_LINES_BLOCK = 'SET_PAGE_HEADER_LINES_BLOCK';
export const SET_PAGE_HEADER_BACK = 'SET_PAGE_HEADER_BACK';
export const SET_PAGE_HEADER_SHOW_DEFAULT_BACK_BUTTON = 'SET_PAGE_HEADER_SHOW_DEFAULT_BACK_BUTTON';
export const SET_PAGE_HEADER_BREADCRUMBS_TITLE = 'SET_PAGE_HEADER_BREADCRUMBS_TITLE';
export const SET_MENU_ITEMS = 'SET_MENU_ITEMS';
export const SET_CATALOG_EDIT_CAPTION = 'CATALOG_EDIT_CAPTION/set';
export const ADD_RULES_DEDUPLICATION = 'ADD_RULES_DEDUPLICATION';
export const RESET_RULES_DEDUPLICATION = 'RESET_RULES_DEDUPLICATION';
export const ADD_ALGORITHM_DEDUPLICATION = 'ADD_ALGORITHM_DEDUPLICATION';
export const SET_SUBMIT_REQUEST = 'SET_SUBMIT_REQUEST';
export const ADD_DEGREE_PROXIMITY_DEDUPLICATION = 'ADD_DEGREE_PROXIMITY_DEDUPLICATION';
export const SET_DEDUPLICATION_ATTRIBUTE_FOR_JOB_TABLE = 'SET_DEDUPLICATION_ATTRIBUTE_FOR_JOB_TABLE';
export const ADD_START_DEDUPLICATION_PROTOCOL = 'ADD_START_DEDUPLICATION_PROTOCOL';
export let SET_DEDUPLICATION_PROTOCOL_INFO = 'SET_DEDUPLICATION_PROTOCOL_INFO';
export let RESET_DEDUPLICATION_PROTOCOL_INFO = 'RESET_DEDUPLICATION_PROTOCOL_INFO';
export const SET_DEDUPLICATION_JOB_PROTOCOL_INFO_DELETE = 'SET_DEDUPLICATION_JOB_PROTOCOL_INFO_DELETE';
export const SET_DEDUPLICATION_PROTOCOLS = 'SET_DEDUPLICATION_PROTOCOLS';
export const SET_DEDUPLICATION_UNARCHIVED_PROTOCOLS = 'SET_DEDUPLICATION_UNARCHIVED_PROTOCOLS';
export const EDIT_JOB_DEDUPLICATION_PROTOCOL = 'EDIT_JOB_DEDUPLICATION_PROTOCOL';
export const SET_DEDUPLICATION_GROUP_PROTOCOL_INFO = 'SET_DEDUPLICATION_GROUP_PROTOCOL_INFO';
export const RESET_DEDUPLICATION_GROUP_PROTOCOL_INFO = 'RESET_DEDUPLICATION_GROUP_PROTOCOL_INFO';
export const SET_DEDUPLICATION_BLOCK_LINK_GROUP = 'SET_DEDUPLICATION_BLOCK_LINK_GROUP';
export const SET_DEDUPLICATION_BLOCK_LINK_FULL_GROUP = 'SET_DEDUPLICATION_BLOCK_LINK_FULL_GROUP';
export const SET_DEDUPLICATION_CANCEL_LINK_GROUP = 'SET_DEDUPLICATION_CANCEL_LINK_GROUP';
export const SET_DEDUPLICATION_SET_LINK_GROUP = 'SET_DEDUPLICATION_SET_LINK_GROUP';
export const SET_SYSTEM_INFO_PROTOCOL = 'SET_SYSTEM_INFO_PROTOCOL';
export const SET_DEDUPLICATION_PROTOCOLS_DELETE = 'SET_DEDUPLICATION_PROTOCOLS_DELETE';
export const COLOR = 'COLOR';
export const CAPTION = 'CAPTION';
export const SET_NOTIFICATION_ID = 'SET_NOTIFICATION_ID';
export const SET_NOTIFICATION_DEACTIVATE = 'SET_NOTIFICATION_DEACTIVATE';
export const SET_NOTIFICATION_ACTIVATE = 'SET_NOTIFICATION_ACTIVATE';
export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION';
export const SET_NOTIFICATION_LIST = 'SET_NOTIFICATION_LIST';
export const DELETE_NOTIFICATION_TEMPLATE = 'DELETE_NOTIFICATION_TEMPLATE';
export const SET_NOTIFICATION_TEMPLATE = 'SET_NOTIFICATION_TEMPLATE';
export const RESET_NOTIFICATION_TEMPLATE = 'RESET_NOTIFICATION_TEMPLATE';
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const SEND_SUCCESS_MESSAGE = 'SEND_SUCCESS_MESSAGE';
export const EDIT_NOTIFICATION_TEMPLATE = 'EDIT_NOTIFICATION_TEMPLATE';
export const TEST_NOTIFICATION = 'TEST_NOTIFICATION';
export const ADD_NOTIFICATION_TEMPLATE = 'ADD_NOTIFICATION_TEMPLATE';
export const SET_NOTIFICATION_TEMPLATE_LIST = 'SET_NOTIFICATION_TEMPLATE_LIST';
export const LOADING_JOBS_DEDUPLICATION = 'LOADING_JOBS_DEDUPLICATION';
export const LOADING_PROTOCOLS_DEDUPLICATION = 'LOADING_PROTOCOLS_DEDUPLICATION';
export const LOADING_PROTOCOLS_UNARCHIVED_DEDUPLICATION = 'LOADING_PROTOCOLS_UNARCHIVED_DEDUPLICATION';
export const LOADING_NOTIFICATION_TEMPLATES = 'LOADING_NOTIFICATION_TEMPLATES';
export const LOADING_NOTIFICATION_LIST = 'LOADING_NOTIFICATION_LIST';
export const SET_NOTIFICATION_SUBSCRIPTION_LIST = 'SET_NOTIFICATION_SUBSCRIPTION_LIST';
export const SET_FILTERED_SUBSCRIPTION = 'SET_FILTERED_SUBSCRIPTION';
export const SET_RECORD_ID = 'SET_RECORD_ID';
export const SET_EMAILS_FOR_TEST = 'SET_EMAILS_FOR_TEST';
export const SET_PHONE_NUMBER_FOR_TEST = 'SET_PHONE_NUMBER_FOR_TEST';
export const SET_REFERENCE_CATALOG_PATTERN_ORIGIN = 'SET_REFERENCE_CATALOG_PATTERN_ORIGIN';
export const SET_CURRENT_CATALOG = 'SET_CURRENT_CATALOG';
export const SET_COMPARED_VERSIONS = 'SET_COMPARED_VERSIONS';
