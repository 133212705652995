import {routes} from 'config/routes';
import {useMemo} from 'react';
import {matchPath, useLocation} from 'react-router';

export const useActiveMenuItem = () => {
  const location = useLocation();

  const activeMenuItem = useMemo(() => {
    return routes.find((route) => {
      const match = matchPath(location.pathname, {path: route.path, exact: true});
      return match !== null;
    });
  }, [location.pathname]);

  return activeMenuItem;
};
