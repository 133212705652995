import React, {useState, useEffect} from 'react';
import {DeleteOutlined} from '@ant-design/icons';

import useCRUD from '../../hooks/CRUD.hook';
import {errorModalCreate} from '../../components/Helpers/Modals';
import SubsystemForm from '../../components/SystemsForms/SubsystemForm';
import ListWithAddForm from '../../components/ListWithAddForm/ListWithAddForm';
import {PaginationProps} from 'ui-kit';

const apiUrl = '/api/v1/subsystem';

const SubsystemsList = () => {
  const columns = [
    {
      title: 'Наименование',
      dataIndex: 'caption',
    },
    {
      title: 'Код',
      dataIndex: 'code',
    },
    {
      title: 'Внешняя система',
      dataIndex: ['externalSystem', 'caption'],
    },
    {
      title: 'Идентификатор',
      dataIndex: 'id',
    },
    {
      title: '',
      key: 'del',
      align: 'center',
      render: (text: string, record: any) => <DeleteOutlined onClick={() => deleteSubsystem(record.id)} />,
    },
  ];
  const [editableItem, setEditableItem] = useState();
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const {getData, deleteData, changeData, data, loading, total} = useCRUD({
    apiUrl,
    idField: 'id',
    onDeleteError: (error) => {
      errorModalCreate(error.data.message);
    },
  });

  useEffect(() => {
    getData({page, size});
  }, [getData, page, size]);

  const changeSubsystem = async (values: any, editableItem: any) => {
    try {
      if (!values.externalSystem || !values.externalSystem.id) {
        values.externalSystem = null;
      }
      await changeData(values, editableItem);
      getData({page, size});
    } catch (error) {}
  };

  const deleteSubsystem = async (uuid: string) => {
    try {
      await deleteData(uuid);
      getData({page, size});
    } catch (error) {}
  };

  const handleTableChange = (paginator: PaginationProps) => {
    paginator.current && setPage(--paginator.current);
    paginator.pageSize && setSize(paginator.pageSize);
  };

  return (
    <ListWithAddForm
      editibleItem={editableItem}
      setEditibleItem={setEditableItem}
      changeData={changeSubsystem}
      handleTableChange={handleTableChange}
      tableConfig={{
        data,
        loading,
        columns,
        total,
      }}
      editCatalog
      addModalTitle="подсистемы"
      FormComponent={SubsystemForm}
    />
  );
};

export default SubsystemsList;
