import React, {Component, Dispatch} from 'react';
import CatalogList from '../../components/CatalogList';
import './Catalogs.scss';
import {DispatchProp, connect} from 'react-redux';
import {RootState} from 'redux/types/rootState';

export interface CatalogsProps {
  history: any;
  breadcrumbNameMap: {
    route: string;
  }[];
  location: Location;
  toggleActive: (value: boolean) => void;
  editRulesMode?: boolean;
}

export interface CatalogsState {
  columns: {
    title: string;
    dataIndex: string;
    key: string;
    render?: (text: string, record: any) => void;
    width?: string;
  }[];
  displayedColumns: string[];
}

class Catalogs extends Component<CatalogsProps, CatalogsState> {
  constructor(props: CatalogsProps) {
    super(props);
    const {history} = this.props;
    this.state = {
      columns: [
        {
          title: 'Название',
          dataIndex: 'caption',
          key: 'caption',
          render: (text: string, record: any) => (
            <div onClick={() => history.push(`/catalog/${record.uuid}`)} style={{cursor: 'pointer'}}>
              {text}
            </div>
          ),
          width: '50%',
        },
        {
          title: 'Код',
          dataIndex: 'origin',
          key: 'origin',
          width: '50%',
        },
      ],
      displayedColumns: ['caption', 'origin'],
    };
  }

  componentDidMount() {
    const {breadcrumbNameMap, location, toggleActive} = this.props;
    if (location) {
      const active = breadcrumbNameMap.find((elem) => elem.route === location.pathname);
      toggleActive(!!active);
    }
  }

  render() {
    const {columns, displayedColumns} = this.state;

    return (
      <CatalogList
        card={true}
        // editible={true}
        columns={columns}
        isContextSearch={true}
        displayedColumns={displayedColumns}
        apiUrl="/api/v1/catalogs"
        sort={'caption,asc'}
        editRulesMode={this.props.editRulesMode}
      />
    );
  }
}

const mapStateToProps = (store: RootState, ownProps: CatalogsProps) => {
  return {breadcrumbNameMap: store.navigate.breadcrumbNames};
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    updateBreadcrumbMap: (obj: any) => {
      dispatch({
        type: 'UPDATE_BREADCRUMB_MAP',
        data: obj,
      });
    },
    toggleActive: (elementRef = false) => {
      dispatch({
        type: 'SET_ACTIVE_MENU_ELEMENT',
        data: elementRef,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Catalogs);
