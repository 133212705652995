import React, {Key, useState} from 'react';
import {Button, ColumnsType, Modal} from 'ui-kit';
import {useDispatch, useSelector} from 'react-redux';
import {
  getSubscriptions,
  deleteSubscription,
  getCatalogs,
  subscribeCatalogs,
  getCatalogueEvents,
  getEventsTypes,
  deleteSubscriptionEvent,
  includeEventsInSubscribe,
} from './effects/actions';
import {CheckCircleOutlined, CloseCircleOutlined, DeleteOutlined, PlusOutlined} from '@ant-design/icons';
import {showDeleteConfirm} from '../../components/Helpers/Modals';
import {ControlledTable, TableControlProps} from '../../components/ControlledTable/controlledTable';
import {RootState} from 'redux/types/rootState';

type RecordType = Record<string, any>;
type TableColumnsType = ColumnsType<any>;

const Subscriptions = ({}) => {
  const dispatch = useDispatch();
  const subscriptions = useSelector(({subscriptions}: RootState) => subscriptions.subscriptions);
  const catalogs = useSelector(({subscriptions}: RootState) => subscriptions.catalogs);
  const eventsTypes = useSelector(({subscriptions}: RootState) => subscriptions.eventsTypes);
  const catalogEvents = useSelector(({subscriptions}: RootState) => subscriptions.catalogEvents);
  const loadings = useSelector(({subscriptions}: RootState) => subscriptions.loadings);

  const subscriptionsColumns: TableColumnsType = [
    {
      title: 'Название',
      dataIndex: 'caption',
      key: 'caption',
      sortDirections: ['descend', 'ascend'],
      sorter: true,
      render: (text: string, record: RecordType) => (
        <Button
          type="link"
          onClick={() =>
            setEventsModal({
              visible: true,
              catalogUuid: record.uuid,
            })
          }
        >
          {text}
        </Button>
      ),
    },
    {
      title: 'Код справочника',
      dataIndex: ['catalog', 'origin'],
      key: 'origin',
    },
    {
      title: '',
      key: 'action',
      width: '36px',
      render: (text: string, record: RecordType) => {
        return (
          <DeleteOutlined
            onClick={() =>
              showDeleteConfirm({
                content: `Вы действительно хотите удалить подписку для справочника "${record.caption}"?`,
                onOk: () => dispatch(deleteSubscription(record.uuid)),
              })
            }
          />
        );
      },
    },
  ];

  const catalogsColumns: TableColumnsType = [
    {
      title: 'Название',
      dataIndex: 'caption',
      key: 'caption',
      sortDirections: ['descend', 'ascend'],
      sorter: true,
    },
    {
      title: 'Код справочника',
      dataIndex: 'origin',
      key: 'origin',
      sortDirections: ['descend', 'ascend'],
      sorter: true,
    },
  ];

  const eventTypesColumns: TableColumnsType = [
    {
      title: 'Название события',
      dataIndex: 'caption',
      key: 'caption',
      sortDirections: ['descend', 'ascend'],
      sorter: true,
      width: '50%',
    },
    {
      title: 'Код события',
      dataIndex: 'code',
      key: 'code',
      sortDirections: ['descend', 'ascend'],
      sorter: true,
      width: '50%',
    },
  ];

  const eventsColumns: TableColumnsType = [
    {
      title: 'Название',
      dataIndex: ['eventCode', 'caption'],
      key: 'caption',
    },
    {
      title: 'Код события',
      dataIndex: ['eventCode', 'code'],
      key: 'code',
    },
    {
      title: '',
      key: 'action',
      width: '36px',
      render: (text: string, record: RecordType) => {
        return (
          <DeleteOutlined
            onClick={() =>
              showDeleteConfirm({
                content: `Вы действительно хотите удалить событие из подписки?`,
                onOk: () => dispatch(deleteSubscriptionEvent(eventsModal.catalogUuid, record.uuid)),
              })
            }
          />
        );
      },
    },
  ];

  const initialSub = {
    visible: false,
    selectedCatalogs: [],
  };

  const initialEventsTypes = {
    visible: false,
    selectedEventsTypes: [],
  };

  const initialEvents = {
    visible: false,
    catalogUuid: null,
  };

  const [subscribeModal, setSubscribeModal] = useState<{visible: boolean; selectedCatalogs: Key[]}>(initialSub);
  const [eventsModal, setEventsModal] = useState(initialEvents);
  const [eventsTypesModal, setEventsTypesModal] = useState<{visible: boolean; selectedEventsTypes: Key[]}>(
    initialEventsTypes
  );

  return (
    <div className="containerWhite d-flex flex-column gap-24">
      <div>
        <Button
          type="primary"
          onClick={() =>
            setSubscribeModal({
              ...subscribeModal,
              visible: true,
            })
          }
          icon={<PlusOutlined />}
        >
          Добавить
        </Button>
      </div>
      <ControlledTable
        getDataAction={getSubscriptions}
        config={{
          columns: subscriptionsColumns,
          contextSearch: true,
          contextPlaceholder: 'Наименование или origin',
          // titleActions: () => (
          //   <div>
          //     <Button
          //       type="primary"
          //       className="ml-2"
          //       onClick={() =>
          //         setSubscribeModal({
          //           ...subscribeModal,
          //           visible: true,
          //         })
          //       }
          //       icon={<PlusOutlined />}
          //     >
          //       Добавить
          //     </Button>
          //   </div>
          // ),
          loading: loadings.subscriptions,
          dataSource: subscriptions.content,
          rowKey: (record: RecordType) => record.uuid,
          totalElements: subscriptions.totalElements,
          bordered: true,
        }}
      />
      <Modal
        open={subscribeModal.visible}
        width={800}
        onCancel={() => setSubscribeModal(initialSub)}
        okButtonProps={{
          disabled: !subscribeModal.selectedCatalogs.length,
          icon: <CheckCircleOutlined />,
        }}
        cancelButtonProps={{
          icon: <CloseCircleOutlined />,
        }}
        onOk={async () => {
          await dispatch(subscribeCatalogs(subscribeModal.selectedCatalogs));
          setSubscribeModal(initialSub);
        }}
        title="Добавление подписки"
        okText="Подтвердить"
        cancelText="Отменить"
      >
        <ControlledTable
          getDataAction={getCatalogs}
          config={{
            columns: catalogsColumns,
            contextSearch: true,
            contextPlaceholder: 'Наименование или origin',
            loading: loadings.catalogs,
            dataSource: catalogs.content,
            rowSelection: {
              selectedRowKeys: subscribeModal.selectedCatalogs,
              onChange: (keys: Key[]) =>
                setSubscribeModal({
                  ...subscribeModal,
                  selectedCatalogs: keys,
                }),
            },
            rowKey: (record: RecordType) => record.uuid,
            totalElements: catalogs.totalElements,
          }}
        />
      </Modal>
      <Modal
        open={eventsModal.visible}
        width={900}
        onCancel={() => setEventsModal(initialEvents)}
        footer={null}
        destroyOnClose
        title="Просмотр событий подписки"
      >
        <div className="d-flex flex-column gap-12">
          <div>
            <Button
              type="primary"
              onClick={() =>
                setEventsTypesModal({
                  ...eventsTypesModal,
                  visible: true,
                })
              }
              icon={<PlusOutlined />}
            >
              Добавить
            </Button>
          </div>
          <ControlledTable
            getDataAction={(tableControl: TableControlProps | any = {}) =>
              getCatalogueEvents(tableControl, eventsModal.catalogUuid)
            }
            config={{
              columns: eventsColumns,
              contextSearch: true,
              contextPlaceholder: 'Наименование или код',
              loading: loadings.catalogEvents,
              dataSource: catalogEvents.content,
              rowKey: (record: RecordType) => record.uuid,
              totalElements: catalogEvents.totalElements,
              bordered: true,
            }}
          />
        </div>
      </Modal>
      <Modal
        open={eventsTypesModal.visible}
        width={900}
        onCancel={() => setEventsTypesModal(initialEventsTypes)}
        okButtonProps={{
          disabled: !eventsTypesModal.selectedEventsTypes.length,
          icon: <CheckCircleOutlined />,
        }}
        cancelButtonProps={{
          icon: <CloseCircleOutlined />,
        }}
        okText="Подтвердить"
        cancelText="Отменить"
        onOk={async () => {
          await dispatch(includeEventsInSubscribe(eventsModal.catalogUuid, eventsTypesModal.selectedEventsTypes));
          setEventsTypesModal(initialEventsTypes);
        }}
        title="Добавление событий подписки"
        zIndex={10000}
      >
        <ControlledTable
          getDataAction={getEventsTypes}
          config={{
            columns: eventTypesColumns,
            contextSearch: true,
            loading: loadings.eventsTypes,
            dataSource: eventsTypes.content,
            rowSelection: {
              selectedRowKeys: eventsTypesModal.selectedEventsTypes,
              onChange: (keys: Key[]) =>
                setEventsTypesModal({
                  ...eventsTypesModal,
                  selectedEventsTypes: keys,
                }),
            },
            rowKey: (record: RecordType) => record.code,
            totalElements: eventsTypes.totalElements,
          }}
        />
      </Modal>
    </div>
  );
};

export default Subscriptions;
