import React from 'react';
import {Form, Select, Checkbox, Button, Tooltip} from 'ui-kit';

export const KYCselectOptions = [
  {value: 'Проверка на стоп-факторы', key: 'stopFactors'},
  {value: 'Проверка службой безопасности', key: 'securityService'},
  {value: 'Антикоррупционная проверка', key: 'antiBribery'},
  {value: 'Проверка по чёрным спискам', key: 'blackLists'},
];

const checkboxOptions = [
  {label: 'Проверка на экстремистов/террористов', value: 'extremist'},
  {label: 'Проверка на банкротство', value: 'bankrupt'},
  {label: 'Проверка на вхождение в санкционные списки', value: 'sanctions'},
  {label: 'Проверка на отсутствие задолженности по налогам', value: 'tax_debts'},
  {label: 'Проверка на связь с офшорными зонами', value: 'offshore'},
];

const KYCCheckForm = ({onSubmit, closeModal, loading}) => {
  const onFinish = async (values) => {
    const {checkType, requiredChecked, checkboxGroup} = values;
    let data = {
      checkType: {
        type: 'string',
        value: checkType,
      },
    };
    if (requiredChecked) {
      data.duration = {
        type: 'integer',
        value: 0,
      };
    }
    if (checkType === 'stopFactors') {
      let criteries = checkboxGroup.map((item) => {
        return {critType: `${item}`};
      });
      data.criterionTypeMap = {
        type: 'Object',
        value: JSON.stringify(criteries),
        valueInfo: {
          objectTypeName: 'java.util.ArrayList',
          serializationDataFormat: 'application/json',
        },
      };
    }
    try {
      await onSubmit(data);
      closeModal();
    } catch (error) {}
  };

  return (
    <Form
      onFinish={onFinish}
      initialValues={{
        checkType: 'stopFactors',
      }}
    >
      <Form.Item name="checkType" label="Тип проверки">
        <Select>
          {KYCselectOptions.map((item) => (
            <Select.Option key={item.key} value={item.key}>
              {item.value}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item noStyle shouldUpdate={(prev, next) => prev.checkType !== next.checkType}>
        {({getFieldValue}) => {
          const type = getFieldValue('checkType');
          if (type === 'securityService' || type === 'antiBribery') {
            return (
              <Form.Item
                name="requiredChecked"
                label="Принудительная ручная проверка"
                valuePropName="checked"
                initialValue={false}
              >
                <Tooltip
                  title="Если признак установлен, пользовательское задание на проверку будет сформировано принудительно.
                            Иначе - будет осуществлён поиск действующей проверки контрагента. Пользовательское задание будет сформировано,
                            если действующая проверка не будет найдена"
                >
                  <Checkbox />
                </Tooltip>
              </Form.Item>
            );
          }
          if (type === 'stopFactors') {
            return (
              <Form.Item
                name="checkboxGroup"
                label=""
                valuePropName="checked"
                rules={[{required: true, message: 'Критерии не отмечены'}]}
              >
                <Checkbox.Group options={checkboxOptions} />
              </Form.Item>
            );
          }
        }}
      </Form.Item>
      <Form.Item wrapperCol={{offset: 14}}>
        <Button type="primary" htmlType="submit" loading={loading}>
          Запустить проверку
        </Button>
        <Button onClick={closeModal} className="ml-1">
          Отмена
        </Button>
      </Form.Item>
    </Form>
  );
};

export default KYCCheckForm;
