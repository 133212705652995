import {Input, Rule} from 'ui-kit';
import React from 'react';
import styles from '../EditFieldForm.module.scss';
import {CheckboxAccessValue, DefaultValue, FieldOrder} from '../helpers/FormComponents';

interface Props {
  isDisabled: boolean;
  detail: any;
  defaultValue?: boolean;
}

const DecimalFieldForm = ({isDisabled, detail, defaultValue}: Props) => {
  const rules: Rule[] = [
    {
      required: false,
      message: `Допустимое значение - десятичное число`,
      pattern: /^[0-9]*[.,]?[0-9]+$/,
    },
  ];

  return (
    <>
      <div className={styles.twoColumns}>
        <FieldOrder />
        {defaultValue && (
          <DefaultValue rules={rules}>
            <Input disabled={isDisabled} />
          </DefaultValue>
        )}
      </div>
      {/* MDM-1769 */}
      {/* {!detail && <CheckboxAccessValue isDisabled={isDisabled} />} */}
    </>
  );
};

export default React.memo(DecimalFieldForm);
