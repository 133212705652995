import React, {useMemo, useState} from 'react';
import {Input, Checkbox, Button, Select, Divider, Form} from 'ui-kit';
import {checkBdNameFormat, checkingForExtraSpacesInString} from '../CatalogItem/helpers/utils';
import {createDraftItem} from '../CatalogItem/effects/actions';
import {CloseOutlined, SaveOutlined} from '@ant-design/icons';
import {useSiderCollapse} from 'hooks';
import * as H from 'history';

export interface CatalogItemAddFormProps {
  history: H.History;
  showTitle?: boolean;
}

const CatalogItemAddForm = ({history, showTitle = true}: CatalogItemAddFormProps) => {
  const [form] = Form.useForm();
  const {windowWidth} = useSiderCollapse();
  const [loading, setLoading] = useState(false);

  const createDraft = async (values: any) => {
    setLoading(true);
    try {
      const resultValues = {...values};
      if (values.options) resultValues.options = JSON.parse(values.options);
      else resultValues.options = null;
      const data = await createDraftItem(resultValues);
      history.push(`/catalog-draft/${data.uuid}`);
    } catch {
    } finally {
      setLoading(false);
    }
  };

  const requiredRule = {required: true, message: 'Поле обязательно для заполнения'};
  const widthFormClass = useMemo(() => {
    if (windowWidth <= 1024) {
      return 'w-100';
    }
    if (windowWidth <= 1650) {
      return 'w-75';
    }
    return 'w-50';
  }, [windowWidth]);

  return (
    <Form
      layout={'vertical'}
      form={form}
      name="catalog-item-add"
      onFinish={createDraft}
      initialValues={{
        validateRecords: false,
        prioritySupport: false,
        inputType: 'mixed',
      }}
      className={widthFormClass}
    >
      {showTitle && (
        <>
          <h5>Создание справочника</h5>
          <Divider />
        </>
      )}

      <Form.Item
        label="Наименование справочника"
        name="catalogCaption"
        rules={[
          requiredRule,
          {
            validator: checkingForExtraSpacesInString,
          },
        ]}
      >
        <Input placeholder="Введите наименование справочника" />
      </Form.Item>
      <Form.Item
        label="Наименование в БД"
        name="origin"
        extra="Допустимо использовать английские буквы, цифры, нижние подчеркивания. На первом месте строчная буква."
        rules={[
          requiredRule,
          {
            validator: checkBdNameFormat,
          },
        ]}
      >
        <Input placeholder="Введите наименование в БД" />
      </Form.Item>
      <Form.Item name="validateRecords" valuePropName="checked">
        <Checkbox>Требуется верификация записей справочника</Checkbox>
      </Form.Item>
      <Form.Item name="prioritySupport" valuePropName="checked">
        <Checkbox>Включен механизм приоритетности источников данных</Checkbox>
      </Form.Item>
      <Form.Item label="Способ введения" name="inputType" rules={[requiredRule]}>
        <Select
          options={[
            {value: 'manual', label: 'Вручную'},
            {value: 'external', label: 'Во внешних источниках данных'},
            {value: 'mixed', label: 'В смешанном режиме'},
          ]}
        />
      </Form.Item>
      {/* <Form.Item label="Дополнительные параметры" name="options" rules={[{validator: checkOptionsFormat}]}>
        <Input.TextArea />
      </Form.Item> */}
      <Form.Item className="justify-content-start">
        <div className="d-flex justify-content-start">
          <Button loading={loading} icon={<SaveOutlined />} className="mr-3" type="primary" htmlType="submit">
            Сохранить
          </Button>
          <Button
            loading={loading}
            type="default"
            icon={<CloseOutlined />}
            onClick={() => history.push('/catalog-draft')}
          >
            Отменить
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};

export default CatalogItemAddForm;
