import React, {useEffect} from 'react';
import CatalogList from '../../../components/CatalogList';
import {Link} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {setPageHeaderShowBackButton} from 'redux/actions/actions';

const columns = [
  {
    title: 'Название',
    dataIndex: 'caption',
    key: 'caption',
    render: (text: string, record: any) => {
      return (
        <Link to={`/verifier-list/catalogs/${record.uuid}`} className="table--link">
          {text || 'Не задано'}
        </Link>
      );
    },
  },
  {
    title: 'Код',
    dataIndex: 'origin',
    key: 'origin',
  },
  {
    title: 'Записи на верификации',
    dataIndex: 'countOnVerificationItems',
    key: 'countOnVerificationItems',
    width: 20,
    align: 'center',
  },
];

const displayedColumns = ['caption', 'countOnVerificationItems'];

const VerifierList = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setPageHeaderShowBackButton(false));
  });
  return (
    <CatalogList
      columns={columns}
      displayedColumns={displayedColumns}
      apiUrl="/api/v1/catalogs"
      additionalParams="&verified_only=true"
      sort={'caption,asc'}
      isContextSearch={true}
      card={true}
      type="verify"
    />
  );
};

export default VerifierList;
