import {CloseOutlined, FormOutlined, SaveOutlined} from '@ant-design/icons';
import {mdm} from 'Utils/constant';
import {Button} from 'ui-kit';
import {ReactElement} from 'react';

export interface AttributesButtonProps {
  isAttrEdit: boolean;
  onCancel: () => void;
  onEditAttributes: () => void;
  onChangeMode: () => void;
  loading: boolean;
  status?: 'Черновик' | string;
}

export const AttributesButton = ({
  isAttrEdit,
  onCancel,
  onEditAttributes,
  onChangeMode,
  loading,
  status,
}: AttributesButtonProps): ReactElement => {
  return (
    <>
      {isAttrEdit ? (
        <div className="d-flex gap-12">
          <Button type="primary" onClick={onEditAttributes} icon={<SaveOutlined />}>
            {mdm.save}
          </Button>
          <Button onClick={onCancel} icon={<CloseOutlined />}>
            {mdm.cancel}
          </Button>
        </div>
      ) : (
        // : status === 'Черновик' ? (
        //   <Button className="ml-auto" type="primary" onClick={onChangeMode} loading={loading} icon={<FormOutlined />}>
        //     {mdm.change}
        //   </Button>
        // )
        ''
      )}
    </>
  );
};
