import {fetchFunc} from '../../../Utils/security/http/mdm';
import {
  SET_SUBSCRIPTIONS_LOADING,
  SET_SUBSCRIPTIONS,
  SET_CATALOGS_LOADING,
  SET_CATALOGS,
  SET_EVENTS_TYPES,
  SET_EVENTS_TYPES_LOADING,
  SET_CATALOG_EVENTS_LOADING,
  SET_CATALOG_EVENTS,
} from './actionTypes';

export const getSubscriptions = ({page = 0, size = 10, sorter, search}) => {
  return async (dispatch) => {
    let searchParam = search ? '&search=' + search : '';
    let sortParam = sorter ? '&sort=' + sorter : '';
    dispatch(setSubscriptionsLoading(true));
    const fetchData = await fetchFunc(
      {
        url: `/api/v1/subscriptions?size=${size}&page=${page}${searchParam}${sortParam}`,
        method: 'get',
      },
      () => dispatch(setSubscriptionsLoading(false))
    );
    dispatch(setSubscriptionsLoading(false));
    dispatch(setSubscriptions(fetchData));
  };
};

export const getCatalogs = ({page = 0, size = 10, sorter, search}) => {
  return async (dispatch) => {
    let searchParam = search ? '&search=' + search : '';
    let sortParam = sorter ? '&sort=' + sorter : '';
    dispatch(setCatalogsLoading(true));
    const fetchData = await fetchFunc(
      {
        url: `/api/v1/catalogs?size=${size}&page=${page}${searchParam}${sortParam}`,
        method: 'get',
      },
      () => dispatch(setCatalogsLoading(false))
    );
    dispatch(setCatalogsLoading(false));
    dispatch(setCatalogs(fetchData));
  };
};

export const getEventsTypes = ({page = 0, size = 10, sorter, search}) => {
  return async (dispatch) => {
    let searchParam = search ? '&search=' + search : '';
    let sortParam = sorter ? '&sort=' + sorter : '';
    dispatch(setEventsTypesLoading(true));
    const fetchData = await fetchFunc(
      {
        url: `/api/v1/event-types?size=${size}&page=${page}${searchParam}${sortParam}`,
        method: 'get',
      },
      () => dispatch(setEventsTypesLoading(false))
    );
    dispatch(setEventsTypesLoading(false));
    dispatch(setEventsTypes(fetchData));
  };
};

export const getCatalogueEvents = ({page = 0, size = 10, sorter, search}, catalogUuid) => {
  return async (dispatch) => {
    let searchParam = search ? '&search=' + search : '';
    let sortParam = sorter ? '&sort=' + sorter : '';
    dispatch(setCatalogEventsLoading(true));
    const fetchData = await fetchFunc(
      {
        url: `/api/v1/subscriptions/${catalogUuid}/events?size=${size}&page=${page}${searchParam}${sortParam}`,
        method: 'get',
      },
      () => dispatch(setCatalogEventsLoading(false))
    );
    dispatch(setCatalogEventsLoading(false));
    dispatch(
      setCatalogEvents({
        ...fetchData,
        catalogUuid: catalogUuid,
      })
    );
  };
};

export const subscribeCatalogs = (catalogs) => {
  return async (dispatch) => {
    const fetchData = await fetchFunc({
      url: `/api/v1/subscriptions/catalogs`,
      method: 'post',
      data: catalogs,
    });
    dispatch(getSubscriptions({}));
  };
};

export const includeEventsInSubscribe = (subscribeUuid, events) => {
  return async (dispatch) => {
    const fetchData = await fetchFunc({
      url: `/api/v1/subscriptions/${subscribeUuid}/events`,
      method: 'post',
      data: events,
    });
    dispatch(getCatalogueEvents({}, subscribeUuid));
  };
};

export const deleteSubscription = (subscriptionUuid) => {
  return async (dispatch) => {
    await fetchFunc({
      url: `/api/v1/subscriptions/${subscriptionUuid}`,
      method: 'delete',
    });
    dispatch(getSubscriptions({}));
  };
};

export const deleteSubscriptionEvent = (subscriptionUuid, eventUuid) => {
  return async (dispatch) => {
    await fetchFunc({
      url: `/api/v1/subscriptions/${subscriptionUuid}/${eventUuid}`,
      method: 'delete',
    });
    dispatch(getCatalogueEvents({}, subscriptionUuid));
  };
};

export const setSubscriptions = (values) => {
  return {
    type: SET_SUBSCRIPTIONS,
    payload: values,
  };
};

export const setCatalogs = (values) => {
  return {
    type: SET_CATALOGS,
    payload: values,
  };
};

export const setEventsTypes = (values) => {
  return {
    type: SET_EVENTS_TYPES,
    payload: values,
  };
};

export const setCatalogEvents = (values) => {
  return {
    type: SET_CATALOG_EVENTS,
    payload: values,
  };
};

export const setCatalogEventsLoading = (value) => {
  return {
    type: SET_CATALOG_EVENTS_LOADING,
    payload: value,
  };
};

export const setSubscriptionsLoading = (value) => {
  return {
    type: SET_SUBSCRIPTIONS_LOADING,
    payload: value,
  };
};

export const setCatalogsLoading = (value) => {
  return {
    type: SET_CATALOGS_LOADING,
    payload: value,
  };
};

export const setEventsTypesLoading = (value) => {
  return {
    type: SET_EVENTS_TYPES_LOADING,
    payload: value,
  };
};
