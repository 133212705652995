import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Button, Form, Popover} from 'ui-kit';
import {isEmpty} from 'lodash';
import Search from 'antd/es/input/Search';

import FilterAttributes from './Components/FilterAttributes/FilterAttributes';
import styles from './CatalogsFilters.module.scss';
import {catalogsFilters} from './helpers/constant';
import SystemInfo from './Components/SystemInfo';
import {
  setContextSearchValue,
  setFilterErrorKeyAttribute,
  setFilterReset,
  setFilterSearchValue,
  setHistorySearchValue,
} from '../../redux/actions/actions';

import './CatalogsFilters.scss';
import FilterContext from './Components/FilterContext';
import FilterHistoryChanges from './Components/FilterHistoryChanges';
import {RootState} from 'redux/types/rootState';
import {FilterField} from 'types/filterTypes';
import {SearchOutlined} from '@ant-design/icons';

export interface CatalogsFiltersProps {
  onContextSearch?: boolean;
  isFilterSearch?: boolean;
  isContextSearch?: boolean;
  fields?: FilterField[];
  onFilterSearch?: boolean;
  onHistorySearch?: boolean;
  onHistoryReset?: () => void;
  applyFilter: (bodyData: any, action?: any) => void;
  isDeduplicationRecordsAll?: boolean;
  itemData?: any;
  catalogListState?: any;
  referenceOrigin?: any;
  referenceUuid?: string;
  conditionFilter?: any;
  isVerifier?: boolean;
  compactMode?: boolean;
  referenceCaption?: any;
  isVerification?: boolean;
}

/** Фильтры справочника */
const CatalogsFilters = (props: CatalogsFiltersProps) => {
  const [formHistory] = Form.useForm();
  const [form] = Form.useForm();
  /** Отправка данных в хранилище */
  const dispatch = useDispatch();
  /** Группы фильтра */
  const [groups, setGroups] = useState<any[]>([]);
  /** Ключ, общего фильтра */
  const [applyKey, setApplyKey] = useState('');
  /** Ключ активной панели */
  const [activeKeyCollapse, setActiveKeyCollapse] = useState<string | string[] | null>(null);
  /** Массив ошибок валидации */
  const [validateError, setValidateError] = useState<any[]>([]);
  /** Ключ, для сброса всех фильтров */
  const [allResetFilterKey, setAllResetFilterKey] = useState<string | null>(null);
  /** Используем хранилище */
  const filterContextSearch = useSelector((state: RootState) => state?.filter.contextString);
  const filterHistory = useSelector((state: RootState) => state?.filter.historyBody);
  const filterAttributes = useSelector((state: RootState) => state?.filter.filterBody);
  /** Валидация на сброс всех фильтров */
  const filtersValidate =
    (!isEmpty(filterAttributes) && !isEmpty(filterHistory)) ||
    (!isEmpty(filterContextSearch) && !isEmpty(filterHistory)) ||
    (!isEmpty(filterAttributes) && !isEmpty(filterContextSearch));
  const {
    onContextSearch,
    isFilterSearch,
    fields,
    onFilterSearch,
    onHistorySearch,
    onHistoryReset,
    applyFilter,
    isDeduplicationRecordsAll,
    itemData,
    catalogListState,
    referenceOrigin,
    referenceUuid,
    isVerification,
    isVerifier,
  } = props;

  const resetFilters = useCallback(() => {
    dispatch(setFilterSearchValue({}));
    dispatch(setContextSearchValue(''));
    dispatch(setHistorySearchValue(null));
  }, [dispatch]);

  useEffect(() => {
    return () => {
      resetFilters();
    };
  }, [resetFilters]);

  /** Фильтруем атрибуты на наличие пропса для контекстного поиска */
  const filterAttribute =
    !isEmpty(fields) &&
    fields
      ?.filter((field) => field?.options?.context_search && field?.options?.context_search === true)
      .map((item) => item.caption)
      // .slice(',')
      .join(', ');
  /** Отправка значений фильтра */
  const onApplyFilter = useCallback(() => {
    form
      .validateFields()
      .then(() => {
        applyFilter({bodyFilter: filterAttributes, bodyFilterhistory: filterHistory, bodySearch: filterContextSearch});
        setApplyKey('key');
        dispatch(setFilterErrorKeyAttribute(false));
      })
      .catch((errorInfo) => {
        const {errorFields} = errorInfo;
        const formIndex = errorFields.map((item: any) => item.name.toString());
        !isEmpty(formIndex) && setValidateError([...formIndex]);
        dispatch(setFilterErrorKeyAttribute(true));
      });
  }, [applyFilter, dispatch, filterAttributes, filterContextSearch, filterHistory, form]);
  /** Очистка всех фильтров */
  const onResetAllFilters = () => {
    form.resetFields();
    formHistory.resetFields();
    setGroups([]);
    setAllResetFilterKey('reset');
    dispatch(setFilterSearchValue(null));
    dispatch(setContextSearchValue(null));
    dispatch(setHistorySearchValue(null));
    dispatch(setFilterReset(true));
  };
  return (
    <div className={styles.catalogsFilters}>
      {onHistorySearch && <SystemInfo referenceOrigin={referenceOrigin} referenceUuid={referenceUuid} />}
      {onContextSearch && (
        <FilterContext
          applyKey={!isEmpty(applyKey) && applyKey}
          applyFilter={applyFilter}
          setApplyKey={setApplyKey}
          filterAttribute={filterAttribute}
          allResetFilter={!isEmpty(allResetFilterKey) && allResetFilterKey}
        />
      )}
      {onHistorySearch && !isVerification && (
        <>
          {(isFilterSearch || onFilterSearch) && (
            <FilterAttributes
              groups={groups}
              setGroups={setGroups}
              setValidateError={setValidateError}
              activeKeyCollapse={activeKeyCollapse}
              setActiveKeyCollapse={setActiveKeyCollapse}
              validateError={validateError}
              incomeFields={fields}
              form={form}
              isDeduplicationRecordsAll={isDeduplicationRecordsAll}
              itemData={itemData}
            />
          )}
          <FilterHistoryChanges
            fields={fields}
            formHistory={formHistory}
            activeKeyCollapse={activeKeyCollapse}
            onFilterSearch={onHistorySearch}
            onHistoryReset={onHistoryReset}
          />
        </>
      )}
      {!isEmpty(catalogListState) && (
        <Search
          size="large"
          suffix={false}
          placeholder={catalogsFilters.placeholderSearch}
          // style={{width: 800}}
          allowClear
          onChange={(event) => {
            dispatch(setContextSearchValue(event.target.value));
          }}
          onSearch={() => {
            onApplyFilter();
          }}
          onPressEnter={() => {
            applyFilter({
              bodyFilter: filterAttributes,
              bodyFilterhistory: filterHistory,
              bodySearch: filterContextSearch,
            });
            setApplyKey('key');
          }}
        />
      )}
      {/* {!isVerifier && isEmpty(catalogListState) && conditionFilter && (onContextSearch || onHistorySearch) && ( */}
      {!isVerifier && isEmpty(catalogListState) && (onContextSearch || onHistorySearch) && (
        <div className={styles.button}>
          <Button type="primary" onClick={onApplyFilter} icon={<SearchOutlined />}>
            {catalogsFilters.search}
          </Button>
          {filtersValidate && (
            <Popover content={catalogsFilters.popoverFreshFilter} className="deduplicationProtocolForm__popover">
              <Button className="catalogsFilters__button--end" type="primary" ghost onClick={onResetAllFilters}>
                {catalogsFilters.freshFilter}
              </Button>
            </Popover>
          )}
        </div>
      )}
    </div>
  );
};

export default React.memo(CatalogsFilters);
