import {Form, Input} from 'ui-kit';
import styles from '../EditFieldForm.module.scss';
import React from 'react';
import {getValidationRules} from '../helpers/utils';
import {CheckboxAccessValue, DefaultValue, FieldOrder} from '../helpers/FormComponents';

interface Props {
  isDisabled: boolean;
  detail: any;
  validator: any;
}

const TextFieldForm = ({isDisabled, detail, validator}: Props) => {
  return (
    <>
      <div className={styles.twoColumns}>
        <FieldOrder />
        <DefaultValue rules={getValidationRules(validator)}>
          <Input disabled={isDisabled} />
        </DefaultValue>
      </div>
      <Form.Item noStyle shouldUpdate={(prevState, nextState) => prevState.phone_mask !== nextState.phone_mask}>
        {({getFieldValue}) => {
          const disabledMask = getFieldValue('phone_mask');
          return (
            <Form.Item label="Маска" name="input_mask" extra="Регулярное выражение javaScript">
              <Input disabled={disabledMask} />
            </Form.Item>
          );
        }}
      </Form.Item>
      {/* MDM-1769 */}
      {/* {!detail && <CheckboxAccessValue isDisabled={isDisabled} />} */}
    </>
  );
};

export default React.memo(TextFieldForm);
