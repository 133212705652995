import React, {useState} from 'react';
import {useHistory, useRouteMatch} from 'react-router';
import InputMask from 'react-input-mask';
import _, {get, has, isArray, isEmpty} from 'lodash';
import {Button, Checkbox, DatePicker, Form, Input, InputNumber, Modal, Popover, Select, Typography} from 'ui-kit';
import isURL from 'validator/lib/isURL';

import {
  getFieldConfig,
  getRelatedFieldsFromRule,
  processRule,
  ruleValuesGenerator,
  searchProcessRule,
} from '../Helpers/RuleConfigurator';
import {
  checkEditible,
  digitRankFormatter,
  hiddenFieldsHandler,
  setDatePickerSettings,
  setExclamation,
  sortFieldsByOrder,
  truncationToNumberByType,
  stepToNumberByType,
} from '../Helpers/Utils';
import PhoneNumber from '../FormElements/PhoneNumber';
import DynamicSelect from '../FormElements/DynamicSelect';
import Records from '../CatalogItemWidgets/Records/Records';
import RecordRedactor from './RecordRedactor';
import StructureRenderer from './StructureRenderer';
import VerifyAlert from '../Verification/VerifyAlert';
import {errorModalCreate} from '../Helpers/Modals';
import {AditionalFields, customEditModal, DetailsMeta} from './meta/meta';

import './RecordRedactor.scss';
import styles from './RecordEditor.module.scss';
import classNames from 'classnames';
import dayjs from 'dayjs';
import RenderValuesArrayField from './RenderValuesArrayField';

const RecordEditor = (props) => {
  const {
    fieldsMeta,
    form,
    data,
    mode,
    referenceOrigin,
    recordUuid,
    structure,
    detailFieldUuid,
    isUnverified,
    setHiddenFields,
    hiddenFields,
    changeOrgData,
    detailUuid,
    rootForm,
    rootValues,
    maxRefsDepth,
    renderCustomTable,
    ecmDocData,
    setEcmDocData,
    versionId,
    isModal = false,
    isEdit,
    changeType,
    validateCatalog,
    onUpdateDataForm,
  } = props;

  const [compositeModalVisible, setCompositeModalVisible] = useState(false);
  const [compositeDetailFieldUuid, setCompositeDetailFieldUuid] = useState(null);
  const [compositeDetailName, setCompositeDetailName] = useState(null);
  const [compositeModalMode, setCompositeModalMode] = useState('create');
  const [compositeInitialValue, setCompositeInitialValue] = useState({});

  const match = useRouteMatch();
  const history = useHistory();

  const renderItem = (field) => {
    const {options, fieldType} = field;
    let cnf = {visible: true};
    let optionsCnf = {};
    const isDisabled = field?.options?.gui_editable !== true || field?.accessReadOnly;

    if (options) {
      if (options.caption) cnf.caption = options.caption;
      if (options.customView) cnf.customView = options.customView;
      if (options.relatedParams) cnf.relatedParams = options.relatedParams;
      if (options.clearFields) cnf.clearFields = options.clearFields;
      if (options.filter) cnf.filter = options.filter;
      if (options.input_mask) cnf.input_mask = options.input_mask;
      if (options.rules) {
        if (_.isString(options.rules)) {
          try {
            optionsCnf = getFieldConfig(JSON.parse(options.rules), data);
          } catch (error) {
            errorModalCreate('Неверный формат у строки rules ' + options.rules);
          }
        } else {
          optionsCnf = getFieldConfig(options.rules, data);
        }
      }
    }

    cnf = {
      ...cnf,
      ...optionsCnf,
    };

    cnf.editable = checkEditible(field, mode);
    switch (fieldType.id) {
      case 2:
      case 21:
      case 3:
        return renderInputNumber(field, cnf, isDisabled);
      case 4:
        return renderDynamicSelect(field, cnf, isDisabled);
      case 7:
        return renderDateTime(field, cnf, 'YYYY-MM-DD HH:mm:ss', 7, isDisabled);
      case 8:
        return renderDateTime(field, cnf, 'YYYY-MM-DD', 8, isDisabled);
      case 10:
        return renderCheckbox(field, cnf, isDisabled);
      case 11:
      case 19:
        return renderTextField(field, cnf, isDisabled);
      case 12: {
        if (field.options && field.options.detailMode === 'select') return renderDynamicSelect(field, cnf, isDisabled);
        else return renderDetail(field, cnf, isDisabled);
      }
      case 14:
        return renderSelect(field, cnf, isDisabled);
      case 15:
        return renderSelect(field, cnf, 'multi', isDisabled);
      case 117:
        return renderCompositeField(field, cnf);
      case 22:
      case 23:
      case 24:
      case 25:
        return (
          <div className={classNames(styles.formItemWrapper, styles.valuesArrayField)}>
            <div className={styles.formItem}>{RenderValuesArrayField(field, cnf, createItemRules, form)}</div>
            {isUnverified && <VerifyAlert name={field.origin} />}
          </div>
        );
      case 'customField':
        return field.options.render(form, recordUuid);
      case 'docTypeDetail': {
        const renderDocTypeTable = field.options.render({
          record: data,
          ecmDocData,
          isEditMode: mode,
          setEcmDocData,
        });
        return formItemWrapper(field, renderDocTypeTable, cnf);
      }
      default:
        return renderInput(field, cnf, isDisabled);
    }
  };

  const createItemRules = ({validation, required, input_mask}, bdRequired, typeId) => {
    let rules = [];
    if (validation) {
      let pattern = validation;
      if (_.isString(validation)) {
        const parts = _.isString(validation) && /\/(.*)\/(.*)/.exec(validation);
        if (parts) pattern = new RegExp(parts[1], parts[2]);
      }
      rules.push({
        pattern: pattern,
        message: 'Введите верное значение',
      });
    } else if (input_mask) {
      let pattern = input_mask;
      if (_.isString(input_mask)) {
        pattern = new RegExp(input_mask);
      }
      rules.push({
        pattern: pattern,
        message: 'Введите верное значение',
      });
    }
    if (bdRequired || required)
      rules.push({
        required: bdRequired || required,
        message: 'Поле обязательно для заполнения',
      });
    if (typeId === 19) {
      rules.push({
        validator(_, value) {
          if (value) {
            try {
              JSON.parse(value);
              return Promise.resolve();
            } catch (error) {
              return Promise.reject('Неверный формат json');
            }
          } else {
            return Promise.resolve();
          }
        },
      });
    }
    if (typeId === 13 || typeId === 25) {
      rules.push({
        validator: (rule, value) => {
          if (value && !isURL(value, {require_protocol: true})) {
            return Promise.reject('Неверный формат URL');
          }
          return Promise.resolve();
        },
      });
    }
    return rules;
  };

  const renderCustomViewItems = (customView, origin, columnsGreed) => {
    let render = [];
    customView.forEach((item) => {
      if (item.pattern !== origin) {
        render.push(
          <Form.Item key={item.pattern} label={item.label} name={item.pattern} className={`col-${columnsGreed}`}>
            <Input disabled={true} />
          </Form.Item>
        );
      }
    });
    return render;
  };

  const formItemWrapper = (item, field, cnf) => {
    const {visibleRule, requiredRule, captionRule, validationRule, maskRule, valueRule, customView} = cnf;
    const {fieldType} = item;
    const columnsGreed = customView && parseInt(12 / customView.length);

    const formItemRender = (cnf) => {
      const fieldTitleRender = item.description ? (
        <Popover content={item.description} placement="topLeft">
          <div className="d-flex align-items-center gap-8">
            {<Typography.Text>{item?.caption}</Typography.Text>}
            {setExclamation(item)}
          </div>
        </Popover>
      ) : (
        <div className="d-flex align-items-center gap-8">
          <Typography.Text>{item?.caption}</Typography.Text>
          {setExclamation(item)}
        </div>
      );

      if (cnf?.value) form.setFieldsValue({[item.origin]: cnf?.value});

      return (
        <>
          {fieldType.id !== 12 || (item.options && item.options.detailMode) ? (
            <div className={styles.formItemWrapper}>
              <Form.Item
                key={item.origin}
                label={fieldTitleRender}
                name={item.origin}
                rules={createItemRules(cnf, item.required, item.fieldType.id)}
                valuePropName={item.fieldType.id === 10 ? 'checked' : 'value'}
                className={styles.formItem}
              >
                {cnf?.mask ? (
                  <InputMask mask={cnf.mask} disabled={item?.options?.gui_editable !== true || item?.accessReadOnly} />
                ) : (
                  field
                )}
              </Form.Item>
              {customView && renderCustomViewItems(customView, item.origin, columnsGreed)}
              {isUnverified && <VerifyAlert name={item.origin} />}
            </div>
          ) : (
            <>
              <div className="ant-form-item-label">
                <label>{fieldTitleRender}</label>
              </div>
              <div className={styles.formItemWrapper}>
                <div className={styles.formItem}>{field}</div>
                {isUnverified && <VerifyAlert name={item.origin} />}
              </div>
            </>
          )}
        </>
      );
    };

    if (visibleRule || requiredRule || captionRule || validationRule || maskRule || valueRule) {
      const getRuleForProcess = (rule) => {
        return isArray(rule) ? searchProcessRule(rule, data) && searchProcessRule(rule, data).rule : rule.rule;
      };

      const getValueForProcess = (rule, valueName) => {
        return isArray(rule) ? searchProcessRule(rule, data)[valueName] : rule[valueName];
      };

      const processVisibility = (flag) => {
        if (flag) {
          setHiddenFields && setHiddenFields(hiddenFieldsHandler(hiddenFields, item.origin, 'delete'));
          return formItemRender(cnf);
        } else {
          setHiddenFields && setHiddenFields(hiddenFieldsHandler(hiddenFields, item.origin, 'add'));
          return null;
        }
      };

      const relatedVisibleFieldsNames =
        visibleRule && visibleRule.rule ? getRelatedFieldsFromRule(getRuleForProcess(visibleRule)) : [];
      const relatedRequiredFieldsNames =
        requiredRule && requiredRule.rule ? getRelatedFieldsFromRule(getRuleForProcess(requiredRule)) : [];
      const relatedCaptionFieldsNames = captionRule ? getRelatedFieldsFromRule(getRuleForProcess(captionRule)) : [];
      const relatedValidationFieldsNames = validationRule
        ? getRelatedFieldsFromRule(getRuleForProcess(validationRule))
        : [];
      const relatedMaskFieldsNames = maskRule ? getRelatedFieldsFromRule(getRuleForProcess(maskRule)) : [];
      const relatedValueFieldsNames = valueRule ? getRelatedFieldsFromRule(getRuleForProcess(valueRule)) : [];

      const relatedFieldsNames = _.concat(
        relatedVisibleFieldsNames,
        relatedRequiredFieldsNames,
        relatedCaptionFieldsNames,
        relatedValidationFieldsNames,
        relatedMaskFieldsNames,
        relatedValueFieldsNames
      );
      return (
        <Form.Item
          key={item.origin + '-update-wrapper'}
          noStyle
          shouldUpdate={(prevState, nextState) =>
            relatedFieldsNames.some((item) => prevState[item] !== nextState[item])
          }
        >
          {({getFieldValue, setFieldsValue}) => {
            const store = detailUuid ? {...rootForm.getFieldValue(), ...getFieldValue()} : getFieldValue;
            const relatedFieldsVisibleValues =
              relatedVisibleFieldsNames && ruleValuesGenerator(relatedVisibleFieldsNames, store, data);
            // дополнительно прокидываем data для частного случая привяки rule к значению вне формы (например serviceType.uuid)
            const relatedFieldsRequiredValues =
              relatedRequiredFieldsNames && ruleValuesGenerator(relatedRequiredFieldsNames, store);
            const relatedFieldsCaptionValues =
              relatedCaptionFieldsNames && ruleValuesGenerator(relatedCaptionFieldsNames, store);
            const relatedFieldsValidationValues =
              relatedValidationFieldsNames && ruleValuesGenerator(relatedValidationFieldsNames, store);
            const relatedFieldsMaskValues =
              relatedMaskFieldsNames && ruleValuesGenerator(relatedMaskFieldsNames, store);
            const relatedFieldsValueValues =
              relatedValueFieldsNames && ruleValuesGenerator(relatedValueFieldsNames, store);

            if (requiredRule) {
              if (has(requiredRule, 'value')) {
                cnf.required = requiredRule.value;
              } else {
                cnf.required = processRule(getRuleForProcess(requiredRule), relatedFieldsRequiredValues);
              }
            }
            if (captionRule) {
              if (
                relatedFieldsCaptionValues &&
                processRule(getRuleForProcess(captionRule), relatedFieldsCaptionValues)
              ) {
                cnf.caption = getValueForProcess(captionRule, 'caption');
              } else {
                cnf.caption = item.caption;
              }
            }
            if (validationRule) {
              if (
                relatedFieldsValidationValues &&
                processRule(getRuleForProcess(validationRule), relatedFieldsValidationValues)
              ) {
                cnf.pattern = getValueForProcess(validationRule, 'pattern');
              } else {
                cnf.pattern = null;
              }
            }
            if (maskRule) {
              if (processRule(getRuleForProcess(maskRule), relatedFieldsMaskValues)) {
                cnf.mask = getValueForProcess(maskRule, 'mask');
              } else {
                cnf.mask = null;
              }
            }
            if (valueRule) {
              if (relatedFieldsValueValues && processRule(getRuleForProcess(valueRule), relatedFieldsValueValues)) {
                if (isArray(valueRule)) {
                  searchProcessRule(valueRule, data).value(getFieldValue, setFieldsValue);
                } else {
                  valueRule.value(getFieldValue, setFieldsValue);
                }
              } else {
                cnf.value = null;
              }
            }
            if (visibleRule) {
              if (has(visibleRule, 'value')) {
                if (visibleRule.value) {
                  return processVisibility(true);
                } else {
                  return processVisibility(false);
                }
              } else {
                if (
                  relatedFieldsVisibleValues &&
                  processRule(getRuleForProcess(visibleRule), relatedFieldsVisibleValues)
                ) {
                  return processVisibility(true);
                } else {
                  return processVisibility(false);
                }
              }
            } else return formItemRender(cnf);
          }}
        </Form.Item>
      );
    } else return formItemRender(cnf);
  };

  const renderFields = (meta) => {
    return (
      <div
        className={classNames(styles.renderFields, {
          [styles.card]: !isModal,
        })}
      >
        {sortFieldsByOrder(meta).map((field) =>
          !field?.options?.old ? (
            <div key={field.origin} className={styles.fieldItem}>
              {renderItem(field)}
            </div>
          ) : null
        )}
      </div>
    );
  };

  const renderInput = (item, cnf, isDisabled) => {
    const {setFieldsValue, getFieldValue} = form;

    let render = <Input disabled={isDisabled} />;

    /* MDM-2125 */
    // if (item.options && item.options.phone_mask) {
    //   render = (
    //     <PhoneNumber
    //       disabled={isDisabled}
    //       onValueChange={(value) => {
    //         setFieldsValue({
    //           [item.origin]: value,
    //         });
    //       }}
    //       incomeValue={getFieldValue(item.origin)}
    //     />
    //   );
    // }

    return formItemWrapper(item, render, cnf);
  };

  const renderTextField = (item, cnf, isDisabled) => {
    const render = <Input.TextArea disabled={isDisabled} />;
    return formItemWrapper(item, render, cnf);
  };

  const openCompositeModalVisible = (detailFieldUuid, detailName, mode, initialValue) => {
    setCompositeDetailFieldUuid(detailFieldUuid);
    setCompositeDetailName(detailName);
    setCompositeModalMode(mode);
    setCompositeModalVisible(true);
    setCompositeInitialValue(initialValue);
  };

  const onCompositeModalSubmit = (item) => {
    const newData = {...data};
    const index = newData[compositeDetailName].findIndex((elem) => elem?.uuid === item?.uuid);
    if (index !== -1) newData[compositeDetailName][index] = item;
    else newData[compositeDetailName].push(item);
    changeOrgData(newData);
  };

  const renderCompositeField = (item, cnf) => {
    const {options} = item;
    const {paramVal, paramName, storeName} = options.extractor;
    const dataField = data && data[storeName] && data[storeName].find((item) => item[paramName] === paramVal);
    const render = (
      <div className="d-flex">
        <div className="col-10 pl-0">{options.convertForDisplay(dataField)}</div>
        <div className="col-2">
          {dataField && dataField.uuid ? (
            <Button onClick={() => openCompositeModalVisible(dataField.uuid, storeName, 'edit')}>Изменить</Button>
          ) : (
            <Button
              onClick={() =>
                openCompositeModalVisible(null, storeName, 'create', {
                  [item.options.extractor.paramName]: item.options.extractor.paramVal,
                })
              }
            >
              Добавить
            </Button>
          )}
        </div>
      </div>
    );

    return formItemWrapper(item, render, cnf);
  };

  const renderInputNumber = (item, cnf, isDisabled) => {
    const {
      fieldType: {id},
    } = item;
    const render = (
      <InputNumber
        style={{width: '100%'}}
        disabled={isDisabled}
        decimalSeparator={'.'}
        step={stepToNumberByType('0.00', id, 2, false)}
        parser={(value) => truncationToNumberByType(value, id, 2)}
      />
    );
    return formItemWrapper(item, render, cnf);
  };

  const renderCheckbox = (item, cnf, isDisabled) => {
    const {setFieldsValue} = form;
    const render = (
      <Checkbox
        disabled={!cnf.editable || isDisabled}
        onChange={() =>
          item.options &&
          item.options.onChangeHandler &&
          item.options.onChangeHandler(setFieldsValue, data[item.origin], fieldsMeta, item.origin)
        }
      />
    );
    return formItemWrapper(item, render, cnf);
  };

  const renderDateTime = (item, cnf, format, type, isDisabled) => {
    const {pickerType, defaultTime, defaultTimeFormat, dateFormat} = setDatePickerSettings(item, format);
    const render = (
      <DatePicker
        disabled={isDisabled}
        format={dateFormat}
        picker={pickerType}
        showTime={type === 7 && {defaultValue: dayjs(defaultTime, defaultTimeFormat)}}
      />
    );
    return formItemWrapper(item, render, cnf);
  };

  const renderSelect = (item, cnf, type, isDisabled) => {
    const {setFieldsValue} = form;
    let fieldOptions;
    let defaultValue;

    if (item.options !== null && item.options.combobox_options) {
      fieldOptions = item.options.combobox_options.map((item) => (
        <Select.Option key={item.key} value={item.key}>
          {item.value}
        </Select.Option>
      ));
    }
    if (item.value && item.options !== null) {
      if (type === 'multi') {
        defaultValue = [];
        item.value.forEach((element) => {
          defaultValue.push(item?.options?.combobox_options.find((elem) => elem.key === element));
        });
      } else {
        defaultValue = item?.options?.combobox_options.find((elem) => elem.key === item.value);
      }
    }
    if (item.value === null && type === 'multi') {
      item.value = undefined;
    }

    const render = (
      <Select
        style={{width: '100%'}}
        disabled={isDisabled}
        value={item.value}
        allowClear={true}
        mode={type === 'multi' && 'multiple'}
        defaultValue={defaultValue && defaultValue.key}
        onChange={() =>
          item.options &&
          item.options.onChangeHandler &&
          item.options.onChangeHandler(setFieldsValue, data[item.origin], fieldsMeta, item.origin)
        }
      >
        {fieldOptions}
      </Select>
    );

    return formItemWrapper(item, render, cnf);
  };

  const renderDynamicSelect = (item, cnf, isDisabled) => {
    const {setFieldsValue, getFieldValue} = form;
    const {customView, relatedParams, clearFields, filter} = cnf;
    let searchUrl;
    let method = 'post';
    let valueField = 'uuid';
    let referenceStore = 'mdm';
    let valueSearchName;
    let fieldsForSearch = null;
    let refScript = null;
    let displayedField;
    if (item.referenceCatalog) {
      searchUrl =
        item.referenceCatalog.url || '/api/v1/catalogs/' + item.referenceCatalog.origin + '/items/search/extended';
      method = item.referenceCatalog.method;
      valueField = item.referenceCatalog.valueField;
      referenceStore = item.referenceCatalog.referenceStore;
      valueSearchName = item.referenceCatalog.valueSearchName;
    }
    let defaultFilter = null;
    if (item.options) {
      if (item.options.referenceCatalogPattern) item.referenceCatalogPattern = item.options.referenceCatalogPattern;
      if (item.options.valueSearchName) valueSearchName = item.options.valueSearchName;
      if (item.options.fieldsForSearch) fieldsForSearch = item.options.fieldsForSearch;
      if (item.options.reference_value_script) refScript = item.options.reference_value_script;
    }
    if (item.referenceCatalogPattern) {
      if (_.isArray(item.referenceCatalogPattern)) {
        displayedField = item.referenceCatalogPattern;
      } else {
        const refValues = item.referenceCatalogPattern.split(';');
        displayedField = refValues.length === 3 ? [refValues[1], refValues[2]] : refValues[1];
      }
    }
    const detailMode = item.options && item.options.detailMode === 'select';
    if (detailMode) {
      displayedField = item.options.detailCfg.referenceCatalogPattern;
      defaultFilter = item.options.detailCfg.defaultFilter;
    }
    const render = (
      <DynamicSelect
        style={{width: '100%'}}
        disabled={isDisabled}
        placeholder="Выбрать"
        notfoundcontentplaceholder="Введите запрос"
        mode={detailMode && 'multiple'}
        detailMode={detailMode}
        allowClear={true}
        resetOptions={true}
        valueSearchName
        fullResponseValue={customView}
        filterOption={(input, option) => (option?.children ?? '').toLowerCase().includes(input.toLowerCase())}
        displayedFieldSeparator={item.displayedFieldSeparator || get(item, 'options.detailCfg.displayedFieldSeparator')}
        feachoptions={{
          referenceStore,
          url: searchUrl,
          inComeOptionUrl: item.referenceCatalog && item.referenceCatalog.inComeOptionUrl,
          catalogOrigin: item.referenceCatalog && item.referenceCatalog.origin,
          method: method || 'post',
          valueField,
          displayedField: displayedField,
          getFieldValue,
          relatedParams,
          defaultSize: 1000,
          valueSearchName: displayedField,
          fieldsForSearch,
          refScript,
          defaultFilter: defaultFilter || filter,
        }}
        handleSelectChange={(value, option, itemData) => {
          if (customView) {
            customView.forEach((item) => {
              setFieldsValue({[item.pattern]: itemData[item.pattern]});
            });
          }
          if (clearFields) {
            clearFields.forEach((field) => {
              setFieldsValue({
                [field]: null,
              });
            });
          }
          setFieldsValue({[item.origin]: value ? value : null});
          onUpdateDataForm && onUpdateDataForm({...form.getFieldsValue(), [item.origin]: value ? value : null});
        }}
      />
    );

    return formItemWrapper(item, render, cnf);
  };

  const renderDetail = (item, cnf, isDisabled) => {
    const render = (
      <Records
        isDisabled={isDisabled}
        history={history}
        hasContextSearch={true}
        validateCatalog={validateCatalog}
        mode={mode}
        changeType={changeType}
        detail={item.uuid || item.origin}
        referenceOrigin={referenceOrigin}
        recordUuid={detailFieldUuid || recordUuid}
        isEdit={isEdit}
        additionalColumns={item.options && item.options.additionalColumns}
        isUnverified={isUnverified}
        versionId={versionId}
        match={match}
        referenceCatalogPatternField={!isEmpty(item) && item.referenceCatalogPattern}
        additionalMeta={_.has(DetailsMeta, item.origin) && DetailsMeta[item.origin]}
        additionalFields={_.has(AditionalFields, item.origin) && AditionalFields[item.origin]}
        customEditModal={_.has(customEditModal, item.origin) && customEditModal[item.origin]}
        rootValues={rootValues || data}
        rootForm={form}
        maxRefsDepth={maxRefsDepth}
        renderCustomTable={item?.options?.customDetail}
        structure={item?.options?.structure}
      />
    );
    return formItemWrapper(item, render, cnf);
  };

  return (
    <>
      {!_.isEmpty(fieldsMeta) && structure ? (
        <StructureRenderer structure={structure} meta={fieldsMeta} renderItem={renderItem} mode={mode} />
      ) : (
        renderFields(fieldsMeta)
      )}
      <Modal
        title={compositeModalMode === 'edit' ? 'Редактировать запись' : 'Создать запись'}
        open={compositeModalVisible}
        footer={null}
        onCancel={() => setCompositeModalVisible(false)}
        destroyOnClose={true}
        width={800}
      >
        <RecordRedactor
          detailUuid={compositeDetailName}
          isEdit={compositeModalMode === 'edit'}
          referenceOrigin={referenceOrigin}
          recordUuid={recordUuid}
          detailFieldUuid={compositeDetailFieldUuid}
          onCompositeModalSubmit={onCompositeModalSubmit}
          compositeInitialValue={compositeInitialValue}
          closeModal={() => setCompositeModalVisible(false)}
          mode={compositeModalMode}
          rootForm={rootForm || form}
          renderCustomTable={renderCustomTable}
        />
      </Modal>
    </>
  );
};

export default RecordEditor;
