export const formItemLayout = {
  labelCol: {span: 6},
  wrapperCol: {span: 18},
};

export const CBLayout = {
  wrapperCol: {offset: 6, span: 18},
};

export const radioStyle = {
  display: 'block',
  height: '30px',
  lineHeight: '30px',
};

export const fieldTypes = [
  {value: 1, label: 'Строка (до 255 символов)'},
  {value: 11, label: 'Текст'},
  {value: 13, label: 'Гиперссылка (URL)'},
  {value: 2, label: 'Целое число'},
  {value: 3, label: 'Число с плавающей точкой'},
  {value: 21, label: 'Числа с произвольной точностью'},
  {value: 4, label: 'Ссылка на справочник'},
  {value: 14, label: 'Значение из списка'},
  {value: 15, label: 'Значения из списка с множественным выбором'},
  {value: 10, label: 'Логическое'},
  {value: 8, label: 'Дата'},
  {value: 7, label: 'Дата и время'},
  {value: 12, label: 'Детализация'},
  {value: 19, label: 'JSON'},
  {value: 6, label: 'UUID'},
];

export const fieldArrayTypes = [
  {value: 22, label: 'Массив строк'},
  {value: 23, label: 'Массив целочисленных'},
  {value: 24, label: 'Массив десятичных'},
  {value: 25, label: 'Массив гиперссылок (URL)'},
];

export const selectDisplayWays = [
  {key: 'dropdownlist', value: 'Выпадающий список'},
  {key: 'radio', value: 'Переключатель'},
];

export const defaultValueCheckbox = [
  {key: 'true', value: 'Установлен'},
  {key: 'false', value: 'Снят'},
];

export const dateFormats = [
  {key: 'DD.MM.YYYY', value: 'ДД.ММ.ГГГГ'},
  {key: 'MM YYYY', value: 'месяц ГГГГ'},
  {key: 'C YYYY', value: 'квартал ГГГГ'},
];

export const dateTimeFormats = [
  {key: 'DD.MM.YYYY HH:mm', value: 'ДД.ММ.ГГГГ ЧЧ:ММ'},
  {key: 'DD.MM.YYYY HH:mm:ss', value: 'ДД.ММ.ГГГГ ЧЧ:ММ:СС'},
];

export const defaultValueDate = [
  {key: 'empty', value: 'Не заполнено'},
  {key: 'date', value: 'Значение относительно текущей даты'},
  {key: 'specific', value: 'Конкретное значение'},
];

export const defaultValues = {
  fieldTypeId: 1,
  fieldOrder: 1000,
  origin: '',
  caption: '',
  description: '',
  defaultValue: null,
  context_search: false,
  attribute_search: false,
  default_in_list: true,
  gui_editable: true,
  required: false,
  uniqueIndex: false,
  importUnique: false,
  old: false,
  referenceCatalogPattern: null,
  referenceCatalog: null,
  filter: '',
  control_type: 'dropdownlist',
  combobox_options: [],
  date_format: 'DD.MM.YYYY',
  date_default_value_type: 'empty',
  date_duration: 0,
  input_mask: '',
  hierarchy_field: false,
  rules: '',
  uuid: '',
  multilevel_link: false,
  phone_mask: false,
  accessValue: false,
  enabledLower: false,
  uniqueSingleIndex: false,
};

export const requiredRule = [{required: true, message: 'Поле обязательно для заполнения'}];
export const requiredRuleWithoutText = [{required: true, message: ''}];
