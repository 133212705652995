import {useState} from 'react';
import {get, isEmpty} from 'lodash';
import {CloseCircleOutlined, CheckCircleOutlined} from '@ant-design/icons';
import {Button, Form, Tooltip, Input, Flex, InputNumber} from 'ui-kit';
import DynamicSelect from '../../../FormElements/DynamicSelect';
import {fetchFunc} from '../../../../Utils/security/http/mdm';
import {mdm} from '../../../../Utils/constant';
import {priorityForm} from '../../helpers/constant';
import {requiredRule} from '../../../EditFieldForm/helpers/constants';
import {priorityValidate} from 'components/CatalogItemWidgets/utils/utils';

/** Форма добавления приоритетов систем */
const AddPriorityForm = ({editableItem, closeModal, onSubmit}) => {
  const [data, setData] = useState({});
  const [externalSystem, setExternalSystem] = useState('');
  const [form] = Form.useForm();

  const getSubsystem = async (uuid) => {
    try {
      return await fetchFunc({
        url: `/api/v1/subsystem/${uuid}`,
        method: 'get',
      });
    } catch (error) {}
  };

  const handleSelectChange = async (value, name) => {
    const {setFieldsValue} = form;

    if (name === 'subsystem') {
      let id;
      let externalSystem;
      if (value) {
        let data = await getSubsystem(value);
        id = data.id;
        externalSystem = data.externalSystem && data.externalSystem.caption;

        setData(data);
        setExternalSystem(data.externalSystem && data.externalSystem.caption);

        setFieldsValue({
          id,
          externalSystem,
        });
      }
    }
    setFieldsValue({[name]: value});
  };

  const onFinish = (values) => {
    onSubmit(values);
    closeModal();
  };

  const initialValues = {
    subsystem: get(editableItem, 'subsystem.id') || null,
    id: get(editableItem, 'subsystem.id') || null,
    externalSystem: get(editableItem, 'subsystem.externalSystem.caption') || null,
    priority: editableItem ? editableItem.priority : 0,
  };

  return (
    <Form name="add-priority" onFinish={onFinish} layout="vertical" form={form} initialValues={initialValues}>
      <Form.Item label={priorityForm.subsystem} name="subsystem" rules={requiredRule}>
        <DynamicSelect
          name="subsystem"
          style={{width: '100%'}}
          placeholder={mdm.select}
          notfoundcontentplaceholder={mdm.notFound}
          allowClear={true}
          disabled={editableItem}
          resetOptions={true}
          feachoptions={{
            referenceStore: 'mdm',
            url: '/api/v1/subsystem',
            method: 'get',
            referenceCatalogue: 'subsystem',
            valueField: 'id',
            displayedField: 'caption',
            defaultSize: 20,
          }}
          handleSelectChange={(value) => handleSelectChange(value, 'subsystem')}
        />
      </Form.Item>

      {(!isEmpty(data) || !isEmpty(editableItem)) && (
        <Form.Item label={priorityForm.id} name="id">
          <Input disabled />
        </Form.Item>
      )}
      {(!isEmpty(externalSystem) || !isEmpty(editableItem)) && (
        <Form.Item label={priorityForm.externalSystem} name="externalSystem">
          <Input disabled />
        </Form.Item>
      )}
      <Form.Item
        label={<Tooltip title={priorityForm.tooltipPriority}>{priorityForm.priority}</Tooltip>}
        name="priority"
        rules={[...requiredRule, {validator: priorityValidate}]}
      >
        <InputNumber style={{width: '100%'}} />
      </Form.Item>
      <Flex justify={'flex-end'} className="mt-4">
        <Button icon={<CloseCircleOutlined />} type="default" className="mr-4" onClick={closeModal}>
          {mdm.cancel}
        </Button>
        <Button icon={<CheckCircleOutlined />} type="primary" htmlType="submit">
          {mdm.save}
        </Button>
      </Flex>
    </Form>
  );
};

export default AddPriorityForm;
