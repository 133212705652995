import {Skeleton, Space} from 'ui-kit';
import styles from './PageHeader.module.scss';

export const SkeletonPageHeader = () => {
  return (
    <div className={styles.pageHeader}>
      <div className={styles.top}>
        <div className={styles.main}>
          <Space>
            <Skeleton.Button size="small" active />
            <Skeleton.Button size="small" active />
            <Skeleton.Button size="small" active />
          </Space>
          <div className={styles.titleWrapper}>
            <Skeleton paragraph={{rows: 0}} title active />
            <div className={styles.flexGrow}></div>
          </div>
        </div>
      </div>
      <div className={styles.tabs}>
        <Space>
          <Skeleton.Button size="default" active />
          <Skeleton.Button size="default" active />
          <Skeleton.Button size="default" active />
          <Skeleton.Button size="default" active />
        </Space>
      </div>
    </div>
  );
};
