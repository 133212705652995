import {routes} from 'config/routes';
import {useCallback, useMemo} from 'react';
import {matchPath, useLocation} from 'react-router';
import {Eroute, IRoute} from 'types';
import {useActiveMenuItem} from './useActiveMenuItem';
import {useSelector} from 'react-redux';
import {RootState} from 'redux/types/rootState';

export const useBreadCrumbs = () => {
  const location = useLocation();
  const {breadcrumbsTitle} = useSelector((state: RootState) => state.pageHeader);

  const isCurrentPath = useCallback(
    (path?: string) => {
      return path === location.pathname;
    },
    [location.pathname]
  );

  const getRouteById = useCallback((id: Eroute) => {
    return routes.find((route) => route.id === id);
  }, []);

  const matchingRoute = useActiveMenuItem();
  const breadcrumbs: IRoute[] = useMemo(() => {
    let breadcrumbs: IRoute[] = [];
    const getWithParents = (prev: IRoute[], id: Eroute) => {
      const currentRoute = getRouteById(id);
      if (currentRoute) {
        breadcrumbs = [currentRoute, ...prev];
        if (currentRoute.parent) {
          breadcrumbs = [...getWithParents(breadcrumbs, currentRoute.parent)];
        }
      } else {
        breadcrumbs = prev;
      }
      if (isCurrentPath(breadcrumbsTitle?.path) && breadcrumbsTitle?.title?.length) {
        if (Array.isArray(breadcrumbsTitle.title)) {
          const titles = breadcrumbsTitle.title.slice().reverse();
          titles.forEach((newTitle, index) => {
            const targetIndex = breadcrumbs.length - 1 - index;
            if (breadcrumbs[targetIndex] && newTitle) {
              breadcrumbs[targetIndex].title = newTitle;
            }
          });
        } else {
          breadcrumbs[breadcrumbs.length - 1].title = breadcrumbsTitle.title;
        }
      }
      return breadcrumbs;
    };
    if (matchingRoute) {
      return [
        {
          title: 'Главная',
          id: Eroute.MainPage,
          navLink: '/',
        },
        ...getWithParents([], matchingRoute.id),
      ].map((breadcrumb, index, array) => {
        // Формируем реальные ссылки на основе объекта match и параметров URL
        if (index !== array.length - 1) {
          const match = matchPath(location.pathname, {path: breadcrumb.path});
          const navLink = match ? match.url : breadcrumb.navLink;
          return {...breadcrumb, navLink};
        }
        return breadcrumb;
      });
    }

    return [];
  }, [breadcrumbsTitle?.path, breadcrumbsTitle?.title, getRouteById, isCurrentPath, location.pathname, matchingRoute]);

  return breadcrumbs;
};
