import {Modal as AntdModal} from 'antd';
import styles from './Modal.module.scss';
import classNames from 'classnames';
import {ModalFuncProps} from 'antd/lib';
import {InfoCircleOutlined} from '@ant-design/icons';

export const ModalInfo = (props: ModalFuncProps) => {
  return AntdModal.info({
    ...props,
    className: classNames(styles.modalInfo, props.className),
    icon: props?.icon || <InfoCircleOutlined />,
  });
};
