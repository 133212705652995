import React, {ReactElement, useCallback, useMemo, useState} from 'react';
import {Table as AntTable} from 'antd';
import styles from './Table.module.scss';
import classNames from 'classnames';
import {TableProps as AntdTableProps, ColumnsType as AntdColumnsType} from 'antd/es/table';
import {isBoolean, isObject} from 'lodash';

export type TableProps<T> = AntdTableProps<T>;
export type ColumnsType<RecordType> = AntdColumnsType<RecordType>;

export const Table = ({pagination: propsPagination, ...props}: TableProps<any>): ReactElement => {
  const [paginationSize, setPaginationSize] = useState((propsPagination && propsPagination.pageSize) || 10);
  const paginationTotal = useMemo(
    () => (propsPagination && propsPagination.total) || (props.dataSource && props.dataSource.length) || 0,
    [props.dataSource, propsPagination]
  );

  const onChangePagination = useCallback(
    (page: number, pageSize: number) => {
      setPaginationSize(pageSize);
      if (propsPagination && propsPagination.onChange) {
        propsPagination.onChange(page, pageSize);
      }
    },
    [propsPagination]
  );
  const pagination = useMemo(() => {
    let paginationFormation = propsPagination;

    if (paginationTotal > 10) {
      const showSizeChanger = propsPagination && propsPagination?.showSizeChanger === false ? false : true;

      paginationFormation = {
        ...paginationFormation,
        showSizeChanger,
      };
    } else {
      paginationFormation = {
        ...paginationFormation,
        showSizeChanger: false,
      };
    }

    if (paginationTotal <= paginationSize) {
      return {
        ...paginationFormation,
        className: styles.hidePagination,
      };
    }
    return paginationFormation;
  }, [paginationSize, paginationTotal, propsPagination]);

  const isBordered = isBoolean(props.bordered) ? props.bordered : true;
  return (
    <AntTable
      {...props}
      showSorterTooltip={{
        mouseEnterDelay: 0.5,
        mouseLeaveDelay: 0.5,
        ...(isObject(props.showSorterTooltip) ? props.showSorterTooltip : {}),
      }}
      bordered={isBordered}
      className={classNames(styles.table, props.className)}
      pagination={{...pagination, onChange: onChangePagination}}
    />
  );
};

const {Column, ColumnGroup, Summary, SELECTION_ALL, SELECTION_COLUMN, SELECTION_INVERT, SELECTION_NONE, EXPAND_COLUMN} =
  AntTable;

Table.Column = Column;
Table.ColumnGroup = ColumnGroup;
Table.Summary = Summary;
Table.SELECTION_ALL = SELECTION_ALL;
Table.SELECTION_COLUMN = SELECTION_COLUMN;
Table.SELECTION_INVERT = SELECTION_INVERT;
Table.SELECTION_NONE = SELECTION_NONE;
Table.EXPAND_COLUMN = EXPAND_COLUMN;
