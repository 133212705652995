import React, {useEffect} from 'react';
import './NotFoundPage.scss';
import {History} from 'history';
import {Typography} from 'ui-kit';
import {useDispatch} from 'react-redux';
import {setBreadCrumbsTitle} from 'redux/actions/actions';
export interface NotFoundPageProps {
  history: History;
}

export const NotFoundPage = ({history}: NotFoundPageProps) => {
  const {Paragraph: P} = Typography;

  const dispatch = useDispatch();
  useEffect(() => {
    history.push('/error/404');
    dispatch(setBreadCrumbsTitle('Страница не найдена'));
  }, [dispatch, history]);

  return (
    <div className="containerWhite w-100 d-flex flex-column">
      <P>Извините, запрашиваемая страница не найдена.</P>
      <P>
        Пожалуйста, убедитесь, что вы ввели правильный URL или используйте меню навигации для перехода на другие
        страницы.
      </P>
      <P>Если проблема повторяется, пожалуйста, свяжитесь с администратором сайта.</P>
    </div>
  );
};

export default NotFoundPage;
