import React from 'react';
import {Collapse, Descriptions} from 'ui-kit';
import {catalogsFilters} from '../helpers/constant';

export interface SystemInfoProps {
  referenceOrigin?: string;
  referenceUuid?: string;
}

const SystemInfo: React.FC<SystemInfoProps> = ({referenceOrigin, referenceUuid}) => {
  const {Panel} = Collapse;
  return (
    <Collapse>
      <Panel header="Системная информация" key="1">
        <Descriptions>
          <Descriptions.Item label={catalogsFilters.catalogName}> {referenceOrigin}</Descriptions.Item>
          <Descriptions.Item label={catalogsFilters.uuid}>{referenceUuid}</Descriptions.Item>
        </Descriptions>
      </Panel>
    </Collapse>
  );
};
export default SystemInfo;
