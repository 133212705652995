import {ComparedVersionsState} from 'redux/types/rootState';
import {SET_COMPARED_VERSIONS} from '../actions/actionTypes';

const initialState: ComparedVersionsState = {};

export const ComparedVersionsReducers = (state: ComparedVersionsState = initialState, action: any) => {
  switch (action.type) {
    case SET_COMPARED_VERSIONS:
      return {
        ...state,
        changedFields: action.payload?.changedFields,
        versionItem: action.payload?.versionItem,
        comparedItem: action.payload?.comparedItem,
      };
    default:
      return state;
  }
};
