import React from 'react';
import {Input, Select} from 'ui-kit';
import '../DeduplicationProtocolForm/deduplicationProtocolForm.scss';
import {ReactComponent as ExclamationPoint} from '../../../assets/images/coolicon.svg';

export const deduplication = {
  title: 'Задания дедупликации',
  titleFormComparison: 'Форма сравнения записей',
  titleForListProtocols: 'Список протоколов',
  protocol: 'Протокол дедупликации',
  degreeProximityTooltip: [
    'Сходство (Степень близости) задаёт, насколько сильно записи должны,',
    'совпадать для определения их как дубликаты. 0 - нет совпадений, 1 - полное совпадение.',
    'Выберите значение в соответствии с требуемой точностью поиска дубликатов.',
  ],
  goBack: 'Назад',
  subtitle: [
    'Запуск дедупликации происходит вручную, выберите настройки задания',
    'Для отправки формы необходимо заполнить все поля формы',
  ],
  close: 'Закрыть',
  cancel: 'Отменить',
  reset: 'Сбросить',
  save: 'Сохранить',
  search: 'Поиск',
  edit: 'Редактировать',
  confirm: 'Применить',
  openProtocol: 'Открыть протокол',
  statusConnection: ' Статус связи',
  groupName: 'Группа записей',
  openComparisonForm: 'Открыть форму сравнения',
  statusInTable: 'Статус',
  algorithm: 'Алгоритм',
  attributeCatalog: 'Атрибуты справочника',
  userProfile: 'Пользователь, подтвердивший связь',
  degreeProximity: 'Мин. коэффициент совпадения',
  resemblance: 'Сходство',
  attention: 'Внимание!',
  resemblanceAttention:
    'При установке значения сходства от 0,7 и ниже существует вероятность,что некоторые уникальные записи будут ошибочно определены как дубликаты.Для большей точности рекомендуем установить значение выше 0,7',
  attributes: 'Атрибут',
  name: 'Наименование',
  addName: 'Введите название',
  nameJob: 'Наименование задания',
  dateOfProtocol: 'Дата протокола',
  catalog: 'Справочник',
  systemInfoHeader: 'Системная информация',
  uuid: 'UUID: ',
  createDate: 'Дата создания: ',
  finishedDate: 'Дата завершения: ',
  version: 'Версия: ',
  select: 'Выбрать',
  notFound: 'Не найдено',
  changeDate: 'Дата изменения: ',
  identifier: 'Идентификатор: ',
  defaultDegreeProximity: 0.5,
  labelCheckboxForDelete: 'Отображать заархивированные протоколы',
  status: [
    'Открыть протокол возможно только в статусе "Завершено".',
    'Запустить задание возможно в статусах "Новое", "Завершено", "Ошибка".',
    'Удалить задание возможно в статусах  "Новое", "В очереди", "Ошибка".',
  ],
  helpAlgorithms: 'Справка (алгоритмы)',
  buttonText: 'Создать задание на дедупликацию',
  hintContentsTextForAlgorithms: [
    '1. Алгоритм ФИО - применяется для полей "ФИО"',
    '2. Алгоритм Адрес - применяется для полей "Адрес"',
    '3. Алгоритм Наименование организации - применяется для полей "Наименование организации"',
    '4. Алгоритм Телефон - применяется для полей "Телефон"',
    '5. Алгоритм E-mail - применяется для полей "E-mail"',
    '6. Алгоритм последовательности цифр - применяется для полей последовательность цифр: ИНН, ОКПО, номер банковского счёта и т.п.',
    '7. Алгоритм произвольный текст - применяется для текстовой строки произвольного формата',
  ],
  columnsFormDeduplicationTable: [
    {
      title: '',
      dataIndex: 'fieldCaption',
      key: 'id',
      render: (text) => <p className="deduplicationProtocolFormConstant__text">{text}</p>,
    },
    {
      title: 'Алгоритм',
      dataIndex: 'Algorithm',
      key: 'Algorithm',
      render: (value) => <Select options={value} className="deduplicationProtocolFormConstant__select" />,
    },
    {
      title: 'Степень близости',
      dataIndex: 'proximityDegree',
      key: 'proximityDegree',
      render: (value) => (
        <Input
          id="numPeople"
          type="number"
          min="0"
          max="1"
          step={0.1}
          value={value}
          defaultValue={0.5}
          className="deduplicationProtocolFormConstant__input"
        />
      ),
    },
  ],
  blockGroupsLink: 'Запретить связь',
  cancelLink: ' Отменить связь',
  setLink: 'Установить связь',
  confirmDelete: 'Подтвердить',
  goldRecord: 'Золотая запись',
  gold: 'Золотая',
  preparePayloadData: {
    catalogDeduplicationId: '',
    mainItemUuid: '',
    updatedMainItemFields: [],
  },
  emptyProtocolTitle: 'Дубли не обнаружены',
  emptyProtocol: [
    'В ходе процесса дедупликации не было обнаружено ни одной группы потенциальных дублей.',
    'Это свидетельствует о высоком качестве Ваших данных. Однако, это может быть также причиной',
    'слишком мягких настроек задания дедупликации. При необходимости вы всегда можете понизить',
    ' степень близости или изменить алгоритм в настройках задания дедупликации.',
  ],
  // configModalForDelete: [
  //   <p>
  //     Внимание! <ExclamationPoint />
  //   </p>,
  //   <p>После запрета связи группа станет недоступной, форма закроется.</p>,
  //   <p>Вы действительно хотите запретить связь для всех записей группы?</p>,
  // ],
};
