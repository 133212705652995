import {
  notificationMethodOptions,
  notifications,
  notificationScheduleOptions,
} from 'components/NotificationPage/helpers/constant';
import {isEmpty} from 'lodash';
import {RecordType} from 'types';
import {Button, Col, Form, FormInstance, Input, Popover, Row, Select} from 'ui-kit';
import {ReactComponent as ExclamationPoint} from '../../../../assets/images/coolicon.svg';
import {RestOutlined, SaveOutlined} from '@ant-design/icons';
import isURL from 'validator/lib/isURL';
import {useEffect, useMemo} from 'react';

const {TextArea} = Input;

interface HttpFormPropsType {
  formHttp: FormInstance<RecordType>;
  openCollapseHttp: string | string[];
  isActiveHttp: boolean;
  renderPopoverContent: JSX.Element;
  editMethodNotificationHttpHandler: () => void;
  setIsActiveHttp: React.Dispatch<React.SetStateAction<boolean>>;
  templateTypeHttp?: RecordType;
  methodNotificationsHttp: RecordType;
  setMethodNotificationsHttp: React.Dispatch<React.SetStateAction<RecordType>>;
  success: () => void;
  status: string;
}

const HttpForm = ({
  formHttp,
  openCollapseHttp,
  isActiveHttp,
  renderPopoverContent,
  editMethodNotificationHttpHandler,
  setIsActiveHttp,
  templateTypeHttp,
  methodNotificationsHttp,
  setMethodNotificationsHttp,
  success,
  status,
}: HttpFormPropsType) => {
  const initialValuesHttp = useMemo(
    () => ({
      scheduleHttp:
        status === 'edit' && templateTypeHttp?.transportType === 'HTTP' && !isEmpty(templateTypeHttp.transportParams)
          ? templateTypeHttp.scheduleType
          : formHttp.getFieldValue('scheduleHttp'),
      url:
        status === 'edit' && !isEmpty(templateTypeHttp?.transportParams?.url)
          ? templateTypeHttp?.transportParams?.url
          : formHttp.getFieldValue('url'),
      methodsHttp:
        status === 'edit' && templateTypeHttp?.transportType === 'HTTP'
          ? templateTypeHttp?.transportParams?.method
          : formHttp.getFieldValue('methodsHttp'),
      textHttp:
        status === 'edit' && templateTypeHttp?.transportType === 'HTTP'
          ? templateTypeHttp?.body
          : formHttp.getFieldValue('textHttp'),
    }),
    [
      formHttp,
      status,
      templateTypeHttp?.body,
      templateTypeHttp?.scheduleType,
      templateTypeHttp?.transportParams,
      templateTypeHttp?.transportType,
    ]
  );
  const handleActiveChangeHttp = () => {
    setIsActiveHttp((prev) => !prev);
    const {methodsHttp, scheduleHttp, textHttp, url} = formHttp.getFieldsValue();
    if (status === 'edit') {
      if (!isEmpty(templateTypeHttp)) {
        methodNotificationsHttp.methodUpdateUuid = templateTypeHttp?.uuid;
      }
      setMethodNotificationsHttp({
        body: textHttp ? textHttp : initialValuesHttp.textHttp,
        scheduleType: scheduleHttp ? scheduleHttp : initialValuesHttp.scheduleHttp,
        transportParams: {
          url,
          method: methodsHttp ? methodsHttp : initialValuesHttp.methodsHttp,
        },
        transportType: notifications.http,
        methodUpdateUuid: templateTypeHttp?.uuid,
      });
    } else {
      setMethodNotificationsHttp({
        body: textHttp,
        scheduleType: scheduleHttp,
        transportParams: {
          url: url,
          method: methodsHttp,
        },
        transportType: notifications.http,
      });
    }
    success();
  };

  useEffect(() => {
    formHttp.setFieldsValue(initialValuesHttp);
  }, [formHttp, initialValuesHttp, templateTypeHttp]);

  const clearTemplateHttpHandler = () => {
    formHttp.resetFields(['scheduleHttp', 'url', 'methodsHttp', 'textHttp']);
    setMethodNotificationsHttp({});
    setIsActiveHttp(false);
  };

  return (
    <>
      <Form
        onFinish={!isActiveHttp ? handleActiveChangeHttp : editMethodNotificationHttpHandler}
        name="notificationFormHttp"
        layout="vertical"
        form={formHttp}
        scrollToFirstError
      >
        <Row gutter={16}>
          <Col xs={24} lg={12}>
            <Form.Item
              label={notifications.methodsHttp}
              name="methodsHttp"
              rules={[
                {
                  required: !isEmpty(openCollapseHttp) && true,
                  message: notifications.rulesMessage,
                },
              ]}
            >
              <Select
                options={notificationMethodOptions}
                placeholder={notifications.selectMethod}
                disabled={(isEmpty(openCollapseHttp) || isActiveHttp) && true}
              />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item
              label={notifications.schedule}
              name="scheduleHttp"
              rules={[
                {
                  required: !isEmpty(openCollapseHttp) && true,
                  message: notifications.rulesMessage,
                },
              ]}
            >
              <Select
                options={notificationScheduleOptions}
                placeholder={notifications.selectSchedule}
                disabled={(isEmpty(openCollapseHttp) || isActiveHttp) && true}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          label={notifications.url}
          name="url"
          rules={[
            {
              required: !isEmpty(openCollapseHttp) && true,
              message: notifications.rulesMessage,
            },
            {
              validator: (_rule, value) => {
                if (value && !isURL(value, {protocols: ['http', 'https']})) {
                  return Promise.reject('Неверный формат URL');
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Input disabled={(isEmpty(openCollapseHttp) || isActiveHttp) && true} />
        </Form.Item>
        <Form.Item
          label={
            <div>
              <span>{notifications.text}</span>{' '}
              <Popover content={renderPopoverContent}>
                <ExclamationPoint />
              </Popover>
            </div>
          }
          name="textHttp"
          rules={[
            {
              required: !isEmpty(openCollapseHttp) && true,
              message: notifications.rulesMessage,
            },
          ]}
        >
          <TextArea rows={4} disabled={(isEmpty(openCollapseHttp) || isActiveHttp) && true} />
        </Form.Item>
        <div className="d-flex justify-content-end">
          <Button icon={<RestOutlined />} className="mr-3" onClick={clearTemplateHttpHandler}>
            {notifications.fresh}
          </Button>
          <Button type="primary" icon={<SaveOutlined />} ghost htmlType="submit">
            {!isActiveHttp ? notifications.save : notifications.edit}
          </Button>
        </div>
      </Form>
    </>
  );
};

export default HttpForm;
