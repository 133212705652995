import {useDispatch, useSelector} from 'react-redux';
import {RootState} from 'redux/types/rootState';
import {useEffect, useMemo} from 'react';
import {getMenuAccess, setActiveMenuItem, setBreadcrumbsNames} from 'redux/actions/actions';
import {createBreadcrumbNameMap} from 'modules/App/helpers/utils';
import {MenuItem} from 'modules/App/menu';
import {useLocation} from 'react-router';
import {routes} from 'config/routes';
import {Eroute} from 'types';

export const useMenuItems = () => {
  const location = useLocation();

  const dispatch = useDispatch();
  const menuAccess = useSelector((state: RootState) => state.access.menuAccess);
  const {breadcrumbNames: breadcrumbMap} = useSelector((state: RootState) => state.navigate);
  const newItems = useMemo(() => {
    const filteredRoutes = [...routes];

    const getRouteById = (id: React.Key) => {
      const route = filteredRoutes.find((route) => route.id === id);
      return route;
    };

    const setVisibleByIndex = (id: React.Key) => {
      const index = filteredRoutes.findIndex((route) => route.id === id);
      if (index !== -1) {
        filteredRoutes[index] = {...filteredRoutes[index], showMenu: true};
      }
    };

    const setVisibleByIndexMap = (indexes: React.Key[]) => {
      indexes.forEach((index) => {
        setVisibleByIndex(index);
      });
    };

    if (menuAccess.hasSystemCatalogsAccess) {
      setVisibleByIndexMap([Eroute.MenuNotification, Eroute.NotificationPageTemplates, Eroute.NotificationPageList]);
    }

    if (menuAccess.hasSchedulerAccess) {
      setVisibleByIndexMap([Eroute.MenuScheduler, Eroute.SchedulerTasks, Eroute.SchedulerCalendar]);
    }
    if (menuAccess.hasTypeCreateAccess) {
      setVisibleByIndexMap([Eroute.CatalogItemAdd, Eroute.CatalogItemImport]);
    }
    if (menuAccess.hasSystemCatalogsAccess) {
      setVisibleByIndexMap([Eroute.MenuSystemCatalogs, Eroute.ExternalSystemsList, Eroute.SubsystemsList]);

      if (getRouteById(Eroute.MenuDeduplication)) {
        setVisibleByIndex(Eroute.DeduplicationJob);
      }
    }
    if (menuAccess.hasSubscriptionsAccess) {
      setVisibleByIndexMap([Eroute.Subscriptions]);
    }

    return filteredRoutes;
  }, [
    menuAccess.hasSchedulerAccess,
    menuAccess.hasSubscriptionsAccess,
    menuAccess.hasSystemCatalogsAccess,
    menuAccess.hasTypeCreateAccess,
  ]);

  useEffect(() => {
    dispatch(getMenuAccess());
  }, [dispatch]);

  useEffect(() => {
    dispatch(setBreadcrumbsNames(createBreadcrumbNameMap(newItems)));
  }, [dispatch, newItems]);

  useEffect(() => {
    if (breadcrumbMap) {
      dispatch(setActiveMenuItem(breadcrumbMap.find((elem: MenuItem) => elem.route === location.pathname)));
    }
  }, [breadcrumbMap, dispatch, location.pathname]);

  return newItems;
};
