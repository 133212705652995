import {BellOutlined, BellTwoTone} from '@ant-design/icons';
import {notifications} from 'components/NotificationPage/helpers/constant';
import NotificationPageModalTestTelephone from 'components/NotificationPage/NotificationPageModalTestTelephone';
import {isEmpty} from 'lodash';
import {renderEmails, renderPhoneNumber, setMethodNotifications} from 'modules/NotificationPage/helpers/utils';
import {ReactNode, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {setNotificationEmailsForTestAC, setNotificationPhoneNumberForTestAC} from 'redux/actions/actions';
import {RecordType} from 'types';
import {Button, Collapse, Form, FormInstance, Modal} from 'ui-kit';

const {Panel} = Collapse;

interface childrenPropsType {
  isActive: boolean;
  renderPopoverContent: JSX.Element;
  editMethodNotificationHandler: () => void;
  openCollapse: string | string[];
  setIsActive: React.Dispatch<React.SetStateAction<boolean>>;
  setIsVisibleModalForPhone: React.Dispatch<React.SetStateAction<boolean>>;
  setTestKey: React.Dispatch<React.SetStateAction<string>>;
  form: FormInstance<RecordType>;
}

interface FormContainerType {
  header: string;
  name: string;
  methodNotification: RecordType;
  setMethodNotification: React.Dispatch<React.SetStateAction<RecordType>>;
  status: string;
  children: (props: childrenPropsType) => ReactNode;
  templateType?: RecordType;
}

const FormContainer = ({
  header,
  name,
  methodNotification,
  children,
  setMethodNotification,
  status,
  templateType,
}: FormContainerType) => {
  const [isActive, setIsActive] = useState(false);
  const [openCollapse, setOpenCollapse] = useState<string | string[]>('');
  const [isVisibleModalForPhone, setIsVisibleModalForPhone] = useState(false);
  const [testKey, setTestKey] = useState('');

  const [form] = Form.useForm();

  const dispatch = useDispatch();

  const closeModal = () => {
    setIsVisibleModalForPhone(false);
  };

  const testNotificationHandler = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation();
    setIsVisibleModalForPhone(true);
    setTestKey(name);

    switch (name) {
      case 'email':
        dispatch(setNotificationEmailsForTestAC(renderEmails(form.getFieldValue('emails'), methodNotification)));
        break;
      case 'sms':
        dispatch(setNotificationPhoneNumberForTestAC(renderPhoneNumber(form.getFieldValue('telephone'), templateType)));
        break;
    }
  };

  useEffect(() => {
    !isEmpty(methodNotification) && setOpenCollapse(name);
  }, [name, methodNotification]);

  useEffect(() => {
    !isEmpty(methodNotification) && status && setIsActive(true);
  }, [methodNotification, status]);

  useEffect(() => {
    !isEmpty(templateType) && status && setMethodNotifications(templateType, setMethodNotification);
  }, [setMethodNotification, status, templateType]);

  const editMethodNotificationHandler = () => {
    setIsActive((prev) => !prev);
  };

  const renderPopoverContent = (
    <div>
      {notifications.hintContentsTextForTextNotification?.map((content, index) => <p key={index}>{content}</p>)}
    </div>
  );

  return (
    <>
      <Collapse collapsible="header" onChange={(key) => setOpenCollapse(key)} activeKey={openCollapse}>
        <Panel
          header={header}
          extra={
            <div>
              <Button
                id={name}
                type="link"
                icon={!isActive ? <BellOutlined /> : <BellTwoTone />}
                onClick={(event) => testNotificationHandler(event)}
                disabled={!isActive && true}
              >
                {notifications.test}
              </Button>
            </div>
          }
          key={name}
        >
          {children({
            isActive,
            renderPopoverContent,
            editMethodNotificationHandler,
            openCollapse,
            setIsActive,
            setIsVisibleModalForPhone,
            setTestKey,
            form,
          })}
        </Panel>
      </Collapse>
      <Modal open={isVisibleModalForPhone} width={500} footer={null} onCancel={closeModal}>
        <NotificationPageModalTestTelephone
          textEmail={name === 'email' && form?.getFieldValue('textEmail')}
          testKey={testKey}
          setIsVisibleModalForPhone={setIsVisibleModalForPhone}
          methodNotification={methodNotification}
        />
      </Modal>
    </>
  );
};

export default FormContainer;
