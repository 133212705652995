import {isArray, isEmpty, isObject} from 'lodash';
import {read, utils} from 'xlsx';
import {XMLParser} from 'fast-xml-parser';
import {message} from 'ui-kit';

/** Функция преобразования cvs в JSON */
const csvToJSON = (csv) => {
  const lines = csv.split('\n');
  const header = lines
    .shift()
    .split(';')
    .map((item) => item.trim());
  lines.shift();
  return lines.map((line) => {
    const bits = line.replaceAll('\r', '').split(';');
    const obj = {};
    header.forEach((head, i) => (obj[head.trim()] = bits[i]));
    return obj;
  });
};
/** Функция парсинга Xlsx  */
export const parseXlsx = async (file) => {
  const arrayBuffer = await new Promise((resolve) => {
    const fr = new FileReader();
    fr.readAsArrayBuffer(file);
    const interval = setInterval(() => {
      if (fr.readyState === FileReader.DONE) {
        clearInterval(interval);
        resolve(fr.result);
      }
    }, 100);
  });
  const wb = read(arrayBuffer);
  const data = utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]);
  return {path: null, data};
};
/** Функция парсинга Json  */
export const parseJson = async (file) => {
  const arrayBuffer = await new Promise((resolve) => {
    const fr = new FileReader();
    fr.addEventListener('load', (event) => {
      resolve(event.target.result);
    });
    fr.readAsText(file);
  });
  const data = JSON.parse(arrayBuffer);
  return {path: null, data};
};
/** Функция парсинга Csv  */
export const parseCsv = async (file) => {
  const arrayBuffer = await new Promise((resolve) => {
    const fr = new FileReader();
    fr.addEventListener('load', (event) => {
      resolve(event.target.result);
    });
    fr.readAsText(file);
  });
  const data = csvToJSON(arrayBuffer);
  return {path: null, data};
};
const getPathWithoutRoot = (path) => {
  path.shift();
  return path;
};
/** Функция преобразования Xml при  Object  */
const getParsedXmlIfObject = (obj) => {
  let parsedXmlIfObjectToArray = [...Object.values(obj)];
  const result = [];
  parsedXmlIfObjectToArray.map((item) => {
    if (!Array.isArray(Object.values(item)[0])) {
      return result.push(Object.values(item)[0]);
    }
    return [];
  });
  return result;
};
/** Получаем path для payload */
const getPathToFields = (obj) => {
  if (isArray(obj)) return '';
  if (isObject(obj)) {
    const returnableKey = Object.keys(obj);
    const path = getPathToFields(obj[returnableKey]);
    return !path ? `${returnableKey}` : `${returnableKey}.${path}`;
  }
};
/** Функция парсинга Xml  */
export const parseXml = async (file) => {
  const arrayBuffer = await new Promise((resolve) => {
    const fr = new FileReader();
    fr.addEventListener('load', (event) => resolve(event.target.result));
    fr.readAsText(file);
  });
  const parser = new XMLParser({
    ignoreAttributes: true,
    alwaysCreateTextNode: false,
  });
  const parsedXml = parser.parse(arrayBuffer);
  if (isEmpty(arrayBuffer)) return message.error('Не удается распарсить файл. Ошибка валидации ');

  const dataFromObject = getParsedXmlIfObject(parsedXml);
  if (isEmpty(dataFromObject)) {
    const path = getPathToFields(parsedXml);
    const arrayPath = path.split('.');
    const fields = arrayPath.reduce((acc, item) => {
      return !acc ? parsedXml[item] : acc[item];
    }, null);
    const data = fields.reduce((acc, item) => {
      const field = {};
      Object.keys(item).forEach((key) => (field[key] = item[key]));
      acc.push(field);
      return acc;
    }, []);
    const pathWithoutRoot = getPathWithoutRoot(arrayPath).toString();
    return {path: pathWithoutRoot, data};
  } else {
    const path = Object.keys(Object.values(parsedXml)[0]).toString();
    const data = dataFromObject;
    return {path: path, data};
  }
};
/** Функция парсинга Ext */
export const getFileExt = (fileName) => {
  const lastDot = fileName.lastIndexOf('.');
  return fileName.slice(lastDot + 1);
};
/** Функция парсинга документа */
export const parseDocument = async (file, ext) => {
  if (ext === 'json') return await parseJson(file);
  if (ext === 'csv') return await parseCsv(file);
  if (ext === 'xml') return await parseXml(file);
  return await parseXlsx(file);
};
