import classNames from 'classnames';
import {CustomIconName, customIconsMap} from './CustomIconsMap';
import {ReactNode} from 'react';

export interface CustomIconProps {
  menuIcon?: boolean;
  children?: ReactNode;
  name?: CustomIconName;
}

export const CustomIcon = ({menuIcon, children, name}: CustomIconProps) => {
  const IconComponent = name ? customIconsMap[name] : null;
  return (
    <span
      role="img"
      aria-label="swap"
      className={classNames('anticon', {
        'ant-menu-item-icon': menuIcon,
      })}
    >
      {children && children}
      {IconComponent && <IconComponent />}
    </span>
  );
};
