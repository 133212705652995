import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {isEmpty} from 'lodash';
import {Button, Modal, Spin} from 'ui-kit';

import {
  getBlockLinkFullGroup,
  getCancelLinkGroup,
  getDeduplicationGroupProtocolInfo,
  getSetLinkGroup,
} from '../../../../../redux/thunk/deduplicationThunk';
import {deduplication} from '../../../helpers/constants';
import DeduplicationGroupTable from '../DeduplicationGroupTable/DeduplicationGroupTable';
import {resetDeduplicationProtocolInfoGroup} from '../../../../../redux/actions/actions';

import style from './DeduplicationGroupModal.module.scss';
import {CloseOutlined, LinkOutlined, StopOutlined} from '@ant-design/icons';
import {Primary} from 'stories/Button.stories';
import {ModalConfirm} from 'ui-kit/Modal/ModalConfirm';

/**
 * Данные   гпупп дедупликации
 * @param setIsDeduplicationModalVisible - функция для подготовки данных к отправке
 * @param groupId  - данные для отпраки
 * @param status  - статус группы
 * @param deduplicationProtocolInfo - данные по протоколу
 */
const DeduplicationGroupModal = ({setIsDeduplicationModalVisible, groupId, status, deduplicationProtocolInfo}) => {
  /** Отправка данных в хранилище */
  const dispatch = useDispatch();
  /** Используем хранилище */
  const deduplicationGroupProtocolInfo = useSelector((state) => state?.deduplication.deduplicationGroupProtocolInfo);

  /** Начальное состояние модального окна групп дедупликации */
  /** Данные для отправки на изменение связи в группе */
  const [payloadData, setPayloadData] = useState(deduplication.preparePayloadData);
  /** Состояние для переключения золотой записи */
  const [isGoldRecord, setIsGoldRecord] = useState(false);
  /** Состояние золотой записи */
  const [goldRecord, setGoldRecord] = useState(null);
  /** Золотая запись подвежденная */
  const goldRecordData = !isEmpty(deduplicationGroupProtocolInfo)
    ? deduplicationGroupProtocolInfo?.mainItem?.attributes
    : null;
  /** Uuid золотой записи */
  const mainItemUuid = !isEmpty(deduplicationGroupProtocolInfo) && deduplicationGroupProtocolInfo?.mainItem?.recordUuid;

  useEffect(() => {
    isEmpty(groupId) && dispatch(getDeduplicationGroupProtocolInfo(groupId));
  }, [groupId]);
  /** Запретить  связь у всей группы */
  const blockFullGroupsLinkHandler = () => {
    dispatch(getBlockLinkFullGroup(groupId));
    setIsDeduplicationModalVisible(false);
    setPayloadData([]);
    setGoldRecord([]);
    setIsGoldRecord(false);
  };
  /** Отменить связь у записи группы */
  const cancelLinkGroupHandler = () => {
    dispatch(getCancelLinkGroup(groupId, mainItemUuid));
    setPayloadData([]);
    setGoldRecord([]);
    setIsGoldRecord(false);
    setIsDeduplicationModalVisible(false);
    dispatch(resetDeduplicationProtocolInfoGroup());
  };
  /** Установить  связь в группе*/
  const setLinkGroupHandler = () => {
    dispatch(getSetLinkGroup(payloadData, groupId));
    setPayloadData([]);
    setGoldRecord([]);
    setIsGoldRecord(false);
    setIsDeduplicationModalVisible(false);
  };

  const onCloseModal = () => {
    setIsDeduplicationModalVisible(false);
    setGoldRecord(null);
    setPayloadData([]);
    setIsGoldRecord(false);
    dispatch(resetDeduplicationProtocolInfoGroup());
  };

  const modalForDelete = () => {
    ModalConfirm({
      onOk: blockFullGroupsLinkHandler,
      afterClose: () => {
        setGoldRecord(null);
      },
      okText: deduplication.confirmDelete,
      cancelText: deduplication.cancel,
      width: 400,
      loading: true,
      title: 'Внимание!',
      content: (
        <div className={style.deleteModal_content}>
          <div>После запрета связи группа станет недоступной, форма закроется.</div>
          <div>Вы действительно хотите запретить связь для всех записей группы?</div>
        </div>
      ),
    });
  };

  if (isEmpty(deduplicationGroupProtocolInfo))
    return <Spin size="small" className={style.deduplicationGroupModal__spin} />;
  return (
    <div className={style.deduplicationGroupModal}>
      <h5 className={style.deduplicationGroupModal__title}>{deduplication.titleFormComparison}</h5>
      {!isEmpty(deduplicationGroupProtocolInfo) && (
        <DeduplicationGroupTable
          goldRecordData={goldRecordData}
          groupId={groupId}
          isGoldRecord={isGoldRecord}
          setIsGoldRecord={setIsGoldRecord}
          setPayloadData={setPayloadData}
          status={status}
          deduplicationProtocolInfo={deduplicationProtocolInfo}
          deduplicationGroupProtocolInfo={
            !isEmpty(deduplicationGroupProtocolInfo) ? deduplicationGroupProtocolInfo : null
          }
          goldRecord={goldRecord}
          setGoldRecord={setGoldRecord}
          payloadData={payloadData}
        />
      )}
      <div className={style.deduplicationGroupModal__button_box}>
        <Button
          type="primary"
          ghost={status !== 'DRAFT'}
          danger={status !== 'DRAFT'}
          onClick={status === 'DRAFT' ? setLinkGroupHandler : cancelLinkGroupHandler}
          icon={<LinkOutlined />}
          disabled={status === 'DRAFT' && !isGoldRecord}
        >
          {status === 'DRAFT' ? deduplication.setLink : deduplication.cancelLink}
        </Button>
        <Button
          type="primary"
          ghost
          danger
          icon={<StopOutlined />}
          onClick={modalForDelete}
          hidden={status === 'LINK_SET' || status === 'LINK_BLOCKED'}
        >
          {deduplication.blockGroupsLink}
        </Button>
        <Button icon={<CloseOutlined />} onClick={onCloseModal}>
          {deduplication.close}
        </Button>
      </div>
    </div>
  );
};

export default React.memo(DeduplicationGroupModal);
