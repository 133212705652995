import React, {useState, useEffect, Key} from 'react';
import {Modal, ModalProps, PaginationProps, Table, TableProps} from 'ui-kit';
import useCRUD from '../../hooks/CRUD.hook';
import {errorModalCreate} from '../Helpers/Modals';
import {TableRowSelection} from 'antd/es/table/interface';

export interface MultiplySelectionProps {
  open: boolean;
  columns: TableProps<any>['columns'];
  onCancel: () => void;
  onOk: (selectedRows: any[], selectedRowKeys: string[]) => void;
  isSingleSelection: boolean;
  rowKey: string;
  defaultSelected: any[];
  title?: string;
  modalOptions: ModalProps;
}

const MultiplySelection = ({
  open,
  columns,
  onCancel,
  onOk,
  isSingleSelection,
  rowKey,
  defaultSelected = [],
  title,
  modalOptions,
}: MultiplySelectionProps) => {
  const [page, setPage] = useState<number>(0);
  const [size, setSize] = useState<number>(10);
  const {getData, data, loading, total} = useCRUD({apiUrl: '/api/v1/external-system'});
  const [selectedRowKeys, setSelectedRowKeys] = useState(defaultSelected);
  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    setSelectedRowKeys(defaultSelected);
    setSelectedRows([]);
  }, [defaultSelected]);

  useEffect(() => {
    getData({page, size});
  }, [getData, page, size]);

  const handleTableChange = (paginator: PaginationProps) => {
    paginator.current && setPage(--paginator.current);
    paginator.pageSize && setSize(paginator.pageSize);
  };

  const rowSelection: TableRowSelection<any> = {
    type: 'checkbox',
    onChange: (selectedRowKeys: Key[], selectedRows: any) => {
      setSelectedRowKeys(selectedRowKeys);
      setSelectedRows(selectedRows);
    },
    selectedRowKeys,
    preserveSelectedRowKeys: true,
  };

  const onModalOk = () => {
    if (isSingleSelection && selectedRowKeys.length > 1) errorModalCreate('Максимум 1 элемент');
    else {
      onOk(selectedRows, selectedRowKeys);
    }
  };

  return (
    <Modal {...modalOptions} open={open} onCancel={onCancel} onOk={onModalOk}>
      <Table
        dataSource={data}
        columns={columns}
        loading={loading}
        rowKey={(record) => record[rowKey]}
        rowSelection={rowSelection}
        onChange={handleTableChange}
        pagination={{
          total,
        }}
        bordered
      />
    </Modal>
  );
};

export default React.memo(MultiplySelection);
