import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {get, isEmpty} from 'lodash';
import {ButtonProps, Modal} from 'ui-kit';
import {mdm} from '../../Utils/constant';
import Versions from '../../components/Verisons';
import {fetchFunc} from '../../Utils/security/http/mdm';
import AccessRights from '../../components/AccessRights';
import Records from '../../components/CatalogItemWidgets/Records/Records';
import Attributes from '../../components/CatalogItemWidgets/Attributes';
import {useDispatch, useSelector} from 'react-redux';
import {
  setPageHeaderTabs,
  setPageHeaderButtons,
  setPageHeaderLoading,
  setBreadCrumbsTitle,
  setPageHeaderBack,
} from '../../redux/actions/actions';
import {SliceTabs} from 'ui-kit';

import style from './CatalogItem.module.scss';
import classNames from 'classnames';

import {RootState} from 'redux/types/rootState';
import {DeleteOutlined} from '@ant-design/icons';
import {useActiveTab} from 'hooks/navigation';
import {getPreviousFolderUrl} from 'Utils/pathUtils/getPrevPath';
import {RecordType} from 'types';

export interface CatalogItemProps {
  activeTab?: string | number;
  history: any;
  match: {
    params: {
      item: any;
    };
  };
  location?: any;
  setPageHeaderButtons: (value: ButtonProps[]) => void;
  setPageHeaderTabs: (value: {
    items: {key: string; label: string; hide?: boolean}[];
    activeTab?: string | number;
  }) => void;
  setPageHeaderLoading: (value: boolean) => void;
}

export interface CatalogItemState {
  data: any;
  referenceUuid: string;
  catalogData: any[];
  loading: boolean;
  showModal: boolean;
  requestUuid?: string;
  hasDuplicates?: boolean;
  isVerification?: boolean;
  isHierarchy?: boolean;
}

export const CatalogItem = ({history, location, match}: CatalogItemProps) => {
  const dispatch = useDispatch();

  const [data, setData] = useState<any>({});
  const [referenceUuid, setReferenceUuid] = useState<string>('');
  const [isRestored, setIsRestored] = useState(false);
  const [hasDuplicates, setHasDuplicates] = useState(false);
  const [isVerification, setIsVerification] = useState(false);
  const [isHierarchy, setIsHierarchy] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [requestUuid, setRequestUuid] = useState<string>('');
  const {activeTab} = useActiveTab();
  const [confirmDeleteIsVisible, setConfirmDeleteIsVisible] = useState(false);

  const deleteCatalogAccess = useSelector(({access}: RootState) => access.menuAccess.hasTypeCreateAccess);

  const editSigns = useMemo(() => {
    return {
      editAttributes: data.editAttributes,
      editCatalog: data.editCatalog,
      editProperties: data.editProperties,
      inputType: data.inputType,
    };
  }, [data.editAttributes, data.editCatalog, data.editProperties, data.inputType]);

  const viewRoute = data?.options?.special_interface?.view_route;
  const hideVerification = data.options?.special_interface?.hide_verification;

  const getCatalog = useCallback(async () => {
    try {
      if (!isEmpty(referenceUuid)) {
        setLoading(true);
        const data = await fetchFunc({
          url: `/api/v1/catalogs/${referenceUuid}`,
          method: 'get',
        });
        setData(data);
        setReferenceUuid(data?.uuid);
        setHasDuplicates(data?.hasDuplicates);
        setIsVerification(data.verification === true && true);
        setIsHierarchy(data?.hierarchy);
        setLoading(false);
        dispatch(setBreadCrumbsTitle(data.caption));
      }
    } catch (error) {
      setLoading(false);
    }
    dispatch(setPageHeaderLoading(false));
  }, [dispatch, referenceUuid]);

  const handleClickDeleteCatalog = useCallback(async () => {
    setLoading(true);
    setShowModal(false);
    try {
      const request = await fetchFunc({
        url: `/api/v1/catalog-draft`,
        method: 'put',
        data: {
          catalogIdentifier: {
            catalogIdentifier: data.origin,
          },
          hidden: true,
        },
      });
      if (request.uuid) {
        const requestDataAction = await fetchFunc({
          url: `/api/v1/catalog-draft/${request.uuid}/actions`,
          method: 'post',
          data: {action: 'Применить'},
        });
        const requestStatus = requestDataAction?.status?.caption;
        setLoading(false);
        setRequestUuid('catalog');
        isEmpty(requestStatus === 'Применено') ? history.push('/catalog') : history.push(`/catalog/${request.uuid}`);
      } else {
        setLoading(false);
      }
      dispatch(setPageHeaderLoading(false));
    } catch (error) {
      dispatch(setPageHeaderLoading(false));
      setLoading(false);
    }
  }, [data.origin, dispatch, history]);

  useEffect(() => {
    setReferenceUuid(match?.params?.item);
  }, [match?.params?.item]);

  useEffect(() => {
    getCatalog();
  }, [dispatch, referenceUuid, getCatalog]);

  useEffect(() => {
    if (deleteCatalogAccess) {
      dispatch(
        setPageHeaderButtons([
          {
            children: mdm.delete,
            type: 'primary',
            ghost: true,
            danger: true,
            icon: <DeleteOutlined />,
            onClick: () => {
              if (!confirmDeleteIsVisible) {
                Modal.confirm({
                  title: mdm.attention,
                  content: <div>{mdm.textForDeleteCatalog}</div>,
                  okText: mdm.confirm,
                  cancelText: mdm.cancel,
                  onOk: async () => {
                    handleClickDeleteCatalog();
                  },
                  onCancel: () => {
                    setConfirmDeleteIsVisible(false);
                  },
                });
                setConfirmDeleteIsVisible(true);
              }
            },
          },
        ])
      );
    } else {
      setPageHeaderButtons([]);
    }
  }, [confirmDeleteIsVisible, deleteCatalogAccess, dispatch, handleClickDeleteCatalog]);

  useEffect(() => {
    dispatch(setPageHeaderBack(getPreviousFolderUrl()));
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      setPageHeaderTabs({
        activeTab: activeTab ? `${activeTab}` : '1',
        items: [
          {key: '1', label: mdm.record},
          {key: '2', label: mdm.attributes, hide: !(data.editAttributes || data.editProperties)},
          {key: '3', label: mdm.permissions, hide: !data.editProperties},
          {key: '4', label: mdm.deletedRecord, hide: !data.editAttributes},
          {key: '5', label: mdm.verificationRecord, hide: !(data.validateRecords && !hideVerification)},
        ],
      })
    );
  }, [
    activeTab,
    data.editAttributes,
    data.editProperties,
    data.validateRecords,
    dispatch,
    hideVerification,
    referenceUuid,
  ]);

  useEffect(() => {
    dispatch(setPageHeaderLoading(true));
  }, [dispatch]);

  return (
    <div className={classNames('containerWhite', style.CatalogItem)}>
      <SliceTabs.Content activeKey={activeTab}>
        <SliceTabs.TabPane tab={mdm.record} key="1">
          <Records
            isRestored={isRestored}
            referenceOrigin={data.origin}
            hasContextSearch={data.hasContextSearch}
            referenceCaption={data.caption}
            isHierarchy={isHierarchy}
            hasDuplicates={hasDuplicates}
            inputType={data.inputType}
            referenceUuid={referenceUuid}
            location={location}
            match={match}
            editSigns={editSigns}
            maxRefsDepth={get(data, ['options', 'max_refs_depth'])}
            viewRoute={viewRoute} //прокидываю спешел интерфейс
            history={history}
          />
        </SliceTabs.TabPane>
        {(editSigns.editAttributes || editSigns.editProperties) && (
          <SliceTabs.TabPane tab={mdm.attributes} key="2">
            <Attributes
              referenceUuid={referenceUuid}
              referenceOrigin={data.origin}
              prioritySupport={data.prioritySupport}
              // history={history}
              // location={location}
              editSigns={editSigns}
              priorityModalVisible={undefined}
            />
          </SliceTabs.TabPane>
        )}
        {editSigns.editProperties && (
          <SliceTabs.TabPane tab={mdm.permissions} key="3">
            <AccessRights referenceOrigin={data.origin} />
          </SliceTabs.TabPane>
        )}
        {editSigns.editAttributes && (
          <SliceTabs.TabPane tab={mdm.deletedRecord} key="4">
            <Versions
              type="deleted"
              setIsRestored={setIsRestored}
              // location={location}
              // history={history}
              catalogUuid={referenceUuid}
              includeDeprecatedHistory={undefined}
            />
          </SliceTabs.TabPane>
        )}
        {data.validateRecords && !hideVerification && (
          <SliceTabs.TabPane tab={mdm.verificationRecord} key="5">
            <Records
              referenceOrigin={data.origin}
              referenceCaption={data.caption}
              referenceUuid={referenceUuid}
              isVerification={isVerification}
              isVerifier
              location={location}
              match={match}
              onDoubleClick={(record: any) => history.push(`/catalog/${data.origin}/${record.uuid}/verify`)}
              history={history}
            />
          </SliceTabs.TabPane>
        )}
      </SliceTabs.Content>
    </div>
  );
};

export default CatalogItem;
