import React, {ReactElement, ReactNode} from 'react';
import {Checkbox, Form, InputNumber, Popover, Button} from 'ui-kit';
import {Rule} from 'rc-field-form/es/interface';
import {digitRankFormatter} from 'components/Helpers/Utils';

interface CheckboxType {
  isDisabled: boolean;
  setFieldsValue: (value: any) => void;
}

export const CheckboxPhoneMask = ({isDisabled, setFieldsValue}: CheckboxType): JSX.Element => {
  return (
    <Form.Item
      name="phone_mask"
      valuePropName="checked"
      extra="Применение стандартной маски телефона доступно при отсутствии универсальной маски"
    >
      <Checkbox
        disabled={isDisabled}
        onChange={({target: {checked}}) => {
          if (checked) {
            setFieldsValue({
              input_mask: null,
            });
          }
        }}
      >
        Применить маску международного телефона
      </Checkbox>
    </Form.Item>
  );
};

interface AccessValueType {
  isDisabled: boolean;
  onChange?: (e: any) => void;
}

export const CheckboxAccessValue = ({isDisabled, onChange}: AccessValueType): JSX.Element => {
  return (
    <Form.Item name="accessValue" valuePropName="checked">
      <Checkbox onChange={onChange} disabled={isDisabled}>
        Доступ по значению поля
      </Checkbox>
    </Form.Item>
  );
};

interface DefaultValueType {
  rules?: Rule[];
  children: ReactNode;
  extra?: string;
  name?: string;
}

export const DefaultValue = ({children, rules, extra, name}: DefaultValueType): ReactElement => {
  return (
    <Form.Item label="Значение по умолчанию" extra={extra} name={name || 'defaultValue'} rules={rules}>
      {children}
    </Form.Item>
  );
};

interface DateType {
  rules?: Rule[];
  children: ReactNode;
}

export const DateFormat = ({children, rules}: DateType): ReactElement => {
  return (
    <Form.Item label="Формат значения" name="date_format" rules={rules}>
      {children}
    </Form.Item>
  );
};

export const DateDuration = ({children, rules}: DateType): ReactElement => {
  return (
    <Form.Item label="Календарных дней от текущей даты" name="date_duration" rules={rules}>
      {children}
    </Form.Item>
  );
};

export const DefaultValueDate = ({children, rules}: DateType): ReactElement => {
  return (
    <Form.Item label="Значение" name="defaultValue" rules={rules}>
      {children}
    </Form.Item>
  );
};

// MDM-2126
// export const hierarchyField = ({isDisabled, setFieldsValue}: CheckboxType): JSX.Element => {
//   return (
//     <Form.Item name="hierarchy_field" valuePropName="checked">
//       <Checkbox
//         disabled={isDisabled}
//         onChange={({target: {checked}}) => {
//           if (checked) {
//             setFieldsValue({
//               referenceCatalog: null,
//               referenceCatalogPattern: null,
//             });
//           }
//         }}
//       >
//         Иерархическое поле
//       </Checkbox>
//     </Form.Item>
//   );
// };

// MDM-2126
// export const multilevelLink = ({isDisabled, setFieldsValue}: CheckboxType): JSX.Element => {
//   return (
//     <Form.Item name="multilevel_link" valuePropName="checked">
//       <Checkbox
//         disabled={isDisabled}
//         onChange={({target: {checked}}) => {
//           if (checked) {
//             setFieldsValue({
//               referenceCatalogPattern: null,
//             });
//           }
//         }}
//       >
//         Многоуровневая ссылка
//       </Checkbox>
//     </Form.Item>
//   );
// };

export const enabledLower = ({isDisabled}: CheckboxType): JSX.Element => {
  return (
    <Form.Item name="enabledLower" valuePropName="checked">
      <Checkbox disabled={isDisabled}>Включая нижестоящие записи</Checkbox>
    </Form.Item>
  );
};

interface comboboxOptionsType {
  rules: Rule[];
  onClick: () => void;
  label: string;
}

export const ComboboxOptions = ({rules, onClick, label}: comboboxOptionsType): JSX.Element => {
  return (
    <Form.Item label="Список значений" name="combobox_options" rules={rules}>
      <Button onClick={onClick}>{label}</Button>
    </Form.Item>
  );
};

export const FieldOrder = (): JSX.Element => {
  return (
    <Form.Item
      label={
        <Popover content="Порядок расположения поля на форме просмотра записи справочника. Чем больше - тем ниже.">
          {'Порядок расположения'}
        </Popover>
      }
      name="fieldOrder"
    >
      <InputNumber decimalSeparator={','} formatter={(value) => digitRankFormatter(value)} className="w-100" />
    </Form.Item>
  );
};
