import React, {useEffect, useState} from 'react';
import {Button, Card, Tabs, Menu, Dropdown, Modal, Input} from 'ui-kit';
import {saveAs} from 'file-saver';
import moment from 'moment';
import {fetchFunc} from '../../Utils/security/http/mdm';
import RecordReader from '../../components/RecordRedactor/RecordReader';
import RecordEditor from '../../components/RecordRedactor/RecordEditor';
import {serviceMeta, additionalServiceFields} from './Meta/meta';
import {
  MainInfoStructure,
  BaseRECStructure,
  TariffsStructure,
  HistoryStructure,
  Specifications,
  ProductApproval,
} from './Structure/structure';
import Versions from '../../components/Verisons';
import {normalizeValues, onError} from '../../components/Helpers/Utils';
import FormInitiator from '../../components/RecordRedactor/FormInitator';
import {errorModalCreate} from '../../components/Helpers/Modals';
import SystemInformation from '../../components/SystemInformation';
import {LoadingOutlined, PrinterOutlined} from '@ant-design/icons';
import {metaUnifier} from '../../components/Helpers/RuleConfigurator';
import StatusHistory from './StatusHistory';
import ServiceDocuments from './ServiceDocuments';
import BusinessVersions from '../../components/BusinessVersions';
import BpmnModeller from '../../components/BpmnModeller';
import {structureElementManipulations} from '../../components/Helpers/StructureManipulations';
import {useHistory} from 'react-router';
import {get, set} from 'lodash';
import Approval from './CustomRenders/Approval/Approval';
import SysActionForm from './Forms/SysActionForm';

const {TabPane} = Tabs;
const {SubMenu} = Menu;

const ServiceCard = (props) => {
  const {match, serviceUuid, isLastVersion, openFromVersion} = props;

  const [loading, setLoading] = useState(false);
  const [serviceData, setData] = useState(null);
  const [allServiceData, setAllServiceData] = useState(null);
  const [statusDate, setStatusDate] = useState(null);
  const [recordUuid, setRecordUuid] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [hiddenFields, setHiddenFields] = useState([]);
  const [printForms, setPrintForms] = useState([]);
  const [actions, setActions] = useState([]);
  const [actionModalVisible, setActionModalVisible] = useState(false);
  const [actionName, setActionName] = useState('');
  const [actionCode, setActionCode] = useState('');
  const [access, setAccess] = useState({});
  const [meta, setMeta] = useState([]);
  const [catalog, setCatalog] = useState({});
  const [versionModalVisible, setVersionModalVisible] = useState(false);
  const [tabKey, setTabKey] = useState('mainInfoStructure');
  const [readonly, setReadonly] = useState(true);
  const history = useHistory();

  const [servicesStructure, setServicesStructure] = useState({
    mainInfoStructure: [],
    baseRECStructure: [],
    tariffsStructure: [],
    historyStructure: [],
    specifications: [],
  });

  useEffect(() => {
    if (meta.length) {
      setupServiceStructure(meta);
    }
  }, [meta]);

  useEffect(() => {
    if (serviceUuid) {
      setRecordUuid(serviceUuid);
    } else if (!isLastVersion) {
      setRecordUuid(match.params && match.params.id);
    }
  }, [match]);

  useEffect(() => {
    setLoading(true);
    getPrintForms();
    if (isLastVersion) {
      getServiceVersionInfo();
    }
    if (recordUuid) {
      if (!isLastVersion) {
        getServiceInfo(recordUuid);
      }
      getStatusDate();
      getServiceFields();
      getAccess();
      getActions();
      getCatalog();
    }
  }, [recordUuid]);

  useEffect(() => {
    if (serviceData && statusDate) {
      setAllServiceData({
        ...serviceData,
        statusDate,
      });
    }
  }, [serviceData, statusDate]);

  const getStatusDate = async () => {
    try {
      const data = await fetchFunc({
        url: `/api/v1/service/${recordUuid}/statusHistory`,
        params: {
          size: 1,
          sort: 'dateFrom,desc',
        },
      });
      if (data.content[0] && data.content[0].changeDate) {
        setStatusDate(moment(data.content[0].changeDate).format('YYYY-MM-DD'));
      }
    } catch (error) {}
  };

  const getServiceVersionInfo = async () => {
    try {
      const fetchData = await fetchFunc(
        {
          url: `/api/v1/service/${openFromVersion}/versions/acting`,
          method: 'get',
        },
        null
      );
      setData(fetchData);
      setRecordUuid(fetchData.uuid);
    } catch (error) {
      setLoading(false);
    }
  };

  const onServiceVersionInfoError = (error) => {
    const errorMessage = error.status === 404 ? 'Не существует ни одной действующей версии' : error.data.message;
    errorModalCreate(errorMessage);
  };

  const getCatalog = async () => {
    try {
      const data = await fetchFunc({
        url: `/api/v1/catalogs/service`,
      });
      setCatalog(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const getAccess = async () => {
    try {
      const data = await fetchFunc({
        url: `/api/v1/catalogs/service/items/${recordUuid}/fact/access`,
      });
      setAccess(data);
    } catch (error) {}
  };

  const getActions = async () => {
    const errorMessage = 'Не удалось получить данные о действиях!';
    const customErrorMessage = ({data: {message = errorMessage}}) => errorModalCreate(message);
    try {
      const data = await fetchFunc(
        {
          url: `/api/v1/service/${recordUuid}/actions`,
        },
        customErrorMessage
      );
      if (!data) return errorModalCreate(errorMessage);
      let actions = Object.keys(data.actions).map((key) => {
        return {
          label: key,
          ...data.actions[key],
        };
      });
      setActions(actions);
      setReadonly(data.readonly);
    } catch (error) {
      console.log(error);
    }
  };

  const changeStatus = async (body) => {
    setLoading(true);
    const customErrorMessage = ({data: {message}}) => errorModalCreate(message);
    try {
      const res = await fetchFunc(
        {
          url: `/api/v1/service/${recordUuid}/actions`,
          method: 'post',
          data: body,
        },
        customErrorMessage
      );
      await getActions();
      await getServiceInfo(recordUuid);
      const newServiceUuid = get(res, 'objects.copy.uuid', null);
      if (newServiceUuid) {
        history.push(`/services/${res.objects.copy.uuid}`);
      }
    } finally {
      setLoading(false);
    }
  };

  const onChangeStatusClick = async (action, actionCode) => {
    if (actionCode === 'commentForming' || actionCode === 'changeResponsible') {
      setActionModalVisible(true);
      setActionName(action);
      setActionCode(actionCode);
    } else {
      await changeStatus({action});
    }
  };

  const onActionModalOk = async (values) => {
    await changeStatus({
      action: actionName,
      params: values,
    });
    setActionModalVisible(false);
  };

  const getServiceInfo = async (uuid) => {
    try {
      let fetchData = await fetchFunc({
        url: `/api/v1/catalogs/service/items/${uuid}?showDetails=1`,
        method: 'get',
      });
      setData(fetchData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const getServiceFields = async () => {
    try {
      const response = await fetchFunc({
        url: `/api/v1/catalogs/service/fields?size=1000`,
        method: 'get',
      });
      const meta = metaUnifier([...response.content, ...additionalServiceFields], serviceMeta);
      setMeta(meta);
    } catch (error) {
      errorModalCreate((error.response && error.response.data.message) || 'Неизвестная ошибка');
    }
  };

  const changeServiceInfo = async (values) => {
    try {
      const fetchData = await fetchFunc(
        {
          url: `/api/v1/catalogs/service/items/${recordUuid}?showDetails=1`,
          method: 'put',
          data: {
            fields: values,
          },
        },
        onError
      );
      setData(fetchData);
      setLoading(false);
      setEditMode(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const getPrintForms = async () => {
    try {
      let data = await fetchFunc({
        url: `/api/v1/catalogs-type/service/printforms`,
      });
      setPrintForms(data.content);
    } catch (error) {}
  };

  const printDocument = async (e, format) => {
    try {
      let tempUuid = e.target.value;
      let data = await fetchFunc({
        url: `/api/v1/service/${recordUuid}/printforms/${tempUuid}`,
        params: {format},
        responseType: 'blob',
      });
      saveAs(data, `${serviceData.name}.${format}`);
    } catch (error) {}
  };

  const checkExistenceVersion = async () => {
    try {
      await fetchFunc(
        {
          url: `/api/v1/service/${allServiceData && allServiceData.uuid}/versions/acting`,
          method: 'get',
        },
        onServiceVersionInfoError
      );
      setVersionModalVisible(true);
    } catch (error) {}
  };

  const fillEcmDocuments = async (doc = [], recUuid) => {
    setLoading(true);
    const docData = Array.isArray(doc) ? doc : [doc];
    const preparedDocData = docData.map(({uuid, documentTypeIdentifier: {documentTypeIdentifier}}) => ({
      docType: documentTypeIdentifier,
      uuid,
    }));
    await fetchFunc({
      url: `/api/v1/service/${recUuid}/fillEcmDocuments`,
      method: 'post',
      data: preparedDocData,
    });
    await getServiceInfo(recUuid);
    setLoading(false);
  };

  const removeDocFromEcmDocuments = async (uuid) => {
    setLoading(true);
    await fetchFunc({
      url: `/api/v1/catalogs/service/detail/ecmDocs/${recordUuid}/items/${uuid}`,
      method: 'delete',
    });
    await getServiceInfo(recordUuid);
    setLoading(false);
  };

  const print = (
    <Menu>
      {printForms.length &&
        printForms.map((item, index) => {
          return (
            <SubMenu key={index} title={item.caption}>
              <Menu.Item key={index + '_pdf'}>
                <button className="documents__drop-button" value={item.uuid} onClick={(e) => printDocument(e, 'pdf')}>
                  pdf
                </button>
              </Menu.Item>
              <Menu.Item key={index + '_word'}>
                <button className="documents__drop-button" value={item.uuid} onClick={(e) => printDocument(e, 'docx')}>
                  word
                </button>
              </Menu.Item>
              <Menu.Item key={index + '_excel'}>
                <button className="documents__drop-button" value={item.uuid} onClick={(e) => printDocument(e, 'xlsx')}>
                  excel
                </button>
              </Menu.Item>
            </SubMenu>
          );
        })}
    </Menu>
  );

  const setupServiceStructure = (meta) => {
    let serviceStructure = setupStaticStructure();
    setupCustomStructure(serviceStructure, meta);
  };

  const setupCustomStructure = (staticStructure, orgMeta) => {
    let newServiceStructure = staticStructure;
    structureElementManipulations(orgMeta, newServiceStructure);
    setServicesStructure(newServiceStructure);
  };

  const setupStaticStructure = () => {
    return {
      mainInfoStructure: MainInfoStructure,
      baseRECStructure: BaseRECStructure,
      tariffsStructure: TariffsStructure,
      historyStructure: HistoryStructure,
      specifications: Specifications,
      productApproval: ProductApproval,
    };
  };

  const {mainInfoStructure, baseRECStructure, tariffsStructure, historyStructure, specifications, productApproval} =
    servicesStructure;
  const renderEditButtons = () => {
    return (
      <div>
        {!readonly &&
          (editMode ? (
            <Button onClick={() => setEditMode(false)} className="ml-1 ml-auto">
              Отменить
            </Button>
          ) : (
            <>
              {serviceData && access.canEdit && (
                <Button type="primary" className="ml-auto" onClick={() => setEditMode(true)}>
                  Изменить
                </Button>
              )}
            </>
          ))}
        <Dropdown className="ml-2" overlay={print} trigger={['click']}>
          <Button>
            <PrinterOutlined />
          </Button>
        </Dropdown>
      </div>
    );
  };

  const changeRecord = (values) => {
    setLoading(true);
    changeServiceInfo(normalizeValues({values, fields: meta}));
  };

  const renderViews = (meta, structure, editMode, store, form, isCard = true) => {
    return !editMode ? (
      <RecordReader
        data={allServiceData}
        recordUuid={recordUuid}
        referenceOrigin={store}
        fieldsMeta={meta}
        structure={structure}
      />
    ) : (
      <RecordEditor
        form={form}
        data={allServiceData}
        recordUuid={recordUuid}
        referenceOrigin={store}
        fieldsMeta={meta}
        structure={structure}
        setHiddenFields={setHiddenFields}
        hiddenFields={hiddenFields}
      />
    );
  };

  const tabsForRender = (form) => {
    return (
      <Tabs className={'org-list__tabs'} activeKey={tabKey} onChange={setTabKey}>
        <TabPane forceRender={true} tab="Общая информация" key="mainInfoStructure">
          {renderViews(meta, mainInfoStructure, editMode, 'service', form)}
        </TabPane>
        <TabPane forceRender={true} tab="Нормативно-правовая база РЭЦ" key="baseRECStructure">
          <ServiceDocuments
            fillEcmDocuments={fillEcmDocuments}
            removeDocFromEcmDocuments={removeDocFromEcmDocuments}
            ecmDocs={serviceData?.ecmDocs ?? []}
            catalog={catalog}
            serviceId={recordUuid}
            editMode={editMode}
            recUuid={recordUuid}
          />
          {renderViews(meta, baseRECStructure, editMode, 'service', form)}
        </TabPane>
        <TabPane forceRender={true} tab="Тарифы и ограничения" key="tariffsStructure">
          {renderViews(meta, tariffsStructure, editMode, 'service', form)}
        </TabPane>
        <TabPane tab="Характеристики услуги" key="specifications">
          {renderViews(meta, specifications, editMode, 'service', form)}
        </TabPane>
        <TabPane forceRender={true} tab="Версии" key="versions">
          <Card title="Бизнес-версии" className={'org-list__card container--white mb-5'}>
            <BusinessVersions recordUuid={recordUuid} />
          </Card>
          <Card title="Технические версии" className={'org-list__card container--white mb-5'}>
            <Versions
              catalogUuid={'service'}
              recordUuid={recordUuid}
              location={props.location}
              history={props.history}
            />
          </Card>
          <Card title="История изменения статусов" className={'org-list__card container--white mb-5'}>
            {renderViews(meta, historyStructure, editMode, 'service', form)}
            <StatusHistory serviceId={recordUuid} />
          </Card>
        </TabPane>
        <TabPane forceRender={true} tab="Согласование продукта" key="productApproval">
          {renderViews(meta, productApproval, editMode, 'service', form)}
          <Approval serviceId={recordUuid} data={allServiceData} meta={meta} />
        </TabPane>
        <TabPane tab="Бизнес-схема" key="bpmnSchema">
          <BpmnModeller
            schemeId={recordUuid}
            store="service"
            readonly={readonly && !access.canEdit}
            editMode={editMode}
          />
        </TabPane>
        <TabPane tab="Исполняемая схема" key="executableBpmnSchema">
          <BpmnModeller schemeId={serviceData?.bpmnProcess} store="process-definition" readonly={true} />
        </TabPane>
      </Tabs>
    );
  };

  return (
    <>
      <Card title={serviceData?.status?.caption ?? ''} className="org-list" extra={renderEditButtons()}>
        {serviceData && (
          <SystemInformation
            data={serviceData}
            statusDate={statusDate}
            businessVersions
            onVersionOpen={() => checkExistenceVersion()}
            referenceOriginLocal={'service'}
          />
        )}
        {actions && (
          <div className="d-flex justify-content-end my-2">
            {actions.map((item) => (
              <Button
                key={item.label}
                type="primary"
                className="mr-1"
                onClick={() => onChangeStatusClick(item.label, item.sysAction)}
              >
                {item.label}
              </Button>
            ))}
          </div>
        )}
        {allServiceData && allServiceData.uuid && meta.length ? (
          !editMode ? (
            tabsForRender()
          ) : (
            <FormInitiator
              structure={servicesStructure}
              setTabKey={setTabKey}
              formName={'service-form'}
              hiddenFields={hiddenFields}
              fieldsMeta={meta}
              data={allServiceData}
              changeRecord={changeRecord}
              fixedSubmitButton
              submitButtonPosition={{
                bottom: '50px',
                right: '64px',
              }}
              children={(form) => tabsForRender(form)}
            />
          )
        ) : (
          ''
        )}
        <Modal
          title={actionName}
          open={actionModalVisible}
          footer={null}
          onCancel={() => setActionModalVisible(false)}
          destroyOnClose
        >
          <SysActionForm onFinish={onActionModalOk} actionCode={actionCode} />
        </Modal>
        <Modal
          open={versionModalVisible}
          onCancel={() => setVersionModalVisible(false)}
          footer={null}
          width={1100}
          title={`Последняя действующая версия`}
        >
          <ServiceCard isLastVersion openFromVersion={allServiceData && allServiceData.uuid} />
        </Modal>
      </Card>
      {
        <div className={`loading-overlay loading-overlay--${loading ? 'visible' : 'hidden'}`}>
          <LoadingOutlined className={'loading-overlay__icon'} />
        </div>
      }
    </>
  );
};

export default ServiceCard;
