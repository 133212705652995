import React, {useState} from 'react';
import cn from 'classnames';
import {Form, Select, Button, DatePicker, SelectDefaultOptionType} from 'ui-kit';

import {deduplication} from '../helpers/constants';

import './DeduplicationFilters.scss';
import styles from './DeduplictaionFilters.module.scss';

import {CheckOutlined, CloseOutlined} from '@ant-design/icons';

const {RangePicker} = DatePicker;

/**
 * Фильтр дедупликации
 * @param statusDeduplication - фильтр статус задания дедупликации
 * @param authorUserName - фильтр автор задания на дедупликацию
 * @param jobTitleFilter -  фильтр наименования задания дедупликации
 * @param statusConnectionFilter - фильтр статус протокола дедупликации
 * @param userConfirmConnectFilter - фильтр пользователь,подтвердивший связь
 * @param catalogTitle - фильтр справочник
 * @param setFilter - функция сеттер фильтра
 * @param filterProtocol -ключ к фильтрам протокола дедупликации
 * @param deduplicationJobFilter -ключ к фильтрам задания дедупликации
 * @param deduplicationProtocolsFilter -ключ к фильтрам протоколов дедупликации
 */

export interface DeduplicationFiltersProps {
  statusDeduplication?: any[];
  authorUserName: any[];
  statusConnectionFilter?: SelectDefaultOptionType[];
  userConfirmConnectFilter?: SelectDefaultOptionType[];
  catalogTitle: any[];
  setFilter: (value: any) => void;
  filterProtocol?: boolean;
  deduplicationJobFilter: boolean;
  deduplicationProtocolsFilter?: boolean;
  jobTitleFilter?: any[];
  loading: boolean;
}

const DeduplicationFilters: React.FC<DeduplicationFiltersProps> = ({
  statusDeduplication,
  authorUserName,
  statusConnectionFilter,
  userConfirmConnectFilter,
  catalogTitle,
  setFilter,
  filterProtocol,
  deduplicationJobFilter,
  deduplicationProtocolsFilter,
  jobTitleFilter,
  loading,
}) => {
  /** Получение данных с формы */
  const [form] = Form.useForm();
  /** Значение фильтра */
  const [filterValue, setFilterValue] = useState<{
    status: string | null;
    author: string | null;
    catalog: string | null;
    launchDate: string | null;
    name: string | null;
    statusConnection: string | null;
    userConfirmConnect: string | null;
  }>({
    status: null,
    author: null,
    catalog: null,
    launchDate: null,
    name: null,
    statusConnection: null,
    userConfirmConnect: null,
  });
  /** Изменение значений фильтра */
  const onChangeFilter = (field: any, value: any) => {
    switch (field) {
      case 'status':
        setFilterValue({...filterValue, status: `${value}`});
        break;
      case 'author':
        setFilterValue({...filterValue, author: `${value}`});
        break;
      case 'catalog':
        setFilterValue({...filterValue, catalog: `${value}`});
        break;
      case 'name':
        setFilterValue({...filterValue, name: `${value}`});
        break;
      case 'launchDate':
        setFilterValue({...filterValue, launchDate: value});
        break;
      case 'statusConnection':
        setFilterValue({...filterValue, statusConnection: value});
        break;
      case 'userConfirmConnect':
        setFilterValue({...filterValue, userConfirmConnect: value});
        break;
      default:
        break;
    }
  };
  /** Изменение значений фильтра - статус */
  const handleOnChangeStatus = (event: any) => onChangeFilter('status', event);
  /** Изменение значений фильтра - автор */
  const handleOnChangeAuthor = (event: any) => onChangeFilter('author', event);
  /** Изменение значений фильтра - справочник */
  const handleOnChangeCatalog = (event: any) => onChangeFilter('catalog', event);
  /** Изменение значений фильтра - Статус связи */
  const handleOnChangeStatusConnection = (event: any) => onChangeFilter('statusConnection', event);
  /** Изменение значений фильтра - Пользователь,подтвердивший связь */
  const handleOnChangeUserConfirmConnect = (event: any) => onChangeFilter('userConfirmConnect', event);
  /** Изменение значений фильтра - дата запуска */
  const handleOnChangeDate = (launchDate: any, formatString: [string, string]) => {
    const dateFrom = launchDate?.[0];
    const dateTo = launchDate?.[1];
    onChangeFilter('launchDate', [dateFrom, dateTo]);
  };
  /** Изменение значений фильтра - Наименование задания */
  const handleOnChangeName = (event: any) => onChangeFilter('name', event);
  /** Изменение значений фильтра при подтверждении  */
  const handleSubmit = () => {
    setFilter({
      ...filterValue,
      name: filterValue.name,
      author: filterValue.author,
      status: filterValue.status,
      catalog: filterValue.catalog,
      launchDate: filterValue.launchDate,
      statusConnection: filterValue.statusConnection,
      userConfirmConnect: filterValue.userConfirmConnect,
    });
  };

  return (
    <Form
      className={cn(styles.deduplicationFilters, {
        [styles.filterProtocol]: filterProtocol,
      })}
      form={form}
      name="protocol_filter_form"
      onFinish={handleSubmit}
    >
      <div className={styles.filters}>
        <Form.Item
          className={cn(styles.name_filter, {
            [styles.protocols_filter]: filterProtocol || deduplicationProtocolsFilter,
          })}
          name="name"
          hidden={filterProtocol}
        >
          <Select
            placeholder="Наименование задания"
            filterOption={true}
            onChange={handleOnChangeName}
            value={filterValue.name}
            options={jobTitleFilter}
            showSearch
            loading={loading}
          />
        </Form.Item>
        <Form.Item
          className={styles.author_filter}
          name="author"
          hidden={filterProtocol || deduplicationProtocolsFilter}
        >
          <Select
            filterOption={true}
            placeholder="Автор "
            options={authorUserName}
            value={filterValue.author}
            onChange={handleOnChangeAuthor}
            showSearch
            loading={loading}
          />
        </Form.Item>
        <Form.Item className={styles.date_protocol_filter} name="launchDate" hidden={filterProtocol}>
          <RangePicker className={styles.date_protocol_filter} onChange={handleOnChangeDate} format="DD.MM.YYYY" />
        </Form.Item>
        <Form.Item
          className={cn(styles.catalog_filter, {
            deduplicationFilters__catalogs: filterProtocol || deduplicationProtocolsFilter,
          })}
          name="catalog"
          hidden={filterProtocol}
        >
          <Select
            onChange={handleOnChangeCatalog}
            value={filterValue.catalog}
            filterOption={true}
            placeholder="Справочник "
            options={catalogTitle}
            showSearch
            loading={loading}
          />
        </Form.Item>
        <Form.Item
          className={styles.status_filter}
          name="status"
          hidden={filterProtocol || deduplicationProtocolsFilter}
        >
          <Select
            onChange={handleOnChangeStatus}
            value={filterValue.status}
            filterOption={(input, option) => (`${option?.value}` || '').toLowerCase().includes(input.toLowerCase())}
            placeholder="Статус "
            options={statusDeduplication}
            showSearch
            loading={loading}
          />
        </Form.Item>
        <Form.Item
          className={cn('deduplicationFilters__status-connection', {
            ' deduplicationFilters__status-connection--filterProtocol': filterProtocol,
          })}
          name="statusConnection"
          hidden={deduplicationProtocolsFilter || deduplicationJobFilter}
        >
          <Select
            onChange={handleOnChangeStatusConnection}
            value={filterValue.statusConnection}
            filterOption={true}
            placeholder="Статус связи "
            options={statusConnectionFilter}
            showSearch
            loading={loading}
          />
        </Form.Item>
        <Form.Item
          className={cn('deduplicationFilters__user-name', {
            ' deduplicationFilters--filterProtocol': filterProtocol,
          })}
          name="userConfirmConnect"
          hidden={deduplicationJobFilter || deduplicationProtocolsFilter}
        >
          <Select
            onChange={handleOnChangeUserConfirmConnect}
            value={filterValue.userConfirmConnect}
            filterOption={true}
            placeholder="Пользователь, подтвердивший связь"
            options={userConfirmConnectFilter}
            showSearch
            loading={loading}
          />
        </Form.Item>
      </div>
      <div className="d-flex gap-8 flex-wrap">
        <Button
          icon={<CloseOutlined />}
          type="default"
          onClick={() => {
            form.resetFields();
            setFilterValue({
              status: null,
              author: null,
              catalog: null,
              launchDate: null,
              name: null,
              statusConnection: null,
              userConfirmConnect: null,
            });
            setFilter({
              status: null,
              author: null,
              catalog: null,
              launchDate: null,
              name: null,
              statusConnection: null,
              userConfirmConnect: null,
            });
          }}
          loading={loading}
        >
          {deduplication.cancel}
        </Button>
        <Button type="primary" htmlType="submit" icon={<CheckOutlined />} loading={loading}>
          {deduplication.confirm}
        </Button>
      </div>
    </Form>
  );
};

export default React.memo(DeduplicationFilters);
