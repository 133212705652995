const bpmnModeller = (props) => `
  & .bpmnModeller__container{
    border: 1px solid #000000;
    height: 90vh;
    width: 90vw;
  }

  & .bpmnModeller__file-input{
    display: none!important;
  }

  & .bpmnModeller__custom-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
  }

  & .bpmnModeller__input-btn {
    padding: 4px 10px;
    cursor: pointer;
  }
`;

export const bpmnModellerStyles = {
  bpmnModeller,
};
