import {Button, Checkbox, Form, Input, InputNumber, Select, Typography} from 'ui-kit';
import styles from './DetailDraftRedactor.module.scss';
import {fetchFunc} from 'Utils/security/http/mdm';
import {CheckOutlined, CloseOutlined, FormOutlined, SaveOutlined} from '@ant-design/icons';
import classNames from 'classnames';
import {requiredRule} from 'components/EditFieldForm/helpers/constants';
import {checkFormat} from 'components/Helpers/Utils';
import {mdm} from 'Utils/constant';
import {useState} from 'react';
import {isBoolean, isString} from 'lodash';
import {RecordType} from 'types';

export interface AttributesProps {
  data: RecordType;
  uuid: string;
  detailUuid?: string;
  operation?: string;
  onSave: () => void;
}

const DetailDraftAttributes = ({data, uuid, detailUuid, operation, onSave}: AttributesProps) => {
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const onchange = () => {
    setIsEdit(true);
  };

  const onCancel = () => {
    form.resetFields();
    setIsEdit(false);
  };

  const fields = [
    {value: 1, label: 'Строка (до 255 символов)'},
    {value: 11, label: 'Текст'},
    {value: 5, label: 'Файл'},
    {value: 13, label: 'Гиперссылка (URL)'},
    {value: 2, label: 'Целое число'},
    {value: 3, label: 'Число с плавающей точкой'},
    {value: 4, label: 'Ссылка на справочник'},
    {value: 14, label: 'Выбор из списка'},
    {value: 10, label: 'Логическое'},
    {value: 7, label: 'Дата и время'},
    {value: 12, label: 'Детализация'},
  ];

  const editAttributes = async (values: RecordType) => {
    setLoading(true);
    try {
      const data = makeBody(values);
      await fetchFunc({
        url: `/api/v1/catalog-draft/${uuid}/fields/${detailUuid}`,
        method: 'put',
        data,
      });
      setIsEdit(false);
      setLoading(false);
      onSave();
    } catch (error) {
      setLoading(false);
    }
  };

  const makeBody = (values: RecordType) => {
    const options = ['gui_editable', 'old', 'rules'];
    const data: RecordType = {operation};
    for (const key in values) {
      if (options.includes(key)) {
        if (isBoolean(values[key]) || isString(values[key])) {
          data.options = {
            ...data.options,
            [key]: values[key],
          };
        }
      } else {
        data[key] = values[key];
      }
    }
    return data;
  };

  const noRequiredRule = [{required: false}];

  const initialValues = {
    fieldTypeId: data?.fieldType?.id,
    caption: data?.caption,
    origin: data?.origin,
    description: data?.description,
    gui_editable: data?.options?.gui_editable,
    old: data?.options?.old,
    rules: data?.options?.rules,
    fieldOrder: data?.fieldOrder,
  };

  const valueItem = fields.find((elem) => elem.value === data?.fieldType?.id);

  return (
    <Form form={form} initialValues={initialValues} onFinish={editAttributes} layout="vertical">
      <div className={styles.upperBlock}>
        <Typography.Title level={3}>{mdm.params}</Typography.Title>
        <div className="d-flex gap-12">
          {!isEdit ? (
            <Button icon={<FormOutlined />} onClick={onchange} type="primary">
              {mdm.change}
            </Button>
          ) : (
            <>
              <Form.Item>
                <Button type="primary" htmlType="submit" loading={loading} icon={<SaveOutlined />}>
                  {mdm.save}
                </Button>
              </Form.Item>
              <Button onClick={onCancel} loading={loading} icon={<CloseOutlined />}>
                {mdm.cancel}
              </Button>
            </>
          )}
        </div>
      </div>
      <div
        className={classNames(styles.form, {
          [styles.noEdit]: !isEdit,
        })}
      >
        <Form.Item label="Тип поля" name="fieldTypeId">
          {isEdit ? <Select options={fields} disabled></Select> : valueItem ? valueItem.label : '-'}
        </Form.Item>
        <Form.Item rules={isEdit ? requiredRule : noRequiredRule} label="Наименование" name="caption">
          {isEdit ? <Input /> : data?.caption || '-'}
        </Form.Item>
        <Form.Item
          rules={isEdit ? [...requiredRule, {validator: checkFormat}] : noRequiredRule}
          label="Наименование в БД"
          name="origin"
        >
          {isEdit ? <Input /> : data?.origin || '-'}
        </Form.Item>
        <Form.Item label="Описание" name="description">
          {isEdit ? <Input.TextArea /> : data?.description || '-'}
        </Form.Item>
        <Form.Item label="Редактируемое через GUI" name="gui_editable" valuePropName="checked">
          {isEdit ? <Checkbox /> : data?.options?.gui_editable ? <CheckOutlined /> : <CloseOutlined />}
        </Form.Item>
        <Form.Item label="Устаревшее поле" name="old" valuePropName="checked">
          {isEdit ? <Checkbox /> : data?.options?.old ? <CheckOutlined /> : <CloseOutlined />}
        </Form.Item>
        {/* MDM-2147 */}
        {/* <Form.Item label="Правила поля" name="rules">
          {isEdit ? <Input.TextArea /> : data?.options?.rules || '-'}
        </Form.Item> */}
        <Form.Item label="Порядок расположения" name="fieldOrder">
          {isEdit ? <InputNumber style={{width: 200}} /> : data?.fieldOrder || '-'}
        </Form.Item>
      </div>
    </Form>
  );
};

export default DetailDraftAttributes;
