import {ReactComponent as IconUserFilled} from './icons/filled/user.svg';
import {ReactComponent as IconDeduplicationOutlined} from './icons/outlined/deduplication.svg';

export type SvgComponentType = React.FunctionComponent<React.SVGProps<SVGSVGElement>>;

export const customIconsMap: Record<string, SvgComponentType> = {
  // outlined
  deduplicationOutlined: IconDeduplicationOutlined,

  // filled
  userFilled: IconUserFilled,
};

// export type CustomIconName = keyof typeof customIconsMap;
export type CustomIconNameOutlined = 'deduplicationOutlined';
export type CustomIconNameFilled = 'userFilled';

export type CustomIconName = CustomIconNameFilled | CustomIconNameOutlined;
