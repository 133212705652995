import React, {RefObject} from 'react';
import {Select, Spin, SelectProps} from 'ui-kit';
import _, {isEmpty, isNull, isString} from 'lodash';

import {errorModalCreate} from '../../Helpers/Modals';
import {fetchFunc} from '../../../Utils/security/http/mdm';
import {charCounter, createReferenceScriptText, onError} from '../../Helpers/Utils';
import {RecordType} from 'types';

export interface FetchOptions {
  referenceStore: string;
  valueField?: string;
  catalogOrigin?: 'catalogs';
  inComeOptionUrl?: string;
  displayedField: string;
  url: string;
  method: 'get' | 'post';
  valueSearchName?: string;
  defaultSize?: number;
  extraSearchParams?: string;
  getFieldValue?: (value: boolean) => void;
  relatedParams?: boolean[];
  fieldsForSearch?: string[];
  defaultFilter?: RecordType;
  refScript?: {names: string[]};
  keyForOptions?: boolean;
  referenceCatalogue?: 'subsystem';
  timeout?: number;
}

export interface DynamicSelectProps extends SelectProps {
  defaultValue?: any;
  detailMode?: 'create';
  value?: any;
  feachoptions: FetchOptions;
  selectedUuid?: boolean;
  resetOptions?: boolean;
  mode?: 'multiple' | 'tags';
  filterMode?: boolean;
  displayedFieldSeparator?: string;
  notFoundContent?: React.ReactNode;
  handleSelectChange: (value: any, option: any, itemData?: any) => void;
  filterOption?: boolean | ((inputValue: string, option?: any) => boolean);
  fullResponseValue?: boolean;
  defaultUuid?: string;
  valueSearchName?: boolean;
  onDataFilter?: (fields: any[]) => any[];
  noVisibleOptions?: string[] | number[];
}

export interface DynamicSelectState {
  depth: number;
  options: RecordType[];
  renderFields: string[];
  fetching: boolean;
  // nextFetch: boolean;
  data: RecordType[];
}

class DynamicSelect extends React.Component<DynamicSelectProps, DynamicSelectState> {
  constructor(props: DynamicSelectProps) {
    super(props);
    this.state = {
      options: [],
      renderFields: [],
      fetching: false,
      depth: 1,
      // nextFetch: true,
      data: [],
    };
    this.pageNumberRef = React.createRef();
  }

  pageNumberRef: null | RefObject<any> = null;

  componentDidMount() {
    const {
      defaultValue,
      detailMode,
      value,
      feachoptions: {displayedField},
    } = this.props;
    if (displayedField && displayedField.indexOf('.') !== -1) {
      this.setState({
        depth: _.isString(displayedField) ? charCounter(displayedField, /[^.]/g) : 0,
      });
    } else {
      if (detailMode) this.setState({depth: 1});
    }
    if (defaultValue || value) {
      this.setState(
        {
          fetching: true,
        },
        () => this.getOptions()
      );
    }
  }

  componentDidUpdate(prevProps: DynamicSelectProps) {
    const {defaultValue, value} = this.props;

    if ((defaultValue && defaultValue !== prevProps.defaultValue) || (value && value !== prevProps.value)) {
      this.setState(
        {
          fetching: true,
        },
        () => this.getOptions()
      );
    }
  }

  getInComeOption = async (uuid: string, unUsedVar: any = false) => {
    this.setState({
      fetching: true,
    });
    const {referenceStore, valueField = 'uuid', catalogOrigin, inComeOptionUrl} = this.props.feachoptions;
    const {depth} = this.state;
    let urlForFetching;
    let method = 'get';
    if (!isEmpty(uuid)) {
      if (inComeOptionUrl) urlForFetching = `${inComeOptionUrl}/${uuid}`;
      else {
        if (catalogOrigin === 'catalogs') urlForFetching = `/api/v1/catalogs/${uuid}?showRefs=${depth}`;
        else {
          urlForFetching = `/api/v1/catalogs/${catalogOrigin}/items/search/extended?showRefs=${depth}`;
          method = 'post';
        }
      }

      try {
        const data = await fetchFunc(
          {
            url: urlForFetching,
            method,
            data: method === 'post' && {
              [valueField]: uuid,
            },
          },
          onError,
          referenceStore || 'mdm'
        );

        if (data) {
          this.setState({
            options: [...this.state.options, method === 'post' ? data.content[0] || [] : data],
            fetching: false,
            data,
          });
        }
      } catch (error) {
        this.setState({
          fetching: false,
        });
      }
    }
  };

  getOptions = async (searchValue = '') => {
    const {
      referenceStore,
      url,
      method,
      displayedField,
      valueSearchName,
      defaultSize,
      extraSearchParams,
      getFieldValue,
      relatedParams,
      valueField = 'uuid',
      fieldsForSearch,
      defaultFilter,
      refScript,
      keyForOptions,
    } = this.props.feachoptions;
    const {value, detailMode, selectedUuid, resetOptions, mode, filterMode} = this.props;
    // if (!this.state.nextFetch) return;
    if (isNull(this.pageNumberRef?.current)) this.pageNumberRef = {...(this.pageNumberRef || {}), current: 0};
    if (selectedUuid) this.pageNumberRef = {...(this.pageNumberRef || {}), current: 0};

    const {depth} = this.state;
    const size = defaultSize;
    const page = this.pageNumberRef?.current || 0;

    const valueString = searchValue
      ? method === 'get'
        ? '&' + valueSearchName + '=' + searchValue.toString()
        : ''
      : '';
    const urlForFetching = `${url}?size=${size}${extraSearchParams || valueString || ''}&showRefs=${depth}&page=${page}`;
    let data: RecordType = {};
    if (method === 'post') {
      if (fieldsForSearch || refScript) {
        data = {
          filter: {},
        };
        let fieldsStore: string[] = [];
        if (refScript) {
          if (isString(refScript)) {
            try {
              fieldsStore = JSON.parse(refScript).names;
            } catch (e) {
              errorModalCreate(`Ошибка JSON - ${refScript}`);
            }
          } else {
            fieldsStore = refScript.names;
          }
        } else fieldsStore = fieldsForSearch || [];
        fieldsStore.forEach((field) => {
          if (searchValue) {
            data.filter[field] = searchValue;
          }
        });
        if (searchValue) {
          data.filter['%context_filter'] = searchValue;
        }
      } else if (Array.isArray(displayedField)) {
        if (detailMode) {
          data = {filter: {}};
          displayedField.forEach((item) => {
            data.filter[item] = searchValue;
          });
        } else {
          if (searchValue) {
            data = {
              filter: {
                [Array.isArray(valueSearchName) ? valueSearchName[0] : valueSearchName]: searchValue,
                ['%context_filter']: searchValue,
              },
            };
          }
        }
      } else {
        if (detailMode === 'create') {
          data = {
            fetchFields: [displayedField],
          };
        } else if (searchValue) {
          data = {
            filter: {
              or: [
                {['%context_filter']: searchValue},
                {
                  [displayedField]: searchValue,
                },
              ],
            },
          };
        }
      }
    }
    let filter = null;
    if (defaultFilter) filter = {...defaultFilter};
    if (relatedParams && getFieldValue) {
      const filterParam = relatedParams[2] ? `${relatedParams[0]}.${relatedParams[1]}` : relatedParams.join('.');
      filter = {[filterParam]: getFieldValue(relatedParams[2] || relatedParams[0])};
    }
    if (_.isPlainObject(filter)) _.assign(data, filter);
    try {
      const fetchData = await fetchFunc(
        {
          method,
          url: urlForFetching,
          data,
        },
        null,
        referenceStore || 'mdm'
      );
      if (fetchData) {
        this.setState(
          {
            options:
              isEmpty(this.state.options) || isEmpty(keyForOptions)
                ? [...fetchData.content]
                : [...this.state.options, ...fetchData.content],
            fetching: false,
            // nextFetch: fetchData.totalPages > page,
          },
          () => {
            if (!resetOptions && this.pageNumberRef?.current) {
              this.pageNumberRef = {
                ...this.pageNumberRef,
                current: this.pageNumberRef.current + 1,
              };
            }

            if (!relatedParams && filterMode !== true) {
              if (value && !searchValue) {
                if (Array.isArray(value)) {
                  const notFetchedData = value.filter(
                    (val) => !fetchData.content.some((item: RecordType) => item[valueField] === val)
                  );
                  if (notFetchedData && notFetchedData.length) {
                    notFetchedData.forEach((item) => this.getInComeOption(item));
                  }
                } else {
                  if (!fetchData.content.some((item: RecordType) => item[valueField] === value)) {
                    this.getInComeOption(selectedUuid ? selectedUuid : value, searchValue);
                  }
                }
              }
            }
          }
        );
      }
    } catch (error: any) {
      this.setState(
        {
          fetching: false,
        },
        () => errorModalCreate(error.message)
      );
    }
  };

  getValForDisplayedField = (store: RecordType, displayedField: string) => {
    if (displayedField && displayedField.indexOf('.') !== -1) {
      const displayPath = displayedField.split('.');
      const value = _.get(store, displayPath);
      return _.isString(value) ? value : null;
    } else {
      return store[displayedField];
    }
  };

  prepareOptions(arr: any[], displayedField: any): SelectProps['options'] {
    const {
      displayedFieldSeparator = ' ',
      feachoptions: {referenceCatalogue, valueField, refScript},
      onDataFilter,
      noVisibleOptions,
    } = this.props;
    const tmpArray: string[] = [];

    function itemCheck(item: string) {
      if (tmpArray.indexOf(item[displayedField]) === -1) {
        tmpArray.push(item[displayedField]);
        return true;
      }
      return false;
    }
    const uniqueArr = arr.filter((item) => itemCheck(item));
    const filterData = onDataFilter ? onDataFilter(uniqueArr) : uniqueArr;
    const parsedOptions = filterData.map((item, index) => {
      if (noVisibleOptions && noVisibleOptions?.length > 0) {
        const filedId = item?.fieldType?.id;

        // eslint-disable-next-line
        // @ts-ignore: Unreachable code error
        const checkNoVisibleOption: boolean = noVisibleOptions.includes(filedId);
        if (checkNoVisibleOption) {
          return null;
        }
      }
      if (!item[displayedField]) return null;
      const displayedFieldsArray =
        _.isArray(displayedField) && displayedField.map((fieldName) => _.get(item, fieldName));
      const value = valueField ? item[valueField] : referenceCatalogue === 'subsystem' ? item.id : item.uuid;

      return {
        value,
        key: index,
        label: refScript
          ? createReferenceScriptText(item, refScript)
          : displayedFieldsArray
            ? displayedFieldsArray.join(displayedFieldSeparator)
            : _.isObject(item[displayedField])
              ? 'Не верный формат поля для caption'
              : this.getValForDisplayedField(item, displayedField) || 'Значение не заполнено',
        fieldCaption: valueField ? item[valueField] : undefined,
        fieldOrigin: item.origin,
        uuidValue: item.uuid,
        referenceCatalogPatternId: item.id,
        referenceOrigin: item.origin,
      };
    });
    const filteredOptions = parsedOptions.filter((option) => option !== null) as SelectProps['options'];
    return filteredOptions;
  }

  onSearchSelectValues = (searchValue = '') => {
    this.setState(
      {
        fetching: !isEmpty(searchValue) && true,
      },
      () => {
        this.getOptions(searchValue);
      }
    );
  };

  onPopupScroll = (event: any) => {
    const {clientHeight, scrollTop, scrollHeight} = event.target;
    const {selectedUuid} = this.props;
    const scroll = Math.ceil(clientHeight + scrollTop);
    if (scroll >= scrollHeight - 2 && !selectedUuid) {
      this.getOptions();
    }
  };

  render() {
    const {
      notFoundContent,
      handleSelectChange,
      resetOptions = false,
      filterOption = false,
      mode,
      filterMode = false,
      fullResponseValue,
      selectedUuid,
      defaultValue,
      detailMode,
      displayedFieldSeparator,
      valueSearchName,
      ...props
    } = this.props;
    const {displayedField, timeout: timeoutValue} = this.props.feachoptions;
    const timeout = typeof timeoutValue !== 'undefined' ? timeoutValue : 1000;
    const {options, fetching} = this.state;

    return (
      <Select
        {...props}
        defaultValue={defaultValue}
        mode={mode}
        // selectedUuid={selectedUuid}
        // resetOptions={resetOptions}
        // showArrow={true}
        showSearch={true}
        // filterMode={filterMode}
        filterOption={filterOption}
        notFoundContent={fetching ? <Spin size="small" /> : notFoundContent}
        loading={fetching}
        onSearch={_.debounce((value) => this.onSearchSelectValues(value), timeout)}
        onPopupScroll={this.onPopupScroll}
        onChange={(value, option) => {
          if (!fullResponseValue) {
            const itemData = options.find((item) => item.caption === value);
            handleSelectChange(value, option, itemData);
          } else {
            handleSelectChange(value, option);
          }
        }}
        onDropdownVisibleChange={(open) => {
          if (open) this.onSearchSelectValues();
        }}
        options={this.prepareOptions(options, displayedField)}
      />
    );
  }
}

export default DynamicSelect;
