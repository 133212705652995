import React from 'react';
import _ from 'lodash';
import {getReferenceInfo} from '../../../components/Helpers/Extractors';
import {clearFieldsHandler} from './formHandlers';
import TableCard from '../../../components/TableCard';

export const orgIpOptions = [
  {
    origin: 'ogrn',
    options: {
      rules: {
        validation: [
          {
            rule: {or: {recType: 'comm_org'}},
            pattern: /\b\d{13}\b/,
          },
          {
            rule: {or: {recType: 'individual'}},
            pattern: /\b\d{15}\b/,
          },
        ],
      },
    },
  },
  {
    origin: 'inn',
    options: {
      rules: {
        validation: {
          rule: {or: {recType: 'individual'}},
          pattern: /\b\d{12}\b/,
        },
      },
    },
  },
  {
    origin: 'kpp',
    options: {
      rules: {
        validation: {
          rule: {or: {recType: 'individual'}},
          pattern: /\b\d{9}\b/,
        },
      },
    },
  },
  {
    origin: 'blockReason',
    options: {
      rules: {
        visible: {
          rule: {or: {relationStatus: 'blocked'}},
        },
      },
    },
  },
  {
    origin: 'terminationReason',
    options: {
      rules: {
        visible: {
          rule: {or: {activityStopped: true}},
        },
      },
    },
  },
  {
    origin: 'terminationDate',
    options: {
      rules: {
        visible: {
          rule: {or: {activityStopped: true}},
        },
      },
    },
  },
  {
    origin: 'mspStayPeriod',
    options: {
      gui_editable: false,
      rules: {
        visible: {
          rule: {or: {inMspRegistry: true}},
        },
      },
    },
  },
  {
    origin: 'okpo',
    options: {
      rules: {
        validation: {
          rule: {or: {recType: 'individual'}},
          pattern: /\b\d{8}\b/,
        },
      },
    },
  },
  {
    origin: 'okveds',
    options: {
      additionalColumns: [
        {
          dataIndex: ['okved', 'caption'],
          key: 'okvedCaption',
          sorter: false,
          title: 'Наименование',
        },
      ],
    },
  },
  {
    origin: 'tnveds',
    options: {
      additionalColumns: [
        {
          dataIndex: ['tnved', 'code'],
          key: 'tnvedCode',
          sorter: false,
          title: 'Код',
        },
      ],
    },
  },
  {
    origin: 'corrScore',
    options: {
      rules: {
        visible: {
          rule: {or: {nonResidentRf: false}},
        },
      },
    },
  },
  {
    origin: 'bic',
    options: {
      customView: [
        {
          label: 'Код',
          pattern: 'bic',
        },
        {
          label: 'Код региона',
          pattern: 'locationCode',
        },
        {
          label: 'Регистрационный номер',
          pattern: 'registrationNumber',
        },
      ],
      rules: {
        visible: {
          rule: {or: {nonResidentRf: false}},
        },
      },
    },
  },
  {
    origin: 'iban',
    options: {
      rules: {
        visible: {
          rule: {or: {nonResidentRf: true}},
        },
      },
    },
  },
  {
    origin: 'genLicDate',
    options: {
      rules: {
        visible: {
          rule: {or: {nonResidentRf: false}},
        },
      },
    },
  },
  {
    origin: 'clientManager',
    options: {
      fieldsForSearch: ['firstName', 'lastName', 'middleName'],
      referenceCatalogPattern: ['lastName', 'firstName', 'middleName'],
    },
  },
  {
    origin: 'nonResidentRf',
    options: {
      onChangeHandler: clearFieldsHandler,
    },
  },
  {
    origin: 'recType',
    options: {
      onChangeHandler: clearFieldsHandler,
    },
  },
  {
    origin: 'bank',
    options: {
      onChangeHandler: clearFieldsHandler,
    },
  },
];

export const orgOptions = [
  {
    origin: 'ogrn',
    options: {
      rules: {
        validation: [
          {
            rule: {or: {recType: 'comm_org'}},
            pattern: /\b\d{13}\b/,
          },
          {
            rule: {or: {recType: 'individual'}},
            pattern: /\b\d{15}\b/,
          },
        ],
        // location: {
        //     tab: 'generalInformation',
        //     block: 'statuses',
        //     line: 2,
        //     greed: 6,
        //     order: 2
        // }
      },
    },
  },
  {
    origin: 'inn',
    options: {
      rules: {
        validation: {
          rule: {or: {recType: 'comm_org'}},
          pattern: /\b\d{10}\b/,
        },
      },
    },
  },
  {
    origin: 'kpp',
    options: {
      rules: {
        validation: {
          rule: {or: {recType: 'comm_org'}},
          pattern: /\b\d{9}\b/,
        },
      },
    },
  },
  {
    origin: 'orgform',
    options: {
      rules: {
        value: {
          rule: {or: {okopf: 'no_empty'}},
          value: async (getFieldValue, setFieldsValue) => {
            const responseData = await getReferenceInfo({
              apiUrl: '/api/v1/catalogs/okopf/items',
              refUuid: getFieldValue('okopf'),
            });
            setFieldsValue({orgform: responseData['caption']});
          },
        },
      },
    },
  },
  {
    origin: 'blockReason',
    options: {
      rules: {
        visible: {
          rule: {or: {relationStatus: 'blocked'}},
        },
      },
    },
  },
  {
    origin: 'terminationReason',
    options: {
      rules: {
        visible: {
          rule: {or: {activityStopped: true}},
        },
      },
    },
  },
  {
    origin: 'terminationDate',
    options: {
      rules: {
        visible: {
          rule: {or: {activityStopped: true}},
        },
      },
    },
  },
  {
    origin: 'mspStayPeriod',
    options: {
      gui_editable: false,
      rules: {
        visible: {
          rule: {or: {inMspRegistry: true}},
        },
      },
    },
  },
  {
    origin: 'okpo',
    options: {
      rules: {
        validation: {
          rule: {or: {recType: 'comm_org'}},
          pattern: /\b\d{8}\b/,
        },
      },
    },
  },
  {
    origin: 'okveds',
    options: {
      additionalColumns: [
        {
          dataIndex: ['okved', 'caption'],
          key: 'okvedCaption',
          sorter: false,
          title: 'Наименование',
        },
      ],
    },
  },
  {
    origin: 'tnveds',
    options: {
      additionalColumns: [
        {
          dataIndex: ['tnved', 'code'],
          key: 'tnvedCode',
          sorter: false,
          title: 'Код',
        },
      ],
    },
  },
  {
    origin: 'corrScore',
    options: {
      rules: {
        visible: {
          rule: {or: {nonResidentRf: false}},
        },
      },
    },
  },
  {
    origin: 'bic',
    options: {
      customView: [
        {
          label: 'Код',
          pattern: 'bic',
        },
        {
          label: 'Код региона',
          pattern: 'locationCode',
        },
        {
          label: 'Регистрационный номер',
          pattern: 'registrationNumber',
        },
      ],
      rules: {
        visible: {
          rule: {or: {nonResidentRf: false}},
        },
      },
    },
  },
  {
    origin: 'iban',
    options: {
      rules: {
        visible: {
          rule: {or: {nonResidentRf: true}},
        },
      },
    },
  },
  {
    origin: 'genLicDate',
    options: {
      rules: {
        visible: {
          rule: {or: {nonResidentRf: false}},
        },
      },
    },
  },
  {
    origin: 'clientManager',
    options: {
      fieldsForSearch: ['firstName', 'lastName', 'middleName'],
      referenceCatalogPattern: ['lastName', 'firstName', 'middleName'],
    },
  },
  {
    origin: 'nonResidentRf',
    options: {
      onChangeHandler: clearFieldsHandler,
    },
  },
  {
    origin: 'recType',
    options: {
      onChangeHandler: clearFieldsHandler,
    },
  },
  {
    origin: 'bank',
    options: {
      onChangeHandler: clearFieldsHandler,
    },
  },
];

export const orgAdditionalFields = [
  {
    origin: 'extAddressesLegal',
    fieldType: {id: 117},
    caption: 'Юридический адрес (адрес регистрации)',
    options: {
      extractor: {
        paramVal: 'legal',
        paramName: 'extAddressType',
        storeName: 'extAddresses',
      },
      rules: {
        visible: {
          rule: {or: {supranational: [false, null]}},
        },
      },
      convertForDisplay: (values) => {
        const fields = [
          'extIndex',
          ['extCountry', 'caption'],
          'extDistrict',
          'extCity',
          'extCommunity',
          'extStreet',
          'extHouse',
          'extBuilding',
          'extOffice',
        ];
        let resultString = '';
        const getItemData = (record, path) => {
          let resString = '';
          if (_.isArray(path)) resString = _.has(record, [path[0], path[1]]) && record[path[0]][path[1]];
          else resString = _.has(record, path) && record[path];
          return resString ? `${resString};` : '';
        };
        fields.forEach((item) => {
          resultString = resultString + `${getItemData(values, item)}`;
        });
        return resultString.slice(0, -1);
      },
    },
  },
  {
    origin: 'extAddressesPost',
    fieldType: {id: 117},
    caption: 'Почтовый адрес (адрес доставки корреспонденции)',
    options: {
      extractor: {
        paramVal: 'post',
        paramName: 'extAddressType',
        storeName: 'extAddresses',
      },
      rules: {
        visible: {
          rule: {or: {supranational: [false, null]}},
        },
      },
      convertForDisplay: (values) => {
        const fields = [
          'extIndex',
          ['extCountry', 'caption'],
          'extDistrict',
          'extCity',
          'extCommunity',
          'extStreet',
          'extHouse',
          'extBuilding',
          'extOffice',
        ];
        let resultString = '';
        const getItemData = (record, path) => {
          let resString = '';
          if (_.isArray(path)) resString = _.has(record, [path[0], path[1]]) && record[path[0]][path[1]];
          else resString = _.has(record, path) && record[path];
          return resString ? `${resString};` : '';
        };
        fields.forEach((item) => {
          resultString = resultString + `${getItemData(values, item)}`;
        });
        return resultString.slice(0, -1);
      },
      gui_editable: false,
    },
  },
  {
    origin: 'extAddressesFact',
    fieldType: {id: 117},
    caption: 'Адрес местонахождения (фактический адрес)',
    options: {
      extractor: {
        paramVal: 'fact',
        paramName: 'extAddressType',
        storeName: 'extAddresses',
      },
      convertForDisplay: (values) => {
        const fields = [
          'extIndex',
          ['extCountry', 'caption'],
          'extDistrict',
          'extCity',
          'extCommunity',
          'extStreet',
          'extHouse',
          'extBuilding',
          'extOffice',
        ];
        let resultString = '';
        const getItemData = (record, path) => {
          let resString = '';
          if (_.isArray(path)) resString = _.has(record, [path[0], path[1]]) && record[path[0]][path[1]];
          else resString = _.has(record, path) && record[path];
          return resString ? `${resString};` : '';
        };
        fields.forEach((item) => {
          resultString = resultString + `${getItemData(values, item)}`;
        });
        return resultString.slice(0, -1);
      },
      gui_editable: false,
    },
  },
  {
    origin: 'contactPersonsBoss',
    fieldType: {id: 117},
    caption: 'Руководитель организации',
    options: {
      extractor: {
        paramVal: true,
        paramName: 'director',
        storeName: 'contactPersons',
      },
      formMeta: [
        {
          origin: 'lastName',
          fieldType: {id: 1},
          caption: 'Фамилия',
          required: true,
          options: {
            gui_editable: true,
          },
        },
        {
          origin: 'firstName',
          fieldType: {id: 1},
          caption: 'Имя',
          required: true,
          options: {
            gui_editable: true,
          },
        },
        {
          origin: 'patronymic',
          fieldType: {id: 1},
          caption: 'Отчество',
          options: {
            gui_editable: true,
          },
        },
        {
          origin: 'position',
          fieldType: {id: 1},
          caption: 'Должность',
          options: {
            gui_editable: true,
          },
        },
        {
          origin: 'email',
          fieldType: {id: 1},
          caption: 'E-mail',
          options: {
            gui_editable: true,
          },
        },
        {
          origin: 'phone',
          fieldType: {id: 1},
          caption: 'Телефон',
          options: {
            gui_editable: true,
          },
        },
        {
          origin: 'altContacts',
          fieldType: {id: 11},
          caption: 'Прочие контактные данные',
          options: {
            gui_editable: true,
          },
        },
        {
          origin: 'comment',
          fieldType: {id: 11},
          caption: 'Комментарий',
          options: {
            gui_editable: true,
          },
        },
      ],
      convertForDisplay: (values) => {
        const getValue = (name) => {
          let resultValue = '';
          if (_.has(values, name)) resultValue = values[name];
          return resultValue || '';
        };
        return `${getValue('lastName')} ${getValue('firstName')} ${getValue('patronymic')} 
                ${getValue('position') ? `должность: ${getValue('position')}` : ''} ${getValue('phone') ? `телефон: ${getValue('phone')}` : ''}`;
      },
      gui_editable: false,
    },
  },
  {
    origin: 'contactPersonsMain',
    fieldType: {id: 117},
    caption: 'Основное контактное лицо',
    options: {
      extractor: {
        paramVal: true,
        paramName: 'mainContact',
        storeName: 'contactPersons',
      },
      formMeta: [
        {
          origin: 'lastName',
          fieldType: {id: 1},
          caption: 'Фамилия',
          required: true,
          options: {
            gui_editable: true,
          },
        },
        {
          origin: 'firstName',
          fieldType: {id: 1},
          caption: 'Имя',
          required: true,
          options: {
            gui_editable: true,
          },
        },
        {
          origin: 'patronymic',
          fieldType: {id: 1},
          caption: 'Отчество',
          options: {
            gui_editable: true,
          },
        },
        {
          origin: 'position',
          fieldType: {id: 1},
          caption: 'Должность',
          options: {
            gui_editable: true,
          },
        },
        {
          origin: 'email',
          fieldType: {id: 1},
          caption: 'E-mail',
          options: {
            gui_editable: true,
          },
        },
        {
          origin: 'phone',
          fieldType: {id: 1},
          caption: 'Телефон',
          options: {
            gui_editable: true,
          },
        },
        {
          origin: 'altContacts',
          fieldType: {id: 11},
          caption: 'Прочие контактные данные',
          options: {
            gui_editable: true,
          },
        },
        {
          origin: 'comment',
          fieldType: {id: 11},
          caption: 'Комментарий',
          options: {
            gui_editable: true,
          },
        },
      ],
      convertForDisplay: (values) => {
        const getValue = (name) => {
          let resultValue = '';
          if (_.has(values, name)) resultValue = values[name];
          return resultValue || '';
        };
        return `${getValue('lastName')} ${getValue('firstName')} ${getValue('patronymic')} 
                ${getValue('position') ? `должность: ${getValue('position')}` : ''} ${getValue('phone') ? `телефон: ${getValue('phone')}` : ''}`;
      },
      gui_editable: false,
    },
  },
  {
    origin: 'linked_profiles',
    fieldType: {
      id: 'customField',
    },
    options: {
      render: (_, record = {}) => {
        const uuid = typeof record === 'object' ? record.uuid : record;
        const config = {
          store: 'user_profile',
          filter: {
            orgId: uuid,
          },
        };
        const columns = [
          {
            title: 'ФИО',
            key: 'fio',
            render: ({lastName = '', firstName = '', middleName = '', uuid}) => {
              const fio = [lastName, firstName, middleName].filter((str) => str).join(' ');
              const baseUrl = window._env.REACT_APP_GOTO_URL;
              return <a href={`${baseUrl}/catalog/user_profile/${uuid}`}>{fio}</a>;
            },
          },
          {
            title: 'Должность',
            dataIndex: 'position',
            key: 'position',
          },
          {
            title: 'Login',
            dataIndex: 'username',
            key: 'username',
          },
        ];
        return <TableCard config={config} columns={columns} />;
      },
    },
  },
];
