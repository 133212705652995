import React, {useMemo, useState} from 'react';
import {useHistory} from 'react-router';
import {unstable_batchedUpdates} from 'react-dom';
import {Button, Form, message, Modal, Upload, Table, Select} from 'ui-kit';
import {CloseOutlined, UploadOutlined} from '@ant-design/icons';
import {isEmpty, isArray} from 'lodash';
import {getFileExt, parseDocument} from '../../Utils/fileUtils';
import {fetchFunc} from '../../Utils/security/http/mdm';
import {catalogNames, tableColumns, uploadFileModal} from './constants';
import {layout} from '../Helpers/constants';

import style from './UploadFileModal.module.scss';

const {Option} = Select;

/**
 * Форма загрузки файла
 * @param catalogColumns - данные справочника
 * @param isModalOpen - состояние модального окна
 * @param handleCancel -функция отмены импорта
 * @param onImport- функция  импорта
 * @param dictName -Название справочника
 * @param referenceColumns -Информация о справочнике
 */
export const UploadFileModal = ({
  catalogColumns,
  isModalOpen,
  handleCancel = () => {},
  onImport = () => {},
  dictName,
  referenceColumns,
}) => {
  /** Маршрутизатор */
  const history = useHistory();
  /** Uuid загружаемого файла */
  const [fileUuid, setFileUuid] = useState(null);
  /** Получаем документ */
  const [parsedDocument, setParsedDocument] = useState(null);
  /** Получаем  путь документа */
  const [parsedDocumentPath, setParsedDocumentPath] = useState(null);
  /** Формат загружаемого файла */
  const [uploadFileExt, setUploadFileExt] = useState(null);
  /** Получаем origin аттрибута при переключении */
  const [attrLink, setAttrLink] = useState({});
  /** Данные файла */
  const [fileList, setFileList] = useState([]);
  /** Получаем данные с формы */
  const [form] = Form.useForm();
  /** Очистка состояния загрузки */
  const resetFileState = () => {
    unstable_batchedUpdates(() => {
      setFileUuid(null);
      setParsedDocument(null);
      setUploadFileExt(null);
      setAttrLink({});
      setFileList([]);
    });
  };

  const onErrorFile = (onError) => {
    unstable_batchedUpdates(() => {
      setFileUuid(null);
      setParsedDocument(null);
      setUploadFileExt(null);
      setAttrLink({});
    });
    onError && onError();
  };

  /** Пропс для загрузки */
  const props = {
    name: 'file',
    accept: '.csv,.xlsx,.xml,.json, .xls',
    multiple: false,
    maxCount: 1,
    onRemove: resetFileState,
    customRequest: async ({file, onSuccess, onError}) => {
      const fileExt = getFileExt(file.name);

      const onEmptyFile = () => onErrorFile(() => onError({message: 'Импортируемый файл не должен быть пустым'}));

      if (file.size === 0) {
        return onEmptyFile();
      }

      try {
        const parseResult = await parseDocument(file, fileExt);

        if (isEmpty(parseResult.data)) {
          return onEmptyFile();
        }
        if (!isArray(parseResult.data)) {
          return onErrorFile(() => onError({message: 'Импортируемый файл должен содержать массив записей'}));
        }

        const formData = new FormData();
        formData.append('file', file);

        const uploadResponse = await fetchFunc({
          url: '/api/v1/catalogs/import/docstore',
          method: 'POST',
          data: formData,
        });

        unstable_batchedUpdates(() => {
          setFileUuid(uploadResponse.file_uuid);
          setParsedDocument(parseResult.data);
          setParsedDocumentPath(parseResult.path);
          setUploadFileExt(fileExt);
        });
        onSuccess();
      } catch (error) {
        resetFileState();
        onErrorFile(() => onError(error));
      }
    },

    onChange: async (info) => {
      if (info.file.status === 'done') {
        message.success(`Файл ${info.file.name} успешно загружен`);
      }
      if (info.file.status === 'error') {
        message.error(`Произошла ошибка при загрузке файла ${info.file.name}.`);
      }
    },
  };

  const okButtonProps = {
    disabled: Array.isArray(fileList) && !isEmpty(parsedDocument) ? !fileList.length : true,
  };

  /** Аттрибуты справочника */
  const columnNames = useMemo(() => {
    return parsedDocument ? Object.keys(parsedDocument[0]) : [];
  }, [parsedDocument]);

  /** Функция подьверждения загрузки файла */
  const handleSubmit = () => {
    const references = [];
    const fieldMapping = columnNames.reduce((acc, colName) => {
      const catalogColumnName = catalogColumns.find((item) => item.title === colName)?.title;
      const catalogColumnOriginName = catalogColumns.find((item) => item.title === colName && item.key)?.key;
      if (attrLink[colName]) {
        acc[colName] = attrLink[colName];
        return acc;
      }
      referenceColumns.forEach((item) => {
        if (catalogColumnName === item.origin) {
          references.push({
            refCatalog: item.referenceCatalog.origin,
            refField: colName,
            srcField: catalogColumnName,
            filters: [],
          });
        }
      });

      if (catalogColumnName) {
        acc[colName] = catalogColumnOriginName;
      }
      return acc;
    }, {});

    const hasBic = Object.keys(fieldMapping).find((key) => fieldMapping[key] === catalogNames.BIC);

    if (dictName === catalogNames.BIC && !hasBic) {
      message.error('Отсутствует обязательный атрибут БИК.');
      return;
    }

    const data = {
      fileUUID: fileUuid,
      fileType: uploadFileExt,
      transformConfig: {
        ...(parsedDocumentPath ? {path: parsedDocumentPath} : {}),
        dictName,
        fieldMapping,
        references,
      },
    };
    onImport(data);
    resetFileState();
    history.push(`/catalog`);
  };

  const handleCloseModal = () => {
    resetFileState();
    handleCancel();
  };
  /** Функция выбора аттрибута в справочнике */
  const handleAttrLinkChange = (colName, link) => {
    setAttrLink((prev) => ({...prev, [colName]: link}));
  };
  /** Функция для проверки наличия атрибута и render его */
  const dataSource = useMemo(() => {
    if (!parsedDocument) return [];
    return columnNames.map((columnName, index) => {
      const catalogColumnName = catalogColumns.find((item) => item.title === columnName)?.title;
      return {
        key: index,
        sourceColumnName: columnName,
        isKey: true,
        catalogColumnName:
          columnName !== catalogColumnName ? (
            <>
              <p>
                {`${uploadFileModal.notFound}`} <span className={style.uploadAttr}>{`${columnName}.`}</span>
                {`${uploadFileModal.chooseAttribute}`}
              </p>
              <Select
                className="w-100"
                onChange={(value) => handleAttrLinkChange(columnName, value)}
                placeholder={uploadFileModal.selectPlaceholder}
              >
                {catalogColumns.map((item, key) => (
                  <Option key={key} value={item.dataIndex}>
                    {item.title}
                  </Option>
                ))}
              </Select>
            </>
          ) : (
            <div>{columnName}</div>
          ),
      };
    });
  }, [catalogColumns, columnNames, parsedDocument]);

  const footerModal = useMemo(
    () => (
      <>
        <Button icon={<CloseOutlined />} onClick={handleCloseModal}>
          {uploadFileModal.cancel}
        </Button>
        <Button icon={<UploadOutlined />} {...okButtonProps} type="primary" onClick={form.submit}>
          {uploadFileModal.imported}
        </Button>
      </>
    ),
    [okButtonProps]
  );

  return (
    <Modal
      title={uploadFileModal.fileImport}
      open={isModalOpen}
      className={style.modal}
      width={760}
      onCancel={handleCloseModal}
      footer={footerModal}
    >
      <Form {...layout} form={form} width={1000} onFinish={handleSubmit} type="submit" layout="vertical">
        <Form.Item name="file" label={uploadFileModal.file} required>
          <Upload {...props} name="file" type="file" fileList={fileList} onChange={(e) => setFileList(e.fileList)}>
            <Button icon={<UploadOutlined />}>{uploadFileModal.upload}</Button>
          </Upload>
        </Form.Item>
        {parsedDocument ? (
          <Table dataSource={dataSource} columns={tableColumns} pagination={false} width={500} />
        ) : null}
      </Form>
    </Modal>
  );
};
