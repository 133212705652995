import {useState} from 'react';
import {EditOutlined, DeleteOutlined, PlusOutlined} from '@ant-design/icons';

import {Modal, Table, Button} from 'ui-kit';
import AddPriorityForm from './components/AddPriorityForm/AddPriorityForm';
import {mdm} from '../../Utils/constant';
import style from './Style.module.scss';

const PriorityModal = ({
  modalVisible,
  onOk,
  onCancel,
  data,
  totalElements,
  handleTableChange,
  deletePriority,
  createPriority,
}) => {
  const [addModalVisible, setAddModalVisible] = useState(false);
  const [editableItem, setEditableItem] = useState(false);

  const columns = [
    {
      title: 'Подсистема',
      dataIndex: ['subsystem', 'caption'],
      key: 'subsystem',
      width: '25%',
      render: (text) => {
        if (text) {
          return text;
        } else {
          return 'По умолчанию';
        }
      },
    },
    {
      title: 'Идентификатор подсистемы',
      dataIndex: ['subsystem', 'id'],
      key: 'subsystemId',
      width: '25%',
    },
    {
      title: 'Внешняя система',
      dataIndex: ['subsystem', 'externalSystem', 'caption'],
      key: 'externalSystem',
      width: '25%',
    },
    {
      title: 'Приоритет',
      dataIndex: 'priority',
      key: 'priority',
      width: '25%',
    },
    {
      key: 'edit',
      width: '30px',
      render: (_, record) => <EditOutlined className={style.icon} onClick={() => openModal(record)} />,
    },
    {
      key: 'delete',
      width: '30px',
      render: (_, record) => <DeleteOutlined className={style.icon} onClick={() => deletePriority(record.id)} />,
    },
  ];

  const openModal = (record) => {
    setAddModalVisible(true);
    setEditableItem(record);
  };

  const closeModal = () => {
    setAddModalVisible(false);
  };

  return (
    <Modal
      open={modalVisible}
      onCancel={onCancel}
      onOk={onOk}
      width={760}
      title={'Добавление подсистемы'}
      footer={null}
      destroyOnClose={true}
    >
      <div className="d-flex mb-3">
        <Button type="primary" icon={<PlusOutlined />} onClick={() => openModal()}>
          {mdm.add}
        </Button>
      </div>
      <Table
        dataSource={data}
        columns={columns}
        onChange={handleTableChange}
        pagination={{
          total: totalElements,
        }}
      />
      <Modal title="Внешние источники" open={addModalVisible} onCancel={closeModal} footer={null} destroyOnClose={true}>
        <AddPriorityForm
          editableItem={editableItem}
          onSubmit={(values) => createPriority(values, editableItem)}
          closeModal={closeModal}
        />
      </Modal>
    </Modal>
  );
};

export default PriorityModal;
