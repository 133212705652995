import React from 'react';
import {Form, Input, Upload, Button} from 'ui-kit';
import {UploadOutlined} from '@ant-design/icons';

const requiredRule = [{required: true, message: 'Поле обязательно для заполнения'}];

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

const formTailLayout = {
  labelCol: {span: 6},
  wrapperCol: {span: 18, offset: 6},
};

const TemplateForm = ({onSubmit, onCancel, editibleItem}) => {
  const initialValues = {
    file: [],
  };
  if (editibleItem && editibleItem.caption) {
    initialValues.caption = editibleItem.caption;
  }

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  return (
    <Form name="add_template_form" onFinish={onSubmit} {...layout} initialValues={initialValues}>
      <Form.Item name="caption" label="Наименование" rules={requiredRule}>
        <Input />
      </Form.Item>
      <Form.Item name="file" label="Файл" rules={requiredRule}>
        <Upload
          beforeUpload={() => {
            return false;
          }}
          getValueFromEvent={normFile}
        >
          <Button>
            <UploadOutlined /> Загрузить
          </Button>
        </Upload>
      </Form.Item>
      <Form.Item {...formTailLayout} className="text-right">
        <Button className="mr-3" type="primary" htmlType="submit">
          Сохранить
        </Button>
      </Form.Item>
    </Form>
  );
};

export default TemplateForm;
