import React, {useEffect, useCallback} from 'react';
import {useSelector} from 'react-redux';
import {Button, Form} from 'ui-kit';
import {isNumber, isString, isObject, isArray, has, isUndefined, isNull} from 'lodash';

import {calculateDate, convertExpoToString, convertToJson} from '../Helpers/Utils';
import {errorModalCreate} from '../Helpers/Modals';
import scrollIntoView from 'scroll-into-view-if-needed';
import {SaveOutlined} from '@ant-design/icons';
import classNames from 'classnames';
import dayjs from 'dayjs';

const FormInitiator = ({
  structure,
  setTabKey,
  children,
  mode,
  changeRecord,
  hiddenFields = [],
  data,
  fieldsMeta,
  formName,
  onCancel,
  fixedSubmitButton,
  submitButtonPosition,
  footer,
  className = '',
  showFooter = true,
  onChangeFormData,
}) => {
  const [form] = Form.useForm();
  const recordLoading = useSelector((state) => state?.loading.recordLoading);

  const prepareValuesBeforeSend = (values) => {
    if (hiddenFields.length) hiddenFields.forEach((item) => (values[item] = ''));
    if (onCancel) onCancel();
    changeRecord(values);
  };

  const onChangeForm = () => {
    if (onChangeFormData) {
      const values = form.getFieldsValue();

      if (hiddenFields.length) hiddenFields.forEach((item) => (values[item] = ''));
      return onChangeFormData(values);
    }
  };

  const changeTabAndScrollToElement = async ({validatedElement, node}) => {
    const tabs = Object.keys(structure);
    let tab = '';
    const findElOnTab = (items, tabName) => {
      items.forEach((item) => {
        if (item.origin === validatedElement) tab = tabName;
        if (item.items && !tab) return findElOnTab(item.items, tabName);
      });
    };

    tabs.map((key) => (!tab ? findElOnTab(structure[key], key) : null));

    if (tab) {
      await setTabKey(tab);

      scrollIntoView(node, {
        behavior: 'smooth',
        scrollMode: 'always',
      });
    } else {
      errorModalCreate('Заполните обязательные поля во всех вкладках');
    }
  };

  const finishFailed = async ({errorFields}) => {
    const validatedElement = errorFields[0].name.pop();
    const isFromWithTabs = Boolean(formName === 'catalog-orgs-form' || formName === 'service-form');
    const getFormElementId = (el) => {
      const id = form.getFieldInstance(el)?.resizableTextArea
        ? form.getFieldInstance(el)?.resizableTextArea?.props?.id
        : form.getFieldInstance(el)?.props?.id;
      return document.getElementById(id);
    };
    const node = getFormElementId(validatedElement);
    if (node)
      isFromWithTabs
        ? changeTabAndScrollToElement({validatedElement, node})
        : scrollIntoView(node, {
            behavior: 'smooth',
            scrollMode: 'always',
          });
  };

  const createInitialValues = useCallback(
    (data) => {
      let newData = {};
      fieldsMeta.forEach(async (item) => {
        const fieldType = item.fieldType && item.fieldType.id;
        let incomeData;
        if (fieldType === 117) {
          const {paramVal, paramName, storeName} = item.options && item.options.extractor;
          const valuesObj = has(data, storeName) && data[storeName].find((item) => item[paramName] === paramVal);
          incomeData = valuesObj && item.options.convertForDisplay(valuesObj);
        } else {
          incomeData = has(data, item.origin) ? data[item.origin] : null;
        }
        const defaultValue = (item.options && item.options.defaultValue) || item.defaultValue || null;
        switch (fieldType) {
          case 21:
            newData[item.origin] = incomeData && convertExpoToString(incomeData);
            break;
          case 4: {
            newData[item.origin] =
              (incomeData && isObject(incomeData) ? incomeData.uuid : incomeData) || defaultValue || '';
            break;
          }
          case 2:
          case 3: {
            const defaultNumValue = !isNaN(parseFloat(defaultValue)) ? parseFloat(defaultValue) : '';
            const isIncomeData = incomeData || incomeData === 0;

            newData[item.origin] = isIncomeData ? incomeData : defaultNumValue;
            break;
          }
          case 7:
          case 8: {
            if (incomeData) {
              newData[item.origin] = dayjs(incomeData);
            } else if (defaultValue) {
              newData[item.origin] = defaultValue === 'now()' ? dayjs() : dayjs(defaultValue);
            } else if (item.options && (item.options.date_duration || item.options.date_duration === 0)) {
              newData[item.origin] = dayjs(calculateDate({duration: item.options.date_duration}));
            } else {
              newData[item.origin] = null;
            }
            break;
          }
          case 10:
            {
              let initValue = false;
              if (!isNull(incomeData)) {
                initValue = incomeData;
              } else if (!isUndefined(defaultValue)) {
                initValue = defaultValue ? defaultValue === 'true' : false;
              }
              newData[item.origin] = initValue;
            }
            break;
          case 12:
            if (item.options && item.options.detailMode === 'select') {
              newData[item.origin] = incomeData && incomeData.map((elem) => elem[item.options.detailCfg.field].uuid);
            }
            break;
          case 117:
            newData[item.origin] = incomeData || null;
            break;
          case 15: {
            let resultValue;
            if (incomeData) {
              if (isString(incomeData)) {
                const parsedData = await convertToJson(incomeData, `Неверный JSON у поля ${item.caption}`);
                if (isArray(parsedData)) resultValue = parsedData.map((item) => (item && item.key) || null);
                else resultValue = parsedData;
              } else {
                resultValue = incomeData;
              }
            } else {
              resultValue = defaultValue
                ? isString(defaultValue)
                  ? await convertToJson(defaultValue, `Неверный JSON defaultValue у поля ${item.caption}`)
                  : defaultValue
                : [];
            }
            newData[item.origin] = resultValue;
            break;
          }
          case 19:
            newData[item.origin] = incomeData ? (!isString(incomeData) ? JSON.stringify(incomeData) : incomeData) : '';
            break;
          case 20:
            newData[item.origin] = incomeData ? (isObject(incomeData) ? incomeData.caption : incomeData) : '';
            break;
          case 22:
          case 23:
          case 24:
          case 25:
            newData[item.origin] = incomeData || defaultValue || [null];
            break;
          default: {
            newData[item.origin] = isNumber(incomeData) ? incomeData : incomeData || defaultValue || '';
          }
        }
      });
      return newData;
    },
    [fieldsMeta]
  );

  useEffect(() => {
    form.setFieldsValue(createInitialValues(data));
  }, [createInitialValues, data, fieldsMeta]);

  return (
    <Form
      form={form}
      name={formName}
      layout="vertical"
      onFinish={prepareValuesBeforeSend}
      onFinishFailed={finishFailed}
      className={classNames(className, 'd-flex flex-column gap-24')}
      onChange={onChangeForm}
    >
      {children(form)}
      {showFooter &&
        (footer ? (
          footer(!fixedSubmitButton && recordLoading)
        ) : (
          <div className="fixed-button">
            <Button
              style={{...submitButtonPosition}}
              icon={<SaveOutlined />}
              type="primary"
              htmlType="submit"
              loading={recordLoading}
            >
              Сохранить
            </Button>
          </div>
        ))}
    </Form>
  );
};

export default FormInitiator;
