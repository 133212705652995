import React, {useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Button, Form, Input, Popover, Select, Typography} from 'ui-kit';
import {isEmpty} from 'lodash';

import {
  addNotification,
  getFilteredSubscription,
  getNotificationTemplateList,
} from '../../../redux/thunk/notificationThunk';
import {notifications} from '../../../components/NotificationPage/helpers/constant';
import {requiredRule} from '../../../components/EditFieldForm/helpers/constants';
import {ReactComponent as ExclamationPoint} from '../../../assets/images/coolicon.svg';

// import './NotificationPageForm.scss';
import {CloseOutlined, SaveOutlined} from '@ant-design/icons';

/**
 * Форма заполнения уведомления
 * @param setNotificationModalVisible - состояние модального окна
 * @param status - ключ для просмотра формы уведомления
 * @param notificationId - информация по уведомлению
 * */
const NotificationPageForm = ({setNotificationModalVisible, status, notificationId}) => {
  /** Данные формы */
  const [form] = Form.useForm();
  /** Отправка данных в хранилище */
  const dispatch = useDispatch();
  /** Используем хранилище*/
  const notificationTemplateList = useSelector((state) => state?.notification.notificationTemplateList);
  const notificationEventList = useSelector((state) => state?.notification.notificationEventList);
  /** Начальное состояние  способа подписки */
  const [eventSubscription, setEventSubscription] = useState([]);
  /** Uuid справочника на который совершена подписка */
  const [catalogIdentifier, setCatalogIdentifier] = useState('');
  /** Uuid шаблона уведомления */
  const [templateUuid, setTemplateUuid] = useState('');
  /** Начальное состояние справочника-подписки */
  const [subscription, setSubscription] = useState('');
  /** Начальное состояние значения  шаблона */
  const [templateCaption, setTemplateCaption] = useState('');
  /** Начальное состояние событий для выбранного шаблона и подписки */
  const [unavailableEvents, setUnavailableEvents] = useState([]);
  /** Данные для отображения информации уведомления */
  const initialValues = {
    name:
      !isEmpty(notificationId) && status === 'viewing'
        ? notificationId.captionNotification
        : form.getFieldValue('name'),
    activeNotification:
      !isEmpty(notificationId) && status === 'viewing'
        ? notificationId.active
        : form.getFieldValue('activeNotification'),
    template:
      !isEmpty(notificationId) && status === 'viewing'
        ? notificationId.nameTemplateNotification
        : form.getFieldValue('template'),
    subscription:
      !isEmpty(notificationId) && status === 'viewing'
        ? notificationId.catalogName
        : form.getFieldValue('subscription'),
    eventSubscription:
      !isEmpty(notificationId) && status === 'viewing'
        ? notificationId.subscriptions?.map((item) => item.eventCaption)
        : form.getFieldValue('eventSubscription'),
  };
  /** Получаем список шаблонов */
  useEffect(() => {
    dispatch(getNotificationTemplateList());
  }, []);
  /**  Загружаем данные для редактирования шаблона */
  useEffect(() => {
    status === 'viewing' && !isEmpty(notificationId) && form.setFieldsValue(initialValues);
  }, [form, notificationId, status]);
  /**  Инициируем данные переключателя */
  useEffect(() => {
    status === 'create' && form.setFieldsValue({activeNotification: true});
  }, [form, status]);
  useEffect(() => {
    !isEmpty(templateUuid) && dispatch(getFilteredSubscription(templateUuid));
  }, [templateUuid]);
  /** Получаем список шаблонов */
  const templates = notificationTemplateList?.map((template) => ({
    value: template.nameNotification,
    key: template.uuid,
  }));
  /** Получаем список подписок */
  const notificationSubscriptionList = notificationEventList?.flatMap((item) => {
    return {
      value: item.catalogName,
      key: item.catalogIdentifier,
    };
  });
  /** Получаем список  событий для выбранной подписки */
  const eventSubscriptionOptions = useMemo(() => {
    const eventsForSelectedSubscription =
      !isEmpty(catalogIdentifier) &&
      notificationEventList?.find((item) => item.catalogIdentifier === catalogIdentifier && item);
    const availableEvents = eventsForSelectedSubscription?.availableEvents?.map((item) => ({
      value: item?.eventCaption,
      key: item.eventCode,
      eventCode: item.eventCode,
    }));
    const unavailableEvents = eventsForSelectedSubscription?.unavailableEvents?.map((item) => ({
      value: item?.eventCaption,
      key: item.eventCode,
      eventCode: item.eventCode,
      disabled: true,
    }));
    setUnavailableEvents(unavailableEvents);
    return availableEvents?.concat(unavailableEvents);
  }, [catalogIdentifier, notificationEventList, templateUuid]);
  /**
   *Сеттер значения подписки
   * @param value - значение подписки
   * @param option - дополнительная информация
   * */
  const handleSubscriptionChange = (value, option) => {
    setSubscription(value);
    setCatalogIdentifier(option.key);
    form.setFieldValue('eventSubscription', []);
  };
  /**
   *Сеттер значения события  подписки
   * @param value - значение подписки
   * @param option - дополнительная информация
   * */
  const handleEventSubscription = (value, option) => {
    setEventSubscription(option);
  };
  /**
   * Функция сеттер названия уведомления
   * @param value - значение подписки
   * */
  const handleTitleNotificationChange = (value) => {
    form.setFieldsValue({name: value});
  };
  /**
   * Функция сеттер шаблона
   * @param value - значение подписки
   * @param option - дополнительная информация
   * */
  const handleTemplateChange = (value, option) => {
    setTemplateCaption(value);
    setTemplateUuid(option.key);
    form.setFieldsValue({subscription: null, activeNotification: true});
    setUnavailableEvents([]);
    setEventSubscription([]);
    setSubscription('');
  };
  /** Формируем объект для payloadData */
  const createCurrentNotification = () => {
    const eventSubscriptionForPayloadData = eventSubscription.map((item) => ({
      eventCaption: item.value,
      eventCode: item.eventCode,
    }));
    return {
      active: true,
      captionNotification: form.getFieldValue('name'),
      catalogIdentifier: catalogIdentifier,
      notificationTemplateUUID: templateUuid,
      subscriptions: eventSubscriptionForPayloadData,
    };
  };

  /** Очистка данных формы */
  const resetHandler = () => {
    form.resetFields();
    setNotificationModalVisible(false);
    setEventSubscription([]);
    setUnavailableEvents([]);
    setTemplateUuid('');
    setTemplateCaption(null);
    setSubscription(null);
  };
  /** Отправка данных формы */
  const submitHandler = () => {
    dispatch(addNotification(createCurrentNotification()));
    form.resetFields();
    setNotificationModalVisible(false);
    setTemplateUuid('');
    setEventSubscription([]);
    setUnavailableEvents([]);
    setTemplateCaption(null);
    setSubscription(null);
  };

  const checkingTemplateSelection = isEmpty(form.getFieldValue('template')) || status === 'viewing';
  const checkingSubscriptionSelection = isEmpty(subscription) || status === 'viewing';

  return (
    <div>
      <Typography.Title level={3}>{notifications.form}</Typography.Title>
      <Form layout={'vertical'} form={form} autoComplete="off" onFinish={submitHandler}>
        <Form.Item label={notifications.title} name="name" rules={requiredRule}>
          <Input
            disabled={status === 'viewing'}
            placeholder={notifications.addTitle}
            onChange={(event) => handleTitleNotificationChange(event.target.value)}
          />
        </Form.Item>
        <Form.Item name="template" label={notifications.template} rules={requiredRule}>
          <Select
            disabled={status === 'viewing'}
            placeholder={notifications.selectTemplate}
            options={templates}
            filterOption={true}
            resetOptions={true}
            showSearch
            onChange={(value, option) => handleTemplateChange(value, option)}
          />
        </Form.Item>
        <Typography.Text className="d-flex mb-2 ">{notifications.noticeTerms}</Typography.Text>
        <Form.Item
          label={notifications.subscription}
          name="subscription"
          id="subscription"
          rules={checkingTemplateSelection ? false : requiredRule}
        >
          <Select
            disabled={checkingTemplateSelection}
            placeholder={notifications.selectSubscription}
            options={notificationSubscriptionList}
            filterOption={true}
            resetOptions={true}
            width={'100%'}
            showSearch
            onChange={(value, option) => handleSubscriptionChange(value, option)}
          />
        </Form.Item>
        {status === 'viewing' && isEmpty(initialValues.subscription) && (
          <div className="notificationForm__block-groups--subscription-box--empty">
            {notifications.emptySubscription}
          </div>
        )}

        <Form.Item
          name="eventSubscription"
          label={
            <div className="d-flex">
              <span>{notifications.eventSubscription}</span>
              {!isEmpty(unavailableEvents) && (
                <Popover
                  content={
                    <div>
                      {notifications.popoverOne}
                      <span className="notificationForm__popover--span">{subscription}</span> {notifications.popoverTwo}
                      <span className="notificationForm__popover--span">{templateCaption}</span>
                    </div>
                  }
                  key="1"
                >
                  {' '}
                  <ExclamationPoint className="ml-2" />
                </Popover>
              )}
              {isEmpty(eventSubscriptionOptions) && !isEmpty(subscription) && (
                <Popover content={<div>{notifications.notFoundEvent}</div>} key="1">
                  {' '}
                  <ExclamationPoint className="ml-2" />
                </Popover>
              )}
            </div>
          }
          rules={checkingSubscriptionSelection ? false : requiredRule}
        >
          <Select
            mode="multiple"
            filterOption={true}
            resetOptions={true}
            options={eventSubscriptionOptions}
            placeholder={notifications.selectEventSubscription}
            onChange={(value, option) => handleEventSubscription(value, option)}
            disabled={checkingSubscriptionSelection}
            showSearch
          />
        </Form.Item>

        <div className="d-flex justify-content-end">
          {status !== 'viewing' ? (
            <>
              <Button className="mr-3" icon={<CloseOutlined />} htmlType="button" onClick={resetHandler}>
                {notifications.cancel}
              </Button>
              <Button type="primary" htmlType="submit" icon={<SaveOutlined />}>
                {notifications.save}
              </Button>
            </>
          ) : (
            <Button danger icon={<CloseOutlined />} type="primary" htmlType="button" onClick={resetHandler}>
              {notifications.close}
            </Button>
          )}
        </div>
      </Form>
    </div>
  );
};
export default React.memo(NotificationPageForm);
