import {useDispatch} from 'react-redux';
import {useLocation} from 'react-router';
import {setBreadCrumbsTitle, setPageHeaderLoading} from 'redux/actions/actions';

export interface IPageHeader {
  setHeaderTitle: (title: string) => void;
  setHeaderBreadCrumbsTitle: (title: string | string[]) => void;
  setHeaderLoading: (value: boolean) => void;
}

export const usePageHeader = (): IPageHeader => {
  const dispatch = useDispatch();
  const location = useLocation();
  const setHeaderTitle = (title: string) => {
    dispatch(setBreadCrumbsTitle(title));
  };
  const setHeaderBreadCrumbsTitle = (title: string | string[]) => {
    dispatch(setBreadCrumbsTitle(title));
  };
  const setHeaderLoading = (value: boolean) => {
    dispatch(setPageHeaderLoading(value));
  };
  return {
    setHeaderTitle,
    setHeaderBreadCrumbsTitle,
    setHeaderLoading,
  };
};
