import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {isEmpty} from 'lodash';
import cn from 'classnames';

import styles from '../DeduplicationGroupTable/DeduplicationGroupTable.module.scss';
import {RootState} from 'redux/types/rootState';
import {Link} from 'react-router-dom';
import {ExportOutlined} from '@ant-design/icons';
import {renderDeduplicationValue} from 'modules/Deduplication/helpers/DeduplicationUtils';

export interface DeduplicationGroupModalValueProps {
  recordsValue: {
    caption: string;
    recordUuid: string;
    origin: boolean;
    value: any;
  };
  caption: {
    caption: string;
  };
  copyValueToGoldRecordHandler: (...value: any[]) => void;
  index: number;
  status: string;
  goldRecord: any[];
  goldRecordsIndex: {column: number; row: number}[];
  currentIndex: {column: number; row: number};
}

const DeduplicationGroupModalValue = ({
  recordsValue,
  caption,
  copyValueToGoldRecordHandler,
  index,
  status,
  goldRecord,
  goldRecordsIndex,
  currentIndex,
}: DeduplicationGroupModalValueProps) => {
  /** Индекс активной записи */
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  /** Состояние для изменения класса элемента */
  const [active, setActive] = useState(false);
  /** Uuid выбранного элемента  */
  const [uuidSelected, setSelected] = useState('');
  /** Используем хранилище */
  const color = useSelector((state: RootState) => state?.deduplication.color);
  const recordId = useSelector((state: RootState) => state?.deduplication.recordId);

  const onClick = useCallback(
    (event: any) => {
      if (status !== 'LINK_SET') {
        copyValueToGoldRecordHandler(
          event,
          recordsValue?.value,
          recordsValue?.caption,
          recordsValue?.recordUuid,
          recordsValue?.origin,
          active,
          currentIndex
        );
        setActiveIndex(index);
        setSelected(recordsValue.recordUuid);
        setActive((prevState) => !prevState);
      }
    },
    [
      active,
      copyValueToGoldRecordHandler,
      currentIndex,
      index,
      recordsValue?.caption,
      recordsValue?.origin,
      recordsValue?.recordUuid,
      recordsValue?.value,
      status,
    ]
  );
  /** Условие для выделения выбранного элемента */
  // const checkingForHighlighting =
  // (color === 'red' && activeIndex === index && uuidSelected === recordId) ||
  // (color === 'gray' && activeIndex === index && uuidSelected === recordId)
  //   ? !active
  //   : active;
  const checkingForHighlighting =
    status === 'DRAFT' &&
    goldRecordsIndex.find((item) => {
      return item.column === currentIndex.column && item.row === currentIndex.row;
    });

  /** Условие для изменения активного класса */
  useEffect(() => {
    (isEmpty(goldRecord) || (caption?.caption === recordsValue?.caption && uuidSelected !== recordId)) &&
      setActive(false);
  }, [recordId, recordsValue, goldRecord, caption?.caption, uuidSelected]);

  return (
    <div
      key={index}
      id={recordsValue?.recordUuid}
      className={cn(styles.contentBlockItem, {
        [styles.active]: checkingForHighlighting,
      })}
      onClick={onClick}
    >
      {renderDeduplicationValue(recordsValue)}
    </div>
  );
};

export default React.memo(DeduplicationGroupModalValue);
