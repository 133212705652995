import React from 'react';
import {Collapse, Descriptions, Popover} from 'ui-kit';
import dayjs from 'dayjs';
import {isEmpty} from 'lodash';

import {StyledSpan} from '../../../../../components/Themes/Components';
import {deduplication} from '../../../helpers/constants';
import {ExclamationCircleOutlined} from '@ant-design/icons';

/**
 * @param deduplicationProtocolInfo - данные для отображения системной информации по протоколу
 * Системная информация протокола дедупликации */
const DeduplicationProtocolSystemInfo = ({deduplicationProtocolInfo}) => {
  const {Panel} = Collapse;

  const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm';
  const renderDateTime = (date) => {
    return (
      <div className="d-flex align-items-center gap-8">
        {date ? (
          <>
            {dayjs(date).tz('Europe/Moscow').format(DATE_TIME_FORMAT)}
            <Popover content="Московское время (UTC+3)">
              <ExclamationCircleOutlined className="exclamationIcon" />
            </Popover>
          </>
        ) : (
          '---'
        )}
      </div>
    );
  };

  return (
    <div className="deduplicationProtocolSystemInfo">
      <Collapse>
        <Panel header={deduplication.systemInfoHeader} key="1">
          <Descriptions column={2}>
            <Descriptions.Item label={deduplication.uuid}>{deduplicationProtocolInfo?.protocolUuid}</Descriptions.Item>
            <Descriptions.Item label={deduplication.version}>
              {deduplicationProtocolInfo?.protocolVersion}
            </Descriptions.Item>
            <Descriptions.Item label={deduplication.createDate}>
              {renderDateTime(deduplicationProtocolInfo?.protocolCreateDate)}
            </Descriptions.Item>
            <Descriptions.Item label={deduplication.changeDate}>
              {renderDateTime(deduplicationProtocolInfo?.protocolDateFrom)}
            </Descriptions.Item>
            <Descriptions.Item label={deduplication.finishedDate}>
              {!isEmpty(deduplicationProtocolInfo?.protocolDateReport) &&
                renderDateTime(deduplicationProtocolInfo?.protocolDateReport)}
            </Descriptions.Item>
            <Descriptions.Item label={deduplication.identifier}>
              {deduplicationProtocolInfo?.protocolId}
            </Descriptions.Item>
          </Descriptions>
        </Panel>
      </Collapse>
    </div>
  );
};
export default React.memo(DeduplicationProtocolSystemInfo);
