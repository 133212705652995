export const anchorElementColor = '#1890ff';

export const notificationPage = {
  title: 'Уведомления',
  tableName: 'Название',
  template: 'Шаблон',
  subscription: 'Подписка',
  methods: 'Способ отправки',
  addNotification: 'Добавить уведомление',
  goBack: 'Назад',
};
