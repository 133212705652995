import {Modal as AntdModal} from 'antd';
import styles from './Modal.module.scss';
import classNames from 'classnames';
import {ModalFuncProps} from 'antd/lib';
import {InfoCircleOutlined} from '@ant-design/icons';

export const ModalConfirm = (props: ModalFuncProps) => {
  return AntdModal.confirm({
    ...props,
    className: classNames(styles.confirm, props.className),
    icon: props?.icon || <InfoCircleOutlined />,
    okButtonProps: {
      ...(props.okButtonProps || {}),
      size: 'small',
    },
    cancelButtonProps: {
      ...(props.cancelButtonProps || {}),
      size: 'small',
    },
  });
};
