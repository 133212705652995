import React, {useState, useEffect, useCallback} from 'react';
import {Alert, Button, Input, Tooltip, Typography} from 'ui-kit';
import {useHistory} from 'react-router';
import {useSelector, useDispatch} from 'react-redux';
import {includes, get, isEqual, isEmpty} from 'lodash';
import {DEFAULT_FILTER} from './helpers/constant';

import {fetchFunc} from '../../Utils/security/http/mdm';
import RecordReader from '../../components/RecordRedactor/RecordReader';
import {
  setBreadCrumbsTitle,
  setPageHeaderLoading,
  setPageHeaderShowBackButton,
  setVerify,
} from '../../redux/actions/actions';
import SystemInformation from '../../components/SystemInformation';

import style from './Verification.module.scss';
import {RootState} from 'redux/types/rootState';
import {BackwardOutlined, CheckOutlined, CloseOutlined, RollbackOutlined, SaveOutlined} from '@ant-design/icons';
import classNames from 'classnames';

const {TextArea} = Input;

export interface VerificationProps {
  match: {
    params: any;
  };
}

/** Верификация записи */
const Verification = ({match: {params}}: VerificationProps) => {
  const [data, setData] = useState<any>({});
  const [dataFromCpe, setDataFromCpe] = useState(null);
  const [fields, setFields] = useState<any[]>([]);
  const [comment, setComment] = useState('');
  const [catalogIdentifier] = useState(params.item);
  const [uuid] = useState(params.id);
  const [saveLoading, setSaveLoading] = useState(false);
  const [changeType, setChangeType] = useState('');
  const [tableColumns, setTableColumns] = useState<any[]>([]);
  const [tableSource, setTableSource] = useState<any>({});
  const [record, setRecord] = useState();
  const history = useHistory();
  const verifyInfo = useSelector((state: RootState) => state?.verify?.verifyValues);
  const dispatch = useDispatch();

  const getRecord = useCallback(async () => {
    try {
      const data = await fetchFunc({
        url: `/api/v1/catalogs/${catalogIdentifier}/items/${uuid}/`,
      });
      setRecord(data);
    } catch (error) {
      console.log(error);
    }
  }, [catalogIdentifier, uuid]);

  const getFields = useCallback(async () => {
    try {
      const data = await fetchFunc({
        url: `/api/v1/catalogs/${catalogIdentifier}/fields?size=1000`,
      });
      setFields(data.content);
    } catch (error) {}
  }, [catalogIdentifier]);

  const changeVerifyInfo = async () => {
    try {
      await fetchFunc({
        url: `/api/v1/catalogs/${catalogIdentifier}/items/${uuid}/unverified/verifyinfo`,
        method: 'put',
        data: {
          remark: comment,
          result: verifyInfo,
        },
      });
      setSaveLoading(false);
    } catch (error) {
      setSaveLoading(false);
    }
  };

  const denyDraft = async () => {
    try {
      await changeVerifyInfo();
      await fetchFunc({
        url: `/api/v1/catalogs/${catalogIdentifier}/items/${uuid}/unverified/reject`,
        method: 'post',
      });
      if (catalogIdentifier === 'org') history.push(`/verifier-list/${catalogIdentifier}`);
      else history.push(`/verifier-list/catalogs/${catalogIdentifier}`);
    } catch (error) {}
  };

  const approveVerify = async () => {
    try {
      await changeVerifyInfo();
      await fetchFunc({
        url: `/api/v1/catalogs/${catalogIdentifier}/items/${uuid}/unverified/approve`,
        method: 'post',
      });
      if (catalogIdentifier === 'org') history.push(`/verifier-list/${catalogIdentifier}`);
      else history.push(`/verifier-list/catalogs/${catalogIdentifier}`);
    } catch (error) {}
  };

  const getDataFromCpe = useCallback(async () => {
    try {
      const respData = await fetchFunc(
        {
          url: `/api/v1/org-from-cpe/${uuid}`,
          method: 'GET',
        },
        onCpeError,
        'org'
      );
      setDataFromCpe(respData);
    } catch (error) {}
  }, [uuid]);

  const onCpeError = () => {
    setDataFromCpe(null);
  };

  let fieldsForRender = fields;

  const diffFields = get(data, 'sysVerifyInfo.result.diff_fields', null);

  if (catalogIdentifier === 'org') {
    const orgFields =
      changeType === 'modify'
        ? diffFields
        : [
            'recType',
            'nonResidentRf',
            'regCountry',
            'description',
            'ogrn',
            'inn',
            'codeOgrnAnalog',
            'innAnalog',
            'extAddresses',
            'contactPersons',
          ];

    if (diffFields) {
      if (includes(orgFields, 'director') && !includes(orgFields, 'contactPersons')) orgFields.push('contactPersons');
      if (includes(orgFields, 'legalAddress') && !includes(orgFields, 'extAddresses')) orgFields.push('extAddresses');
    }

    fieldsForRender = fields.filter((item) => includes(orgFields, item.origin));
  }

  fieldsForRender = fieldsForRender.map((item) => {
    const hasChanged = !isEqual(data?.[item.origin], record?.[item.origin]);
    return {
      ...item,
      hasChanged,
    };
  });

  const getUnverifiedItem = useCallback(async () => {
    try {
      const data = await fetchFunc({
        url: `/api/v1/catalogs/${params.item}/items/${params.id}/unverified/`,
      });
      if (data) {
        const {sysVerifyInfo} = data;
        const changeType = sysVerifyInfo && sysVerifyInfo.changeType;
        setData(data);
        setChangeType(changeType);
        setComment(sysVerifyInfo && sysVerifyInfo.remark);
        dispatch(setVerify({...sysVerifyInfo.result}));
        if (changeType === 'modify') {
          await getRecord();
          catalogIdentifier === 'org' && (await getDataFromCpe());
        }
      }
    } catch (error) {
      console.log(error);
    }
  }, [catalogIdentifier, dispatch, getDataFromCpe, getRecord, params.id, params.item]);

  useEffect(() => {
    dispatch(setPageHeaderShowBackButton(true));
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(params)) {
      getUnverifiedItem().then();
      getFields().then();
    }
  }, [getFields, getUnverifiedItem, params]);

  useEffect(() => {
    dispatch(setPageHeaderLoading(true));
  }, [dispatch]);

  useEffect(() => {
    let changePrefix = 'Изменение записи в';
    if (changeType === 'add') {
      changePrefix = 'Добавление записи в';
    } else if (changeType === 'delete') {
      changePrefix = 'Удаление записи в';
    }
    const catalogCaption = data && data.catalogId && data.catalogId.caption;
    const title = `${changePrefix} «${catalogCaption}»`;

    if (catalogCaption) {
      dispatch(setBreadCrumbsTitle([catalogCaption, title]));
      dispatch(setPageHeaderLoading(false));
    }
  }, [changeType, data, dispatch]);

  return (
    <>
      <div className="containerWhite d-flex flex-column gap-12">
        {changeType && changeType !== 'modify' && (
          <Alert
            className={style.verificationAlert}
            showIcon
            message={
              changeType === 'add'
                ? 'Выполняется добавление указанной ниже записи справочника'
                : 'Выполняется удаление указанной ниже записи справочника'
            }
            type={changeType === 'add' ? 'info' : 'error'}
            style={{maxWidth: 900, width: '100%'}}
          />
        )}
        {data && (
          <SystemInformation data={data} referenceOriginLocal={catalogIdentifier} recordUuid={(record as any)?.uuid} />
        )}
        <div className={style.versions}>
          <div className={style.verificationCard}>
            <Typography.Title level={3}>Новая версия</Typography.Title>
            <RecordReader
              setTableColumns={setTableColumns}
              setTableSource={setTableSource}
              tableColumns={tableColumns}
              tableSource={tableSource}
              fieldsMeta={fieldsForRender}
              data={data}
              referenceOrigin={catalogIdentifier}
              isUnverified
              isVerificator={changeType !== 'delete'}
              recordsDefaultFilter={DEFAULT_FILTER}
            />
          </div>
          <div className={classNames(style.verificationCard, style.originVersion)}>
            <Typography.Title level={3}>Исходная версия </Typography.Title>
            {changeType && (
              <RecordReader
                setTableColumns={setTableColumns}
                setTableSource={setTableSource}
                tableColumns={tableColumns}
                tableSource={tableSource}
                fieldsMeta={fieldsForRender}
                data={record || {}}
                changeType={changeType}
                referenceOrigin={catalogIdentifier}
                recordsDefaultFilter={DEFAULT_FILTER}
                isOriginVersion
              />
            )}
          </div>
          {catalogIdentifier === 'org' && data && !data.nonResidentRf && (
            <div className="col-5">
              <h6 className="mb-2">Данные ФНС</h6>
              {dataFromCpe ? (
                <RecordReader fieldsMeta={fieldsForRender} data={dataFromCpe} referenceOrigin={catalogIdentifier} />
              ) : (
                <div className="text-center">Сведения ФНС отсутствуют</div>
              )}
            </div>
          )}
        </div>
        <div className="d-flex gap-12 mt-4">
          {data && data.sysVerifyInfo && data.sysVerifyInfo.status === 'verification' && (
            <Tooltip title="Внимание! Будут применены все изменения записи, в том числе помеченные как отклонённые">
              <Button type="primary" onClick={approveVerify} icon={<CheckOutlined />} className="ignore-tooltip">
                Подтвердить изменения
              </Button>
            </Tooltip>
          )}
          <Button type="primary" ghost className="ml-1" onClick={denyDraft} icon={<RollbackOutlined />}>
            Запросить уточнение
          </Button>
        </div>
        <div className="d-flex flex-column" style={{maxWidth: 900}}>
          <h6 className=" mb-2">Замечания по результатам верификации</h6>
          <TextArea value={comment} onChange={(e) => setComment(e.target.value)} />
        </div>
        <div className="d-flex gap-12">
          <Button
            type="primary"
            icon={<SaveOutlined />}
            onClick={() => {
              setSaveLoading(true);
              changeVerifyInfo();
              history.push(
                catalogIdentifier === 'org'
                  ? `/verifier-list/${catalogIdentifier}`
                  : `/verifier-list/catalogs/${catalogIdentifier}`
              );
            }}
            loading={saveLoading}
          >
            Сохранить
          </Button>
          {catalogIdentifier === 'org' && data && data.sysVerifyInfo && data.sysVerifyInfo.changeType !== 'add' && (
            <Button
              type="default"
              href={catalogIdentifier === 'org' ? `/organisations/${uuid}` : `/catalog/${uuid}`}
              target={'_blank'}
              icon={<CloseOutlined />}
            >
              Исходная запись
            </Button>
          )}

          <Button
            onClick={() =>
              history.push(
                catalogIdentifier === 'org'
                  ? `/verifier-list/${catalogIdentifier}`
                  : `/verifier-list/catalogs/${catalogIdentifier}`
              )
            }
            icon={<CloseOutlined />}
          >
            Закрыть
          </Button>
        </div>
      </div>
    </>
  );
};

export default Verification;
