import React, {ReactElement} from 'react';
import {Modal as AntdModal, Typography} from 'antd';
import styles from './Modal.module.scss';
import classNames from 'classnames';
import {ModalProps as AntdModalProps} from 'antd/es/modal';
import {ModalInfo} from './ModalInfo';
import {ModalConfirm} from './ModalConfirm';
import {ModalError} from './ModalError';

export type ModalProps = AntdModalProps;

export const Modal = (props: ModalProps): ReactElement => {
  return (
    <AntdModal
      {...props}
      title={
        <Typography.Title level={5} className="mb-4">
          {props.title}
        </Typography.Title>
      }
      className={classNames(styles.modal, props.className)}
      wrapClassName={classNames(styles.modalWrapper, props.wrapClassName)}
    />
  );
};

Modal.info = ModalInfo;
Modal.confirm = ModalConfirm;
Modal.success = AntdModal.success;
Modal.error = ModalError;
Modal.warning = AntdModal.warning;
Modal.useModal = AntdModal.useModal;
