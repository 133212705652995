import {DeleteOutlined, EditOutlined, PlusOutlined, RestOutlined, SaveOutlined} from '@ant-design/icons';
import {notifications, notificationScheduleOptions} from 'components/NotificationPage/helpers/constant';
import {isEmpty, isUndefined} from 'lodash';
import {RecordType} from 'types';
import {Button, Col, Form, FormInstance, Input, MaskedInput, Popover, Row, Select} from 'ui-kit';
import {ReactComponent as ExclamationPoint} from '../../../../assets/images/coolicon.svg';
import styles from './NotificationMethodForms.module.scss';
import classNames from 'classnames';
import {useEffect, useMemo} from 'react';
import {renderInitValuesTelephone} from 'modules/NotificationPage/helpers/utils';

const {TextArea} = Input;

interface SmsFormPropsType {
  formSms: FormInstance<RecordType>;
  openCollapseSms: string | string[];
  isActiveSms: boolean;
  renderPopoverContent: JSX.Element;
  editMethodNotificationSmsHandler: () => void;
  setIsActiveSms: React.Dispatch<React.SetStateAction<boolean>>;
  templateTypeSms?: RecordType;
  methodNotificationsSms: RecordType;
  setMethodNotificationsSms: React.Dispatch<React.SetStateAction<RecordType>>;
  success: () => void;
  status: string;
}

const SmsForm = ({
  formSms,
  templateTypeSms,
  openCollapseSms,
  methodNotificationsSms,
  isActiveSms,
  renderPopoverContent,
  setMethodNotificationsSms,
  editMethodNotificationSmsHandler,
  setIsActiveSms,
  success,
  status,
}: SmsFormPropsType) => {
  const handleActiveChangeSms = () => {
    setIsActiveSms((prev) => !prev);
    const {telephone, scheduleSms, textSms} = formSms.getFieldsValue();

    const transportParams = {
      ...telephone.map((item: string) => {
        return {phonenumber: item?.match(/\d/g)?.join('')};
      }),
    };

    if (status === 'edit') {
      if (!isEmpty(templateTypeSms)) {
        methodNotificationsSms.methodUpdateUuid = templateTypeSms?.uuid;
      }
      setMethodNotificationsSms({
        body: textSms ? textSms : initialValuesSms.textSms,
        scheduleType: scheduleSms ? scheduleSms : initialValuesSms.scheduleSms,
        transportType: notifications.sms,
        transportParams,
        methodUpdateUuid: templateTypeSms?.uuid,
      });
    } else {
      setMethodNotificationsSms({
        body: textSms,
        scheduleType: scheduleSms,
        transportType: notifications.sms,
        transportParams,
      });
    }
    success();
  };

  const clearTemplateSmsHandler = () => {
    formSms.resetFields(['scheduleSms', 'telephone', 'telephoneFirst', 'textSms']);
    formSms.setFieldValue('telephone', ['']);
    setMethodNotificationsSms({});
    setIsActiveSms(false);
  };

  const initialValuesSms = useMemo(
    () => ({
      telephone:
        status === 'edit' && !isEmpty(templateTypeSms)
          ? renderInitValuesTelephone(templateTypeSms)
          : !isUndefined(formSms.getFieldValue('telephone'))
            ? formSms.getFieldValue('telephone')
            : [''],
      scheduleSms:
        status === 'edit' &&
        !isEmpty(templateTypeSms) &&
        templateTypeSms?.transportType === 'SMS' &&
        !isEmpty(templateTypeSms?.transportParams)
          ? templateTypeSms?.scheduleType
          : formSms.getFieldValue('scheduleSms'),
      textSms:
        status === 'edit' && !isEmpty(templateTypeSms) ? templateTypeSms?.body : formSms.getFieldValue('textSms'),
    }),
    [formSms, status, templateTypeSms]
  );

  useEffect(() => {
    formSms.setFieldsValue(initialValuesSms);
  }, [formSms, initialValuesSms, templateTypeSms]);

  return (
    <>
      <Form
        onFinish={!isActiveSms ? handleActiveChangeSms : editMethodNotificationSmsHandler}
        layout={'vertical'}
        form={formSms}
        name="notificationFormSms"
        scrollToFirstError
      >
        <Row gutter={16}>
          <Col xs={24} lg={12}>
            <Form.List name="telephone">
              {(fields, {add, remove}) => {
                return (
                  <div>
                    {fields.map((field, index) => {
                      const message =
                        index === 0 ? notifications.rulesMessage : 'Необходимо удалить или заполнить поле';
                      return (
                        <div key={field.key} className={styles.itemField}>
                          <Form.Item
                            className={classNames([styles.formItem])}
                            {...field}
                            label={index === 0 ? notifications.telephone : ''}
                            rules={[
                              {
                                required: !isEmpty(openCollapseSms) && true,
                                message: message,
                              },
                              {
                                pattern: /^\+7\(\d{3}\)\d{3}-\d{2}-\d{2}$/,
                                message: notifications.patternNumber,
                              },
                            ]}
                            key={field.key}
                          >
                            <MaskedInput
                              mask={'+7(000)000-00-00'}
                              type="tel"
                              disabled={isEmpty(openCollapseSms) || isActiveSms}
                            />
                          </Form.Item>
                          {!isActiveSms && index !== 0 ? (
                            <Button
                              className={styles.deleteIcon}
                              disabled={isActiveSms}
                              onClick={() => {
                                remove(field.name);
                              }}
                              icon={<DeleteOutlined />}
                            />
                          ) : null}
                        </div>
                      );
                    })}
                    {!isActiveSms && (
                      <Form.Item>
                        <Button
                          type="link"
                          disabled={isActiveSms}
                          onClick={() => {
                            add();
                          }}
                          icon={<PlusOutlined />}
                        >
                          {notifications.addNumber}
                        </Button>
                      </Form.Item>
                    )}
                  </div>
                );
              }}
            </Form.List>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item
              label={notifications.schedule}
              name="scheduleSms"
              rules={[
                {
                  required: !isEmpty(openCollapseSms) && true,
                  message: notifications.rulesMessage,
                },
              ]}
            >
              <Select
                options={notificationScheduleOptions}
                placeholder={notifications.selectSchedule}
                disabled={(isEmpty(openCollapseSms) || isActiveSms) && true}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          label={
            <div>
              <span>{notifications.text}</span>{' '}
              <Popover content={renderPopoverContent}>
                <ExclamationPoint />
              </Popover>
            </div>
          }
          name="textSms"
          rules={[
            {
              required: !isEmpty(openCollapseSms) && true,
              message: notifications.rulesMessage,
            },
          ]}
        >
          <TextArea rows={6} disabled={(isEmpty(openCollapseSms) || isActiveSms) && true} />
        </Form.Item>
        <div className="d-flex justify-content-end">
          <Button onClick={clearTemplateSmsHandler} icon={<RestOutlined />} className="mr-3">
            {notifications.fresh}
          </Button>
          <Button type="primary" ghost icon={!isActiveSms ? <SaveOutlined /> : <EditOutlined />} htmlType="submit">
            {!isActiveSms ? notifications.save : notifications.edit}
          </Button>
        </div>
      </Form>
    </>
  );
};

export default SmsForm;
