import {fetchFunc} from 'Utils/security/http/mdm';

export const getCatalog = async (catalogUuid: string) => {
  try {
    const data = await fetchFunc({
      url: `/api/v1/catalogs/${catalogUuid}`,
      method: 'get',
    });
    return data;
  } catch (error) {}
};
