import React, {useEffect, useState} from 'react';
import Fields from '../CardWidgets/Fields/Fields';
import {fetchFunc} from '../../Utils/security/http/mdm';
import DetailDraftAttributes from './DetailDraftAttributes';
import {Skeleton} from 'ui-kit';
import {isEmpty} from 'lodash';
import {useDispatch} from 'react-redux';
import {setBreadCrumbsTitle, setPageHeaderLoading, setPageHeaderShowBackButton} from 'redux/actions/actions';

interface DetailDraftRedactorProps {
  history: any;
  id: string;
  location: any;
  main: any;
  match: any;
  path: string;
  staticContext: any;
  status: string;
}

const DetailDraftRedactor = ({status, location, history, match, ...props}: DetailDraftRedactorProps) => {
  const dispatch = useDispatch();
  const {params} = match;
  const {draftId, detailId, catalogIdentifier} = params;
  const [data, setData] = useState<any>({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(setPageHeaderLoading(true));
    getDetailItems();
    setLoading(true);
    dispatch(setPageHeaderShowBackButton(true));
  }, [match, catalogIdentifier, dispatch]);

  const getDetailItems = async () => {
    try {
      const data = await fetchFunc({
        url: `/api/v1/catalog-draft/${draftId}/fields/${detailId}`,
        method: 'get',
      });

      if (data) {
        setData(data);
        setLoading(false);
        dispatch(setBreadCrumbsTitle(`${data.caption}`));
        dispatch(setPageHeaderLoading(false));
      }
    } catch (error) {
      setLoading(false);
      dispatch(setPageHeaderLoading(false));
    }
  };

  const onAttributesSave = () => {
    setLoading(true);
    getDetailItems();
  };

  return (
    <div className="containerWhite">
      <Skeleton active loading={isEmpty(data) || loading}>
        <DetailDraftAttributes
          uuid={draftId}
          detailUuid={detailId}
          operation={data && data.operation}
          data={data}
          onSave={onAttributesSave}
        />
      </Skeleton>
      <Fields
        status={status}
        draftAction={data && data.operation}
        catalogIdentifier={catalogIdentifier}
        detail={true}
        location={location}
        history={history}
        match={match}
      />
    </div>
  );
};

export default DetailDraftRedactor;
