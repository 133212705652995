import React, {useState, useEffect, useMemo} from 'react';
import {Form, DatePicker, Input, Select, Button, Checkbox} from 'ui-kit';
import DynamicSelect from '../../components/FormElements/DynamicSelect';
import {fetchFunc} from '../../Utils/security/http/mdm';
import {backendDateFormat} from '../../components/Helpers/Utils';
import {forIn} from 'lodash';
import moment from 'moment';

const requiredRule = [{required: true, message: 'Поле обязательно для заполнения'}];

const CatalogCrossLinkForm = ({editibleItem, editMode, onSubmit, onCancel}) => {
  const [form] = Form.useForm();
  const [selectedType, setSelectedType] = useState(null);
  const [elementTypeOptions, setElementTypeOptions] = useState([]);

  useEffect(() => {
    editibleItem && editibleItem.linkType && getLinkType(editibleItem.linkType.id);
  }, [editibleItem]);

  let initialValues = {};

  const getLinkType = async (id) => {
    try {
      const fetchData = await fetchFunc({
        url: `/api/v1/link-type/${id}`,
      });
      let options = [];
      if (fetchData.symmetricLinkTypeId) {
        fetchData.symmetricLinkTypeId.availableForCatalogs.forEach((item) => {
          options.push(
            <Select.Option key={item.catalogId.uuid} value={item.catalogId.uuid}>
              {item.catalogId.caption}
            </Select.Option>
          );
        });
      }
      setElementTypeOptions(options);
      setSelectedType(fetchData);
    } catch (error) {
      console.log(error);
    }
  };

  if (editibleItem) {
    const {linkType, catalog, item, expiryDate, linkAssignmentDate, note} = editibleItem;
    initialValues = {
      linkType: linkType && linkType.id,
      catalog: catalog && catalog.catalogUuid,
      item: item && item.uuid,
      expiryDate: expiryDate && moment(expiryDate),
      linkAssignmentDate: linkAssignmentDate && moment(linkAssignmentDate),
      note: note || '',
      ...editibleItem.extendedAttributes,
    };
  }

  const handleLinkTypeChange = (value) => {
    setSelectedType(null);
    getLinkType(value);
    form.setFieldsValue({linkType: value, catalog: null});
  };

  const handleCatalogChange = (value) => {
    form.setFieldsValue({catalog: value, item: null});
  };

  const onFinish = (values) => {
    values.catalog = {
      catalogIdentifier: values.catalog,
    };
    values.item = {
      uuid: values.item,
    };
    values.linkType = {
      id: values.linkType,
    };
    values.linkAssignmentDate = values.linkAssignmentDate.format(backendDateFormat);
    if (values.expiryDate) values.expiryDate = values.expiryDate.format(backendDateFormat);
    let extendedAttributes = {};
    forIn(values, (value, key) => {
      let keyParts = key.split('_');
      if (keyParts[0] === 'extends') {
        extendedAttributes[keyParts[1]] = value;
      }
    });
    values.extendedAttributes = extendedAttributes;
    onSubmit(values);
  };

  const renderAdditionalFields = () => {
    if (selectedType && selectedType.additionalAttributes) {
      return selectedType.additionalAttributes.map((item) => renderField(item));
    } else return '';
  };

  const renderField = ({type, name, caption}) => {
    let children = <Input />;
    switch (type) {
      case 'boolean':
        children = <Checkbox />;
        break;
    }

    return (
      <Form.Item name={`extends_${name}`} label={caption}>
        {children}
      </Form.Item>
    );
  };

  const isDelete = editMode === 'delete';
  const isEdit = editMode === 'edit';

  return (
    <Form name="cross-link-add" form={form} onFinish={onFinish} initialValues={initialValues}>
      <Form.Item name="linkType" label="Тип связи" rules={requiredRule}>
        <DynamicSelect
          disabled={isDelete}
          style={{width: '100%'}}
          placeholder="Выбрать"
          notFoundContentPlaceholder="Введите запрос"
          dropdownClassName="form-item__dropdown"
          feachoptions={{
            url: '/api/v1/link-type/filter',
            inComeOptionUrl: '/api/v1/link-type',
            method: 'get',
            valueField: 'id',
            displayedField: 'caption',
            valueSearchName: 'caption',
            defaultSize: 20,
          }}
          handleSelectChange={(value) => handleLinkTypeChange(value)}
        />
      </Form.Item>
      <Form.Item noStyle shouldUpdate={(prev, next) => prev.linkType !== next.linkType}>
        {({getFieldValue}) => {
          const linkType = getFieldValue('linkType');
          return (
            linkType && (
              <Form.Item name="catalog" label="Тип элемента" rules={requiredRule}>
                <Select disabled={isDelete} onChange={handleCatalogChange}>
                  {elementTypeOptions}
                </Select>
              </Form.Item>
            )
          );
        }}
      </Form.Item>
      <Form.Item noStyle shouldUpdate={(prev, next) => prev.catalog !== next.catalog}>
        {({getFieldValue}) => {
          const catalog = getFieldValue('catalog');
          if (catalog) {
            let url = '';
            let item;
            let catalogOrigin = 'org';
            let catalogPattern;
            if (selectedType) {
              const {
                symmetricLinkTypeId: {availableForCatalogs},
              } = selectedType;
              item = availableForCatalogs.find((elem) => elem.catalogId.uuid === catalog);
              catalogOrigin = item.catalogId.origin;
              url = `/api/v1/catalogs/${item.catalogId.origin}/items/search`;
            }
            switch (catalogOrigin) {
              case 'private_persons':
                catalogPattern = ['surname', 'name', 'middleName'];
                break;
              case 'org':
                catalogPattern = 'caption';
                break;
              default:
                catalogPattern = 'name';
            }
            return (
              url && (
                <Form.Item name="item" label="Связный элемент" rules={requiredRule}>
                  <DynamicSelect
                    disabled={isDelete}
                    style={{width: '100%'}}
                    placeholder="Выбрать"
                    notFoundContentPlaceholder="Введите запрос"
                    dropdownClassName="form-item__dropdown"
                    feachoptions={{
                      url,
                      method: 'post',
                      displayedField: catalogPattern,
                      defaultSize: 20,
                      catalogOrigin: item && item.catalogId.origin,
                    }}
                    handleSelectChange={(value) => form.setFieldsValue({item: value})}
                  />
                </Form.Item>
              )
            );
          }
        }}
      </Form.Item>
      <Form.Item className="noRequiredMargin" name="expiryDate" label="Дата прекращения связи">
        <DatePicker disabled={isEdit} />
      </Form.Item>
      <Form.Item name="linkAssignmentDate" label="Дата установления связи" rules={requiredRule}>
        <DatePicker disabled={isDelete} />
      </Form.Item>
      <Form.Item name="note" label="Комментарий">
        <Input.TextArea />
      </Form.Item>
      {renderAdditionalFields()}
      <Form.Item wrapperCol={{offset: 14}}>
        <Button type="primary" className="mr-1" htmlType="submit">
          Сохранить
        </Button>
        <Button onClick={onCancel}>Отмена</Button>
      </Form.Item>
    </Form>
  );
};

export default CatalogCrossLinkForm;
