import {useCallback, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {useHistory, useLocation} from 'react-router';
import {RootState} from 'redux/types/rootState';

export interface useActiveTabsType {
  activeTab?: string;
  onChangeTab?: (activeTab: string) => void;
}

export const useActiveTab = () => {
  const history = useHistory();
  const location = useLocation();
  const firstTabKey = useSelector((state: RootState) => state.pageHeader?.tabsProps?.props?.items?.[0].key);

  const activeTab = useMemo(() => {
    const newTab = new URLSearchParams(location.search).get('tab') || firstTabKey;

    return newTab;
  }, [location.search, firstTabKey]);

  const onChangeTab = useCallback(
    (activeTab: string) => {
      history.push(`${location.pathname}?tab=${activeTab}`);
    },
    [history, location.pathname]
  );

  return {activeTab, onChangeTab};
};
