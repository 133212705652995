import React, {Fragment, useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  Button,
  Checkbox,
  Collapse,
  DatePicker,
  Form,
  FormInstance,
  Input,
  InputNumber,
  notification,
  Popover,
  Select,
  Switch,
} from 'ui-kit';
import {CheckOutlined, CloseOutlined, DeleteOutlined, PlusOutlined} from '@ant-design/icons';
import _, {concat, get, isEmpty} from 'lodash';
import ruRU from 'antd/lib/locale/ru_RU';
import 'dayjs/locale/ru';
import dayjs from 'dayjs';
import {mdm} from '../../../../Utils/constant';
import DynamicSelect from '../../../FormElements/DynamicSelect';
import {
  arrayIntengerConditions,
  arrayStringConditions,
  catalogsFilters,
  integerConditions,
  operatorOptions,
  stringConditions,
} from '../../helpers/constant';
import {backendDateFormat, convertDateToPayload, defaultDateFormat} from '../../../Helpers/Utils';
import {setFilterReset, setFilterSearchValue} from '../../../../redux/actions/actions';
import {ReactComponent as ExclamationPoint} from '../../../../assets/images/coolicon.svg';

import styles from './FilterAttributes.module.scss';
import stylesCatalogFilters from '../../CatalogsFilters.module.scss';
import {RootState} from 'redux/types/rootState';
import classNames from 'classnames';
/**
 * Фильтрация по аттрибутам
 * @param form - данные формы
 * @param incomeFields - данные атрибутов
 * @param activeKeyCollapse - ключ панели
 * @param setActiveKeyCollapse -функция переключения панели
 * @param groups - группы фильтра
 * @param setGroups - сеттер групп
 * @param validateError - ошибки валидации
 * @param setValidateError - сеттер ошибок валидации
 */

export interface FilterAttributesProps {
  incomeFields?: any[];
  form: FormInstance;
  activeKeyCollapse: string | string[] | null;
  setActiveKeyCollapse: (value: string | string[] | null) => void;
  groups: any[];
  setGroups: (value: any[]) => void;
  validateError: any;
  setValidateError: (value: any) => void;
  // don't used
  isDeduplicationRecordsAll?: any;
  itemData?: any;
}

export interface CoreType {
  value: any;
  filters: any[];
}

const FilterAttributes: React.FC<FilterAttributesProps> = ({
  incomeFields,
  form,
  activeKeyCollapse,
  setActiveKeyCollapse,
  groups,
  setGroups,
  validateError,
  setValidateError,
}) => {
  dayjs.locale('ru');
  const {Panel} = Collapse;
  /** Отправка данных в хранилище */
  const dispatch = useDispatch();
  /** Информация о полях справочника */
  const [fields, setFields] = useState<any[]>([]);
  /** Массив операторов */
  const [operators, setOperators] = useState<string[]>([]);
  /** Атрибуты */
  const [attributeOptions, setAttributeOptions] = useState([]);
  /** Значение оператора внутри группы для отправки значений фильтра */
  const [operator, setOperator] = useState('and');
  /** Оператор между группами для отправки значений фильтра */
  const [operatorLengthGroupsArr, setOperatorLengthGroupsArr] = useState<string[]>([]);
  /** Массив значений оператора внутри группы */
  const [operatorValueArr, setOperatorValueArr] = useState([operatorOptions[0].value]);
  /** Массив значений оператора между группами */
  const [operatorLengthGroupsValueArr, setOperatorLengthGroupsValueArr] = useState<string[]>([]);
  /** Индекс группы */
  const [indexGroup, setIndexGroup] = useState<number | null>(null);
  /** Состояние для всплывающего уведомления */
  const [api, contextHolder] = notification.useNotification();
  /** Состояние для валидации формы */
  const filterErrorFlag = useSelector((state: RootState) => state?.filter.filterErrorAttribute);
  /** Получаем поля справочника */
  useEffect(() => {
    incomeFields && setFields(concat(fields, incomeFields));
  }, [incomeFields]);
  /** Обновляем поля удовлетворяющие условию */
  useEffect(() => {
    const newFields: any = [];
    fields &&
      _.uniqBy(fields, 'origin').forEach((item) => {
        if (item.fieldType.id !== 12) {
          newFields.push(
            <Select.Option key={item.origin} value={item.origin}>
              {item.caption}
            </Select.Option>
          );
        }
      });
    setAttributeOptions(newFields);
  }, [fields]);
  /** Функция для оповещения при выборе атриюута в группе */
  const openNotificationWithIcon = (type: string) => {
    (api as any)[type]({
      message: catalogsFilters.attention,
      description: catalogsFilters.message,
    });
  };
  /** Функция добавления группы */
  const addGroup = () => {
    setIndexGroup(groups.length);
    setGroups([
      ...groups,
      {
        value: 'and',
        operator: operator,
        valueOut: groups.length && indexGroup ? operatorLengthGroupsArr[indexGroup] : 'and',
        groupNumber: groups.length,
        filters: [
          {
            attribute: '',
            value: '',
          },
        ],
      },
    ]);
    if (groups.length) {
      const newOperators = operators;
      const newOperatorsValueArr = operatorValueArr;
      const newOperatorLengthGroupsValueArr = operatorLengthGroupsValueArr;
      const newOperatorLengthGroupsArr = operatorLengthGroupsArr;
      newOperators.push(operatorOptions[0].value);
      newOperatorsValueArr.push(operatorOptions[0].value);
      newOperatorLengthGroupsValueArr.push(operatorOptions[0].value);
      newOperatorLengthGroupsArr.push('and');
      setOperators([...newOperators]);
      setOperatorValueArr([...newOperatorsValueArr]);
      setOperatorLengthGroupsArr([...newOperatorLengthGroupsArr]);
      setOperatorLengthGroupsValueArr([...newOperatorLengthGroupsValueArr]);
    }
  };
  /** Функция сеттер оператора внутри группы */
  const handleFilterOperatorChange = (value: any, index: number, option: any, type = '') => {
    const newGroups = groups;
    const newOperatorsValueArr = operatorValueArr;
    setOperator(option.key);
    newOperatorsValueArr[index] = value;
    newGroups[index].value = option.key;
    setGroups([...newGroups]);
    setOperatorValueArr([...newOperatorsValueArr]);
    getFilterBody();
  };
  /** Функция сеттер оператора между группами */
  const handleOperatorChange = (value: any, index: number, option: any) => {
    const newGroups = groups;
    const newOperators = operators;
    const newOperatorLengthGroupsValueArr = operatorLengthGroupsValueArr;
    const newOperatorLengthGroupsArr = operatorLengthGroupsArr;
    newOperatorLengthGroupsValueArr[index] = value;
    newOperatorLengthGroupsArr[index] = option.key;
    newGroups[index + 1].valueOut = option.key;
    setGroups([...newGroups]);
    setOperators([...newOperators]);
    setOperatorLengthGroupsArr([...newOperatorLengthGroupsArr]);
    setOperatorLengthGroupsValueArr([...newOperatorLengthGroupsValueArr]);
    getFilterBody();
  };
  /** Удаление фильтра в группе */
  const deleteFilter = (index: number, filterIndex: number) => {
    const {resetFields, getFieldValue, setFieldsValue} = form;
    resetFields([
      `attribute-${index}-${filterIndex}`,
      `condition-${index}-${filterIndex}`,
      `field-${index}-${filterIndex}`,
    ]);
    const newGroups = groups;
    const newGroupsFilterLength = newGroups[index].filters && newGroups[index].filters.length;

    if (newGroupsFilterLength > 1) {
      for (let i = filterIndex + 1; i < newGroupsFilterLength; i++) {
        setFieldsValue({
          [`attribute-${index}-${i - 1}`]: getFieldValue(`attribute-${index}-${i}`),
          [`condition-${index}-${i - 1}`]: getFieldValue(`condition-${index}-${i}`),
          [`field-${index}-${i - 1}`]: getFieldValue(`field-${index}-${i}`),
        });
        resetFields([`attribute-${index}-${i}`, `condition-${index}-${i}`, `field-${index}-${i}`]);
      }
    }
    newGroups[index].filters.splice(filterIndex, isEmpty(validateError) ? 1 : validateError?.length);
    getFilterBody();
    setGroups([...newGroups]);
  };
  /** Удаление группы */
  const deleteGroup = (index: number) => {
    const {resetFields, getFieldValue, setFieldsValue} = form;
    const fieldsForReset: any = [];
    groups[index].filters &&
      groups[index].filters.forEach((item: number, filterIndex: number) => {
        fieldsForReset.push(
          `attribute-${index}-${filterIndex}`,
          `condition-${index}-${filterIndex}`,
          `field-${index}-${filterIndex}`
        );
      });
    resetFields(fieldsForReset);
    const newGroups = groups;
    const newOperatorLengthGroupsValueArr = operatorLengthGroupsValueArr;
    const newGroupsLength = newGroups.length;
    if (newGroupsLength > 1) {
      for (let i = index + 1; i < newGroupsLength; i++) {
        newGroups[i].filters.forEach((item: number, filterIndex: number) => {
          setFieldsValue({
            [`attribute-${i - 1}-${filterIndex}`]: getFieldValue(`attribute-${i}-${filterIndex}`),
            [`condition-${i - 1}-${filterIndex}`]: getFieldValue(`condition-${i}-${filterIndex}`),
            [`field-${i - 1}-${filterIndex}`]: getFieldValue(`field-${i}-${filterIndex}`),
          });
          resetFields([`attribute-${i}-${filterIndex}`, `condition-${i}-${filterIndex}`, `field-${i}-${filterIndex}`]);
        });
      }
    }
    newGroups.splice(index, 1);
    newOperatorLengthGroupsValueArr.splice(index - 1, 1);
    const changedNewGroupNumber = newGroups.map((group, index) => ({...group, groupNumber: index}));
    const newOperators = operators;
    newOperators.splice(index - 1, 1);
    setOperatorLengthGroupsValueArr([...newOperatorLengthGroupsValueArr]);
    setGroups([...changedNewGroupNumber]);
    setOperators(newOperators);
    getFilterBody();
  };
  /** Функция сеттер условия в зависимости от типа поля */
  const handleFilterConditionChange = (value: any, index: number, filterIndex: number) => {
    const newGroups = groups;
    newGroups[index].filters[filterIndex].condition = value;
    getFilterBody();
    setGroups([...newGroups]);
  };
  /** Функция сеттер атрибута */
  const handleAttributeChange = (value: any, index: number, filterIndex: number) => {
    const attributeInOneGroup = groups
      ?.find((item) => item.groupNumber === index)
      ?.filters.map((item: any) => item.attribute);
    if (!isEmpty(attributeInOneGroup)) {
      if (attributeInOneGroup?.includes(value) && operator === 'and') {
        form.resetFields([
          `attribute-${index}-${filterIndex}`,
          `condition-${index}-${filterIndex}`,
          `field-${index}-${filterIndex}`,
        ]);
        deleteFilter(index, filterIndex);
        openNotificationWithIcon('warning');
      } else {
        const newGroups = groups;
        const fieldOrigin = value;
        const field = fields && fields.find((elem) => elem.origin === fieldOrigin);
        if (value !== newGroups[index].filters[filterIndex].attribute) {
          form.resetFields([`condition-${index}-${filterIndex}`, `field-${index}-${filterIndex}`]);
        }
        switch (field && field.fieldType.id) {
          case 1:
          case 11:
          case 13:
            newGroups[index].filters[filterIndex].attribute = value;
            newGroups[index].filters[filterIndex].value = '';
            setGroups([...newGroups]);
            break;
          case 22:
          case 23:
          case 24:
          case 25:
            newGroups[index].filters[filterIndex].attribute = value;
            newGroups[index].filters[filterIndex].condition = '';
            newGroups[index].filters[filterIndex].value = '';
            setGroups([...newGroups]);
            break;
          default:
            newGroups[index].filters[filterIndex].attribute = value;
            newGroups[index].filters[filterIndex].value = '';
        }
        getFilterBody();
        setGroups([...newGroups]);
      }
    }
  };
  /** Функция сеттер значения */
  const handleFilterValueChange = (value: any, index: number, filterIndex: number, fieldName = '', type = '') => {
    const newGroups = groups;
    switch (fieldName) {
      case 'Date':
        newGroups[index].filters[filterIndex].value = value && value.format(backendDateFormat);
        break;
      case 'DateTime':
        newGroups[index].filters[filterIndex].value = value && convertDateToPayload(value);
        break;
      default:
        newGroups[index].filters[filterIndex].value = value;
    }
    getFilterBody();
    setGroups([...newGroups]);
  };
  /** Добавление фильтра */
  const addFilter = (index: number) => {
    const newGroups = groups;
    newGroups[index].filters.push({
      attribute: '',
      value: '',
    });
    setGroups([...newGroups]);
  };
  /** Функция сбора данных фильтра для отправки */
  const getFilterBody = () => {
    const and: number[] = [];
    const or = [...groups];
    const secondGroup = groups?.find((item) => item?.groupNumber === 1)?.valueOut;
    const core: CoreType = {
      value: isEmpty(secondGroup) ? 'and' : secondGroup,
      filters: [],
    };
    operators.forEach((item, index) => {
      if (item === 'and') {
        if (operators[index - 1] === 'and') {
          and.push(groups[index + 1]);
          const delIndex = or.findIndex((elem) => elem.groupNumber === groups[index + 1].groupNumber);
          or.splice(delIndex, 1);
        } else {
          and.push(groups[index], groups[index + 1]);
          const delIndex = or.findIndex((elem) => elem.groupNumber === groups[index].groupNumber);
          or.splice(delIndex, 2);
        }
      }
    });
    if (and.length && indexGroup) {
      or.push({
        value: groups[indexGroup].operator,
        filters: [...and],
      });
    }
    core.filters = or;
    dispatch(setFilterSearchValue(getFilters(core)));
  };
  /** Функция подготовки данных для отправки */
  const getFilters = useCallback(
    (core: CoreType) => {
      let obj: any = {
        [core.value]: [],
      };
      let group: any = {};
      const groupArr: any[] = [];
      const thirdGroup = groups?.find((item) => item?.groupNumber === 2)?.valueOut;
      let lastGroup: any = {};
      core.filters.forEach((item) => {
        if (item.filters) {
          group = {
            ...getFilters(item),
          };
          if (item?.groupNumber < 2 || (item?.groupNumber === 2 && thirdGroup === core.value)) {
            groupArr.push(group);
          } else {
            lastGroup = [getFilters(item)];
          }
        } else {
          const filterItem = getFilterItem(item, group);
          group = {
            ...filterItem,
          };

          groupArr.push(group);
        }
      });
      if (groups?.length === 3) {
        lastGroup = !isEmpty(lastGroup) && {[thirdGroup]: [...lastGroup]};
        obj[core.value] = [...groupArr];
        if (thirdGroup !== core.value) {
          obj = {
            [thirdGroup]: {...lastGroup, ...obj},
          };
        }
      } else {
        obj[core.value] = [...groupArr];
      }
      return obj;
    },
    [groups]
  );
  /** Функция подготовки данных  с учетом атрибута и значения */
  const getFilterItem = (
    {
      attribute,
      condition,
      value,
    }: {
      attribute: string;
      condition: string;
      value: any;
    },
    core: any
  ) => {
    if (Array.isArray(value)) {
      if (condition === '!=') {
        return {
          and: {
            [`${attribute}_from`]: value[1],
            [`${attribute}_to`]: value[0],
          },
        };
      } else {
        if (attribute === 'status') {
          return {
            and: {
              [`${attribute}_in`]: value,
            },
          };
        } else if (condition === '>=' || condition === '<=') {
          return {
            and: {
              [`${attribute}_from`]: value[0],
              [`${attribute}_to`]: value[1],
            },
          };
        } else {
          attribute = `${attribute}_in`;
        }
      }
    } else {
      if (!_.isUndefined(condition)) {
        switch (condition) {
          case '!=':
          case 'wo_':
            attribute = `wo_${attribute}`;
            break;
          case '=':
          case '':
            attribute = `${attribute}`;
            break;
          default:
            attribute = `${attribute}${condition}`;
        }
      } else {
        attribute = `${attribute}_in`;
      }
    }
    if (core[attribute] || core[`${attribute}_in`]) {
      if (core[attribute]) {
        value = [value];
        value.push(core[attribute]);
        delete core[attribute];
      } else {
        value = [value, ...core[`${attribute}`]];
      }
      attribute = `${attribute}`;
    }
    return {
      [attribute]: value,
    };
  };
  /** Очистка фильтра */
  const onReset = () => {
    form.resetFields();
    setOperators([]);
    setGroups([]);
    setOperatorLengthGroupsValueArr([]);
    dispatch(setFilterSearchValue({}));
    dispatch(setFilterReset(true));
  };
  /** Функция рендер компонентов в зависимости от типа поля */
  const renderField = (index: number, field: any, filterIndex: number, fieldName: string) => {
    let children = null;
    const typeId = field?.fieldType?.id;
    switch (typeId) {
      case 1:
      case 13:
      case 22:
      case 25:
        children = renderInput(index, filterIndex);
        break;
      case 2:
      case 3:
      case 'id':
      case 23:
      case 24:
        children = renderFloat(field.origin, index, filterIndex);
        break;
      case 4:
      case 20:
        children = renderDynamicSelect(field, index, filterIndex, fieldName);
        break;
      case 8:
        children = renderDate(index, filterIndex, typeId);
        break;
      case 7:
        children = renderDateInTime(index, filterIndex, typeId);
        break;
      case 10:
        children = renderCheckbox(index, filterIndex);
        break;
      case 11:
        children = renderTextArea(index, filterIndex);
        break;
      case 14:
      case 15:
        children = renderSelect(field, index, filterIndex, typeId);
        break;
      default:
        return null;
    }
    return (
      <>
        {children ? (
          <Form.Item
            name={`field-${index}-${filterIndex}`}
            style={typeId !== 10 ? {width: 224} : {}}
            rules={
              typeId !== 10
                ? [
                    {
                      required: true,
                      message: mdm.requiredMessage,
                    },
                  ]
                : []
            }
          >
            {children}
          </Form.Item>
        ) : (
          ''
        )}
      </>
    );
  };

  const conditionsSelect = (conditionOptions: any[], index: number, filterIndex: number, defaultValue: any) => {
    return (
      <Select
        defaultValue={defaultValue}
        className="w-100"
        onChange={(value) => handleFilterConditionChange(value, index, filterIndex)}
      >
        {conditionOptions.map((item, index) => (
          <Select.Option key={index} value={item.value}>
            {item.label}
          </Select.Option>
        ))}
      </Select>
    );
  };

  const renderConditions = (typeId: number, index: number, filterIndex: number) => {
    let children = null;
    switch (typeId) {
      case 1:
      case 11:
      case 13:
        children = conditionsSelect(stringConditions, index, filterIndex, '_in');
        break;
      case 22:
      case 25:
        children = conditionsSelect(arrayStringConditions, index, filterIndex, '');
        break;
      case 23:
      case 24:
        children = conditionsSelect(arrayIntengerConditions, index, filterIndex, '');
        break;
      case 2:
      case 3:
      case 8:
      case 7:
        children = conditionsSelect(integerConditions, index, filterIndex, '=');
        break;
      default:
        return null;
    }
    return (
      <>
        {children ? (
          <Form.Item style={{width: 224}} name={`condition-${index}-${filterIndex}`}>
            {children}
          </Form.Item>
        ) : (
          ''
        )}
      </>
    );
  };

  const renderInput = (index: number, filterIndex: number) => {
    return (
      <Input
        placeholder={mdm.inputItem}
        className="w-100"
        onChange={(event) => handleFilterValueChange(event.target.value, index, filterIndex)}
      />
    );
  };

  const renderFloat = (fieldName: string, index: number, filterIndex: number) => {
    return (
      <InputNumber
        name={fieldName}
        className="w-100"
        onChange={(value) => handleFilterValueChange(value, index, filterIndex)}
      />
    );
  };

  const renderSelect = (field: any, index: number, filterIndex: number, typeId: number) => {
    const options = get(field, 'options.combobox_options');
    let renderedOptions = [];
    if (options) {
      renderedOptions = options?.map((item: any) => (
        <Select.Option key={item.key} value={item.key}>
          {item.value}
        </Select.Option>
      ));
    }
    return (
      <Select
        mode={typeId == 15 ? 'multiple' : undefined}
        placeholder={mdm.chooseItem}
        popupClassName="form-item__dropdown"
        className="w-100"
        onChange={(value) => {
          handleFilterValueChange(value, index, filterIndex, field.origin, 'select');
        }}
      >
        {renderedOptions}
      </Select>
    );
  };

  const renderDynamicSelect = (field: any, index: number, filterIndex: number, fieldName: string) => {
    let searchUrl;
    let method: 'post' | 'get' = 'post';
    let displayedField;
    let valueSearchName;
    let refScript = null;
    let valueField;
    let mode: 'multiple' | 'tags' | undefined;
    if (field.referenceCatalog?.origin === 'user_profile') {
      searchUrl = '/api/v1/catalogs/user_profile/items/search';
      method = 'post';
      displayedField = ['lastName', 'firstName', 'middleName'];
    } else if (field.fieldType?.id === 20) {
      searchUrl = '/api/v1/statuses';
      method = 'get';
      valueSearchName = 'caption';
      displayedField = 'caption';
      valueField = 'id';
      mode = 'multiple';
    } else {
      if (field.referenceCatalogPattern) {
        if (_.isArray(field.referenceCatalogPattern)) {
          displayedField = field.referenceCatalogPattern;
        } else {
          const refValues = field.referenceCatalogPattern.split(';');
          displayedField = refValues.length === 3 ? [refValues[1], refValues[2]] : refValues[1];
        }
      }
      searchUrl = `/api/v1/catalogs/${field.referenceCatalog.uuid}/items/search/extended`;
      const refValues = field.referenceCatalogPattern.split(';');
      displayedField = refValues.length === 3 ? [refValues[1], refValues[2]] : refValues[1];
    }

    if (field.options && field.options.reference_value_script) refScript = field.options.reference_value_script;

    return (
      <DynamicSelect
        // name={fieldName}
        className="w-100"
        mode={mode}
        placeholder={mdm.select}
        allowClear={true}
        resetOptions={true}
        filterMode={true}
        filterOption={(input: any, option: any) => (option?.children ?? '').toLowerCase().includes(input.toLowerCase())}
        // notfoundcontentplaceholder={mdm.notFound}
        displayedFieldSeparator={
          field.displayedFieldSeparator || get(field, 'options.detailCfg.displayedFieldSeparator')
        }
        popupClassName="form-item__dropdown"
        feachoptions={{
          referenceStore: 'mdm',
          url: searchUrl,
          catalogOrigin: field.referenceCatalog?.origin,
          method,
          displayedField,
          valueSearchName,
          refScript,
          valueField,
          defaultSize: 20,
        }}
        handleSelectChange={(value: any) => {
          form.setFieldsValue({[`field-${index}-${filterIndex}`]: value});
          handleFilterValueChange(value, index, filterIndex, field.origin, 'select');
        }}
      />
    );
  };

  const renderDate = (index: number, filterIndex: number, typeId = false) => {
    return (
      <DatePicker
        // locale={ruRU}
        onChange={(date) => handleFilterValueChange(date, index, filterIndex, 'Date')}
        className="w-100"
        format={defaultDateFormat}
      />
    );
  };

  const renderDateInTime = (index: number, filterIndex: number, typeId = false) => {
    return (
      <DatePicker
        // locale={ruRU}
        onChange={(date) => handleFilterValueChange(date, index, filterIndex, 'DateTime')}
        showTime
        className="w-100"
        format="DD.MM.YYYY  HH:mm"
      />
    );
  };

  const renderCheckbox = (index: number, filterIndex: number) => {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <Switch
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          onChange={(event) => handleFilterValueChange(event, index, filterIndex, 'checkbox')}
        />
      </div>
    );
  };

  const renderTextArea = (index: number, filterIndex: number) => {
    return (
      <Input.TextArea
        placeholder={mdm.inputItem}
        rows={1}
        onChange={(event) => handleFilterValueChange(event.target.value, index, filterIndex, 'checkbox')}
      />
    );
  };
  const onResetFilterForm = () => {
    if (filterErrorFlag === true) {
      !isEmpty(validateError) &&
        isEmpty(activeKeyCollapse) &&
        validateError.map((item: any) => deleteFilter(item.slice(6, 7), item.slice(8, 9)));
      setValidateError([]);
    }
  };
  return (
    <div onClick={onResetFilterForm}>
      <Collapse
        // className={stylesCatalogFilters.collapse}
        onChange={(key) => {
          setActiveKeyCollapse(key);
        }}
      >
        <Panel
          key="filter"
          header={catalogsFilters.filterSearch}
          // className="catalogsFilters__collapse--panel"
        >
          <>
            <>{contextHolder}</>
            <Form
              form={form}
              className="d-flex flex-column"
              onValuesChange={() => {
                getFilterBody();
              }}
            >
              <div className="d-flex mb-3">
                <Button disabled={groups.length >= 3} onClick={addGroup} type="primary" ghost icon={<PlusOutlined />}>
                  {catalogsFilters.addGroup}
                </Button>
                {groups.length >= 3 && (
                  <Popover content={catalogsFilters.popover} className="filter-popover">
                    <ExclamationPoint className="ml-2" />
                  </Popover>
                )}
              </div>
              {groups.map((item, index) => (
                <Fragment key={index}>
                  <div className={classNames('d-flex', styles['filter-item'])}>
                    <div
                      className={classNames(
                        'd-flex flex-column align-items-start p-0 justify-content-center gap-12',
                        styles['operator-block']
                      )}
                    >
                      {item?.filters?.length > 1 && (
                        <div className="d-flex w-100 align-items-center justify-content-between">
                          <p className="mb-0">{catalogsFilters.operator}</p>
                          <Select
                            style={{width: 80}}
                            value={operatorValueArr[index]}
                            options={operatorOptions as any}
                            onChange={(value, option) => handleFilterOperatorChange(value, index, option, 'value')}
                          />
                        </div>
                      )}
                      <div className="d-flex w-100">
                        <Button
                          className="w-100"
                          type="primary"
                          onClick={() => addFilter(index)}
                          icon={<PlusOutlined />}
                        >
                          {catalogsFilters.addFilter}
                        </Button>
                      </div>
                      <div className="d-flex w-100">
                        <Button
                          className="w-100"
                          type="primary"
                          icon={<CloseOutlined />}
                          ghost
                          onClick={() => deleteGroup(index)}
                        >
                          {catalogsFilters.deleteGroup}
                        </Button>
                      </div>
                    </div>
                    <div className="d-flex flex-column gap-20">
                      {item.filters.map((filter: any, filterIndex: number) => {
                        const fieldOrigin = groups[index].filters[filterIndex].attribute.split('_')[0];
                        const field = fields && fields.find((elem) => elem.origin === fieldOrigin);
                        const fieldName = `${fieldOrigin}-${index}-${filterIndex}`;
                        return (
                          <div className={styles['filter-subitem']} key={filterIndex}>
                            <Form.Item style={{width: 224}} name={`attribute-${index}-${filterIndex}`}>
                              <Select
                                placeholder={catalogsFilters.attributeForSearch}
                                popupClassName="form-item__dropdown"
                                onChange={(value) => {
                                  handleAttributeChange(value, index, filterIndex);
                                }}
                              >
                                {attributeOptions}
                              </Select>
                            </Form.Item>
                            {renderConditions(field && field.fieldType.id, index, filterIndex)}
                            {renderField(index, field, filterIndex, fieldName)}
                            <div className="p-0">
                              <Button
                                onClick={() => deleteFilter(index, filterIndex)}
                                style={{border: 'none', width: 'auto', padding: 0, minWidth: 'auto'}}
                              >
                                <DeleteOutlined />
                              </Button>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  {operatorLengthGroupsValueArr[index] && (
                    <Select
                      className="my-3"
                      style={{width: 80}}
                      options={operatorOptions as any}
                      value={operatorLengthGroupsValueArr[index]}
                      onChange={(value, option) => handleOperatorChange(value, index, option)}
                    />
                  )}
                </Fragment>
              ))}
              {!isEmpty(groups) && (
                <div className="d-flex ml-auto mt-4">
                  <Button onClick={onReset} type="primary" danger ghost icon={<DeleteOutlined />}>
                    {catalogsFilters.reset}
                  </Button>
                </div>
              )}
            </Form>
          </>
        </Panel>
      </Collapse>
    </div>
  );
};

export default FilterAttributes;
