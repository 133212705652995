import React, {useCallback, useEffect, useState} from 'react';
import {useHistory, useRouteMatch} from 'react-router';
import {useDispatch, useSelector} from 'react-redux';
import {Tabs, Button} from 'ui-kit';
import {isEmpty} from 'lodash';

import RecordRedactor from '../../components/RecordRedactor';
import Versions from '../../components/Verisons';
import {SignsTable} from '../../components/SignDoc';
import {fetchFunc} from '../../Utils/security/http/mdm';

import './CatalogItemRecord.scss';
import {setPageHeaderTabs, setPageHeaderBack, setBreadCrumbsTitle, setPageHeaderLoading} from 'redux/actions/actions';
import {SliceTabs} from 'ui-kit';
import {getPreviousFolderUrl} from 'Utils/pathUtils/getPrevPath';
import {RootState} from 'redux/types/rootState';
import {useActiveTab} from 'hooks/navigation';
import {getCatalog} from 'api';

const CatalogItemRecord = () => {
  const dispatch = useDispatch();
  const catalogId = useSelector(({subscriptions}: RootState) => subscriptions.currentCatalog.catalogUuid);
  const catalogTitle = useSelector(({subscriptions}: RootState) => subscriptions.currentCatalog.caption);
  const catalogIdentifier = useSelector(({subscriptions}: RootState) => subscriptions.currentCatalog.catalogIdentifier);
  const {activeTab} = useActiveTab();

  const tabs = useSelector((store: RootState) => store.pageHeader?.tabsProps);

  const {params} = useRouteMatch();
  const history = useHistory();
  const catalogUuid = (params as any).item;
  const recordUuid = (params as any).id;
  const [access, setAccess] = useState<any>({});
  const [isUnverified, setIsUnverified] = useState(false);
  /** Ключ для вкладки версии */
  const [versionKey, setVersionKey] = useState('');
  const [isRestored, setIsRestored] = useState(false);
  const [isChanged, setIsChanged] = useState(false);

  const setTitleHeader = useCallback(async () => {
    const data = await getCatalog(catalogUuid);
    if (data) {
      dispatch(setBreadCrumbsTitle([data.caption, `${data.caption} - информация о записи`]));
      dispatch(setPageHeaderLoading(false));
    }
  }, [dispatch, catalogUuid]);

  const getCatalogRecordAccess = useCallback(async () => {
    try {
      const data = await fetchFunc({
        url: `/api/v1/catalogs/${catalogUuid}/items/${recordUuid}/fact/access`,
        method: 'get',
      });
      setAccess(data);
    } catch (error) {
      console.log(error);
    }
  }, [catalogUuid, recordUuid]);

  useEffect(() => {
    catalogUuid && recordUuid && getCatalogRecordAccess();
  }, [catalogUuid, getCatalogRecordAccess, recordUuid]);

  useEffect(() => {
    dispatch(setPageHeaderBack(getPreviousFolderUrl()));
  }, [dispatch]);

  useEffect(() => {
    dispatch(setPageHeaderLoading(true));
    setTitleHeader();
  }, [dispatch, setTitleHeader]);

  useEffect(() => {
    dispatch(
      setPageHeaderTabs({
        items: [
          {
            key: '1',
            label: 'Общая информация',
          },
          {
            key: '2',
            label: 'Версии',
          },
          // MDM-2157
          // {
          //   key: '3',
          //   label: 'Подписи',
          // },
        ],
        activeTab: activeTab ? `${activeTab}` : '1',
      })
    );
  }, [dispatch, activeTab]);

  return (
    <div className="catalogs-item-record">
      <div className="containerWhite">
        <SliceTabs.Content activeKey={activeTab}>
          <SliceTabs.TabPane tab="Общая информация" key="1">
            <RecordRedactor
              canEdit={access?.canEdit}
              isRestored={isRestored}
              setIsRestored={setIsRestored}
              setIsChanged={setIsChanged}
              setIsUnverified={setIsUnverified}
              catalogUuid={!isEmpty(catalogUuid) && catalogUuid}
              recordUuid={!isEmpty(recordUuid) && recordUuid}
              fixedSubmitButton
              versionKey={versionKey}
              setVersionKey={setVersionKey}
              isCard
            />
          </SliceTabs.TabPane>
          <SliceTabs.TabPane tab="Версии" key="2">
            <Versions
              setIsRestored={setIsRestored}
              isChanged={isChanged}
              catalogUuid={!isEmpty(catalogUuid) && catalogUuid}
              recordUuid={!isEmpty(recordUuid) && recordUuid}
              versionKey={versionKey}
              setVersionKey={setVersionKey}
              type={'info'}
              isUnverified={isUnverified}
              isVersionComparison
            />
          </SliceTabs.TabPane>
          {/* MDM-2157 */}
          {/* <SliceTabs.TabPane tab="Подписи" key="3">
            <SignsTable
              mainFetchProps={{
                docUuid: recordUuid,
                docTypeIdentifier: catalogUuid,
              }}
              canEdit={access?.canEdit}
              uuidFile={undefined}
            />
          </SliceTabs.TabPane> */}
        </SliceTabs.Content>
      </div>
    </div>
  );
};

export default React.memo(CatalogItemRecord);
