import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {isEmpty} from 'lodash';
import {useDispatch, useSelector} from 'react-redux';
import {Button, Form, Input, Modal, Popover, Typography} from 'ui-kit';
import {CloseOutlined, ExclamationCircleOutlined} from '@ant-design/icons';

import {ReactComponent as ExclamationPoint} from '../../../assets/images/coolicon.svg';
import {
  addDeduplicationProtocol,
  editJobDeduplication,
  getDeduplicationFormProtocol,
} from '../../../redux/thunk/deduplicationThunk';
import DynamicSelect from '../../../components/FormElements/DynamicSelect';
import FormList from './components/FormList/FormList';
import {addRulesDeduplication} from '../../../redux/actions/actions';
import {requiredRule} from '../../../components/EditFieldForm/helpers/constants';
import {deduplication} from '../helpers/constants';
import {
  fieldTypesFilter,
  renderAlgorithmDeduplication,
  renderAlgorithmDeduplicationPayload,
  renderButtonDeduplicationCancel,
  renderButtonDeduplicationSubmit,
} from '../helpers/DeduplicationUtils';

import styles from './deduplicationProtocolForm.module.scss';
import classNames from 'classnames';

/**
 * Форма задания на дедупликацию
 * @param editableItem  - данные справочника
 * @param setDeduplicationModalVisible  - состояние модального окна
 */
const DeduplicationProtocolForm = ({
  formStatus,
  recordUuid,
  setDeduplicationModalVisible,
  protocolsJobDeduplicationInfo,
}) => {
  /** Данные формы */
  const [form] = Form.useForm();
  /** Отправка данных для хранилища */
  const dispatch = useDispatch();
  /** Состояние для всплывающего уведомления */
  const [modal, contextHolder] = Modal.useModal();
  /** Используем хранилище */
  const deduplicationForm = useSelector((state) => state?.deduplication.deduplicationFormProtocol);
  const rulesDeduplication = useSelector((state) => state?.deduplication.rulesDeduplication);
  /** Добавление индекса  */
  const rulesDeduplicationWithId = rulesDeduplication?.map((item, index) => {
    return {...item, id: index};
  });
  /** Получаем степень близости по дефолту */
  const defaultDegreeProximity = deduplicationForm?.map((item) => item.defaultValue)[0];
  /** Получения аттрибутов справочника для редактирования формы */
  const attributesFieldCaption = protocolsJobDeduplicationInfo?.rulesDeduplication?.map((item) => item.fieldCaption);
  /** Получения значения кода  статуса задания */
  const status = protocolsJobDeduplicationInfo && protocolsJobDeduplicationInfo.status.key;
  /** Значение uuid для атрибута */
  const [uuidCatalog, setUuidCatalog] = useState('');
  /** Значение origin справочника для отправки запроса */
  const [referenceOrigin, setReferenceOrigin] = useState('');
  /** Состояние селекта с выбором атрибутов */
  const [isDisabled, setDisabled] = useState(true);
  /** Значение UUID справочника */
  const [catalogDeduplicationId, setCatalogDeduplicationId] = useState('');
  /** Значение для условия отрисовки */
  const [editeData, setEditeData] = useState(protocolsJobDeduplicationInfo);
  /** Объект для отправки запроса на дедупликацию */
  const [payloadData, setPayloadData] = useState({});
  /** Объект для отправки запроса на дедупликацию */
  const [closeWarning, setCloseWarning] = useState(true);
  /** Проверка условия для отображения уведомления */
  const resemblance = useMemo(() => {
    return rulesDeduplication?.filter((item) => item.degreeProximity <= 0.7 && item);
  }, [rulesDeduplication]);
  /** Данные для редактирования формы */
  const initialValues = {
    name:
      protocolsJobDeduplicationInfo && formStatus === 'editStatus'
        ? protocolsJobDeduplicationInfo.name
        : form.getFieldValue('name'),
    catalogDeduplicationName:
      !isEmpty(protocolsJobDeduplicationInfo?.catalogDeduplicationName) && formStatus === 'editStatus'
        ? protocolsJobDeduplicationInfo?.catalogDeduplicationName || form.getFieldValue('catalogDeduplicationName')
        : form.getFieldValue('catalogDeduplicationName'),
    attributeCatalog:
      formStatus === 'editStatus'
        ? rulesDeduplication.map((item) => item.fieldCaption)
        : form.getFieldValue('attributeCatalog'),
    algorithmDeduplication:
      formStatus === 'editStatus'
        ? rulesDeduplication?.map((item) => renderAlgorithmDeduplication(item.algorithmDeduplication))
        : form.getFieldValue('algorithmDeduplication'),
    degreeProximity:
      formStatus === 'editStatus'
        ? rulesDeduplication?.map((item) => Number(item.degreeProximity)) || 0.5
        : form.getFieldValue('degreeProximity'),
  };
  /** Функция для отображение оповещения при выборе степени близости */
  const openNotificationWithIcon = () => {
    modal.confirm({
      title: deduplication.attention,
      content: deduplication.resemblanceAttention,
      icon: <ExclamationCircleOutlined />,
      onOk: () => setCloseWarning(false),
      cancelButtonProps: {style: {display: 'none'}},
    });
  };
  /** Загружаем данные для формы дедупликации */
  useEffect(() => {
    dispatch(getDeduplicationFormProtocol());
  }, []);
  /**  Загружаем данные для редактирования формы  дедупликации */
  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form]);
  /** Получение правил для дедупликации */
  const comboBoxOptions = deduplicationForm
    ?.map((item) => item.options)
    .filter((item) => item?.combobox_options)
    .flatMap((item) => item?.combobox_options);
  /** Сеттер названия  задания на дедупликацию */
  const handleNameChange = (value) => {
    form.setFieldsValue({name: value});
  };
  /**
   * Сеттер значения справочника
   * @param value {string} - название справочника
   * @param option {Object} - данные справочника
   */
  const handleCatalogChange = useCallback(
    (value, option) => {
      form.setFieldsValue({
        catalogDeduplicationName: value,
        uuidItem: setUuidCatalog(option.uuidValue),
        attributeCatalog: [],
      });
      setDisabled(value && false);
      setEditeData({});
      setPayloadData({catalogDeduplicationId: option.uuidValue});
      setCatalogDeduplicationId(option.uuidValue);
      setReferenceOrigin(option.referenceOrigin);
      dispatch(addRulesDeduplication([]));
    },
    [form, uuidCatalog]
  );
  /** Сеттер атрибутов справочника */
  const handleAttributesChange = useCallback(
    (value, option) => {
      form.setFieldsValue({
        attributeCatalog: value,
        uuidItem: setUuidCatalog(option.uuidValue),
      });
      const createRulesDeduplicationArr = option?.map((item, index) => {
        return {
          key: item.referenceOrigin,
          fieldCaption: item.fieldCaption,
          fieldOrigin: item.referenceOrigin,
          algorithmDeduplication:
            renderAlgorithmDeduplicationPayload(form.getFieldValue(['algorithmDeduplication', index])) ||
            rulesDeduplication.algorithmDeduplication,
          degreeProximity: form.getFieldValue(['degreeProximity', index]) || rulesDeduplication.degreeProximity || 0.5,
        };
      });
      dispatch(addRulesDeduplication(createRulesDeduplicationArr));
    },
    [form, rulesDeduplication]
  );
  /** Формируем объект для payloadData */
  const createCurrentRulesDeduplicationArr = () => {
    const rulesDeduplicationArr = rulesDeduplication?.map((item) => {
      return {
        fieldCaption: item.fieldCaption,
        fieldOrigin: item.fieldOrigin,
        degreeProximity: Number(item.degreeProximity),
        algorithmDeduplication: item.algorithmDeduplication,
      };
    });
    payloadData.name = form.getFieldValue('name');
    payloadData.catalogDeduplicationName =
      initialValues?.catalogDeduplicationName || form.getFieldValue('catalogDeduplicationName');
    payloadData.catalogDeduplicationId =
      protocolsJobDeduplicationInfo?.catalogDeduplicationId || catalogDeduplicationId;
    payloadData.rulesDeduplication = rulesDeduplicationArr;
    return payloadData;
  };
  /** Отправка запроса на создание дедупликации */
  const submitHandler = useCallback(() => {
    if (!isEmpty(resemblance) && closeWarning === true) {
      openNotificationWithIcon('warning');
    } else if ((closeWarning === false && !isEmpty(resemblance)) || isEmpty(resemblance)) {
      formStatus === 'createNewDeduplication' &&
        dispatch(addDeduplicationProtocol(createCurrentRulesDeduplicationArr(), referenceOrigin));
      formStatus === 'editStatus' &&
        dispatch(
          editJobDeduplication(recordUuid, {
            fields: createCurrentRulesDeduplicationArr(),
            referenceOrigin: protocolsJobDeduplicationInfo.catalogDeduplicationId,
          })
        );

      if (formStatus === 'createNewDeduplication') {
        form.resetFields();
        setDisabled(isDisabled);
        setUuidCatalog('');
        setCatalogDeduplicationId('');
      }
      setCloseWarning(true);
      setDeduplicationModalVisible(false);
    }
  }, [closeWarning, resemblance]);
  /** Очистка данных формы */
  const resetHandler = () => {
    form.resetFields();
    setDeduplicationModalVisible(false);
    dispatch(addRulesDeduplication([]));
    setDisabled((prev) => !prev);
  };
  /** Отрисовка подсказки для алгоритмов */
  const renderPopoverContent = (
    <div>
      {deduplication.hintContentsTextForAlgorithms.map((content) => (
        <p key={content.index}>{content}</p>
      ))}
    </div>
  );
  /** Отрисовка подсказки для формы */
  const renderPopoverForm = (
    <div>
      {deduplication.subtitle.map((content) => (
        <p key={content.index}>{content}</p>
      ))}
    </div>
  );
  /** Отрисовка подсказки для формы при установлении сходства */
  const renderPopoverFormResemblance = (
    <div>
      {deduplication.degreeProximityTooltip.map((content) => (
        <p key={content.index}>{content}</p>
      ))}
    </div>
  );

  return (
    <Form layout={'vertical'} form={form} name="deduplication-job_form" onFinish={submitHandler}>
      <Popover content={renderPopoverForm} className={styles.popover}>
        <h5 className={styles.title}>{deduplication.title}</h5>
        <ExclamationPoint className="ml-2" />
      </Popover>
      <>{contextHolder}</>
      <div className={styles.head_menu}>
        <Form.Item label={deduplication.name} name="name" className={styles.head_menu__item_one} rules={requiredRule}>
          <Input
            placeholder={deduplication.addName}
            setValue={handleNameChange}
            disabled={formStatus === 'editStatus' && (status === 5 || status === 2 || status === 3)}
          />
        </Form.Item>

        <Form.Item
          name="catalogDeduplicationName"
          label={deduplication.catalog}
          className={styles.head_menu__item_two}
          rules={requiredRule}
        >
          <DynamicSelect
            style={{width: '100%'}}
            placeholder={deduplication.select}
            notFoundContentPlaceholder={deduplication.notFound}
            filterOption={true}
            selectedUuid={uuidCatalog}
            resetOptions={true}
            disabled={status === 5 || status === 2 || status === 3}
            feachoptions={{
              referenceStore: 'mdm',
              inComeOptionUrl: '/api/v1/catalogs',
              url: '/api/v1/catalogs',
              method: 'get',
              catalogOrigin: 'catalogs',
              displayedField: 'caption',
              valueField: 'caption',
              valueSearchName: 'caption',
              defaultSize: 1000,
            }}
            handleSelectChange={(value, option) => handleCatalogChange(value, option)}
          />
        </Form.Item>
      </div>
      <Form.Item
        name="attributeCatalog"
        label={deduplication.attributeCatalog}
        className={styles.head_menu__item_three}
        rules={requiredRule}
      >
        <DynamicSelect
          style={{width: '100%'}}
          placeholder={deduplication.select}
          disabled={
            (formStatus === 'createNewDeduplication' && isEmpty(form.getFieldValue('catalogDeduplicationName'))) ||
            (formStatus === 'editStatus' && (status === 5 || status === 2 || status === 3))
          }
          mode="multiple"
          filterOption={true}
          resetOptions={true}
          onDataFilter={fieldTypesFilter}
          notFoundContentPlaceholder="Не найдено"
          feachoptions={{
            referenceStore: 'mdm',
            url: `${`/api/v1/catalogs/${
              uuidCatalog || catalogDeduplicationId || protocolsJobDeduplicationInfo?.catalogDeduplicationId
            }/fields`}`,
            method: 'get',
            catalogOrigin:
              uuidCatalog || catalogDeduplicationId || protocolsJobDeduplicationInfo?.catalogDeduplicationId,
            displayedField: 'caption',
            valueField: 'caption',
            valueSearchName: 'caption',
            defaultSize: 1000,
          }}
          handleSelectChange={(value, option) => handleAttributesChange(value, option)}
        />
      </Form.Item>
      {(!isEmpty(rulesDeduplication) || (formStatus === 'editStatus' && !isEmpty(editeData))) && (
        <div className={styles.form_list__head_title}>
          <Typography.Title level={5} className={styles.form_list__head_title__item_one}>
            {deduplication.attributes}
          </Typography.Title>
          <Typography.Title level={5} className={styles.form_list__head_title__item_two}>
            <Popover content={renderPopoverContent} className={styles.popover}>
              <span>{deduplication.algorithm}</span>
              <ExclamationPoint className="ml-2" />
            </Popover>
          </Typography.Title>
          <Typography.Title level={5} className={styles.form_list__head_title__item_three}>
            <Popover content={renderPopoverFormResemblance} className={styles.popover}>
              <span>{deduplication.resemblance}</span>
              <ExclamationPoint className="ml-2" />
            </Popover>
          </Typography.Title>
        </div>
      )}
      {rulesDeduplication &&
        rulesDeduplicationWithId?.map((attribute, index) => {
          return (
            <FormList
              key={attribute.id}
              index={attribute.id}
              attribute={attribute}
              status={status}
              attributesFieldCaption={attributesFieldCaption}
              defaultDegreeProximity={defaultDegreeProximity}
              protocolsJobDeduplicationInfo={protocolsJobDeduplicationInfo}
              comboBoxOptions={comboBoxOptions}
              form={form}
              formStatus={formStatus}
              initialValues={initialValues}
            />
          );
        })}
      <div className={classNames('d-flex justify-content-end', styles.button)}>
        {renderButtonDeduplicationCancel({
          status,
          formStatus,
          onClick: resetHandler,
        })}
        {renderButtonDeduplicationSubmit({status, formStatus})}
      </div>
    </Form>
  );
};

export default React.memo(DeduplicationProtocolForm);
