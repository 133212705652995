import React, {Component, ReactNode} from 'react';
import {connect} from 'react-redux';
import {saveAs} from 'file-saver';
import {Button, Popover, Tooltip} from 'ui-kit';
import {get, isObject} from 'lodash';
import moment from 'moment';

import Fields from '../../components/CardWidgets/Fields/Fields';
import Attributes from '../../components/CardWidgets/Attributes/Attributes';
import {fetchFunc} from '../../Utils/security/http/mdm';
import {errorModalCreate} from '../../components/Helpers/Modals';

import './CatalogDraftCard.scss';
import {
  setPageHeaderTag,
  setPageHeaderLinesBlock,
  setPageHeaderButtons,
  setBreadCrumbsTitle,
  setPageHeaderLoading,
} from 'redux/actions/actions';
import {
  CloseOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  ExportOutlined,
  SaveOutlined,
} from '@ant-design/icons';
import {History} from 'history';
import {RootState} from 'redux/types/rootState';
import {Typography} from 'antd';
import {mdm} from 'Utils/constant';
import dayjs from 'dayjs';
import {RecordType} from 'types';

export interface CatalogDraftCardProps {
  location: Location;
  userRoles: string[];
  history: History;
  match: any;
  referenceCatalogPatternStore: any;
  setBreadCrumbsTitle: (value: string) => void;
  setPageHeaderTag: (value: {children: ReactNode; color: string}) => void;
  setPageHeaderLinesBlock: (value: {label: string; value: string}[]) => void;
  setPageHeaderButtons: (value: any) => void;
  setPageHeaderLoading: (value: boolean) => void;
}

interface CatalogDraftCardState {
  hasApplyOrRemoveAccess: boolean;
  options: {
    value: string;
    label: string;
  }[];
  loading: boolean;
  formLoading: boolean;
  uuid: string;
  data?: any;
  changeMode: boolean;
}

interface attributesDataType {
  caption: string;
  value: RecordType;
  type: string;
  origin: string;
  noEdit?: boolean;
  disabled?: boolean;
}

class CatalogDraftCard extends Component<CatalogDraftCardProps, CatalogDraftCardState> {
  static applyOrRemoveAccessRoles = ['mdm_admin', 'catalog_admin', 'ROLE_SYSTEM', 'ROLE_ROLE_SYSTEM'];

  constructor(props: CatalogDraftCardProps) {
    super(props);

    // this.setState({
    //   hasApplyOrRemoveAccess: false,
    //   options: [
    //     {value: 'manual', label: 'Вручную'},
    //     {value: 'external', label: 'Во внешних источниках данных'},
    //     {value: 'mixed', label: 'В смешанном режиме'},
    //   ],
    //   changeMode: false,
    // });
  }

  componentDidMount() {
    this.props.setPageHeaderLoading(true);
    this.setState(
      {
        loading: true,
        formLoading: false,
        uuid: this.props.location.pathname.split('/')[2],
        hasApplyOrRemoveAccess: this.defineHasApplyOrRemoveAccess(this.props.userRoles),
        options: [
          {value: 'manual', label: 'Вручную'},
          {value: 'external', label: 'Во внешних источниках данных'},
          {value: 'mixed', label: 'В смешанном режиме'},
        ],
      },
      () => {
        this.getDraftItem();
      }
    );
  }

  componentDidUpdate() {
    const {setPageHeaderTag, setPageHeaderLinesBlock, setPageHeaderButtons, setBreadCrumbsTitle, setPageHeaderLoading} =
      this.props;
    const {data} = this.state;
    setPageHeaderButtons([
      {
        children: mdm.close,
        type: 'primary',
        ghost: true,
        danger: true,
        icon: <CloseOutlined />,
        onClick: () => this.props.history.push('/catalog-draft/'),
      },
    ]);
    if (data && data.description && data.orderNumber) {
      setBreadCrumbsTitle(`Заявка ${data.orderNumber} - ${data.description}`);
      setPageHeaderLoading(false);
    }
    if (data && data.status && data.status.caption) {
      setPageHeaderTag({children: data.status.caption, color: 'blue'});
    }
    if (data) {
      const createDateContent = data && (
        <span className="d-inline-flex gap-8">
          {dayjs(data.createDate).tz().format('YYYY-MM-DD HH:mm')}
          <Popover content="Московское время (UTC+3)">
            <ExclamationCircleOutlined className="exclamationIcon" />
          </Popover>
        </span>
      );

      setPageHeaderLinesBlock([
        {label: 'Идентификатор', value: data && data.uuid},
        {
          label: 'Дата создания',
          value: createDateContent,
        },
        {label: 'Автор', value: data && data.authorFio},
      ]);
    }
  }

  defineHasApplyOrRemoveAccess(userRoles: string[] = []) {
    return CatalogDraftCard.applyOrRemoveAccessRoles.some((role) => userRoles.includes(role));
  }

  makeAction = async (type: 'apply' | 'delete') => {
    this.setState({formLoading: true});
    const {uuid} = this.state;
    let url = '/api/v1/catalog-draft/' + uuid;
    let method = 'delete';
    const body = {} as any;
    if (type === 'apply') {
      url = '/api/v1/catalog-draft/' + uuid + '/actions';
      method = 'post';
      body.action = 'Применить';
    }
    try {
      const response = await fetchFunc({url, method, data: body}, (error: any) =>
        errorModalCreate(get(error, 'data.message') || error.message)
      );
      const isErrorResponse =
        isObject(response) &&
        (response as any)?.objects &&
        (response as any)?.objects.action &&
        (response as any)?.objects.action.errorMessage;
      if (isErrorResponse) {
        errorModalCreate(isErrorResponse);
      } else {
        this.props.history.push('/catalog-draft');
      }
    } catch (error) {
    } finally {
      this.setState({formLoading: false});
    }
  };

  getDraftItem = async () => {
    try {
      const {uuid} = this.state;
      const data = await fetchFunc({
        url: `/api/v1/catalog-draft/${uuid}`,
        method: 'get',
      });
      this.setState({data, loading: false});
    } catch (error) {
      this.setState({loading: false});
    }
  };

  onExportError = async (response: any) => {
    const text = await response.data.text();
    errorModalCreate(JSON.parse(text).message);
  };

  exportDraft = async () => {
    const {uuid} = this.state;
    try {
      const data = await fetchFunc(
        {
          url: `/api/v1/draft-order/${uuid}/metadata`,
          method: 'get',
          responseType: 'blob',
        },
        this.onExportError
      );
      saveAs(data, `${uuid}-metadata-${moment().format('DD-MM-YYYY-h-mm')}`);
    } catch (error) {
      this.setState({loading: false});
    }
  };

  onAttributesSave = () => {
    this.setState({loading: true}, () => this.getDraftItem());
  };

  onChangeMode = () => {
    this.setState({changeMode: !this.state.changeMode});
  };

  render() {
    const {data, options, loading, hasApplyOrRemoveAccess, changeMode, formLoading} = this.state || {};
    const {location, history, match, referenceCatalogPatternStore} = this.props;

    const attributesData: attributesDataType[] = [
      {caption: 'Наименование справочника', value: data && data.caption, type: 'String', origin: 'caption'},
      {
        caption: 'Наименование в БД',
        value: data && data.origin,
        type: 'String',
        origin: 'origin',
        disabled: data?.draftAction === 'modify',
      },
      {
        caption: 'Требуется верификация записей справочника',
        value: data && data.validateRecords,
        type: 'Checkbox',
        origin: 'validateRecords',
      },
      {
        caption: 'Включен механизм приоритетности источников данных',
        value: data && data.prioritySupport,
        type: 'Checkbox',
        origin: 'prioritySupport',
      },
      {caption: 'Способ ведения', value: data && data.inputType, type: 'Select', origin: 'inputType'},
    ];

    const uniqueFields = data?.options?.unique_fields || [];

    if (uniqueFields?.length > 0) {
      attributesData.push({
        caption: 'Уникальные атрибуты при импорте',
        value: uniqueFields?.join(', '),
        type: 'String',
        origin: 'options.unique_fields',
        noEdit: true,
      });
    }
    return (
      <>
        <div className="containerWhite default-column-gap">
          <div className="d-flex align-items-center">
            <Typography.Title level={3} className="flex-grow-1">
              {mdm.params}
            </Typography.Title>

            <div className="d-flex gap-12">
              <Tooltip title="Экспортировать структуру заявки в файл" style={{width: '100%'}}>
                <Button
                  ghost
                  type="primary"
                  onClick={() => this.exportDraft()}
                  icon={<ExportOutlined />}
                  className="ignore-tooltip"
                >
                  Экспортировать
                </Button>
              </Tooltip>
              {!changeMode && data?.status?.caption === 'Черновик' && (
                <Button onClick={this.onChangeMode} icon={<ExportOutlined />} type="primary">
                  Изменить
                </Button>
              )}
            </div>
          </div>
          <Attributes
            uuid={data && data.uuid}
            status={data && data.status && data.status.caption}
            loading={loading}
            options={options}
            changeMode={this?.state && this?.state.changeMode}
            onChange={this.onChangeMode}
            data={attributesData}
            onSave={this.onAttributesSave}
          />
          <Fields
            draftAction={data && data.draftAction}
            status={data && data.status && data.status.caption}
            catalogOptions={data && data.options}
            options={options}
            loading={loading}
            referenceCatalogPatternStore={referenceCatalogPatternStore}
            uuid={data && data.uuid}
            catalogIdentifier={data && data.catalogType && data.catalogType.catalogIdentifier}
            location={location}
            history={history}
            match={match}
            onSave={this.onAttributesSave}
          />
          <div className="mt-3">
            {data && data.status && data.status.caption === 'Черновик' && hasApplyOrRemoveAccess && (
              <>
                <Button
                  className="mr-3"
                  onClick={() => this.makeAction('apply')}
                  type="primary"
                  icon={<SaveOutlined />}
                  loading={formLoading}
                >
                  Применить заявку
                </Button>
              </>
            )}
            {data && data?.status.id !== 5 && (
              <Button
                onClick={() => this.makeAction('delete')}
                loading={formLoading}
                className="mr-2"
                icon={<DeleteOutlined />}
              >
                Удалить
              </Button>
            )}
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (store: RootState) => {
  return {
    breadcrumbNameMap: store.navigate.breadcrumbNames,
    referenceCatalogPatternStore: store.detail.referenceCatalogPatternStore,
    userRoles: store.user.roles,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateBreadcrumbMap: (obj: any) => {
      dispatch({
        type: 'UPDATE_BREADCRUMB_MAP',
        data: obj,
      });
    },

    setBreadCrumbsTitle: (title: string) => {
      dispatch(setBreadCrumbsTitle(title));
    },
    setPageHeaderLoading: (value: boolean) => {
      dispatch(setPageHeaderLoading(value));
    },
    setPageHeaderTag: (props: any) => {
      dispatch(setPageHeaderTag(props));
    },
    setPageHeaderLinesBlock: (props: any) => {
      dispatch(setPageHeaderLinesBlock(props));
    },
    setPageHeaderButtons: (props: any) => {
      dispatch(setPageHeaderButtons(props));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CatalogDraftCard);
