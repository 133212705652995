import React, {useState} from 'react';
import {Collapse, Input, Typography} from 'ui-kit';
import {isEmpty} from 'lodash';
import {useDispatch, useSelector} from 'react-redux';

import {catalogsFilters} from '../helpers/constant';
import {setContextSearchValue, setFilterReset} from '../../../redux/actions/actions';
import {RootState} from 'redux/types/rootState';

/**
 * Контекстный поиск
 * @param applyKey - ключ отправки фильтра
 * @param applyFilter - функция отправки значений фильтра
 * @param setApplyKey - функция сеттер отправки значений фильтра
 * @param filterAttribute - поля справочника доступные поиску
 */

interface FilterContextProps {
  applyKey?: string | false;
  applyFilter: (bodyData: any, action?: any) => void;
  filterAttribute?: string | false;
  setApplyKey: (key: string) => void;
  allResetFilter?: any;
}

const FilterContext: React.FC<FilterContextProps> = ({applyKey, applyFilter, filterAttribute, setApplyKey}) => {
  const dispatch = useDispatch();
  const {Panel} = Collapse;
  /** Значение контекстного фильтра */
  const [searchFilter, setSearchFilter] = useState<string | undefined>();
  /** Используем хранилище */
  const filterContextSearch = useSelector((state: RootState) => state?.filter.contextString);
  const filterHistory = useSelector((state: RootState) => state?.filter.historyBody);
  const filterAttributes = useSelector((state: RootState) => state?.filter.filterBody);
  const onChangeContextFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.type === 'change') {
      setSearchFilter(event.target.value);
      dispatch(setContextSearchValue(event.target.value));
    } else {
      setSearchFilter(undefined);
      dispatch(setContextSearchValue(null));
      isEmpty(filterHistory) && isEmpty(filterAttributes) && !isEmpty(applyKey) && dispatch(setFilterReset(true));
    }
  };

  return (
    <Collapse className="catalogsFilters__collapse">
      <Panel key="searchFilter" header={catalogsFilters.searchPlaceholder} className="catalogsFilters__collapse--panel">
        <Input
          size="middle"
          name="requestString"
          value={!isEmpty(filterContextSearch) ? searchFilter : undefined}
          allowClear
          className="w-50"
          onChange={(event) => onChangeContextFilter(event)}
          onPressEnter={() => {
            applyFilter({
              bodyFilter: filterAttributes,
              bodyFilterhistory: filterHistory,
              bodySearch: filterContextSearch,
            });
            setApplyKey('key');
          }}
        />
        <div className="catalogsFilters__clue">
          <Typography.Text strong>{catalogsFilters.attributeForSearch}</Typography.Text> <span>{filterAttribute}</span>
        </div>
      </Panel>
    </Collapse>
  );
};
export default React.memo(FilterContext);
