import {Input} from 'ui-kit';
import React from 'react';
import styles from '../EditFieldForm.module.scss';
import {DefaultValue, FieldOrder} from '../helpers/FormComponents';
import isURL from 'validator/lib/isURL';

interface Props {
  isDisabled: boolean;
  defaultValue?: boolean;
}

const HyperlinkFieldForm = ({isDisabled, defaultValue = true}: Props) => {
  return (
    <>
      <div className={styles.twoColumns}>
        <FieldOrder />
        {defaultValue && (
          <DefaultValue
            rules={[
              {
                validator: (_rule, value) => {
                  if (value && !isURL(value)) {
                    return Promise.reject('Неверный формат URL');
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input disabled={isDisabled} />
          </DefaultValue>
        )}
      </div>
    </>
  );
};

export default React.memo(HyperlinkFieldForm);
