import {Button, Popover, Tooltip, Typography} from 'ui-kit';
import styles from './VersionCard.module.scss';
import RecordReader from 'components/RecordRedactor/RecordReader';
import {CheckOutlined, ExclamationCircleOutlined} from '@ant-design/icons';
import {useHistory, useRouteMatch} from 'react-router';
import {fetchFunc} from 'Utils/security/http/mdm';
import {onError} from 'components/Helpers/Utils';
import {useState} from 'react';
import RecordRedactor from 'components/RecordRedactor';

interface VersionCardProps {
  fieldsMeta: any[];
  data: any;
}

const VersionCard = ({fieldsMeta, data}: VersionCardProps) => {
  const {version, active} = data;
  const history = useHistory();
  const {params} = useRouteMatch();
  const catalogUuid = (params as any).item;
  const recordUuid = (params as any).id;

  const restoreRecord = async () => {
    const urlRollBack = `/api/v1/catalogs/${catalogUuid}/item/${recordUuid}/history/${version}/undo`;
    try {
      const data = await fetchFunc(
        {
          url: urlRollBack,
          method: 'post',
        },
        onError,
        'mdm'
      );
      if (data.sysVerifyInfo && data.sysVerifyInfo.status && data.sysVerifyInfo.status === 'draft') {
        if (catalogUuid === 'org') {
          history.push(`/catalog/organisations/${data.uuid}/verify`);
        } else {
          history.push(`/catalog/${catalogUuid}/${data.uuid}/verify`);
        }
      } else history.push(`/catalog/${catalogUuid}/${recordUuid}`);
    } catch (error) {}
  };

  return (
    <div className={styles.versionCard}>
      <Typography.Title level={5}>
        Версия {version} {active && '(текущая)'}
      </Typography.Title>
      <RecordRedactor
        recordUuid={recordUuid}
        referenceOrigin={catalogUuid}
        fieldsMeta={fieldsMeta}
        versionId={version}
      />
      {!active && (
        <div className={styles.restoreButton}>
          <Button disabled={data.sysVerifyInfoDraft} icon={<CheckOutlined />} onClick={restoreRecord} type="primary">
            Восстановить
          </Button>
          {data?.sysVerifyInfoDraft && (
            <Popover content="Запись уже находится на верификации">
              <ExclamationCircleOutlined style={{fontSize: '20px', color: '#00000066', cursor: 'pointer'}} />
            </Popover>
          )}
        </div>
      )}
    </div>
  );
};

export default VersionCard;
