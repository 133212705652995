import {Select} from 'ui-kit';
import React from 'react';
import styles from '../EditFieldForm.module.scss';
import {CheckboxAccessValue, DefaultValue, FieldOrder} from '../helpers/FormComponents';
import {defaultValueCheckbox} from '../helpers/constants';

interface Props {
  isDisabled: boolean;
  detail: any;
  mapOption: (value: any) => JSX.Element;
}

const BooleanFieldForm = ({isDisabled, detail, mapOption}: Props) => {
  return (
    <>
      <div className={styles.twoColumns}>
        <FieldOrder />
        <DefaultValue>
          <Select disabled={isDisabled}>{mapOption(defaultValueCheckbox)}</Select>
        </DefaultValue>
      </div>
      {/* MDM-1769 */}
      {/* {!detail && <CheckboxAccessValue isDisabled={isDisabled} />} */}
    </>
  );
};

export default React.memo(BooleanFieldForm);
