import {DeleteOutlined, EyeFilled, EyeTwoTone, PlusOutlined, RestOutlined, SaveOutlined} from '@ant-design/icons';
import {notifications, notificationScheduleOptions} from 'components/NotificationPage/helpers/constant';
import {isEmpty, isUndefined} from 'lodash';
import {RecordType} from 'types';
import {Button, Col, Form, FormInstance, Input, Popover, Row, Select} from 'ui-kit';
import {ReactComponent as ExclamationPoint} from '../../../../assets/images/coolicon.svg';
import styles from './NotificationMethodForms.module.scss';
import cn from 'classnames';
import {
  replaceEmailNotificationValueToPayload,
  replaceEmailPayloadToNotificationValue,
  setStylesForNotificationText,
} from 'modules/NotificationPage/helpers/utils';
import {useEffect, useMemo} from 'react';

const {TextArea} = Input;

interface EmailFormPropsType {
  formEmail: FormInstance<RecordType>;
  openCollapseEmail: string | string[];
  isActiveEmail: boolean;
  renderPopoverContent: JSX.Element;
  setIsVisibleModalForPhone: React.Dispatch<React.SetStateAction<boolean>>;
  setTestKey: React.Dispatch<React.SetStateAction<string>>;
  editMethodNotificationsEmailHandler: () => void;
  setIsActiveEmail: React.Dispatch<React.SetStateAction<boolean>>;
  status: string;
  setMethodNotificationsEmail: React.Dispatch<React.SetStateAction<RecordType>>;
  success: () => void;
  templateTypeEmail?: RecordType;
}

const EmailForm = ({
  formEmail,
  openCollapseEmail,
  isActiveEmail,
  renderPopoverContent,
  setIsVisibleModalForPhone,
  setTestKey,
  editMethodNotificationsEmailHandler,
  setIsActiveEmail,
  setMethodNotificationsEmail,
  status,
  success,
  templateTypeEmail,
}: EmailFormPropsType) => {
  const clearTemplateEmailHandler = () => {
    formEmail.resetFields(['scheduleEmail', 'emails', 'textEmail', 'subjectEmail']);
    formEmail.setFieldValue('emails', [null]);
    setMethodNotificationsEmail({});
    setIsActiveEmail(false);
  };

  const initialValuesEmail = useMemo(
    () => ({
      emails:
        status === 'edit' && !isEmpty(templateTypeEmail?.transportParams)
          ? templateTypeEmail?.transportParams?.email?.split(',')
          : !isUndefined(formEmail.getFieldValue('emails'))
            ? formEmail.getFieldValue('emails')
            : [null],
      scheduleEmail:
        status === 'edit' && templateTypeEmail?.transportType === 'EMAIL' && !isEmpty(templateTypeEmail.transportParams)
          ? templateTypeEmail?.scheduleType
          : formEmail.getFieldValue('scheduleEmail'),
      textEmail:
        status === 'edit' && templateTypeEmail?.transportType === 'EMAIL' && !isEmpty(templateTypeEmail.transportParams)
          ? replaceEmailPayloadToNotificationValue(templateTypeEmail.body)
          : formEmail.getFieldValue('textEmail'),
      subjectEmail:
        status === 'edit' && templateTypeEmail?.transportType === 'EMAIL' && !isEmpty(templateTypeEmail.transportParams)
          ? templateTypeEmail?.transportParams.topic
          : formEmail.getFieldValue('subjectEmail'),
    }),
    [
      formEmail,
      status,
      templateTypeEmail?.body,
      templateTypeEmail?.scheduleType,
      templateTypeEmail?.transportParams,
      templateTypeEmail?.transportType,
    ]
  );
  const handleActiveChangeEmail = () => {
    setIsActiveEmail((prev) => !prev);
    const {emails, subjectEmail, scheduleEmail, textEmail} = formEmail.getFieldsValue();
    const email = emails?.toString();
    const text = textEmail ? textEmail : initialValuesEmail.textEmail;
    const body = setStylesForNotificationText(replaceEmailNotificationValueToPayload(text));

    if (status === 'edit') {
      setMethodNotificationsEmail({
        body,
        scheduleType: scheduleEmail ? scheduleEmail : initialValuesEmail.scheduleEmail,
        transportParams: {
          email,
          topic: subjectEmail ? subjectEmail : initialValuesEmail.subjectEmail,
        },
        transportType: notifications.email,
        methodUpdateUuid: templateTypeEmail?.uuid,
      });
    } else {
      setMethodNotificationsEmail({
        body,
        scheduleType: scheduleEmail,
        transportParams: {
          email,
          topic: subjectEmail,
        },
        transportType: notifications.email,
      });
    }
    success();
  };

  useEffect(() => {
    formEmail.setFieldsValue(initialValuesEmail);
  }, [formEmail, initialValuesEmail, templateTypeEmail]);

  return (
    <>
      <Form
        onFinish={!isActiveEmail ? handleActiveChangeEmail : editMethodNotificationsEmailHandler}
        name="notificationFormEmail"
        layout="vertical"
        form={formEmail}
        scrollToFirstError
      >
        <Row gutter={16}>
          <Col xs={24} lg={12}>
            <Form.List name="emails">
              {(fields, {add, remove}) => {
                return (
                  <>
                    {fields.map((field, index) => {
                      const message =
                        index === 0 ? notifications.rulesMessage : 'Необходимо удалить или заполнить поле';
                      return (
                        <div key={field.key} className={styles.itemField}>
                          <Form.Item
                            {...field}
                            label={index === 0 ? notifications.emails : ''}
                            rules={[
                              {
                                required: !isEmpty(openCollapseEmail) && true,
                                message,
                              },
                              {type: 'email', message: 'Введенный email некорректный!'},
                            ]}
                            className={cn([styles.formItem])}
                          >
                            <Input
                              placeholder={notifications.emails}
                              disabled={(isEmpty(openCollapseEmail) || isActiveEmail) && true}
                            />
                          </Form.Item>
                          {!isActiveEmail && index !== 0 ? (
                            <Button
                              className={styles.deleteIcon}
                              disabled={isActiveEmail}
                              onClick={() => {
                                remove(field.name);
                              }}
                              icon={<DeleteOutlined />}
                            />
                          ) : null}
                        </div>
                      );
                    })}
                    {!isActiveEmail && (
                      <Form.Item>
                        <Button
                          type="link"
                          disabled={isActiveEmail}
                          onClick={() => {
                            add();
                          }}
                          icon={<PlusOutlined />}
                        >
                          {notifications.addEmail}
                        </Button>
                      </Form.Item>
                    )}
                  </>
                );
              }}
            </Form.List>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item
              label={notifications.schedule}
              name="scheduleEmail"
              rules={[
                {
                  required: !isEmpty(openCollapseEmail) && true,
                  message: notifications.rulesMessage,
                },
              ]}
            >
              <Select
                options={notificationScheduleOptions}
                placeholder={notifications.selectSchedule}
                disabled={(isEmpty(openCollapseEmail) || isActiveEmail) && true}
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          label={notifications.subject}
          rules={[
            {
              required: !isEmpty(openCollapseEmail) && true,
              message: notifications.rulesMessage,
            },
          ]}
          name="subjectEmail"
        >
          <Input type="string" disabled={(isEmpty(openCollapseEmail) || isActiveEmail) && true} />
        </Form.Item>
        <Form.Item
          label={
            <div>
              <span>{notifications.text}</span>{' '}
              <Popover content={renderPopoverContent}>
                <ExclamationPoint />
              </Popover>
            </div>
          }
          name="textEmail"
          rules={[
            {
              required: !isEmpty(openCollapseEmail) && true,
              message: notifications.rulesMessage,
            },
          ]}
        >
          <TextArea rows={4} disabled={(isEmpty(openCollapseEmail) || isActiveEmail) && true} />
        </Form.Item>
        <div className="d-flex justify-content-end">
          <Button icon={<RestOutlined />} className="mr-3" onClick={clearTemplateEmailHandler}>
            {notifications.fresh}
          </Button>
          <Form.Item shouldUpdate={(prevState, nextState) => prevState?.textEmail != nextState?.textEmail}>
            {({getFieldValue}) => {
              const textEmail = getFieldValue('textEmail');
              return (
                <Button
                  className="mr-3"
                  icon={!textEmail ? <EyeFilled /> : <EyeTwoTone />}
                  hidden={!textEmail}
                  onClick={() => {
                    setIsVisibleModalForPhone(true);
                    setTestKey('view');
                  }}
                >
                  {notifications.preview}
                </Button>
              );
            }}
          </Form.Item>
          <Button icon={<SaveOutlined />} type="primary" htmlType="submit" ghost>
            {!isActiveEmail ? notifications.save : notifications.edit}
          </Button>
        </div>
      </Form>
    </>
  );
};
export default EmailForm;
