import {SET_ACTIVE_MENU_ITEM, SET_BREADCRUMB_NAMES, SET_MENU_ITEMS} from '../actions/actionTypes';

const initialState = {
  breadcrumbNames: [],
  activeMenuItem: '',
  items: [],
  pageTitle: {},
};

export const navigateReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_BREADCRUMB_NAMES:
      return {
        ...state,
        breadcrumbNames: action.payload,
      };
    case SET_ACTIVE_MENU_ITEM:
      return {
        ...state,
        activeMenuItem: action.payload,
      };
    case SET_MENU_ITEMS:
      return {
        ...state,
        items: action.payload,
      };
    default:
      return state;
  }
};
