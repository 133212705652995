import {IRoute} from 'types';

export const createBreadcrumbNameMap = (items: IRoute[]) => {
  const toggleList: any[] = [];
  items.forEach((item) => {
    if (item.navLink) {
      toggleList.push({id: item.id, route: item.navLink, parent: item.parent});
    }
    // if (item.items && item.items.length) {
    //   toggleList = [...toggleList, ...createBreadcrumbNameMap(item.items, item.id)];
    // }
  });
  return toggleList;
};
