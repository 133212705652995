import {fetchFunc} from '../../../Utils/security/http/mdm';
import {SET_DOCUMENT_SIGNS} from './actionTypes';
import {algorithmAlias} from '../helpers/constants';
import {infoModalCreate} from '../../Helpers/Modals';
import {saveTextToFile} from '../../Helpers/Utils';

export const getSignData = ({docUuid, docTypeIdentifier, mode}, uuidFile) => {
  return async (dispatch) => {
    const fetchData = await fetchFunc({
      url: uuidFile
        ? `/api/v1/catalogs/${docTypeIdentifier}/items/${docUuid}/file/${uuidFile}/sign`
        : `/api/v1/catalogs/${docTypeIdentifier}/items/${docUuid}/sign`,
      method: 'get',
    });
    dispatch(setDocumentSigns(fetchData));
  };
};

export const getSignDigest = async ({docUuid, docTypeIdentifier, mode}, fileUuid = null, filesToSign = null) => {
  return await fetchFunc({
    url: fileUuid
      ? `/api/v1/catalogs/${docTypeIdentifier}/items/${docUuid}/file/${fileUuid}/hash?algorithmAlias=${algorithmAlias}`
      : `/api/v1/catalogs/${docTypeIdentifier}/items/${docUuid}/sign/digest?showDetails=3&showRefs=0&algorithmAlias=${algorithmAlias}`,
    method: fileUuid ? 'get' : 'post',
    data: fileUuid ? null : filesToSign,
  });
};

export const saveSignData = ({docUuid, docTypeIdentifier, mode}, uuidSign, fileName) => {
  return async (dispatch) => {
    const fetchData = await fetchFunc({
      url: `/api/v1/catalogs/${docTypeIdentifier}/items/${docUuid}/sign/${uuidSign}/digest`,
      method: 'get',
    });
    saveTextToFile(fetchData.digest, uuidSign, 'sig');
  };
};

export const sendSignData = ({docUuid, docTypeIdentifier, mode}, uuid, signature, hash, fileUuid) => {
  return async (dispatch) => {
    let data;
    if (fileUuid) {
      data = [
        {
          algorithmAlias: algorithmAlias,
          hash,
          signAlgorithm: algorithmAlias,
          signData: signature,
        },
      ];
    } else {
      data = {
        signData: signature,
      };
    }
    try {
      await fetchFunc({
        url: fileUuid
          ? `/api/v1/catalogs/${docTypeIdentifier}/items/${docUuid}/file/${fileUuid}/sign`
          : `/api/v1/catalogs/${docTypeIdentifier}/items/${docUuid}/sign/${uuid}?signAlgorithm=${algorithmAlias}`,
        method: 'post',
        data,
      });
      dispatch(getSignData({docUuid, docTypeIdentifier, mode}, fileUuid));
      infoModalCreate({
        title: 'Информация о подписи',
        content: `Успешно подписано`,
      });
    } catch (error) {}
  };
};

export const checkSign = ({docUuid, docTypeIdentifier, mode}, uuidSign, uuidFile = null) => {
  return async (dispatch) => {
    const fetchData = await fetchFunc({
      url: uuidFile
        ? `/api/v1/catalogs/${docTypeIdentifier}/items/${docUuid}/file/${uuidFile}/sign/${uuidSign}/check`
        : `/api/v1/catalogs/${docTypeIdentifier}/items/${docUuid}/sign/${uuidSign}/check`,
      method: 'put',
    });
    infoModalCreate({
      title: 'Результат проверки подписи',
      content: !fetchData?.validSign ? fetchData?.error : 'Текущая подпись валидна',
    });
  };
};

export const deleteSign = ({docUuid, docTypeIdentifier, mode}, uuidSign, uuidFile = null) => {
  return async (dispatch) => {
    await fetchFunc({
      url: uuidFile
        ? `/api/v1/catalogs/${docTypeIdentifier}/items/${docUuid}/file/${uuidFile}/sign/${uuidSign}`
        : `/api/v1/catalogs/${docTypeIdentifier}/items/${docUuid}/sign/${uuidSign}`,
      method: 'delete',
    });
    dispatch(getSignData({docUuid, docTypeIdentifier, mode}, uuidFile));
  };
};

export const setDocumentSigns = (values) => {
  return {
    type: SET_DOCUMENT_SIGNS,
    payload: values,
  };
};
