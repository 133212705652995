import React, {useEffect, useState} from 'react';
import {Collapse, Alert, Typography, Popover} from 'ui-kit';
import {get, isEmpty, isEqual} from 'lodash';
import {renderVerifierFio, renderStatus, getAuthorFio} from '../Helpers/Utils';
import {Link, useRouteMatch} from 'react-router-dom';
import dayjs from 'dayjs';
import {ExclamationCircleOutlined} from '@ant-design/icons';
import styles from './SystemInformation.module.scss';
import classNames from 'classnames';
import {RecordType} from 'types';
const {Panel} = Collapse;

const getCardUrl = (referenceOrigin: string, recordUuid: string) => {
  if (referenceOrigin === 'organisations') {
    return `/${referenceOrigin}/${recordUuid}`;
  }
  return `/catalog/${referenceOrigin}/${recordUuid}`;
};

export interface SystemInformationProps {
  statusDate?: string;
  data: {
    uuid: string;
    version: string;
    createDate: string;
    dateFrom: string;
    authorFio: boolean;
    sysVerifyInfo: {
      changeType: 'add' | 'modify' | 'delete';
      sourceId: {
        version: string;
      };
      status: string;
    };
    sysVerifyInfoDraft: boolean;
    id: boolean;
    processInstanceId: boolean;
    regNumber: boolean;
    versionNumber: boolean;
  };
  recordUuid: string;
  referenceOriginLocal: string;
  validateCatalog?: boolean;
  businessVersions?: boolean;
  onVersionOpen?: () => void;
}

const SystemInformation = ({
  statusDate,
  data,
  recordUuid,
  referenceOriginLocal,
  validateCatalog,
  businessVersions,
  onVersionOpen,
}: SystemInformationProps) => {
  const {
    uuid,
    version,
    createDate,
    dateFrom,
    authorFio,
    sysVerifyInfo,
    sysVerifyInfoDraft,
    id,
    processInstanceId,
    regNumber,
  } = data;

  const [postAuthor, setPostAuthor] = useState('');

  const match: any = useRouteMatch();
  const [filteredMatch, setFilteredMatch] = useState(match);
  const {params} = match;
  const scope = (params as any)?.scope;

  useEffect(() => {
    const {params} = match;
    const {tab, ...filteredParams} = params;

    if (!isEqual(filteredParams, params)) setFilteredMatch({...match, params: filteredParams});
  }, [match]);

  useEffect(() => {
    const changeType = sysVerifyInfo?.changeType;
    if (!isEmpty(data) && scope !== 'verifier-list' && changeType !== 'add') {
      (async () => {
        const {authorFio} = await getAuthorFio({uuid: recordUuid, match: filteredMatch});
        setPostAuthor(authorFio);
      })();
    }
  }, [data, recordUuid, scope, sysVerifyInfo?.changeType, filteredMatch]);

  const renderSystemDate = (data: string | RecordType, path = '') => {
    const date = path ? get(data, path) : data;

    return date ? (
      <>
        {dayjs(date).tz().format('YYYY-MM-DD HH:mm')}
        <Popover content="Московское время (UTC+3)">
          <ExclamationCircleOutlined className="exclamationIcon" />
        </Popover>
      </>
    ) : (
      '-'
    );
  };

  return (
    <Collapse className="mb-0">
      <Panel header="Системная информация" key="1">
        <div className="mb-4">
          <div className="d-flex mb-1">
            <div className="col-6">
              <Typography.Text type="secondary">UUID:</Typography.Text> {uuid}
            </div>
            <div className="col-6">
              <Typography.Text type="secondary">Версия:</Typography.Text>{' '}
              {version || (sysVerifyInfo && sysVerifyInfo?.sourceId && sysVerifyInfo?.sourceId?.version)}
            </div>
          </div>
          <div className="d-flex mb-1">
            <div className="col-6">
              <Typography.Text type="secondary">Дата создания:</Typography.Text>{' '}
              <span className="d-inline-flex gap-8">{createDate ? renderSystemDate(createDate) : '-'}</span>
            </div>
            <div className="col-6">
              <Typography.Text type="secondary">Дата изменения:</Typography.Text>{' '}
              <span className="d-inline-flex gap-8">{createDate ? renderSystemDate(dateFrom) : '-'}</span>
            </div>
          </div>
          {referenceOriginLocal === 'service' && (
            <div className="d-flex mb-1">
              <div className="col-6">
                <Typography.Text type="secondary">Дата присвоения статуса:</Typography.Text> {statusDate || '-'}
              </div>
              <div className="col-6">
                <Typography.Text type="secondary">
                  Идентификатор экземпляра <br /> процесса согласования:
                </Typography.Text>{' '}
                {processInstanceId || '-'}
              </div>
            </div>
          )}
          <div className="d-flex mb-1">
            <div className="col-6">
              <Typography.Text type="secondary">Автор изменений:</Typography.Text> {authorFio}
            </div>
            <div className="col-6">
              <Typography.Text type="secondary">Идентификатор:</Typography.Text> {id}
            </div>
          </div>
          <div className="d-flex mb-1">
            {scope !== 'verifier-list' && (
              <div className="col-6">
                <Typography.Text type="secondary">Автор записи:</Typography.Text> {postAuthor}
              </div>
            )}
            {referenceOriginLocal === 'service' && (
              <div className="col-6">
                <Typography.Text type="secondary">Реестровый номер:</Typography.Text> {regNumber}
              </div>
            )}
          </div>
          {businessVersions && (
            <>
              <div className="d-flex mb-1">
                <div className="col-6">
                  <Typography.Text type="secondary">Текущая версия:</Typography.Text> {data?.versionNumber}
                </div>
              </div>
              <div className="d-flex mb-1">
                <div className="col-6">
                  <span className="link" onClick={onVersionOpen}>
                    Последняя действующая версия
                  </span>
                </div>
              </div>
            </>
          )}
          {validateCatalog && (
            <>
              <div className={classNames('d-flex mb-1 mt-4', styles['ml-15px'])}>
                <Typography.Title level={5}>Сведения о верификации</Typography.Title>
              </div>
              {sysVerifyInfoDraft && (
                <div className={classNames('d-flex mb-1', styles['ml-15px'])}>
                  <Alert
                    message={
                      <div>
                        Для записи справочника существует версия, ещё не прошедшая верификацию.{' '}
                        <Link to={`/catalog/${referenceOriginLocal}/${recordUuid}/verify`}>Перейти</Link>
                      </div>
                    }
                    type="info"
                    className="mb-2"
                    showIcon={true}
                  />
                </div>
              )}
              {sysVerifyInfo &&
                (sysVerifyInfo.changeType === 'modify' || sysVerifyInfo.changeType === 'delete') &&
                sysVerifyInfo.status !== 'approved' && (
                  <div className={classNames('d-flex mb-1', styles['ml-15px'])}>
                    <Alert
                      message={
                        <div>
                          Для записи справочника существует действующая версия.{' '}
                          <Link to={getCardUrl(referenceOriginLocal, recordUuid)}>Перейти</Link>
                        </div>
                      }
                      type="info"
                      className="mb-2"
                      showIcon={true}
                    />
                  </div>
                )}
              <div className={classNames('w-100 grid-2 gap-24', styles['ml-15px'])}>
                <div className="d-flex mb-1">
                  <div>
                    <Typography.Text type="secondary">Статус:</Typography.Text>{' '}
                    {renderStatus(data, sysVerifyInfo ? 'sysVerifyInfo.status' : 'sysVerifyInfoDraft.status')}
                  </div>
                </div>

                <div className="d-flex mb-1">
                  <div>
                    <Typography.Text type="secondary">Верификатор:</Typography.Text>{' '}
                    {renderVerifierFio(sysVerifyInfo ? 'sysVerifyInfo.verifier' : 'sysVerifyInfoDraft.verifier', data)}
                  </div>
                </div>
                <div className="d-flex mb-1">
                  <div>
                    <Typography.Text type="secondary">Дата начала верификации:</Typography.Text>{' '}
                    <span className="d-inline-flex gap-8">
                      {renderSystemDate(data, sysVerifyInfo ? 'sysVerifyInfo.dateFrom' : 'sysVerifyInfoDraft.dateFrom')}
                    </span>
                  </div>
                </div>
                <div className="d-flex mb-1">
                  <div>
                    <Typography.Text type="secondary">Дата окончания верификации:</Typography.Text>{' '}
                    <span className="d-inline-flex gap-8">
                      {renderSystemDate(data, sysVerifyInfo ? 'sysVerifyInfo.dateTo' : 'sysVerifyInfoDraft.dateTo')}
                    </span>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </Panel>
    </Collapse>
  );
};

export default SystemInformation;
