import {
  ADD_VERIFY_VALUE,
  SET_ALL_VERIFY_VALUE,
  SET_CHANGED_DETAIL_NAME,
  SET_CHANGED_DETAIL_FIELD,
  SET_CHANGED_DETAIL_MODE,
  SET_CONTEXT_SEARCH_VALUE,
  SET_FILTER_SEARCH_VALUE,
  SET_HISTORY_SEARCH_VALUE,
  SET_FILTER_RESET,
  SET_FILTER_ERROR,
  SET_RECORD_LOADING,
  SET_MENU_ACCESS,
  SET_MENU_ITEMS,
  SET_BREADCRUMB_NAMES,
  SET_ACTIVE_MENU_ITEM,
  SET_CATALOG_EDIT_CAPTION,
  ADD_DEDUPLICATION_PROTOCOL,
  SET_DEDUPLICATION_FORM_PROTOCOL,
  SET_DEDUPLICATION_JOB_PROTOCOL,
  ADD_RULES_DEDUPLICATION,
  ADD_ALGORITHM_DEDUPLICATION,
  ADD_DEGREE_PROXIMITY_DEDUPLICATION,
  ADD_START_DEDUPLICATION_PROTOCOL,
  SET_DEDUPLICATION_JOB_PROTOCOL_INFO_DELETE,
  SET_DEDUPLICATION_PROTOCOLS,
  EDIT_JOB_DEDUPLICATION_PROTOCOL,
  SET_DEDUPLICATION_PROTOCOL_INFO,
  SET_DEDUPLICATION_GROUP_PROTOCOL_INFO,
  RESET_RULES_DEDUPLICATION,
  SET_DEDUPLICATION_BLOCK_LINK_GROUP,
  SET_DEDUPLICATION_SET_LINK_GROUP,
  SET_DEDUPLICATION_BLOCK_LINK_FULL_GROUP,
  SET_SYSTEM_INFO_PROTOCOL,
  SET_DEDUPLICATION_CANCEL_LINK_GROUP,
  SET_DEDUPLICATION_PROTOCOLS_DELETE,
  RESET_DEDUPLICATION_PROTOCOL_INFO,
  RESET_DEDUPLICATION_GROUP_PROTOCOL_INFO,
  COLOR,
  SET_NOTIFICATION_LIST,
  ADD_NOTIFICATION,
  ADD_NOTIFICATION_TEMPLATE,
  SET_NOTIFICATION_TEMPLATE_LIST,
  DELETE_NOTIFICATION_TEMPLATE,
  SET_NOTIFICATION_TEMPLATE,
  EDIT_NOTIFICATION_TEMPLATE,
  DELETE_NOTIFICATION,
  SET_NOTIFICATION_ACTIVATE,
  SET_NOTIFICATION_DEACTIVATE,
  SET_NOTIFICATION_ID,
  CAPTION,
  TEST_NOTIFICATION,
  SEND_SUCCESS_MESSAGE,
  SET_FILTERED_SUBSCRIPTION,
  SET_NOTIFICATION_SUBSCRIPTION_LIST,
  SET_RECORD_ID,
  SET_REFERENCE_CATALOG_PATTERN_ORIGIN,
  RESET_NOTIFICATION_TEMPLATE,
  SET_SCHEDULE_EXECUTION,
  GET_USER_NAME,
  GET_HELP_INFO,
  SET_DATA_OPTIONS,
  SET_USER,
  SET_SUBMIT_REQUEST,
  SET_USER_ROLES,
  LOADING_JOBS_DEDUPLICATION,
  LOADING_PROTOCOLS_DEDUPLICATION,
  LOADING_NOTIFICATION_LIST,
  LOADING_NOTIFICATION_TEMPLATES,
  SET_CURRENT_CATALOG,
  SET_DATA_OPTIONS_RESET,
  GET_FIELDS_TYPE,
  SET_EMAILS_FOR_TEST,
  SET_PHONE_NUMBER_FOR_TEST,
  SET_VALIDATOR_LENGTH,
  SET_NUMERIC_COMPARISON,
  SET_DEDUPLICATION_UNARCHIVED_PROTOCOLS,
  LOADING_PROTOCOLS_UNARCHIVED_DEDUPLICATION,
  GET_USERS_ROLES,
  SET_PAGE_HEADER,
  SET_PAGE_HEADER_BUTTONS,
  SET_PAGE_HEADER_TABS,
  SET_PAGE_HEADER_ACTIVE_TAB,
  SET_PAGE_HEADER_TAG,
  SET_PAGE_HEADER_LINES_BLOCK,
  SET_PAGE_HEADER_BACK,
  SET_PAGE_HEADER_SHOW_DEFAULT_BACK_BUTTON,
  SET_FILTER_ERROR_KEY_ATTRIBUTE,
  SET_PAGE_HEADER_BREADCRUMBS_TITLE,
  SET_PAGE_HEADER_LOADING,
  SET_COMPARED_VERSIONS,
} from './actionTypes';

import {fetchFunc} from '../../Utils/security/http/mdm';
import {PageHeaderState} from 'redux/types/rootState';
import {ButtonProps, TagProps} from 'antd';
import {IRoute} from 'types';

export const getMenuAccess = () => {
  return async (dispatch: any) => {
    const fetchData = await fetchFunc({
      url: `/api/v1/catalogs/menu/fact/access`,
      method: 'get',
    });
    dispatch(setMenuAccess(fetchData));
  };
};
export const getUserNameAC = (value: any) => {
  return {
    type: GET_USER_NAME,
    payload: value,
  };
};
export const setValidatorLengthAC = (value: any) => {
  return {
    type: SET_VALIDATOR_LENGTH,
    payload: value,
  };
};
export const setNumericComparisonAC = (value: any) => {
  return {
    type: SET_NUMERIC_COMPARISON,
    payload: value,
  };
};
export const getHelpInfoAC = (value: any) => {
  return {
    type: GET_HELP_INFO,
    payload: value,
  };
};
export const setMenuAccess = (roles: any) => {
  return {
    type: SET_MENU_ACCESS,
    payload: roles,
  };
};

export const setCurrentCatalog = (payload: any) => ({
  type: SET_CURRENT_CATALOG,
  payload,
});

export const setCatalogEditCaption = (value: any) => {
  return {
    type: SET_CATALOG_EDIT_CAPTION,
    payload: value,
  };
};

export const setActiveMenuItem = (payload: any) => {
  return {
    type: SET_ACTIVE_MENU_ITEM,
    payload: payload,
  };
};

export const setPageHeader = (payload: PageHeaderState) => {
  return {
    type: SET_PAGE_HEADER,
    payload: payload,
  };
};

export const setPageHeaderTag = (payload: TagProps) => {
  return {
    type: SET_PAGE_HEADER_TAG,
    payload: payload,
  };
};

export const setPageHeaderLinesBlock = (payload: {label: string; value: string}[]) => {
  return {
    type: SET_PAGE_HEADER_LINES_BLOCK,
    payload: payload,
  };
};

export const setBreadCrumbsTitle = (payload: string[] | string) => {
  return {
    type: SET_PAGE_HEADER_BREADCRUMBS_TITLE,
    payload: payload,
  };
};

export const setPageHeaderLoading = (payload: boolean) => {
  return {
    type: SET_PAGE_HEADER_LOADING,
    payload: payload,
  };
};
export const setPageHeaderButtons = (payload: ButtonProps[]) => {
  return {
    type: SET_PAGE_HEADER_BUTTONS,
    payload: payload,
  };
};

export const setPageHeaderTabs = (payload: {
  items: {key: string; label: string; hide?: boolean}[];
  activeTab: string;
  hide?: boolean;
}) => {
  return {
    type: SET_PAGE_HEADER_TABS,
    payload: payload,
  };
};

export const setPageHeaderActiveTab = (payload: string) => {
  return {
    type: SET_PAGE_HEADER_ACTIVE_TAB,
    payload: payload,
  };
};

export const setPageHeaderBack = (payload: string | undefined) => {
  return {
    type: SET_PAGE_HEADER_BACK,
    payload: payload,
  };
};

export const setPageHeaderShowBackButton = (payload: boolean) => {
  return {
    type: SET_PAGE_HEADER_SHOW_DEFAULT_BACK_BUTTON,
    payload: payload,
  };
};

export const setDataForOptionsTypeSelect = (values: any[]) => {
  return {
    type: SET_DATA_OPTIONS,
    payload: values,
  };
};
export const setBreadcrumbsNames = (payload: any[]) => {
  return {
    type: SET_BREADCRUMB_NAMES,
    payload: payload,
  };
};

export const setMenuItems = (payload: IRoute[]) => {
  return {
    type: SET_MENU_ITEMS,
    payload: payload,
  };
};

export const addVerify = (name: any, value: any) => {
  return {
    type: ADD_VERIFY_VALUE,
    payload: {
      name,
      value,
    },
  };
};

export const setVerify = (values: any) => {
  return {
    type: SET_ALL_VERIFY_VALUE,
    payload: values,
  };
};

export const setChangedDetailName = (values: any) => {
  return {
    type: SET_CHANGED_DETAIL_NAME,
    payload: values,
  };
};

export const setChangedDetailField = (values: any) => {
  return {
    type: SET_CHANGED_DETAIL_FIELD,
    payload: values,
  };
};

export const setReferenceCatalogPatternOriginAC = (value: any) => {
  return {
    type: SET_REFERENCE_CATALOG_PATTERN_ORIGIN,
    payload: value,
  };
};

export const setNotificationEmailsForTestAC = (emails: any) => {
  return {
    type: SET_EMAILS_FOR_TEST,
    payload: emails,
  };
};

export const setNotificationPhoneNumberForTestAC = (phoneNumbers: any) => {
  return {
    type: SET_PHONE_NUMBER_FOR_TEST,
    payload: phoneNumbers,
  };
};

export const setChangedDetailMode = (values: string) => {
  return {
    type: SET_CHANGED_DETAIL_MODE,
    payload: values,
  };
};
export const searchScheduleExecutionAC = (value: any) => {
  return {
    type: SET_SCHEDULE_EXECUTION,
    payload: value,
  };
};
export const getUsersAC = (user: any) => {
  return {
    type: SET_USER,
    payload: user,
  };
};
export const getFieldsTypeAC = (types: any) => {
  return {
    type: GET_FIELDS_TYPE,
    payload: types,
  };
};
export const getUsersRolesAC = (roles: any) => {
  return {
    type: GET_USERS_ROLES,
    payload: roles,
  };
};
export const setUserRoles = (roles: any) => {
  return {
    type: SET_USER_ROLES,
    payload: roles,
  };
};
export const setContextSearchValue = (value: string | null) => {
  return {
    type: SET_CONTEXT_SEARCH_VALUE,
    payload: value,
  };
};
export const setFilterSearchValue = (values: {[x: number]: never[]} | null) => {
  return {
    type: SET_FILTER_SEARCH_VALUE,
    payload: values,
  };
};
export const setHistorySearchValue = (
  values: {
    '%history_filter':
      | {attributes: any; dateFrom: string; dateTo: string}
      | {attributes: any; dateFrom: string; dateTo: string};
  } | null
) => {
  return {
    type: SET_HISTORY_SEARCH_VALUE,
    payload: values,
  };
};
export const setFilterReset = (flag: boolean) => {
  return {
    type: SET_FILTER_RESET,
    payload: flag,
  };
};

export const setDataOptionsReset = (flag: undefined) => {
  return {
    type: SET_DATA_OPTIONS_RESET,
    payload: flag,
  };
};
export const setFilterError = (flag: boolean) => {
  return {
    type: SET_FILTER_ERROR,
    payload: flag,
  };
};

export const setRecordLoading = (flag: boolean) => {
  return {
    type: SET_RECORD_LOADING,
    payload: flag,
  };
};

export const addDeduplicationProtocolAC = (value: any) => {
  return {
    type: ADD_DEDUPLICATION_PROTOCOL,
    payload: value,
  };
};

export const setFilterErrorKeyAttribute = (flag: any) => {
  return {
    type: SET_FILTER_ERROR_KEY_ATTRIBUTE,
    payload: flag,
  };
};

export const addStartDeduplicationProtocolAC = (value: any, uuid: any) => {
  return {
    type: ADD_START_DEDUPLICATION_PROTOCOL,
    payload: {
      value,
      uuid,
    },
  };
};

export const editJobDeduplicationAC = (value: any, uuid: any) => {
  return {
    type: EDIT_JOB_DEDUPLICATION_PROTOCOL,
    payload: {
      value,
      uuid,
    },
  };
};

export const getDeduplicationFormProtocolAC = (values: any) => {
  return {
    type: SET_DEDUPLICATION_FORM_PROTOCOL,
    payload: values,
  };
};

export const getDeduplicationJobProtocolAC = (values: any) => {
  return {
    type: SET_DEDUPLICATION_JOB_PROTOCOL,
    payload: values,
  };
};

export const getDeduplicationProtocolsAC = (values: any) => {
  return {
    type: SET_DEDUPLICATION_PROTOCOLS,
    payload: values,
  };
};

export const getDeduplicationUnarchivedProtocolsAC = (values: any) => {
  return {
    type: SET_DEDUPLICATION_UNARCHIVED_PROTOCOLS,
    payload: values,
  };
};

export const getDeduplicationProtocolDeleteAC = (value: any, protocolId: any) => {
  return {
    type: SET_DEDUPLICATION_PROTOCOLS_DELETE,
    payload: {value, protocolId},
  };
};
export const addRulesDeduplication = (values: never[]) => {
  return {
    type: ADD_RULES_DEDUPLICATION,
    payload: values,
  };
};

export const resetRulesDeduplication = (values: undefined) => {
  return {
    type: RESET_RULES_DEDUPLICATION,
    payload: values,
  };
};

export const addAlgorithmDeduplication = (value: any, comboBoxOptions: any, rulesDeduplication: any, key: any) => {
  return {
    type: ADD_ALGORITHM_DEDUPLICATION,
    payload: {
      value,
      comboBoxOptions,
      rulesDeduplication,
      key,
    },
  };
};

export const addDegreeProximityDeduplication = (value: any, key: any) => {
  return {
    type: ADD_DEGREE_PROXIMITY_DEDUPLICATION,
    payload: {
      value,
      key,
    },
  };
};
export const loadingJobsAC = (value: boolean) => {
  return {
    type: LOADING_JOBS_DEDUPLICATION,
    payload: value,
  };
};
export const loadingProtocolsAC = (value: boolean | undefined) => {
  return {
    type: LOADING_PROTOCOLS_DEDUPLICATION,
    payload: value,
  };
};
export const loadingProtocolsUnarchivedAC = (value: boolean | undefined) => {
  return {
    type: LOADING_PROTOCOLS_UNARCHIVED_DEDUPLICATION,
    payload: value,
  };
};
export const loadingNotificationListAC = (value: boolean | undefined) => {
  return {
    type: LOADING_NOTIFICATION_LIST,
    payload: value,
  };
};
export const loadingPNotificationTemplatesAC = (value: boolean | undefined) => {
  return {
    type: LOADING_NOTIFICATION_TEMPLATES,
    payload: value,
  };
};
export const getSubmitRequestAC = (value: boolean) => {
  return {
    type: SET_SUBMIT_REQUEST,
    payload: value,
  };
};
export const getDeduplicationProtocolInfoAC = (values: any, protocolId: any) => {
  return {
    type: SET_DEDUPLICATION_PROTOCOL_INFO,
    payload: {values, protocolId},
  };
};
export const getDeduplicationGroupProtocolInfoAC = (value: any, groupId: any) => {
  return {
    type: SET_DEDUPLICATION_GROUP_PROTOCOL_INFO,
    payload: {value, groupId},
  };
};

export const getDeduplicationJobProtocolInfoDeleteAC = (uuid: any) => {
  return {
    type: SET_DEDUPLICATION_JOB_PROTOCOL_INFO_DELETE,
    payload: uuid,
  };
};

export const getBlockLinkGroupAC = (value: any, groupId: any, itemGroupId: any) => {
  return {
    type: SET_DEDUPLICATION_BLOCK_LINK_GROUP,
    payload: {value, groupId, itemGroupId},
  };
};

export const getBlockLinkFullGroupAC = (value: any, groupId: any) => {
  return {
    type: SET_DEDUPLICATION_BLOCK_LINK_FULL_GROUP,
    payload: {value, groupId},
  };
};

export const getCancelLinkGroupAC = (value: any, groupId: any) => {
  return {
    type: SET_DEDUPLICATION_CANCEL_LINK_GROUP,
    payload: {value, groupId},
  };
};

export const getSetLinkGroupAC = (value: any, groupId: any) => {
  return {
    type: SET_DEDUPLICATION_SET_LINK_GROUP,
    payload: {value, groupId},
  };
};

export const setSystemInfoProtocolAC = (value: any) => {
  return {
    type: SET_SYSTEM_INFO_PROTOCOL,
    payload: value,
  };
};

export const resetDeduplicationProtocolInfo = () => {
  return {
    type: RESET_DEDUPLICATION_PROTOCOL_INFO,
  };
};

export const resetDeduplicationProtocolInfoGroup = () => {
  return {
    type: RESET_DEDUPLICATION_GROUP_PROTOCOL_INFO,
  };
};

export const color = (color: string) => {
  return {
    type: COLOR,
    payload: color,
  };
};
export const setCaption = (caption: any, value: any) => {
  return {
    type: CAPTION,
    payload: {caption, value},
  };
};
export const setRecordId = (value: any) => {
  return {
    type: SET_RECORD_ID,
    payload: value,
  };
};
export const deleteNotificationAC = (uuid: any) => {
  return {
    type: DELETE_NOTIFICATION,
    payload: uuid,
  };
};
export const getNotificationListAC = (values: any) => {
  return {
    type: SET_NOTIFICATION_LIST,
    payload: values,
  };
};
export const getNotificationIdAC = (uuid: any) => {
  return {
    type: SET_NOTIFICATION_ID,
    payload: uuid,
  };
};
export const setActivateNotificationAC = (value: any, notifyConditionId: any) => {
  return {
    type: SET_NOTIFICATION_ACTIVATE,
    payload: {value, notifyConditionId},
  };
};
export const setDeactivateNotificationAC = (value: any, notifyConditionId: any) => {
  return {
    type: SET_NOTIFICATION_DEACTIVATE,
    payload: {value, notifyConditionId},
  };
};
export const getNotificationTemplateListAC = (values: any) => {
  return {
    type: SET_NOTIFICATION_TEMPLATE_LIST,
    payload: values,
  };
};
export const deleteNotificationTemplateAC = (uuid: any) => {
  return {
    type: DELETE_NOTIFICATION_TEMPLATE,
    payload: uuid,
  };
};
export const getNotificationTemplateAC = (uuid: any) => {
  return {
    type: SET_NOTIFICATION_TEMPLATE,
    payload: uuid,
  };
};
export const resetNotificationTemplateAC = (value: any) => {
  return {
    type: RESET_NOTIFICATION_TEMPLATE,
    payload: value,
  };
};
export const addNotificationTemplateAC = (value: any) => {
  return {
    type: ADD_NOTIFICATION_TEMPLATE,
    payload: value,
  };
};
export const addNotificationAC = (value: any) => {
  return {
    type: ADD_NOTIFICATION,
    payload: value,
  };
};
export const editNotificationTemplateAC = (value: any, uuid: any) => {
  return {
    type: EDIT_NOTIFICATION_TEMPLATE,
    payload: {value, uuid},
  };
};
export const testNotificationAC = (value: undefined) => {
  return {
    type: TEST_NOTIFICATION,
    payload: value,
  };
};
export const sendSuccessSendMessageAC = (value: string) => {
  return {
    type: SEND_SUCCESS_MESSAGE,
    payload: value,
  };
};
export const getNotificationListSubscriptionAC = (values: any) => {
  return {
    type: SET_NOTIFICATION_SUBSCRIPTION_LIST,
    payload: values,
  };
};
export const getFilteredSubscriptionAC = (values: any) => {
  return {
    type: SET_FILTERED_SUBSCRIPTION,
    payload: values,
  };
};

export const setComparedVersionsAC = (values: any) => {
  return {
    type: SET_COMPARED_VERSIONS,
    payload: values,
  };
};
