import React, {ReactElement, useEffect} from 'react';
import {useHistory} from 'react-router';
import CatalogItemAddForm from './CatalogItemAddForm';

import styles from './CatalogItemAdd.module.scss';

import {usePageHeader} from 'hooks';

const CatalogItemAdd = (): ReactElement => {
  const history = useHistory();
  const {setHeaderTitle} = usePageHeader();
  useEffect(() => {
    setHeaderTitle('Создание справочника');
  }, [setHeaderTitle]);
  return (
    <div className="containerWhite">
      <div className={styles.add_body}>
        <CatalogItemAddForm history={history} showTitle={false} />
      </div>
    </div>
  );
};

export default CatalogItemAdd;
