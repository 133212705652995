export const cpeStructure = [
  {
    type: 'group',
    greed: 24,
    className: '',
    items: [
      {
        type: 'group',
        name: 'cpe',
        label: '',
        items: [
          {
            type: 'container',
            greed: 24,
            items: [
              {origin: 'recType', type: 'item', greed: 16},
              {origin: 'nonResidentRf', type: 'item', greed: 8},
            ],
          },
          {
            type: 'container',
            greed: 24,
            items: [
              {origin: 'ogrn', type: 'item', greed: 8},
              {origin: 'codeOgrnAnalog', type: 'item', greed: 8},
            ],
          },
          {
            type: 'container',
            greed: 24,
            items: [
              {origin: 'inn', type: 'item', greed: 8},
              {origin: 'innAnalog', type: 'item', greed: 8},
            ],
          },
          {type: 'container', greed: 24, items: [{origin: 'kpp', type: 'item', greed: 24}]},
          {type: 'container', greed: 24, items: [{origin: 'innDate', type: 'item', greed: 24}]},
          {type: 'container', greed: 24, items: [{origin: 'okopf', type: 'item', greed: 24}]},
          {type: 'container', greed: 24, items: [{origin: 'orgform', type: 'item', greed: 24}]},
          {type: 'container', greed: 24, items: [{origin: 'caption', type: 'item', greed: 24}]},
          {type: 'container', greed: 24, items: [{origin: 'description', type: 'item', greed: 24}]},
          {type: 'container', greed: 24, items: [{origin: 'captionEn', type: 'item', greed: 24}]},
          {type: 'container', greed: 24, items: [{origin: 'descriptionEn', type: 'item', greed: 24}]},
          {type: 'container', greed: 24, items: [{origin: 'registerDate', type: 'item', greed: 24}]},
          {type: 'container', greed: 24, items: [{origin: 'egrulDate', type: 'item', greed: 24}]},
          {type: 'container', greed: 24, items: [{origin: 'regAuthority', type: 'item', greed: 24}]},
          {
            type: 'group',
            name: 'exporter',
            label: '',
            items: [
              {origin: 'okved', type: 'item'},
              {origin: 'okveds', type: 'item'},
              {origin: 'tnveds', type: 'item'},
            ],
          },
          {type: 'container', greed: 24, items: [{origin: 'email', type: 'item', greed: 24}]},
          {type: 'container', greed: 24, items: [{origin: 'url', type: 'item', greed: 24}]},
          {type: 'container', greed: 24, items: [{origin: 'contactPersons', type: 'item', greed: 24}]},
          {type: 'container', greed: 24, items: [{origin: 'regCountry', type: 'item', greed: 24}]},
          {type: 'container', greed: 24, items: [{origin: 'extAddresses', type: 'item', greed: 24}]},
          {type: 'container', greed: 24, items: [{origin: 'activityStopped', type: 'item', greed: 24}]},
          {type: 'container', greed: 24, items: [{origin: 'separateDivision', type: 'item', greed: 24}]},
          {type: 'container', greed: 24, items: [{origin: 'terminationDate', type: 'item', greed: 24}]},
          {type: 'container', greed: 24, items: [{origin: 'terminationCode', type: 'item', greed: 24}]},
          {type: 'container', greed: 24, items: [{origin: 'terminationReason', type: 'item', greed: 24}]},
        ],
      },
    ],
  },
];
