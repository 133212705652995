import React from 'react';
import {Button} from 'ui-kit';
import {CloseOutlined, CheckOutlined, CheckCircleOutlined, CloseCircleOutlined} from '@ant-design/icons';
import {useSelector, useDispatch} from 'react-redux';

import {addVerify} from '../../../redux/actions/actions';

import style from './VerificationChecker.module.scss';
import {RootState} from 'redux/types/rootState';
import classNames from 'classnames';

export interface VerificationCheckerProps {
  name: any;
}

/** Подтверждения/отклонения записи
 * @name - origin поле */
const VerificationChecker = ({name}: VerificationCheckerProps) => {
  const initialValues = useSelector((state: RootState) => state?.verify.verifyValues);
  const dispatch = useDispatch();
  /** Сеттер подтверждения/отклонения записи */
  const handleRadioChange = (value: any) => {
    dispatch(addVerify(name, value));
  };

  const isHasOwnProperty = new Object(initialValues).hasOwnProperty(name);

  return (
    <div className={style.iconGroup}>
      <CheckCircleOutlined
        onClick={() => handleRadioChange(true)}
        className={classNames(style.iconGrey, {[style.iconPrimary]: isHasOwnProperty && initialValues[name] === true})}
      />
      <CloseCircleOutlined
        onClick={() => handleRadioChange(false)}
        className={classNames(style.iconGrey, {[style.iconError]: isHasOwnProperty && initialValues[name] === false})}
      />
    </div>
  );
};

export default VerificationChecker;
