import React, {Component} from 'react';
import {isEmpty, isEqual} from 'lodash';
import cn from 'classnames';
import {Alert, Button, Select, Switch, Table} from 'ui-kit';
import {connect} from 'react-redux';
import {recordTable} from './helpers/constant';
import {getFieldsMeta, onError, sortFieldsByOrder} from '../Helpers/Utils';
import {UploadFileModal} from '../UploadFileModal';
import {fetchFunc} from '../../Utils/security/http/mdm';
import './RecordTable.scss';
import {DeleteOutlined, EditOutlined, ImportOutlined, PlusOutlined} from '@ant-design/icons';
import {mdm} from 'Utils/constant';
import {showDeleteConfirm} from 'components/Helpers/Modals';
import {notificationCreate} from 'components/Helpers/Notification';

class RecordTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayedColumns: this.props.displayedColumns,
      columnsForRender: this.props.columnsForRender,
      isModalOpen: false,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {displayedColumns, columnsForRender, isVerification, detail} = this.props;
    if (prevProps?.columnsForRender !== columnsForRender) {
      this.setState({columnsForRender});
    }
    if (prevProps?.displayedColumns !== displayedColumns) {
      const newDisplayedColumns = displayedColumns;

      const checkValueStatusOnColumns = newDisplayedColumns.includes('status');
      if (isVerification && !checkValueStatusOnColumns) {
        newDisplayedColumns.unshift('status');
      }
      this.setState({displayedColumns: newDisplayedColumns});
    }
  }

  showUploadModal = () => {
    this.setState({...this.state, isModalOpen: true});
  };

  renderAddButton = () => {
    const {
      detail,
      isEdit,
      editable,
      csvLoading,
      xmlLoading,
      xlsxLoading,
      xlsLoading,
      openModal,
      onExport,
      editSigns,
      inputType,
      viewRoute,
      hasDuplicates,
      isDeduplicationRecordsAll,
      setIsDeduplicationRecordsAll,
      isVerifier,
      jsonLoading,
      changeType,
      isUnverified,
      filterData,
      historyData,
      isDisabled,
      contextString,
    } = this.props;

    const selectLoadType = () => {
      const type = this.state.exportValue;
      if (type === 'CVS') {
        return csvLoading;
      }
      if (type === 'XLSX') {
        return xlsxLoading;
      }
      if (type === 'XLS') {
        return xlsLoading;
      }
      if (type === 'JSON') {
        return jsonLoading;
      }
      if (type === 'XML') {
        return xmlLoading;
      }

      return false;
    };

    let children = '';
    const isAddDetail = detail && (changeType === 'modify' || !isUnverified);
    const isCatalogEditable = editSigns && editSigns.editCatalog && editSigns.inputType !== 'external';

    if ((isAddDetail || isCatalogEditable) && editable && isEdit && !isDisabled) {
      children = (
        <Button type="primary" className="mr-2" onClick={() => openModal('create', false)} icon={<PlusOutlined />}>
          Добавить
        </Button>
      );
    }
    let renderButton = editSigns && editSigns.editCatalog;

    if (inputType && inputType === 'external') renderButton = false;
    if (!detail && !isVerifier) {
      children = (
        <>
          {renderButton && !viewRoute && !isDisabled && (
            <Button type="primary" className="mr-2" onClick={() => openModal('create', false)} icon={<PlusOutlined />}>
              Добавить
            </Button>
          )}
          {renderButton && !isDisabled && (
            <Button type="primary" ghost className="mr-2" onClick={this.showUploadModal} icon={<ImportOutlined />}>
              Импорт из файла
            </Button>
          )}
          <Select
            placeholder={recordTable.export}
            loading={selectLoadType()}
            options={recordTable.formats}
            value={recordTable.export}
            onSelect={(value) => {
              this.setState({exportValue: value});
              onExport(value);
            }}
            className="recordTable_exportSelect"
          />
          {hasDuplicates === true && (
            <div className="ml-3 d-flex justify-content-center align-items-center">
              <div className="mb-1 mr-2">{recordTable.duplicates}</div>
              <Switch
                checked={isDeduplicationRecordsAll}
                onChange={(checked) => setIsDeduplicationRecordsAll(!checked)}
              />
            </div>
          )}
        </>
      );
    }
    return <div className="d-flex">{children}</div>;
  };

  handleColumnsSelectChange(value) {
    const {columns, isVerification, handleTableColumnsChange, columnsForRender, detail} = this.props;
    if (handleTableColumnsChange) handleTableColumnsChange(value);
    const newColumnsForRender = columns.filter((item) => value.some((elem) => elem === item.key));
    const isRequiredStatusColumn = isEmpty(detail) && !isVerification && newColumnsForRender[0]?.key !== 'status';
    this.setState({
      displayedColumns: value,
      columnsForRender: isRequiredStatusColumn
        ? [columnsForRender[0], ...sortFieldsByOrder(newColumnsForRender)]
        : [...sortFieldsByOrder(newColumnsForRender)],
    });
  }

  onRow = (record) => {
    const {onDoubleClick, detail, openModal, editable, editSigns, isEdit} = this.props;
    const isEditDetail =
      (detail || (editSigns && editSigns.editCatalog && editSigns.inputType !== 'external')) && editable && isEdit;

    return {
      onDoubleClick: () =>
        isEmpty(detail) ? onDoubleClick(record) : !isEditDetail ? openModal('edit', false, record.uuid) : null,
    };
  };

  handleCancel = () => {
    this.setState({isModalOpen: false});
  };

  importFromFile = async (data) => {
    await fetchFunc({
      url: '/api/v1/catalogs/import',
      method: 'POST',
      data,
    });
    this.handleCancel();
  };

  deleteRecord = async (detailFieldUuid) => {
    const {detail, referenceOrigin, getCatalogItem, isUnverified, recordUuid, history, validateCatalog} = this.props;
    try {
      let url = `/api/v1/catalogs/${referenceOrigin}/items/${detailFieldUuid}`;
      if (detail) {
        url = `/api/v1/catalogs/${referenceOrigin}/detail/${detail}/${recordUuid}/items/${detailFieldUuid}${
          isUnverified ? '/unverified' : ''
        }`;
      }
      await fetchFunc(
        {
          url,
          method: 'delete',
        },
        onError
      );
      if (validateCatalog) {
        let url = `/catalog/${referenceOrigin}/${recordUuid}/verify`;
        if (referenceOrigin === 'org') {
          url = `/catalog/organisations/${recordUuid}/verify`;
        }
        history.push(url);
      } else {
        getCatalogItem();
        this.setState({loading: false});
      }
    } catch (error) {
      this.setState({loading: false});
    }
  };

  render() {
    const {
      renderCustomTable,
      columns,
      data,
      loading,
      handleTableChange,
      detail,
      referenceColumns,
      tableParams,
      editable,
      tableSource,
      isEdit,
      editSigns,
      openModal,
      isOriginVersion,
      mode,
      isDisabled,
    } = this.props;

    const {displayedColumns, columnsForRender, isModalOpen} = this.state;
    let columnsShowOptions = [];
    let maxLength = 0;
    let dropdownMenuWidth;

    if (columnsForRender && columns) {
      columnsShowOptions = columns?.map((item, index) => {
        if (item.title.length > maxLength) {
          maxLength = item.title.length;
        }
        return (
          <Select.Option value={item.key} key={'column-select' + index}>
            {item.title}
          </Select.Option>
        );
      });
    }
    dropdownMenuWidth = maxLength * 5 + 44;

    const EditColumns = [
      {
        title: '',
        key: 'edit',
        width: 20,
        render: (text, record) => (
          <Button
            className="mr-2 border-0"
            onClick={() => {
              openModal('edit', true, record.uuid);
            }}
            type="ghost"
            icon={<EditOutlined />}
          />
        ),
      },
      {
        title: '',
        key: 'delete',
        width: 20,
        render: (text, record) => (
          <Button
            className="border-0"
            onClick={() => {
              this.setState({loading: true});
              showDeleteConfirm({
                content: 'Вы действительно хотите удалить запись справочника?',
                onOk: () => this.deleteRecord(record.uuid),
                okText: mdm.confirm,
                cancelText: mdm.cancel,
              });
            }}
            type="ghost"
            icon={<DeleteOutlined />}
          />
        ),
      },
    ];

    const metaColumns = getFieldsMeta({
      columns: columnsForRender,
      rule: renderCustomTable,
      additionalRule: 'table',
    });
    const isEditDetail =
      (detail || (editSigns && editSigns.editCatalog && editSigns.inputType !== 'external')) && editable && isEdit;

    return (
      <>
        <UploadFileModal
          isModalOpen={isModalOpen}
          handleCancel={this.handleCancel}
          catalogColumns={columns}
          dictName={this.props.referenceOrigin}
          onImport={this.importFromFile}
          referenceColumns={referenceColumns}
        />
        <div className="d-flex mb-3 justify-content-between">
          {this.renderAddButton()}
          <Select
            dropdownStyle={{width: dropdownMenuWidth}}
            popupMatchSelectWidth={false}
            mode="multiple"
            name="displayedColumns"
            maxTagCount={0}
            maxTagPlaceholder={'Колонки'}
            style={{width: '130px'}}
            placeholder="Колонки"
            value={displayedColumns}
            onChange={(value) => {
              if (displayedColumns?.length === 1 && value?.length === 0) {
                notificationCreate({
                  message: 'Невозможно скрыть текущую колонку, должна быть выбрана хотя бы одна колонка',
                  type: 'warning',
                  duration: 4,
                  closeIcon: false,
                });
              } else {
                this.handleColumnsSelectChange(value);
              }
            }}
          >
            {columnsShowOptions}
          </Select>
        </div>
        {(isEmpty(displayedColumns) && !isEmpty(data) && !loading && (
          <Alert message={recordTable.notificationHiddenAttributes} type="warning" showIcon />
        )) || (
          <Table
            columns={isEditDetail && !isDisabled ? [...metaColumns, ...EditColumns] : metaColumns}
            dataSource={data}
            loading={loading}
            rowKey={(record) => `${record?.uuid}_${record?.id}_${record?.rowKey}_${record?.key}`}
            rowClassName={(record) => {
              const originTableField =
                tableSource &&
                tableSource['originVersion'] &&
                tableSource['originVersion'].find((field) => field.uuid === record.uuid);
              const newTableField =
                tableSource &&
                tableSource['newVersion'] &&
                tableSource['newVersion'].find((field) => field.uuid === record.uuid);

              const isChangedField = !isEqual(originTableField, newTableField);

              return cn('', {
                'field-changed': isChangedField,
                'is_origin-version': isOriginVersion,
                'unverified-version': !detail && record?.sysVerifyInfoDraft,
                recordTable__checkMainItemUuidInData: !isEmpty(record.mainItemUuid),
                // disabledRow: !isEmpty(detail) && !isEditDetail,
                highlightRow: !detail && record?.sysVerifyInfoDraft,
              });
            }}
            onChange={handleTableChange}
            onRow={this.onRow}
            pagination={tableParams?.total >= 10 && tableParams}
            bordered
            scroll={{x: true}}
          />
        )}
        {!isEdit && mode === 'create' && (
          <Alert
            showIcon
            className="mt-3 defaultAlert"
            description="Добавление записей детализации доступно только после создания основной записи"
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    filterData: store.filter.filterBody,
    contextString: store.filter.contextString,
    historyData: store.filter.historyBody,
  };
};

export default connect(mapStateToProps)(RecordTable);
