import React from 'react';
import {Button, Form, Input, Modal} from 'ui-kit';

import {StyledInput, StyledButton, StyledForm, StyledFormItem} from '../Themes/Components';
import {layout} from '../Helpers/constants';
import {mdm} from '../../Utils/constant';
import {DetailTableRecordType} from './DetailTable';
import styles from './DetailTable.module.scss';

export interface ComboboxOptionsFormProps {
  onSubmit: (values: DetailTableRecordType) => void;
  closeModal: () => void;
}

/** Форма добавления значений в список
 * @param closeModal - функция закрытия модального окна
 * @param onSubmit - функция для сбора данных с формы */
const ComboboxOptionsForm = ({onSubmit, closeModal}: ComboboxOptionsFormProps) => {
  /** Данные формы */
  const [form] = Form.useForm();
  return (
    <Form
      layout={'vertical'}
      form={form}
      name="combobox-detail-add"
      onFinish={(values) => {
        onSubmit(values);
        closeModal();
      }}
    >
      <div className={styles.formItems}>
        <Form.Item
          label="Ключ"
          name="key"
          className={styles.formItem}
          rules={[{required: true, message: mdm.requiredMessage}]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Значение"
          name="value"
          className={styles.formItem}
          rules={[{required: true, message: mdm.requiredMessage}]}
        >
          <Input />
        </Form.Item>
      </div>
      <div className={styles.footerButtons}>
        <Button onClick={() => closeModal()}>{mdm.cancel}</Button>
        <Button type="primary" htmlType="submit">
          {mdm.save}
        </Button>
      </div>
    </Form>
  );
};

export default ComboboxOptionsForm;
