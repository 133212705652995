import {Modal as AntdModal} from 'antd';
import styles from './Modal.module.scss';
import classNames from 'classnames';
import {ModalFuncProps} from 'antd/lib';
import {ExclamationCircleOutlined} from '@ant-design/icons';

export const ModalError = (props: ModalFuncProps) => {
  return AntdModal.error({
    ...props,
    className: classNames(styles.confirm, props.className),
    icon: props?.icon || <ExclamationCircleOutlined />,
  });
};
