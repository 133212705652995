import React, {Component} from 'react';
import {connect} from 'react-redux';
import {isArray, isEmpty} from 'lodash';

import CatalogsFilters from '../CatalogsFilters';
import AccessRuleForm from '../AccessRights/AccessRuleForm';
import {setFilterReset} from '../../redux/actions/actions';
import {fetchFunc} from '../../Utils/security/http/mdm';
import {successModalCreate} from '../Helpers/Modals';
import {setCatalogs} from '../../modules/Subscriptions/effects/actions';
import {RouteComponentProps, withRouter} from 'react-router';
import {Table, Select, Button, Modal} from 'ui-kit';
import classNames from 'classnames';
import {TableProps} from 'antd';
import {TableRowSelection} from 'antd/es/table/interface';
import {RootState} from 'redux/types/rootState';
import {Dispatch} from 'redux';
import styles from './CatalogList.module.scss';
import {EditOutlined} from '@ant-design/icons';
import {notificationCreate} from 'components/Helpers/Notification';

type ColumnsType = any;

export interface CatalogListProps {
  columns: ColumnsType[];
  displayedColumns: string[];
  apiUrl: string;
  additionalParams?: string;
  sort: string;
  isContextSearch?: boolean;
  card?: boolean;
  type?: 'verify';
  referenceOrigin?: string;
  resetFilter: any;
  setResetFlagFilter: any;
  catalogIdentifier?: string;
  setCatalogs: (value: any) => void;
  filterData?: any;
  contextString?: any;
  isFilterSearch?: boolean;
  fields?: any[];
  editRulesMode?: boolean;
  rowSelection?: TableRowSelection<any>;
}

interface Props extends RouteComponentProps, CatalogListProps {}

export interface CatalogListState {
  _isMounted?: boolean;
  columns: ColumnsType[];
  displayedColumns: string[];
  columnsForRender: ColumnsType[];
  data: any[];
  totalElements: number;
  loading: boolean;
  page: number;
  size: number;
  search: string;
  modalRulesShow: boolean;
  catalogListState: string;
  referenceOrigins: any[];
  filter?: any;
  sort?: any;
  selectedRowKeys?: any;
}

class CatalogList extends Component<Props, CatalogListState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      columns: this.props.columns,
      displayedColumns: this.props.displayedColumns,
      columnsForRender: [],
      data: [],
      totalElements: 0,
      loading: true,
      page: 0,
      size: 10,
      search: '',
      modalRulesShow: false,
      catalogListState: 'catalogSearch',
      referenceOrigins: [],
    };
  }

  componentDidMount = () => {
    this.setState({
      _isMounted: true,
    });
    const {columns, displayedColumns} = this.state;
    let newColumns: TableProps<any>['columns'][] = [];
    if (columns && displayedColumns) {
      columns.forEach((item) => {
        if (displayedColumns.some((elem) => elem === item?.key)) {
          newColumns.push(item as any);
        }
      });
    } else newColumns = columns;

    this.setState({columnsForRender: newColumns}, () => {
      if (this.props.referenceOrigin === 'service') this.getCatalogItems().then();
      else this.getCatalogs().then();
    });
  };

  componentDidUpdate(prevProps: Props, prevState: CatalogListState, snapshot: any) {
    const {resetFilter, setResetFlagFilter, rowSelection} = this.props;
    if (prevProps.resetFilter !== resetFilter && resetFilter) {
      this.applyFilter();
      setResetFlagFilter(false);
    }

    const tableData = this.state.data;
    const prevTableData = prevState.data;

    if (tableData !== prevTableData) {
      const rowSelectionOnChange = rowSelection?.onChange;
      const selectedRowKeys = rowSelection?.selectedRowKeys;
      if (selectedRowKeys && selectedRowKeys?.length > 0) {
        const filterDataSelected = tableData?.filter((data) => selectedRowKeys.includes(data.uuid));
        const uuidsRowSelected = filterDataSelected?.map((data) => data.uuid);

        rowSelectionOnChange && rowSelectionOnChange(uuidsRowSelected, filterDataSelected, {type: 'all'});
      }
    }
  }

  componentWillUnmount() {
    this.setState({
      _isMounted: false,
    });
  }

  getCatalogs = async (groupUuid = false) => {
    try {
      const {page, size, search} = this.state;
      const {apiUrl, catalogIdentifier, sort, additionalParams = '', location} = this.props;
      const pageParam = `?page=${page}`;
      const sizeParam = `&size=${size}`;
      const searchParam = apiUrl === '/api/v1/org' ? `&caption_like=${search}` : `&search=${search}`;
      const sortParam = sort ? `&sort=${sort}` : '';
      const groupParam = groupUuid ? `&group=${groupUuid}` : '';
      const filterCatalogDraft = catalogIdentifier ? `&filterCatalogDraft=${catalogIdentifier}` : '';
      const editProperties = location.pathname === '/catalogs-rules' ? '&edit_properties=true' : '';
      const url =
        apiUrl +
        pageParam +
        sizeParam +
        filterCatalogDraft +
        searchParam +
        sortParam +
        groupParam +
        additionalParams +
        editProperties;
      const data = await fetchFunc({
        url,
        method: 'get',
      });
      if (this.state._isMounted) {
        this.setState({
          data: data.content,
          totalElements: data.totalElements,
          loading: false,
        });
      }
      this.props.setCatalogs(data.content);
    } catch (error) {
      this.setState({loading: false});
    }
  };

  makeBody = () => {
    const {search, filter} = this.state;
    const body = {
      and: [],
    } as any;
    if (filter && !isEmpty(filter)) body.and.push(filter);
    if (search) body.and.push({'%context_filter': search});
    return body;
  };

  getCatalogItems = async () => {
    try {
      const {referenceOrigin} = this.props;
      const {page, size, sort} = this.state;
      const pageParam = `&page=${page}`;
      const sizeParam = `&size=${size}`;
      let sortParam = '';
      if (sort && sort.order) {
        sortParam = `&sort=${sort.field},${sort.order}`;
      }
      const url = `/api/v1/catalogs/${referenceOrigin}/items/search/extended?showRefs=1${pageParam}${sizeParam}${sortParam}`;
      const data = await fetchFunc({
        url,
        method: 'post',
        data: this.makeBody(),
      });
      this.setState({
        data: data.content,
        totalElements: data.totalElements,
        loading: false,
      });
    } catch (error) {
      this.setState({loading: false});
    }
  };

  applyFilter = () => {
    const {filterData, contextString} = this.props;
    this.setState(
      {
        filter: filterData,
        page: 0,
        search: contextString,
        loading: true,
      },
      () => this.getCatalogs()
    );
  };

  handleColumnsSelectChange(value: ColumnsType[]) {
    const {columns} = this.state;
    const newColumnsForRender: ColumnsType[] = [];
    columns.forEach((item) => {
      if (value.some((elem) => elem === item.key)) {
        newColumnsForRender.push(item);
      }
    });
    this.setState({displayedColumns: value, columnsForRender: newColumnsForRender});
  }

  handleTableChange = (pagination: any) => {
    this.setState(
      {
        loading: true,
        size: pagination.pageSize as number,
        page: --(pagination.current as number),
      },
      () => this.getCatalogs()
    );
  };

  onSelectedRowKeysChange = (record: any, selected = false) => {
    this.setState(({referenceOrigins}) => {
      const {origin} = record;
      if (isArray(record)) referenceOrigins = record.map((item) => item?.origin);
      else {
        if (!selected) {
          const index = referenceOrigins.findIndex((item) => item === origin);
          referenceOrigins.splice(index, 1);
        } else referenceOrigins.push(origin);
      }
      return {referenceOrigins};
    });
  };

  updateAccessRules = async (newRights: any) => {
    const {referenceOrigins} = this.state;
    this.setState({modalRulesShow: false});
    try {
      await referenceOrigins.forEach((item) => this.postAccessRules(newRights, item));
      successModalCreate('', 'Права назначены успешно');
    } catch (error: any) {
      console.log(error.response);
    }
  };

  postAccessRules = async (rules: string[], referenceOrigin: string) => {
    const url = `/api/v1/catalogs/${referenceOrigin}/access`;
    try {
      await fetchFunc({
        url,
        method: 'post',
        data: rules,
      });
    } catch (error: any) {
      console.log(error.response);
    }
  };

  render() {
    const {
      columns,
      columnsForRender,
      loading,
      data,
      displayedColumns,
      totalElements,
      selectedRowKeys,
      modalRulesShow,
      referenceOrigins,
      page,
      catalogListState,
    } = this.state;
    const {isFilterSearch, isContextSearch, card} = this.props;
    let columnsShowOptions: any[] = [];
    if (displayedColumns) {
      columnsShowOptions = columns.map((item, index) => {
        return (
          <Select.Option value={item.key} key={'column-select' + index}>
            {item.title}
          </Select.Option>
        );
      });
    }

    const rowSelectionObj = {
      selectedRowKeys,
      onSelect: this.onSelectedRowKeysChange,
      onSelectAll: (selected: boolean, selectedRows: any[]) => {
        this.onSelectedRowKeysChange(selectedRows);
      },
      hideDefaultSelections: true,
    };

    return (
      <>
        <div>
          <div className={classNames({container: !card, containerWhite: card})}>
            {isFilterSearch && (
              <CatalogsFilters
                isFilterSearch={isFilterSearch && true}
                isContextSearch={isContextSearch}
                applyFilter={this.applyFilter}
                fields={this.props.fields}
                catalogListState={catalogListState}
              />
            )}
            {displayedColumns && (
              <div>
                <div className="d-flex mb-3 align-items-center gap-24">
                  {!isFilterSearch && isContextSearch && (
                    <div className="flex-grow-1 w-100">
                      <CatalogsFilters
                        isFilterSearch={isFilterSearch && true}
                        isContextSearch={isContextSearch}
                        applyFilter={this.applyFilter}
                        fields={this.props.fields}
                        catalogListState={catalogListState}
                        compactMode={true}
                      />
                    </div>
                  )}

                  <div className="d-flex ml-auto align-items-center">
                    <div>
                      <Select
                        mode="multiple"
                        maxTagCount={0}
                        maxTagPlaceholder={'Колонки'}
                        style={{width: '120px', height: '40px'}}
                        placeholder="Колонки"
                        value={displayedColumns}
                        onChange={(value) => {
                          if (displayedColumns?.length === 1 && value?.length === 0) {
                            notificationCreate({
                              message: 'Невозможно скрыть текущую колонку, должна быть выбрана хотя бы одна колонка',
                              type: 'warning',
                              duration: 4,
                              closeIcon: false,
                            });
                          } else {
                            this.handleColumnsSelectChange(value);
                          }
                        }}
                        showSearch={false}
                        className={styles.columnsSelect}
                      >
                        {columnsShowOptions}
                      </Select>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="d-flex flex-column gap-16 mt-3">
              {!!(this.props.editRulesMode && referenceOrigins && referenceOrigins.length) && (
                <div>
                  <Button onClick={() => this.setState({modalRulesShow: true})} type="primary" icon={<EditOutlined />}>
                    Изменить права доступа
                  </Button>
                </div>
              )}
              <Table
                className={styles.table}
                columns={columnsForRender}
                dataSource={data}
                loading={loading}
                rowSelection={(this.props.editRulesMode && rowSelectionObj) || this.props.rowSelection}
                onChange={this.handleTableChange}
                rowKey={(record) => record.uuid}
                pagination={{
                  total: totalElements,
                  current: page + 1,
                }}
                bordered
              />
            </div>
          </div>
        </div>
        <Modal
          title={'Установка прав на группу справочников'}
          open={modalRulesShow}
          footer={null}
          onCancel={() => this.setState({modalRulesShow: false})}
          destroyOnClose={true}
          width={800}
        >
          <AccessRuleForm store="dictionary" updateAccessRules={this.updateAccessRules} />
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (store: RootState) => {
  return {
    filterData: store.filter.filterBody,
    contextString: store.filter.contextString,
    resetFilter: store.filter.filterResetFlag,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    setResetFlagFilter: (flag: boolean) => {
      dispatch(setFilterReset(flag));
    },
    setCatalogs: (data: any) => {
      dispatch(setCatalogs(data));
    },
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CatalogList));
