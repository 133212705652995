import {errorModalCreate} from '../../../components/Helpers/Modals';
import axios from 'axios';
import security from '..';
import {keycloak, keycloakLogout} from '../../../index';
import _, {get} from 'lodash';
import {renderError} from 'components/Helpers/Utils';

const {getToken} = security;
const mdmFetch = axios.create();

const issueToken = () => {
  return new Promise((resolve, reject) => {
    return keycloak
      .updateToken(30000)
      .success((refreshed) => {
        if (refreshed) {
          resolve(keycloak.token);
          sessionStorage.setItem('kctoken', `${keycloak.token}`);
        } else {
          console.log('not refreshed ' + new Date());
        }
      })
      .error(() => {
        reject();
        keycloakLogout();
      });
  });
};

mdmFetch.interceptors.request.use(
  (config) => {
    let originalRequest = config;
    if (keycloak.isTokenExpired(30000)) {
      return issueToken().then((token) => {
        _.set(originalRequest, 'headers.Authorization', `Bearer ${token}`);
        return Promise.resolve(originalRequest);
      });
    } else {
      _.set(config, 'headers.Authorization', `Bearer ${getToken()}`);
    }
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

const getUrl = (url, service) => {
  const {
    REACT_APP_MDM_URL: mdmUrl,
    REACT_APP_SCHEDULER_URL: schedulerUrl,
    REACT_APP_ECM_URL: ecmUrl,
    REACT_APP_ORG_URL: orgUrl,
    REACT_APP_BMPN: bpmnUrl,
    REACT_APP_MAINTENANCE_API_URL: maintenanceUrl,
    REACT_APP_DEDUPLICATION_URL: deduplicationUrl,
    REACT_APP_API_SERVICE: apiService,
    REACT_APP_API_SERVICE_RO: apiServiceRo,
    REACT_APP_API_SERVICE_RW: apiServiceRw,
    REACT_APP_NOTIFICATION_URL: notificationUrl,
    REACT_APP_NOTIFY_ADAPTER: notifyAdapterUrl,
  } = window._env;
  switch (service) {
    case 'mdm':
      return `${mdmUrl}${url}`;
    case 'maintenanceUrl':
      return `${maintenanceUrl}${url}`;
    case 'scheduler':
      return `${schedulerUrl}${url}`;
    case 'ecm':
      return `${ecmUrl}${url}`;
    case 'org':
      return `${orgUrl}${url}`;
    case 'bpmn':
      return `${bpmnUrl}${url}`;
    case 'deduplicationUrl':
      return `${deduplicationUrl}${url}`;
    case 'apiService':
      return `${apiService}${url}`;
    case 'apiServiceRo':
      return `${apiServiceRo}${url}`;
    case 'apiServiceRw':
      return `${apiServiceRw}${url}`;
    case 'notificationUrl':
      return `${notificationUrl}${url}`;
    case 'notifyAdapter':
      return `${notifyAdapterUrl}${url}`;
    default:
      return `${mdmUrl}${url}`;
  }
};

export const fetchFunc = async (fetchOptions, onError, service = 'mdm') => {
  try {
    let {url, method, data, responseType, params, headers} = fetchOptions;
    const encodedUrl = encodeURI(getUrl(url, service));

    const response = await mdmFetch({
      url: encodedUrl,
      method,
      data,
      responseType,
      params,
      headers,
    });
    const {status} = response;
    if (status === 200 || status === 201 || status === 204 || status === 202) {
      if (response.data) {
        return response.data;
      } else return status;
    }
  } catch (error) {
    if (onError) onError(error.response);
    else renderError(error.response, 'data.message', fetchOptions.url, error);
    throw Error(error.response);
  }
};
