import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Input, Checkbox, Button, Select, Form, Modal, InputNumber, Radio} from 'ui-kit';
import {get, isString, isUndefined, isEmpty, includes, isBoolean} from 'lodash';
import DetailTable from '../DetailTable/DetailTable';
import {errorModalCreate} from '../Helpers/Modals';
import Validators from '../Validators';
import {checkFormat, normalizeValues} from '../Helpers/Utils';

import {
  fieldTypes,
  dateFormats,
  dateTimeFormats,
  defaultValueDate,
  defaultValues,
  radioStyle,
  requiredRule,
  CBLayout,
  fieldArrayTypes,
} from './helpers/constants';
import {
  setDataForOptionsTypeSelect,
  setDataOptionsReset,
  setReferenceCatalogPatternOriginAC,
} from '../../redux/actions/actions';
import {getValidationRules} from './helpers/utils';
import styles from './EditFieldForm.module.scss';
import StringFieldForm from './FieldFormComponents/StringFieldForm';
import TextFieldForm from './FieldFormComponents/TextFieldForm';
import HyperlinkFieldForm from './FieldFormComponents/HyperlinkFieldForm';
import IntegerFieldForm from './FieldFormComponents/IntegerFieldForm';
import DecimalFieldForm from './FieldFormComponents/DecimalFieldForm';
import FloatFieldForm from './FieldFormComponents/FloatFieldForm';
import UUIDFieldForm from './FieldFormComponents/UUIDFieldForm';
import {CloseOutlined, SaveOutlined} from '@ant-design/icons';
import CatalogLinkFieldForm from './FieldFormComponents/CatalogLinkFieldForm';
import ValueListFieldForm from './FieldFormComponents/ValueListFieldForm';
import MultipleValueListFieldForm from './FieldFormComponents/MultipleValueListFieldForm';
import {DefaultValue, FieldOrder} from './helpers/FormComponents';
import BooleanFieldForm from './FieldFormComponents/BooleanFieldForm';
import DateFieldForm from './FieldFormComponents/DateFieldForm';
import {mdm} from 'Utils/constant';
import dayjs from 'dayjs';
import classNames from 'classnames';
/** Форма создания/Редактирования справочника*/
const EditFieldForm = (props) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const {
    type,
    dataCatalogs,
    editableItem,
    loading,
    status,
    catalogOptions,
    detail,
    uuid,
    referenceCatalogPatternCaption,
  } = props;
  const fieldType = Form.useWatch('fieldTypeId', form) || type;
  const [modalVisible, setModalVisible] = useState(false);
  const [comboboxData, setComboboxData] = useState([]);
  /** Получаем uuid справочника */
  const [uuidCatalog, setUuidCatalog] = useState('');
  /** Получаем Origin названия справочника */
  const [referenceCatalogPatternOrigin, setReferenceCatalogPatternOrigin] = useState('');
  /** Получаем значения валидации поля */
  const validator = useSelector((state) => state?.detail.validatorLength);
  /** Получаем валидацию для поля fieldTypeId=2  */
  const numericComparison = useSelector((state) => state?.detail.numericComparison);

  /** Поля добавленные при редактироватии для типа поля Список */
  const [detailSubmitData, setDetailSubmitData] = useState([]);
  const isDisabled = status === 'Применено';

  useEffect(() => {
    if (get(editableItem, 'options.combobox_options') && isEmpty(detailSubmitData)) {
      setComboboxData(editableItem.options.combobox_options);
    }
  }, [editableItem, detailSubmitData]);
  /** Отправляем origin справочника в хранилище */
  useEffect(() => {
    !isEmpty(editableItem) &&
      isEmpty(referenceCatalogPatternOrigin) &&
      dispatch(
        setReferenceCatalogPatternOriginAC(
          editableItem.referenceCatalogPattern?.split(';')[1] || editableItem.referenceCatalogPattern
        )
      );
  }, [editableItem, referenceCatalogPatternOrigin, referenceCatalogPatternCaption, dispatch]);
  /** Функция для изменения значения справочника */
  const handleReferenceCatalogChange = (value, option) => {
    form.setFieldsValue({
      referenceCatalog: value,
      referenceCatalogPattern: null,
      uuidItem: setUuidCatalog(option.uuidValue),
    });
  };
  /** Функция для валидации значения по умолчанию при указанном количестве символов */
  useEffect(() => {
    !validator && getValidationRules(validator);
  }, [validator]);
  /** Функция для изменения значения аттрибутов справочника */
  const handleReferenceCatalogPatternChange = (value, option) => {
    dispatch(setReferenceCatalogPatternOriginAC(option.fieldOrigin));
    setReferenceCatalogPatternOrigin(option.fieldOrigin);
    form.setFieldsValue({
      referenceCatalogPattern: value,
    });
  };
  /** Получаем Uuid выбранного справочника  */
  const findCaptionFromUuid = () => {
    const itemData = dataCatalogs?.find((item) => item?.uuid === editableItem?.referenceCatalog?.uuid);
    return itemData?.caption;
  };

  const onResetForm = () => {
    form.resetFields([
      'context_search',
      'gui_editable',
      'importUnique',
      'old',
      'default_in_list',
      'required',
      'uniqueIndex',
      'uniqueSingleIndex',
    ]);

    form.setFieldsValue({input_mask: '', phone_mask: false});
  };

  const handleTypeChange = (value) => {
    onResetForm();
    // form.resetFields([
    //   'context_search',
    //   'gui_editable',
    //   'importUnique',
    //   'old',
    //   'default_in_list',
    //   'required',
    //   'uniqueIndex',
    //   'input_mask',
    //   'phone_mask',
    // ]);
    let values = {};
    switch (value) {
      case 8:
      case 7:
        const defaultValueType = form.getFieldValue('date_default_value_type');
        if (defaultValueType === 'empty') form.setFieldsValue({date_duration: null, defaultValue: null});
        values = {
          date_format: value === 7 ? 'DD.MM.YYYY HH:mm' : 'DD.MM.YYYY',
          context_search: false,
        };
        break;
      case 10:
        values = {
          context_search: false,
          required: false,
          uniqueIndex: false,
          uniqueIndex: false,
          uniqueSingleIndex: false,
        };
        break;
      case 12:
        values = {
          attribute_search: false,
          context_search: false,
          required: false,
          uniqueIndex: false,
          uniqueSingleIndex: false,
          default_in_list: false,
        };
        break;
      case 14:
      case 15:
        setComboboxData([]);
        values = {
          uniqueIndex: false,
          uniqueSingleIndex: false,
          combobox_options: [],
        };
        break;
      case 22:
      case 23:
        values = {
          uniqueIndex: false,
          uniqueSingleIndex: false,
          importUnique: false,
        };
        break;
      case 24:
      case 25:
        values = {
          uniqueIndex: false,
          uniqueSingleIndex: false,
          importUnique: false,
          context_search: false,
        };
        break;
      case 19:
        values = {
          context_search: false,
          default_in_list: false,
        };
        break;
      case 21:
        return (
          <>
            {!detail && (
              <Form.Item {...CBLayout} name="accessValue" valuePropName="checked">
                <Checkbox disabled={isDisabled}>Доступ по значению</Checkbox>
              </Form.Item>
            )}
            <Form.Item
              label="Значение по умолчанию"
              name="defaultValue"
              rules={
                type === 2
                  ? [
                      {
                        required: false,
                        message: `Допустимое значение - ${type === 2 ? 'целое' : 'десятичное'} число`,
                        pattern: type === 2 ? /^-?[0-9]*$/ : /^-?[0-9]*[.,]?[0-9]+$/,
                      },
                      {
                        validator: async (_, value) => {
                          if (
                            value &&
                            ((Number(value) !== numericComparison?.validator_NumericComparison &&
                              numericComparison?.validator_operator_NumericComparison === 'eq') ||
                              (Number(value) > numericComparison?.validator_NumericComparison &&
                                numericComparison?.validator_operator_NumericComparison === 'less') ||
                              (Number(value) < numericComparison?.validator_NumericComparison &&
                                numericComparison?.validator_operator_NumericComparison === 'more') ||
                              ((Number(value) >= Number(numericComparison?.validator_NumericComparison_bottom) &&
                                Number(value) <= Number(numericComparison?.validator_NumericComparison_top)) ===
                                false &&
                                numericComparison?.validator_operator_NumericComparison === 'range'))
                          ) {
                            return Promise?.reject(new Error('Введенное значение не соответствует валидации'));
                          }
                        },
                      },
                    ]
                  : [
                      {
                        required: false,
                        message: `Допустимое значение - ${type === 2 ? 'целое' : 'десятичное'} число`,
                        pattern: type === 2 ? /^[0-9]*$/ : /^[0-9]*[.,]?[0-9]+$/,
                      },
                    ]
              }
            >
              <Input disabled={isDisabled} />
            </Form.Item>
          </>
        );
      case 6:
        values = {
          context_search: false,
        };
        break;
      default:
    }
    if (value !== 4) {
      values.referenceCatalog = null;
      values.referenceCatalogPattern = null;
      values.enabledLower = false;
    }
    if (!includes([1, 2, 11, 4, 10, 14, 21], value)) {
      values.accessValue = false;
      values.enabledLower = false;
    }

    /* Reset validators */
    values.is_Pattern = false;
    values.is_Length = false;
    values.is_Enumeration = false;
    values.is_NumericComparison = false;
    values.validator_Pattern = '';
    values.validator_Length = '';
    values.validator_Enumeration = '';
    values.validator_NumericComparison = '';
    /* Reset validators */

    form.setFieldsValue(values);
  };

  const mapOption = (array) => {
    return array?.map((item) => (
      <Select.Option value={item.key} key={item.key}>
        {item.value}
      </Select.Option>
    ));
  };

  const onFinish = () => {
    const values = {...form.getFieldsValue(true)};
    const {fieldTypeId} = values;
    const {validators, editableItem, changeValidator, changeDraftField, deleteValidator, closeModal} = props;
    let fieldValues = {};
    let createValidatorValues = {};
    let createValidatorOperators = {};
    let editValidatorValues = {};
    let editValidatorOperators = {};
    let editUuid = {};
    let validatorsUuids = [];
    if (values.is_NumericComparison) {
      if (values.validator_operator_NumericComparison === 'range') {
        values.validator_NumericComparison = [
          values.validator_NumericComparison_bottom,
          values.validator_NumericComparison_top,
        ];
      }
      delete values.validator_NumericComparison_top;
      delete values.validator_NumericComparison_bottom;
    }

    if (values.hierarchy_field) values.referenceCatalog = null;

    values.referenceCatalog = uuidCatalog;
    values.uuidItem = uuidCatalog;

    for (let key in values) {
      if (values.hasOwnProperty(key)) {
        normalizeValues({values, key, type: 'editFieldForm'});
        let keyArr = key.split('_');
        if (keyArr[0] === 'validator') {
          if (values[key] || values[key] === 0) {
            if (keyArr[1] === 'operator') {
              if (validators.find((elem) => elem.validationType.caption === keyArr[2])) {
                editValidatorOperators[keyArr[2]] = values[key];
              } else {
                createValidatorOperators[keyArr[2]] = values[key];
              }
            } else {
              const validator = validators.find(
                (elem) => elem.validationType && elem.validationType.caption === keyArr[1]
              );
              if (validator) {
                editValidatorValues[keyArr[1]] = values[key];
                editUuid[keyArr[1]] = validator.uuid;
                validatorsUuids.push(validator.uuid);
              } else {
                createValidatorValues[keyArr[1]] = values[key];
              }
            }
          }
        } else if (keyArr[0] !== 'is') {
          fieldValues[key] = values[key];
        }
      }
    }
    if (editableItem) {
      if (validatorsUuids.length !== validators.length) {
        validators.forEach((item) => {
          if (validatorsUuids.length === 0) deleteValidator(editableItem.uuid, item.uuid);
          else if (!validatorsUuids.some((elem) => elem === item.uuid)) deleteValidator(editableItem.uuid, item.uuid);
        });
      }
      if (!isEmpty(createValidatorValues))
        changeValidator(createValidatorValues, createValidatorOperators, editableItem.uuid, {});
      changeValidator(editValidatorValues, editValidatorOperators, editableItem.uuid, editUuid);
    }
    if (fieldTypeId !== 8 && fieldTypeId !== 7) {
      delete values.date_format;
      delete values.date_default_value_type;
      delete values.date_duration;
    }
    if (fieldTypeId !== 14) {
      delete values.control_type;
    }
    if (fieldTypeId !== 14 && fieldTypeId !== 15) {
      delete values.combobox_options;
    }

    if (detail) {
      delete values.accessValue;
      delete values.enabledLower;
    }

    changeDraftField(
      values,
      editableItem ? 'edit' : 'create',
      !editableItem && {createValidatorValues, createValidatorOperators},
      () => {
        closeModal('editFieldModalVisible');
      }
    );
    isEmpty(editableItem) && dispatch(setDataOptionsReset());
  };
  /** Функция сбора данных  для отправки при добавления записей в список */
  const detailSubmit = (values) => {
    const {value, key} = values;
    let duplicateValues = comboboxData?.find((elem) => elem.value === value);
    let duplicateKeys = comboboxData?.find((elem) => elem.key === key);
    if (!isEmpty(duplicateValues)) {
      errorModalCreate('Такое значение уже существует');
      return false;
    }
    if (!isEmpty(duplicateKeys)) {
      errorModalCreate('Такой ключ уже существует');
      return false;
    }
    dispatch(setDataForOptionsTypeSelect([...comboboxData, values]));
    setDetailSubmitData([...comboboxData, values]);
    setComboboxData([...comboboxData, values]);
  };
  /** Функция сеттер значений списка  */
  const okModal = () => {
    setModalVisible(false);
    form.setFieldsValue({combobox_options: comboboxData});
  };

  const getOptionsValue = (path, defaultValue = false, stringVal = false) => {
    if (editableItem) {
      const value = get(editableItem, `options.${path}`);
      if (value && stringVal) return isString(value) ? value : JSON.stringify(value);
      else return value;
    } else {
      return defaultValue;
    }
  };

  const getValidationData = (id, withOperator, type) => {
    const {validators} = props;
    let validator = validators && validators.find((elem) => elem.validationType?.id === id);
    if (validator?.rule) {
      if (withOperator) {
        let rule = JSON.parse(validator.rule);
        let keys = Object.keys(rule);
        switch (type) {
          case 'operator':
            return keys[0];
          case 'value':
            return rule[keys[0]];
          case 'topValue':
            return rule[keys[0]][0];
          case 'bottomValue':
            return rule[keys[0]][1];
          default:
            return validator;
        }
      } else {
        if (type === 'value') {
          return validator.rule;
        } else {
          return validator;
        }
      }
    }
    return validator;
  };

  const getDefaultValue = ({editableItem, defaultValue}) => {
    if (editableItem) {
      if (editableItem.defaultValue) {
        const {defaultValue} = editableItem;
        switch (editableItem.fieldType.id) {
          case 7:
          case 8:
            return dayjs(defaultValue);
          case 15:
            return isString(defaultValue) ? JSON.parse(defaultValue) : defaultValue;
          default:
            return editableItem.defaultValue;
        }
      } else return null;
    } else if (defaultValue) return defaultValue;
    else return undefined;
  };

  const defaultValueDateOptions = mapOption(defaultValueDate);

  const renderDisplayWaysOpt = (ways) =>
    ways.map((item) => (
      <Radio style={radioStyle} value={item.key} key={item.key}>
        {item.value}
      </Radio>
    ));

  const {
    fieldTypeId,
    fieldOrder,
    origin,
    caption,
    description,
    defaultValue,
    context_search,
    attribute_search,
    default_in_list,
    gui_editable,
    required,
    uniqueIndex,
    old,
    mask,
    referenceCatalog,
    referenceCatalogPattern,
    filter,
    control_type,
    combobox_options,
    date_format,
    date_default_value_type,
    date_duration,
    hierarchy_field,
    rules,
    multilevel_link,
    phone_mask,
    reference_value_script,
    input_mask,
    enabledLower,
    accessValue,
    importUnique,
    uniqueSingleIndex,
  } = defaultValues;

  /** Данные для отображения формы редактирование */
  const initialValues = {
    fieldTypeId: editableItem ? type : fieldTypeId,
    fieldOrder: editableItem ? editableItem.fieldOrder : fieldOrder,
    origin: editableItem ? editableItem.origin : origin,
    caption: editableItem ? editableItem.caption : caption,
    description: editableItem ? editableItem.description : description,
    defaultValue: getDefaultValue({editableItem, defaultValue}),
    context_search: getOptionsValue('context_search', context_search),
    attribute_search: getOptionsValue('attribute_search', attribute_search),
    default_in_list: getOptionsValue('default_in_list', default_in_list),
    gui_editable: getOptionsValue('gui_editable', gui_editable),
    required: editableItem ? editableItem.required : required,
    uniqueIndex: editableItem ? editableItem.uniqueIndex : uniqueIndex,
    old: getOptionsValue('old', old),
    mask: editableItem ? editableItem.mask : mask,
    referenceCatalogPattern:
      !isEmpty(referenceCatalogPatternCaption) && !isEmpty(editableItem)
        ? referenceCatalogPatternCaption
        : referenceCatalogPattern,
    referenceCatalog: editableItem ? findCaptionFromUuid() : referenceCatalog,
    filter: getOptionsValue('filter', filter, true),
    control_type: getOptionsValue('control_type', control_type),
    combobox_options: getOptionsValue('combobox_options', combobox_options),
    date_format: getOptionsValue('date_format', date_format),
    date_default_value_type: getOptionsValue('date_default_value_type', date_default_value_type),
    date_duration: getOptionsValue('date_duration', date_duration),
    input_mask: getOptionsValue('input_mask', input_mask) || input_mask,
    hierarchy_field: getOptionsValue('hierarchy_field', hierarchy_field),
    multilevel_link: getOptionsValue('multilevel_link', multilevel_link),
    is_Pattern: !!getValidationData(3, false),
    validator_Pattern: !getValidationData(3, false) ? '' : getValidationData(3, false, 'value'),
    is_Length: !!getValidationData(2, true),
    validator_operator_Length: getValidationData(2, true, 'operator'),
    validator_Length: getValidationData(2, true, 'value'),
    is_NumericComparison: !!getValidationData(4, true),
    validator_operator_NumericComparison: getValidationData(4, true, 'operator'),
    validator_NumericComparison: getValidationData(4, true, 'value'),
    validator_NumericComparison_bottom: getValidationData(4, true, 'topValue'),
    validator_NumericComparison_top: getValidationData(4, true, 'bottomValue'),
    is_Enumeration: !!getValidationData(1, false),
    validator_Enumeration: getValidationData(1, false, 'value'),
    rules: getOptionsValue('rules', rules, true),
    phone_mask: getOptionsValue('phone_mask', phone_mask),
    reference_value_script: getOptionsValue('reference_value_script', reference_value_script, true),
    enabledLower: editableItem ? editableItem.enabledLower : enabledLower,
    accessValue: editableItem ? editableItem.accessValue : accessValue,
    importUnique: editableItem ? editableItem.importUnique : importUnique,
    uniqueSingleIndex: editableItem ? editableItem.uniqueSingleIndex : uniqueSingleIndex,
    valuesArray: editableItem ? fieldArrayTypes.find((field) => field.value === type) : false,
  };
  const fieldOptionsRender =
    catalogOptions &&
    catalogOptions?.show_field_options?.map((item) => {
      const validateRules = [
        {
          validator: (_, value) => {
            if (!value) {
              return Promise.resolve();
            } else {
              try {
                JSON.parse(value);
                return Promise.resolve();
              } catch (error) {
                return Promise.reject('Неверный формат JSON');
              }
            }
          },
        },
      ];

      const type = item?.fieldType;
      const defaultValue = getOptionsValue(item.option, defaultValues[item.option]);

      if (type === 'boolean' && isUndefined(defaultValue)) initialValues[item.option] = false;
      else initialValues[item.option] = defaultValue ? JSON.stringify(defaultValue) : defaultValue;

      let children;
      switch (type) {
        case 'boolean':
          children = <Checkbox name={item.option} />;
          break;
        case 'integer':
          children = <InputNumber />;
          break;
        default:
          children = <Input.TextArea />;
      }
      return (
        <Form.Item
          key={item.option}
          name={item.option}
          valuePropName={type === 'boolean' && 'checked'}
          labelCol="10"
          label={item.fieldName}
          rules={type !== 'integer' && type !== 'boolean' && validateRules}
        >
          {children}
        </Form.Item>
      );
    });

  const fieldTypeOptions = (fieldTypes) =>
    fieldTypes.map((item, index) => {
      if (editableItem && item.value === 12) {
        return null;
      } else {
        return (
          <Select.Option key={index} value={item.value}>
            {item.label}
          </Select.Option>
        );
      }
    });

  return (
    <>
      <Form
        disabled={isDisabled}
        layout={'vertical'}
        form={form}
        name="field-add"
        onFinish={onFinish}
        initialValues={initialValues}
      >
        <Form.Item
          name="valuesArray"
          valuePropName="checked"
          style={{
            marginBottom: 0,
          }}
        >
          <Checkbox
            onChange={() => {
              form.setFieldsValue({
                uniqueIndex: false,
                uniqueSingleIndex: false,
                importUnique: false,
              });
            }}
          >
            Массив значений
          </Checkbox>
        </Form.Item>
        <Form.Item
          noStyle
          shouldUpdate={(prevState, nextState) => {
            if (isBoolean(nextState.valuesArray) && prevState.valuesArray !== nextState.valuesArray) {
              const defaultValue = nextState.valuesArray ? 22 : 1;
              form.setFieldValue('fieldTypeId', defaultValue);
              onResetForm();
              return prevState.valuesArray !== nextState.valuesArray;
            }
            return prevState.hierarchy_field !== nextState.hierarchy_field;
          }}
        >
          {({getFieldValue}) => {
            const hierarchyField = getFieldValue('hierarchy_field');
            const valuesArray = getFieldValue('valuesArray');
            const fieldCurrentTypes = valuesArray ? fieldArrayTypes : fieldTypes;
            return (
              <Form.Item label="Тип поля" name="fieldTypeId" rules={requiredRule}>
                <Select
                  style={{width: '100%'}}
                  disabled={isDisabled || hierarchyField}
                  onChange={(value) => handleTypeChange(value)}
                >
                  {fieldTypeOptions(fieldCurrentTypes)}
                </Select>
              </Form.Item>
            );
          }}
        </Form.Item>
        <div className={styles.twoColumns}>
          <Form.Item
            label={fieldType === 12 ? 'Наименование' : 'Наименование атрибута'}
            name="caption"
            rules={requiredRule}
          >
            <Input disabled={isDisabled} />
          </Form.Item>
          <Form.Item
            label="Наименование в БД"
            name="origin"
            extra="Формат camelCase. Допустимо использовать английские буквы, цифры. На первом месте строчная буква."
            rules={[...requiredRule, {validator: checkFormat}]}
          >
            <Input disabled={isDisabled} />
          </Form.Item>
        </div>

        <Form.Item label="Описание" name="description">
          <Input.TextArea style={{height: 76, resize: 'none'}} disabled={isDisabled} />
        </Form.Item>
        <Form.Item
          noStyle
          shouldUpdate={(prevState, nextState) => {
            const {setFieldsValue} = form;
            if (prevState.fieldTypeId !== nextState.fieldTypeId) {
              setFieldsValue({defaultValue: nextState.fieldTypeId === 15 ? [] : null});
            }
            return prevState.fieldTypeId !== nextState.fieldTypeId;
          }}
        >
          {({getFieldValue, setFieldsValue}) => {
            const type = getFieldValue('fieldTypeId');
            switch (type) {
              case 1:
              case 22:
                return (
                  <StringFieldForm
                    isDisabled={isDisabled}
                    setFieldsValue={setFieldsValue}
                    detail={detail}
                    validator={validator}
                    defaultValue={type === 1}
                  />
                );
              case 11:
                return <TextFieldForm isDisabled={isDisabled} detail={detail} validator={validator} />;
              case 2:
              case 23:
                return (
                  <IntegerFieldForm
                    isDisabled={isDisabled}
                    detail={detail}
                    numericComparison={numericComparison}
                    defaultValue={type === 2}
                  />
                );
              case 21:
              case 24:
                return <DecimalFieldForm isDisabled={isDisabled} detail={detail} defaultValue={type === 21} />;
              case 3:
                return (
                  <FloatFieldForm
                    isDisabled={isDisabled}
                    numericComparison={numericComparison}
                    defaultValue={type === 3}
                  />
                );
              case 4:
                return (
                  <CatalogLinkFieldForm
                    isDisable={isDisabled}
                    detail={detail}
                    editableItem={editableItem}
                    uuidCatalog={uuidCatalog}
                    referenceCatalog={referenceCatalog}
                    uuid={uuid}
                    findCaptionFromUuid={findCaptionFromUuid}
                    handleReferenceCatalogChange={handleReferenceCatalogChange}
                    handleReferenceCatalogPatternChange={handleReferenceCatalogPatternChange}
                  />
                );
              case 13:
              case 25:
                return <HyperlinkFieldForm isDisabled={isDisabled} defaultValue={type === 13} />;
              case 6:
                return <UUIDFieldForm isDisabled={isDisabled} />;
              case 14:
                return (
                  <ValueListFieldForm
                    isDisabled={isDisabled}
                    detail={detail}
                    comboboxData={comboboxData}
                    mapOption={mapOption}
                    setModalVisible={setModalVisible}
                    renderDisplayWaysOpt={renderDisplayWaysOpt}
                  />
                );
              case 15:
                return (
                  <MultipleValueListFieldForm
                    isDisabled={isDisabled}
                    comboboxData={comboboxData}
                    mapOption={mapOption}
                    setModalVisible={setModalVisible}
                  />
                );
              case 8: {
                return (
                  <DateFieldForm
                    dateFormats={dateFormats}
                    isDisabled={isDisabled}
                    defaultOptions={defaultValueDateOptions}
                    form={form}
                    mapOption={mapOption}
                  />
                );
              }
              case 7: {
                return (
                  <DateFieldForm
                    dateFormats={dateTimeFormats}
                    isDisabled={isDisabled}
                    defaultOptions={defaultValueDateOptions}
                    form={form}
                    mapOption={mapOption}
                    withTime={true}
                  />
                );
              }
              case 10:
                return <BooleanFieldForm isDisabled={isDisabled} detail={detail} mapOption={mapOption} />;
              default:
                return <FieldOrder />;
            }
          }}
        </Form.Item>
        {/* MDM-2147 */}
        {/* <Form.Item label="Правила" name="rules">
          <Input.TextArea style={{height: 76, resize: 'none'}} disabled={isDisabled} />
        </Form.Item> */}
        {fieldOptionsRender}
        <Validators mapOption={mapOption} form={form} />
        <div style={{paddingBottom: 24}} className={classNames(styles.twoColumns, styles.optionsAttribute)}>
          <Form.Item noStyle shouldUpdate={(prev, next) => prev.fieldTypeId !== next.fieldTypeId}>
            {({getFieldValue}) => {
              const value = getFieldValue('fieldTypeId');
              const disabledTypes = [2, 3, 6, 7, 8, 10, 12, 15, 19, 21, 23, 24];
              return (
                <Form.Item name="context_search" valuePropName="checked">
                  <Checkbox disabled={isDisabled || includes(disabledTypes, value)}>
                    Используется для контекстного поиска
                  </Checkbox>
                </Form.Item>
              );
            }}
          </Form.Item>
          <Form.Item noStyle shouldUpdate={(prev, next) => prev.fieldTypeId !== next.fieldTypeId}>
            {({getFieldValue}) => {
              const value = getFieldValue('fieldTypeId');
              const disabledTypes = [12, 19];
              return (
                <Form.Item name="default_in_list" valuePropName="checked">
                  <Checkbox disabled={isDisabled || includes(disabledTypes, value)}>
                    Отображать в списковой форме по умолчанию
                  </Checkbox>
                </Form.Item>
              );
            }}
          </Form.Item>
          <Form.Item name="gui_editable" valuePropName="checked">
            <Checkbox disabled={isDisabled}>Редактируемое через GUI</Checkbox>
          </Form.Item>
          <Form.Item noStyle shouldUpdate={(prev, next) => prev.fieldTypeId !== next.fieldTypeId}>
            {({getFieldValue}) => {
              const value = getFieldValue('fieldTypeId');
              const disabledTypes = [10, 12, 22, 23, 24, 25];
              return (
                <Form.Item name="required" valuePropName="checked">
                  <Checkbox disabled={isDisabled || includes(disabledTypes, value)}>Обязательное на уровне БД</Checkbox>
                </Form.Item>
              );
            }}
          </Form.Item>
          <Form.Item noStyle shouldUpdate={(prev, next) => prev.fieldTypeId !== next.fieldTypeId}>
            {({getFieldValue}) => {
              const value = getFieldValue('fieldTypeId');
              const disabledTypes = [12, 22, 23, 24, 25];
              return (
                <Form.Item name="importUnique" valuePropName="checked">
                  <Checkbox disabled={isDisabled || includes(disabledTypes, value)}>Уникальное при импорте</Checkbox>
                </Form.Item>
              );
            }}
          </Form.Item>

          <Form.Item name="old" valuePropName="checked">
            <Checkbox disabled={isDisabled}>Устаревшее поле</Checkbox>
          </Form.Item>
        </div>
        <Form.Item
          // noStyle
          label="Уникальность атрибута"
          shouldUpdate={(prevState, nextState) => prevState.fieldTypeId !== nextState.fieldTypeId}
        >
          {({getFieldValue, setFieldsValue}) => {
            const value = getFieldValue('fieldTypeId');

            const disabledTypes = [10, 12, 14, 15, 22, 23, 24, 25];
            return (
              <div>
                {/* <strong>Уникальность атрибута</strong> */}
                <div className="d-grid grid-2 w-100 gap-12">
                  <Form.Item name="uniqueSingleIndex" valuePropName="checked">
                    <Checkbox
                      value={value}
                      onChange={({target: {checked}}) => {
                        if (checked) {
                          setFieldsValue({
                            uniqueIndex: false,
                          });
                        }
                      }}
                      disabled={isDisabled || includes(disabledTypes, value)}
                    >
                      Уникальный атрибут
                    </Checkbox>
                  </Form.Item>
                  <Form.Item name="uniqueIndex" valuePropName="checked">
                    <Checkbox
                      value={value}
                      onChange={({target: {checked}}) => {
                        if (checked) {
                          setFieldsValue({
                            uniqueSingleIndex: false,
                          });
                        }
                      }}
                      disabled={isDisabled || includes(disabledTypes, value)}
                    >
                      Группа уникальных атрибутов
                    </Checkbox>
                  </Form.Item>
                </div>
              </div>
            );
          }}
        </Form.Item>
        {!isDisabled && (
          <div className={styles.buttonGroup}>
            <Button
              icon={<CloseOutlined />}
              loading={loading}
              onClick={() => props.closeModal('editFieldModalVisible')}
            >
              Отмена
            </Button>
            <Button type="primary" icon={<SaveOutlined />} htmlType="submit" loading={loading}>
              Сохранить
            </Button>
          </div>
        )}
        <Modal
          open={modalVisible}
          onCancel={() => {
            setModalVisible(false);
          }}
          width={960}
          destroyOnClose={true}
          footer={null}
          title={mdm.listItems}
        >
          <DetailTable
            disabled={isDisabled}
            data={!isEmpty(comboboxData) ? comboboxData : []}
            setComboboxData={setComboboxData}
            okModal={okModal}
            onCancel={() => {
              setModalVisible(false);
            }}
            detailSubmit={detailSubmit}
          />
        </Modal>
      </Form>
    </>
  );
};

export default React.memo(EditFieldForm);
