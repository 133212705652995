import {deduplication} from './constants';
import stylesJob from '../DeduplicationJob/DeduplicationJob.module.scss';

import {CloseOutlined, EditOutlined, ExportOutlined, SaveOutlined} from '@ant-design/icons';
import {renderButtonDeduplicationTextSave} from './DeduplicationUtilsLegacy';
import {Button} from 'ui-kit';
import {EDeduplicationStatus, RecordType} from 'types';
import {Link} from 'react-router-dom';

export * from './DeduplicationUtilsLegacy';

/** Получаем значение статуса для отображения  */
export const renderStatusDeduplicationColor = (status: number) => {
  switch (status) {
    case EDeduplicationStatus.new:
      return stylesJob.status__new;
    case EDeduplicationStatus.process:
      return stylesJob.status__process;
    case EDeduplicationStatus.order:
      return stylesJob.status__order;
    case EDeduplicationStatus.error:
      return stylesJob.status__error;
    case EDeduplicationStatus.success:
      return stylesJob.status__success;
    default:
      return;
  }
};

export const renderButtonDeduplicationTextCancel = (status: number, formStatus: string) => {
  if (formStatus === 'editStatus') {
    const closeStatuses = [EDeduplicationStatus.process, EDeduplicationStatus.order, EDeduplicationStatus.success];
    if (closeStatuses.includes(status)) {
      return deduplication.close;
    }
  }

  return deduplication.cancel;
};

export const renderButtonDeduplicationCancel = ({
  status,
  formStatus,
  onClick,
}: {
  status: number;
  formStatus: string;
  onClick: () => void;
}) => {
  const isClosed = !![2, 3, 5].find((value) => value === status);

  return (
    <Button type={isClosed ? 'primary' : 'default'} icon={<CloseOutlined />} danger={isClosed} onClick={onClick}>
      {renderButtonDeduplicationTextCancel(status, formStatus)}
    </Button>
  );
};

export const renderButtonDeduplicationSubmit = ({
  status,
  formStatus,
}: {
  status: number;
  formStatus: string;
  onClick: () => void;
}) => {
  const hiddenStatuses = [EDeduplicationStatus.process, EDeduplicationStatus.order, EDeduplicationStatus.success];
  const isHidden = hiddenStatuses.includes(status);
  const isEditIcon = formStatus === 'editStatus' && (status === 1 || status === 4);
  const isSaveIcon = formStatus === 'createNewDeduplication';

  if (isHidden) {
    return;
  }
  return (
    <Button
      type="primary"
      htmlType="submit"
      icon={(isSaveIcon && <SaveOutlined />) || (isEditIcon && <EditOutlined />)}
    >
      {renderButtonDeduplicationTextSave(status, formStatus)}
    </Button>
  );
};

export const fieldTypesFilter = (fields: any[]) => {
  const nonArrayFieldTypes = [1, 11, 13, 2, 3, 21, 4, 14, 15, 10, 8, 7, 19, 6];

  return fields.filter((field) => {
    return nonArrayFieldTypes.includes(field?.fieldType?.id);
  });
};

export const renderDeduplicationValue = (recordsValue: any) => {
  if (!recordsValue) {
    return '';
  }
  if (typeof recordsValue.value === 'string' || typeof recordsValue.value === 'number') {
    return recordsValue.value;
  }

  if (typeof recordsValue.value === 'boolean') {
    return recordsValue.value ? 'True' : 'False';
  }

  if (typeof recordsValue.value === 'object' && recordsValue?.referenceCatalogPattern) {
    const path = recordsValue.referenceCatalogPattern.split(';');
    const value = recordsValue.value?.[path?.[1]];
    if (!recordsValue?.value?.uuid) {
      return null;
    }
    return (
      <div className="d-flex gap-8 align-items-center">
        <span>{value}</span>
        {` `}
        <Link
          target="_blank"
          to={`/catalog/${recordsValue?.value?.catalogId?.catalogUuid}/${recordsValue?.value?.uuid}`}
          onClick={() =>
            window.open(`/catalog/${recordsValue?.value?.catalogId?.catalogUuid}/${recordsValue?.value?.uuid}`)
          }
        >
          <span>
            <ExportOutlined />
          </span>
        </Link>
      </div>
    );
  }
  return '';
};
