export enum Eroute {
  // catalogs
  VerifyOrgCard = 'VerifyOrgCard',
  VerifyCard = 'VerifyCard',
  CatalogItemRecord = 'CatalogItemRecord',
  CatalogItem = 'CatalogItem',
  Catalogs = 'Catalogs',
  CatalogItemAdd = 'CatalogItemAdd',
  Comparsion = 'Comparsion',

  // deduplication
  DeduplicationProtocol = 'DeduplicationProtocol',
  DeduplicationProtocols = 'DeduplicationProtocols',
  DeduplicationProtocolForm = 'DeduplicationProtocolForm',
  DeduplicationJob = 'DeduplicationJob',

  // Verification
  Verification = 'Verification',
  VerifyRecords = 'VerifyRecords',
  VerifierList = 'VerifierList',
  VerifierOrgList = 'VerifierOrgList',

  // notification
  NotificationPageTemplates = 'NotificationPageTemplates',
  NotificationPageList = 'NotificationPageList',

  //scheduler
  SchedulerTasks = 'SchedulerTasks',
  SchedulerCalendar = 'SchedulerCalendar',

  // admin
  CatalogItemImport = 'CatalogItemImport',
  CatalogDraftCard = 'CatalogDraftCard',
  CatalogsDraft = 'CatalogsDraft',
  CatalogGroupAccessPage = 'CatalogGroupAccessPage',

  // system catalogs
  ExternalSystemsList = 'ExternalSystemsList',
  Subscriptions = 'Subscriptions',
  SubsystemsList = 'SubsystemsList',

  // unsorted
  CatalogsOrgCard = 'CatalogsOrgCard',
  CatalogsOrgList = 'CatalogsOrgList',
  DetailDraftRedactor = 'DetailDraftRedactor',
  ServiceCard = 'ServiceCard',
  Services = 'Services',
  LinkTypes = 'LinkTypes',
  SettingsPage = 'SettingsPage',
  ContactsPage = 'ContactsPage',
  NotAccessPage = 'NotAccessPage',
  NotFoundPage = 'NotFoundPage',
  NotFoundPageAny = 'NotFoundPageAny',

  // Main Menu
  MenuCatalogs = 'MenuCatalogs',
  MenuDeduplication = 'MenuDeduplication',
  MenuVerifiaction = 'MenuVerifiaction',
  MenuNotification = 'MenuNotification',
  MenuScheduler = 'MenuScheduler',
  MenuAdmin = 'MenuAdmin',
  MenuSystemCatalogs = 'MenuSystemCatalogs',
  MainPage = 'MainPage',
}
