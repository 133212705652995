export const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
export const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

export const CBLayout = {
  wrapperCol: {offset: 8, span: 16},
};

export const buttonsLayout = {
  wrapperCol: {offset: 14, span: 10},
};

export const helpersModal = {
  info: ['7TECH MDM', `© 2020 - ${new Date().getFullYear()} ООО «7тек».`, 'Все права защищены.'],
};

export const headerMDM = {
  headTitle: '7TECH MDM',
  about: 'О программе',
  url: 'https://www.7tech.ru/',
  version: 'Версия приложения:',
  productVersion: 'Версии продукта:',
  component: 'Компонент',
  versionTable: 'Версия',
};
