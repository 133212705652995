import React, {useState, useEffect} from 'react';
import {Table, Button, Tooltip, Modal} from 'ui-kit';
import useCRUD from '../../hooks/CRUD.hook';
import CatalogCrossLinkForm from './CatalogCrossLinkForm';
import {DeleteOutlined} from '@ant-design/icons';

const CatalogCrosLinks = ({recordUuid}) => {
  const columns = [
    {
      title: 'Кросс-связи',
      dataIndex: 'caption',
      key: 'linkType',
    },
  ];
  const size = 1000;
  const [modalVisible, setModalVisible] = useState(false);
  const [page, setPage] = useState(0);
  const [editMode, setEditMode] = useState(null);
  const [tableData, setTableData] = useState([]);

  const {getData, changeData, getItem, record, clearRecord, data, loading} = useCRUD({
    apiUrl: `/api/v1/catalogs/org/items/${recordUuid}/links`,
    idField: 'id',
  });

  useEffect(() => {
    getData({page, size});
  }, [page]);

  useEffect(() => {
    prepeareTableData();
  }, [data]);

  const prepeareTableData = async () => {
    try {
      let tableData = [];
      data.forEach((item) => {
        const index = tableData.findIndex((elem) => elem.linkTypeId === item.linkType.id);
        if (index !== -1) {
          tableData[index].elems.push(item);
        } else
          tableData.push({
            caption: item.linkType.caption,
            linkTypeId: item.linkType.id,
            elems: [item],
          });
      });
      setTableData(tableData);
    } catch (error) {
      console.log(error);
    }
  };

  const changeLink = async (values) => {
    try {
      await changeData(values, record);
      getData({page, size});
      setModalVisible(false);
    } catch (error) {}
  };

  const onEditClick = async (mode, id) => {
    try {
      await getItem(id);
      setEditMode(mode);
      setModalVisible(true);
    } catch (error) {}
  };

  const handleTableChange = ({current}) => {
    setPage(current - 1);
  };

  const expandedRowRender = (record) => {
    const columns = [
      {title: 'Связный элемент', dataIndex: ['item', 'caption'], key: 'item'},
      {title: 'Тип элемента', dataIndex: ['catalog', 'caption'], key: 'catalog'},
      {title: 'Дата прекращения', dataIndex: 'expiryDate', key: 'expiryDate'},
      {title: 'Дата создания', dataIndex: 'linkAssignmentDate', key: 'linkAssignmentDate'},
      {
        key: 'del',
        render: (text, record) => (
          <Tooltip title="Прекратить связь">
            <DeleteOutlined onClick={() => onEditClick('delete', record.id)} />
          </Tooltip>
        ),
      },
    ];

    return <Table columns={columns} dataSource={record.elems} pagination={false} onRow={onRow} />;
  };

  const onRow = (record) => {
    return {
      onDoubleClick: async () => {
        await onEditClick('edit', record.id);
      },
    };
  };

  return (
    <div className="org-list__no-structure-items container--white">
      <Button
        type="primary"
        className="mb-1"
        onClick={() => {
          setModalVisible(true);
          clearRecord();
          setEditMode('create');
        }}
      >
        Добавить
      </Button>
      <Table
        columns={columns}
        expandable={{expandedRowRender}}
        dataSource={tableData}
        rowKey={(record) => record.linkTypeId}
        loading={loading}
        onChange={handleTableChange}
      />
      <Modal open={modalVisible} onCancel={() => setModalVisible(false)} title="Создание" footer={null} destroyOnClose>
        <CatalogCrossLinkForm
          editibleItem={record}
          editMode={editMode}
          onCancel={() => setModalVisible(false)}
          onSubmit={changeLink}
        />
      </Modal>
    </div>
  );
};

export default CatalogCrosLinks;
