import React, {Component, SetStateAction} from 'react';
import {connect} from 'react-redux';
import _, {get, isBoolean, isEmpty, isEqual, isFunction, isObject} from 'lodash';
import moment from 'moment';
import {saveAs} from 'file-saver';
import {CheckOutlined, CloseOutlined, UnlockOutlined} from '@ant-design/icons';

import {
  convertExpoToString,
  createReferenceScriptText,
  defaultDateFormat,
  getReportStructure,
  renderChangeType,
  renderStatus,
  renderVerifierFio,
  sortFieldsByOrder,
  toDecimal,
} from '../../Helpers/Utils';
import RecordTable from '../../RecordTable';
import AccessRights from '../../AccessRights';
import RecordRedactor from '../../RecordRedactor';
import CatalogsFilters from '../../CatalogsFilters';
import {setFilterReset, setPageHeaderButtons} from '../../../redux/actions/actions';
import {fetchFunc} from '../../../Utils/security/http/mdm';
import {errorModalCreate} from '../../Helpers/Modals';
import style from './Records.module.scss';
import {ButtonProps, Modal, Tooltip} from 'ui-kit';
import {RecordType} from 'types';
import {DataContentType, EFileFormat, FilterAttributeType, ModeType, ResponseErrorType} from './types';
import {History} from 'history';
import {RootState} from 'redux/types/rootState';
import {Dispatch} from 'redux';
import {TablePaginationConfig} from 'antd/es/table/interface';

export interface RecordsProps {
  isDisabled?: boolean;
  mode?: string;
  validateCatalog?: boolean;
  defaultData?: RecordType[];
  referenceOrigin: string;
  versionId?: string;
  value?: RecordType[];
  changedDetail?: string;
  changedField: boolean;
  detail?: string;
  resetFilter: boolean;
  setResetFlagFilter: (value: boolean) => void;
  recordUuid?: string;
  isVerifier?: boolean;
  isVerification?: boolean;
  isUnverified?: boolean;
  userRole?: string;
  changedMode?: 'create';
  defaultFilter?: RecordType;
  additionalMeta?: boolean;
  additionalFields?: any[];
  customEditModal?: boolean;
  rootValues?: boolean;
  rootForm?: boolean;
  renderCustomTable?: string;
  structure?: boolean;

  isRestored?: boolean;
  hasContextSearch?: boolean;
  referenceCaption?: string;
  isHierarchy?: boolean;
  hasDuplicates?: boolean;
  inputType?: number;
  referenceUuid?: string;
  location?: Location;
  match?: any;
  additionalColumns?: RecordType[];
  editSigns?: {
    editAttributes: boolean;
    editCatalog: boolean;
    editProperties: boolean;
    inputType: boolean;
  };
  maxRefsDepth?: number;
  viewRoute?: boolean;
  filterData?: RecordType;
  contextString?: string;
  historyData?: RecordType;
  onDoubleClick?: (record: RecordType) => void;
  history: History;
  editable?: boolean;
  isEdit?: boolean;

  // unused variables
  setTableColumns?: (() => void) | any;
  tableColumns?: any;
  setTableSource?: any;
  tableSource?: any;
  detailData?: any;
  isOriginVersion?: boolean;
  isVerificator?: boolean;
  changeType?: any;
}

export interface RecordsState {
  editSigns?: boolean;
  filedTypeIds: number[];
  columns: boolean[];
  columnsForRender?: boolean[];
  data: any[];
  isVerification?: boolean;
  isVerifier?: boolean;
  columnSelect: boolean[];
  loading?: boolean;
  filterAttributes: FilterAttributeType[];
  modalVisible: boolean;
  mode?: ModeType;
  isEdit?: boolean;
  detailUuid?: string;
  modalRulesShow: boolean;
  rulesRecordUuid?: string;
  fields: boolean[];
  newElement: RecordType;
  comboboxColumns: RecordType[];
  dateColumns: RecordType[];
  referenceColumns: RecordType[];
  sort: RecordType;
  filter?: RecordType;
  historyFilter?: RecordType;
  itemData?: RecordType[];
  // isTree: boolean;
  isDeduplicationRecordsAll: boolean;
  hierarchyFields: {
    origin: string;
  }[];
  isHierarchyFields?: boolean;
  tableParams: {
    pagination: {
      current: number;
      pageSize: number;
      showSizeChanger?: boolean;
      total?: number;
    };
  };
  totalElements?: number;
  search?: string;
  displayedColumns?: string[];
  csvLoading?: boolean;
  jsonLoading?: boolean;
  xmlLoading?: boolean;
  xlsxLoading?: boolean;
  xlsLoading?: boolean;
  isContextSearch?: boolean;
  isFilterSearch?: boolean;
  convertedItemData?: RecordType;
  mainAttrList?: RecordType[];
  defaultRecord?: RecordType;
  dataFilter?: boolean;
  totalElementsWithMainUuid?: number;
}

const verifyColumns = [
  {
    title: 'Статус',
    key: 'status',
    dataIndex: ['sysVerifyInfo', 'status'],
    render: (text: string, record: RecordType) => renderStatus(record, 'sysVerifyInfo.status'),
  },
  {
    title: 'Тип изменения',
    key: 'changeType',
    dataIndex: ['sysVerifyInfo', 'changeType'],
    render: (text: string) => renderChangeType(text),
  },
  {
    title: 'Дата начала верификации',
    key: 'dateFrom',
    dataIndex: ['sysVerifyInfo', 'dateFrom'],
    render: (text: string) => moment(text).format(defaultDateFormat),
  },
  {
    title: 'Верификатор',
    key: 'verifier',
    dataIndex: ['sysVerifyInfo', 'verifier'],
    render: (text: string, record: RecordType) => renderVerifierFio('sysVerifyInfo.verifier', record),
  },
];

class Records extends Component<RecordsProps, RecordsState> {
  constructor(props: RecordsProps) {
    super(props);
    this.state = {
      filedTypeIds: [1, 2, 3, 4, 7, 8, 10, 11, 13, 14, 15],
      columns: [],
      columnSelect: [],
      data: [],
      loading: false,
      filterAttributes: [{field: '', type: '', contains: ''}],
      modalVisible: false,
      modalRulesShow: false,
      fields: [],
      newElement: {},
      comboboxColumns: [],
      dateColumns: [],
      referenceColumns: [],
      sort: {},
      filter: {},
      itemData: this.props.defaultData,
      // isTree: false,
      isDeduplicationRecordsAll: false,
      hierarchyFields: [],
      tableParams: {
        pagination: {
          current: 1,
          pageSize: 10,
        },
      },
    };
  }

  componentDidMount() {
    if (this.props.referenceOrigin) {
      if (!this.props.defaultData && this.props.mode != 'create' && this.props.changeType !== '') {
        if (this.props.tableColumns) {
          this.setState({
            sort: {
              field: 'id',
              order: 'desc',
            },
          });
        }
        this.setState(
          {
            loading: true,
          },
          () => this.getCatalogItem()
        );
      } else {
        this.getTableData();
      }
      this.getCatalogFields().then();
    }
  }

  getTableSource = (isDataUpdate?: boolean) => {
    const {convertedItemData} = this.state;
    const {tableColumns, tableSource, isOriginVersion, setTableSource} = this.props;
    let isAllRecords = false;
    if (tableSource) {
      for (const key in tableSource) {
        isAllRecords = tableSource[key]?.length === convertedItemData?.length;
      }
    }

    const isAllowedSetTableSource =
      tableSource &&
      Object.keys(tableSource).length < 2 &&
      (!isAllRecords || !isOriginVersion) &&
      !isEmpty(tableColumns) &&
      !isEmpty(convertedItemData);

    if (isAllowedSetTableSource || isDataUpdate) {
      const itemArray: any[] = [];

      convertedItemData?.forEach((item: any) => {
        const requiredData: any = {};
        tableColumns &&
          tableColumns?.forEach((column: any) => {
            const {dataIndex} = column;
            requiredData[dataIndex] = item[dataIndex];
          });

        itemArray.push(requiredData);
      });
      let versionRecord = 'newVersion';
      if (isOriginVersion) {
        versionRecord = 'originVersion';
      }
      setTableSource && setTableSource((prev: any) => ({...prev, [versionRecord]: itemArray}));
    }
  };

  componentDidUpdate(prevProps: RecordsProps, prevState: RecordsState) {
    const {
      referenceOrigin,
      value,
      changedDetail,
      changedField,
      detail,
      resetFilter,
      isRestored,
      setResetFlagFilter,
      detailData,
      setTableColumns,
      tableColumns,
      tableSource,
    } = this.props;
    const {itemData, columns, convertedItemData} = this.state;

    if (columns !== prevState.columns) {
      setTableColumns && setTableColumns([...columns, {dataIndex: 'uuid'}]);
    }

    if (
      !isEmpty(tableColumns) &&
      tableColumns &&
      tableColumns?.length > 1 &&
      !isEmpty(convertedItemData) &&
      Object.keys(tableSource).length < 2 &&
      !isEqual(convertedItemData, prevState.convertedItemData)
    ) {
      this.getTableSource();
    }

    if (
      convertedItemData &&
      tableSource &&
      (!isEqual(convertedItemData, prevState.convertedItemData) || !isEqual(tableSource, prevProps.tableSource)) &&
      Object.keys(tableSource).length == 2
    ) {
      this.getTableSource(true);
    }

    if (
      (changedDetail === detail && changedField !== prevProps?.changedField) ||
      prevProps?.isRestored !== isRestored
    ) {
      this.setState(
        {
          loading: true,
        },
        () => {
          this.getCatalogItem();
          this.getCatalogFields();
        }
      );
    }
    if (prevProps?.referenceOrigin !== referenceOrigin) {
      if (!this.props.versionId && !this.props.defaultData) {
        this.getCatalogItem().then();
      }
      this.getCatalogFields().then();
    }
    if (detailData && !isEqual(detailData, prevProps?.detailData)) {
      this.getCatalogItem().then();
    }
    if ((prevState?.itemData !== itemData || prevState?.columns !== columns) && columns && itemData) {
      this.getTableData();
    }
    if (prevProps?.value !== value) {
      this.setState({
        itemData: value,
        totalElements: value?.length || 0,
        loading: false,
      });
    }
    if (prevProps?.resetFilter !== resetFilter && resetFilter) {
      this.applyFilter();
      setResetFlagFilter(false);
    }
  }

  setTreeDataChildren = (parents: RecordType[], dataContent: DataContentType) => {
    parents.forEach((parent: RecordType) => {
      const children = this.findChildrenItem(parent.uuid, dataContent);
      if (children.length) {
        this.setTreeDataChildren(children as any, dataContent);
        parent.children = children;
      }
    });
  };
  prepareTableTreeData = (dataContent: DataContentType) => {
    const {hierarchyFields} = this.state;
    const parents = dataContent.filter(
      (item) => !!hierarchyFields.every((hierarchyFieldsItem) => !item[hierarchyFieldsItem.origin])
    );
    this.setTreeDataChildren(parents, dataContent);
    return parents;
  };
  getCatalogFields = async () => {
    try {
      const {referenceOrigin, detail, versionId} = this.props;
      let url = `/api/v1/catalogs/${referenceOrigin}/fields?size=1000`;
      if (detail) {
        url = `/api/v1/catalogs/${referenceOrigin}/detail/${detail}/fields?showDetails=0&showRefs=0&type=base&size=1000`;
      }
      const data = await fetchFunc({
        url,
        method: 'get',
      });
      const hierarchyFields = data.content.filter(
        (elem: RecordType) => elem?.options && elem?.options?.hierarchy_field
      );
      this.setState(
        {
          data: data.content,
          hierarchyFields,
          isHierarchyFields: !!hierarchyFields,
        },
        () => {
          this.convertFields();
          this.getFieldsList();
        }
      );
      if (versionId) {
        this.setState({loading: false});
      }
    } catch (error) {
      this.setState({loading: false});
    }
  };
  getCatalogItem = async () => {
    try {
      const {
        referenceOrigin,
        detail,
        recordUuid,
        isVerifier,
        isUnverified,
        userRole,
        maxRefsDepth,
        match,
        isHierarchy,
        changedMode,
        detailData,
        versionId,
        isOriginVersion,
        changeType,
      } = this.props;
      const {
        sort,
        // isTree,
        tableParams,
      } = this.state;
      let sortParam = '';
      if (sort && sort.order) {
        sortParam = `${sort.field},${sort.order}`;
      } else {
        sortParam = 'id,desc';
      }
      let url = `/api/v1/catalogs/${referenceOrigin}/items/search/extended`;
      let recordUuidForUrl = recordUuid;
      const newUuid = match.params.id;
      if (newUuid) recordUuidForUrl = newUuid;
      if (isEmpty(recordUuid) && isEmpty(newUuid)) recordUuidForUrl = match.params.item;
      if (detail) {
        const parentUuid = recordUuid ? recordUuid : recordUuidForUrl;
        if (isUnverified) {
          url = `/api/v1/catalogs/${referenceOrigin}/detail/${detail}/${parentUuid}/items/unverified/search/extended`;
        } else url = `/api/v1/catalogs/${referenceOrigin}/detail/${detail}/${parentUuid}/items/search/extended`;
      }
      if (isVerifier) {
        url = `/api/v1/catalogs/${referenceOrigin}/items/unverified/extended?${
          userRole === 'verificator' ? 'verifyStatus=verification' : ''
        }`;
      }
      if (!versionId && (changeType !== 'add' || !isOriginVersion)) {
        const data = await fetchFunc({
          url,
          method: 'post',
          data: this.makeBody(),
          params: {
            // page: (isTree && isHierarchy === true) || changedMode === 'create' ? 0 : tableParams.pagination.current,
            page: tableParams.pagination.current - 1,
            size: tableParams.pagination.pageSize,
            sort: sortParam,
            showRefs: maxRefsDepth || 1,
          },
        });

        const tableData = data.content;

        this.setState({
          itemData: tableData,
          loading: false,
          tableParams: {
            pagination: {
              current: tableParams.pagination.current,
              pageSize: data.size,
              total: data.totalElements,
            },
          },
        });
      } else {
        this.setState({
          itemData: detailData,
          loading: false,
        });
      }
    } catch (error) {
      this.setState({loading: false});
    }
  };
  findChildrenItem = (uuid: string, dataContent: DataContentType) => {
    const {hierarchyFields} = this.state;
    return dataContent.reduce((acc, item) => {
      const parentUuids = hierarchyFields.reduce((acc, hierarchyFieldsItem) => {
        const uuid: string = item[hierarchyFieldsItem.origin] && item[hierarchyFieldsItem.origin].uuid;
        if (uuid) acc.push(uuid);
        return acc;
      }, [] as any[]);

      if (parentUuids.includes(uuid)) {
        acc.push({
          key: `${item.uuid}_${uuid}`,
          ...item,
        });
      }
      return acc;
    }, []);
  };

  makeBody = () => {
    const {search, filter, historyFilter, hierarchyFields, isDeduplicationRecordsAll} = this.state;
    const {defaultFilter, isVerifier, isUnverified, detail, recordUuid} = this.props;
    let body: RecordType = {
      filter: {
        or: [],
      },
    };
    if (isDeduplicationRecordsAll !== true) {
      body = {
        filter: {
          ...body.filter,
          and: [{mainItemUuid: null}],
        },
      };
    }
    // if (!isEmpty(hierarchyFields) && isTree === true) {
    //   return hierarchyFields.reduce((acc: RecordType, item) => {
    //     acc[item.origin] = null;
    //     return acc;
    //   }, {});
    // }
    const isUnverifiedDetail = isUnverified && detail;
    if (isVerifier || isUnverifiedDetail) body = {filter: {or: []}};
    if (filter && !isEmpty(filter)) body.filter.or.push({...filter});
    if (!isEmpty(historyFilter)) body.filter.or.push({...historyFilter});
    if (defaultFilter) body = defaultFilter;
    if (search) body.filter.or.push({'%context_filter': search});
    return body;
  };

  downloadCsv = async () => {
    try {
      const {columns, displayedColumns, sort} = this.state;
      if (!displayedColumns?.length)
        return errorModalCreate(
          'Нет колонок, доступных для вывода в отчёт. Добавьте необходимые колонки в списковую форму'
        );
      this.setState({csvLoading: true});
      const {referenceOrigin} = this.props;
      const sorter = [];
      if (sort.order) {
        sorter.push(`${sort.field},${sort.order}`);
      }
      const data = await fetchFunc({
        url: `/api/v1/catalogs/${referenceOrigin}/items/report?showRefs=${this.props.maxRefsDepth || '1'}`,
        method: 'post',
        data: {
          filter: this.makeBody().filter,
          reportStructure: getReportStructure(columns, displayedColumns),
          sort: sorter,
        },

        responseType: 'blob',
      });
      saveAs(data, `${referenceOrigin}.csv`);
      this.setState({csvLoading: false});
    } catch (error) {
      this.setState({csvLoading: false});
    }
  };
  /** Загрузка Json файла */
  downloadJson = async () => {
    try {
      const {columns, displayedColumns, sort, filter} = this.state;
      if (!displayedColumns?.length)
        return errorModalCreate(
          'Нет колонок, доступных для вывода в отчёт. Добавьте необходимые колонки в списковую форму'
        );
      this.setState({jsonLoading: true});
      const {referenceOrigin} = this.props;
      const sorter = [];
      if (sort.order) {
        sorter.push(`${sort.field},${sort.order}`);
      }
      const data = await fetchFunc({
        url: `/api/v1/catalogs/${referenceOrigin}/items/report?showRefs=${this.props.maxRefsDepth || '1'}`,
        method: 'post',
        data: {
          filter,
          format: 'JSON',
          reportStructure: getReportStructure(columns, displayedColumns),
          sort: sorter,
        },
        responseType: 'blob',
      });
      saveAs(data, `${referenceOrigin}.json`);
      this.setState({jsonLoading: false});
    } catch (error) {
      this.setState({jsonLoading: false});
    }
  };

  onError = (response: ResponseErrorType) => {
    const blob = response?.data;
    const reader = new FileReader();

    reader.addEventListener(
      'load',
      () => {
        if (typeof reader.result === 'string') {
          const responseObject = JSON.parse(reader.result);

          errorModalCreate(responseObject.message || 'Неизвестная ошибка');
        }
      },
      false
    );

    if (blob) {
      reader.readAsText(blob);
    } else {
      errorModalCreate((response && response.data.message) || 'Неизвестная ошибка');
    }
  };

  /** Загрузка XML файла */
  downloadXml = async () => {
    try {
      const {columns, displayedColumns, sort} = this.state;
      if (!displayedColumns?.length)
        return errorModalCreate(
          'Нет колонок, доступных для вывода в отчёт. Добавьте необходимые колонки в списковую форму'
        );
      this.setState({xmlLoading: true});
      const {referenceOrigin} = this.props;
      const sorter = [];
      if (sort.order) {
        sorter.push(`${sort.field},${sort.order}`);
      }

      const data = await fetchFunc(
        {
          url: `/api/v1/catalogs/${referenceOrigin}/items/report?showDetails=0&showRefs=${this.props.maxRefsDepth || '1'}`,
          method: 'post',
          data: {
            // filter: {},
            filter: this.makeBody().filter,
            reportStructure: getReportStructure(columns, displayedColumns),
            sort: sorter,
            format: 'XML',
          },
          responseType: 'blob',
        },
        this.onError
      );
      saveAs(data, `${referenceOrigin}.xml`);
      this.setState({xmlLoading: false});
    } catch (error) {
      this.setState({xmlLoading: false});
    }
  };
  /** Загрузка XLSX файла */
  downloadXlsx = async () => {
    try {
      const {columns, displayedColumns, sort} = this.state;
      if (!displayedColumns?.length)
        return errorModalCreate(
          'Нет колонок, доступных для вывода в отчёт. Добавьте необходимые колонки в списковую форму'
        );
      this.setState({xlsxLoading: true});
      const {referenceOrigin} = this.props;
      const sorter = [];
      if (sort.order) {
        sorter.push(`${sort.field},${sort.order}`);
      }
      const data = await fetchFunc({
        url: `/api/v1/catalogs/${referenceOrigin}/items/report?showRefs=${this.props.maxRefsDepth || '1'}`,
        method: 'post',
        data: {
          filter: this.makeBody().filter,
          reportStructure: getReportStructure(columns, displayedColumns),
          sort: sorter,
          format: 'XLSX',
        },

        responseType: 'blob',
      });
      saveAs(data, `${referenceOrigin}.xlsx`);
      this.setState({xlsxLoading: false});
    } catch (error) {
      this.setState({xlsxLoading: false});
    }
  };
  /** Загрузка XLS файла */
  downloadXls = async () => {
    try {
      const {columns, displayedColumns, sort} = this.state;
      if (!displayedColumns?.length)
        return errorModalCreate(
          'Нет колонок, доступных для вывода в отчёт. Добавьте необходимые колонки в списковую форму'
        );
      this.setState({xlsLoading: true});
      const {referenceOrigin} = this.props;
      const sorter = [];
      if (sort.order) {
        sorter.push(`${sort.field},${sort.order}`);
      }
      const data = await fetchFunc({
        url: `/api/v1/catalogs/${referenceOrigin}/items/report?showRefs=${this.props.maxRefsDepth || '1'}`,
        method: 'post',
        data: {
          filter: this.makeBody().filter,
          reportStructure: getReportStructure(columns, displayedColumns),
          sort: sorter,
          format: 'XLS',
        },

        responseType: 'blob',
      });
      saveAs(data, `${referenceOrigin}.xls`);
      this.setState({xlsLoading: false});
    } catch (error) {
      this.setState({xlsLoading: false});
    }
  };
  /** Выбор функции, в зависимости от выбранного формата */
  renderFormats = (format: EFileFormat) => {
    switch (format) {
      case 'CSV':
        return this.downloadCsv();
      case 'JSON':
        return this.downloadJson();
      case 'XLSX':
        return this.downloadXlsx();
      case 'XLS':
        return this.downloadXls();
      case 'XML':
        return this.downloadXml();
    }
  };

  convertFields = () => {
    const {data} = this.state;
    data.forEach((item) => {
      if (!item.options) {
        item.options = {
          gui_editable: true,
        };
        switch (item.fieldType.id) {
          case 1:
          case 2:
          case 3:
          case 7:
          case 8:
          case 10:
          case 11:
          case 13:
            item.options = {
              default_in_list: true,
            };
            break;
          default:
            return false;
        }
      }
    });
    const isContextSearch =
      this.props.hasContextSearch && !!data.find((item) => item?.options?.context_search === true);
    const isFilterSearch = !!data.find((item) => item.options?.attribute_search === true);
    this.setState({isContextSearch, isFilterSearch});
    this.getColumnsList();
  };

  getColumnsList = () => {
    const {detail, isVerification, isVerifier, editSigns, additionalColumns, viewRoute} = this.props;
    const {data} = this.state;
    let columns = data;
    const defaultColumns = this.getDefaultColumns();
    const comboboxColumns: RecordType[] = [];
    const dateColumns: RecordType[] = [];
    const referenceColumns: RecordType[] = [];
    const newColumns = sortFieldsByOrder(columns)
      .map((item: RecordType) => {
        let obj: RecordType | null = {
          fieldOrder: item.fieldOrder,
          title: item.caption,
          dataIndex: item.origin,
          key: item.key || item.origin,
          sorter: true,
          className: 'column--text',
        };
        switch (item.fieldType && item.fieldType.id) {
          case 3:
          case 21:
            obj = {
              ...obj,
              render: (text: number) => toDecimal(text) || '',
            };
            break;
          case 4:
            const {referenceCatalog, referenceCatalogPattern, origin, options} = item;
            referenceColumns.push(item);
            const refScript = options && options.reference_value_script;
            let pathToData = origin;
            if (!refScript) {
              const displayedField = referenceCatalogPattern ? referenceCatalogPattern.split(';')[1] : 'name';
              pathToData = [origin, displayedField];
              if (displayedField && displayedField.indexOf('.') !== -1) {
                pathToData = [origin, ...displayedField.split('.')];
              }
            }
            obj = {
              ...obj,
              dataIndex: pathToData,
              render: (text: string, record: RecordType) => (
                <a
                  href={
                    viewRoute
                      ? `${viewRoute}/${referenceCatalog && referenceCatalog.uuid}`
                      : `/catalog/${referenceCatalog && referenceCatalog.uuid}/${record[origin] && record[origin].uuid}`
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {refScript
                    ? text
                      ? createReferenceScriptText(text, refScript)
                      : ''
                    : isObject(text)
                      ? JSON.stringify(text)
                      : text}
                </a>
              ),
            };
            break;
          case 7:
            obj = {
              ...obj,
              render: (text: string) =>
                text ? moment(text).format(get(item, 'options.date_format') || 'YYYY-MM-DD HH:mm:ss') : '',
            };
            break;
          case 8:
            obj = {
              ...obj,
              render: (text: string) => {
                const dateFormat = get(item, 'options.date_format') || 'YYYY-MM-DD';
                if (text) {
                  switch (dateFormat) {
                    case 'C YYYY':
                      return `${moment(text).format('YYYY')}-${moment(text).quarter()}`;
                    case 'MM YYYY':
                      return moment(text).format('MM.YYYY');
                    default:
                      return moment(text).format(dateFormat);
                  }
                } else return '';
              },
            };
            break;
          case 10:
            obj = {
              ...obj,
              render: (text: string) => (text ? <CheckOutlined /> : <CloseOutlined />),
            };
            break;
          case 12:
          case 19:
            obj = null;
            break;
          case 13:
            obj = {
              ...obj,
              render: (text: string) => (
                <a href={text} target="_blank">
                  {text}
                </a>
              ),
            };
            break;

          case 22:
          case 23:
          case 24:
            obj = {
              ...obj,
              render: (array: string[]) => {
                const splitArray = array?.map((item) => <div>{item}</div>);
                return splitArray || '';
              },
            };
            break;
          case 25:
            obj = {
              ...obj,
              render: (links: string[]) => {
                return links
                  ? links.map((link, index, arr) => (
                      <div>
                        <a href={link} target="_blank" rel="noopener noreferrer">
                          {link}
                        </a>
                      </div>
                    ))
                  : '';
              },
            };
            break;
          case 14:
            comboboxColumns.push(item);
            break;
          case 15:
            obj = {
              ...obj,
              render: (text: string[]) => {
                const comboboxValues = item?.options.combobox_options;
                if (text && typeof text === 'string') {
                  text = JSON?.parse(text)?.map((key: string) => {
                    const comboboxValue = comboboxValues.find((combobox: RecordType) => combobox.key === key);
                    return (comboboxValue && comboboxValue.value) || '';
                  });
                } else if (Object.prototype.toString.call(text) === '[object Array]') {
                } else text = [];
                return text && <div>{text.join(', ')}</div>;
              },
            };
            comboboxColumns.push(item);
            break;
          case 21:
            obj = {
              ...obj,
              render: (text: number) => (text ? convertExpoToString(text) : ''),
            };
            break;
          default:
        }
        return obj;
      })
      .filter((item: RecordType) => !_.isNull(item));

    const columnsForRender = newColumns.filter((item: RecordType) =>
      defaultColumns.some((elem) => elem.origin === item.key)
    );

    if (!detail) {
      const otherColumns = this.getOtherColumns();
      columns = [...otherColumns];
    }
    if (editSigns && editSigns?.editProperties) {
      columnsForRender &&
        !detail &&
        !isVerifier &&
        columnsForRender.unshift({
          key: 1,
          width: '40px',
          render: (text: string, record: RecordType) => {
            return (
              <Tooltip title="Настройка доступа">
                <UnlockOutlined
                  onClick={() =>
                    this.setState({
                      modalRulesShow: true,
                      rulesRecordUuid: record.uuid,
                    })
                  }
                />
              </Tooltip>
            );
          },
        });
    }

    if (isVerification || isVerifier) {
      columnsForRender.unshift(...verifyColumns);
      newColumns.unshift(...verifyColumns);
    }

    if (detail && additionalColumns) {
      columnsForRender.push(...additionalColumns, ...columns);
      newColumns.push(...additionalColumns);
    }
    this.setState({
      columns: newColumns,
      columnsForRender,
      comboboxColumns,
      dateColumns,
      referenceColumns,
      editSigns: editSigns?.editProperties,
    });
  };

  getDefaultColumns = () => {
    const {data} = this.state;
    const {additionalColumns, isVerification, isVerifier} = this.props;
    const defaultColumns: RecordType[] = [];
    // @todo - fix this
    const displayedColumns: any[] = [];

    if (isVerification || isVerifier) {
      displayedColumns.unshift(...verifyColumns.map((item) => item.key));
    }
    data.filter((item) => {
      if (item?.options?.old !== true && item?.options?.default_in_list === true) {
        displayedColumns.push(item.origin);
        defaultColumns.push(item);
      }
      return {defaultColumns, displayedColumns};
    });

    if (additionalColumns) {
      additionalColumns.forEach((item) => {
        displayedColumns.push(item.key);
      });
    }

    this.setState({displayedColumns});
    return defaultColumns;
  };

  getOtherColumns = () => {
    const {data, filedTypeIds} = this.state;
    const otherColumns: RecordType[] = [];
    filedTypeIds.forEach((item) => {
      const fields = data.filter((elem) => elem.fieldType.id === item);
      fields.forEach((field) => {
        if (isEmpty(field?.options?.default_in_list) || (field?.options?.old !== true && field?.fieldType !== 12)) {
          otherColumns.push(field);
        } else {
          return false;
        }
      });
    });
    return otherColumns;
  };

  newItemCallback = (item: RecordType) => {
    const {comboboxColumns, dateColumns} = this.state;
    for (const k in item) {
      let comboboxField: RecordType | undefined;
      let dateField;
      let option;
      if (item.hasOwnProperty(k)) {
        comboboxField = comboboxColumns.find((elem) => elem.origin === k);
        dateField = dateColumns.find((elem) => elem.origin === k);
        if (comboboxField && !_.isNull(comboboxField.options) && comboboxField.options.combobox_options) {
          if (Array.isArray(item[k])) {
            option = [];
            item[k].forEach((optionKey: string, index: number) => {
              if (comboboxField) {
                option = comboboxField.options.combobox_options.find((el: RecordType) => el.key === optionKey);
                if (option) {
                  item[k][index] = option.value;
                }
              }
            });
          } else {
            option = comboboxField.options.combobox_options.find((el: RecordType) => el.key === item[k]);
            if (option) {
              item[k] = option.value;
            }
          }
        }
        if (dateField && item[k]) item[k] = moment(item[k]).format('DD.MM.YYYY');
      }
    }
    return item;
  };

  getTableData = () => {
    const {itemData} = this.state;
    const newItemData =
      itemData &&
      itemData.map((item) => {
        if (item?.children && item?.children.length) {
          item.children = item?.children.map(this.newItemCallback);
        }
        return this.newItemCallback(item);
      });
    this.setState({convertedItemData: newItemData});
  };

  applyFilter = (action: (() => void | Promise<void>) | undefined = undefined) => {
    const {filterData, contextString, historyData} = this.props;
    const {tableParams} = this.state;
    this.setState(
      {
        search: contextString,
        loading: true,
        filter: filterData,
        historyFilter: historyData,
        tableParams: {
          pagination: {
            ...tableParams.pagination,
            current: 1,
          },
        },
      },
      () => {
        this.getCatalogItem();
        action && isFunction(action) && action();
      }
    );
  };

  handleTableChange = (pagination: TablePaginationConfig, filter: string, sorter: RecordType) => {
    const sort: RecordType = {};
    if (sorter.field) {
      sort.field = sorter.columnKey;
      sort.order = sorter.order === 'descend' ? 'desc' : sorter.order === 'ascend' ? 'asc' : '';
    }
    if (pagination?.pageSize !== this.state.tableParams.pagination?.pageSize) {
      this.setState({data: []});
    }

    this.setState(
      {
        sort,
        loading: true,
        tableParams: {
          pagination: {
            current: pagination.current || 1,
            pageSize: pagination.pageSize || 10,
            total: pagination.total,
          },
        },
      },

      () => this.getCatalogItem()
    );
  };

  closeModal = () => {
    this.setState({modalVisible: false});
  };

  openModal = (mode: ModeType, isEdit: boolean, detailUuid: string) => {
    this.setState({modalVisible: true, mode, isEdit, detailUuid});
  };

  getFieldsList = () => {
    const {data, newElement} = this.state;
    const newFields: RecordType[] = [];
    data.forEach((item) => {
      let value = null;
      if (!isEmpty(item?.options) && item?.options?.old !== true) {
        return;
      }
      if (item?.fieldType?.id === 18) {
        return;
      }
      if (newElement[item.origin]) {
        value = newElement[item.origin];
      }
      newFields.push({
        caption: item.caption,
        description: item.description,
        origin: item.origin,
        required: item.required,
        referenceCatalog: item.referenceCatalog,
        referenceCatalogPattern: item.referenceCatalogPattern,
        value,
        fieldType: item.fieldType.id,
        options: item.options,
      });
    });
    this.setState({mainAttrList: newFields});
  };

  onDoubleClick = (record: RecordType) => {
    const {detail, referenceOrigin, onDoubleClick, defaultData, viewRoute} = this.props;
    if (defaultData) {
      this.setState({
        defaultRecord: record,
        modalVisible: true,
      });
    } else if (detail) {
      this.openModal('edit', false, record.uuid);
    } else if (onDoubleClick) {
      return onDoubleClick(record);
    } else {
      if (viewRoute) {
        return this.props.history?.push(`${viewRoute}/${record?.uuid}`);
      } else {
        return this.props.history?.push(`/catalog/${referenceOrigin}/${record?.uuid}`);
      }
    }
  };

  checkIsDetailEditable = () => {
    const {editable} = this.props;
    if (isEmpty(editable)) {
      return true;
    }
    return editable;
  };
  /** Переключение состояния для отображения при иерархии в справочнике */
  handleTreeSwitchChange = (prevState: RecordsState) => {
    const {tableParams} = this.state;
    this.setState(
      {
        // isTree: !prevState,
        tableParams: {
          pagination: {
            ...tableParams.pagination,
            current: 1,
          },
        },
      },
      () => this.getCatalogItem()
    );
  };
  /** Переключение состояние отображения при наличии дублей в справочнике */
  handleDeduplicationRecordsAllSwitchChange = (checked: boolean) => {
    const {tableParams} = this.state;
    this.setState(
      {
        isDeduplicationRecordsAll: !checked,
        tableParams: {
          pagination: {
            ...tableParams.pagination,
            current: 1,
          },
        },
      },
      () => this.getCatalogItem()
    );
  };

  render() {
    const {
      detail,
      recordUuid,
      referenceCaption,
      referenceOrigin,
      referenceUuid,
      isVerifier,
      isUnverified,
      editSigns,
      additionalMeta,
      additionalFields,
      customEditModal,
      rootValues,
      rootForm,
      defaultData,
      inputType,
      renderCustomTable,
      structure,
      hasDuplicates,
      isVerification,
      isHierarchy,
      validateCatalog,
      isVerificator,
      changeType,
      isOriginVersion,
      tableSource,
    } = this.props;

    const {
      displayedColumns,
      columnsForRender,
      columns,
      loading,
      itemData,
      convertedItemData,
      data,
      modalVisible,
      mode,
      detailUuid,
      isFilterSearch,
      isContextSearch,
      modalRulesShow,
      rulesRecordUuid,
      csvLoading,
      // isTree,
      defaultRecord,
      hierarchyFields,
      referenceColumns,
      dataFilter,
      isDeduplicationRecordsAll,
      totalElementsWithMainUuid,
      isHierarchyFields,
      jsonLoading,
      xlsxLoading,
      xlsLoading,
      xmlLoading,
      tableParams,
    } = this.state;
    const editable = this.checkIsDetailEditable();

    const getTitleModal = () => {
      switch (mode) {
        case 'edit':
          if (this.state.isEdit) {
            return 'Изменить запись';
          }
          return 'Просмотр записи';
        case 'create':
          return 'Добавить запись';
      }
    };

    const titleModal = getTitleModal();

    return (
      <div className={style.CatalogItem}>
        {this.props.mode !== 'create' && !isBoolean(isVerificator) && !isOriginVersion && (
          <CatalogsFilters
            fields={data}
            isDeduplicationRecordsAll={isDeduplicationRecordsAll}
            referenceUuid={referenceUuid}
            referenceCaption={referenceCaption}
            referenceOrigin={referenceOrigin}
            applyFilter={this.applyFilter}
            onHistorySearch={!detail}
            onFilterSearch={true}
            onContextSearch={isContextSearch && true}
            isFilterSearch={isFilterSearch}
            isContextSearch={isContextSearch}
            isVerification={isVerification}
            isVerifier={isVerifier}
          />
        )}
        <div className="catalogs-item__body">
          <RecordTable
            isDisabled={this.props.isDisabled}
            tableSource={tableSource}
            validateCatalog={validateCatalog}
            history={this.props.history}
            changeType={changeType}
            mode={this.props.mode}
            isUnverified={isUnverified}
            getCatalogItem={this.getCatalogItem}
            recordUuid={isEmpty(recordUuid) ? this.props.match.params.id : recordUuid}
            isHierarchy={isHierarchy}
            tableParams={!isEmpty(convertedItemData) && tableParams.pagination}
            paginationDisabled={defaultData}
            displayedColumns={displayedColumns}
            loading={loading}
            columnsForRender={columnsForRender}
            totalElementsWithMainUuid={totalElementsWithMainUuid}
            dataFilter={dataFilter}
            columns={columns}
            editable={editable}
            isEdit={this.props.isEdit}
            detail={detail}
            data={convertedItemData}
            handleDeduplicationRecordsAllSwitchChange={this.handleDeduplicationRecordsAllSwitchChange}
            handleTableChange={this.handleTableChange}
            onDoubleClick={this.onDoubleClick}
            openModal={this.openModal}
            onExport={(value: string) => {
              this.applyFilter(() => this.renderFormats(value as EFileFormat));
            }}
            handleTableColumnsChange={(columns: string[]) => this.setState(() => ({displayedColumns: [...columns]}))}
            csvLoading={csvLoading}
            jsonLoading={jsonLoading}
            xlsxLoading={xlsxLoading}
            xlsLoading={xlsLoading}
            xmlLoading={xmlLoading}
            isVerifer={isVerifier}
            isVerification={isVerification}
            editSigns={editSigns}
            // setIsTree={this.handleTreeSwitchChange}
            // isTree={isTree}
            isHierarchyFields={isHierarchyFields}
            hasDuplicates={hasDuplicates}
            hierarchyField={!!hierarchyFields.length}
            inputType={inputType}
            viewRoute={this.props.viewRoute}
            renderCustomTable={renderCustomTable}
            referenceOrigin={this.props.referenceOrigin}
            referenceColumns={referenceColumns}
            isDeduplicationRecordsAll={isDeduplicationRecordsAll}
            setIsDeduplicationRecordsAll={this.handleDeduplicationRecordsAllSwitchChange}
            mainItemUuidForGoldRecord={itemData}
            isVerifier={isVerifier}
          />
          <Modal
            open={modalVisible}
            onCancel={this.closeModal}
            footer={null}
            destroyOnClose={true}
            width={800}
            title={titleModal}
          >
            <RecordRedactor
              initialValidateCatalog={!!validateCatalog}
              defaultRecord={defaultRecord}
              isEdit={this.state.isEdit}
              detailUuid={detail}
              referenceOrigin={referenceOrigin}
              recordUuid={isEmpty(recordUuid) ? this.props.match.params.id : recordUuid}
              referenceUuid={referenceUuid}
              detailFieldUuid={detailUuid}
              closeModal={this.closeModal}
              getCatalogItem={this.getCatalogItem}
              maxRefsDepth={this.props.maxRefsDepth}
              mode={mode}
              isUnverified={isUnverified}
              additionalMeta={additionalMeta}
              additionalFields={additionalFields}
              customEditModal={customEditModal}
              rootValues={rootValues}
              rootForm={rootForm}
              renderCustomTable={renderCustomTable}
              structure={structure}
              isModal={true}
            />
          </Modal>
          <Modal
            title={'Права записи'}
            open={modalRulesShow}
            footer={null}
            onCancel={() => this.setState({modalRulesShow: false})}
            destroyOnClose={true}
            width={800}
          >
            <AccessRights
              rulesRecordUuid={rulesRecordUuid}
              store="items"
              referenceOrigin={this.props.referenceOrigin}
            />
          </Modal>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store: RootState) => {
  return {
    changedDetail: store.detail.changedDetail,
    changedMode: store.detail.mode,
    changedField: store.detail.changedField,
    resetFilter: store.filter.filterResetFlag,
    filterData: store.filter.filterBody,
    contextString: store.filter.contextString,
    historyData: store.filter.historyBody,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    setResetFlagFilter: (flag: boolean) => {
      dispatch(setFilterReset(flag));
    },

    setPageHeaderButtons: (headerConfig: ButtonProps[]) => {
      dispatch(setPageHeaderButtons(headerConfig));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Records);
