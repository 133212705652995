import React, {useState, useEffect} from 'react';
import {Button, Tooltip} from 'ui-kit';
import {DeleteOutlined, PlusSquareOutlined} from '@ant-design/icons';

import useCRUD from '../../hooks/CRUD.hook';
import {fetchFunc} from '../../Utils/security/http/mdm';
import {errorModalCreate} from '../../components/Helpers/Modals';
import ListWithAddForm from '../../components/ListWithAddForm/ListWithAddForm';
import SchedulerForm from '../../components/SchedulerForm/SchedulerForm';

const SchedulerTasks = () => {
  const columns = [
    {
      title: 'Наименование',
      dataIndex: 'name',
    },
    {
      title: 'Описание',
      dataIndex: 'description',
    },
    {
      title: 'Задача',
      dataIndex: ['job', 'name'],
    },
    {
      title: 'Стратегия запуска',
      dataIndex: 'type',
      render: (text) => (text === 'once' ? 'Разовое' : text === 'scheduled' ? 'Периодическое' : '-'),
    },
    {
      title: 'Расписание периодического запуска',
      dataIndex: 'schedule',
    },
    {
      key: 'start',
      render: (text, record) => (
        <Tooltip placement="topLeft" title="Запустить принудительно">
          <PlusSquareOutlined
            onClick={() => {
              setActionLoading(true);
              changeStatus('run', record.uuid);
            }}
          />
        </Tooltip>
      ),
    },
    {
      key: 'on/off',
      width: 135,
      render: (text, record) => (
        <Button
          loading={actionLoading}
          onClick={() => {
            setActionLoading(true);
            changeStatus(record.statusOn ? 'disable' : 'enable', record.uuid);
          }}
        >
          {record.statusOn ? 'Выключить' : 'Включить'}
        </Button>
      ),
    },
    {
      key: 'remove',
      render: (text, record) => (
        <DeleteOutlined
          loading={actionLoading.toString()}
          disabled={record.name === '18_1_add1390y'}
          onClick={() => {
            setActionLoading(true);
            removeRecord(record.uuid);
          }}
        />
      ),
    },
  ];
  const [editableItem, setEditableItem] = useState();
  const [actionLoading, setActionLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const [search, setSearch] = useState('');

  const {getData, changeData, data, loading, total} = useCRUD({
    apiUrl: `/api/v1/scheduler/execution`,
    service: 'scheduler',
  });

  useEffect(() => {
    getData({page, size, name_description_like: search});
  }, [page, search, size]);

  const changeSchedule = async (values, editableItem) => {
    try {
      await changeData({fields: values}, editableItem);
      getData({page, size, name_description_like: search});
    } catch (error) {}
  };

  const changeStatus = async (action, uuid) => {
    try {
      await fetchFunc(
        {
          url: `/api/v1/scheduler/execution/${uuid}/${action}`,
          method: 'post',
        },
        (response) => {
          errorModalCreate(response.data?.message);
          setActionLoading(false);
        },
        'scheduler'
      );
      setActionLoading(false);
      getData({page, size, name_description_like: search});
    } catch (error) {}
  };

  const removeRecord = async (uuid) => {
    try {
      await fetchFunc(
        {
          url: `/api/v1/scheduler/execution/${uuid}/`,
          method: 'delete',
        },
        (response) => {
          errorModalCreate(response.data?.message);
          setActionLoading(false);
        },
        'scheduler'
      );
      setActionLoading(false);
      getData({page, size, name_description_like: search});
    } catch (error) {}
  };

  const handleTableChange = (paginator) => {
    setPage(--paginator.current);
    setSize(paginator.pageSize);
  };

  const onSearch = (value) => {
    setSearch(value);
  };

  return (
    <ListWithAddForm
      placeholderText={'Укажите наименование для поиска'}
      editableItem={editableItem}
      setEditableItem={setEditableItem}
      isSearch={true}
      changeData={changeSchedule}
      handleTableChange={handleTableChange}
      onSearch={onSearch}
      tableConfig={{
        data,
        loading,
        columns,
        total,
      }}
      editCatalog
      addModalTitle="расписания"
      FormComponent={SchedulerForm}
    />
  );
};

export default React.memo(SchedulerTasks);
