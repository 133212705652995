import {Form, Input} from 'ui-kit';
import styles from '../EditFieldForm.module.scss';
import React from 'react';
import {getValidationRules} from '../helpers/utils';
import {DefaultValue, FieldOrder} from '../helpers/FormComponents';

interface Props {
  isDisabled: boolean;
  setFieldsValue: (v: any) => void;
  detail: any;
  validator: any;
  defaultValue?: boolean;
}

const StringFieldForm = ({isDisabled, validator, defaultValue = true}: Props) => {
  return (
    <>
      <div className={styles.twoColumns}>
        <FieldOrder />
        {defaultValue && (
          <DefaultValue rules={getValidationRules(validator)}>
            <Input disabled={isDisabled} />
          </DefaultValue>
        )}
      </div>
      <Form.Item noStyle shouldUpdate={(prevState, nextState) => prevState.phone_mask !== nextState.phone_mask}>
        {({getFieldValue}) => {
          const disabledMask = getFieldValue('phone_mask');
          return (
            <Form.Item label="Маска" name="input_mask" extra="Регулярное выражение javaScript">
              <Input disabled={disabledMask} />
            </Form.Item>
          );
        }}
      </Form.Item>
      {/* MDM-2125 */}
      {/* <div className={styles.twoColumns}>
        <CheckboxPhoneMask setFieldsValue={setFieldsValue} isDisabled={isDisabled} />
        {!detail && <CheckboxAccessValue isDisabled={isDisabled} />}
      </div> */}
    </>
  );
};

export default React.memo(StringFieldForm);
