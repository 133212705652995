import React, {useState, useEffect, useCallback} from 'react';
import {DeleteOutlined, EditOutlined} from '@ant-design/icons';
import {fetchFunc} from '../../Utils/security/http/mdm';
import {Table} from 'ui-kit';
import styles from './AccessRights.module.scss';
import {accessRule} from './helpers/constants';

const AccessRightsTable = (props) => {
  const {
    handlePaginationChange,
    loading,
    data: {content, totalElements},
    editRule,
    showDeleteRuleConfirm,
  } = props;

  const [tableContent, setTableContent] = useState([]);

  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  useEffect(() => {
    let arr = [];
    if (content) {
      setTableContent([]);
      content.forEach(async (item) => {
        if (item.userId) {
          await getUserName(item.userId).then((userName) => {
            item.userName = userName;
            arr.push(item);
            forceUpdate();
          });
        } else arr.push(item);
      });
      setTableContent(arr);
    }
  }, [content]);

  const getUserName = async (userId) => {
    try {
      const data = await fetchFunc({
        url: `/api/v1/catalogs/user_profile/items/${userId}`,
        method: 'get',
      });
      return data.username;
    } catch (error) {}
  };

  const {accessIsDenied, view, full, unknown} = accessRule;

  const columns = [
    {
      title: 'Пользователь, Роль',
      dataIndex: 'roleName',
      render: (text, record) => {
        return text || record.userName;
      },
    },
    {
      title: 'Уровень доступа',
      dataIndex: 'access',
      render: (text) => {
        switch (text) {
          case 0:
            return accessIsDenied;
          case 1:
            return view;
          case 2:
            return full;
          default:
            return unknown;
        }
      },
    },
    {
      render: (text, record) => {
        return (
          <EditOutlined
            className={styles.tableIcon}
            onClick={() => {
              editRule(record.id);
            }}
          />
        );
      },
    },
    {
      render: (text, record) => {
        return (
          <DeleteOutlined
            className={styles.tableIcon}
            onClick={() => {
              showDeleteRuleConfirm(record.roleName ? record.roleName : record.userName, record.id);
            }}
          />
        );
      },
    },
  ];

  return (
    <Table
      loading={loading}
      columns={columns}
      rowKey={(record) => record.id}
      dataSource={[...tableContent]}
      pagination={{
        onChange: handlePaginationChange,
        total: totalElements,
      }}
    />
  );
};

export default AccessRightsTable;
