import React, {ReactNode, useCallback, useEffect, useState} from 'react';
import {Table, Input, TableProps} from 'ui-kit';
import {useDispatch} from 'react-redux';
import {SorterResult} from 'antd/es/table/interface';

import styles from './ControlledTable.module.scss';
import classNames from 'classnames';

interface Config extends TableProps<any> {
  contextSearch?: boolean;
  contextPlaceholder?: string;
  titleActions?: () => ReactNode;
  totalElements: number;
}

export interface ControlledTableProps {
  config: Config;
  getDataAction: (value: TableControlProps) => void;
}

export interface TableControlProps {
  page: number;
  size: number;
  sorter: null | string;
  search: null | string;
}

export const ControlledTable = ({config, getDataAction}: ControlledTableProps) => {
  const {Search} = Input;
  const dispatch = useDispatch();

  const [tableControl, setTableControl] = useState<TableControlProps>({
    page: 0,
    size: 10,
    sorter: null,
    search: null,
  });

  const handleTableChange: TableProps<any>['onChange'] = ({current}, filters, sorter) => {
    const {order, field} = sorter as SorterResult<any>;
    let sorterString = '';
    if (order) sorterString = `${field},${order === 'descend' ? 'desc' : 'asc'}`;
    setTableControl({
      ...tableControl,
      page: (current || 0) - 1,
      sorter: sorterString,
    });
  };

  const onSearch = (value: string) =>
    setTableControl({
      ...tableControl,
      page: 0,
      search: value,
    });

  useEffect(() => {
    dispatch(getDataAction(tableControl));
  }, [tableControl]);

  return (
    <div className="d-flex flex-column gap-24">
      <div className="d-flex">
        {config.contextSearch && (
          <Search
            placeholder={config.contextPlaceholder}
            onSearch={onSearch}
            onChange={(event) => onSearch(event.currentTarget.value)}
            allowClear
          />
        )}
        {config.titleActions && config.titleActions()}
      </div>
      <Table
        {...config}
        className={classNames(config.className, styles.table)}
        pagination={{
          showSizeChanger: false,
          total: config.totalElements,
          current: tableControl.page + 1,
        }}
        onChange={handleTableChange}
      />
    </div>
  );
};
