export const checkBdNameFormat = (_: any, value: string) => {
  const Reg = new RegExp('^[a-z][a-z0-9_]*$');
  if (Reg.test(value)) {
    return Promise.resolve();
  }
  return Promise.reject('Неверный формат');
};

export const checkingForExtraSpacesInString = (_: any, value: string) => {
  const regExpForCheckingSpaceStartLine = new RegExp('^\\s+');
  const regExpForCheckingSpaceEndLine = new RegExp('\\s+$');
  const regExpForCheckingNumberOnSpaces = new RegExp('\\s{2}');

  if (regExpForCheckingNumberOnSpaces.test(value)) {
    return Promise.reject('Значение не может содержать два и более пробела подряд');
  }
  if (regExpForCheckingSpaceStartLine.test(value)) {
    return Promise.reject('Значение не может начинаться с пробела');
  }
  if (regExpForCheckingSpaceEndLine.test(value)) {
    return Promise.reject('Значение не может заканчиваться пробелом');
  }

  return Promise.resolve();
};

const IsJsonString = (str: string) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const checkOptionsFormat = (_: any, value: string) => {
  if (!value) return Promise.resolve();
  else {
    if (IsJsonString(value)) {
      return Promise.resolve();
    } else return Promise.reject('Неверный формат');
  }
};
