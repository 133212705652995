import {DatePicker, InputNumber, Select, Form, FormInstance} from 'ui-kit';
import styles from '../EditFieldForm.module.scss';
import React from 'react';
import {DateDuration, DateFormat, DefaultValue, DefaultValueDate, FieldOrder} from '../helpers/FormComponents';
import {requiredRule} from '../helpers/constants';

interface Props {
  isDisabled: boolean;
  withTime?: boolean | {format: string};
  dateFormats: {
    key: string;
    value: string;
  }[];
  defaultOptions: JSX.Element;
  mapOption: (value: any) => JSX.Element;
  form: FormInstance<any>;
}

type PickerType = 'date' | 'month' | 'week' | 'quarter' | 'year' | undefined;

const DateFieldForm = ({mapOption, dateFormats, defaultOptions, withTime = false, isDisabled, form}: Props) => {
  const dateFormatsOpt = mapOption(dateFormats);
  return (
    <>
      <div className={styles.twoColumns}>
        <FieldOrder />
        <DefaultValue name="date_default_value_type" rules={requiredRule}>
          <Select
            disabled={isDisabled}
            onChange={(value) => {
              if (value === 'empty')
                form.setFieldsValue({
                  date_duration: null,
                  defaultValue: null,
                });
            }}
          >
            {defaultOptions}
          </Select>
        </DefaultValue>
      </div>
      <div className={styles.twoColumns}>
        <DateFormat rules={requiredRule}>
          <Select disabled={isDisabled}>{dateFormatsOpt}</Select>
        </DateFormat>
        <Form.Item
          noStyle
          shouldUpdate={(prevState, nextState) =>
            prevState.date_default_value_type !== nextState.date_default_value_type ||
            prevState.date_format !== nextState.date_format
          }
        >
          {({getFieldValue}) => {
            const value = getFieldValue('date_default_value_type');
            let dateFormat = getFieldValue('date_format');
            let pickerType: PickerType = 'date';
            switch (dateFormat) {
              case 'MM YYYY':
                pickerType = 'month';
                dateFormat = 'MM.YYYY';
                break;
              case 'C YYYY':
                pickerType = 'quarter';
                dateFormat = (value: any) => {
                  if (!value) {
                    return '';
                  }
                  const year = value.year();
                  const quarter = Math.floor(value.month() / 3) + 1;
                  return `Q${quarter} ${year}`;
                };
                break;
              case 'DD.MM.YYYY HH:mm:ss':
                withTime = {format: 'HH:mm:ss'};
                break;
              case 'DD.MM.YYYY HH:mm':
                withTime = {format: 'HH:mm'};
                break;
              default:
            }
            return value === 'date' ? (
              <DateDuration rules={requiredRule}>
                <InputNumber style={{width: '100%'}} disabled={isDisabled} />
              </DateDuration>
            ) : (
              value === 'specific' && (
                <DefaultValueDate rules={requiredRule}>
                  <DatePicker
                    style={{width: '100%'}}
                    format={dateFormat}
                    picker={pickerType}
                    showTime={withTime}
                    disabled={isDisabled}
                  />
                </DefaultValueDate>
              )
            );
          }}
        </Form.Item>
      </div>
    </>
  );
};

export default React.memo(DateFieldForm);
