import React, {useState, useEffect} from 'react';
import {DeleteOutlined} from '@ant-design/icons';
import ListWithAddForm from '../../components/ListWithAddForm/ListWithAddForm';
import useCRUD from '../../hooks/CRUD.hook';
import ExternalSystenForm from '../../components/SystemsForms/ExternalSystenForm';

const apiUrl = '/api/v1/external-system';

const ExternalSystemsList = () => {
  const columns = [
    {
      title: 'Наименование',
      dataIndex: 'caption',
    },
    {
      title: 'Код',
      dataIndex: 'code',
    },
    {
      title: 'Идентификатор',
      dataIndex: 'id',
    },
    {
      title: '',
      key: 'del',
      align: 'center',
      render: (text, record) => <DeleteOutlined onClick={() => deleteSubsystem(record.id)} />,
    },
  ];
  const [editibleItem, setEditibleItem] = useState();
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const {getData, deleteData, changeData, data, loading, total} = useCRUD({
    apiUrl,
    idField: 'id',
  });

  useEffect(() => {
    getData({page, size});
  }, [page, size]);

  const changeSubsystem = async (values, editibleItem) => {
    try {
      await changeData(values, editibleItem);
      getData({page, size});
    } catch (error) {}
  };

  const deleteSubsystem = async (uuid) => {
    try {
      await deleteData(uuid);
      getData({page, size});
    } catch (error) {}
  };

  const handleTableChange = (paginator) => {
    setPage(--paginator.current);
    setSize(paginator.pageSize);
  };

  return (
    <ListWithAddForm
      editibleItem={editibleItem}
      setEditibleItem={setEditibleItem}
      changeData={changeSubsystem}
      handleTableChange={handleTableChange}
      tableConfig={{
        data,
        loading,
        columns,
        total,
      }}
      editCatalog
      addModalTitle="внешней системы"
      FormComponent={ExternalSystenForm}
    />
  );
};

export default ExternalSystemsList;
