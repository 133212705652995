import React, {Key, ReactNode} from 'react';
import {getFieldConfig} from '../Helpers/RuleConfigurator';
import {Popover, SelectDefaultOptionType} from 'ui-kit';
import {CheckOutlined, CloseOutlined} from '@ant-design/icons';

import {PropertyPath, get, isBoolean, isEmpty, isNumber} from 'lodash';

import {useHistory, useRouteMatch} from 'react-router';
import moment from 'moment';
import _ from 'lodash';

import {sortFieldsByOrder, findNameValueByKey, getFieldsMeta, convertExpoToString, toDecimal} from '../Helpers/Utils';
import StructureRenderer from './StructureRenderer';
import Records from '../CatalogItemWidgets/Records/Records';
import VerificationChecker from '../Verification/VerificationChecker/VerificationChecker';
import VerifyAlert from '../Verification/VerifyAlert';
import ReferenceReader from './ReferenceReader';
import {AditionalFields, DetailsMeta} from './meta/meta';
import {errorModalCreate} from '../Helpers/Modals';

import styles from './RecordReader.module.scss';
import classNames from 'classnames';
import dayjs from 'dayjs';

export interface RecordReaderProps {
  renderCustomTable?: string;
  data: any;
  referenceOrigin?: any;
  recordUuid?: string;
  referenceUuid?: string;
  structure?: boolean;
  fieldsMeta: any[];
  isVerificator?: boolean;
  isUnverified?: boolean;
  detailFieldUuid?: string;
  detailUuid?: string;
  rootValues?: any[];
  maxRefsDepth?: number;
  versionId?: string;
  recordsDefaultFilter?: any;
  mode?: 'create' | 'edit';
  twoColumnsMode?: boolean;
  isOriginVersion?: boolean;
  changeType?: string;
  setTableColumns?: React.Dispatch<React.SetStateAction<any[]>>;
  setTableSource?: React.Dispatch<React.SetStateAction<any[]>>;
  tableColumns?: any[];
  tableSource?: any;
  isRestored?: boolean;
}

const RecordReader = (props: RecordReaderProps) => {
  const {
    isRestored,
    renderCustomTable,
    data,
    referenceOrigin,
    recordUuid,
    referenceUuid,
    structure,
    fieldsMeta,
    isVerificator,
    isUnverified,
    detailFieldUuid,
    detailUuid,
    rootValues,
    maxRefsDepth,
    versionId,
    recordsDefaultFilter,
    mode,
    twoColumnsMode = false,
    isOriginVersion,
    changeType,
    setTableColumns,
    setTableSource,
    tableColumns,
    tableSource,
  } = props;
  const match = useRouteMatch();
  const detailMeta = DetailsMeta;
  const history = useHistory();
  const getFieldValueFromData = (origin: string) => {
    return isNumber(data[origin]) ? data[origin] : data[origin] || null;
  };

  const getComboboxLabel = ({options}: {options: SelectDefaultOptionType}, itemValue: string) => {
    const option =
      options && options?.combobox_options && options.combobox_options?.find((item: any) => item.key === itemValue);
    return option && option?.value;
  };

  const renderItem = (field: any, index: number) => {
    const {options, fieldType, description, caption, origin, referenceCatalog} = field;
    let cnf: any = {visible: true};
    let optionsCnf = {};
    if (options) {
      if (options.caption) cnf.caption = options?.caption;
      if (options.customView) {
        cnf.customView = options?.customView;
      }
      let dataForRules = data;
      if (detailUuid)
        dataForRules = {
          ...data,
          ...rootValues,
        };
      if (options.rules && !isEmpty(data)) {
        if (_.isString(options.rules)) {
          try {
            optionsCnf = getFieldConfig(JSON.parse(options.rules), dataForRules);
          } catch (error) {
            errorModalCreate('Неверный формат у строки rules ' + options.rules);
          }
        } else {
          optionsCnf = getFieldConfig(options.rules, dataForRules);
        }
      }
    }
    if (referenceCatalog?.options?.special_interface) {
      cnf.viewRoute = referenceCatalog?.options?.special_interface?.view_route;
    }
    cnf = {
      ...cnf,
      ...optionsCnf,
    };
    const itemCaption = isEmpty(cnf?.caption) ? caption : cnf?.caption;
    let resultValue: ReactNode = '';
    const itemValue = getFieldValueFromData(origin);
    let className = fieldType.id === 'customField' ? styles.fieldItem : styles.fieldItemBordered;
    if (field.hasChanged) {
      const {fieldItem_changed_origin, fieldItem_changed} = styles;
      className += ' ' + (isOriginVersion ? fieldItem_changed_origin : fieldItem_changed);
    }
    switch (fieldType.id) {
      case 3:
      case 21:
        resultValue = toDecimal(itemValue) || '-';
        break;
      case 10:
        className = '';
        resultValue = !itemValue ? <CloseOutlined /> : <CheckOutlined />;
        break;
      case 7:
        resultValue = itemValue
          ? dayjs(itemValue).format(get(field, 'options.date_format') || 'YYYY-MM-DD HH:mm:ss')
          : '-';
        break;
      case 4:
        resultValue = itemValue
          ? _.has(itemValue, 'uuid')
            ? renderReference(field, itemValue, cnf.customView, cnf.viewRoute)
            : itemValue
          : '-';
        break;
      case 8:
        const dateFormat = get(field, 'options.date_format') || 'YYYY-MM-DD';
        switch (dateFormat) {
          case 'C YYYY':
            resultValue = itemValue ? `Q${dayjs(itemValue).format('Q YYYY')}` : '-';
            break;
          case 'MM YYYY':
            resultValue = itemValue ? moment(itemValue).format('MM.YYYY') : '-';
            break;
          default:
            resultValue = itemValue ? moment(itemValue).format(dateFormat) : '-';
        }
        break;
      case 12:
        className = '';
        if (field.options && field.options.detailMode === 'select') {
          resultValue = renderSelectDetail(field, itemValue);
        } else resultValue = renderDetail(field, itemValue);
        break;
      case 25:
        resultValue = itemValue ? (
          <div className="d-flex flex-column">
            {itemValue.map((item: string) => (
              <a href={item} target="_blank" rel="noopener noreferrer">
                {item}
              </a>
            ))}
          </div>
        ) : (
          '-'
        );
        break;
      case 22:
      case 23:
      case 24:
        resultValue = itemValue ? (
          <div className="d-flex flex-column">
            {itemValue.map((item: string) => (
              <div>{item}</div>
            ))}
          </div>
        ) : (
          '-'
        );
        break;
      case 14:
        resultValue = getComboboxLabel(field, itemValue);
        break;
      case 15: {
        resultValue = Array.isArray(itemValue)
          ? itemValue.map((item, index) => {
              return (
                <div key={index}>
                  {(item && item.value) || findNameValueByKey(field.options.combobox_options, item)}
                </div>
              );
            })
          : itemValue &&
            JSON.parse(itemValue)
              .map((key: Key) => findNameValueByKey(field.options.combobox_options, key))
              .join(', ');
        break;
      }
      case 117:
        resultValue = renderCompositeField(field);
        break;
      case 112:
        resultValue = renderNonDynamicDetail(field, itemValue);
        break;
      case 19:
        resultValue = itemValue ? (typeof itemValue !== 'string' ? JSON.stringify(itemValue) : itemValue) : '';
        break;
      case 13:
        resultValue = (
          <a href={itemValue} target="_blank">
            {itemValue}
          </a>
        );
        break;
      case 20:
        resultValue = itemValue
          ? _.isObject(itemValue)
            ? (itemValue as any)?.caption || (itemValue as any).value
            : itemValue
          : '';
        break;
      case 21:
        resultValue = itemValue ? convertExpoToString(itemValue) : '-';
        break;
      case 'customField':
        if (options && !options.onlyForEdit) {
          resultValue = options.render && options.render(null, data);
        }
        break;
      case 'docTypeDetail':
        return (resultValue = field.options.render({record: data, itemCaption}));
      default: {
        resultValue = isNumber(itemValue) ? itemValue : itemValue || '-';
      }
    }
    return (
      <div key={index} className={styles.field}>
        {cnf?.visible ? (
          description ? (
            <>
              <Popover content={description} placement="topLeft">
                <div className={styles.fieldItem_title}>{caption}</div>
              </Popover>
              <div className={className}>{resultValue || '-'}</div>
            </>
          ) : (
            <>
              <div className={styles.fieldItem_title}>{itemCaption}</div>
              <div className={className}>{resultValue}</div>
            </>
          )
        ) : (
          ''
        )}
      </div>
    );
  };

  const renderFields = (meta: any[]) => {
    return (
      <div className={classNames('d-flex flex-column gap-24', styles.renderFields)}>
        {sortFieldsByOrder(meta).map((field: any, index: number) => {
          const {onlyForEdit, old} = field.options || {};
          if (onlyForEdit || old) {
            return null;
          } else {
            return (
              <div key={field.origin} className={classNames(styles.containerFields)}>
                <div
                  className={classNames(
                    {
                      // row: isUnverified,
                    },
                    styles.renderField
                  )}
                >
                  {isVerificator && isUnverified && (
                    <div className={styles.verificationChecker}>
                      <VerificationChecker name={field?.origin} />
                    </div>
                  )}
                  <div
                    className={classNames(styles.renderItemContainer, {
                      [styles.verifyDetail]: isVerificator && isUnverified,
                    })}
                  >
                    {renderItem(field, index)}
                  </div>
                </div>
                {isUnverified && !isBoolean(isVerificator) && <VerifyAlert name={field.origin} />}
              </div>
            );
          }
        })}
      </div>
    );
  };

  const renderDetail = (
    item: {uuid?: string; origin: string; options: any; additionalColumns: any[]},
    itemValue: any
  ) => {
    return (
      <Records
        isRestored={isRestored}
        hasContextSearch={true}
        setTableColumns={setTableColumns && setTableColumns}
        tableColumns={tableColumns && tableColumns}
        setTableSource={setTableSource && setTableSource}
        tableSource={tableSource && tableSource}
        detail={item.uuid || item.origin}
        detailData={itemValue}
        isOriginVersion={isOriginVersion}
        isVerificator={isVerificator}
        referenceOrigin={referenceOrigin}
        changeType={changeType}
        referenceUuid={referenceUuid}
        recordUuid={detailFieldUuid || recordUuid}
        additionalColumns={item.options && item.options.additionalColumns}
        additionalMeta={_.has(detailMeta, item.origin) && (detailMeta as any)[item.origin]}
        additionalFields={_.has(AditionalFields, item.origin) && (AditionalFields as any)[item.origin]}
        isUnverified={isUnverified}
        versionId={versionId}
        match={match}
        rootValues={rootValues || data}
        maxRefsDepth={maxRefsDepth}
        renderCustomTable={item?.options?.customDetail}
        structure={item?.options?.structure}
        defaultFilter={recordsDefaultFilter}
        history={history}
      />
    );
  };

  const renderNonDynamicDetail = (item: any, value: any) => {
    return (
      <Records
        isRestored={isRestored}
        detail={item.uuid || item.origin}
        versionId={versionId}
        defaultData={value || []}
        referenceOrigin={referenceOrigin}
        additionalColumns={item.options && item.options.additionalColumns}
        match={match}
        rootValues={rootValues || data}
        maxRefsDepth={maxRefsDepth}
        defaultFilter={recordsDefaultFilter}
        history={history}
      />
    );
  };

  const renderReference = (item: any, value: any, customView: any[], viewRoute: string) => {
    const {referenceCatalog, referenceCatalogPattern, displayedFieldSeparator, options} = item;
    if (_.has(value, 'uuid'))
      return (
        <ReferenceReader
          catalog={referenceCatalog.origin}
          uuid={value.uuid}
          referenceCatalogPattern={(options && options.referenceCatalogPattern) || referenceCatalogPattern}
          referenceScript={options && options.reference_value_script}
          displayedFieldSeparator={displayedFieldSeparator}
          customView={customView}
          viewRoute={viewRoute}
          active={value.active}
          isUnverified={isUnverified}
          versionId={value?.version}
        />
      );
  };

  const renderSelectDetail = (item: any, value: any[]) => {
    const {
      options: {
        detailCfg: {referenceCatalogPattern, field, origin, displayedFieldSeparator = ' '},
      },
    } = item;
    return (
      value &&
      value.map((item: any) => (
        <ReferenceReader
          catalog={origin}
          uuid={item[field].uuid}
          referenceCatalogPattern={referenceCatalogPattern}
          detailMode={true}
          displayedFieldSeparator={displayedFieldSeparator}
        />
      ))
    );
  };

  const renderCompositeField = (item: any) => {
    const {options} = item;
    const {paramVal, paramName, storeName} = options.extractor;
    if (_.has(data, storeName)) {
      const dataField = data[storeName].find((item: any) => item[paramName] === paramVal);
      return dataField ? options.convertForDisplay(dataField) : '-';
    } else return '-';
  };

  return (
    <>
      {structure ? (
        <StructureRenderer
          structure={structure}
          meta={getFieldsMeta({
            columns: fieldsMeta,
            rule: renderCustomTable,
            additionalRule: 'reader',
          })}
          renderItem={renderItem}
          isUnverified={isUnverified}
        />
      ) : (
        fieldsMeta &&
        renderFields(
          getFieldsMeta({
            columns: fieldsMeta,
            rule: renderCustomTable,
            additionalRule: 'reader',
          })
        )
      )}
    </>
  );
};

export default RecordReader;
