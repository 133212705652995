import React, {useState} from 'react';
import {ImportOutlined, UploadOutlined} from '@ant-design/icons';
import {Button, Upload, Form} from 'ui-kit';
import {warningModalCreate} from '../../components/Helpers/Modals';
import styles from './CatalogItemImport.module.scss';

const CatalogImportForm = ({catalogImport}) => {
  const [form] = Form.useForm();

  const [fileList, setFileList] = useState([]);

  const submitForm = (values) => {
    const {type} = values.upload.file;
    if (type !== 'application/json') {
      warningModalCreate('Неверный тип файла', 'Импорт файлов возможен только в формате .json');
    } else {
      // @todo - разобраться с этим fileReader
      // eslint-disable-next-line no-undef
      let reader = new FileReader();
      reader.onload = async (event) => {
        try {
          const catalogData = await JSON.parse(reader.result);
          catalogImport(catalogData);
        } catch (error) {
          console.log(error);
        }
      };
      reader.readAsText(values.upload.file);
    }
  };

  const propsUpload = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file]);
      return false;
    },
    fileList,
  };

  return (
    <Form form={form} name="basic" onFinish={submitForm} layout="vertical" className={styles.form}>
      <Form.Item
        label="Файл"
        name="upload"
        rules={[
          {
            required: true,
            message: 'Выберите файл для импорта',
          },
        ]}
      >
        <Upload {...propsUpload}>
          {fileList && !fileList.length && (
            <Button>
              <UploadOutlined /> Загрузить
            </Button>
          )}
        </Upload>
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="mt-3"
          icon={<ImportOutlined />}
          disabled={fileList.length === 0}
        >
          Импортировать
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CatalogImportForm;
