import React, {Key, useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import cn from 'classnames';
import {isEmpty} from 'lodash';
import {Button, Skeleton, Switch, Table, TableProps, Typography} from 'ui-kit';

import DeduplicationGroupModalValue from '../DeduplicationGroupModalValue/DeduplicationGroupModalValue';

import {getBlockLinkGroup, getDeduplicationProtocolInfo} from '../../../../../redux/thunk/deduplicationThunk';
import {color, setCaption, setRecordId} from '../../../../../redux/actions/actions';

import styles from './DeduplicationGroupTable.module.scss';

import {RootState} from 'redux/types/rootState';
import {DeleteOutlined} from '@ant-design/icons';
import {RecordType} from 'types';
import {renderDeduplicationValue} from 'modules/Deduplication/helpers/DeduplicationUtils';
import {sortRecordArrayByStringArray} from 'Utils/arrayUtils/sortByStringArray';

/**
 * Таблица гпупп дедупликации
 * @param groupId - uuid группы
 * @param setPayloadData - функция для подготовки данных к отправке
 * @param payloadData  - данные для отпраки
 * @param status  - статус группы
 * @param deduplicationProtocolInfo - данные по протоколу
 * @param deduplicationGroupProtocolInfo - данные по группам дедупликации
 * @param goldRecord - состояние золотой записи
 * @param setGoldRecord -функция изменения золотой записи
 * @param setIsGoldRecord - функция изменения состояния выделения  золотой записи
 * @param isGoldRecord - состояние золотой записи
 * @param match - данные для загрузки id при перезагрузки страницы
 */

interface IDeduplicationGroupProtocolInfo {
  deduplicationFields?: any[]; // возможно вообще надо удалить
  fields?: any[];
  mainItem: {
    attributes: any;
    title?: string;
  };
  records: {
    attributes: any[];
    groupRecordUuid: string;
    recordUuid: string;
    title: string;
  }[];
}

export interface DeduplicationGroupTableProps {
  groupId: boolean;
  setPayloadData: (value: boolean | null) => void;
  payloadData: any;
  status: string;
  deduplicationGroupProtocolInfo: IDeduplicationGroupProtocolInfo;
  goldRecord: any[];
  setGoldRecord: (value: any[] | null) => void;
  setIsGoldRecord: (value: boolean | null) => void;
  isGoldRecord: boolean;
  match: {
    params: {
      id: number;
    };
  };
}

const DeduplicationGroupTable = ({
  groupId,
  setPayloadData,
  payloadData,
  status,
  deduplicationGroupProtocolInfo,
  goldRecord,
  setGoldRecord,
  setIsGoldRecord,
  isGoldRecord,
  match,
}: DeduplicationGroupTableProps) => {
  /** Отправка данных в хранилище */
  const dispatch = useDispatch();
  /** Используем хранилище */
  const caption = useSelector((state: RootState) => state?.deduplication.caption);
  /** Uuid группы записей */
  const [groupRecordUuid, setGroupRecordUuid] = useState('');
  /** Uuid выбранной записи не подтвержденной */
  const [goldRecordUuidLocal, setGoldRecordUuidLocal] = useState('');
  const [goldRecordsIndex, setGoldsRecordsIndex] = useState<{column: number; row: number}[]>([]);

  /** Золотая запись подвежденная */
  const goldRecordData = deduplicationGroupProtocolInfo ? deduplicationGroupProtocolInfo?.mainItem?.attributes : null;
  /** Используем хранилище */
  const deduplicationProtocolInfo = useSelector((state: RootState) => state?.deduplication.deduplicationProtocolInfo);

  const catalogDeduplicationId =
    !isEmpty(deduplicationProtocolInfo) && deduplicationProtocolInfo[0]?.catalogDeduplicationId;
  /** Добавляем в золотую запись полученные даннные */
  useEffect(() => {
    setGoldRecord(goldRecordData);
  }, [goldRecordData]);
  useEffect(() => {
    isEmpty(deduplicationProtocolInfo) && dispatch(getDeduplicationProtocolInfo(match.params.id));
  }, [deduplicationProtocolInfo]);

  /** Функция для переключения записи в золотую */
  const goldRecordHandler = useCallback(
    (event: any, checked: boolean, value: any, id: string, index: number) => {
      event.preventDefault();
      if (checked) {
        setPayloadData({
          ...payloadData,
          catalogDeduplicationId: catalogDeduplicationId,
          mainItemUuid: id,
        });
        setIsGoldRecord(true);
        setGoldRecordUuidLocal(id);
        setGoldRecord(value);
        setGoldsRecordsIndex(
          value.map((_: any, attrIndex: number) => {
            return {
              column: index,
              row: attrIndex,
            };
          })
        );
      } else {
        setGoldRecord(null);
        setIsGoldRecord(false);
        setGoldsRecordsIndex([]);
      }
    },
    [catalogDeduplicationId, payloadData, setGoldRecord, setIsGoldRecord, setPayloadData]
  );
  /** Функция для добавления значений в золотую запись*/
  const copyValueToGoldRecordHandler = useCallback(
    (
      event: any,
      value: any,
      caption: string,
      recordUuid: boolean,
      origin: boolean,
      active: boolean,
      coordinate: {column: number; row: number}
    ) => {
      event.preventDefault();
      const updatedGoldRecord = goldRecord?.map((item) => {
        if (item.caption === caption) {
          dispatch(color('red'));
          dispatch(setCaption(item.caption, item.recordUuid));
          return {...item, value: value};
        } else {
          dispatch(color('gray'));
          dispatch(setRecordId(recordUuid));
          return item;
        }
      });
      const data = payloadData?.updatedMainItemFields?.filter((item: any) => item.attribute !== origin);
      active === false
        ? setPayloadData({
            ...payloadData,
            updatedMainItemFields: !isEmpty(data)
              ? [{attribute: origin, value: value}, ...data]
              : [{attribute: origin, value: value}],
          })
        : setPayloadData({
            ...payloadData,
            updatedMainItemFields: payloadData?.updatedMainItemFields?.filter((item: any) => item.attribute !== origin),
          });
      setGroupRecordUuid(groupRecordUuid);
      setGoldRecord(updatedGoldRecord);
      const newGoldRecordsIndex = [...goldRecordsIndex.filter((item) => item.row !== coordinate.row), coordinate];
      setGoldsRecordsIndex(newGoldRecordsIndex);
    },
    [dispatch, goldRecord, goldRecordsIndex, groupRecordUuid, payloadData, setGoldRecord, setPayloadData]
  );

  const renderRecordTitle = useCallback(
    (record: any, index: number) => {
      return (
        <div className="d-flex align-items-center">
          <span className="flex-grow-1">{record.title}</span>
          {status === 'DRAFT' && (
            <>
              <div className="d-flex gap-8 align-items-center">
                <Typography.Text type="secondary">Золотая</Typography.Text>
                <Switch
                  size="small"
                  checked={isGoldRecord && goldRecordUuidLocal === record.recordUuid}
                  onChange={(checked, event) =>
                    goldRecordHandler(event, checked, record.attributes, record.recordUuid, index)
                  }
                />
              </div>
              <Button
                id={record?.groupRecordUuid}
                type="text"
                onClick={() => {
                  dispatch(getBlockLinkGroup(groupId, record?.groupRecordUuid));
                }}
                disabled={
                  (isGoldRecord && goldRecordUuidLocal === record?.recordUuid) ||
                  deduplicationGroupProtocolInfo?.records.map((item) => item?.attributes).length <= 2
                }
                icon={<DeleteOutlined />}
              />
            </>
          )}
        </div>
      );
    },
    [
      deduplicationGroupProtocolInfo?.records,
      dispatch,
      goldRecordHandler,
      goldRecordUuidLocal,
      groupId,
      isGoldRecord,
      status,
    ]
  );

  const columns: TableProps<any>['columns'] = useMemo(() => {
    const additionalRecords = deduplicationGroupProtocolInfo?.records?.map((record, index) => {
      return {
        key: `column_${index}`,
        title: renderRecordTitle(record, index),
        dataIndex: `column_${index}`,
        render: (_: any, record: RecordType, columnIndex: number) => {
          const recordsValue = record[`column_${index}`];
          return (
            <DeduplicationGroupModalValue
              recordsValue={recordsValue}
              goldRecord={goldRecord}
              copyValueToGoldRecordHandler={copyValueToGoldRecordHandler}
              index={index}
              caption={caption}
              status={status}
              goldRecordsIndex={goldRecordsIndex}
              currentIndex={{column: index, row: columnIndex}}
            />
          );
        },
      };
    });
    return [
      {
        key: 'columns_name',
        dataIndex: 'columns_name',
        title: '',
        render: (value) => {
          return <div className={styles.columnsNameItem}>{value} </div>;
        },
      },
      {
        key: 'gold_record',
        dataIndex: 'gold_record',
        title: 'Золотая запись',
        render: (value) => {
          if (value) {
            return (
              <div
                className={cn(styles.contentBlockItem, {
                  [styles.goldRecordItem]: isGoldRecord,
                })}
              >
                {/* {value.value} */}
                {renderDeduplicationValue(value)}
              </div>
            );
          }
          return <div className={styles.contentBlockItem}></div>;
        },
      },
      ...additionalRecords,
    ] as TableProps<any>['columns'];
  }, [
    caption,
    copyValueToGoldRecordHandler,
    deduplicationGroupProtocolInfo?.records,
    goldRecord,
    goldRecordsIndex,
    isGoldRecord,
    renderRecordTitle,
    status,
  ]);

  const dataSource = useMemo(() => {
    const fields = deduplicationGroupProtocolInfo?.deduplicationFields || deduplicationGroupProtocolInfo?.fields;
    const additionalRecords = deduplicationGroupProtocolInfo?.records;
    const sortedGoldRecord =
      fields && goldRecord ? sortRecordArrayByStringArray(goldRecord, fields, 'caption') : goldRecord;

    const rows = fields?.map((field, index) => {
      const row: any = {
        columns_name: field,
        key: `column_${index}`,
      };
      if (additionalRecords) {
        additionalRecords.map((record, recordIndex) => {
          const sortedRecordAttributes = sortRecordArrayByStringArray(record.attributes, fields, 'caption');

          row[`column_${recordIndex}`] = sortedRecordAttributes[index];
        });
      }
      if (!isEmpty(sortedGoldRecord)) {
        row.gold_record = sortedGoldRecord[index];
      }
      return row;
    });
    return rows;
  }, [deduplicationGroupProtocolInfo, goldRecord]);

  if (isEmpty(deduplicationGroupProtocolInfo)) return <Skeleton active />;
  return <Table columns={columns} dataSource={dataSource} scroll={{x: true}} className={styles.table} />;
};
export default React.memo(DeduplicationGroupTable);
