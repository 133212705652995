import React from 'react';
import {Button, Form, Input} from 'ui-kit';
import DynamicSelect from '../../../components/FormElements/DynamicSelect';

const SysActionForm = ({onFinish, actionCode}) => {
  const [form] = Form.useForm();

  const renderFormElements = () => {
    let elem;
    let name;
    switch (actionCode) {
      case 'commentForming':
        {
          elem = <Input.TextArea />;
          name = 'statusComment';
        }
        break;
      case 'changeResponsible':
        {
          elem = (
            <DynamicSelect
              placeholder="Выбрать"
              notFoundContentPlaceholder="Введите запрос"
              feachoptions={{
                referenceStore: 'mdm',
                url: '/api/v1/catalogs/user_profile/items/search',
                method: 'post',
                valueSearchName: 'username',
                catalogOrigin: 'user_profile',
                displayedField: ['lastName', 'firstName', 'middleName'],
                valueField: 'uuid',
                defaultSize: 20,
              }}
              handleSelectChange={(value) => {
                form.setFieldsValue({responsibleId: value});
              }}
            />
          );
          name = 'responsibleId';
        }
        break;
    }
    return <Form.Item name={name}>{elem}</Form.Item>;
  };

  return (
    <div className="mt-3">
      <Form form={form} name="sys-action-form" onFinish={onFinish}>
        {renderFormElements()}
        <Form.Item className="text-right mt-5">
          <Button type="primary" htmlType="submit">
            Сохранить
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default SysActionForm;
