import {isEmpty} from 'lodash';

export const filterOptions = (item) => {
  switch (item) {
    case isEmpty(item?.old):
      return item;
    case isEmpty(item?.default_in_list):
      return item;
    default:
      return null;
  }
};

export const priorityValidate = async (_, value) => {
  const isNotValidToMin = value < 0;
  const isNotValidToMax = value > 1000;

  if (isNotValidToMin) {
    return Promise.reject('Значение не должно быть меньше 0');
  }
  if (isNotValidToMax) {
    return Promise.reject('Значение не должно быть больше 1000');
  }
  return Promise.resolve();
};
