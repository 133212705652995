import React, {useEffect, useState} from 'react';
import useCRUD from '../../hooks/CRUD.hook';
import {convertDate} from '../Helpers/Utils';
import ReferenceReader from '../RecordRedactor/ReferenceReader';
import {StyledTable} from '../Themes/Components';

const BusinessVersions = ({recordUuid}) => {
  const {REACT_APP_GOTO_URL} = window._env;

  const columns = [
    {
      title: '№ версии',
      dataIndex: 'versionNumber',
      key: 'versionNumber',
      render: (text, record) => <a href={`${REACT_APP_GOTO_URL}/services/${record.uuid}`}>{text}</a>,
    },
    {
      title: 'Автор',
      dataIndex: 'authorFio',
      key: 'authorFio',
      render: (_, rec) => (
        <ReferenceReader
          catalog={'service'}
          referenceCatalogPattern={['authorFio']}
          apiInfoUrl={`/api/v1/catalogs/service/items/${rec.uuid}/author`}
          apiUrl={true}
        />
      ),
    },
    {
      title: 'Дата создания',
      dataIndex: 'createDate',
      key: 'createDate',
      render: (text) => convertDate(text, 'YYYY-MM-DD'),
    },
    {
      title: 'Статус',
      dataIndex: ['status', 'caption'],
      key: 'versionNumber',
    },
  ];

  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);

  const {getData, data, loading, total} = useCRUD({
    apiUrl: `/api/v1/service/${recordUuid}/versions`,
  });

  useEffect(() => {
    getData({
      page,
      size,
    });
  }, [page, size]);

  const handleTableChange = ({current, pageSize}) => {
    setPage(current);
    setSize(pageSize);
  };

  return (
    <StyledTable
      dataSource={data}
      columns={columns}
      loading={loading}
      rowKey={(record) => record.uuid}
      pagination={{
        total: total,
      }}
      onChange={handleTableChange}
      bordered
    />
  );
};

export default BusinessVersions;
