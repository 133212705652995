import React, {useEffect, useState} from 'react';
import {Collapse, Typography} from 'ui-kit';
import styles from './VersionSystemInformation.module.scss';
import {isEmpty} from 'lodash';
import dayjs from 'dayjs';
import {useRouteMatch} from 'react-router';

const {Panel} = Collapse;

interface VersionSystemInformationProps {
  data?: any[];
}

const VersionSystemInformation = ({data}: VersionSystemInformationProps) => {
  return (
    <>
      <Collapse>
        <Panel header="Системная информация" key="1">
          <div className={styles.panel}>
            {data &&
              data.map((version: any) => {
                return (
                  <div key={version?.version} className={styles.version}>
                    <Typography.Title level={5}>Версия {version?.version}</Typography.Title>
                    <div>
                      <Typography.Text type="secondary">UUID:</Typography.Text> {version?.uuid}
                    </div>
                    <div>
                      <Typography.Text type="secondary">Дата создания: </Typography.Text>
                      {dayjs(version?.createDate).format('DD-MM-YYYY')}
                    </div>
                    <div>
                      <Typography.Text type="secondary">Дата изменения: </Typography.Text>
                      {version?.dateFrom ? dayjs(version?.dateFrom).format('DD-MM-YYYY') : '-'}
                    </div>
                    <div>
                      <Typography.Text type="secondary">Автор записи:</Typography.Text> {version?.authorFio}
                    </div>
                    <div>
                      <Typography.Text type="secondary">Автор изменений:</Typography.Text> {version?.authorFio}
                    </div>
                    <div>
                      <Typography.Text type="secondary">Версия:</Typography.Text> {version?.version}
                    </div>
                    <div>
                      <Typography.Text type="secondary">Идентификатор:</Typography.Text> {version?.id}
                    </div>
                  </div>
                );
              })}
          </div>
        </Panel>
      </Collapse>
    </>
  );
};

export default VersionSystemInformation;
