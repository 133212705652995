import React, {useCallback} from 'react';
import {Tag, Typography} from 'ui-kit';
import styles from './PageHeader.module.scss';
import {ReactElement, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {RootState} from 'redux/types/rootState';
import {useHistory, useLocation} from 'react-router-dom';
import {Button, SliceTabs} from 'ui-kit';
import {LeftOutlined} from '@ant-design/icons';
import classNames from 'classnames';
import {BreadCrumbsBlock} from './BreadCrumbsBlock';
import {useDocumentTitle} from 'hooks/useDocumentTitle';
import {useActiveTab, useBreadCrumbs} from 'hooks/navigation';
import {LinesBlock} from './LinesBlock';
import {SkeletonPageHeader} from './SkeletonPageHeader';

export const PageHeader = (): ReactElement => {
  const location = useLocation();
  const history = useHistory();
  const breadCrumbs = useBreadCrumbs();
  const {
    buttonsProps,
    tabsProps,
    tagProps,
    linesBlockProps,
    backButton,
    showDefaultBackButton,
    loading: LoadingState,
  } = useSelector((state: RootState) => state.pageHeader);
  const {activeTab, onChangeTab} = useActiveTab();

  const isCurrentPath = useCallback(
    (path?: string) => {
      return path === location.pathname;
    },
    [location.pathname]
  );

  const loading = useMemo(
    () => !!isCurrentPath(LoadingState?.path) && LoadingState?.status,
    [LoadingState?.path, LoadingState?.status, isCurrentPath]
  );

  const title = useMemo(() => {
    if (loading) {
      return '';
    }
    const lastBreadCrumb = breadCrumbs[breadCrumbs.length - 1];
    return lastBreadCrumb.title || '';
  }, [breadCrumbs, loading]);

  useDocumentTitle(title ? `${title} - MDM Front` : 'MDM Front');

  const goBackHandler = useCallback(() => {
    const linkToBack = backButton?.to;
    if (linkToBack && isCurrentPath(backButton.path)) {
      history.push(linkToBack);
    } else {
      history.goBack();
    }
  }, [backButton, history, isCurrentPath]);

  if (loading) {
    return <SkeletonPageHeader />;
  }

  return (
    <div className={styles.pageHeader}>
      <div className={styles.top}>
        <div className={styles.main}>
          <BreadCrumbsBlock />
          <div className={styles.titleWrapper}>
            <Button
              onClick={goBackHandler}
              icon={<LeftOutlined />}
              className={classNames(styles.backButton, {
                [styles['hide']]: !(
                  (backButton?.to && isCurrentPath(backButton?.path)) ||
                  (showDefaultBackButton?.show && isCurrentPath(showDefaultBackButton?.path))
                ),
              })}
            />
            <Typography.Title level={2}>{title}</Typography.Title>
            {tagProps && isCurrentPath(tagProps.path) && (
              <Tag {...tagProps.props} className={classNames(tagProps.props?.className, styles.tag)} />
            )}
            <div className={styles.flexGrow}></div>
            {buttonsProps?.items && isCurrentPath(buttonsProps.path) && (
              <div className={styles.buttonsBlock}>
                {buttonsProps.items.map((buttonProp, index) => (
                  <Button {...buttonProp} key={index} />
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
      {linesBlockProps && isCurrentPath(linesBlockProps.path) && <LinesBlock items={linesBlockProps.items} />}
      {tabsProps?.props?.items && isCurrentPath(tabsProps.path) && (
        <div className={styles.tabs}>
          <SliceTabs.Switcher items={tabsProps.props.items} activeKey={activeTab} onChange={onChangeTab} />
        </div>
      )}
    </div>
  );
};
