import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router';
import {isEmpty} from 'lodash';
import cn from 'classnames';
import dayjs from 'dayjs';
import {Button, Modal, Popover, Table, TableProps} from 'ui-kit';

import {deduplication} from '../helpers/constants';
import {deduplicationJobsFilter} from '../helpers/deduplicationFilterUtils';
import DeduplicationProtocolForm from '../DeduplicationProtocolForm/DeduplicationProtocolForm';
import DeduplicationFilters from '../DeduplicationFilters/DeduplicationFilters';
import {
  authorUserNameFilter,
  catalogFilter,
  jobTitleFilter,
  renderStatusDeduplicationColor,
  statusFilter,
} from '../helpers/DeduplicationUtils';
import {
  addStartDeduplication,
  getDeduplicationJobProtocol,
  getDeduplicationJobProtocolInfoDelete,
  getDeduplicationProtocolInfo,
} from '../../../redux/thunk/deduplicationThunk';
import {addRulesDeduplication, setSystemInfoProtocolAC} from '../../../redux/actions/actions';

import './DeduplicationJob.scss';
import styles from './DeduplicationJob.module.scss';

import {DeleteOutlined, ExclamationCircleOutlined, PlayCircleOutlined, PlusOutlined} from '@ant-design/icons';
import {RootState} from 'redux/types/rootState';
import {EDeduplicationStatus, RecordType} from 'types';

/** Протоколы задания дедупликации */
const DeduplicationJob = () => {
  /** Маршрутизатор */
  const history = useHistory();
  /** Отправка данных в хранилище */
  const dispatch = useDispatch();
  /** Состояние отображения модального окна */
  const [deduplicationModalVisible, setDeduplicationModalVisible] = useState(false);
  /** Начальное состояние фильтра */
  const [filter, setFilter] = useState({
    status: null,
    author: null,
    catalog: null,
    launchDate: null,
    name: null,
    statusConnection: null,
    userConfirmConnect: null,
  });
  /** Используем хранилище */
  const protocolsJobDeduplication = useSelector((state: RootState) => state?.deduplication.deduplicationJobProtocol);
  const loading = useSelector((state: RootState) => state?.deduplication.loading);
  const [formStatus, setFormStatus] = useState('');
  const [record, setRecord] = useState({});
  const [recordUuid, setRecordUuid] = useState(null);

  /** Получение данных о заданиях дедупликации */
  useEffect(() => {
    if (!protocolsJobDeduplication) {
      dispatch(getDeduplicationJobProtocol());
    }
  }, [protocolsJobDeduplication, recordUuid]);

  /** Функция для отображения отфильтрованной таблицы */
  const filteringDeduplicationJobList = deduplicationJobsFilter(protocolsJobDeduplication, filter);
  /** Отрисовка подсказки для алгоритмов */
  const renderPopoverContent = (
    <ol className={styles.popover__content}>
      {deduplication.status.map((content, key) => (
        <li key={key}>{content}</li>
      ))}
    </ol>
  );
  /** Функция перехода к форме просмотра/редактирования задания дедупликации */
  const deduplicationJobInfoHandler = (record: RecordType) => {
    return {
      onDoubleClick: () => {
        setRecordUuid(record?.uuid);
        setRecord(record);
        setFormStatus('editStatus');
        dispatch(addRulesDeduplication(record?.rulesDeduplication));
        setDeduplicationModalVisible(true);
      },
    };
  };
  /** Колонки для таблицы заданий дедупликации */
  const columnsDeduplicationTableList: TableProps<any>['columns'] = [
    {
      title: 'Наименование',
      dataIndex: 'name',
      key: 'name',
      width: '16%',
      render: (text) => <div className={styles.open_job_id_btn}>{text}</div>,
    },
    {
      title: 'Автор',
      dataIndex: 'username',
      key: 'username',
      width: '16%',
      render: (text) => <p>{text}</p>,
    },
    {
      title: 'Дата запуска',
      dataIndex: 'launchDate',
      key: 'launchDate',
      width: '16%',
      render: (text) => <p>{!isEmpty(text) && dayjs(text).format('DD.MM.YYYY HH:mm')}</p>,
    },
    {
      title: (
        <div className="d-flex w-100">
          <Popover content={renderPopoverContent} className={styles.popover} rootClassName={styles.rootPopover}>
            <div className={styles.exclamation_point}>{deduplication.statusInTable}</div>
            <ExclamationCircleOutlined size={18} className={styles.statusIcon} />
          </Popover>
        </div>
      ),
      dataIndex: ['status', 'title'],
      key: 'title',
      width: '16%',
      render: (text, record) => <p className={renderStatusDeduplicationColor(record?.status?.key)}>{text}</p>,
    },
    {
      title: 'Справочник',
      dataIndex: 'catalogDeduplicationName',
      key: 'catalogDeduplicationName',
      width: '16%',
      render: (text) => <p>{text}</p>,
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      render: (record) => {
        if (!deduplication.openProtocol || record?.status?.key !== 5) {
          return;
        }
        return (
          <Button
            className={styles.button_box__text}
            type="link"
            onClick={() => {
              dispatch(setSystemInfoProtocolAC(record.protocolDeduplication));
              dispatch(getDeduplicationProtocolInfo(record.protocolDeduplication.uuid));
              history.push(`/deduplication/protocols/${record.protocolDeduplication.uuid}`);
            }}
          >
            {deduplication.openProtocol}
          </Button>
        );
      },
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      render: (record) => {
        // на будущее - надо проверить индексы, не факт что они правильные
        const status = record?.status?.key;
        const disabledStatuses = [EDeduplicationStatus.process, EDeduplicationStatus.order];
        const isClickedStatuses = [EDeduplicationStatus.new, EDeduplicationStatus.error, EDeduplicationStatus.success];

        return (
          <PlayCircleOutlined
            className={cn(styles.startIcon, {
              [styles.disabled]: disabledStatuses.includes(status),
            })}
            onClick={
              isClickedStatuses.includes(status)
                ? () => {
                    dispatch(addStartDeduplication(record.uuid));
                  }
                : undefined
            }
          />
        );
      },
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      width: '36px',
      render: (record) => {
        // на будущее - индексы надо проверить
        const status = record?.status?.key;
        const isDisabled = !![
          EDeduplicationStatus.process,
          // EDeduplicationStatus.error,
          EDeduplicationStatus.success,
        ].includes(status);

        const isStatusForDelete = [
          EDeduplicationStatus.new,
          EDeduplicationStatus.order,
          EDeduplicationStatus.error,
        ].includes(status);
        return (
          <DeleteOutlined
            disabled={isDisabled}
            className={cn(styles.deleteIcon, {
              [styles.disabled]: isDisabled,
            })}
            onClick={
              isStatusForDelete ? () => dispatch(getDeduplicationJobProtocolInfoDelete(record?.uuid)) : undefined
            }
          />
        );
      },
    },
  ];

  return (
    <>
      <div className="containerWhite d-flex flex-column gap-24">
        <div>
          <Button
            id="job"
            type="primary"
            onClick={() => {
              setDeduplicationModalVisible(true);
              setFormStatus('createNewDeduplication');
            }}
            icon={<PlusOutlined />}
            loading={loading}
          >
            {deduplication.buttonText}
          </Button>
        </div>

        <>
          <DeduplicationFilters
            deduplicationJobFilter={true}
            jobTitleFilter={jobTitleFilter(protocolsJobDeduplication)}
            authorUserName={authorUserNameFilter(protocolsJobDeduplication)}
            catalogTitle={catalogFilter(protocolsJobDeduplication)}
            statusDeduplication={statusFilter(protocolsJobDeduplication)}
            // protocolsJobDeduplication={protocolsJobDeduplication}
            loading={loading}
            setFilter={setFilter}
          />
          <Table
            columns={columnsDeduplicationTableList}
            dataSource={protocolsJobDeduplication ? filteringDeduplicationJobList : null}
            rowKey={(record) => record?.key}
            onRow={deduplicationJobInfoHandler}
            bordered
            loading={loading}
            scroll={{x: true}}
          />
        </>

        <Modal
          open={deduplicationModalVisible}
          footer={null}
          okText={deduplication.save}
          cancelText={deduplication.cancel}
          className={styles.modal}
          // loading={true}
          closable={false}
          width={800}
        >
          <DeduplicationProtocolForm
            formStatus={formStatus}
            protocolsJobDeduplicationInfo={formStatus === 'editStatus' ? record : null}
            recordUuid={recordUuid}
            setDeduplicationModalVisible={setDeduplicationModalVisible}
          />
        </Modal>
      </div>
    </>
  );
};
export default React.memo(DeduplicationJob);
