import {
  BellOutlined,
  CheckOutlined,
  DatabaseOutlined,
  SettingOutlined,
  ScheduleOutlined,
  HomeOutlined,
  SwapOutlined,
  ReadOutlined,
  FolderOpenOutlined,
  MinusSquareOutlined,
  CheckSquareOutlined,
  CalendarOutlined,
  CloseOutlined,
  PlusOutlined,
} from '@ant-design/icons/lib/icons';

import {ReactElement, ReactNode} from 'react';
import {AntIconComponentType} from 'types';
import {CustomIcon} from 'ui-kit/CustomIcon/CustomIcon';

export const iconsMap: Record<string, AntIconComponentType> = {
  SettingOutlined,
  DatabaseOutlined,
  CheckOutlined,
  BellOutlined,
  ScheduleOutlined,
  HomeOutlined,
  SwapOutlined,
  ReadOutlined,
  FolderOpenOutlined,
  MinusSquareOutlined,
  CheckSquareOutlined,
  CalendarOutlined,
  CloseOutlined,
  PlusOutlined,
};

export const customIconsMap: Record<string, ReactNode> = {
  // outlined
  DeduplicationOutlined: <CustomIcon name="deduplicationOutlined" />,

  // filled
  UserFilled: <CustomIcon name="userFilled" />,
};

export const convertStringToIcon = (iconName: string | null | undefined): ReactElement | null => {
  if (!iconName) {
    return null;
  }
  const IconComponent = iconsMap[iconName];
  const customIconComponent = customIconsMap[iconName];
  if (customIconComponent) {
    return <>{customIconComponent}</>;
  }
  return IconComponent ? <IconComponent /> : null;
};
