import React, {useState, useCallback} from 'react';
import {Form, Input, Button} from 'ui-kit';
import MultiplySelection from '../MultiplyeSelection';
import {get} from 'lodash';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  CloseOutlined,
  FileAddOutlined,
  SaveOutlined,
  SearchOutlined,
} from '@ant-design/icons';

const columns = [
  {
    title: 'Идентификатор',
    dataIndex: 'id',
  },
  {
    title: 'Наименование',
    dataIndex: 'caption',
  },
  {
    title: 'Код',
    dataIndex: 'code',
  },
];

const buttonLayout = {
  wrapperCol: {span: 7, offset: 17},
};

const validateMessages = {
  required: 'Поле обязательно для заполнения!',
};

const SubsystemForm = ({onSubmit, editibleItem, onCancel}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedSystem, setSelectedSystem] = useState(editibleItem ? editibleItem.externalSystem : null);
  const [defaultSelected, setDefaultSelected] = useState(
    get(editibleItem, 'externalSystem.id') ? [editibleItem.externalSystem.id] : []
  );
  const [form] = Form.useForm();

  const initialValues = {
    caption: editibleItem && editibleItem.caption,
    code: editibleItem && editibleItem.code,
  };

  const onExternalSelect = (value, key) => {
    setModalVisible(false);
    setSelectedSystem(value[0]);
    form.setFieldsValue({externalSystem: {id: value[0] && value[0].id}});
  };

  const clearSubsystem = () => {
    setSelectedSystem(null);
    setDefaultSelected([]);
    form.setFieldsValue({externalSystem: null});
  };

  const onHandleCancel = () => {
    form.resetFields();
    onCancel();
  };

  return (
    <Form
      form={form}
      onFinish={(values) => onSubmit(values, editibleItem)}
      initialValues={initialValues}
      validateMessages={validateMessages}
      layout="vertical"
    >
      <Form.Item label="Наименование" name="caption" rules={[{required: true}]}>
        <Input placeholder="Введите наименование" />
      </Form.Item>
      <Form.Item label="Код" name="code" rules={[{required: true}]}>
        <Input placeholder="Введите код" />
      </Form.Item>
      <Form.Item>
        <div className="d-flex gap-8">
          <p className="d-flex mt-1">Внешняя система:</p>
          <div className="d-flex flex-column gap-8">
            <Form.Item noStyle name="externalSystem">
              <Button onClick={() => setModalVisible(true)} icon={<SearchOutlined />}>
                Выбрать
              </Button>
            </Form.Item>
            <p className="mb-0">{selectedSystem && selectedSystem.caption}</p>
            {selectedSystem && (
              <Button type="link" className="m-0 p-0" onClick={clearSubsystem}>
                Очистить
              </Button>
            )}
          </div>
        </div>
      </Form.Item>
      <Form.Item>
        <div className="gap-8 d-flex justify-content-end">
          <Button onClick={onHandleCancel} icon={<CloseOutlined />}>
            Отменить
          </Button>
          <Button type="primary" htmlType="submit" icon={editibleItem ? <SaveOutlined /> : <FileAddOutlined />}>
            {editibleItem ? 'Обновить' : 'Создать'}
          </Button>
        </div>
      </Form.Item>
      <MultiplySelection
        onCancel={useCallback(() => setModalVisible(false), [])}
        onOk={useCallback(onExternalSelect, [form])}
        open={modalVisible}
        columns={columns}
        isSingleSelection={true}
        defaultSelected={defaultSelected}
        rowKey="id"
        modalOptions={{
          title: 'Выбор внешней системы',
          okText: 'Подтвердить',
          cancelText: 'Отменить',
          okButtonProps: {icon: <CheckCircleOutlined />},
          cancelButtonProps: {
            icon: <CloseCircleOutlined />,
          },
        }}
      />
    </Form>
  );
};

export default SubsystemForm;
