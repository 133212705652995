import React, {useReducer, useEffect, ReactNode, useState} from 'react';
import {fetchFunc} from '../../Utils/security/http/mdm';
import moment from 'moment';
import {Table, Modal, Button, Empty, Typography, Flex, Popover} from 'ui-kit';
import RecordRedactor from '../RecordRedactor';
import VerificatorHead from '../Verification/VerificatorHead';
import styles from './Versions.module.scss';
import {isBoolean, isEmpty} from 'lodash';
import {renderStatus} from '../Helpers/Utils';
import {Link, useHistory} from 'react-router-dom';
import {ExclamationCircleOutlined, SwapOutlined} from '@ant-design/icons';
import classNames from 'classnames';
import {TablePaginationConfig} from 'antd/es/table/interface';

const reducer = (
  state: {
    columns: any[];
    data: any[];
    unverified: any;
    loading: boolean;
    modalVisible: boolean;
    versionId: any;
    recordUuidLocal: any;
    totalElements: any;
  },
  action: {
    type: string;
    payload: any;
  }
) => {
  switch (action.type) {
    case 'setData':
      return {...state, data: action.payload};
    case 'setLoading':
      return {...state, loading: action.payload};
    case 'setModalVisible':
      return {...state, modalVisible: action.payload};
    case 'setVersionId':
      return {...state, versionId: action.payload};
    case 'setRecordUuid':
      return {...state, recordUuidLocal: action.payload};
    case 'setTotal':
      return {...state, totalElements: action.payload};
    case 'setUnverified':
      return {...state, unverified: action.payload};
    default:
      return state;
  }
};

export interface VersionsProps {
  type?: 'deleted' | 'info';
  recordUuid?: string;
  catalogUuid?: string;
  versionDate?: string | null;
  versionModal?: (value: any) => ReactNode;
  includeDeprecatedHistory?: boolean;
  versionKey?: string;
  setVersionKey?: (value: string) => void;
  modal?: boolean;
  setShowVersionModal?: (value: boolean) => void;
  isUnverified?: boolean;
  isVersionComparison?: boolean;
  setIsRestored?: React.Dispatch<React.SetStateAction<boolean>>;
  onRestoreCallback?: () => void;
  isChanged?: boolean;
}

const Versions = ({
  type,
  recordUuid,
  catalogUuid,
  versionDate,
  versionModal,
  includeDeprecatedHistory,
  versionKey,
  setVersionKey,
  modal = false,
  setShowVersionModal,
  isUnverified,
  isVersionComparison,
  setIsRestored,
  onRestoreCallback,
  isChanged,
}: VersionsProps) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const history = useHistory();
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: 10,
  });

  const handleTableChange = (pagination: TablePaginationConfig) => {
    setPagination(pagination);
  };

  const versionColumns = [
    {
      title: 'Идентификатор',
      width: '20%',
      dataIndex: 'id',
      render: (text: string, record: any) =>
        isBoolean(record.active) ? (
          <span
            className="link"
            onClick={() => {
              type !== 'deleted' && setVersionKey && setVersionKey('version');
              showModal(record.id, record.uuid);
            }}
          >
            {text}
          </span>
        ) : (
          <Link to={`/catalog/${catalogUuid}/${recordUuid}/verify`}>{record.version}</Link>
        ),
    },
    {
      title: 'Автор',
      dataIndex: 'authorFio',
      width: '20%',
    },
    {
      title: 'Действует с',
      dataIndex: 'dateFrom',
      width: '20%',
      render: (text: string) => renderTableDate(text),
    },
    {
      title: 'Действует по',
      dataIndex: 'dateTo',
      width: '20%',
      render: (text: string) => renderTableDate(text),
    },
  ];

  if (type !== 'deleted') {
    versionColumns.push({
      title: 'Статус',
      dataIndex: 'active',
      render: (active: string, record: any) => {
        const isWithoutVerif = isBoolean(active);
        const isUnverified = !isEmpty(record.sysVerifyInfoDraft);
        if (isUnverified) {
          const currentVerson = record.sysVerifyInfoDraft.sourceId.id;
          return <>{currentVerson === record.id ? 'Действующая' : 'Архив'}</>;
        } else {
          return (
            <>{isWithoutVerif ? (active ? 'Действующая' : 'Архив') : renderStatus(record, 'sysVerifyInfo.status')}</>
          );
        }
      },
      width: '',
    });
  }

  const initialState = {
    columns: versionColumns,
    data: [],
    unverified: {},
    loading: false,
    modalVisible: false,
    versionId: null,
    recordUuidLocal: null,
    totalElements: null,
  };
  const [state, dispatch] = useReducer(reducer, initialState);
  const {columns, data, loading, modalVisible, versionId, recordUuidLocal, unverified} = state;

  if (type === 'deleted')
    versionColumns.push({
      title: 'Дата создания записи',
      dataIndex: 'createDate',
      render: (text: string) => renderTableDate(text),
      width: '',
    });

  const renderTableDate = (date: string) => (date ? moment(date).format('DD-MM-YYYY') : '');

  useEffect(() => {
    dispatch({type: 'setLoading', payload: true});
    getVersions().then(() => {
      if (type !== 'deleted' && isUnverified) getRecord().then();
    });
  }, [type, dispatch, isUnverified, pagination.current, pagination.pageSize, isChanged]);

  useEffect(() => {
    dispatch({type: 'setData', payload: [unverified, ...data]});
  }, [unverified]);

  const getVersions = async () => {
    try {
      let url = `/api/v1/catalogs/${catalogUuid}/items/${recordUuid}/history`;
      if (type === 'deleted') {
        url = `/api/v1/catalogs/${catalogUuid}/items/history/deleted`;
      }
      const data = await fetchFunc({
        url,
        method: 'get',
        params: {
          filterDate: versionDate,
          includeDeprecatedHistory,
          page: pagination?.current ? pagination.current - 1 : 0,
          size: pagination?.pageSize || 10,
          sort: 'id,desc',
        },
      });
      dispatch({type: 'setData', payload: data.content});
      dispatch({type: 'setTotal', payload: data.totalElements});
      setPagination((pagination) => ({
        ...pagination,
        current: pagination.current,
        total: data.totalElements,
      }));
      dispatch({type: 'setLoading', payload: false});
    } catch (error) {
      if (type === 'deleted' || !isUnverified) dispatch({type: 'setLoading', payload: false});
    }
  };

  const getRecord = async () => {
    try {
      const data = await fetchFunc({
        url: `/api/v1/catalogs/${catalogUuid}/items/${recordUuid}/unverified`,
      });
      dispatch({type: 'setUnverified', payload: {...data, sysVerifyInfo: data.sysVerifyInfo}});
      dispatch({type: 'setLoading', payload: false});
    } catch (error) {}
  };

  const showModal = (id: string, uuid: string) => {
    dispatch({type: 'setVersionId', payload: id});
    dispatch({type: 'setRecordUuid', payload: uuid});
    dispatch({type: 'setModalVisible', payload: true});
  };

  const closeModal = () => {
    dispatch({type: 'setModalVisible', payload: false});
    if (setVersionKey) {
      setVersionKey('');
    }
  };

  const onRestore = () => {
    getVersions().then();
    setIsRestored && setIsRestored((isRestored) => !isRestored);
    closeModal();
    setShowVersionModal && setShowVersionModal(false);
    if (setVersionKey) {
      setVersionKey('');
    }
  };
  const sortedData = data.sort((a: any, b: any) => b.version - a.version);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    hideSelectAll: true,
    getCheckboxProps: (record: any) => {
      const isWithVerif = !isBoolean(record.active);
      if (record.sysVerifyInfo && isWithVerif) {
        return {...record, disabled: true};
      }
      if (selectedRowKeys.length == 2) {
        const isSelected = record.version === selectedRowKeys[0] || record.version === selectedRowKeys[1];
        return {...record, disabled: !isSelected};
      }
    },
    renderCell(checked: boolean, record: any, index: number, node: ReactNode) {
      return (
        <Popover placement="rightTop" content={<div className={styles.popover}>Для сравнения выберите две версии</div>}>
          {node}
        </Popover>
      );
    },
  };

  const onCompareVersions = () => {
    history.push(`/catalog/${catalogUuid}/${recordUuid}/compare/${selectedRowKeys[0]}/${selectedRowKeys[1]}`);
  };

  return (
    <>
      {type === undefined && unverified.sysVerifyInfo && (
        <VerificatorHead data={unverified} catalogUuid={catalogUuid} recordUuid={recordUuid} />
      )}
      {data && data.length ? (
        isVersionComparison ? (
          <>
            <Table
              rowSelection={rowSelection}
              dataSource={sortedData}
              loading={loading}
              columns={columns}
              rowKey={(record: any) => record.id}
              bordered
              className={styles['modalMode-table']}
              pagination={pagination}
              onChange={handleTableChange}
            />
            {selectedRowKeys.length > 0 && (
              <Button
                onClick={onCompareVersions}
                type="primary"
                disabled={selectedRowKeys.length < 2}
                icon={<SwapOutlined />}
              >
                Сравнить версии
              </Button>
            )}
          </>
        ) : (
          <Table
            dataSource={sortedData}
            loading={loading}
            columns={columns}
            rowKey={(record: any) => record.id}
            bordered
            className={styles['modalMode-table']}
            pagination={pagination}
            onChange={handleTableChange}
          />
        )
      ) : (
        <>
          {type === 'info' ? (
            <Flex align="center" style={{width: '352px'}}>
              <ExclamationCircleOutlined className={classNames('mr-2', styles.warningIcon)} />
              <Typography.Text>Не найдено ни одной версии</Typography.Text>
            </Flex>
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={'Не найдено ни одной версии'} />
          )}
          {setShowVersionModal && (
            <div className="w-100 d-flex justify-content-end">
              <Button className="" type="primary" onClick={() => setShowVersionModal && setShowVersionModal(false)}>
                Назад
              </Button>
            </div>
          )}
        </>
      )}
      <Modal
        open={modalVisible}
        onCancel={closeModal}
        destroyOnClose
        width={800}
        footer={null}
        title="Восстановление записи"
      >
        {(versionModal && versionModal(versionId)) || (
          <RecordRedactor
            recordUuid={recordUuid || recordUuidLocal}
            catalogUuid={catalogUuid}
            versionId={versionId || true}
            type={type}
            versionKey={versionKey}
            onRestore={onRestore}
            closeModal={closeModal}
            onRestoreCallback={onRestoreCallback}
          />
        )}
      </Modal>
    </>
  );
};

export default React.memo(Versions);
