import {Breadcrumb, ItemType} from 'ui-kit';
import {ReactElement, useMemo} from 'react';
import {Link} from 'react-router-dom';
import styles from './PageHeader.module.scss';

import {useBreadCrumbs} from 'hooks/navigation/useBreadCrumbs';

export const BreadCrumbsBlock = (): ReactElement => {
  const breadcrumbsFromHook = useBreadCrumbs();

  const breadcrumbs: ItemType[] = useMemo(() => {
    let breadcrumbs = breadcrumbsFromHook.map((item) => ({
      title: item.title,
      href: item?.navLink,
      path: item?.navLink,
      key: item?.id,
    })) as ItemType[];
    breadcrumbs.pop();

    breadcrumbs = breadcrumbs.filter((item) => item.title);

    return breadcrumbs;
  }, [breadcrumbsFromHook]);

  const renderBreadCrumb = (breadcrumb: ItemType) => {
    if (!breadcrumb.href) {
      return breadcrumb.title;
    }
    return (
      <Link to={breadcrumb.href} className={styles.breadcrumb}>
        {breadcrumb.title}
      </Link>
    );
  };
  if (!breadcrumbs) return <></>;

  return <Breadcrumb items={breadcrumbs} itemRender={renderBreadCrumb}></Breadcrumb>;
};
