import React, {useEffect, useState, useMemo} from 'react';
import {Alert, Button, Card, Tabs, Modal} from 'ui-kit';
import {fetchFunc} from '../../Utils/security/http/mdm';
import RecordReader from '../../components/RecordRedactor/RecordReader';
import RecordEditor from '../../components/RecordRedactor/RecordEditor';
import {backendDateFormat, normalizeValues} from '../../components/Helpers/Utils';
import CatalogsOrgChecks from '../CatalogsOrg/CatalogsOrgChecks';
import CatalogsOrgDocs from '../CatalogsOrg/CatalogsOrgDocs';
import CatalogsOrgDeduplicationDocs from '../CatalogsOrg/CatalogsOrgDeduplicationDocs';
import {structureOrgMainResident, structureOrgContactsResident} from '../CatalogsOrg/OrgStructure/orgResident';
import {structureOrgMainNoResident} from '../CatalogsOrg/OrgStructure/orgNoResident';
import {structureIpMainResident} from '../CatalogsOrg/OrgStructure/ipResident';
import {structureIpMainNoResident} from '../CatalogsOrg/OrgStructure/ipNoResident';
import {structureBranch, structureRating, structureExport, structureLicense} from '../CatalogsOrg/OrgStructure/branch';
import VersionsByDateForm from '../../components/RecordRedactor/VersionByDateForm';
import Versions from '../../components/Verisons';
import FormInitiator from '../../components/RecordRedactor/FormInitator';
import {useHistory, useRouteMatch} from 'react-router';
import {concat, get, has} from 'lodash';
import {onError} from '../../components/Helpers/Utils';
import SystemInformation from '../../components/SystemInformation';
import CatalogCrosLinks from '../CatalogsOrg/CatalogCrosLinks';
import {
  documentRegistryColumns,
  registryFilterFields,
  documentFilterFields,
  documentColumns,
  serviceColumns,
  serviceFilterFields,
} from '../CatalogsOrg/OrgStructure/orgDocument';
import {useDispatch, useSelector} from 'react-redux';
import {metaUnifier} from '../../components/Helpers/RuleConfigurator';
import {getVerifyCatalogRule} from '../../components/Helpers/Extractors';
import {orgAdditionalFields, orgOptions} from '../CatalogsOrg/OrgMeta/meta';
import {structureTypeOfActivity} from '../CatalogsOrg/OrgStructure/typeOfActivity';
import {structureBank} from '../CatalogsOrg/OrgStructure/bank';
import {errorModalCreate, infoModalCreate, showDeleteConfirm} from '../../components/Helpers/Modals';
import {cpeStructure} from '../CatalogsOrg/OrgStructure/cpeStructure';
import {setVerify} from '../../redux/actions/actions';
import {structureElementManipulations} from '../../components/Helpers/StructureManipulations';

const {TabPane} = Tabs;

const VerifyOrgCard = (props) => {
  const {REACT_APP_ECM_FRONT_URL} = window._env;
  const [loading, setLoading] = useState(false);
  const [orgData, setData] = useState(null);
  const [recordUuid, setRecordUuid] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [showVersionModal, setShowVersionModal] = useState(false);
  const [versionDate, setVersionDate] = useState(null);
  const [includeDeprecatedHistory, setIncludeDeprecatedHistory] = useState(false);
  const [hiddenFields, setHiddenFields] = useState([]);
  const [fields, setFields] = useState([]);
  const [cpeLoading, setCpeLoading] = useState(false);
  const [cpeModalVisible, setCpeModalVisible] = useState(false);
  const [cpeData, setCpeData] = useState(null);
  const [cpeMeta, setCpeMeta] = useState(null);
  const [deduplicationModalVisible, setDeduplicationModalVisible] = useState(false);
  const [graph, setGraph] = useState(null);

  const [organisationStructure, setOrganisationStructure] = useState({
    generalInformation: [],
    contactsInformation: [],
    activityType: [],
    branch: [],
    bank: [],
    license: [],
    exportStructure: [],
    rating: [],
  });
  const [tabKey, setTabKey] = useState('1');

  const match = useRouteMatch();
  const history = useHistory();

  const detailName = useSelector((state) => state.detail.changedDetail);
  const changedField = useSelector((state) => state.detail.changedField);
  const changedMode = useSelector((state) => state.detail.mode);
  const dispatch = useDispatch();

  useEffect(() => {
    const newData = {...orgData};
    let editedDetail = newData[detailName];
    if (editedDetail) {
      let index = editedDetail.findIndex((elem) => elem.uuid === changedField.uuid);
      switch (changedMode) {
        case 'edit':
          editedDetail[index] = changedField;
          break;
        case 'create':
          editedDetail.push(changedField);
          break;
        case 'delete':
          editedDetail.splice(index, 1);
          break;
        default:
      }
    }
    setData(newData);
  }, [changedField, detailName, changedMode]);

  useEffect(() => {
    setRecordUuid(match.params && match.params.id);
  }, [match]);

  useEffect(() => {
    setLoading(true);
    recordUuid && getOrgInfo(recordUuid);
    recordUuid && getGraph(recordUuid);
  }, [recordUuid]);

  useEffect(() => {
    getOrgFields();
  }, []);

  useEffect(() => {
    if (fields.length && orgData) {
      setupOrgStructure(fields, orgData);
      setLoading(false);
    }
  }, [fields, orgData]);

  const getOrgInfo = async (uuid) => {
    let url = `/api/v1/catalogs/org/items/${uuid}/unverified?showDetails=1`;
    if (props.versionId) {
      url = `/api/v1/catalogs/org/items/${uuid}/history/${props.versionId}?showDetails=1`;
    }
    try {
      const data = await fetchFunc({
        url,
        method: 'get',
      });
      setLoading(false);
      const verification = await getVerifyCatalogRule('org');
      setData({
        ...data,
        verification,
      });
      dispatch(setVerify(data.sysVerifyInfo.result));
    } catch (error) {
      setLoading(false);
    }
  };

  const getOrgFields = async () => {
    try {
      const fetchData = await fetchFunc({
        url: `/api/v1/catalogs/org/fields?size=1000`,
        method: 'get',
      });
      setFields(fetchData.content);
    } catch (error) {
      setLoading(false);
    }
  };

  const changeOrgInfo = async (values) => {
    try {
      const fetchData = await fetchFunc(
        {
          url: `/api/v1/catalogs/org/items/${recordUuid}/unverified?showDetails=1`,
          method: 'put',
          data: {
            fields: values,
          },
        },
        onError
      );
      setData(fetchData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const updateRecord = async (updateType, skipVerification = false) => {
    try {
      const respData = await fetchFunc({
        url: `/api/v1/catalogs/org/items/${recordUuid}/unverified/${updateType}`,
        method: 'post',
      });
      if (respData) {
        const {
          sysVerifyInfo: {status},
        } = respData;
        switch (status) {
          case 'verification': {
            if (skipVerification) await updateRecord('approve');
            else await getOrgInfo(respData.uuid);
            break;
          }
          case 'approved': {
            history.push(`/organisations/${respData.uuid}`);
            break;
          }
          default: {
            await getOrgInfo(respData.uuid);
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const changeRecord = (values) => {
    // orgMeta.forEach(item => {
    //    if (item.fieldType && item.fieldType.id === 8) {
    //        if (has(values, item.origin)) {
    //            values[item.origin] = convertDate(values[item.origin])
    //        }
    //    }
    // });
    setLoading(true);
    setEditMode(false);
    changeOrgInfo(normalizeValues({values, fields}));
  };

  const deleteRecord = async () => {
    try {
      await fetchFunc(
        {
          url: `/api/v1/catalogs/org/items/${recordUuid}/unverified`,
          method: 'delete',
        },
        onError
      );
      setLoading(false);
      history.push(`/organisations`);
    } catch (error) {
      setLoading(false);
    }
  };

  const getOrgFromCpe = async () => {
    try {
      const data = await fetchFunc(
        {
          url: `/api/v1/org-from-cpe/${recordUuid}`,
          params: {
            showDetails: 1,
          },
        },
        onCpeError,
        'org'
      );
      const orgFields = concat(orgAdditionalFields, fields);
      const cpeMeta = metaUnifier(orgFields, orgOptions);
      cpeMeta.forEach((item) => {
        if (item.fieldType.id === 12) {
          item.fieldType.id = 112;
        }
      });
      setCpeMeta(cpeMeta);
      setCpeData(data);
      setCpeModalVisible(true);
      setCpeLoading(false);
    } catch (error) {
      setCpeLoading(false);
    }
  };

  const getGraph = async () => {
    try {
      const fetchData = await fetchFunc(
        {
          url: `/api/v1/deduplication/graphs/unverifiedGoldCard/${recordUuid}`,
        },
        onGraphError
      );
      setGraph(fetchData);
    } catch (error) {}
  };

  const onGraphError = (error) => {
    if (error.status === 404) {
      return false;
    } else {
      errorModalCreate(error.data.message);
    }
  };

  const deduplicationCreateLinks = async (values) => {
    const {ignoreUserLinks, ignoreExistLinks, processOnlyNew, userConfirmReq, orgType, dataSource, orgs} = values;

    const fetchString = `dataSource=${dataSource}&ignoreUserLinks=${ignoreUserLinks}
        &ignoreExistLinks=${ignoreExistLinks}&processOnlyNew=${processOnlyNew}&userConfirmReq=${userConfirmReq}
        &orgType=${orgType}&orgs=${orgs}`;
    try {
      const fetchData = await fetchFunc(
        {
          url: `/api/v1/deduplication/links/create?${fetchString}`,
          method: 'post',
        },
        onError
      );
      history.push(`/deduplication/protocols/${fetchData.uuid}`);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const onCpeError = (error) => {
    if (error.status === 404) {
      infoModalCreate({title: 'Сведения отсутствуют'});
    } else errorModalCreate(error.message);
  };

  const findVersionsByDate = (values) => {
    setVersionDate(values.date && values.date.format(backendDateFormat));
    setIncludeDeprecatedHistory(values.includeDeprecatedHistory);
    setShowVersionModal(true);
  };

  const renderEditButtons = () => {
    return (
      <div>
        {editMode ? (
          <Button onClick={() => setEditMode(false)} className="ml-1 ml-auto">
            Отменить
          </Button>
        ) : (
          <Button type="primary" className="ml-auto" onClick={() => setEditMode(true)}>
            Изменить
          </Button>
        )}
        {orgData &&
          orgData.sysVerifyInfo &&
          (orgData.sysVerifyInfo.status === 'draft' || orgData.sysVerifyInfo.status === 'rejected') && (
            <Button
              type="danger"
              onClick={() =>
                showDeleteConfirm({
                  content: 'Вы действительно хотите удалить запись справочника?',
                  onOk: () => deleteRecord(),
                })
              }
              className="ml-1"
            >
              Удалить
            </Button>
          )}
      </div>
    );
  };

  const setupOrgStructure = (fields, {recType, nonResidentRf}) => {
    let orgStructure = setupStaticStructure(recType, nonResidentRf);
    setupCustomStructure(orgStructure, orgMeta);
  };

  const setupCustomStructure = (staticStructure, orgMeta) => {
    let newOrgStructure = staticStructure;
    structureElementManipulations(orgMeta, newOrgStructure, orgData);
    setOrganisationStructure(newOrgStructure);
  };

  const setupStaticStructure = (recType, nonResidentRf) => {
    let staticStructure = {};

    if (recType === 'comm_org') {
      staticStructure.generalInformation = nonResidentRf ? structureOrgMainNoResident : structureOrgMainResident;
    } else {
      staticStructure.generalInformation = nonResidentRf ? structureIpMainNoResident : structureIpMainResident;
    }

    staticStructure.contactsInformation = structureOrgContactsResident;
    staticStructure.activityType = structureTypeOfActivity;
    staticStructure.branch = structureBranch;
    staticStructure.bank = structureBank;
    staticStructure.license = structureLicense;
    staticStructure.exportStructure = structureExport;
    staticStructure.rating = structureRating;

    return staticStructure;
  };

  const renderViews = (meta, structure, editMode, store, form, data = orgData) => {
    return !editMode ? (
      <RecordReader
        data={data}
        recordUuid={recordUuid}
        referenceOrigin={store}
        fieldsMeta={meta}
        structure={structure}
        isUnverified
      />
    ) : (
      <RecordEditor
        form={form}
        data={data}
        recordUuid={recordUuid}
        referenceOrigin={store}
        fieldsMeta={meta}
        structure={structure}
        setHiddenFields={setHiddenFields}
        hiddenFields={hiddenFields}
        changeOrgData={setData}
        isUnverified
      />
    );
  };

  const orgMeta = useMemo(() => {
    const orgFields = concat(orgAdditionalFields, fields);
    return metaUnifier(orgFields, orgOptions);
  }, [fields, orgAdditionalFields]);

  const {generalInformation, contactsInformation, activityType, branch, bank, license, exportStructure, rating} =
    organisationStructure;

  const tabsForRender = (form) => {
    return (
      <Tabs className={'org-list__tabs'} activeKey={tabKey} onChange={setTabKey}>
        <TabPane forceRender={true} tab="Общая информация" key="1">
          {renderViews(orgMeta, generalInformation, editMode, 'org', form)}
        </TabPane>
        <TabPane forceRender={true} tab="Контактная информация" key="2">
          {renderViews(orgMeta, contactsInformation, editMode, 'org', form)}
        </TabPane>
        <TabPane forceRender={true} tab="Виды деятельности" key="3">
          {renderViews(orgMeta, activityType, editMode, 'org', form)}
        </TabPane>
        <TabPane tab="Проверки" key="4">
          <CatalogsOrgChecks origin="kyc_check_results" recordUuid={recordUuid} />
        </TabPane>
        <TabPane tab="Документы" key="5">
          <CatalogsOrgDocs
            recordUuid={recordUuid}
            header={
              <Button
                type="primary"
                className="mb-2"
                onClick={() => window.open(`${REACT_APP_ECM_FRONT_URL}/document/create?orgId=${recordUuid}`, '_blank')}
              >
                Создать документ
              </Button>
            }
            columns={documentColumns}
            filterFields={documentFilterFields}
            store={'document'}
          />
        </TabPane>
        <TabPane tab="Реестр документов" key="6">
          <CatalogsOrgDocs
            recordUuid={recordUuid}
            columns={documentRegistryColumns}
            filterFields={registryFilterFields}
            store={'registry'}
          />
        </TabPane>
        <TabPane tab="Структура компании и аффилированные лица" key="7">
          <CatalogCrosLinks recordUuid={recordUuid} />
        </TabPane>
        <TabPane tab="Филиалы" key="8">
          {renderViews(orgMeta, branch, editMode, 'org', form)}
        </TabPane>
        {orgData.bank && (
          <TabPane tab="Банк" key="9">
            {renderViews(orgMeta, bank, editMode, 'org', form)}
          </TabPane>
        )}
        <TabPane tab="Лицензии" key="10">
          {renderViews(orgMeta, license, editMode, 'org', form)}
        </TabPane>
        <TabPane tab="Экспортная деятельность" key="11">
          {renderViews(orgMeta, exportStructure, editMode, 'org', form)}
        </TabPane>
        <TabPane tab="Рейтинги и лимиты" key="12">
          {renderViews(orgMeta, rating, editMode, 'org', form)}
        </TabPane>
        <TabPane tab="Активные услуги" key="13">
          <CatalogsOrgDocs
            recordUuid={recordUuid}
            columns={serviceColumns}
            filterFields={serviceFilterFields}
            store={'services'}
          />
        </TabPane>
        {graph && (
          <TabPane tab="Дедублицированные документы ECM" key="14">
            <CatalogsOrgDeduplicationDocs recordUuid={recordUuid} graph={graph} />
          </TabPane>
        )}
      </Tabs>
    );
  };

  return (
    <Card className="org-list" loading={loading} extra={!props.readOnly && renderEditButtons()}>
      {!props.readOnly && <VersionsByDateForm onFinish={findVersionsByDate} depecated />}
      {orgData && (
        <SystemInformation
          data={orgData}
          validateCatalog={true}
          referenceOriginLocal={'organisations'}
          recordUuid={recordUuid}
        />
      )}
      {get(orgData, 'sysVerifyInfo.remark') && (
        <Alert
          message="Замечания по результатам верификации"
          description={get(orgData, 'sysVerifyInfo.remark')}
          type="error"
        />
      )}
      {orgData && (
        <Button
          type="primary"
          className="mt-3"
          loading={cpeLoading}
          onClick={() => {
            setCpeLoading(true);
            getOrgFromCpe();
          }}
        >
          {orgData.nonResidentRf ? 'Сведения из ЦПП' : 'Сведения из ФНС'}
        </Button>
      )}
      <Button
        type="primary"
        className="mt-3 ml-3"
        onClick={() => {
          setDeduplicationModalVisible(true);
        }}
      >
        Запустить дедубликацию
      </Button>
      <a href={`/deduplication/protocols?goldCard=${recordUuid}`} target={'_blank'} className="button mt-3 ml-3">
        Протоколы дедубликации
      </a>
      <div className="d-flex mb-3">
        {(get(orgData, 'sysVerifyInfo.status') === 'draft' || get(orgData, 'sysVerifyInfo.status') === 'rejected') &&
          orgData.verification && (
            <>
              {!editMode && (
                <Button
                  type="primary"
                  className="ml-auto mr-3"
                  onClick={async () => {
                    setLoading(true);
                    await updateRecord('verify', true);
                  }}
                >
                  Пропустить верификацию
                </Button>
              )}
            </>
          )}
        {(get(orgData, 'sysVerifyInfo.status') === 'draft' || get(orgData, 'sysVerifyInfo.status') === 'rejected') && (
          <>
            {!editMode && (
              <Button
                type="primary"
                onClick={() => {
                  setLoading(true);
                  updateRecord('verify');
                }}
              >
                На верификацию
              </Button>
            )}
          </>
        )}
        {get(orgData, 'sysVerifyInfo.status') === 'verification' && (
          <Button type="danger" className="ml-auto" onClick={() => updateRecord('reject')}>
            Вернуть для уточнения
          </Button>
        )}
      </div>
      {orgData && fields ? (
        !editMode ? (
          tabsForRender()
        ) : (
          <FormInitiator
            formName={'catalog-orgs-form'}
            hiddenFields={hiddenFields}
            fieldsMeta={orgMeta}
            data={orgData}
            changeRecord={changeRecord}
            fixedSubmitButton
            submitButtonPosition={{
              bottom: '50px',
              right: '64px',
            }}
            children={(form) => tabsForRender(form)}
          />
        )
      ) : (
        ''
      )}
      <Modal
        title={'Просмотр версий на дату'}
        open={showVersionModal}
        footer={null}
        onCancel={() => setShowVersionModal(false)}
        destroyOnClose={true}
        width={800}
      >
        <Versions
          catalogUuid="org"
          recordUuid={recordUuid}
          location={props.location}
          history={props.history}
          versionDate={versionDate}
          includeDeprecatedHistory={includeDeprecatedHistory}
          versionModal={(versionId) => <VerifyOrgCard versionId={versionId} readOnly />}
        />
      </Modal>
      <Modal
        title={orgData && orgData.nonResidentRf ? 'Сведения из ЦПП' : 'Сведения из ФНС'}
        open={cpeModalVisible}
        onCancel={() => setCpeModalVisible(false)}
        width={1100}
        footer={null}
      >
        {renderViews(cpeMeta, cpeStructure, false, 'org', null, cpeData)}
      </Modal>
    </Card>
  );
};

export default VerifyOrgCard;
