import React, {useState, useEffect} from 'react';
import {Table, Button, Card, Modal} from 'ui-kit';
import {get} from 'lodash';
import {CatalogsExtractor} from 'mdm-lib';
import security from '../../../../Utils/security';
import {convertDate, optionsExtractor} from '../../../../components/Helpers/Utils';
import {fileDownloadBtn} from '../../../../components/Helpers/FileDownloader';
import {getCatalogFields} from '../../../../components/Helpers/Extractors';
const {getToken} = security;

const ProductOpinions = ({serviceId}) => {
  const columns = [
    {
      title: 'Дата начала сбора мнений',
      dataIndex: 'startDate',
      key: 'startDate',
      render: (text) => text && convertDate(text),
    },
    {
      title: 'Дата окончания сбора мнений',
      dataIndex: 'endDate',
      key: 'endDate',
      render: (text) => text && convertDate(text),
    },
    {
      title: 'Причина сбора мнений',
      dataIndex: 'opinionsReason',
      key: 'opinionsReason',
      render: (text) => optionsExtractor(text, fields, 'opinionsReason'),
    },
  ];

  const opinionsColumns = [
    {
      title: 'Сотрудник',
      key: 'userId',
      dataIndex: ['userId', 'uuid'],
      render: (text) => {
        return (
          <CatalogsExtractor
            token={getToken()}
            catalog={'user_profile'}
            uuid={text}
            render={(value) =>
              value && (
                <a target="_blank" href={`/catalog/user_profile/${value.uuid}`}>
                  {`${value && value.lastName} ${value && value.firstName} ${value && value.middleName}`}
                </a>
              )
            }
          />
        );
      },
    },
    {
      title: 'Голос',
      dataIndex: 'vote',
      render: (text) => {
        return (
          <CatalogsExtractor
            token={getToken()}
            customUrl={'/api/v1/catalogs/service_opinions/detail/opinions/fields'}
            pathToField="content"
            render={(value) => value && optionsExtractor(text, value, 'vote')}
          />
        );
      },
    },
    {
      title: 'Мнение',
      dataIndex: 'opinion',
      key: 'opinion',
    },
    {
      title: 'Дата',
      dataIndex: 'opinionDate',
      key: 'opinionDate',
      render: (text) => convertDate(text),
    },
    {
      title: 'Файл с комментариями',
      dataIndex: 'file',
      render: (text) =>
        text ? fileDownloadBtn({catalogIdentifier: 'service_opinions', uuid: modalData?.uuid, fileUuid: text}) : '',
    },
    {
      title: 'Роль подразделения',
      dataIndex: 'subdivisionRole',
      key: 'subdivisionRole',
    },
    {
      title: 'Подразделение',
      key: 'staffStructId',
      dataIndex: ['staffStructId', 'uuid'],
      render: (text) => {
        return (
          <CatalogsExtractor
            token={getToken()}
            catalog={'staff_struct'}
            uuid={text}
            render={(value) =>
              value && (
                <a target="_blank" href={`/catalog/staff_struct/${value.uuid}`}>
                  {`${value && value.caption}`}
                </a>
              )
            }
          />
        );
      },
    },
  ];

  const [visible, setVisible] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const [fields, setFields] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      setFields(await getCatalogFields('service_opinions'));
    };
    fetch();
  }, []);

  const handleTableChange = ({current, pageSize}) => {
    setPage(current - 1);
    setSize(pageSize);
  };

  return (
    <>
      <CatalogsExtractor
        token={getToken()}
        catalog={'service_opinions'}
        params={{
          size,
          page,
          showDetails: 1,
        }}
        filter={{
          serviceId: serviceId,
        }}
        render={(value) => {
          const data = get(value, 'content', null);
          return (
            data && (
              <Table
                bordered
                dataSource={data}
                columns={columns}
                rowKey={(record) => record.uuid}
                onRow={(record, rowIndex) => {
                  return {
                    onDoubleClick: () => {
                      setModalData(record);
                      setVisible(true);
                    }, // double click row
                  };
                }}
                pagination={{
                  showSizeChanger: false,
                  current: page + 1,
                }}
                onChange={handleTableChange}
              />
            )
          );
        }}
      />
      <Modal open={visible} onCancel={() => setVisible(false)} onOk={() => setVisible(false)} width={1200}>
        {modalData && (
          <div className="p-3">
            <div className="d-flex mb-3">
              <div className="mr-2 font-weight-bold">Дата начала сбора мнений:</div>
              <div>{modalData.startDate && convertDate(modalData.startDate)}</div>
            </div>
            <div className="d-flex mb-3">
              <div className="mr-2 font-weight-bold">Дата окончания сбора мнений:</div>
              <div>{modalData.endDate && convertDate(modalData.endDate)}</div>
            </div>
            <div className="d-flex mb-3">
              <div className="mr-2 font-weight-bold">Причина сбора мнений:</div>
              <div>{optionsExtractor(modalData.opinionsReason, fields, 'opinionsReason')}</div>
            </div>
            <div className="mb-3">
              <div className="mb-2 font-weight-bold">Мнения:</div>
              <div>
                <Table
                  bordered
                  dataSource={modalData && modalData.opinions}
                  columns={opinionsColumns}
                  rowKey={(record) => record.uuid}
                  onChange={handleTableChange}
                  pagination={false}
                />
              </div>
            </div>
          </div>
        )}
      </Modal>
    </>
  );
};

export default ProductOpinions;
