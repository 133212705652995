import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router';
import {isEmpty} from 'lodash';
import {useDispatch, useSelector} from 'react-redux';
import {Button, Modal, Spin, Switch, Table} from 'ui-kit';

import {
  deleteNotification,
  getNotificationId,
  getNotificationList,
  getNotificationSubscriptionList,
  setActivateNotification,
  setDeactivateNotification,
} from '../../../redux/thunk/notificationThunk';
import {
  captionCatalogNameFilter,
  captionNotificationFilter,
  captionTemplateNotificationFilter,
} from '../helpers/notificationFiltersUtils';
import {notificationPage} from '../helpers/constant';
import {StyledTable} from '../../../components/Themes/Components';
import {notificationPageFilters} from '../helpers/notificationPageFilters';
import NotificationPageForm from '../NotificationPageForm/NotificationPageForm';
import {notifications} from '../../../components/NotificationPage/helpers/constant';
import NotificationPageFilters from '../NotificationPageFilters/NotificationPageFilters';
import {ReactComponent as DeleteDeduplication} from '../../../assets/images/delete.svg';

import styles from './NotificationPageList.module.scss';
import {DeleteOutlined, PlusOutlined} from '@ant-design/icons';

/** Список уведомлений */
const NotificationPageList = () => {
  /** Маршрутизатор */
  const history = useHistory();
  /** Отправка данных в хранилище */
  const dispatch = useDispatch();
  /** Состояние отображения модального окна */
  const [notificationModalVisible, setNotificationModalVisible] = useState(false);
  /** Начальное состояние фильтра */
  const [filter, setFilter] = useState({
    title: null,
    template: null,
    subscription: null,
    titleTemplate: null,
    methodTemplate: null,
  });
  /** Начальное состояние статуса для отображения формы уведомления */
  const [status, setStatus] = useState('');
  /** Используем хранилище */
  const notificationId = useSelector((state) => state?.notification.notificationId);
  const notificationList = useSelector((state) => state?.notification.notificationList);
  const notificationSubscription = useSelector((state) => state?.notification.notificationSubscriptionList);
  const loading = useSelector((state) => state?.notification.loading);
  /** Получаем список уведомлений */
  useEffect(() => {
    dispatch(getNotificationList());
  }, [dispatch, notificationSubscription]);
  /** Получаем информацию по подпискам и событиям */
  useEffect(() => {
    dispatch(getNotificationSubscriptionList());
  }, [dispatch]);
  /**
   *Сеттер значения переключателя уведомления
   * @param record - значение переключателя и id уведомления
   * */
  const handleActiveChange = (record) => {
    record.active === false
      ? dispatch(setActivateNotification(record.id))
      : dispatch(setDeactivateNotification(record.id));
  };
  /** Функция перехода к форме просмотра/редактирования шаблона уведомления */
  const notificationInfoHandler = (uuid) => {
    dispatch(getNotificationId(uuid));
    !isEmpty(notificationSubscription) && setNotificationModalVisible(true);
    setStatus('viewing');
  };
  /** Функция для отображения отфильтрованной таблицы  */
  const filteringNotificationList = notificationPageFilters(notificationList, filter);
  /** Колонки для таблицы */
  const baseColumnWidth = '33%';
  const switchColumnWidth = '44px';
  const deleteColumnWidth = '40px';
  const columns = [
    {
      title: notificationPage.tableName,
      dataIndex: 'captionNotification',
      key: 1,
      width: baseColumnWidth,
      render: (text, record) => (
        <p
          style={{cursor: 'pointer'}}
          onDoubleClick={() => {
            notificationInfoHandler(record.id);
          }}
        >
          {text}
        </p>
      ),
    },
    {
      title: notificationPage.template,
      dataIndex: 'nameTemplateNotification',
      key: 2,
      width: baseColumnWidth,
      render: (text) => <p>{text}</p>,
    },
    {
      title: notificationPage.subscription,
      dataIndex: 'catalogName',
      key: 3,
      width: baseColumnWidth,
      render: (text) => <p>{text}</p>,
    },
    {
      title: '',
      dataIndex: '',
      key: 4,
      width: switchColumnWidth,
      className: 'pl-0 pr-0',
      render: (record) => (
        <div className="d-flex justify-content-center ">
          <Switch
            key={notifications.email}
            checked={record.active}
            size="small"
            onChange={() => handleActiveChange(record)}
          />
        </div>
      ),
    },
    {
      title: '',
      dataIndex: '',
      key: 5,
      width: deleteColumnWidth,
      className: 'pl-0 pr-0',
      render: (record) => (
        <div className="d-flex justify-content-center ">
          <DeleteOutlined onClick={() => dispatch(deleteNotification(record.id))} />
        </div>
      ),
    },
  ];
  return (
    <>
      {loading === true ? (
        <div className="containerWhite">
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              setStatus('create');
              setNotificationModalVisible(true);
            }}
          >
            {notificationPage.addNotification}
          </Button>

          <>
            <NotificationPageFilters
              notification={true}
              captionNotificationFilter={captionNotificationFilter(notificationList)}
              captionTemplateNotificationFilter={captionTemplateNotificationFilter(notificationList)}
              captionCatalogNameFilter={captionCatalogNameFilter(notificationList)}
              setFilter={setFilter}
            />
            <Table
              className="layout-fixed"
              columns={columns}
              dataSource={filteringNotificationList}
              rowKey={(record) => record.id}
            />
          </>

          <Modal
            closable={false}
            open={notificationModalVisible}
            footer={null}
            okText={notifications.save}
            cancelText={notifications.cancel}
            loading={true}
            width={700}
          >
            <NotificationPageForm
              notificationId={!isEmpty(notificationId) && notificationId}
              status={status}
              setNotificationModalVisible={setNotificationModalVisible}
            />
          </Modal>
        </div>
      ) : (
        <Spin />
      )}
    </>
  );
};
export default React.memo(NotificationPageList);
