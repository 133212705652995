import {PageHeaderState} from 'redux/types/rootState';
import {
  SET_PAGE_HEADER,
  SET_PAGE_HEADER_BUTTONS,
  SET_PAGE_HEADER_TABS,
  SET_PAGE_HEADER_ACTIVE_TAB,
  SET_PAGE_HEADER_TAG,
  SET_PAGE_HEADER_LINES_BLOCK,
  SET_PAGE_HEADER_BACK,
  SET_PAGE_HEADER_SHOW_DEFAULT_BACK_BUTTON,
  SET_PAGE_HEADER_BREADCRUMBS_TITLE,
  SET_PAGE_HEADER_LOADING,
} from '../actions/actionTypes';

const initialState: PageHeaderState = {};

export const pageHeaderReducer = (state: PageHeaderState = initialState, action: any): PageHeaderState => {
  switch (action.type) {
    case SET_PAGE_HEADER:
      const newState = action.payload;

      return {
        ...state,
        ...newState,
      };

    case SET_PAGE_HEADER_LOADING:
      return {
        ...state,
        loading: {
          status: action.payload,
          path: window.location.pathname,
        },
      };
    case SET_PAGE_HEADER_BUTTONS:
      return {
        ...state,
        buttonsProps: {
          items: action.payload,
          path: window.location.pathname,
        },
      };
    case SET_PAGE_HEADER_BACK:
      return {
        ...state,
        backButton: {
          to: action.payload,
          path: window.location.pathname,
        },
      };

    case SET_PAGE_HEADER_SHOW_DEFAULT_BACK_BUTTON:
      return {
        ...state,
        showDefaultBackButton: {
          show: action.payload,
          path: window.location.pathname,
        },
      };

    case SET_PAGE_HEADER_TABS:
      return {
        ...state,
        tabsProps: {
          props: action.payload,
          path: window.location.pathname,
        },
      };
    case SET_PAGE_HEADER_ACTIVE_TAB:
      return {
        ...state,
        tabsProps: {
          ...state.tabsProps,
          props: {
            ...state?.tabsProps?.props,
            activeTab: action.payload,
          },
        },
      };
    case SET_PAGE_HEADER_TAG:
      return {
        ...state,
        tagProps: {
          props: action.payload,
          path: window.location.pathname,
        },
      };
    case SET_PAGE_HEADER_LINES_BLOCK:
      return {
        ...state,
        linesBlockProps: {
          items: action.payload,
          path: window.location.pathname,
        },
      };
    case SET_PAGE_HEADER_BREADCRUMBS_TITLE:
      return {
        ...state,
        breadcrumbsTitle: {
          title: action.payload,
          path: window.location.pathname,
        },
      };
    default:
      return state;
  }
};
