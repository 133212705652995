import {Select} from 'ui-kit';
import styles from '../EditFieldForm.module.scss';
import React from 'react';
import {DefaultValue, FieldOrder, ComboboxOptions} from '../helpers/FormComponents';
import {requiredRule} from '../helpers/constants';

interface Props {
  isDisabled: boolean;
  validator: any;
  comboboxData: any;
  mapOption: (value: any) => JSX.Element;
  setModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const MultipleValueListFieldForm = ({isDisabled, comboboxData, mapOption, setModalVisible}: Props) => {
  return (
    <>
      <div className={styles.twoColumns}>
        <FieldOrder />
        <DefaultValue extra="Укажите значение по умолчанию">
          <Select mode="multiple" disabled={isDisabled}>
            {mapOption(comboboxData)}
          </Select>
        </DefaultValue>
      </div>
      <ComboboxOptions
        rules={requiredRule}
        onClick={() => setModalVisible(true)}
        label={comboboxData && comboboxData.length >= 1 ? 'Изменить' : 'Добавить'}
      />
    </>
  );
};

export default React.memo(MultipleValueListFieldForm);
