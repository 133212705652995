import {get} from 'lodash';
import {confirmModalCreate} from '../../../components/Helpers/Modals';

export const clearFieldsHandler = (setFieldsValue, initialValue, meta, fieldName) => {
  confirmModalCreate({
    title: 'Предупреждение',
    content: 'Внимание! Будут очищены скрываемые поля',
    okText: 'Принять',
    onOk: () => {
      meta.forEach((field) => {
        let visibleRule = get(field, `options.rules.visible.rule.or.${fieldName}`);
        if (visibleRule === undefined) visibleRule = get(field, `options.rules.visible.rule.and.${fieldName}`);
        if (visibleRule !== undefined) {
          setFieldsValue({
            [field.origin]: null,
          });
        }
      });
    },
    cancelText: 'Отмена',
    onCancel: () => {
      setFieldsValue({
        [fieldName]: initialValue,
      });
    },
  });
};
