import React, {useEffect, useMemo, useState} from 'react';
import {useDispatch} from 'react-redux';
import {isEmpty} from 'lodash';
import {CloseOutlined, FileAddOutlined} from '@ant-design/icons';
import {Button, Form, Input, message, notification, IconType} from 'ui-kit';

import {renderTemplateToPayload} from '../helpers/utils';
import {notifications} from '../../../components/NotificationPage/helpers/constant';
import {getNotificationTemplateAC} from '../../../redux/actions/actions';
import {requiredRule} from '../../../components/EditFieldForm/helpers/constants';
import {addNotificationTemplate, editNotification} from '../../../redux/thunk/notificationThunk';
import EmailForm from './NotificationMethodForms/EmailForm';
import SmsForm from './NotificationMethodForms/SmsForm';
import HttpForm from './NotificationMethodForms/HttpForm';
import {RecordType} from 'types';
import {createPortal} from 'react-dom';
import FormContainer from './NotificationMethodForms/FormContainer';
/**
 * Форма создания уведомления
 * @param templateTypeHttp - данные для шаблона http
 * @param templateTypeEmail - данные для шаблона email
 * @param templateTypeSms - данные для шаблона sms
 * @param status - статус для отображения формы создания шаблона/редактирования формы
 * @param notificationTemplate - данные шаблона
 * @param setNotificationModalVisible - функция изменения состояния модального окна
 * @param uuid - uuid шаблона */

interface NotificationPageTemplateFormProps {
  setNotificationModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  templateTypeHttp?: RecordType;
  templateTypeEmail?: RecordType;
  templateTypeSms?: RecordType;
  status: string;
  notificationTemplate: RecordType | null;
  uuid: string;
}

const NotificationPageTemplateForm = ({
  setNotificationModalVisible,
  templateTypeHttp,
  templateTypeEmail,
  templateTypeSms,
  status,
  notificationTemplate,
  uuid,
}: NotificationPageTemplateFormProps) => {
  /** Данные общей формы для отправки запроса  */
  const [form] = Form.useForm();
  /** Отправка данных для хранилища */
  const dispatch = useDispatch();
  /** Состояние для подготовки данных sms */
  const [methodNotificationsSms, setMethodNotificationsSms] = useState<RecordType>({});
  /** Состояние для подготовки данных email */
  const [methodNotificationsEmail, setMethodNotificationsEmail] = useState<RecordType>({});
  /** Состояние для подготовки данных http */
  const [methodNotificationsHttp, setMethodNotificationsHttp] = useState<RecordType>({});

  /** Состояние для всплывающего уведомления */
  const [api, contextHolder] = notification.useNotification();
  /** Состояние для всплывающего сообщения сохранения шаблона */
  const [messageApi, contextHolderMessage] = message.useMessage();
  /** Функция настройки всплывающего уведомления при заполнении формы*/
  const openNotificationWithIconForm = (type: IconType) => {
    api[type]({
      message: notifications.attention,
      description: notifications.attentionDescriptionForm,
    });
  };
  /** Функция для отображения сообщения при сохранении шаблона */
  const success = () => {
    messageApi
      .open({
        type: 'success',
        content: notifications.changesSave,
      })
      .then();
  };
  /** Данные для отображения информации общей формы */
  const initialValues = useMemo(
    () => ({
      name:
        status === 'edit' && !isEmpty(notificationTemplate)
          ? notificationTemplate.nameNotification
          : form.getFieldValue('name'),
    }),
    [form, notificationTemplate, status]
  );

  /**  Загружаем данные формы для редактирования шаблона  */
  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues, notificationTemplate]);

  /** Информация для отправки  */
  const payloadData = useMemo(() => {
    return {
      retryCount: 0,
      active: true,
    };
  }, []);
  /** Формируем объект для payloadData */
  const createCurrentNotificationTemplate = () => {
    if (status === 'edit') {
      return {
        ...payloadData,
        nameNotification: form.getFieldValue('name'),
        methodNotificationsUpdate: renderTemplateToPayload(
          methodNotificationsSms,
          methodNotificationsHttp,
          methodNotificationsEmail
        ),
      };
    }
    return {
      ...payloadData,
      nameNotification: form.getFieldValue('name'),
      methodNotifications: renderTemplateToPayload(
        methodNotificationsSms,
        methodNotificationsHttp,
        methodNotificationsEmail
      ),
    };
  };
  /** Функция submit в случае ошибки валидации */
  const onFinishFailedForm = () => {
    if (isEmpty(methodNotificationsSms) && isEmpty(methodNotificationsEmail) && isEmpty(methodNotificationsHttp)) {
      openNotificationWithIconForm('warning');
    } else {
      createCurrentNotificationTemplate();
    }
  };

  /** Отправка данных формы */
  const submitHandler = () => {
    if (isEmpty(methodNotificationsSms) && isEmpty(methodNotificationsEmail) && isEmpty(methodNotificationsHttp)) {
      onFinishFailedForm();
    } else {
      status === 'edit'
        ? dispatch(editNotification(createCurrentNotificationTemplate(), uuid))
        : dispatch(addNotificationTemplate(createCurrentNotificationTemplate()));
      resetHandler();
    }
  };
  /** Очистка данных формы */
  const resetHandler = () => {
    form.resetFields();
    setMethodNotificationsSms({});
    setMethodNotificationsEmail({});
    setMethodNotificationsHttp({});
    setNotificationModalVisible(false);
    dispatch(getNotificationTemplateAC(null));
  };

  const [notificationContainer, setNotificationContainer] = useState<HTMLElement | null>(null);

  useEffect(() => {
    const container = document.getElementById('notification_name');
    if (container) {
      setNotificationContainer(container);
    }
  }, []);

  return (
    <div>
      <>
        {contextHolder}
        {contextHolderMessage}
      </>

      <Form form={form} layout="vertical" id="notification_name"></Form>
      <div className="mb-2">{notifications.formMethods}</div>
      <FormContainer
        setMethodNotification={setMethodNotificationsSms}
        templateType={templateTypeSms}
        status={status}
        header={notifications.sms}
        name={'sms'}
        methodNotification={methodNotificationsSms}
      >
        {({isActive, renderPopoverContent, editMethodNotificationHandler, openCollapse, setIsActive, form}) => {
          return (
            <SmsForm
              success={success}
              status={status}
              templateTypeSms={templateTypeSms}
              setIsActiveSms={setIsActive}
              formSms={form}
              openCollapseSms={openCollapse}
              isActiveSms={isActive}
              methodNotificationsSms={methodNotificationsSms}
              renderPopoverContent={renderPopoverContent}
              setMethodNotificationsSms={setMethodNotificationsSms}
              editMethodNotificationSmsHandler={editMethodNotificationHandler}
            />
          );
        }}
      </FormContainer>
      <FormContainer
        setMethodNotification={setMethodNotificationsEmail}
        templateType={templateTypeEmail}
        status={status}
        header={notifications.email}
        name={'email'}
        methodNotification={methodNotificationsEmail}
      >
        {({
          isActive,
          renderPopoverContent,
          editMethodNotificationHandler,
          openCollapse,
          setIsActive,
          setIsVisibleModalForPhone,
          setTestKey,
          form,
        }) => {
          return (
            <EmailForm
              success={success}
              status={status}
              setIsActiveEmail={setIsActive}
              setMethodNotificationsEmail={setMethodNotificationsEmail}
              templateTypeEmail={templateTypeEmail}
              formEmail={form}
              openCollapseEmail={openCollapse}
              isActiveEmail={isActive}
              renderPopoverContent={renderPopoverContent}
              setIsVisibleModalForPhone={setIsVisibleModalForPhone}
              setTestKey={setTestKey}
              editMethodNotificationsEmailHandler={editMethodNotificationHandler}
            />
          );
        }}
      </FormContainer>
      <FormContainer
        setMethodNotification={setMethodNotificationsHttp}
        templateType={templateTypeHttp}
        status={status}
        header={notifications.http}
        name={'http'}
        methodNotification={methodNotificationsHttp}
      >
        {({isActive, renderPopoverContent, editMethodNotificationHandler, openCollapse, setIsActive, form}) => {
          return (
            <HttpForm
              status={status}
              templateTypeHttp={templateTypeHttp}
              success={success}
              setIsActiveHttp={setIsActive}
              methodNotificationsHttp={methodNotificationsHttp}
              setMethodNotificationsHttp={setMethodNotificationsHttp}
              formHttp={form}
              openCollapseHttp={openCollapse}
              isActiveHttp={isActive}
              renderPopoverContent={renderPopoverContent}
              editMethodNotificationHttpHandler={editMethodNotificationHandler}
            />
          );
        }}
      </FormContainer>
      <Form
        layout={'vertical'}
        form={form}
        name="notification_form"
        scrollToFirstError
        onFinishFailed={onFinishFailedForm}
        onFinish={submitHandler}
      >
        {notificationContainer &&
          createPortal(
            <div>
              <Form.Item label={notifications.title} name="name" rules={requiredRule} className="mb-2">
                <Input placeholder={notifications.addTitle} />
              </Form.Item>
            </div>,
            document.getElementById('notification_name') as HTMLElement
          )}
        <div className="d-flex justify-content-end mt-3">
          <Button className="mr-3" htmlType="button" onClick={resetHandler} icon={<CloseOutlined />}>
            {notifications.cancel}
          </Button>
          <Button type="primary" htmlType="submit" icon={<FileAddOutlined />}>
            {status === 'edit' ? notifications.saveTemplate : notifications.addTemplate}
          </Button>
        </div>
      </Form>
    </div>
  );
};
export default React.memo(NotificationPageTemplateForm);
