import React, {useEffect, useState, useMemo} from 'react';
import {Button, Card, Tabs, Modal} from 'ui-kit';
import {fetchFunc} from '../../Utils/security/http/mdm';
import RecordReader from '../../components/RecordRedactor/RecordReader';
import RecordEditor from '../../components/RecordRedactor/RecordEditor';
import {backendDateFormat, normalizeValues} from '../../components/Helpers/Utils';
import {getEditibleRecordFlag} from '../../components/Helpers/Extractors';
import CatalogsOrgChecks from './CatalogsOrgChecks';
import CatalogsOrgDocs from './CatalogsOrgDocs';
import {structureOrgMainResident, structureOrgContactsResident} from './OrgStructure/orgResident';
import {structureOrgMainNoResident} from './OrgStructure/orgNoResident';
import {structureIpMainResident} from './OrgStructure/ipResident';
import {structureIpMainNoResident} from './OrgStructure/ipNoResident';
import {structureBranch, structureRating, structureExport, structureLicense} from './OrgStructure/branch';
import VersionsByDateForm from '../../components/RecordRedactor/VersionByDateForm';
import Versions from '../../components/Verisons';
import FormInitiator from '../../components/RecordRedactor/FormInitator';
import {useHistory, useRouteMatch} from 'react-router';
import {concat} from 'lodash';
import {onError} from '../../components/Helpers/Utils';
import SystemInformation from '../../components/SystemInformation';
import CatalogCrosLinks from './CatalogCrosLinks';
import {
  documentRegistryColumns,
  registryFilterFields,
  documentFilterFields,
  documentColumns,
  serviceColumns,
  serviceFilterFields,
} from './OrgStructure/orgDocument';
import {useSelector} from 'react-redux';
import {metaUnifier} from '../../components/Helpers/RuleConfigurator';
import {orgAdditionalFields, orgIpOptions, orgOptions} from './OrgMeta/meta';
import {structureTypeOfActivity} from './OrgStructure/typeOfActivity';
import {structureBank} from './OrgStructure/bank';
import {cpeStructure} from './OrgStructure/cpeStructure';
import {errorModalCreate, infoModalCreate, confirmModalCreate} from '../../components/Helpers/Modals';
import {structureElementManipulations} from '../../components/Helpers/StructureManipulations';

const {TabPane} = Tabs;

const CatalogsOrgCard = (props) => {
  const {REACT_APP_ECM_FRONT_URL} = window._env;
  const [loading, setLoading] = useState(false);
  const [orgData, setData] = useState(null);
  const [recordUuid, setRecordUuid] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [showVersionModal, setShowVersionModal] = useState(false);
  const [versionDate, setVersionDate] = useState(null);
  const [includeDeprecatedHistory, setIncludeDeprecatedHistory] = useState(false);
  const [hiddenFields, setHiddenFields] = useState([]);
  const [fields, setFields] = useState([]);
  const [cpeLoading, setCpeLoading] = useState(false);
  const [cpeModalVisible, setCpeModalVisible] = useState(false);
  const [cpeData, setCpeData] = useState(null);
  const [cpeMeta, setCpeMeta] = useState(null);
  const [deduplicationModalVisible, setDeduplicationModalVisible] = useState(false);
  const [organisationStructure, setOrganisationStructure] = useState({
    generalInformation: [],
    contactsInformation: [],
    activityType: [],
    branch: [],
    bank: [],
    license: [],
    exportStructure: [],
    rating: [],
  });
  const [tabKey, setTabKey] = useState('generalInformation');

  const match = useRouteMatch();
  const history = useHistory();

  const detailName = useSelector((state) => state?.detail.changedDetail);
  const changedField = useSelector((state) => state?.detail.changedField);
  const changedMode = useSelector((state) => state?.detail.mode);
  const hasDeduplicationAccess = useSelector(
    (state) => state?.menu?.accessRigths && state.menu.accessRigths.hasDeduplicationAccess
  );

  useEffect(() => {
    const newData = {...orgData};
    let editedDetail = newData[detailName];
    if (editedDetail) {
      let index = editedDetail.findIndex((elem) => elem.uuid === changedField.uuid);
      switch (changedMode) {
        case 'edit':
          editedDetail[index] = changedField;
          break;
        case 'create':
          editedDetail.push(changedField);
          break;
        case 'delete':
          editedDetail.splice(index, 1);
          break;
        default:
      }
    }
    setData(newData);
  }, [changedField, detailName, changedMode]);

  useEffect(() => {
    setRecordUuid(match.params && match.params.id);
  }, [match]);

  useEffect(() => {
    setLoading(true);
    recordUuid && getOrgInfo(recordUuid);
  }, [recordUuid]);

  useEffect(() => {
    getOrgFields();
  }, []);

  useEffect(() => {
    if (fields.length && orgData) {
      setupOrgStructure(fields, orgData);
      setLoading(false);
    }
  }, [fields, orgData]);

  const getOrgInfo = async (uuid) => {
    let url = `/api/v1/catalogs/org/items/${uuid}?showDetails=1`;
    if (props.versionId) {
      url = `/api/v1/catalogs/org/items/${uuid}/history/${props.versionId}?showDetails=1`;
    }
    try {
      const fetchData = await fetchFunc({
        url,
        method: 'get',
      });
      setData(fetchData);
      setCanEdit(await getEditibleRecordFlag('org', uuid));
    } catch (error) {
      setLoading(false);
    }
  };

  const getOrgFields = async (uuid) => {
    try {
      const fetchData = await fetchFunc({
        url: `/api/v1/catalogs/org/fields?size=1000`,
        method: 'get',
      });
      setFields(fetchData.content);
    } catch (error) {
      setLoading(false);
    }
  };

  const changeOrgInfo = async (values) => {
    try {
      const fetchData = await fetchFunc(
        {
          url: `/api/v1/catalogs/org/items/${recordUuid}?showDetails=1`,
          method: 'put',
          data: {
            fields: values,
          },
        },
        onError
      );
      if (fetchData.sysVerifyInfo && fetchData.sysVerifyInfo && fetchData.sysVerifyInfo.status !== 'approved') {
        history.push(`/catalog/organisations/${recordUuid}/verify`);
      } else {
        setData(fetchData);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const checkUnverifiedVersion = async () => {
    try {
      const fetchData = await fetchFunc(
        {
          url: `/api/v1/catalogs/org/items/${recordUuid}/unverified`,
          method: 'GET',
        },
        onUnverifiedError
      );
      if (fetchData)
        confirmModalCreate({
          title: 'Внимание',
          content:
            'Для записи справочника уже существует версия, ещё не прошедшая верификацию. Будет открыта указанная версия',
          okText: 'Продолжить',
          cancelText: 'Отмена',
          onOk: () => {
            history.push(`/catalog/organisations/${fetchData.uuid}/verify`);
          },
        });
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const changeRecord = (values) => {
    setLoading(true);
    setEditMode(false);
    if (orgData.recType === 'individual') {
      values.caption = values.description;
    }
    changeOrgInfo(normalizeValues({values, fields}));
  };

  const getOrgFromCpe = async () => {
    try {
      const data = await fetchFunc(
        {
          url: `/api/v1/org-from-cpe/${recordUuid}`,
          params: {
            showDetails: 1,
          },
        },
        onCpeError,
        'org'
      );
      const orgFields = concat(orgAdditionalFields, fields);
      const cpeMeta = metaUnifier(orgFields, orgOptions);
      cpeMeta.forEach((item) => {
        if (item.fieldType.id === 12) {
          item.fieldType.id = 112;
        }
      });
      setCpeMeta(cpeMeta);
      setCpeData(data);
      setCpeModalVisible(true);
      setCpeLoading(false);
    } catch (error) {
      setCpeLoading(false);
    }
  };

  const deduplicationCreateLinks = async (values) => {
    const {ignoreUserLinks, ignoreExistLinks, processOnlyNew, userConfirmReq, orgType, dataSource, forceVerify, orgs} =
      values;

    const fetchParams = {
      dataSource: dataSource ? `&dataSource=${dataSource}` : '',
      orgType: orgType ? `&orgType=${orgType}` : '',
      orgs: orgs ? `&orgs=${orgs}` : '',
    };

    const fetchString = `ignoreUserLinks=${ignoreUserLinks}&forceVerify=${forceVerify}
        &ignoreExistLinks=${ignoreExistLinks}&processOnlyNew=${processOnlyNew}&userConfirmReq=${userConfirmReq}
        ${fetchParams.dataSource}${fetchParams.orgType}${fetchParams.orgs}`;

    try {
      const fetchData = await fetchFunc(
        {
          url: `/api/v1/deduplication/links/create?${fetchString}`,
          method: 'post',
        },
        onError
      );
      history.push(`/deduplication/protocols/${fetchData.uuid}`);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const onCpeError = (error) => {
    if (error.status === 404) {
      infoModalCreate({title: 'Сведения отсутствуют'});
    } else errorModalCreate(error.message);
  };

  const onUnverifiedError = (error) => {
    if (error.status === 404) {
      setEditMode(true);
    } else errorModalCreate(error.message);
  };

  const findVersionsByDate = (values) => {
    setVersionDate(values.date && values.date.format(backendDateFormat));
    setIncludeDeprecatedHistory(values.includeDeprecatedHistory);
    setShowVersionModal(true);
  };

  const renderEditButtons = () => {
    return (
      <div>
        {editMode ? (
          <Button onClick={() => setEditMode(false)} className="ml-1 ml-auto">
            Отменить
          </Button>
        ) : (
          <>
            {canEdit ? (
              <Button
                type="primary"
                className="ml-auto"
                onClick={() => {
                  setLoading(true);
                  checkUnverifiedVersion();
                  //setEditMode(true)
                }}
              >
                Изменить
              </Button>
            ) : (
              ''
            )}
          </>
        )}
      </div>
    );
  };

  const setupOrgStructure = (fields, {recType, nonResidentRf}) => {
    let orgStructure = setupStaticStructure(recType, nonResidentRf);
    setupCustomStructure(orgStructure, orgMeta);
  };

  const setupCustomStructure = (staticStructure, orgMeta) => {
    let newOrgStructure = staticStructure;
    structureElementManipulations(orgMeta, newOrgStructure, orgData);
    setOrganisationStructure(newOrgStructure);
  };

  const setupStaticStructure = (recType, nonResidentRf) => {
    let staticStructure = {};

    if (recType === 'comm_org') {
      staticStructure.generalInformation = nonResidentRf ? structureOrgMainNoResident : structureOrgMainResident;
    } else {
      staticStructure.generalInformation = nonResidentRf ? structureIpMainNoResident : structureIpMainResident;
    }

    staticStructure.contactsInformation = structureOrgContactsResident;
    staticStructure.activityType = structureTypeOfActivity;
    staticStructure.branch = structureBranch;
    staticStructure.bank = structureBank;
    staticStructure.license = structureLicense;
    staticStructure.exportStructure = structureExport;
    staticStructure.rating = structureRating;

    return staticStructure;
  };

  const renderViews = (meta, structure, editMode, store, form, data = orgData) => {
    return !editMode ? (
      <RecordReader
        data={data}
        recordUuid={recordUuid}
        referenceOrigin={store}
        fieldsMeta={meta}
        structure={structure}
      />
    ) : (
      <RecordEditor
        form={form}
        data={data}
        recordUuid={recordUuid}
        referenceOrigin={store}
        fieldsMeta={meta}
        structure={structure}
        setHiddenFields={setHiddenFields}
        hiddenFields={hiddenFields}
        changeOrgData={setData}
      />
    );
  };

  const orgMeta = useMemo(() => {
    let options = orgOptions;
    if (orgData && orgData.recType === 'individual') {
      options = orgIpOptions;
    }
    const orgFields = concat(orgAdditionalFields, fields);
    return metaUnifier(orgFields, options);
  }, [fields, orgAdditionalFields, orgData]);

  const {generalInformation, contactsInformation, activityType, branch, bank, license, exportStructure, rating} =
    organisationStructure;

  const tabsForRender = (form) => {
    return (
      <Tabs className={'org-list__tabs'} activeKey={tabKey} onChange={setTabKey}>
        <TabPane forceRender={true} tab="Общая информация" key="generalInformation">
          {renderViews(orgMeta, generalInformation, editMode, 'org', form)}
        </TabPane>
        <TabPane forceRender={true} tab="Контактная информация" key="contactsInformation">
          {renderViews(orgMeta, contactsInformation, editMode, 'org', form)}
        </TabPane>
        <TabPane forceRender={true} tab="Виды деятельности" key="activityType">
          {renderViews(orgMeta, activityType, editMode, 'org', form)}
        </TabPane>
        <TabPane tab="Проверки" key="4">
          <CatalogsOrgChecks origin="kyc_check_results" recordUuid={recordUuid} />
        </TabPane>
        <TabPane tab="Документы" key="5">
          <CatalogsOrgDocs
            recordUuid={recordUuid}
            header={
              <Button
                type="primary"
                className="mb-2"
                onClick={() => window.open(`${REACT_APP_ECM_FRONT_URL}/document/create?orgId=${recordUuid}`, '_blank')}
              >
                Создать документ
              </Button>
            }
            columns={documentColumns}
            filterFields={documentFilterFields}
            store={'document'}
          />
        </TabPane>
        <TabPane tab="Реестр документов" key="6">
          <CatalogsOrgDocs
            recordUuid={recordUuid}
            columns={documentRegistryColumns}
            filterFields={registryFilterFields}
            store={'registry'}
          />
        </TabPane>
        <TabPane tab="Структура компании и аффилированные лица" key="7">
          <CatalogCrosLinks recordUuid={recordUuid} />
        </TabPane>
        <TabPane tab="Филиалы" key="branch">
          {renderViews(orgMeta, branch, editMode, 'org', form)}
        </TabPane>
        {orgData.bank && (
          <TabPane tab="Банк" key="bank">
            {renderViews(orgMeta, bank, editMode, 'org', form)}
          </TabPane>
        )}
        <TabPane tab="Лицензии" key="license">
          {renderViews(orgMeta, license, editMode, 'org', form)}
        </TabPane>
        <TabPane tab="Экспортная деятельность" key="exportStructure">
          {renderViews(orgMeta, exportStructure, editMode, 'org', form)}
        </TabPane>
        <TabPane tab="Рейтинги и лимиты" key="rating">
          {renderViews(orgMeta, rating, editMode, 'org', form)}
        </TabPane>
        <TabPane tab="Активные услуги" key="13">
          <CatalogsOrgDocs
            recordUuid={recordUuid}
            columns={serviceColumns}
            filterFields={serviceFilterFields}
            store={'services'}
          />
        </TabPane>
      </Tabs>
    );
  };

  return (
    <Card className="org-list" loading={loading} extra={!props.readOnly && renderEditButtons()}>
      {!props.readOnly && <VersionsByDateForm onFinish={findVersionsByDate} depecated />}
      {orgData && (
        <SystemInformation
          data={orgData}
          validateCatalog={true}
          referenceOriginLocal={'organisations'}
          recordUuid={recordUuid}
        />
      )}
      {orgData && (
        <Button
          type="primary"
          className="mt-3"
          loading={cpeLoading}
          onClick={() => {
            setCpeLoading(true);
            getOrgFromCpe();
          }}
        >
          {orgData.nonResidentRf ? 'Сведения из ЦПП' : 'Сведения из ФНС'}
        </Button>
      )}
      {hasDeduplicationAccess && (
        <Button
          type="primary"
          className="mt-3 ml-3"
          onClick={() => {
            setDeduplicationModalVisible(true);
          }}
        >
          Запустить дедубликацию
        </Button>
      )}
      <a href={`/deduplication/protocols?goldCard=${recordUuid}`} target={'_blank'} className="button mt-3 ml-3">
        Протоколы дедубликации
      </a>
      {orgData && fields ? (
        !editMode ? (
          tabsForRender()
        ) : (
          <FormInitiator
            setTabKey={setTabKey}
            structure={organisationStructure}
            formName={'catalog-orgs-form'}
            hiddenFields={hiddenFields}
            fieldsMeta={orgMeta}
            data={orgData}
            changeRecord={changeRecord}
            fixedSubmitButton
            submitButtonPosition={{
              bottom: '50px',
              right: '64px',
            }}
            children={(form) => tabsForRender(form)}
          />
        )
      ) : (
        ''
      )}
      <Modal
        title={'Просмотр версий на дату'}
        open={showVersionModal}
        footer={null}
        onCancel={() => setShowVersionModal(false)}
        destroyOnClose={true}
        width={800}
      >
        <Versions
          catalogUuid="org"
          recordUuid={recordUuid}
          location={props.location}
          history={props.history}
          versionDate={versionDate}
          includeDeprecatedHistory={includeDeprecatedHistory}
          versionModal={(versionId) => {
            return <CatalogsOrgCard versionId={versionId} readOnly />;
          }}
        />
      </Modal>
      <Modal
        title={orgData && orgData.nonResidentRf ? 'Сведения из ЦПП' : 'Сведения из ФНС'}
        open={cpeModalVisible}
        onCancel={() => setCpeModalVisible(false)}
        width={1100}
        footer={null}
      >
        {renderViews(cpeMeta, cpeStructure, false, 'org', null, cpeData)}
      </Modal>
    </Card>
  );
};

export default CatalogsOrgCard;
